import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectMyFullName } from 'redux/restResources/detail/me/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { WithHomeDrawer as Component } from './WithHomeMenu.component';

export interface IOwnProps {
  isCollapsed?: boolean;
  selectedPage: string;
}
type IDispatchProps = typeof mapDispatchToProps;
type IStateProps = ReturnType<typeof mapStateToProps>;

export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    routeProject: selectRouteResource(rrc.project)(rootState),
    myFullName: selectMyFullName(rootState),
  };
};
const mapDispatchToProps = {};

export const WithHomeDrawer = connect(mapStateToProps, mapDispatchToProps)(Component);
