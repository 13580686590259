import { _find } from 'libs/lodash';
import { _get } from 'libs/lodash';
import { selectObatResources } from 'redux/obatResources/selectors';
import { IObatResource } from 'types/Obat/ObatResource';
import { orc } from 'utils/strings/resourceClasses';

import { IRootState } from '../../reducer';

interface IPlotsModalData {
  [key: string]: IObatResource | undefined;
}

export const selectIsPlotsModalOpen = (rootState: IRootState): boolean => {
  return rootState.modals.plotsModal.isOpen;
};

export const selectPlotsModalData = (rootState: IRootState): IPlotsModalData => {
  const { resourceId, resourceClass } = rootState.modals.plotsModal;

  const calendars = selectObatResources('calendar')(rootState);
  const yearProfiles = selectObatResources('year_profile')(rootState);
  const schedules = selectObatResources('schedule')(rootState);

  if (resourceClass === orc.calendar) {
    const calendar = _find(calendars, ['id', resourceId]);

    return { calendar };
  }

  if (resourceClass === orc.year_profile) {
    const yearProfile = _find(yearProfiles, ['id', resourceId]);
    const yearProfileCalendar = yearProfile ? _find(calendars, ['id', _get(yearProfile, 'calendar')]) : undefined;

    return { calendar: yearProfileCalendar, yearProfile };
  }

  if (resourceClass === orc.schedule) {
    const schedule = _find(schedules, ['id', resourceId]);
    const scheduleYearProfile = _find(yearProfiles, ['id', _get(schedule, 'year_profile')]);
    const scheduleCalendar = _find(calendars, ['id', _get(schedule, 'calendar')]);

    return { calendar: scheduleCalendar, yearProfile: scheduleYearProfile, schedule };
  }

  return {};
};
