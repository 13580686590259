import { ILogsModalActions, UPDATE_LOGS_MODAL_SIMULATION_ACTION } from './actions';

export interface ILogsModalState {
  simulationId?: string;
}

export const initialState = {
  simulationId: undefined,
};

export const logsModalReducer = (state: ILogsModalState = initialState, action: ILogsModalActions): ILogsModalState => {
  switch (action.type) {
    case UPDATE_LOGS_MODAL_SIMULATION_ACTION:
      return { ...state, simulationId: action.payload.simulationId };

    default:
      return state;
  }
};
