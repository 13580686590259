// tslint:disable:no-any
import {
  IToggleInputProps,
  ToggleInput,
} from 'components/Forms/Inputs/SingleSelectInputs/ToggleInput/ToggleInput.component';
import React, { PureComponent, ReactNode } from 'react';
import { displayFormFieldError } from 'utils/functions/forms/displayFormFieldError/displayFormFieldError';

interface IProps extends Pick<IToggleInputProps, Exclude<keyof IToggleInputProps, 'value' | 'onSelect'>> {
  formikProps: any;
}

export class FormikToggleInput extends PureComponent<IProps> {
  public render(): ReactNode {
    const { formikProps, field, ...toggleInputProps } = this.props;

    return (
      <ToggleInput
        {...toggleInputProps}
        field={field}
        value={formikProps.values[field]}
        errorText={displayFormFieldError(field, formikProps)}
        onSelect={formikProps.setFieldValue}
      />
    );
  }
}
