import Grid from '@material-ui/core/Grid';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getRestResourceFormDrawer } from 'components/Forms/RestResourceFormDrawer';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { Component, createRef, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './RestResourceCopyFormDrawer.container';

export interface ICopyFormValues {
  name: string;
  comment: string;
}

export interface ICopyRequestParams extends ICopyFormValues {
  simulation?: string /* Only for simulations... */;
}

export class RestResourceCopyFormDrawer extends Component<IProps> {
  public RestResourceFormDrawer = getRestResourceFormDrawer<ICopyFormValues, ICopyRequestParams>();
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { RestResourceFormDrawer } = this;
    const { initialRestResource, resourceClass, getCreationSuccessLandingPageUrl } = this.props;

    return (
      <RestResourceFormDrawer
        isCopy
        title={$t('copyOf', { name: initialRestResource && initialRestResource.name })}
        renderForm={this.renderForm}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.small}
        resourceClass={resourceClass}
        getCreationSuccessLandingPageUrl={getCreationSuccessLandingPageUrl}
        getRequestParams={this.getRequestParams}
      />
    );
  }

  public renderForm = (formikProps: FormikProps<ICopyFormValues>): ReactNode => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikWriteInput field={'name'} formikProps={formikProps} required inputRef={this.formFocusRef} />
        </Grid>
        <Grid item xs={12}>
          <FormikWriteInput field={'comment'} formikProps={formikProps} multiline />
        </Grid>
      </Grid>
    );
  };

  private getInitialFormValues = (): ICopyFormValues => {
    return { name: '', comment: '' };
  };

  private getValidationSchema = (): yup.ObjectSchema<ICopyFormValues> => {
    return yup.object().shape({
      name: getYupStringValidation(true),
      comment: getYupStringValidation(),
    }) as yup.ObjectSchema<ICopyFormValues>;
  };

  private getRequestParams = (formValues: ICopyFormValues): ICopyRequestParams => {
    const { resourceClass, initialRestResource } = this.props;

    const requestParams = convertEmptyFieldsToNull(formValues);

    return resourceClass === rrc.simulation ? { ...requestParams, simulation: initialRestResource.id } : requestParams;
  };
}
