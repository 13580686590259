import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { fetchWeathersTemplatesSagaAction } from 'redux/restResources/detail/weather/actions';
import { selectWeathersTemplateOptionsFunction } from 'redux/restResources/detail/weather/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { IRestResource } from 'types/RestResource';
import { rrc } from 'utils/strings/resourceClasses';

import { WeatherForm as WeatherFormComponent } from './WeatherForm.component';
interface IOwnProps {
  getCreationSuccessLandingPageUrl: (routeProjectId: string, createdGeometry: IRestResource) => string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    weatherToEdit: selectFormInitialResourceFromTable(rrc.weather)(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
    selectTemplateOptions: selectWeathersTemplateOptionsFunction(rootState),
  };
};

const mapDispatchToProps = {
  fetchWeathersTemplates: fetchWeathersTemplatesSagaAction,
};

export const WeatherForm = connect(mapStateToProps, mapDispatchToProps)(WeatherFormComponent);
