import moment from 'moment';

export const formatISODate = (iso: string | null | undefined, format: string, clock: 'utc' | 'dst' = 'utc'): string => {
  if (!iso) {
    return '-';
  }

  return clock === 'utc'
    ? moment.utc(iso).format(format)
    : moment
        .utc(iso)
        .local()
        .format(format);
};

export const formatISODateToDstDatetime = (iso: string) => {
  return formatISODate(iso, 'YYYY-MM-DD HH:mm', 'dst');
};
