import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { IOpening } from 'types/Obat/Opening';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { renderTextFloatElseDash } from 'utils/functions/renderValue/renderValue';
import { OpeningDetail } from '../OpeningDetail';
import { OpeningForm } from '../OpeningForm';

export class OpeningsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'openings'}
        resourceClass={orc.opening}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={OpeningForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 3 },
    {
      id: 'surfaceCategory',
      gridItemSize: 3,
      resourceField: 'surface_category',
      withFilterOptions: true,
      renderCustomText: (opening: IOpening) => $tEnums(opening.surface_category),
    },
    {
      id: 'openingType',
      resourceField: 'type',
      gridItemSize: 2,
      withFilterOptions: true,
      renderCustomText: (opening: IOpening) => $tEnums(opening.type),
    },
    {
      id: 'blindsType',
      resourceField: 'blind_type',
      gridItemSize: 2,
      withFilterOptions: true,
      renderCustomText: (opening: IOpening) => $tEnums(opening.blind_type),
    },
    {
      id: 'conductanceWithUnit',
      resourceField: 'conductance',
      gridItemSize: 2,
    },
  ];

  private renderCardColumnData = (opening: IOpening, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return opening.ref;
      case 'surfaceCategory':
        return $tEnums(opening.surface_category);
      case 'openingType':
        return $tEnums(opening.type);
      case 'blindsType':
        return $tEnums(opening.blind_type);
      case 'conductanceWithUnit':
        return renderTextFloatElseDash(opening.conductance);
    }
  };

  private renderCardDetail = (opening: IOpening): ReactNode => {
    return <OpeningDetail opening={opening} />;
  };
}
