import { IWeatherHourlyData } from 'types/Weather/Weather';
import { IProgressStatus, ps } from 'utils/strings/progressStatus';

import { IWeathersTemplates } from './../../../../types/Weather/Weather';
import {
  CLEAR_WEATHER_MEASURES_ACTION,
  IWeatherDataActions,
  TOGGLE_IS_WEATHER_DATA_DOWNLOAD_ERROR_ACTION,
  UPDATE_DEGREE_DAYS_DATA_ACTION,
  UPDATE_DEGREE_DAYS_PARAMS_ACTION,
  UPDATE_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_ACTION,
  UPDATE_WEATHER_IMPORT_PROGRESS_ACTION,
  UPDATE_WEATHER_MEASURES_METADATA_ACTION,
  UPDATE_WEATHER_MEASURES_PLOTTED_ACTION,
  UPDATE_WEATHER_MEASURES_YEARS_DATA_ACTION,
  UPDATE_WEATHERS_TEMPLATES_ACTION,
} from './actions';

export interface IWeatherMeasuresPlotted {
  seriesId: string;
  year: string;
}

export interface IWeatherMeasuresYearsData {
  [year: string]: IWeatherHourlyData;
}

export interface IWeatherMeasuresMetadata {
  series: string[];
  years?: string[];
}

export interface IDegreeDaysData {
  cdd: number[];
  hdd: number[];
  months: string[];
  sumCdd: number;
  sumHdd: number;
}

export interface IDegreeDaysParams {
  coolingBase: string;
  heatingBase: string;
  year?: string;
}

export interface IConfigOptionsParams {
  dataSource: string;
}

export interface IOpenergyHistoricalWeatherConfigOption {
  name: string;
  id: string;
}

export interface IWeatherState {
  isDataDownloadError: boolean;
  openergyHistoricalWeatherConfigOptions: IOpenergyHistoricalWeatherConfigOption[];
  importProgress: IProgressStatus;
  measures: {
    metadata: IWeatherMeasuresMetadata;
    yearsData: IWeatherMeasuresYearsData;
    plotted?: IWeatherMeasuresPlotted;
  };
  degreeDays: {
    data?: IDegreeDaysData;
    params: IDegreeDaysParams;
  };
  templates?: IWeathersTemplates;
}

export const initialState: IWeatherState = {
  isDataDownloadError: false,
  openergyHistoricalWeatherConfigOptions: [],
  importProgress: ps.finished,
  measures: {
    metadata: {
      series: [],
      years: undefined,
    },
    yearsData: {},
    plotted: undefined,
  },
  degreeDays: {
    data: undefined,
    params: {
      coolingBase: '23',
      heatingBase: '18',
    },
  },
  templates: undefined,
};

export const weatherReducer = (state: IWeatherState = initialState, action: IWeatherDataActions): IWeatherState => {
  switch (action.type) {
    case UPDATE_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_ACTION:
      return {
        ...state,
        openergyHistoricalWeatherConfigOptions: action.payload.configOptions,
      };

    case UPDATE_WEATHER_IMPORT_PROGRESS_ACTION:
      return {
        ...state,
        importProgress: action.payload.progress,
      };

    case UPDATE_WEATHER_MEASURES_PLOTTED_ACTION:
      return {
        ...state,
        measures: {
          ...state.measures,
          plotted: action.payload.plotted,
        },
      };

    case UPDATE_WEATHER_MEASURES_METADATA_ACTION:
      return {
        ...state,
        measures: {
          ...state.measures,
          metadata: action.payload.metadata,
        },
      };

    case UPDATE_WEATHER_MEASURES_YEARS_DATA_ACTION:
      const { seriesId, year, seriesYearData } = action.payload;

      return {
        ...state,
        measures: {
          ...state.measures,
          // @ts-ignore
          yearsData: {
            ...state.measures.yearsData,
            [year]: {
              ...state.measures.yearsData[year],
              [seriesId]: seriesYearData,
            },
          },
        },
      };

    case UPDATE_DEGREE_DAYS_DATA_ACTION:
      return {
        ...state,
        degreeDays: {
          ...state.degreeDays,
          data: action.payload.data,
        },
      };

    case UPDATE_DEGREE_DAYS_PARAMS_ACTION:
      return {
        ...state,
        degreeDays: {
          ...state.degreeDays,
          params: action.payload.params,
        },
      };

    case CLEAR_WEATHER_MEASURES_ACTION:
      return {
        ...state,
        measures: initialState.measures,
      };

    case UPDATE_WEATHERS_TEMPLATES_ACTION:
      return {
        ...state,
        templates: action.payload.templates,
      };

    case TOGGLE_IS_WEATHER_DATA_DOWNLOAD_ERROR_ACTION:
      return {
        ...state,
        isDataDownloadError: action.payload.isError,
      };

    default:
      return state;
  }
};
