import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { selectFormDrawerWidth } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import {
  fetchSimulationGroupConfigOptionsSagaAction,
  refreshSimulationGroupSagaAction,
  runSimulationSagaAction,
} from 'redux/restResources/detail/simulationGroup/actions';
import { selectIsSimulationInProgress } from 'redux/restResources/detail/simulationGroup/selectors';
import { selectIsSeatMissing, selectIsWritePermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { fk } from 'utils/strings/formKeys';
import { rrc } from 'utils/strings/resourceClasses';

import { ConfigMono as Component } from './ConfigMono.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  const routeProject = selectRouteResource(rrc.project)(rootState);
  const routeMonoSimulationGroup = selectRouteResource(rrc.mono_simulation_group)(rootState);

  return {
    routeProject,
    routeMonoSimulationGroup,
    isSimulationInProgress: selectIsSimulationInProgress(rootState),
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
    formDrawerWidth: selectFormDrawerWidth(rootState),
  };
};

const mapDispatchToProps = {
  fetchSimulationGroupConfigOptions: fetchSimulationGroupConfigOptionsSagaAction,
  openFormDrawer: openFormDrawerSagaAction,
  refreshSimulationGroup: refreshSimulationGroupSagaAction,
  runSimulation: runSimulationSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: {}) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openConfigFormDrawer: () =>
    dispatchProps.openFormDrawer(fk.configMono, {
      resourceId: stateProps.routeMonoSimulationGroup.id,
      resourceClass: rrc.mono_simulation_group,
    }),
});

export const ConfigMono = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
