import Grid from '@material-ui/core/Grid';
import { BREADCRUMB_BAR_HEIGHT } from 'components/BreadcrumbTopBar/BreadcrumbTopBar.component';
import { FilterForm } from 'components/Forms/FilterForm/FilterForm.container';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { ICardsTableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t } from 'utils/functions/translate';

import { IProps } from './ObatHeadersBar.container';

export interface IState {
  filtersAnchorEl: EventTarget | undefined;
}
export class ObatHeadersBar extends PureComponent<IProps, IState> {
  public state: IState = {
    filtersAnchorEl: undefined,
  };
  public render(): ReactNode {
    const { isDataEmpty, isDataReady, cardColumnsParams } = this.props;

    if (!isDataReady || isDataEmpty) {
      return null;
    }

    return (
      <ObatHeadersTopBar>
        <Grid container spacing={1}>
          {_map(cardColumnsParams, (columnParams: ICardsTableColumnParams) => {
            return (
              <Grid item xs={12} md={columnParams.gridItemSize} key={columnParams.id}>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  wrap="nowrap"
                  alignItems={columnParams.centerTitle ? 'center' : 'flex-start'}
                >
                  <Grid item xs={12}>
                    {columnParams.title || $t(columnParams.id)}
                  </Grid>
                  <Grid item xs={12} style={{ width: '100%' }}>
                    {columnParams.resourceField && <FilterForm columnId={columnParams.id} />}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </ObatHeadersTopBar>
    );
  }
}

const OBAT_HEADERS_BAR_HEIGHT = 75.63;

export const OBAT_TOP_BAR_HEIGHT = BREADCRUMB_BAR_HEIGHT + OBAT_HEADERS_BAR_HEIGHT;

const ObatHeadersTopBar = styled.div`
  padding: 4px 32px;
  border-bottom: solid 1px ${theme.colors.divider};
  background-color: ${theme.colors.pageBody};
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;
