import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath, selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { IRootState } from 'redux/reducer';
import { editMyPasswordSagaAction } from 'redux/restResources/detail/me/actions';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra } from 'utils/strings/requestActions';
import { selectMyUser } from './../../../../redux/restResources/detail/me/selectors';

import { PasswordForm as PasswordFormComponent } from './PasswordForm.component';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState, _ownProps: IOwnProps) => {
  const myUser = selectMyUser(rootState);

  return {
    myUser,
    isSubmitting:
      !!myUser && selectIsApiRequestPending(getDetailApiRequestKey(dra.changePassword, myUser.id))(rootState),
    errors: myUser
      ? selectFormApiErrorsTranslationPath(getDetailApiRequestKey(dra.changePassword, myUser.id))(rootState)
      : undefined,
  };
};

const mapDispatchToProps = {
  editPassword: editMyPasswordSagaAction,
};

export const PasswordForm = connect(mapStateToProps, mapDispatchToProps)(PasswordFormComponent);
