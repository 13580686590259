import Grid from '@material-ui/core/Grid';
import { SimpleCard } from 'components/Cards';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceForm as getForm } from 'components/Forms/ObatResourceForm';
import { FormikProps } from 'formik';
import { _split } from 'libs/lodash';
import { getYupStringValidation } from 'libs/yup';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { IDayProfileRequestParams } from 'types/Obat/DayProfile';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './DayProfileForm.container';

export interface IDayProfileFormValues {
  text: string;
}

const Form = getForm<IDayProfileFormValues, IDayProfileRequestParams>();

export class DayProfileForm extends PureComponent<IProps> {
  public render(): ReactNode {
    const { dayProfileToEdit } = this.props;

    return (
      <SimpleCard>
        <Form
          initialObatResource={dayProfileToEdit}
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          resourceClass={orc.day_profile}
        />
      </SimpleCard>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IDayProfileFormValues>): ReactNode => {
      const { dayProfileToEdit } = this.props;

      const dayGroupWeakRef = _split(dayProfileToEdit.id, '~')[1];

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <StyledTextArea field={'text'} formikProps={formikProps} required label={dayGroupWeakRef} multiline />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): IDayProfileFormValues => {
    const { dayProfileToEdit } = this.props;

    return {
      text: setFormInitialValue(dayProfileToEdit, 'text'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IDayProfileFormValues> => {
    return yup.object().shape({
      text: getYupStringValidation(true),
    }) as yup.ObjectSchema<IDayProfileFormValues>;
  };
}

const StyledTextArea = (styled(FormikWriteInput)`
  margin-bottom: 16px;
` as unknown) as typeof FormikWriteInput;
