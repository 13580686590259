/* oprc for ObatResources Parent Resource Class */
import { _pick } from 'libs/lodash';
import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

const obatParentResourceClassesTuple = stringsTuple(
  'calendar',
  'year_profile',
  'opening',
  'construction',
  'schedule',
  'surface_group',
  'output_zone_group',
  'bridge',
  'script',
  'system',
  'activity_zone_group',
  'variant',
  'material',
  'use',
  'general',
  'ef_ep',
  'e_plus_parameters'
);
export type IObatParentResourceClass = typeof obatParentResourceClassesTuple[number];
export const oprc = mapStrings<IObatParentResourceClass>(obatParentResourceClassesTuple);

/* ocrc for ObatResources Child Resource Class */
const obatChildResourceClassesTuple = stringsTuple('day_group', 'period', 'day_profile', 'layer', 'modification');
export type IObatChildResourceClass = typeof obatChildResourceClassesTuple[number];
export const ocrc = mapStrings<IObatChildResourceClass>(obatChildResourceClassesTuple);

/* orc for ObatResources Child Resource Class */
export type IObatResourceClass = IObatParentResourceClass | IObatChildResourceClass;
export const orc: { [rc in IObatResourceClass]: IObatResourceClass } = { ...oprc, ...ocrc };

/* rrc for Rest Resource Class */
const restResourceClassesTuple = stringsTuple(
  'organization',
  'floorspace',
  'geometry',
  'obat',
  'project',
  'project_visible',
  'user_task',
  'weather',
  'generic_weather_series',
  'historical_weather_series',
  'openergy_historical_weather_series',
  'simulation',
  'simulation_group',
  'mono_simulation_group',
  'multi_simulation_group',
  'generic_simulation_group',
  'user_organization_permission',
  'user_project_permission',
  'user',
  'series'
);
export type IRestResourceClass = typeof restResourceClassesTuple[number];
export const rrc = mapStrings<IRestResourceClass>(restResourceClassesTuple);

/* urrc for Url Rest Resource Class */
export const urrc = _pick(rrc, [
  rrc.organization,
  rrc.project,
  rrc.geometry,
  rrc.obat,
  rrc.weather,
  rrc.mono_simulation_group,
  rrc.multi_simulation_group,
]);

export type IApiResourceClass = IRestResourceClass | IObatResourceClass;
