import { IRootState } from 'redux/reducer';
import { IAlertStatus } from 'utils/strings/alertStatus';

export const selectAlertModalText = (rootState: IRootState): string | undefined => {
  return rootState.modals.alertModal.alertText;
};

export const selectAlertModalStatus = (rootState: IRootState): IAlertStatus | undefined => {
  return rootState.modals.alertModal.alertStatus;
};

export const selectAlertModalTitle = (rootState: IRootState): string | undefined => {
  return rootState.modals.alertModal.modalTitle;
};
