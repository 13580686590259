import { connect } from 'react-redux';
import { openLogsModalSagaAction } from 'redux/modals/logsModal/actions';
import { IRootState } from 'redux/reducer';
import {
  exportEplusOutputsSagaAction,
  fetchSimulationGroupConfigOptionsSagaAction,
  refreshSimulationGroupSagaAction,
  runSimulationSagaAction,
} from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectIsAllSimulationsEmpty,
  selectIsSimulationInProgress,
  selectSimulationsListByStatus,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { selectIsSeatMissing, selectIsWritePermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { MonitorMulti as Component } from './MonitorMulti.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeMultiSimulationGroup = selectRouteResource(rrc.multi_simulation_group)(rootState);

  return {
    routeMultiSimulationGroup,
    isSeatMissing: selectIsSeatMissing(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
    isSimulationInProgress: selectIsSimulationInProgress(rootState),
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isAllSimulationsEmpty: selectIsAllSimulationsEmpty(rootState),
    simulationsListByStatus: selectSimulationsListByStatus(rootState),
  };
};

const mapDispatchToProps = {
  runSimulation: runSimulationSagaAction,
  exportEplusOutputs: exportEplusOutputsSagaAction,
  refreshSimulationGroup: refreshSimulationGroupSagaAction,
  openLogsModal: openLogsModalSagaAction,
  fetchSimulationGroupConfigOptions: fetchSimulationGroupConfigOptionsSagaAction,
};

export const MonitorMulti = connect(mapStateToProps, mapDispatchToProps)(Component);
