import EventSeatOutlined from '@material-ui/icons/EventSeatOutlined';
import { IMenuItem } from 'components/Menus/WithMenu/WithMenu.component';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { Folder, Settings } from 'react-feather';
import { getOrganizationMenuPageRoute, IOrganizationMenuPage, organizationMenuPages } from 'routing/routes';
import { WithMenu } from '../../../components/Menus/WithMenu';
import { IProps } from './WithOrganizationMenu.container';

export const ORGANIZATION_DEFAULT_DRAWER_WIDTH = 250;
export const ORGANIZATION_COLLAPSED_DRAWER_WIDTH = 60;

interface IOrganizationMenuItem extends IMenuItem {
  page: IOrganizationMenuPage;
}
export class WithOrganizationMenu extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isCollapsed, selectedPage, routeOrganization, children } = this.props;

    const items: IOrganizationMenuItem[] = [
      { page: organizationMenuPages.organizationProjects, Icon: <Folder /> },
      { page: organizationMenuPages.organizationPermissions, Icon: <Settings />, isDividerAbove: true },
      { page: organizationMenuPages.seats, Icon: <EventSeatOutlined /> },
    ];

    return (
      <WithMenu
        isCollapsed={!!isCollapsed}
        selectedPage={selectedPage}
        routeResourceId={routeOrganization.id}
        items={items}
        getMenuPageRoute={getOrganizationMenuPageRoute}
      >
        {children}
      </WithMenu>
    );
  }
}
