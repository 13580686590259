import { AddButton } from 'components/Buttons/AddButton';
import { CenterButtons, TopRightButtons } from 'components/Buttons/ButtonsContainers';
import { Page } from 'components/Page';
import { RestResourcesList } from 'components/RestResources/RestResourcesList';
import React, { PureComponent, ReactNode } from 'react';
import { $t } from 'utils/functions/translate';

import { IProps } from './RestResourcesListPage.container';

export class RestResourcesListPage extends PureComponent<IProps> {
  public render = (): ReactNode => {
    const {
      routingMenu,
      selectedPage,
      selectedPageTitle,
      resourceClass,
      parentResourceClass,
      columnsParams,
      FormComponent,
      defaultSort,
      getCreationSuccessLandingPageUrl,
      availableActions,
      isDataEmpty,
      isLoading,
      isCreationHidden,
      formDrawerWidth,
      isCreationDisabled,
      creationTooltipContent,
      renderAlert,
    } = this.props;

    return (
      <Page
        pageTitle={selectedPageTitle || $t(selectedPage)}
        routingMenu={routingMenu}
        selectedPage={selectedPage}
        renderAlert={renderAlert}
        noPadding
      >
        {!isLoading && isDataEmpty && !isCreationHidden && formDrawerWidth === 0 && (
          <CenterButtons isPulsing>
            <AddButton withText isDisabled={isCreationDisabled} tooltipContent={creationTooltipContent} />
          </CenterButtons>
        )}
        <RestResourcesList
          resourceClass={resourceClass}
          parentResourceClass={parentResourceClass}
          columnsParams={columnsParams}
          FormComponent={FormComponent}
          defaultSort={defaultSort}
          getCreationSuccessLandingPageUrl={getCreationSuccessLandingPageUrl}
          availableActions={availableActions}
        />
        {!isDataEmpty && !isCreationHidden && (
          <TopRightButtons>
            <AddButton isDisabled={isCreationDisabled} tooltipContent={creationTooltipContent} />
          </TopRightButtons>
        )}
      </Page>
    );
  };
}
