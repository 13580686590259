import { connect } from 'react-redux';
import { updateHeatmapAction } from 'redux/obatResources/actions';
import { selectCalendarHeatmap } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { ICalendar } from 'types/Obat/Calendar';

import { CalendarPlot as CalendarPlotComponent } from './CalendarPlot.component';

interface IOwnProps {
  calendar: ICalendar;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return {
    heatmap: selectCalendarHeatmap(ownProps.calendar.ref)(rootState),
  };
};

const mapDispatchToProps = {
  updateCalendarHeatmap: updateHeatmapAction,
};

export const CalendarPlot = connect(mapStateToProps, mapDispatchToProps)(CalendarPlotComponent);
