import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { expandFormDrawerAction } from 'redux/form/actions';
import { selectIsFormDrawerCollapsed } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { manageSeatSagaAction } from 'redux/restResources/detail/organization/actions';
import { fetchAllRouteResourcesSagaAction, toggleIsRefreshingRoutePageAction } from 'redux/routing/actions';
import {
  selectBreadcrumbList,
  selectIsSeatMissing,
  selectRoute,
  selectRoutePage,
  selectRouteResource,
} from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { BreadcrumbTopBar as Component } from './BreadcrumbTopBar.component';

interface IOwnProps {
  pageTitle: string;
  renderHeaders?: () => ReactNode;
  customPageRefresh?: () => void;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => ({
  route: selectRoute(rootState),
  routePage: selectRoutePage(rootState),
  routeProject: selectRouteResource(rrc.project)(rootState),
  routeOrganization: selectRouteResource(rrc.organization)(rootState),
  isFormDrawerCollapsed: selectIsFormDrawerCollapsed(rootState),
  breadcrumbList: selectBreadcrumbList(rootState),
  isSeatMissing: selectIsSeatMissing(rootState),
});

const mapDispatchToProps = {
  manageSeat: manageSeatSagaAction,
  expandForm: expandFormDrawerAction,
  toggleIsRefreshingRoutePage: toggleIsRefreshingRoutePageAction,
  fetchAllRouteResources: fetchAllRouteResourcesSagaAction,
};

export const BreadcrumbTopBar = connect(mapStateToProps, mapDispatchToProps)(Component);
