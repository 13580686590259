import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { IRootState } from 'redux/reducer';
import {
  clearWeatherMeasuresAction,
  downloadWeatherSeriesHourlyDataSagaAction,
} from 'redux/restResources/detail/weather/actions';
import {
  selectWeatherMeasuresPlottedSeries,
  selectWeatherMeasuresSeriesIdOptions,
  selectWeatherMeasuresYearOptions,
  selectWeatherPlottedMeasure,
} from 'redux/restResources/detail/weather/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { WeatherMeasures as Component } from './WeatherMeasures.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeWeather = selectRouteResource(rrc.weather)(rootState);
  const fetchSeriesApiRequestKey = getDetailApiRequestKey(dra.fetchWeatherSeries, routeWeather.id);

  return {
    routeWeather,
    routeProject: selectRouteResource(rrc.project)(rootState),
    plottedSeries: selectWeatherMeasuresPlottedSeries(rootState),
    plottedMeasure: selectWeatherPlottedMeasure(rootState),
    measuresSeriesIdOptions: selectWeatherMeasuresSeriesIdOptions(rootState),
    measuresYearOptions: selectWeatherMeasuresYearOptions(rootState),
    isFetchingWeatherData: selectIsApiRequestPending(fetchSeriesApiRequestKey)(rootState),
  };
};

const mapDispatchToProps = {
  downloadWeatherSeriesHourlyData: downloadWeatherSeriesHourlyDataSagaAction,
  clearWeatherMeasures: clearWeatherMeasuresAction,
};

export const WeatherMeasures = connect(mapStateToProps, mapDispatchToProps)(Component);
