// tslint:disable:no-any

import { _has, _map, _size } from 'libs/lodash';

export interface ISelectOption {
  label: any;
  value: any;
}
export type ISelectOptions = ISelectOption[];

export const getSelectOptions = (
  options?: ISelectOptions | string[],
  renderOptionLabel?: (value: any) => any
): ISelectOptions => {
  let selectOptions: ISelectOptions;
  if (!options || _size(options) === 0) {
    return [];
  }
  if (_has(options[0], 'value')) {
    selectOptions = options as ISelectOptions;
  } else {
    selectOptions = _map(
      options,
      (optionValue: any): ISelectOption => ({
        value: optionValue,
        label: renderOptionLabel ? renderOptionLabel(optionValue) : optionValue,
      })
    );
  }

  return selectOptions;
};
