import { _get, _map } from 'libs/lodash';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';

export const enrichEPlusParameters = (obatContents: IObatContents): IObatData => {
  const enrichedEPlusParameters = [
    {
      commitments: { update: {}, delete: {} },
      id: 'solar_distribution',
      ref: 'solarDistributionCalculation',
      value: obatContents.data.general[0].obat_eplus_conf_solar_distribution,
    },
    {
      commitments: { update: {}, delete: {} },
      distributed_january: obatContents.data.general[0].site_heated_ground_temperatures[0],
      distributed_february: obatContents.data.general[0].site_heated_ground_temperatures[1],
      distributed_march: obatContents.data.general[0].site_heated_ground_temperatures[2],
      distributed_april: obatContents.data.general[0].site_heated_ground_temperatures[3],
      distributed_may: obatContents.data.general[0].site_heated_ground_temperatures[4],
      distributed_june: obatContents.data.general[0].site_heated_ground_temperatures[5],
      distributed_july: obatContents.data.general[0].site_heated_ground_temperatures[6],
      distributed_august: obatContents.data.general[0].site_heated_ground_temperatures[7],
      distributed_september: obatContents.data.general[0].site_heated_ground_temperatures[8],
      distributed_october: obatContents.data.general[0].site_heated_ground_temperatures[9],
      distributed_november: obatContents.data.general[0].site_heated_ground_temperatures[10],
      distributed_december: obatContents.data.general[0].site_heated_ground_temperatures[11],
      id: 'ground_temperatures',
      ref: 'groundTemperatures',
      undistributed_january: obatContents.data.general[0].site_undisturbed_ground_temperatures[0],
      undistributed_february: obatContents.data.general[0].site_undisturbed_ground_temperatures[1],
      undistributed_march: obatContents.data.general[0].site_undisturbed_ground_temperatures[2],
      undistributed_april: obatContents.data.general[0].site_undisturbed_ground_temperatures[3],
      undistributed_may: obatContents.data.general[0].site_undisturbed_ground_temperatures[4],
      undistributed_june: obatContents.data.general[0].site_undisturbed_ground_temperatures[5],
      undistributed_july: obatContents.data.general[0].site_undisturbed_ground_temperatures[6],
      undistributed_august: obatContents.data.general[0].site_undisturbed_ground_temperatures[7],
      undistributed_september: obatContents.data.general[0].site_undisturbed_ground_temperatures[8],
      undistributed_october: obatContents.data.general[0].site_undisturbed_ground_temperatures[9],
      undistributed_november: obatContents.data.general[0].site_undisturbed_ground_temperatures[10],
      undistributed_december: obatContents.data.general[0].site_undisturbed_ground_temperatures[11],
      value: obatContents.data.general[0].obat_eplus_conf_ground_temperature_method,
    },
    {
      commitments: { update: {}, delete: {} },
      distributed_january: obatContents.data.general[0].site_water_temperatures[0],
      distributed_february: obatContents.data.general[0].site_water_temperatures[1],
      distributed_march: obatContents.data.general[0].site_water_temperatures[2],
      distributed_april: obatContents.data.general[0].site_water_temperatures[3],
      distributed_may: obatContents.data.general[0].site_water_temperatures[4],
      distributed_june: obatContents.data.general[0].site_water_temperatures[5],
      distributed_july: obatContents.data.general[0].site_water_temperatures[6],
      distributed_august: obatContents.data.general[0].site_water_temperatures[7],
      distributed_september: obatContents.data.general[0].site_water_temperatures[8],
      distributed_october: obatContents.data.general[0].site_water_temperatures[9],
      distributed_november: obatContents.data.general[0].site_water_temperatures[10],
      distributed_december: obatContents.data.general[0].site_water_temperatures[11],
      id: 'water_temperatures',
      ref: 'waterTemperature',
      value: obatContents.data.general[0].obat_eplus_conf_cold_water_temperature_method,
    },
  ];

  return { ...obatContents.data, e_plus_parameters: enrichedEPlusParameters };
};
