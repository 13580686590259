// tslint:disable:no-any
import { _get } from 'libs/lodash';
import { ITableColumnFilterParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';

export const getFilterComparisonValue = (columnFilterParams: ITableColumnFilterParams, resource: any): string => {
  /* Return the value which the user input should be compared with */

  /* It can only be a string because the user can only enter strings */

  const { getCustomDOMFilterComparisonValue, renderCustomText, resourceField } = columnFilterParams;

  if (getCustomDOMFilterComparisonValue) {
    /* In this case, a DOM is rendered  in the table. (Icon, Styled text, Hyperlink...)
       The function getCustomDOMFilterComparisonValue enables to match a string for a DOM */

    return getCustomDOMFilterComparisonValue(resource);
  } else if (renderCustomText) {
    /* In this case, we can use the custom text directly since it's a string */

    return renderCustomText(resource);
  } else {
    /* When no custom rendering, the value is necessarily a resource field value */

    const resourceFieldValue = !!resourceField && _get(resource, resourceField);

    return renderTextValueElseDash(resourceFieldValue);
  }
};
