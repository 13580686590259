import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectIsSeatMissing, selectIsWritePermissionMissing } from 'redux/routing/selectors';

import { ConfigCardHeader as Component } from './ConfigCardHeader.component';

interface IOwnProps {
  title: string;
  onClick?: () => void;
  isConfigDisabled?: boolean;
  // formKey?: IFormKey;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
  };
};

const mapDispatchToProps = {};

export const ConfigCardHeader = connect(mapStateToProps, mapDispatchToProps)(Component);
