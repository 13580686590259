import Grid from '@material-ui/core/Grid';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { FormikProps } from 'formik';
import { getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IEPlusParametersRequestParams } from 'types/Obat/EPlusParameters';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t, $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikRadioInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikRadioInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { Section } from 'components/Section';
import { IProps } from './EPlusParametersForm.container';

export interface IEPlusParametersFormValues {
  value: string;
  distributed_january?: number;
  distributed_february?: number;
  distributed_march?: number;
  distributed_april?: number;
  distributed_may?: number;
  distributed_june?: number;
  distributed_july?: number;
  distributed_august?: number;
  distributed_september?: number;
  distributed_october?: number;
  distributed_november?: number;
  distributed_december?: number;
  undistributed_january?: number;
  undistributed_february?: number;
  undistributed_march?: number;
  undistributed_april?: number;
  undistributed_may?: number;
  undistributed_june?: number;
  undistributed_july?: number;
  undistributed_august?: number;
  undistributed_september?: number;
  undistributed_october?: number;
  undistributed_november?: number;
  undistributed_december?: number;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IEPlusParametersFormValues, IEPlusParametersRequestParams>();

export class EPlusParametersForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { ePlusParametersToEdit } = this.props;
    let title = '';

    if (ePlusParametersToEdit) {
      title = ePlusParametersToEdit.ref;
    }

    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.e_plus_parameters}
        subFormResources={[orc.e_plus_parameters]}
        title={$t(title)}
      />
    );
  }

  public renderForm = () => {
    const { ePlusParametersToEdit, solarDistributionOptions } = this.props;
    let title = '';

    if (ePlusParametersToEdit) {
      title = ePlusParametersToEdit.ref;
    }

    return (formikProps: FormikProps<IEPlusParametersFormValues>): ReactNode => {
      return (
        <>
          {title === 'solarDistributionCalculation' && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikDropdownInput
                  formikProps={formikProps}
                  field={'value'}
                  options={solarDistributionOptions}
                  required
                  renderOptionLabel={$tEnums}
                  obatResourceToBeCheckedForCommitment={ePlusParametersToEdit}
                />
              </Grid>
            </Grid>
          )}

          {(title === 'groundTemperatures' || title === 'waterTemperature') && (
            <>
              <Grid item xs={12}>
                <FormikRadioInput
                  isDisabled={title === 'groundTemperatures'}
                  field={'value'}
                  formikProps={formikProps}
                  label={$t('type')}
                  options={[
                    { value: 'auto', label: $t('auto') },
                    { value: 'manual', label: $t('manual') },
                  ]}
                />
              </Grid>
              {formikProps.values.value === 'manual' && (
                <>
                  <Section title={$t(title === 'groundTemperatures' ? 'conditionedZones' : 'allZones')} marginTop={20}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_january'}
                          formikProps={formikProps}
                          required
                          label={$t('january')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_july'}
                          formikProps={formikProps}
                          required
                          label={$t('july')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_february'}
                          formikProps={formikProps}
                          required
                          label={$t('february')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_august'}
                          formikProps={formikProps}
                          required
                          label={$t('august')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_march'}
                          formikProps={formikProps}
                          required
                          label={$t('march')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_september'}
                          formikProps={formikProps}
                          required
                          label={$t('september')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_april'}
                          formikProps={formikProps}
                          required
                          label={$t('april')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_october'}
                          formikProps={formikProps}
                          required
                          label={$t('october')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_may'}
                          formikProps={formikProps}
                          required
                          label={$t('may')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_november'}
                          formikProps={formikProps}
                          required
                          label={$t('november')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_june'}
                          formikProps={formikProps}
                          required
                          label={$t('june')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'distributed_december'}
                          formikProps={formikProps}
                          required
                          label={$t('december')}
                        />
                      </Grid>
                    </Grid>
                  </Section>
                  {title === 'groundTemperatures' && (
                    <Section title={$t('unconditionedZones')} marginTop={20}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_january'}
                            formikProps={formikProps}
                            required
                            label={$t('january')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_july'}
                            formikProps={formikProps}
                            required
                            label={$t('july')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_february'}
                            formikProps={formikProps}
                            required
                            label={$t('february')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_august'}
                            formikProps={formikProps}
                            required
                            label={$t('august')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_march'}
                            formikProps={formikProps}
                            required
                            label={$t('march')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_september'}
                            formikProps={formikProps}
                            required
                            label={$t('september')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_april'}
                            formikProps={formikProps}
                            required
                            label={$t('april')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_october'}
                            formikProps={formikProps}
                            required
                            label={$t('october')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_may'}
                            formikProps={formikProps}
                            required
                            label={$t('may')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_november'}
                            formikProps={formikProps}
                            required
                            label={$t('november')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_june'}
                            formikProps={formikProps}
                            required
                            label={$t('june')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikWriteInput
                            field={'undistributed_december'}
                            formikProps={formikProps}
                            required
                            label={$t('december')}
                          />
                        </Grid>
                      </Grid>
                    </Section>
                  )}
                </>
              )}
            </>
          )}
        </>
      );
    };
  };

  private getInitialFormValues = (): IEPlusParametersFormValues => {
    const { ePlusParametersToEdit } = this.props;
    let title = '';

    if (ePlusParametersToEdit) {
      title = ePlusParametersToEdit.ref;
    }

    return {
      value: setFormInitialValue(ePlusParametersToEdit, 'value'),
      ...((title === 'groundTemperatures' || title === 'waterTemperature') && {
        distributed_january: setFormInitialValue(ePlusParametersToEdit, 'distributed_january'),
        distributed_february: setFormInitialValue(ePlusParametersToEdit, 'distributed_february'),
        distributed_march: setFormInitialValue(ePlusParametersToEdit, 'distributed_march'),
        distributed_april: setFormInitialValue(ePlusParametersToEdit, 'distributed_april'),
        distributed_may: setFormInitialValue(ePlusParametersToEdit, 'distributed_may'),
        distributed_june: setFormInitialValue(ePlusParametersToEdit, 'distributed_june'),
        distributed_july: setFormInitialValue(ePlusParametersToEdit, 'distributed_july'),
        distributed_august: setFormInitialValue(ePlusParametersToEdit, 'distributed_august'),
        distributed_september: setFormInitialValue(ePlusParametersToEdit, 'distributed_september'),
        distributed_october: setFormInitialValue(ePlusParametersToEdit, 'distributed_october'),
        distributed_november: setFormInitialValue(ePlusParametersToEdit, 'distributed_november'),
        distributed_december: setFormInitialValue(ePlusParametersToEdit, 'distributed_december'),
      }),
      ...(title === 'groundTemperatures' && {
        undistributed_january: setFormInitialValue(ePlusParametersToEdit, 'undistributed_january'),
        undistributed_february: setFormInitialValue(ePlusParametersToEdit, 'undistributed_february'),
        undistributed_march: setFormInitialValue(ePlusParametersToEdit, 'undistributed_march'),
        undistributed_april: setFormInitialValue(ePlusParametersToEdit, 'undistributed_april'),
        undistributed_may: setFormInitialValue(ePlusParametersToEdit, 'undistributed_may'),
        undistributed_june: setFormInitialValue(ePlusParametersToEdit, 'undistributed_june'),
        undistributed_july: setFormInitialValue(ePlusParametersToEdit, 'undistributed_july'),
        undistributed_august: setFormInitialValue(ePlusParametersToEdit, 'undistributed_august'),
        undistributed_september: setFormInitialValue(ePlusParametersToEdit, 'undistributed_september'),
        undistributed_october: setFormInitialValue(ePlusParametersToEdit, 'undistributed_october'),
        undistributed_november: setFormInitialValue(ePlusParametersToEdit, 'undistributed_november'),
        undistributed_december: setFormInitialValue(ePlusParametersToEdit, 'undistributed_december'),
      }),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IEPlusParametersFormValues> => {
    const { ePlusParametersToEdit } = this.props;
    let title = '';

    if (ePlusParametersToEdit) {
      title = ePlusParametersToEdit.ref;
    }

    return yup.object().shape({
      value: getYupStringValidation(true),
      ...((title === 'groundTemperatures' || title === 'waterTemperature') && {
        distributed_january: getYupNumberValidation(true),
        distributed_february: getYupNumberValidation(true),
        distributed_march: getYupNumberValidation(true),
        distributed_april: getYupNumberValidation(true),
        distributed_may: getYupNumberValidation(true),
        distributed_june: getYupNumberValidation(true),
        distributed_july: getYupNumberValidation(true),
        distributed_august: getYupNumberValidation(true),
        distributed_september: getYupNumberValidation(true),
        distributed_october: getYupNumberValidation(true),
        distributed_november: getYupNumberValidation(true),
        distributed_december: getYupNumberValidation(true),
      }),
      ...(title === 'groundTemperatures' && {
        undistributed_january: getYupNumberValidation(true),
        undistributed_february: getYupNumberValidation(true),
        undistributed_march: getYupNumberValidation(true),
        undistributed_april: getYupNumberValidation(true),
        undistributed_may: getYupNumberValidation(true),
        undistributed_june: getYupNumberValidation(true),
        undistributed_july: getYupNumberValidation(true),
        undistributed_august: getYupNumberValidation(true),
        undistributed_september: getYupNumberValidation(true),
        undistributed_october: getYupNumberValidation(true),
        undistributed_november: getYupNumberValidation(true),
        undistributed_december: getYupNumberValidation(true),
      }),
    }) as yup.ObjectSchema<IEPlusParametersFormValues>;
  };
}
