import { _includes } from 'libs/lodash';
import { $t } from 'utils/functions/translate';

export const getRunTooltip = (simulationGroup: { status: string; obsolete_front: boolean }): string => {
  const { status, obsolete_front } = simulationGroup;

  if (_includes(['empty', 'server_error'], status) || obsolete_front) {
    return $t('runSimulation');
  } else if (_includes(['success', 'failed'], status)) {
    return $t('resultsAreUpToDate');
  } else {
    return $t('simulationInProgress');
  }
};
