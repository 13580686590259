import { _map } from 'libs/lodash';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';
import { IOpening } from 'types/Obat/Opening';

export const enrichOpenings = (obatContents: IObatContents): IObatData => {
  const { opening: openings } = obatContents.data;

  const enrichedOpenings = _map(
    openings,
    (opening: IOpening): IOpening => {
      return { ...opening, conductance: opening.nominal_conductance * opening.conductance_ratio };
    }
  );

  return { ...obatContents.data, opening: enrichedOpenings };
};
