import { Plot } from 'components/Plot';
import { _includes } from 'libs/lodash';
import { _lowerCase } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { Data } from 'plotly.js';
import React, { PureComponent, ReactNode } from 'react';
import { IDegreeDaysData } from 'redux/restResources/detail/weather/reducer';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';

interface IProps {
  color: string;
  degreeDays: IDegreeDaysData['hdd' | 'cdd'];
  months: IDegreeDaysData['months'];
  title: string;
}

export class DegreeDaysPlot extends PureComponent<IProps> {
  public state: { [width: string]: number } = { width: 0 };

  public refCallback = (element: HTMLDivElement | null): void => {
    if (element) {
      this.setState({ width: element.getBoundingClientRect().width });
    }
  };

  public render(): ReactNode {
    const { title, months, degreeDays, color } = this.props;

    const dataBarChart: Data[] = [
      {
        type: 'bar',
        x: _map(months, (month: string): string => {
          const letterNumber = _includes($t(_lowerCase(month)), 'Jui') ? 4 : 3;

          return $t(_lowerCase(month)).slice(0, letterNumber);
        }),
        y: degreeDays,
        marker: {
          color,
          opacity: 0.8,
          line: {
            color,
            width: 1.5,
          },
        },
      },
    ];

    const config = { displayModeBar: false, responsive: true };

    const layout = {
      title,
      width: this.state.width,
      height: 280,
      margin: {
        l: 30,
        r: 10,
        t: 30,
        b: 50,
      },
    };

    return (
      <PlotContainer ref={this.refCallback}>
        <Plot data={dataBarChart} layout={layout} config={config} />
      </PlotContainer>
    );
  }
}

const PlotContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
