import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import { FormLabelAsterisk } from 'components/Forms/FormLabelAsterisk/FormLabelAsterisk';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { $tcc } from 'utils/functions/translate';

interface IProps {
  field: string;
  errorText?: string;
  withSpaceForError?: boolean;
  required?: boolean;
  noLabel?: boolean;
}

export type IWriteInputProps = IProps & Partial<StandardTextFieldProps>;

export class WriteInput extends PureComponent<IWriteInputProps> {
  public render(): ReactNode {
    const { errorText, withSpaceForError, field, required, noLabel, ...textFieldPropsWithoutName } = this.props;

    const textFieldProps = { ...textFieldPropsWithoutName, name: field };
    const label = noLabel ? (
      ''
    ) : (
      <span>
        {this.props.label || $tcc(field)} <FormLabelAsterisk required={required} />
      </span>
    );

    return (
      <TextInputContainer withSpaceForError={withSpaceForError}>
        <StyledTextField {...textFieldProps} label={label} error={!!errorText} helperText={errorText} />
      </TextInputContainer>
    );
  }
}

interface ITextContainerProps {
  withSpaceForError?: boolean;
}

const TextInputContainer = styled.div`
  height: ${(props: ITextContainerProps): string => (props.withSpaceForError ? 70 + 'px' : 'auto')};
  overflow: hidden;
`;

/* label width overflow on input so some texts is sometimes hidden. So we _reduce width to avoid overflow and force text wrap */
const StyledTextField = styled(TextField)`
  height: auto;

  label {
    width: calc(100% - 8px);
  }
` as typeof TextField;
