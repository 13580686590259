import { HourlyResultsPage } from 'components/SimulationGroup/HourlyResults/HourlyResultsPage';
import React, { PureComponent, ReactNode } from 'react';

import { IProps } from './HourlyResultsMono.container';

export class HourlyResultsMono extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeMonoSimulationGroup } = this.props;

    return <HourlyResultsPage routeSimulationGroup={routeMonoSimulationGroup} />;
  }
}
