// tslint:disable:no-any
import { _trimEnd } from 'libs/lodash';
import { $t } from 'utils/functions/translate';

import { isIncludedInString } from '../isIncludedInString';

export const renderValueElseDefault = (value: any, defaultValue: string | number): string | number => {
  if (value === 0) {
    return 0;
  }

  return value ? value : defaultValue;
};

export const renderTextValueElseDash = (value: any): string => {
  return renderValueElseDefault(value, '-').toString();
};

export const renderTextFloatElseDash = (value: number | null | ''): string => {
  return renderValueElseDefault(renderFloat(value), '-').toString();
};

export const renderValueElseEmpty = (value: any): string | number => {
  return renderValueElseDefault(value, '');
};

export const renderTextBoolean = (value: boolean): string => {
  return $t(value ? 'yes' : 'no');
};

export const renderFloat = (value: number | '' | null): string => {
  if (value === 0) {
    return '0';
  }
  if (!value) {
    return '';
  }

  const precision = 5;

  if (Math.abs(value) <= 10 ** -precision && Math.abs(value) >= 10 ** precision) {
    return value.toExponential(precision);
  } else {
    let strValue = value.toPrecision(precision);
    if (isIncludedInString(strValue, [['.']])) {
      strValue = _trimEnd(_trimEnd(strValue, '0'), '.');
    }

    return strValue;
  }
};
