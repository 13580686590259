import { connect } from 'react-redux';
import { openFormDrawerSagaAction, toggleFormResetAction } from 'redux/form/actions';
import { selectFormDrawerWidth } from 'redux/form/selectors';
import { fetchObatContentsSagaAction } from 'redux/obatResources/actions';
import { selectObatResourcesIdsList } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import {
  clearProjectionDataAction,
  clearProjectionParamsAction,
  fetchObatGeometriesSagaAction,
  fetchProjectionDataSagaAction,
  switchProjectionResultsViewAction,
  toggleProjectionTaskRunningStatusAction,
  updateProjectionTableSectionAction,
} from 'redux/restResources/detail/obat/actions';
import {
  selectIsProjectionTaskRunning,
  selectProjectionData,
  selectProjectionGeometryName,
  selectProjectionParams,
  selectProjectionResultsView,
} from 'redux/restResources/detail/obat/selectors';
import { fetchAllRouteResourcesSagaAction, toggleIsRefreshingRoutePageAction } from 'redux/routing/actions';
import { selectRouteResource } from 'redux/routing/selectors';
import { clearTaskAction } from 'redux/tasks/actions';
import { clearVariantContextIdSagaAction } from 'redux/variantContext/actions';
import { selectVariantContextId } from 'redux/variantContext/selectors';
import { getTaskKey } from 'utils/functions/getTaskKey';
import { fk } from 'utils/strings/formKeys';
import { dra } from 'utils/strings/requestActions';
import { orc, rrc } from 'utils/strings/resourceClasses';

import { ProjectionPage as Component } from './ProjectionPage.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  const routeObat = selectRouteResource(rrc.obat)(rootState);

  return {
    routeObat,
    projectionData: selectProjectionData(rootState),
    isProjectionTaskRunning: selectIsProjectionTaskRunning(rootState),
    projectionParams: selectProjectionParams(rootState),
    variantContextId: selectVariantContextId(rootState),
    taskKey: getTaskKey(dra.projection, routeObat.id),
    resultsView: selectProjectionResultsView(rootState),
    geometryName: selectProjectionGeometryName(rootState),
    variantOptions: selectObatResourcesIdsList(orc.variant)(rootState),
    formDrawerWidth: selectFormDrawerWidth(rootState),
  };
};

const mapDispatchToProps = {
  fetchProjectionData: fetchProjectionDataSagaAction,
  fetchObatGeometries: fetchObatGeometriesSagaAction,
  clearProjectionData: clearProjectionDataAction,
  clearProjectionParams: clearProjectionParamsAction,
  updateProjectionSection: updateProjectionTableSectionAction,
  toggleFormReset: toggleFormResetAction,
  openFormDrawer: openFormDrawerSagaAction,
  clearVariantContext: clearVariantContextIdSagaAction,
  fetchObatContents: fetchObatContentsSagaAction,
  switchProjectionResultsView: switchProjectionResultsViewAction,
  toggleProjectionTaskRunningStatus: toggleProjectionTaskRunningStatusAction,
  clearTask: clearTaskAction,
  toggleIsRefreshingRoutePage: toggleIsRefreshingRoutePageAction,
  fetchAllRouteResources: fetchAllRouteResourcesSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: {}) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openVariantContextForm: () => dispatchProps.openFormDrawer(fk.variantContext),
  openProjectionForm: () => dispatchProps.openFormDrawer(fk.projection),
});

export const ProjectionPage = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
