import React, { Component, ReactNode } from 'react';
import { fk } from 'utils/strings/formKeys';
import { IObatResourceClass } from 'utils/strings/resourceClasses';

import { getFormDrawer } from '../FormDrawer';
import { getObatResourceForm } from '../ObatResourceForm';

import { IProps } from './ObatResourceFormDrawer.container';

export class ObatResourceFormDrawer<IObatResourceFormValues, IObatResourceRequestParams> extends Component<
  IProps<IObatResourceFormValues, IObatResourceRequestParams>
> {
  public FormDrawer = getFormDrawer<IObatResourceClass>();
  public ObatResourceForm = getObatResourceForm<IObatResourceFormValues, IObatResourceRequestParams>();

  public render(): ReactNode {
    const { props, FormDrawer, ObatResourceForm } = this;
    const {
      formFocusRef,
      initialObatResource,
      resourceClass,
      getInitialFormValues,
      getRequestParams,
      getValidationSchema,
      renderSubForm,
      formDrawerWidth,
      forceSubmitDisabled,
      renderForm,
      title,
      isCopy,
    } = props;

    return (
      <FormDrawer
        formKey={isCopy ? fk.copy : fk.obatResource}
        width={formDrawerWidth}
        title={title ? title : initialObatResource ? initialObatResource.ref : undefined}
        formFocusRef={formFocusRef}
        resourceClass={resourceClass}
      >
        <ObatResourceForm
          isCopy={!!isCopy}
          initialObatResource={initialObatResource}
          renderForm={renderForm}
          getInitialFormValues={getInitialFormValues}
          getRequestParams={getRequestParams}
          getValidationSchema={getValidationSchema}
          resourceClass={resourceClass}
          forceSubmitDisabled={forceSubmitDisabled}
          renderSubForm={renderSubForm}
        />
      </FormDrawer>
    );
  }
}
