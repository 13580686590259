import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { createUserSagaAction } from 'redux/restResources/detail/organization/actions';

import { CreateAndInviteForm as Component } from './CreateAndInviteForm.component';

interface IOwnProps {
  initialEmail: string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

// tslint:disable-next-line:no-empty
const mapStateToProps = (_rootState: IRootState) => ({});

const mapDispatchToProps = {
  createUser: createUserSagaAction,
};

export const CreateAndInviteForm = connect(mapStateToProps, mapDispatchToProps)(Component);
