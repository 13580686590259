// tslint:disable:no-any

import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectMyFullName } from 'redux/restResources/detail/me/selectors';
import { resetAppAction } from 'redux/routing/actions';

import { IMenuItem, WithMenu as Component } from './WithMenu.component';

export interface IOwnProps {
  isCollapsed?: boolean;
  selectedPage: string;
  routeResourceId?: string;
  items: IMenuItem[];
  getMenuPageRoute: (page: any, resourceId: string) => string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    myFullName: selectMyFullName(rootState),
  };
};

const mapDispatchToProps = {
  resetApp: resetAppAction,
};

export const WithMenu = connect(mapStateToProps, mapDispatchToProps)(Component);
