import { SimpleCard } from 'components/Cards';
import { AlertIcon } from 'components/Icons';
import { SimulationResourceConfig } from 'components/SimulationGroup/SimulationResourceConfig';
import { SimulationVariantConfig } from 'components/SimulationGroup/SimulationVariantConfig';
import { _get } from 'libs/lodash';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $t } from 'utils/functions/translate';
import { IRestResourceClass, orc, rrc } from 'utils/strings/resourceClasses';

import { IProps } from './SimulationConfigCard.container';

export class SimulationConfigCard extends PureComponent<IProps> {
  public componentDidMount(): void {
    const { simulation, fetchSimulationGroupConfigOptions, fetchObatVariants } = this.props;
    fetchSimulationGroupConfigOptions(); // necessary to know whether current obat, geometry or weather has been deleted or not
    if (!!simulation && simulation.obat_id) {
      fetchObatVariants(simulation.obat_id); // necessary to know whether current variant has been deleted or not
    }
  }

  public render(): ReactNode {
    const { simulation, withStatus, withName } = this.props;

    if (!simulation) {
      return null;
    }

    const { weather_id, geometry_id, obat_id, variant, start, end, status } = simulation;

    return (
      <ConfigCard>
        <ConfigContainer>
          {!!withName && (
            <ConfigCell>
              <ConfigHeader>{$t('simulation')}</ConfigHeader>
              <ConfigContent>
                <span>{simulation.name}</span>
              </ConfigContent>
            </ConfigCell>
          )}
          <ConfigCell>
            <ConfigHeader>{$t(rrc.weather)}</ConfigHeader>
            <ConfigContent>
              <SimulationResourceConfig
                resourceClass={rrc.weather}
                resourceId={weather_id}
                isObsolete={this.isResourceObsolete(rrc.weather)}
              />
            </ConfigContent>
          </ConfigCell>
          <ConfigCell>
            <ConfigHeader>{$t(rrc.geometry)}</ConfigHeader>
            <ConfigContent>
              <SimulationResourceConfig
                resourceClass={rrc.geometry}
                resourceId={geometry_id}
                isObsolete={this.isResourceObsolete(rrc.geometry)}
              />
            </ConfigContent>
          </ConfigCell>
          <ConfigCell>
            <ConfigHeader>{$t(rrc.obat)}</ConfigHeader>
            <ConfigContent>
              <SimulationResourceConfig
                resourceClass={rrc.obat}
                resourceId={obat_id}
                isObsolete={this.isResourceObsolete(rrc.obat)}
              />
            </ConfigContent>
          </ConfigCell>
          <ConfigCell>
            <ConfigHeader>{$t(orc.variant)}</ConfigHeader>
            <ConfigContent>
              <SimulationVariantConfig variantRef={variant} obatId={obat_id} />
            </ConfigContent>
          </ConfigCell>
          <ConfigCell>
            <ConfigHeader>{$t('start')}</ConfigHeader>
            <ConfigContent>{renderTextValueElseDash(moment.utc(start).format('MM/YYYY'))}</ConfigContent>
          </ConfigCell>
          <ConfigCell>
            <ConfigHeader>{$t('end')}</ConfigHeader>
            <ConfigContent>{renderTextValueElseDash(moment.utc(end).format('MM/YYYY'))}</ConfigContent>
          </ConfigCell>
          {!!withStatus && (
            <ConfigCell>
              <ConfigHeader>{$t('status')}</ConfigHeader>
              <ConfigContent>
                <AlertIcon status={status} />
              </ConfigContent>
            </ConfigCell>
          )}
        </ConfigContainer>
      </ConfigCard>
    );
  }

  private isResourceObsolete = (resourceClass: IRestResourceClass): boolean => {
    const { routeMultiSimulationGroup, simulation } = this.props;

    return routeMultiSimulationGroup.obsolete_front && simulation && _get(simulation.obsolete, resourceClass).obsolete;
  };
}

const ConfigCard = styled(SimpleCard)`
  min-width: 50vw;
  margin-top: 0;
  padding: 4px;
  height: 38px; /* TODO: automate this value */
`;

const ConfigContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
`;

const ConfigCell = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfigContent = styled.div`
  margin-right: 12px;
`;

const ConfigHeader = styled(ConfigContent)`
  font-size: 110%;
`;
