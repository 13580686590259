import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Buttons/Button.component';
import {
  BottomCenterButtons,
  BottomLeftButtons,
  BottomRightButtons,
  CenterButtons,
} from 'components/Buttons/ButtonsContainers';
import { ITool, ToolsButton } from 'components/Buttons/ToolsButton/ToolsButton.component';
import { ToggleInput } from 'components/Forms/Inputs/SingleSelectInputs/ToggleInput';
import { OplusLoader } from 'components/Loader';
import { VariantContextButtons } from 'components/ObatResources/VariantContext/VariantContextButtons';
import { VariantContextForm } from 'components/ObatResources/VariantContext/VariantContextForm';
import { Page } from 'components/Page';
import { ThreejsViewer } from 'components/ThreejsViewer';
import { _size } from 'libs/lodash';
import { ProjectionForm } from 'pages/ObatMenu/Projection/ProjectionForm';
import { ProjectionTable } from 'pages/ObatMenu/Projection/ProjectionTable';
import React, { PureComponent, ReactNode } from 'react';
import { Box } from 'react-feather';
import { obatMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { ps } from 'utils/strings/progressStatus';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './ProjectionPage.container';

export class ProjectionPage extends PureComponent<IProps> {
  public componentDidMount(): void {
    const { fetchObatGeometries, routeObat, fetchObatContents } = this.props;
    fetchObatContents(routeObat.id);
    fetchObatGeometries();
  }

  public componentDidUpdate(prevProps: IProps): void {
    const { variantContextId, projectionParams, fetchProjectionData } = this.props;

    if (prevProps.variantContextId !== variantContextId && projectionParams.geometry) {
      fetchProjectionData(projectionParams);
    }
  }

  public componentWillUnmount(): void {
    const {
      clearProjectionData,
      clearProjectionParams,
      taskKey,
      clearTask,
      toggleProjectionTaskRunningStatus,
    } = this.props;
    clearTask(taskKey);
    toggleProjectionTaskRunningStatus(false);
    clearProjectionData();
    clearProjectionParams();
  }

  public render(): ReactNode {
    const {
      projectionData,
      openProjectionForm,
      isProjectionTaskRunning,
      resultsView,
      geometryName,
      variantOptions,
      formDrawerWidth,
    } = this.props;

    return (
      <Page
        pageTitle={$t('projection')}
        customPageRefresh={this.customPageRefresh}
        routingMenu={rm.obat}
        selectedPage={obatMenuPages.projection}
        isMenuDrawerCollapsed={true}
      >
        {isProjectionTaskRunning && <OplusLoader progress={ps.crossCheckingData} />}
        {!isProjectionTaskRunning && !projectionData && formDrawerWidth === 0 && (
          <CenterButtons isPulsing>
            <Button Icon={<Box size={12} />} text={$t('selectGeometry')} onClick={openProjectionForm} />
          </CenterButtons>
        )}
        {!isProjectionTaskRunning && projectionData && (
          <>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <ToggleContainer>
                  <ToggleInput
                    field={'view'}
                    value={resultsView}
                    onSelect={this.onToggleView}
                    options={[
                      {
                        value: 'threejs',
                        label: $t('3D'),
                      },
                      {
                        value: 'table',
                        label: $t('table'),
                      },
                    ]}
                  />
                </ToggleContainer>
              </Grid>
            </Grid>
            <BottomCenterButtons>
              <Button
                Icon={<Box size={12} />}
                tooltipProps={{ content: $t('selectGeometry'), placement: 'top' }}
                text={geometryName}
                onClick={openProjectionForm}
              />
            </BottomCenterButtons>
          </>
        )}
        {!isProjectionTaskRunning && projectionData && resultsView === 'table' && <ProjectionTable />}
        {!isProjectionTaskRunning && projectionData && resultsView === 'threejs' && (
          <ViewerContainer>
            <ThreejsViewer threejsData={projectionData.threejs} />
          </ViewerContainer>
        )}

        <BottomLeftButtons>
          <VariantContextButtons />
        </BottomLeftButtons>

        {_size(variantOptions) > 0 && (
          <BottomRightButtons formDrawerWidth={formDrawerWidth}>
            <ToolsButton tools={this.getTools()} />
          </BottomRightButtons>
        )}

        <ProjectionForm />
        <VariantContextForm />
      </Page>
    );
  }

  private getTools = (): ITool[] => {
    const { openVariantContextForm } = this.props;

    return [{ text: $t('applyVariant'), onClick: openVariantContextForm }];
  };

  private onToggleView = (_field: string, value: string): void => {
    const { switchProjectionResultsView } = this.props;
    switchProjectionResultsView(value);
  };

  private customPageRefresh = (): void => {
    const {
      fetchObatGeometries: listGeometriesForProjection,
      fetchProjectionData,
      projectionParams,
      toggleFormReset,
    } = this.props;

    listGeometriesForProjection();
    toggleFormReset(true);
    if (projectionParams.geometry) {
      fetchProjectionData(projectionParams);
    }
  };
}

const ViewerContainer = styled.div`
  margin-top: -56px;
  width: 100%;
  height: 100%;
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
`;
