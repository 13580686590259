import Grid from '@material-ui/core/Grid';
import { SubFormCard } from 'components/Cards';
import { CloseSubFormButton } from 'components/Forms/Buttons/SubFormButtons';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceForm as getForm } from 'components/Forms/ObatResourceForm';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { _keys } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { getYupStringValidation } from 'libs/yup';
import React, { PureComponent, ReactNode } from 'react';
import { IModificationValueFieldDescriptor } from 'redux/enums/selectors';
import { IModificationRequestParams, IModificationValue } from 'types/Obat/Modification';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t, $tcc } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './ModificationForm.container';

export interface IModificationFormValues {
  filter_records: string;
  method: string;
  table_ref: string;
  variant: string;
  weak_ref: string;
  value: string | number;
}

const Form = getForm<IModificationFormValues, IModificationRequestParams>();

export class ModificationForm extends PureComponent<IProps> {
  public render(): ReactNode {
    const { modificationToEdit, close, titleIndex } = this.props;

    const modificationFormTitle = modificationToEdit
      ? `${titleIndex}. ${modificationToEdit.weak_ref}`
      : `${$t('modification')} ${titleIndex}`;

    return (
      <SubFormCard>
        {!!close && <CloseSubFormButton onClick={close} />}
        <Section noDivider title={modificationFormTitle} marginTop={-10}>
          <Form
            initialObatResource={modificationToEdit}
            renderForm={this.renderForm()}
            getInitialFormValues={this.getInitialFormValues}
            getValidationSchema={this.getValidationSchema}
            getRequestParams={this.getRequestParams}
            resourceClass={orc.modification}
          />
        </Section>
      </SubFormCard>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IModificationFormValues>): ReactNode => {
      const { tableRefOptions } = this.props;
      const { table_ref } = formikProps.values;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput field={'weak_ref'} formikProps={formikProps} label={$t('name')} required />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'table_ref'}
              formikProps={formikProps}
              options={tableRefOptions}
              onSelect={this.onTableRefSelect(formikProps)}
              label={$t('objectType')}
              renderOptionLabel={$tcc}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput field={'filter_records'} formikProps={formikProps} label={$t('objectFilter')} multiline />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'method'}
              formikProps={formikProps}
              options={this.getMethodOptions(table_ref)}
              onSelect={this.onMethodSelect(formikProps)}
              renderOptionLabel={$tEnums}
              required
            />
          </Grid>
          {this.renderValueForm(formikProps)}
        </Grid>
      );
    };
  };

  private renderValueForm = (formikProps: FormikProps<IModificationFormValues>): ReactNode => {
    const { table_ref, method } = formikProps.values;
    const { selectVariantValue } = this.props;
    const valueFieldsDescriptors = selectVariantValue(table_ref, method);

    return _map(
      valueFieldsDescriptors,
      (fieldDescriptor: IModificationValueFieldDescriptor, fieldName: string): ReactNode => {
        return (
          <Grid item xs={12} key={fieldName}>
            {this.renderValueFieldInput(
              // @ts-ignore
              fieldName,
              fieldDescriptor,
              formikProps
            )}
          </Grid>
        );
      }
    );
  };

  private renderValueFieldInput = (
    field: keyof IModificationFormValues,
    fieldDescriptor: IModificationValueFieldDescriptor,
    formikProps: FormikProps<IModificationFormValues>
  ): ReactNode => {
    const { selectObatRefsList } = this.props;
    const { type, target_table_ref } = fieldDescriptor;
    switch (type) {
      case 'LinkField': {
        const optionValues = target_table_ref ? selectObatRefsList(target_table_ref) : [];

        return <FormikDropdownInput field={field} formikProps={formikProps} options={optionValues} required />;
      }
      case 'Float': {
        return <FormikWriteInput field={field} formikProps={formikProps} required />;
      }
      case 'FilterSourceCodeField': {
        return <FormikWriteInput field={field} formikProps={formikProps} required multiline />;
      }
      case 'Constant': {
        return null;
      }
    }
  };

  private getInitialFormValues = (): IModificationFormValues => {
    const { modificationToEdit, variant } = this.props;

    return {
      variant: variant.ref,
      filter_records: setFormInitialValue(modificationToEdit, 'filter_records'),
      method: setFormInitialValue(modificationToEdit, 'method'),
      table_ref: setFormInitialValue(modificationToEdit, 'table_ref'),
      value: setFormInitialValue(modificationToEdit, 'value.value'),
      weak_ref: setFormInitialValue(modificationToEdit, 'weak_ref'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IModificationFormValues> => {
    const { selectVariantValue } = this.props;

    return yup.object().shape({
      weak_ref: getYupStringValidation(true, true),
      filter_records: getYupStringValidation(),
      table_ref: getYupStringValidation(true),
      method: getYupStringValidation(true),
      value: yup.string().when(['table_ref', 'method'], {
        is: (tableRef: IModificationFormValues['table_ref'], method: IModificationFormValues['method']): boolean => {
          const valueFieldsDescriptors = selectVariantValue(tableRef, method);

          return _reduce(
            valueFieldsDescriptors,
            (required: boolean, field: IModificationValueFieldDescriptor) => required || field.type !== 'Constant',
            false
          );
        },
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
    }) as yup.ObjectSchema<IModificationFormValues>;
  };

  private getMethodOptions = (tableRef: IModificationFormValues['table_ref']) => {
    if (!tableRef) {
      return [];
    }

    return this.props.selectMethodOptions(tableRef);
  };

  private onTableRefSelect = (formikProps: FormikProps<IModificationFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      formikProps.setValues({
        ...formikProps.values,
        table_ref: selectedValue,
        method: '',
        value: '',
      });
    };
  };

  private onMethodSelect = (formikProps: FormikProps<IModificationFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      formikProps.setValues({
        ...formikProps.values,
        method: selectedValue,
        value: '',
      });
    };
  };

  private getRequestParams = (formValues: IModificationFormValues): IModificationRequestParams => {
    const { table_ref, method } = formValues;
    const { selectVariantValue } = this.props;
    const valueFieldsDescriptors = selectVariantValue(table_ref, method);

    const { modificationToEdit } = this.props;

    const value = _reduce(
      _keys(valueFieldsDescriptors),
      (modificationValue: IModificationValue, fieldName: string): IModificationValue => {
        // @ts-ignore
        return { ...modificationValue, [fieldName]: formValues[fieldName] };
      },
      {}
    );

    /* 1. get filteredFinalFormValues */
    const initialFormValues = this.getInitialFormValues();
    const filteredFormValues = modificationToEdit ? filterEditedValues(initialFormValues, formValues) : formValues;

    /* 2. get requestParamsFields */
    const requestParamsFields = { ...filteredFormValues, value } as IModificationRequestParams;
    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams;
  };
}
