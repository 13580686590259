import Grid from '@material-ui/core/Grid';
import { FormikSwitchInput } from 'components/Forms/Inputs/BooleanInputs/FormikSwitchInput';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikRadioInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikRadioInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getRestResourceFormDrawer } from 'components/Forms/RestResourceFormDrawer';
import { FormikProps } from 'formik';
import { _omit } from 'libs/lodash';
import { getYupBooleanValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IWeatherRequestParams } from 'types/Weather/Weather';
import { fdw } from 'utils/configs/drawerWidths';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './WeatherForm.container';

export interface IWeatherFormValues {
  project: string;
  name: string;
  comment: string;
  format: string;
  fromTemplate: boolean;
  template_category: string;
  template_ref: string;
}

const RestResourceFormDrawer = getRestResourceFormDrawer<IWeatherFormValues, IWeatherRequestParams>();

export class WeatherForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public componentDidMount = () => {
    const { fetchWeathersTemplates } = this.props;
    fetchWeathersTemplates();
  };

  public render(): ReactNode {
    return (
      <RestResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.small}
        resourceClass={rrc.weather}
        getRequestParams={this.getRequestParams}
        getCreationSuccessLandingPageUrl={this.props.getCreationSuccessLandingPageUrl}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IWeatherFormValues>): ReactNode => {
      const { selectTemplateOptions, weatherToEdit } = this.props;
      const { format, fromTemplate, template_category } = formikProps.values;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput field={'name'} formikProps={formikProps} required inputRef={this.formFocusRef} />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput field={'comment'} formikProps={formikProps} multiline />
          </Grid>
          {/*** Temporary unavailable ***/}
          <Grid item xs={12}>
            <FormikRadioInput
              field={'format'}
              formikProps={formikProps}
              options={[
                { value: 'generic', label: $t('generic') },
                { value: 'openergy_historical', label: $t('historical') },
              ]}
              renderOptionLabel={$t}
              isDisabled={!!weatherToEdit}
              onSelect={this.onFormatSelect(formikProps)}
            />
          </Grid>
          {!weatherToEdit && format === 'generic' && (
            <Grid item xs={12}>
              <FormikSwitchInput field={'fromTemplate'} formikProps={formikProps} />
            </Grid>
          )}
          {((!weatherToEdit && format === 'generic' && !!fromTemplate) ||
            (!!weatherToEdit && weatherToEdit.template_ref)) && (
            <>
              <Grid item xs={12}>
                <FormikDropdownInput
                  field={'template_category'}
                  formikProps={formikProps}
                  options={[
                    { value: 'template_weather_iwec', label: 'IWEC' },
                    { value: 'template_weather_meteonorm', label: 'METEONORM' },
                  ]}
                  label={$t('source')}
                  isDisabled={!!weatherToEdit && weatherToEdit.template_category}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikDropdownInput
                  field={'template_ref'}
                  formikProps={formikProps}
                  options={selectTemplateOptions(template_category)}
                  label={$t('location')}
                  isDisabled={!!weatherToEdit && weatherToEdit.template_ref}
                  required
                />
              </Grid>
            </>
          )}
        </Grid>
      );
    };
  };

  private onFormatSelect = (formikProps: FormikProps<IWeatherFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      formikProps.setValues({
        ...formikProps.values,
        format: selectedValue,
        fromTemplate: selectedValue === 'openergy_historical' ? false : formikProps.values.fromTemplate,
      });
    };
  };

  private getInitialFormValues = (): IWeatherFormValues => {
    const { weatherToEdit, routeProject } = this.props;

    return {
      project: routeProject.id,
      name: setFormInitialValue(weatherToEdit, 'name'),
      format: setFormInitialValue(weatherToEdit, 'format', 'generic'),
      comment: setFormInitialValue(weatherToEdit, 'comment'),
      fromTemplate: false,
      template_category: setFormInitialValue(weatherToEdit, 'template_weather_iwec'),
      template_ref: setFormInitialValue(weatherToEdit, 'template_weather_ref'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IWeatherFormValues> => {
    const { weatherToEdit } = this.props;

    return yup.object().shape({
      name: getYupStringValidation(true),
      comment: getYupStringValidation(),
      format: getYupStringValidation().oneOf(['generic', 'openergy_historical']),
      fromTemplate: getYupBooleanValidation(),
      template_ref: yup.string().when(['fromTemplate'], {
        is: (fromTemplate: boolean) => !weatherToEdit && !!fromTemplate,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
    }) as yup.ObjectSchema<IWeatherFormValues>;
  };

  private getRequestParams = (formValues: IWeatherFormValues): IWeatherRequestParams => {
    const { weatherToEdit } = this.props;
    const { fromTemplate } = formValues;

    const initialFormValues = this.getInitialFormValues();
    const filteredFinalFormValues = weatherToEdit ? filterEditedValues(initialFormValues, formValues) : formValues;

    const paramsToOmit =
      weatherToEdit || !fromTemplate ? ['fromTemplate', 'template_ref', 'template_category'] : ['fromTemplate'];

    const requestParamsFields = _omit(filteredFinalFormValues, paramsToOmit);
    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams as IWeatherRequestParams;
  };
}
