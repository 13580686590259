import { ModalFrame } from 'components/Modals/ModalFrame';

import React, { PureComponent, ReactNode } from 'react';
import { $t } from 'utils/functions/translate';

import theme from 'style/theme';
import styled from 'styled-components';
import { getRandomItem } from 'utils/functions/random/getRandomIndex';
import { IProps } from './LogoutModal.container';

export class LogoutModal extends PureComponent<IProps> {
  private gifLink = getRandomItem(gifLinks);

  public render(): ReactNode {
    const { logoutTimer, logoutCause } = this.props;

    const Title = <TitleContainer>{$t('logout')}</TitleContainer>;

    const Content = (
      <ContentContainer>
        <div>{$t(logoutCause === 'kickedOut' ? 'kickedOutSessionAlert' : 'expiredSessionAlert')}</div>
        <GifContainer>
          <img src={this.gifLink} width={400} />
        </GifContainer>
        <div>{$t('logoutTimerAlert', { timer: logoutTimer })}</div>
      </ContentContainer>
    );

    return <ModalFrame isOpen={logoutTimer > 0} Content={Content} Title={Title} maxWidth={'md'} />;
  }
}

const TitleContainer = styled.div`
  color: ${theme.colors.error};
`;

const ContentContainer = styled.div`
  background-color: ${theme.colors.error};
  color: white;
  padding: 8px;
  border-radius: ${theme.borderRadius.small};
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GifContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
  img {
    max-height: 400px;
    max-width: 400px;
  }
`;

const gifLinks = [
  'https://media.giphy.com/media/KctrWMQ7u9D2du0YmD/giphy.gif',
  'https://media.giphy.com/media/YybKRCSHL1OBW/giphy.gif',
  'https://media.giphy.com/media/vxNCVEe0PI9A3YVJEX/giphy.gif',
  'https://media.giphy.com/media/rrLt0FcGrDeBq/giphy.gif',
  'https://media.giphy.com/media/3ohs7KViF6rA4aan5u/giphy.gif',
  'https://media.giphy.com/media/JDTsqJhvLOq9G/giphy.gif',
  'https://media.giphy.com/media/qEBlgZpZWHihO/giphy.gif',
  'https://media.giphy.com/media/42D3CxaINsAFemFuId/giphy.gif',
  'https://media.giphy.com/media/7DzlajZNY5D0I/giphy.gif',
  'https://media.giphy.com/media/VyDsyIrOLixeU/giphy.gif',
  'https://media.giphy.com/media/l3vRbyZfZkTv1GzbG/giphy.gif',
  'https://media.giphy.com/media/l0Iyc00kML4EkVp6M/giphy.gif',
  'https://media.giphy.com/media/GB0lKzzxIv1te/giphy.gif',
  'https://media.giphy.com/media/3o6EhGvKschtbrRjX2/giphy.gif',
];
