import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectMyFullName } from 'redux/restResources/detail/me/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { WithOrganizationMenu as WithOrganizationMenuComponent } from './WithOrganizationMenu.component';

export interface IOwnProps {
  isCollapsed?: boolean;
  selectedPage: string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    myFullName: selectMyFullName(rootState),
    routeOrganization: selectRouteResource(rrc.organization)(rootState),
  };
};

const mapDispatchToProps = {};

export const WithOrganizationMenu = connect(mapStateToProps, mapDispatchToProps)(WithOrganizationMenuComponent);
