import arrayMove from 'array-move';
import { DragAndDropCard } from 'components/Cards/DragAndDropCard.component';
import { FormikProps } from 'formik';
import { _map } from 'libs/lodash';
import { getYupStringArrayValidation } from 'libs/yup';
import React, { PureComponent, ReactNode } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { convertFormValuesEmptyStringToNull } from 'utils/functions/convertFormValuesEmptyStringToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import * as yup from 'yup';

import { getForm } from '../Form';

import { IProps } from './ReorderForm.container';

export interface IReorderFormValues {
  sortedRefs: string[];
}

export interface IReorderRequestParams {
  ref: string;
  sort_index: number;
}

export interface IResourceToReorder {
  id: string;
  weak_ref: string;
}

interface IState {
  resources: IResourceToReorder[];
}

export class ReorderForm<IResource extends IResourceToReorder> extends PureComponent<IProps<IResource>, IState> {
  public state: IState = {
    resources: this.props.resourcesToReorder,
  };

  public Form = getForm<IReorderFormValues>();

  public render(): ReactNode {
    const { Form, props } = this;
    const { isSubmitting, editErrors } = props;

    return (
      <Form
        renderForm={this.renderForm}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        onSubmit={this.onSubmit}
        isSubmitting={isSubmitting}
        formApiErrors={editErrors}
      />
    );
  }

  public renderForm = (formikProps: FormikProps<IReorderFormValues>): ReactNode => {
    const { getCardTitle } = this.props;

    const SortableItem = SortableElement(({ value }: { value: IResource }) => {
      return <DragAndDropCard text={getCardTitle ? getCardTitle(value) : value.weak_ref} />;
    });

    const SortableList = SortableContainer(({ items }: { items: IResource[] }) => {
      return (
        <div>
          {_map(items, (resource: IResource, index: number) => (
            <SortableItem key={resource.id} index={index} value={resource} />
          ))}
        </div>
      );
    });

    const onSortEnd = ({ oldIndex, newIndex }: { newIndex: number; oldIndex: number }) => {
      const sortedResources = arrayMove(this.state.resources, oldIndex, newIndex);

      this.setState((state: IState): IState => ({ ...state, resources: sortedResources }));

      formikProps.setFieldValue(
        'sortedRefs',
        _map(sortedResources, (resource: IResource): string => resource.id)
      );
    };

    // @ts-ignore
    return <SortableList items={this.state.resources} onSortEnd={onSortEnd} lockAxis={'y'} />;
  };

  private getInitialFormValues = (): IReorderFormValues => {
    const { resourcesToReorder } = this.props;

    return {
      sortedRefs: _map(resourcesToReorder, (resourceToSort: IResource): string => resourceToSort.id),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IReorderFormValues> => {
    return yup.object().shape({
      sortedRefs: getYupStringArrayValidation(true),
    }) as yup.ObjectSchema<IReorderFormValues>;
  };

  private getRequestParams = (formValues: IReorderFormValues): IReorderRequestParams[] => {
    const convertedFormValues = convertFormValuesEmptyStringToNull(formValues);

    return _map(
      convertedFormValues.sortedRefs,
      (id: string, index: number): IReorderRequestParams => {
        return { ref: id, sort_index: index };
      }
    );
  };

  private onSubmit = (formValues: IReorderFormValues): void => {
    const { editMultiObatResources, routeObat, resourceClass } = this.props;

    const filteredRequestParamsList = filterEditedValues(
      this.getRequestParams(this.getInitialFormValues()),
      this.getRequestParams(formValues)
    );

    editMultiObatResources(resourceClass, filteredRequestParamsList, routeObat.id);
  };
}
