export const envUrls = {
  local: 'http://localhost:8000/api/v1',
  staging: 'https://test-ossplatform.openergy.fr/api/v1',
  prod: 'https://oplus-back.openergy.fr/api/v1',
  doc: 'https://openergy.zendesk.com/hc/fr/',
};

export const environment = {
  API_URL: process.env.REACT_APP_API_URL || envUrls.staging,
};
