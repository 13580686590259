import { connect } from 'react-redux';
import { closeRestResourceCommitmentsModalAction } from 'redux/modals/restResourceCommitmentsModal/actions';
import {
  selectIsRestResourceCommitmentsModalOpen,
  selectRestResourceCommitments,
  selectRestResourceCommitmentTitle,
} from 'redux/modals/restResourceCommitmentsModal/selectors';
import { IRootState } from 'redux/reducer';

import { RestResourceCommitmentsModal as RestResourceCommitmentsModalComponent } from './RestResourceCommitmentsModal.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  return {
    isOpen: selectIsRestResourceCommitmentsModalOpen(rootState),
    commitments: selectRestResourceCommitments(rootState),
    commitmentTitle: selectRestResourceCommitmentTitle(rootState),
  };
};

const mapDispatchToProps = {
  closeRestResourceCommitmentsModal: closeRestResourceCommitmentsModalAction,
};

export const RestResourceCommitmentsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(RestResourceCommitmentsModalComponent);
