import { RestResourcesRequests } from 'api/requests/RestResourcesRequests';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequestCallbackSuccessAction } from 'redux/apiRequests/actions';
import { apiRequestSaga } from 'redux/apiRequests/sagas';
import { selectApiRequestErrors } from 'redux/apiRequests/selectors';
import { downloadFromAzureBlobSaga, uploadFileToAzureSaga } from 'redux/azure/sagas';
import { importGeometrySagaAction } from 'redux/restResources/detail/geometry/actions';
import { fetchSingleRouteResourceSaga } from 'redux/routing/sagas';
import { selectRouteResource } from 'redux/routing/selectors';
import { navigateRoute } from 'routing/navigateRoute';
import { geometryMenuPages, getGeometryMenuPageRoute } from 'routing/routes';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { getExportFileName } from 'utils/functions/getExportFileName';
import { dr } from 'utils/strings/detailRoutes';
import { hm } from 'utils/strings/httpMethods';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import {
  DOWNLOAD_FLOORPLAN_SAGA_ACTION,
  IDownloadFloorplanSagaAction,
  IUploadFloorplanSagaAction,
  toggleFloorplanJustSavedAction,
  updateFloorplanAction,
  UPLOAD_FLOORPLAN_SAGA_ACTION,
} from './actions';

export function* downloadFloorplanSaga(payload: IDownloadFloorplanSagaAction['payload']): SagaIterator {
  const { floorspaceId } = payload;
  const apiRequestKey = getDetailApiRequestKey(dra.downloadFloorplan, floorspaceId);
  const apiUrlParams = {
    resourceClass: rrc.floorspace,
    resourceId: floorspaceId,
    detailRoute: dr.read_blob_url,
  };

  const blobUrlCallResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const blobUrl = blobUrlCallResult.blob_url;
    const outputFormat = 'json';
    const floorplanBlob = yield call(downloadFromAzureBlobSaga, blobUrl, outputFormat);
    if (floorplanBlob) {
      yield put(updateFloorplanAction(floorplanBlob));
      yield put(apiRequestCallbackSuccessAction(apiRequestKey));
    }
  }
}

export function* downloadFloorplanActionSaga(action: IDownloadFloorplanSagaAction): SagaIterator {
  yield call(downloadFloorplanSaga, action.payload);
}

export function* uploadFloorplanSaga(payload: IUploadFloorplanSagaAction['payload']): SagaIterator {
  const { floorspaceId, floorplan, convert3DNext } = payload;
  const apiRequestKey = getDetailApiRequestKey(dra.uploadFloorplan, floorspaceId);
  const apiUrlParams = {
    resourceClass: rrc.floorspace,
    resourceId: floorspaceId,
    detailRoute: dr.upload_url,
  };

  const blobUrlCallResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const blobUrl = blobUrlCallResult.blob_url;
    const fileContentBlob = new Blob([JSON.stringify(floorplan)], { type: 'text/plain' });
    yield call(uploadFileToAzureSaga, blobUrl, fileContentBlob);
    yield put(updateFloorplanAction(floorplan));

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
    yield put(toggleFloorplanJustSavedAction(true));

    /* update geometry so that the status has changed (empty now)*/
    yield call(fetchSingleRouteResourceSaga, rrc.geometry);

    yield delay(1000);
    if (convert3DNext) {
      const routeProject = yield select(selectRouteResource(rrc.project));
      const routeGeometry = yield select(selectRouteResource(rrc.geometry));
      const route = getGeometryMenuPageRoute(geometryMenuPages.viewer3D, routeProject.id, routeGeometry.id);
      yield call(navigateRoute, route);
      yield put(
        importGeometrySagaAction({
          import_format: 'floorspace',
          file_name: getExportFileName(routeGeometry.name, 'flo'),
        })
      );
    }

    yield delay(2000);
    yield put(toggleFloorplanJustSavedAction(false));
  }
}

export function* uploadFloorplanActionSaga(action: IUploadFloorplanSagaAction): SagaIterator {
  yield call(uploadFloorplanSaga, action.payload);
}

export function* floorspaceActionsSagas(): Generator {
  yield takeLatest(DOWNLOAD_FLOORPLAN_SAGA_ACTION, downloadFloorplanActionSaga);
  yield takeLatest(UPLOAD_FLOORPLAN_SAGA_ACTION, uploadFloorplanActionSaga);
}
