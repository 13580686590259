// tslint:disable:no-any

import Tooltip from '@material-ui/core/Tooltip';
import React, { PureComponent, ReactNode } from 'react';

export type ITooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export interface ITooltipProps {
  content?: any;
  placement: ITooltipPlacement;
  interactive?: boolean;
}

const TooltipInner = React.forwardRef((props: any, ref: any) => (
  // @ts-ignore
  <span ref={ref} {...props} />
));

const tooltipInnerRefCallback = (_element: HTMLSpanElement | null): void => {
  return;
};

export class SimpleTooltip extends PureComponent<ITooltipProps> {
  public render(): ReactNode {
    const { children, content, placement, interactive } = this.props;

    if (!content) {
      return children;
    }

    return (
      <Tooltip title={content} placement={placement} interactive={!!interactive}>
        <TooltipInner ref={tooltipInnerRefCallback}>{children}</TooltipInner>
      </Tooltip>
    );
  }
}
