import { _forEach, _map } from 'libs/lodash';
import { IModification } from 'types/Obat/Modification';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';
import { IVariant } from 'types/Obat/Variant';

import { getObatResourceIndex } from './getObatResource';

export const enrichVariants = (obatContents: IObatContents): IObatData => {
  const { modification: modifications, variant: variants } = obatContents.data;

  let enrichedVariants = _map(
    variants,
    (variant: IVariant): IVariant => {
      return { ...variant, modifications_contents: [] };
    }
  );

  _forEach(modifications, (modification: IModification): void => {
    // attach variant
    const variantIndex = getObatResourceIndex(obatContents, 'variant', modification.variant);

    enrichedVariants = _map(
      enrichedVariants,
      (variant: IVariant, index: number): IVariant => {
        if (index === variantIndex) {
          return {
            ...variant,
            modifications_contents: [...variant.modifications_contents, modification],
          };
        }

        return variant;
      }
    );
  });

  return { ...obatContents.data, variant: enrichedVariants };
};
