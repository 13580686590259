// tslint:disable:no-any
import { IQueryParams } from 'redux/restResources/generic/actions';
import { IRestResource, IRestResourcesResponse } from 'types/RestResource';
import { ITokens } from 'types/Tokens';
import { getRestApiUrl, IRestApiUrlParams } from 'utils/functions/getApiUrl/getRestApiUrl/getRestApiUrl';
import { dr } from 'utils/strings/detailRoutes';
import { hm, IHttpMethod } from 'utils/strings/httpMethods';
import { gra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

export const RestResourcesRequests = {
  retrieve: async (accessToken: ITokens['access'], restApiUrlParams: IRestApiUrlParams): Promise<IRestResource> => {
    const apiUrl = getRestApiUrl(accessToken, gra.retrieve, restApiUrlParams);

    return apiUrl.get().json();
  },

  list: async (
    accessToken: ITokens['access'],
    restApiUrlParams: IRestApiUrlParams,
    queryParams: IQueryParams = {}
  ): Promise<IRestResourcesResponse> => {
    const apiUrl = getRestApiUrl(accessToken, gra.list, restApiUrlParams);

    const apiUrlWithQueryParams = apiUrl.query(queryParams, true);

    return apiUrlWithQueryParams.get().json();
  },

  create: async (
    accessToken: ITokens['access'],
    restApiUrlParams: IRestApiUrlParams,
    requestParams: any
  ): Promise<IRestResource> => {
    const { resourceClass, parentResourceTag } = restApiUrlParams;

    if (resourceClass === rrc.simulation) {
      const multiSimulationGroupTag = parentResourceTag;
      const apiUrlParams = { ...multiSimulationGroupTag, detailRoute: dr.add_simulation };

      // @ts-ignore
      return RestResourcesRequests.detailRoute(accessToken, apiUrlParams, hm.post, {}, requestParams);
    }

    const apiUrl = getRestApiUrl(accessToken, gra.create, restApiUrlParams);

    return apiUrl.post(requestParams).json();
  },

  copy: async (
    accessToken: ITokens['access'],
    restApiUrlParams: IRestApiUrlParams,
    requestParams: string
  ): Promise<IRestResource> => {
    const { resourceClass, parentResourceTag } = restApiUrlParams;

    if (resourceClass === rrc.simulation) {
      const multiSimulationGroupTag = parentResourceTag;
      const apiUrlParams = { ...multiSimulationGroupTag, detailRoute: dr.copy_simulation };

      // @ts-ignore
      return RestResourcesRequests.detailRoute(accessToken, apiUrlParams, hm.post, {}, requestParams);
    }

    const apiUrl = getRestApiUrl(accessToken, gra.copy, restApiUrlParams);

    return apiUrl.post(requestParams).json();
  },

  edit: async (
    accessToken: ITokens['access'],
    restApiUrlParams: IRestApiUrlParams,
    requestParams: any
  ): Promise<IRestResource> => {
    const { resourceClass, resourceId, parentResourceTag } = restApiUrlParams;

    if (resourceClass === rrc.simulation) {
      const multiSimulationGroupTag = parentResourceTag;
      const simulationId = resourceId;

      const apiUrlParams = { ...multiSimulationGroupTag, detailRoute: dr.update_simulation };

      return RestResourcesRequests.detailRoute(
        accessToken,
        // @ts-ignore
        apiUrlParams,
        hm.patch,
        {},
        { ...requestParams, id: simulationId }
      );
    }

    const apiUrl = getRestApiUrl(accessToken, gra.edit, restApiUrlParams);

    return apiUrl.patch(requestParams).json();
  },

  delete: async (accessToken: ITokens['access'], restApiUrlParams: IRestApiUrlParams): Promise<IRestResource> => {
    const { resourceClass, resourceId, parentResourceTag } = restApiUrlParams;

    if (resourceClass === rrc.simulation) {
      const multiSimulationGroupTag = parentResourceTag;
      const simulationId = resourceId;

      const apiUrlParams = { ...multiSimulationGroupTag, detailRoute: dr.delete_simulation };
      const queryParams = { id: simulationId };

      // @ts-ignore
      return RestResourcesRequests.detailRoute(accessToken, apiUrlParams, hm.delete, queryParams, {});
    }

    const apiUrl = getRestApiUrl(accessToken, gra.delete, restApiUrlParams);

    return apiUrl.delete().res();
  },

  detailRoute: async (
    accessToken: ITokens['access'],
    restApiUrlParams: IRestApiUrlParams,
    httpMethod: IHttpMethod,
    queryParams: { [param: string]: string | number } = {},
    requestParams: object = {},
    outputFormat: 'json' | 'csv' | 'empty' = 'json'
  ): Promise<any> => {
    const apiUrl = getRestApiUrl(accessToken, gra.detailRoute, restApiUrlParams);

    const apiUrlWithQueryParams = apiUrl.query(queryParams, true);

    if (httpMethod === hm.delete) {
      return apiUrlWithQueryParams.delete().res();
    }

    let wretcher;

    switch (httpMethod) {
      case hm.get:
        wretcher = apiUrlWithQueryParams.get();
        break;
      case hm.patch:
        wretcher = apiUrlWithQueryParams.patch(requestParams);
        break;
      case hm.put:
        wretcher = apiUrlWithQueryParams.put(requestParams);
        break;
      case hm.post:
        wretcher = apiUrlWithQueryParams.post(requestParams);
        break;
      default:
        wretcher = apiUrlWithQueryParams.get();
    }

    const promise =
      outputFormat === 'json' ? wretcher.json() : outputFormat === 'csv' ? wretcher.text() : wretcher.res();

    /* WARNING: When the output format is not correct it fails silently, need to log promise to see the error */
    /* TODO: catch response syntax errors properly (use .catch(err => {...})).  */

    return promise;
  },
};
