import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { closeFormDrawerAction } from 'redux/form/actions';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { fetchObatVariantsSagaAction } from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectSimulationGroupConfigOptions,
  selectVariantOptionsByObat,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { SimulationForm as Component } from './SimulationForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeMultiSimulationGroup = selectRouteResource(rrc.multi_simulation_group)(rootState);
  const apiRequestKey = getDetailApiRequestKey(dra.updateObatConfig, routeMultiSimulationGroup.id);

  return {
    routeMultiSimulationGroup,
    configOptions: selectSimulationGroupConfigOptions(rootState),
    isSubmitting: selectIsApiRequestPending(apiRequestKey)(rootState),
    variantOptionsByObat: selectVariantOptionsByObat(rootState),
    simulationToEdit: selectFormInitialResourceFromTable(rrc.simulation)(rootState),
  };
};

const mapDispatchToProps = {
  closeFormDrawer: closeFormDrawerAction,
  fetchObatVariants: fetchObatVariantsSagaAction,
};

export const SimulationForm = connect(mapStateToProps, mapDispatchToProps)(Component);
