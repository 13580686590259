import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { _get, _split } from 'libs/lodash';
import { getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IActivityZoneGroupRequestParams } from 'types/Obat/ActivityZoneGroup';
import { fdw } from 'utils/configs/drawerWidths';
import { withEmptyOption } from 'utils/functions/forms/withEmptyOption';
import { getEditionPositionOptions } from 'utils/functions/obatResources/position/getEditionPositionOptions';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './ActivityZoneGroupForm.container';

export interface IActivityZoneGroupFormValues {
  cooling_availability: string;
  cooling_setpoint: string;
  cooling_system: string;
  dhw: string;
  dhw_system: string;
  electric_equipment: string;
  electric_equipment_bis: string;
  filter_geometries: string;
  filter_zones: string;
  heating_setpoint: string;
  heating_system: string;
  infiltration: string;
  internal_mass_coefficient: number | '';
  light: string;
  mechanical_ventilation_system: string;
  natural_ventilation: string;
  natural_ventilation_bis: string;
  outdoor_air: string;
  people: string;
  ref: string;
  sort_index: number;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<
  IActivityZoneGroupFormValues,
  IActivityZoneGroupRequestParams
>();

export class ActivityForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.activity_zone_group}
        subFormResources={[orc.activity_zone_group]}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IActivityZoneGroupFormValues>): ReactNode => {
      const { createPositionOptions, selectSystemsByTypeOptions, activityZoneGroupToEdit } = this.props;

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'sort_index'}
                formikProps={formikProps}
                options={
                  activityZoneGroupToEdit
                    ? getEditionPositionOptions(createPositionOptions, activityZoneGroupToEdit.ref)
                    : createPositionOptions
                }
                label={$t('position')}
                required
              />
            </Grid>
          </Grid>
          <Section title={$t('filters')} marginTop={20}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'filter_geometries'}
                  formikProps={formikProps}
                  label={$t('geometriesFilter')}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'filter_zones'}
                  formikProps={formikProps}
                  label={$t('zonesFilter')}
                  multiline
                />
              </Grid>
            </Grid>
          </Section>
          <Section title={$t('activityZoneGroups')} marginTop={20}>
            <Grid container spacing={1}>
              {this.renderScheduleInput('light', formikProps)}
              {this.renderScheduleInput('people', formikProps)}
              {this.renderScheduleInput('electric_equipment', formikProps)}
              {this.renderScheduleInput('electric_equipment_bis', formikProps)}
              {this.renderScheduleInput('natural_ventilation', formikProps)}
              {this.renderScheduleInput('natural_ventilation_bis', formikProps)}
              {this.renderScheduleInput('infiltration', formikProps)}
              <Grid item xs={12}>
                <FormikWriteInput field={'internal_mass_coefficient'} formikProps={formikProps} />
              </Grid>
            </Grid>
          </Section>
          <Section title={$t('control')} marginTop={20}>
            <Grid container spacing={1}>
              {this.renderScheduleInput('heating_setpoint', formikProps)}
              {this.renderScheduleInput('cooling_setpoint', formikProps)}
              {this.renderScheduleInput('outdoor_air', formikProps)}
              {this.renderScheduleInput('dhw', formikProps)}
            </Grid>
          </Section>
          <Section title={$t('systems')} marginTop={20}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikDropdownInput
                  field={'heating_system'}
                  formikProps={formikProps}
                  options={withEmptyOption(selectSystemsByTypeOptions('heating_loop'))}
                  label={$t('heating')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikDropdownInput
                  field={'cooling_system'}
                  formikProps={formikProps}
                  options={withEmptyOption(selectSystemsByTypeOptions('cooling_loop'))}
                  label={$t('cooling')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikDropdownInput
                  field={'mechanical_ventilation_system'}
                  formikProps={formikProps}
                  options={withEmptyOption(selectSystemsByTypeOptions('air_loop'))}
                  label={$t('mechanicalVentilation')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikDropdownInput
                  field={'dhw_system'}
                  formikProps={formikProps}
                  options={withEmptyOption(selectSystemsByTypeOptions('dhw_loop'))}
                  label={$t('dhw')}
                />
              </Grid>
            </Grid>
          </Section>
        </>
      );
    };
  };

  private renderScheduleInput = (field: string, formikProps: FormikProps<IActivityZoneGroupFormValues>): ReactNode => {
    const { selectSchedulesByTypeOptions, openPlotsModal } = this.props;

    const fieldValue = _get(formikProps.values, field);

    const openScheduleModal = (): void => {
      openPlotsModal(fieldValue, orc.schedule);
    };

    return (
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item xs={11}>
            <FormikDropdownInput
              field={field}
              formikProps={formikProps}
              options={withEmptyOption(selectSchedulesByTypeOptions(_split(field, '_bis')[0]))}
            />
          </Grid>
          {!!fieldValue && (
            <Grid item xs={1}>
              <IconButton size={16} action={'seeDetails'} onClick={openScheduleModal} />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  private getInitialFormValues = (): IActivityZoneGroupFormValues => {
    const { activityZoneGroupToEdit, createPositionOptions } = this.props;

    return {
      ref: setFormInitialValue(activityZoneGroupToEdit, 'ref'),
      sort_index: activityZoneGroupToEdit
        ? activityZoneGroupToEdit.sort_index.toString()
        : (createPositionOptions.length - 1).toString(),
      filter_geometries: setFormInitialValue(activityZoneGroupToEdit, 'filter_geometries'),
      filter_zones: setFormInitialValue(activityZoneGroupToEdit, 'filter_zones'),
      people: setFormInitialValue(activityZoneGroupToEdit, 'people'),
      light: setFormInitialValue(activityZoneGroupToEdit, 'light'),
      electric_equipment: setFormInitialValue(activityZoneGroupToEdit, 'electric_equipment'),
      electric_equipment_bis: setFormInitialValue(activityZoneGroupToEdit, 'electric_equipment_bis'),
      natural_ventilation: setFormInitialValue(activityZoneGroupToEdit, 'natural_ventilation'),
      natural_ventilation_bis: setFormInitialValue(activityZoneGroupToEdit, 'natural_ventilation_bis'),
      infiltration: setFormInitialValue(activityZoneGroupToEdit, 'infiltration'),
      dhw: setFormInitialValue(activityZoneGroupToEdit, 'dhw'),
      internal_mass_coefficient: setFormInitialValue(activityZoneGroupToEdit, 'internal_mass_coefficient'),
      heating_setpoint: setFormInitialValue(activityZoneGroupToEdit, 'heating_setpoint'),
      cooling_setpoint: setFormInitialValue(activityZoneGroupToEdit, 'cooling_setpoint'),
      cooling_availability: setFormInitialValue(activityZoneGroupToEdit, 'cooling_availability'),
      outdoor_air: setFormInitialValue(activityZoneGroupToEdit, 'outdoor_air'),
      heating_system: setFormInitialValue(activityZoneGroupToEdit, 'heating_system'),
      cooling_system: setFormInitialValue(activityZoneGroupToEdit, 'cooling_system'),
      mechanical_ventilation_system: setFormInitialValue(activityZoneGroupToEdit, 'mechanical_ventilation_system'),
      dhw_system: setFormInitialValue(activityZoneGroupToEdit, 'dhw_system'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IActivityZoneGroupFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      filter_geometries: getYupStringValidation(),
      filter_zones: getYupStringValidation(),
      people: getYupStringValidation(),
      light: getYupStringValidation(),
      electric_equipment: getYupStringValidation(),
      electric_equipment_bis: getYupStringValidation(),
      natural_ventilation: getYupStringValidation(),
      natural_ventilation_bis: getYupStringValidation(),
      infiltration: getYupStringValidation(),
      dhw: getYupStringValidation(),
      internal_mass_coefficient: getYupNumberValidation(),
      heating_setpoint: getYupStringValidation(),
      cooling_setpoint: getYupStringValidation(),
      cooling_availability: getYupStringValidation(),
      outdoor_air: getYupStringValidation(),
      heating_system: getYupStringValidation(),
      cooling_system: getYupStringValidation(),
      mechanical_ventilation_system: getYupStringValidation(),
      dhw_system: getYupStringValidation(),
      sort_index: getYupNumberValidation(true),
    }) as yup.ObjectSchema<IActivityZoneGroupFormValues>;
  };
}
