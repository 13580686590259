import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { ISurfaceGroup } from 'types/Obat/SurfaceGroup';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { SurfaceGroupDetail } from '../SurfaceGroupDetail';
import { SurfaceGroupForm } from '../SurfaceGroupForm';

export class SurfaceGroupsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'surfaceGroups'}
        resourceClass={orc.surface_group}
        renderCardDetail={this.renderCardCollapse}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={SurfaceGroupForm}
        projectionSection={'surfaces'}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 5 },
    {
      id: 'surfaceCategory',
      resourceField: 'surface_category',
      withFilterOptions: true,
      gridItemSize: 2,
      renderCustomText: (surfaceGroup: ISurfaceGroup) => $tEnums(surfaceGroup.surface_category),
    },
    {
      id: 'construction',
      resourceField: 'construction',
      withFilterOptions: true,
      gridItemSize: 2,
    },
    {
      id: 'opening',
      resourceField: 'opening',
      withFilterOptions: true,
      gridItemSize: 3,
    },
  ];

  private renderCardColumnData = (surfaceGroup: ISurfaceGroup, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return surfaceGroup.ref;
      case 'surfaceCategory':
        return $tEnums(renderTextValueElseDash(surfaceGroup.surface_category));
      case 'construction':
        return renderTextValueElseDash(surfaceGroup.construction);
      case 'opening':
        return renderTextValueElseDash(surfaceGroup.opening);
    }
  };

  private renderCardCollapse = (surfaceGroup: ISurfaceGroup): ReactNode => {
    return <SurfaceGroupDetail surfaceGroup={surfaceGroup} />;
  };
}
