import { Alert } from 'components/Alerts';
import { Button } from 'components/Buttons/Button.component';
import { BottomRightButtons } from 'components/Buttons/ButtonsContainers';
import { AlertIcon } from 'components/Icons';
import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import { SimulationResourceConfig } from 'components/SimulationGroup/SimulationResourceConfig';
import { SimulationVariantConfig } from 'components/SimulationGroup/SimulationVariantConfig';
import { _includes } from 'libs/lodash';
import moment from 'moment';
import { SimulationForm } from 'pages/SimulationGroupMenu/Multi/Config/SimulationForm';
import React, { PureComponent, ReactNode } from 'react';
import { Play } from 'react-feather';
import { multiSimulationGroupMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { ISimulationListed } from 'types/SimulationGroup/Simulation';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { getRunTooltip } from 'utils/functions/simulationGroup/getRunTooltip';
import { isRunEnabled } from 'utils/functions/simulationGroup/isRunEnabled';
import { $t, $tcc } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rrc } from 'utils/strings/resourceClasses';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './ConfigMulti.container';

export class ConfigMulti extends PureComponent<IProps> {
  public componentDidMount = (): void => {
    const { fetchSimulationGroupConfigOptions, refreshSimulationGroup } = this.props;

    refreshSimulationGroup(rrc.multi_simulation_group);
    fetchSimulationGroupConfigOptions();
  };

  public render(): ReactNode {
    const { routeMultiSimulationGroup, isWritePermissionMissing, isSeatMissing, runSimulation } = this.props;

    const { status, project, simus_nb } = routeMultiSimulationGroup;
    const isRunning = status === as.running;
    const isMaxSimusNbReached = simus_nb >= project.max_parallel_simulations;

    const tooltipContent = isRunning
      ? $t('simulationRunningAlert')
      : isMaxSimusNbReached
      ? $t('maxSimusNbReachedAlert', { max: project.max_parallel_simulations })
      : undefined;

    return (
      <>
        <RestResourcesListPage
          routingMenu={rm.multiSimulationGroup}
          routeParentId={routeMultiSimulationGroup.id}
          renderAlert={this.renderAlert}
          selectedPage={multiSimulationGroupMenuPages.configMulti}
          selectedPageTitle={$t('config')}
          FormComponent={SimulationForm}
          resourceClass={rrc.simulation}
          parentResourceClass={rrc.multi_simulation_group}
          columnsParams={this.getColumnsParams()}
          availableActions={['edit', 'copy', 'delete']}
          isCreationDisabled={isRunning || isMaxSimusNbReached}
          creationTooltipContent={tooltipContent}
          defaultSort={{ columnId: 'start', order: 'desc' }}
        />
        <BottomRightButtons>
          <Button
            Icon={<Play />}
            onClick={runSimulation}
            isSeatMissing={isSeatMissing}
            isWritePermissionMissing={isWritePermissionMissing}
            isDisabled={!isRunEnabled(routeMultiSimulationGroup)}
            tooltipProps={{ content: getRunTooltip(routeMultiSimulationGroup), placement: 'left' }}
          />
        </BottomRightButtons>
      </>
    );
  }

  private renderAlert = (): ReactNode => {
    const { routeMultiSimulationGroup, isAllSimulationsEmpty } = this.props;

    if (routeMultiSimulationGroup.status === as.running) {
      return (
        <Alert status={as.info} opacity={0.8}>
          {$t('simulationGroupRunningConfigAlert')}
        </Alert>
      );
    }

    if (
      routeMultiSimulationGroup.success_nb > 0 &&
      routeMultiSimulationGroup.status === as.empty &&
      !isAllSimulationsEmpty
    ) {
      return (
        <Alert status={as.warning} opacity={0.8}>
          {$t('simulationGroupConfigModificationAlert')}
        </Alert>
      );
    }

    if (routeMultiSimulationGroup.obsolete_front) {
      return (
        <Alert status={as.warning} opacity={0.8}>
          {$t('simulationGroupObsoleteAlert')}
        </Alert>
      );
    }

    return null;
  };

  private getColumnsParams = (): ITableColumnParams[] => {
    return [
      { id: 'name', resourceField: 'name', minWidth: 120 },
      {
        id: 'geometry',
        resourceField: 'geometry_id',
        renderCustomDOM: (simulation: ISimulationListed) => {
          const { geometry_id, obsolete } = simulation;

          return (
            <SimulationResourceConfig
              resourceClass={rrc.geometry}
              resourceId={geometry_id}
              isObsolete={obsolete.geometry.obsolete}
            />
          );
        },
      },
      {
        id: 'obat',
        resourceField: 'obat_id',
        renderCustomDOM: (simulation: ISimulationListed) => {
          const { obat_id, obsolete } = simulation;

          return (
            <SimulationResourceConfig
              resourceClass={rrc.obat}
              resourceId={obat_id}
              isObsolete={obsolete.obat.obsolete}
            />
          );
        },
      },
      {
        id: 'variant',
        resourceField: 'variant',
        renderCustomDOM: (simulation: ISimulationListed): ReactNode => {
          return <SimulationVariantConfig variantRef={simulation.variant} obatId={simulation.obat_id} />;
        },
      },
      {
        id: 'weather',
        resourceField: 'weather_id',
        renderCustomDOM: (simulation: ISimulationListed) => {
          const { weather_id, obsolete } = simulation;

          return (
            <SimulationResourceConfig
              resourceClass={rrc.weather}
              resourceId={weather_id}
              isObsolete={obsolete.weather.obsolete}
            />
          );
        },
      },
      {
        id: 'start',
        resourceField: 'start',
        renderCustomText: (simulation: ISimulationListed) => {
          return moment.utc(simulation.start).format('L');
        },
      },
      {
        id: 'end',
        resourceField: 'end',
        renderCustomText: (simulation: ISimulationListed) => {
          return moment.utc(simulation.end).format('L');
        },
      },
      {
        id: 'status',
        resourceField: 'status',
        withFilterOptions: true,
        renderCustomDOM: (simulation: ISimulationListed): ReactNode => (
          <TableIcon>
            <AlertIcon status={simulation.status} />
          </TableIcon>
        ),
        getCustomDOMFilterComparisonValue: (simulation: ISimulationListed): string => $tcc(simulation.status),
      },
    ];
  };
}

const TableIcon = styled.div`
  margin-bottom: -4px;
`;
