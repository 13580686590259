import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath, selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { editMultiObatResourcesSagaAction } from 'redux/obatResources/actions';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { gra } from 'utils/strings/requestActions';
import { IObatResourceClass } from 'utils/strings/resourceClasses';
import { rrc } from 'utils/strings/resourceClasses';

import { IResourceToReorder, ReorderForm as Component } from './ReorderForm.component';

interface IOwnProps<IResource> {
  resourcesToReorder: IResource[];
  resourceClass: IObatResourceClass;
  getCardTitle?: (resource: IResource) => string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps<IResource> = IOwnProps<IResource> & IInjectedProps;

const mapStateToProps = <IResource>(rootState: IRootState, ownProps: IOwnProps<IResource>) => {
  const { resourceClass } = ownProps;

  return {
    routeObat: selectRouteResource(rrc.obat)(rootState),
    // @ts-ignore
    isSubmitting: selectIsApiRequestPending(getGenericApiRequestKey(gra.editMulti, resourceClass))(rootState),
    // @ts-ignore
    editErrors: selectFormApiErrorsTranslationPath(getGenericApiRequestKey(gra.editMulti, resourceClass))(rootState),
  };
};
const mapDispatchToProps = {
  editMultiObatResources: editMultiObatResourcesSagaAction,
};

export const getReorderForm = <IResource extends IResourceToReorder>() => {
  return connect<IStateProps, IDispatchProps, IOwnProps<IResource>, IRootState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component as new () => Component<IResource>);
};
