import { Action } from 'redux';

export const OPEN_NAVIGATION_MODAL = 'OPEN_NAVIGATION_MODAL';
export interface IOpenNavigationModal extends Action<'OPEN_NAVIGATION_MODAL'> {
  payload: {};
}
export const openNavigationModalAction = (): IOpenNavigationModal => ({
  type: OPEN_NAVIGATION_MODAL,
  payload: {},
});

export const CLOSE_NAVIGATION_MODAL = 'CLOSE_NAVIGATION_MODAL';
export interface ICloseNavigationModal extends Action<'CLOSE_NAVIGATION_MODAL'> {}
export const closeNavigationModalAction = (): ICloseNavigationModal => ({
  type: CLOSE_NAVIGATION_MODAL,
});

export type INavigationModalActions = IOpenNavigationModal | ICloseNavigationModal;
