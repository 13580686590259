// tslint:disable:no-any
import React, { PureComponent, ReactNode } from 'react';
import { displayFormFieldError } from 'utils/functions/forms/displayFormFieldError/displayFormFieldError';

import { ButtonGroupInput, IButtonGroupInputProps } from '../ButtonGroupInput/ButtonGroupInput.component';

interface IProps extends Pick<IButtonGroupInputProps, Exclude<keyof IButtonGroupInputProps, 'values' | 'onSelect'>> {
  formikProps: any;
}

export class FormikButtonGroupInput extends PureComponent<IProps> {
  public render(): ReactNode {
    const { formikProps, field, ...buttonGroupInputProps } = this.props;

    return (
      <ButtonGroupInput
        {...buttonGroupInputProps}
        field={field}
        errorText={displayFormFieldError(field, formikProps)}
        onSelect={formikProps.setFieldValue}
        values={formikProps.values[field]}
      />
    );
  }
}
