// tslint:disable:no-any
import { _memoize } from 'libs/lodash';

/* memoize with a default resolver so that each args change triggers a new computation */

export const memoizeMulti = (func: any) => {
  return _memoize(func, (...args: any): string => {
    return JSON.stringify(args);
  });
};
