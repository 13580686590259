import { Plot } from 'components/Plot';
import { PlotLegend } from 'components/PlotLegend';
import { IPlotLegendItem } from 'components/PlotLegend/PlotLegend.component';
import { _map } from 'libs/lodash';
import { _range } from 'libs/lodash';
import { Data } from 'plotly.js';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { IDayProfile } from 'types/Obat/DayProfile';
import { formatISODate } from 'utils/functions/datetimes/formatISODate';
interface IProps {
  dayProfiles: IDayProfile[];
  yearProfileType: string;
}

export class DayProfilesPlot extends PureComponent<IProps> {
  public state: { [width: string]: number } = { width: 0 };

  // trick to get the size of the parent div
  // https://swizec.com/blog/ref-callbacks-measure-react-component-size/swizec/8444
  public refCallback = (element: HTMLDivElement | null): void => {
    if (element) {
      this.setState({ width: element.getBoundingClientRect().width });
    }
  };

  public render = (): ReactNode => {
    const { dayProfiles, yearProfileType } = this.props;

    const legendItems = _map(
      dayProfiles,
      (dayProfile: IDayProfile, i: number): IPlotLegendItem => {
        return {
          // @ts-ignore
          title: dayProfile.expanded_series.day_group || '',
          dotColor: theme.colors.plot.obatColorway[i],
        };
      }
    );
    const plotlyData: Data[] = _map(
      dayProfiles,
      (dayProfile: IDayProfile): Data => {
        // @ts-ignore
        const { index, data, day_group } = dayProfile.expanded_series;
        const hoverText = _map(_range(index.length), (i: number): string => {
          return `${formatISODate(index[i], 'HH:mm')}  ${data[i]}`;
        });

        return {
          x: index,
          y: data,
          name: day_group,
          type: 'scatter',
          mode: 'lines',
          hoverinfo: 'text',
          text: hoverText,
        };
      }
    );

    // 2012 because the reference year of backend as 2012 for calendars
    const tickvals = [
      '2012-01-01T04:00:00.00000Z',
      '2012-01-01T08:00:00.00000Z',
      '2012-01-01T12:00:00.00000Z',
      '2012-01-01T16:00:00.00000Z',
      '2012-01-01T20:00:00.00000Z',
    ];

    const ticktext = _map(tickvals, (tickDate: string): string => formatISODate(tickDate, 'HH:mm'));

    const layout = {
      font: {
        family: 'Source Sans Pro, sans-serif',
        size: 12,
      },
      showlegend: false,
      width: this.state.width,
      height: 100,
      margin: {
        l: 70,
        r: 0,
        t: 10,
        b: 15,
      },
      id: '',
      xaxis: {
        tickvals,
        ticktext,
        tickangle: 0,
      },
      yaxis: {
        ticksuffix: yearProfileType === 'temperature' ? '°C' : '',
      },
      colorway: theme.colors.plot.obatColorway,
      plot_bgcolor: theme.colors.transparent,
      paper_bgcolor: theme.colors.transparent,
    };

    const config = { displayModeBar: false, responsive: true };

    return (
      <div ref={this.refCallback} onClick={this.onClick}>
        <Plot data={plotlyData} layout={layout} config={config} />
        <LegendContainer>
          <PlotLegend legendItems={legendItems} itemsPerLine={4} />
        </LegendContainer>
      </div>
    );
  };

  private onClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
  };
}

const LegendContainer = styled.div`
  padding-left: 50px;
`;
