import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { IRestResource } from 'types/RestResource';
import { IRestResourceClass, rrc } from 'utils/strings/resourceClasses';

import { RestResourceCopyFormDrawer as Component } from './RestResourceCopyFormDrawer.component';

interface IOwnProps {
  resourceClass: IRestResourceClass;
  getCreationSuccessLandingPageUrl?: (routeProjectId: string, createdRestResource: IRestResource) => string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return {
    initialRestResource: selectFormInitialResourceFromTable(ownProps.resourceClass)(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
  };
};

const mapDispatchToProps = {};

export const RestResourceCopyFormDrawer = connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
