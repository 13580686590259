import { Grid } from '@material-ui/core';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { FormikProps } from 'formik';
import { getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IMaterialRequestParams } from 'types/Obat/Material';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './MaterialForm.container';

export interface IMaterialFormValues {
  ref: string;
  nominal_conductivity: number | '';
  density: number | '';
  specific_heat: number | '';
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IMaterialFormValues, IMaterialRequestParams>();

export class MaterialForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.material}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IMaterialFormValues>): ReactNode => {
      return (
        <>
          <Grid container spacing={1}>
            {/*<Grid item xs={12}>*/}
            {/*<FormikTextInput*/}
            {/*field={'ref'}*/}
            {/*formikProps={formikProps}*/}
            {/*label={translate('name')}*/}
            {/*required*/}
            {/*inputRef={this.formFocusRef}*/}
            {/*/>*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'nominal_conductivity'}
                formikProps={formikProps}
                label={$t('conductivityWithUnit')}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput field={'density'} formikProps={formikProps} label={$t('densityWithUnit')} required />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'specific_heat'}
                formikProps={formikProps}
                label={$t('specificHeatWithUnit')}
                required
              />
            </Grid>
          </Grid>
        </>
      );
    };
  };

  private getInitialFormValues = (): IMaterialFormValues => {
    const { materialToEdit } = this.props;

    return {
      ref: setFormInitialValue(materialToEdit, 'ref'),
      nominal_conductivity: setFormInitialValue(materialToEdit, 'nominal_conductivity'),
      density: setFormInitialValue(materialToEdit, 'density'),
      specific_heat: setFormInitialValue(materialToEdit, 'specific_heat'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IMaterialFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      nominal_conductivity: getYupNumberValidation(true),
      density: getYupNumberValidation(true),
      specific_heat: getYupNumberValidation(true, 100, undefined, true),
    }) as yup.ObjectSchema<IMaterialFormValues>;
  };
}
