import wretch from 'wretch';

export const downloadFromAzureBlob = async (blobUrl: string, outputFormat: 'json' | 'csv' = 'json') => {
  try {
    const wretcher = await wretch(blobUrl).get();

    return outputFormat === 'csv' ? wretcher.text() : wretcher.json();
  } catch (e) {
    throw new Error(`Error while downloading from azure blob url ${blobUrl}: ${e}`);
  }
};
