import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { selectUserCreationModalEmail } from 'redux/modals/userCreationModal/selectors';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { fk } from 'utils/strings/formKeys';
import { rrc } from 'utils/strings/resourceClasses';

import { OrganizationPermissionsList as Component } from './OrganizationPermissionsList.component';

// tslint:disable-next-line
interface IOwnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => ({
  routeOrganization: selectRouteResource(rrc.organization)(rootState),
  initialEmail: selectUserCreationModalEmail(rootState),
});

const mapDispatchToProps = {
  openFormDrawer: openFormDrawerSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openGuestForm: () => {
    dispatchProps.openFormDrawer(fk.inviteUser, {
      resourceId: '',
      resourceClass: rrc.user,
    });
  },
});

export const OrganizationPermissionsList = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
