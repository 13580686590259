import { Button } from 'components/Buttons';
import { BottomRightButtons, CenterButtons } from 'components/Buttons/ButtonsContainers';
import { ToolsButton } from 'components/Buttons/ToolsButton';
import { ITool } from 'components/Buttons/ToolsButton/ToolsButton.component';
import { OplusLoader } from 'components/Loader';
import { Page } from 'components/Page';
import { ThreejsViewer } from 'components/ThreejsViewer';
import { ImportGeometryForm } from 'pages/GeometryMenu/Viewer3D/ImportGeometryForm';
import React, { PureComponent, ReactNode } from 'react';
import { Play, Upload } from 'react-feather';
import { geometryMenuPages } from 'routing/routes';
import { getExportFileName } from 'utils/functions/getExportFileName';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './Viewer3D.container';

export class Viewer3D extends PureComponent<IProps> {
  public componentDidMount(): void {
    const { routeGeometry, refreshImportGeometryTask, downloadGeometryThreejs } = this.props;
    if (routeGeometry.empty) {
      refreshImportGeometryTask();
    } else {
      downloadGeometryThreejs();
    }
  }

  public componentWillUnmount(): void {
    const { routeGeometry, clearGeometryThreejs } = this.props;
    if (!routeGeometry.empty) {
      clearGeometryThreejs();
    }
  }

  public render(): ReactNode {
    const {
      threejsData,
      routeGeometry,
      isFloorspace,
      isLoading,
      isImportingOrFetching,
      openImportGeometryForm,
      geometryImportProgress,
      isWritePermissionMissing,
      isSeatMissing,
      formDrawerWidth,
    } = this.props;

    return (
      <Page
        pageTitle={$t('viewer3D')}
        routingMenu={rm.geometry}
        selectedPage={geometryMenuPages.viewer3D}
        isMenuDrawerCollapsed={true}
      >
        {!threejsData && !isLoading && routeGeometry.empty && formDrawerWidth === 0 && (
          <CenterButtons isPulsing>
            <Button
              Icon={isFloorspace ? <Play size={12} /> : <Upload size={12} />}
              text={isFloorspace ? $t('convert2DTo3D') : $t('import')}
              isWritePermissionMissing={isWritePermissionMissing}
              isSeatMissing={isSeatMissing}
              onClick={isFloorspace ? this.importGeometryFromFloorspace : openImportGeometryForm}
            />
          </CenterButtons>
        )}
        {isImportingOrFetching && <OplusLoader progress={geometryImportProgress} />}
        {!isImportingOrFetching && !routeGeometry.empty && threejsData && <ThreejsViewer threejsData={threejsData} />}
        {!isImportingOrFetching && !routeGeometry.empty && (
          <BottomRightButtons formDrawerWidth={formDrawerWidth}>
            <ToolsButton tools={this.getTools()} />
          </BottomRightButtons>
        )}
        <ImportGeometryForm />
      </Page>
    );
  }

  private getTools = (): ITool[] => {
    const {
      exportGeometry,
      openAlertModal,
      routeGeometry,
      openImportGeometryForm,
      isFloorspace,
      isWritePermissionMissing,
      isSeatMissing,
    } = this.props;

    let tools: ITool[] = [];

    if (!isFloorspace) {
      tools = [
        ...tools,
        {
          text: $t('importNewFile'),
          onClick: openImportGeometryForm,
          isWritePermissionMissing,
          isSeatMissing,
        },
        {
          text: $t('exportGeometry'),
          onClick: exportGeometry,
          isWritePermissionMissing,
          isSeatMissing,
        },
      ];
    }

    if (routeGeometry.last_import_message) {
      const onWarningClick = () => {
        openAlertModal(routeGeometry.last_import_message, as.warning, $t('warnings'));
      };
      tools = [{ text: $t('warnings'), onClick: onWarningClick }, ...tools];
    }

    return tools;
  };

  private importGeometryFromFloorspace = (): void => {
    const { importGeometry, routeGeometry } = this.props;
    importGeometry({ import_format: 'floorspace', file_name: getExportFileName(routeGeometry.name, 'flo') });
  };
}
