import { OplusLoader } from 'components/Loader';
import { Plot } from 'components/Plot';
import { _get } from 'libs/lodash';
import { _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { weatherMeasureTypes } from 'utils/configs/weatherMeasures';
import { getSeriesPlotHoverTooltip } from 'utils/functions/getSeriesPlotHoverTooltip';
import { applySpanOffset } from 'utils/functions/weathers/applySpanOffset';
import { applyStampOffset } from 'utils/functions/weathers/applyStampOffset';

import { IProps } from './WeatherMeasurePlot.container';

interface IState {
  width: number;
}
export class WeatherMeasurePlot extends PureComponent<IProps, IState> {
  public state: IState = {
    width: 0,
  };

  public refCallback = (element: HTMLDivElement | null): void => {
    if (element) {
      this.setState({ width: element.getBoundingClientRect().width });
    }
  };

  public render(): ReactNode {
    const { plottedMeasure, plottedSeries, routeWeather } = this.props;
    const { width } = this.state;

    if (!plottedSeries || !plottedMeasure) {
      return null;
    }

    const seriesMeasureType = _get(weatherMeasureTypes, plottedMeasure.seriesId);

    const offsetSeries =
      seriesMeasureType === 'span' ? applySpanOffset(plottedSeries) : applyStampOffset(plottedSeries);

    const dateFormat = routeWeather.generic_weather_series ? 'DD/MM HH:mm' : 'DD/MM/YYYY HH:mm';
    const tickformat = routeWeather.generic_weather_series ? '%d/%m' : '%d/%m/%Y';

    const seriesHover = getSeriesPlotHoverTooltip(offsetSeries.datetimeIndex, offsetSeries.name, dateFormat, true);

    const data = [
      {
        x: offsetSeries.datetimeIndex,
        y: offsetSeries.values,
        line: { color: theme.colors.plot.default, width: 1, shape: seriesMeasureType === 'span' ? 'hv' : 'linear' },
        ...seriesHover,
      },
    ];

    const layout = {
      width,
      height: 500,
      font: {
        family: 'Source Sans Pro, sans-serif',
        color: theme.colors.font.dark,
        size: 12,
      },
      margin: {
        l: 30,
        r: 30,
        b: 30,
        t: 30,
        pad: 4,
      },
      showlegend: false,
      xaxis: {
        showgrid: false,
        linewidth: 1.5,
        linecolor: '#CDD6E9',
        gridcolor: '#E6E6E6',
        rangeslider: {},
        type: 'date',
        zeroline: false,
        autorange: true,
        tickformat,
      },
      yaxis: {
        zeroline: false,
        gridcolor: '#E6E6E6',
        fixedrange: false,
        autorange: true,
      },
      hovermode: 'closest',
    };

    return (
      <PlotContainer ref={this.refCallback}>
        {_size(offsetSeries.values) > 0 ? (
          // @ts-ignore
          <Plot data={data} layout={layout} />
        ) : (
          <OplusLoader />
        )}
      </PlotContainer>
    );
  }
}

const PlotContainer = styled.div`
  width: 100%;
  height: 100%;
`;
