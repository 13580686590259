import { oPalette } from './palettes/oPalette';
import { x11Palette } from './palettes/x11Palette';

const theme = {
  fonts: {
    weight: {
      light: 300,
      normal: 400,
      bold: 700,
    },
  },
  colors: {
    main: oPalette.turquoise,
    pageBody: x11Palette.gray99,
    link: oPalette.darkturquoise,
    divider: x11Palette.gray84,
    transparent: oPalette.transparent,
    formLabel: x11Palette.gray65,
    listItemHover: x11Palette.gray94,
    lightBackground: x11Palette.gray97,
    error: x11Palette.tomato1,
    variant: x11Palette.gray9,
    running: x11Palette.goldenrod,
    success: x11Palette.mediumspringgreen,
    failed: x11Palette.tomato1,
    serverError: x11Palette.tomato1,
    info: x11Palette.steelblue1,
    warning: x11Palette.tan1,
    empty: x11Palette.gray69,
    seat: {
      gratis: x11Palette.gray69,
      taken: x11Palette.dodgerblue1,
      free: x11Palette.gray28,
      leaving: x11Palette.gold2,
    },
    plot: {
      default: oPalette.skyBlue,
      obatColorway: ['#f8b400', '#06dbdc', '#f71735', '#29335c', '#d037df', '#d35c92', '#bc6cf2', '#56a5f2'],
      seriesColorway: [
        '#7cb5ec',
        '#90ed7d',
        '#f7a35c',
        '#8085e9',
        '#f15c80',
        '#e4d354',
        '#2b908f',
        '#f45b5b',
        '#91e8e1',
      ],
    },
    button: {
      disabled: x11Palette.gray69,
    },
    input: {
      hover: x11Palette.gray93,
      filled: x11Palette.gray97,
      disabled: oPalette.transparentgray,
    },
    font: {
      dark: oPalette.dark,
      light: oPalette.lightdark,
      optional: oPalette.halfblack,
    },
    menu: {
      primary: oPalette.dark,
      secondary: oPalette.lightdark,
      item: x11Palette.gray84,
      selectedItem: oPalette.transparentturquoise,
    },
    surfaceCategories: {
      floor_ground: x11Palette.olivedrab1,
      floor_interior: x11Palette.turquoise,
      floor_outdoor: x11Palette.skyblue2,
      roof_ground: x11Palette.sienna2,
      roof_interior: x11Palette.red4,
      roof_outdoor: x11Palette.gray38,
      wall_interior: x11Palette.peachpuff1,
      wall_outdoor: x11Palette.pale,
      wall_ground: x11Palette.plum4,
    },
    filter: {
      xButton: x11Palette.gray80,
      xButtonHover: x11Palette.gray60,
    },
  },
  borderRadius: {
    small: '4px',
    large: '8px',
  },
  shadows: {
    card: {
      standard: '0 1px 5px 0 rgba(0, 0, 0, 0.1)',
      hovered: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
      clicked: '0 1px 5px 0 rgba(0, 0, 0, 0.3)',
      edited: '0 1px 5px 1px rgba(0, 0, 0, 0.4)',
    },
  },
};

export default theme;
