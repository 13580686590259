import { Action } from 'redux';
import { IApiErrors } from 'types/Errors';

export const START_API_REQUEST_ACTION = 'START_API_REQUEST_ACTION';
export interface IStartApiRequestAction extends Action<'START_API_REQUEST_ACTION'> {
  payload: { apiRequestKey: string; hasCallback?: boolean };
}
export const startApiRequestAction = (apiRequestKey: string, hasCallback?: boolean): IStartApiRequestAction => ({
  type: START_API_REQUEST_ACTION,
  payload: {
    apiRequestKey,
    hasCallback,
  },
});

export const API_REQUEST_SUCCESS_ACTION = 'API_REQUEST_SUCCESS_ACTION';
export interface IApiRequestSuccessAction extends Action<'API_REQUEST_SUCCESS_ACTION'> {
  payload: { apiRequestKey: string; hasCallback?: boolean };
}
export const apiRequestSuccessAction = (apiRequestKey: string, hasCallback?: boolean): IApiRequestSuccessAction => ({
  type: API_REQUEST_SUCCESS_ACTION,
  payload: {
    apiRequestKey,
    hasCallback,
  },
});

export const API_REQUEST_ERROR_ACTION = 'API_REQUEST_ERROR_ACTION';
export interface IApiRequestErrorAction extends Action<'API_REQUEST_ERROR_ACTION'> {
  payload: {
    apiRequestKey: string;
    errors?: IApiErrors;
    errorMessage?: string;
    status?: number;
  };
}
export const apiRequestErrorAction = (
  apiRequestKey: string,
  errors?: IApiErrors,
  errorMessage?: string,
  status?: number
): IApiRequestErrorAction => ({
  type: API_REQUEST_ERROR_ACTION,
  payload: {
    apiRequestKey,
    errors,
    errorMessage,
    status,
  },
});

export const API_REQUEST_CALLBACK_SUCCESS_ACTION = 'API_REQUEST_CALLBACK_SUCCESS_ACTION';
export interface IApiRequestCallbackSuccessAction extends Action<'API_REQUEST_CALLBACK_SUCCESS_ACTION'> {
  payload: { apiRequestKey: string };
}
export const apiRequestCallbackSuccessAction = (apiRequestKey: string): IApiRequestCallbackSuccessAction => ({
  type: API_REQUEST_CALLBACK_SUCCESS_ACTION,
  payload: {
    apiRequestKey,
  },
});

export const CLEAR_SINGLE_API_REQUEST_ACTION = 'CLEAR_SINGLE_API_REQUEST_ACTION';
export interface IClearSingleApiRequestAction extends Action<'CLEAR_SINGLE_API_REQUEST_ACTION'> {
  payload: { apiRequestKey: string };
}
export const clearSingleApiRequestAction = (apiRequestKey: string): IClearSingleApiRequestAction => ({
  type: CLEAR_SINGLE_API_REQUEST_ACTION,
  payload: {
    apiRequestKey,
  },
});

export const CLEAR_ALL_API_REQUESTS_ACTION = 'CLEAR_ALL_API_REQUESTS_ACTION';
export interface IClearAllApiRequestsAction extends Action<'CLEAR_ALL_API_REQUESTS_ACTION'> {}
export const clearAllApiRequestsAction = (): IClearAllApiRequestsAction => ({
  type: CLEAR_ALL_API_REQUESTS_ACTION,
});

export type IApiRequestsActions =
  | IStartApiRequestAction
  | IApiRequestSuccessAction
  | IApiRequestCallbackSuccessAction
  | IApiRequestErrorAction
  | IClearSingleApiRequestAction
  | IClearAllApiRequestsAction;
