import { IAlertStatus } from 'utils/strings/alertStatus';

import { CLOSE_ALERT_MODAL, IAlertModalActions, OPEN_ALERT_MODAL } from './actions';

export interface IAlertModalState {
  alertText?: string;
  alertStatus?: IAlertStatus;
  modalTitle?: string;
}

export const initialState = { alertText: undefined, alertStatus: undefined, modalTitle: undefined };

export const alertModalReducer = (
  state: IAlertModalState = initialState,
  action: IAlertModalActions
): IAlertModalState => {
  switch (action.type) {
    case OPEN_ALERT_MODAL:
      const { alertStatus, alertText, modalTitle } = action.payload;

      return { ...state, alertText, alertStatus, modalTitle };

    case CLOSE_ALERT_MODAL:
      return initialState;

    default:
      return state;
  }
};
