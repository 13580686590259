import { RestResourcesRequests } from 'api/requests/RestResourcesRequests';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequestSaga } from 'redux/apiRequests/sagas';
import { selectApiRequestErrors } from 'redux/apiRequests/selectors';
import { downloadFromAzureBlobSaga, uploadFileToAzureSaga } from 'redux/azure/sagas';
import { closeFormDrawerAction } from 'redux/form/actions';
import {
  FETCH_GEOMETRIES_TEMPLATES_SAGA_ACTION,
  REFRESH_IMPORT_GEOMETRY_TASK_SAGA_ACTION,
  updateGeometriesTemplatesAction,
  updateGeometryImportProgressAction,
} from 'redux/restResources/detail/geometry/actions';
import { selectGeometryImportProgress } from 'redux/restResources/detail/geometry/selectors';
import { retrieveRestResourceSaga } from 'redux/restResources/generic/sagas';
import { selectRouteResource } from 'redux/routing/selectors';
import { updateTaskAction } from 'redux/tasks/actions';
import { refreshTaskSaga } from 'redux/tasks/sagas';
import { selectTask } from 'redux/tasks/selectors';
import { geometryMenuPages } from 'routing/routes';
import { downloadFileFromUrl } from 'utils/functions/downloadFile/downloadFileFromUrl';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { getTaskKey } from 'utils/functions/getTaskKey';
import { dr } from 'utils/strings/detailRoutes';
import { hm } from 'utils/strings/httpMethods';
import { ps } from 'utils/strings/progressStatus';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import {
  DOWNLOAD_GEOMETRY_THREEJS_SAGA_ACTION,
  EXPORT_GEOMETRY_SAGA_ACTION,
  IImportGeometrySagaAction,
  IMPORT_GEOMETRY_SAGA_ACTION,
  updateThreejsAction,
} from './actions';

export function* importGeometrySaga(payload: IImportGeometrySagaAction['payload']): SagaIterator {
  /* 1. Upload Geometry File to Blob */
  yield put(updateGeometryImportProgressAction(ps.uploadingFile));
  const { importFields, fileContentBlob } = payload;
  const routeGeometry = yield select(selectRouteResource(rrc.geometry));
  const uploadApiRequestKey = getDetailApiRequestKey(dra.uploadGeometry, routeGeometry.id);
  const uploadApiUrlParams = {
    resourceClass: rrc.geometry,
    resourceId: routeGeometry.id,
    detailRoute: dr.upload_url,
  };

  if (fileContentBlob) {
    const blobUrlCallResult = yield call(
      apiRequestSaga,
      uploadApiRequestKey,
      false,
      RestResourcesRequests.detailRoute,
      uploadApiUrlParams,
      hm.get
    );
    const blobUrl = blobUrlCallResult.blob_url;
    yield call(uploadFileToAzureSaga, blobUrl, fileContentBlob);
  }

  const uploadApiRequestErrors = yield select(selectApiRequestErrors(uploadApiRequestKey));
  if (!uploadApiRequestErrors) {
    /* 2. Import 3D from Blob to Geometry Resource */
    yield put(updateGeometryImportProgressAction(ps.importingGeometry));
    const importApiRequestKey = getDetailApiRequestKey(dra.importGeometry, routeGeometry.id);
    const importApiUrlParams = {
      resourceClass: rrc.geometry,
      resourceId: routeGeometry.id,
      detailRoute: dr.import_data,
    };

    const callResult = yield call(
      apiRequestSaga,
      importApiRequestKey,
      false,
      RestResourcesRequests.detailRoute,
      importApiUrlParams,
      hm.patch,
      {},
      importFields
    );

    const importApiRequestErrors = yield select(selectApiRequestErrors(importApiRequestKey));
    if (!importApiRequestErrors) {
      yield put(closeFormDrawerAction());
      const taskId = callResult.user_task;
      const taskKey = getTaskKey(dra.importGeometry, routeGeometry.id);
      const task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
      yield put(updateTaskAction(task, taskKey));
      yield call(refreshImportGeometryTaskSaga);
    }
  }

  const importProgress = yield select(selectGeometryImportProgress);
  if (importProgress !== ps.rendering) {
    /* if rendering, it will be turned to 'finished' in the threejsViewer component */
    yield put(updateGeometryImportProgressAction(ps.finished));
  }
}

export function* importGeometryActionSaga(action: IImportGeometrySagaAction): SagaIterator {
  yield call(importGeometrySaga, action.payload);
}

export function* refreshImportGeometryTaskSaga(): SagaIterator {
  const routeGeometry = yield select(selectRouteResource(rrc.geometry));
  const taskKey = getTaskKey(dra.importGeometry, routeGeometry.id);
  const task = yield select(selectTask(taskKey));

  if (task) {
    yield put(updateGeometryImportProgressAction(ps.importingGeometry));
    const finishedTask = yield call(refreshTaskSaga, {
      taskKey,
      routeResourceToUpdateClass: rrc.geometry,
      pagesWhereToRefresh: [geometryMenuPages.viewer3D],
      noWarnings: true,
      /* Cannot open warning modal here because it's impossible to close it before the rendering is over */
    });
    if (finishedTask && finishedTask.status_code === 200) {
      yield put(updateGeometryImportProgressAction(ps.fetchingData));
      yield call(downloadGeometryThreejsSaga);
      yield put(updateGeometryImportProgressAction(ps.rendering));
      /* The import progress will be turned to 'finished' by the threejsViewer */
    } else if (finishedTask && finishedTask.status_code !== 200) {
      yield put(updateGeometryImportProgressAction(ps.finished));
    }
  }
}

export function* refreshImportGeometryTaskActionSaga(): SagaIterator {
  yield call(refreshImportGeometryTaskSaga);
}

function* exportGeometrySaga(): SagaIterator {
  const routeGeometry = yield select(selectRouteResource(rrc.geometry));
  const apiRequestKey = getDetailApiRequestKey(dra.exportGeometry, routeGeometry.id);
  const apiUrlParams = {
    resourceClass: rrc.geometry,
    resourceId: routeGeometry.id,
    detailRoute: dr.source_blob_url,
  };

  const blobUrlCallResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const fileUrl = blobUrlCallResult.blob_url;
    yield call(downloadFileFromUrl, fileUrl);
  }
}

export function* exportGeometryActionSaga(): SagaIterator {
  yield call(exportGeometrySaga);
}

export function* downloadGeometryThreejsSaga(): SagaIterator {
  /* Download Threejs Json in the store (not in browser) */

  const routeGeometry = yield select(selectRouteResource(rrc.geometry));
  const apiRequestKey = getDetailApiRequestKey(dra.downloadThreejs, routeGeometry.id);
  const apiUrlParams = {
    resourceClass: rrc.geometry,
    resourceId: routeGeometry.id,
    detailRoute: dr.threejs_blob_url,
  };

  const blobUrlCallResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const blobUrl = blobUrlCallResult.blob_url;
    const outputFormat = 'json';
    const threejsBlob = yield call(downloadFromAzureBlobSaga, blobUrl, outputFormat);
    if (threejsBlob) {
      yield put(updateThreejsAction(threejsBlob));
    }
  }
}

export function* downloadGeometryThreejsActionSaga(): SagaIterator {
  yield call(downloadGeometryThreejsSaga);
}

export function* fetchGeometriesTemplatesSaga(): SagaIterator {
  /* Download Threejs Json in the store (not in browser) */

  const apiRequestKey = getDetailApiRequestKey(dra.fetchTemplates, 'geometries');
  const apiUrlParams = {
    resourceClass: rrc.geometry,
    detailRoute: dr.templates,
  };

  const templates = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  yield put(updateGeometriesTemplatesAction(templates));
}

export function* fetchGeometriesTemplatesActionSaga(): SagaIterator {
  yield call(fetchGeometriesTemplatesSaga);
}

export function* geometryActionsSagas(): Generator {
  yield takeLatest(IMPORT_GEOMETRY_SAGA_ACTION, importGeometryActionSaga);
  yield takeLatest(REFRESH_IMPORT_GEOMETRY_TASK_SAGA_ACTION, refreshImportGeometryTaskActionSaga);
  yield takeLatest(DOWNLOAD_GEOMETRY_THREEJS_SAGA_ACTION, downloadGeometryThreejsActionSaga);
  yield takeLatest(EXPORT_GEOMETRY_SAGA_ACTION, exportGeometryActionSaga);
  yield takeLatest(FETCH_GEOMETRIES_TEMPLATES_SAGA_ACTION, fetchGeometriesTemplatesActionSaga);
}
