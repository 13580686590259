// tslint:disable:no-any
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { FormLabelAsterisk } from 'components/Forms/FormLabelAsterisk';
import { ISelectInputProps } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput/DropdownInput.component';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { getSelectOptions } from 'utils/functions/forms/getSelectOptions';
import { ISelectOption } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { $tcc } from 'utils/functions/translate';

export interface IRadioInputProps extends ISelectInputProps {
  value: any;
  flexDirection?: 'row' | 'column';
  noLabel?: boolean;
}

export class RadioInput extends PureComponent<IRadioInputProps> {
  public render(): ReactNode {
    const { field, value, isDisabled, options, renderOptionLabel, noLabel, label } = this.props;

    const selectOptions = getSelectOptions(options, renderOptionLabel);

    const Label = noLabel ? (
      ''
    ) : (
      <StyledFormLabel>
        {label || $tcc(field)} <FormLabelAsterisk />
      </StyledFormLabel>
    );

    return (
      <FormControl>
        {Label}
        <StyledRadioGroup name={field} value={value} onChange={this.onChange}>
          {_map(selectOptions, (option: ISelectOption) => {
            return (
              <StyledFormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                disabled={!!isDisabled}
              />
            );
          })}
        </StyledRadioGroup>
      </FormControl>
    );
  }

  private onChange = (_e: React.ChangeEvent<{}>, selectedValue: any): void => {
    const { onSelect, field } = this.props;
    onSelect(field, selectedValue);
  };
}

const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: row;
  justify-content: space-around;
` as typeof RadioGroup;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0;
` as typeof FormControlLabel;

const StyledFormLabel = styled(FormLabel)`
  padding-left: 8px;
  font-size: 80%;
` as typeof FormLabel;
