import { Action } from 'redux';

export const UPDATE_AZURE_ERROR_ACTION = 'UPDATE_AZURE_ERROR_ACTION';
export interface IUpdateAzureErrorAction extends Action<'UPDATE_AZURE_ERROR_ACTION'> {
  payload: {
    error?: string;
  };
}
export const updateAzureErrorAction = (error?: string): IUpdateAzureErrorAction => ({
  type: UPDATE_AZURE_ERROR_ACTION,
  payload: {
    error,
  },
});

export const clearAzureErrorAction = (): IUpdateAzureErrorAction => ({
  type: UPDATE_AZURE_ERROR_ACTION,
  payload: {
    error: undefined,
  },
});

export const TOGGLE_UPLOADING_STATUS_ACTION = 'TOGGLE_UPLOADING_STATUS_ACTION';
export interface IToggleUploadingStatusAction extends Action<'TOGGLE_UPLOADING_STATUS_ACTION'> {
  payload: {
    isUploading: boolean;
  };
}
export const toggleUploadingStatusAction = (isUploading: boolean): IToggleUploadingStatusAction => ({
  type: TOGGLE_UPLOADING_STATUS_ACTION,
  payload: {
    isUploading,
  },
});

export const TOGGLE_AZURE_DOWNLOADING_STATUS_ACTION = 'TOGGLE_AZURE_DOWNLOADING_STATUS_ACTION';
export interface IToggleAzureDownloadingStatusAction extends Action<'TOGGLE_AZURE_DOWNLOADING_STATUS_ACTION'> {
  payload: {
    isDownloading: boolean;
  };
}
export const toggleAzureDownloadingStatusAction = (isDownloading: boolean): IToggleAzureDownloadingStatusAction => ({
  type: TOGGLE_AZURE_DOWNLOADING_STATUS_ACTION,
  payload: {
    isDownloading,
  },
});

export type IAzureActions =
  | IUpdateAzureErrorAction
  | IToggleUploadingStatusAction
  | IToggleAzureDownloadingStatusAction;
