import { SimpleCard } from 'components/Cards';
import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import { _map } from 'libs/lodash';
import { _split } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { IDayProfile } from 'types/Obat/DayProfile';
import { IYearProfile } from 'types/Obat/YearProfile';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { orc } from 'utils/strings/resourceClasses';

import { DayGroupDetail } from '../DayGroupDetail/DayGroupDetail.component';
import { DayProfilesPlot } from '../DayProfilesPlot/DayProfilesPlot.component';
import { YearProfileForm } from '../YearProfileForm';

export class YearProfilesPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'yearProfiles'}
        resourceClass={orc.year_profile}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={YearProfileForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 3 },
    { id: 'calendar', resourceField: 'calendar', withFilterOptions: true, gridItemSize: 2 },
    { id: 'dayProfiles', gridItemSize: 7, centerTitle: true },
  ];

  private renderCardColumnData = (yearProfile: IYearProfile, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return yearProfile.ref;
      case 'calendar':
        return yearProfile.calendar;
      case 'dayProfiles':
        return (
          yearProfile.day_profiles_contents && (
            <DayProfilesPlot dayProfiles={yearProfile.day_profiles_contents} yearProfileType={yearProfile.type} />
          )
        );
    }
  };

  private renderCardDetail = (yearProfile: IYearProfile): ReactNode => {
    return (
      <SimpleCard>
        {_map(yearProfile.day_profiles_contents, (dayProfile: IDayProfile, position: number) => (
          <DayGroupDetail
            key={dayProfile.id}
            dayProfileSeries={dayProfile.series}
            dayGroup={_split(dayProfile.day_group, '~')[1]}
            position={position}
          />
        ))}
      </SimpleCard>
    );
  };
}
