import { connect } from 'react-redux';
import { selectObatContentsFlatOptionsList } from 'redux/enums/selectors';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import {
  selectGroupedObatResourcesCreationPositionOptions,
  selectGroupedObatResourcesIdsListFunction,
  selectObatResourcesIdsList,
} from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { ocfe } from 'utils/configs/enums';
import { orc } from 'utils/strings/resourceClasses';

import { SurfaceGroupForm as SurfaceGroupFormComponent } from './SurfaceGroupForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const surfaceGroupToEdit = selectFormInitialResourceFromTable(orc.surface_group)(rootState);

  return {
    surfaceGroupToEdit,

    surfaceCategoryOptions: selectObatContentsFlatOptionsList(ocfe.surface_categories)(rootState),
    allConstructionOptions: selectObatResourcesIdsList(orc.construction)(rootState),
    selectOpeningsBySurfaceCategoryOptions: selectGroupedObatResourcesIdsListFunction(
      orc.opening,
      'surface_category'
    )(rootState),
    selectConstructionsBySurfaceCategoryOptions: selectGroupedObatResourcesIdsListFunction(
      orc.construction,
      'surface_category'
    )(rootState),
    groupedCreatePositionOptions: selectGroupedObatResourcesCreationPositionOptions(
      orc.surface_group,
      'surface_category'
    )(rootState),
  };
};

const mapDispatchToProps = {};

export const SurfaceGroupForm = connect(mapStateToProps, mapDispatchToProps)(SurfaceGroupFormComponent);
