import { CircularLoader } from 'components/Loader';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { ITooltipProps } from 'components/Tooltip/SimpleTooltip/SimpleTooltip.component';
import { _includes } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { lightenDarkenColor } from 'utils/functions/lightenDarkenColor';
import { $t } from 'utils/functions/translate';
import { pa } from 'utils/strings/permissionAlerts';

interface IProps {
  type?: 'button' | 'submit' | 'reset' | undefined;
  Icon?: ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  isSeatMissing?: boolean;
  isWritePermissionMissing?: boolean;
  isAdminPermissionMissing?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  noShadow?: boolean;
  isSmall?: boolean;
  tooltipProps?: ITooltipProps;
  color?: string;
  bgColor?: string;
  borderColor?: string;
  hoverColor?: string;
  hoverBgColor?: string;
  hoverBorderColor?: string;
  text?: string;
  height?: number;
  isFullWidth?: boolean;
  isError?: boolean;
}

export class Button extends PureComponent<IProps> {
  public render(): ReactNode {
    const {
      type,
      Icon,
      isLoading,
      isAdminPermissionMissing,
      isWritePermissionMissing,
      isSeatMissing,
      tooltipProps,
      noShadow,
      color,
      bgColor,
      borderColor,
      hoverColor,
      hoverBgColor,
      text,
      height,
      isFullWidth,
      isError,
    } = this.props;

    const content = isAdminPermissionMissing
      ? $t(pa.adminPermissionRequired)
      : isWritePermissionMissing
      ? $t(pa.writePermissionRequired)
      : isSeatMissing
      ? $t(pa.seatRequired)
      : tooltipProps
      ? tooltipProps.content
      : undefined;

    const isDisabled = isAdminPermissionMissing || isWritePermissionMissing || isSeatMissing || this.props.isDisabled;

    return (
      <SimpleTooltip content={content} placement={tooltipProps ? tooltipProps.placement : 'left'}>
        <StyledButton
          type={type || 'button'}
          isDisabled={isDisabled}
          onClick={this.onClick}
          isExtended={!!text}
          noShadow={noShadow}
          color={color}
          bgColor={bgColor}
          borderColor={isError ? theme.colors.error : borderColor}
          hoverColor={hoverColor}
          hoverBgColor={hoverBgColor}
          height={height}
          isFullWidth={isFullWidth}
        >
          {isLoading ? (
            <CircularLoader size={12} dark />
          ) : (
            <ButtonContent>
              {!!Icon && Icon}
              {!!text && <TextContainer isIcon={!!Icon}>{text}</TextContainer>}
            </ButtonContent>
          )}
        </StyledButton>
      </SimpleTooltip>
    );
  }

  private onClick = (e: React.MouseEvent): void => {
    const { onClick, isAdminPermissionMissing, isWritePermissionMissing, isSeatMissing } = this.props;
    const isDisabled = isAdminPermissionMissing || isWritePermissionMissing || isSeatMissing || this.props.isDisabled;
    e.stopPropagation();
    if (!isDisabled && onClick) {
      onClick(e);
    }
  };
}

const getColor = (isDisabled?: boolean, color?: string, bgColor?: string): string => {
  return isDisabled && _includes([theme.colors.transparent, 'white'], bgColor)
    ? theme.colors.button.disabled
    : color || 'white';
};

const getHoverColor = (isDisabled?: boolean, color?: string, bgColor?: string, hoverColor?: string): string => {
  return isDisabled && _includes([theme.colors.transparent, 'white'], bgColor)
    ? theme.colors.font.dark
    : !!hoverColor
    ? hoverColor
    : getColor(isDisabled, color);
};

const getBgColor = (isDisabled?: boolean, bgColor?: string): string => {
  return isDisabled && bgColor && _includes([theme.colors.transparent, 'white'], bgColor)
    ? bgColor
    : isDisabled && !_includes([theme.colors.transparent, 'white'], bgColor)
    ? theme.colors.button.disabled
    : bgColor || theme.colors.main;
};

const getHoverBgColor = (isDisabled?: boolean, bgColor?: string, hoverBgColor?: string): string => {
  const buttonBgcolor = getBgColor(isDisabled, bgColor);

  const buttonHoverBgColor = isDisabled
    ? theme.colors.button.disabled
    : !!hoverBgColor
    ? hoverBgColor
    : _includes([theme.colors.transparent, 'white'], buttonBgcolor)
    ? theme.colors.listItemHover
    : lightenDarkenColor(buttonBgcolor, 20);

  return `${buttonHoverBgColor} radial-gradient(circle, transparent 1%, ${buttonHoverBgColor} 1%) center/15000%`;
};

interface IStyledFabProps {
  color?: string;
  bgColor?: string;
  borderColor?: string;
  hoverColor?: string;
  hoverBgColor?: string;
  hoverBorderColor?: string;
  isDisabled?: boolean;
  noShadow?: boolean;
  isExtended?: boolean;
  isSmall?: boolean;
  height?: number;
  isFullWidth?: boolean;
}

const inactiveBoxShadow =
  '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)';

const activeBowShadow =
  '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props: IStyledFabProps): string => (props.height || 40) + 'px'};
  width: ${(props: IStyledFabProps): string =>
    props.isFullWidth ? '100%' : props.isExtended ? 'auto' : (props.height || 40) + 'px'};
  padding: ${(props: IStyledFabProps): string => (props.isExtended ? '0 8px' : '0')};
  box-sizing: border-box;
  border: ${(props: IStyledFabProps): string => (props.borderColor ? `solid 1px ${props.borderColor}` : 'none')};
  border-radius: ${(props: IStyledFabProps): string => (props.isExtended ? '24px' : '50%')};
  color: ${(props: IStyledFabProps): string => getColor(props.isDisabled, props.color, props.bgColor)};
  background-color: ${(props: IStyledFabProps): string => getBgColor(props.isDisabled, props.bgColor)};
  cursor: ${(props: IStyledFabProps): string => (!props.isDisabled ? 'pointer' : '')};
  box-shadow: ${(props: IStyledFabProps): string => (props.noShadow ? 'none' : inactiveBoxShadow)};
  transition: background 0.8s, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  &:hover {
    color: ${(props: IStyledFabProps): string =>
      getHoverColor(props.isDisabled, props.color, props.bgColor, props.hoverColor)};
    background: ${(props: IStyledFabProps): string =>
      getHoverBgColor(props.isDisabled, props.bgColor, props.hoverBgColor)};
  }
  &:focus {
    outline: none;
  }
  &:active {
    background-color: ${(props: IStyledFabProps): string => getBgColor(props.isDisabled, props.bgColor)};
    background-size: 100%;
    transition: background 0s;
    box-shadow: ${(props: IStyledFabProps): string =>
      props.noShadow ? 'none' : props.isDisabled ? inactiveBoxShadow : activeBowShadow};
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  margin-top: ${(props: { isIcon: boolean }): string => (props.isIcon ? -2 : 0) + 'px'};
  margin-left: ${(props: { isIcon: boolean }): string => (props.isIcon ? 4 : 0) + 'px'};
  font-size: 0.8rem;
`;
