// tslint:disable:no-any
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormLabelAsterisk } from 'components/Forms/FormLabelAsterisk/FormLabelAsterisk';
import { ObatResourceCommitmentsTooltip } from 'components/Tooltip/ObatResourceCommitmentsTooltip';
import { _find } from 'libs/lodash';
import React, { Component, ReactNode } from 'react';
import styled from 'styled-components';
import { IObatResource } from 'types/Obat/ObatResource';
import { getSelectOptions } from 'utils/functions/forms/getSelectOptions';
import { ISelectOption, ISelectOptions } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { isCommittedForEdit } from 'utils/functions/obatResources/commitments/isCommitted';
import { $tcc } from 'utils/functions/translate';

import { ReactSelect, SelectWrapper } from '../ReactSelect';

export interface ISelectInputProps {
  field: string;
  onSelect: (field: any, value: any) => void /* This function should have the same type as formikProps.setFieldValue */;
  label?: string;
  options?: ISelectOptions | string[];
  renderOptionLabel?: (value: any) => any;
  errorText?: any;
  isDisabled?: boolean;
  inputRef?: any;
}

export interface IDropdownInputProps extends ISelectInputProps {
  value: any;
  obatResourceToBeCheckedForCommitment?: IObatResource;
  optionsPlacement?: 'bottom' | 'top' | 'auto' | undefined;
  optionsPosition?: 'fixed' | 'absolute' | undefined;
  required?: boolean;
  noLabel?: boolean;
  onBlur?: (e: React.FocusEvent<any>) => void;
}

export class DropdownInput extends Component<IDropdownInputProps> {
  public hasValue: boolean = false;

  public shouldComponentUpdate(): boolean {
    /* this allows to force update. Otherwise, unit field in schedules won't update even if only one option. */
    return true;
  }

  public componentDidUpdate(): void {
    const { field, onSelect, options, value, renderOptionLabel } = this.props;

    const selectOptions = getSelectOptions(options, renderOptionLabel);

    if (selectOptions.length === 1 && value !== selectOptions[0].value) {
      /* this is ineffective if triggered in componentDidMount, so choice has been made to not disable field */
      onSelect(field, selectOptions[0].value);
    }
  }

  public render(): ReactNode {
    const {
      field,
      value,
      required,
      errorText,
      obatResourceToBeCheckedForCommitment,
      optionsPlacement,
      optionsPosition,
      options,
      renderOptionLabel,
      onBlur,
      inputRef,
      noLabel,
    } = this.props;

    const label = noLabel ? (
      ''
    ) : (
      <span>
        {this.props.label || $tcc(field)} <FormLabelAsterisk required={required} />
      </span>
    );
    const selectOptions = getSelectOptions(options, renderOptionLabel);

    let selectedOption: ISelectOption = { value: '', label: '' };
    selectedOption = _find(selectOptions, ['value', value]) || selectedOption;

    if (selectedOption && selectedOption.label === '') {
      this.hasValue = false;
    }

    const isCommitted = obatResourceToBeCheckedForCommitment
      ? isCommittedForEdit(obatResourceToBeCheckedForCommitment, field)
      : false;

    const isDisabled =
      this.props.isDisabled ||
      isCommitted ||
      (selectOptions.length === 1 && !!selectedOption && selectedOption.label !== '');

    const selectInput: ReactNode = (
      <div className={`o-autocomplete`}>
        <SelectWrapper className={`${isDisabled ? 'disabled' : ''}`}>
          <LabelWrapper
            className={`${
              this.hasValue || (selectedOption && selectedOption.value !== '' && selectedOption.label !== '')
                ? 'has-value'
                : ''
            }`}
          >
            <FormHelperText error={!!errorText}>{label}</FormHelperText>
          </LabelWrapper>
          <ReactSelect
            isDisabled={isDisabled}
            optionsPlacement={optionsPlacement}
            optionsPosition={optionsPosition}
            name={field}
            onBlur={onBlur}
            handleChange={this.handleChange}
            options={selectOptions}
            selectedOption={selectedOption}
            inputRef={inputRef}
          />
        </SelectWrapper>
        {!!errorText && <FormHelperText error>{errorText}</FormHelperText>}
      </div>
    );

    return obatResourceToBeCheckedForCommitment && isCommitted ? (
      <ObatResourceCommitmentsTooltip
        editionField={field}
        obatResource={obatResourceToBeCheckedForCommitment}
        placement={'left'}
      >
        {selectInput}
      </ObatResourceCommitmentsTooltip>
    ) : (
      selectInput
    );
  }

  private handleChange = (selectedOption: any): void => {
    const { onSelect, field } = this.props;
    onSelect(field, selectedOption.value);
    this.hasValue = selectedOption.value !== '';
  };
}

const LabelWrapper = styled.div`
  background: transparent !important;
  margin-top: -3px;
  padding-left: 10px;
  > p {
    color: #a6a6a6;
    transform: translate(-1px, 13px) scale(1);
    font-size: 14px;
    pointer-events: none;
    margin-top: 5px;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform-origin: top left;
  }
  &.has-value {
    > p {
      transform: translate(-1px, 3px) scale(1);
      margin-top: 6px;
      font-size: 10.5px;
      margin-bottom: 2.5px;
    }
  }
`;
