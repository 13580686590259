import { WithProjectSubMenu } from 'components/Menus/WithProjectSubMenu';
import { _map, _split } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import {
  getMonoSimulationGroupMenuPageRoute,
  IMonoSimulationGroupMenuPage,
  monoSimulationGroupMenuPages,
  projectMenuPages,
} from 'routing/routes';
import { $t } from 'utils/functions/translate';
import { IProps } from './WithMonoSimulationGroupMenu.container';

export class WithMonoSimulationGroupMenu extends PureComponent<IProps> {
  public render = (): ReactNode => {
    const { selectedPage, routeMonoSimulationGroup, children } = this.props;

    const getItemTitleFromPage = (page: string) => $t(_split(page, 'Mono')[0]);

    let pagesDivision: IMonoSimulationGroupMenuPage[][] = [[monoSimulationGroupMenuPages.configMono]];

    if (routeMonoSimulationGroup.configured) {
      pagesDivision = [[monoSimulationGroupMenuPages.configMono, monoSimulationGroupMenuPages.monitorMono]];
    }

    if (routeMonoSimulationGroup.status === 'success') {
      pagesDivision = [
        ...pagesDivision,
        [monoSimulationGroupMenuPages.monthlyResultsMono, monoSimulationGroupMenuPages.hourlyResultsMono],
      ];
    }

    return (
      <WithProjectSubMenu
        selectedSubMenu={projectMenuPages.simulationGroups}
        subMenuTitle={$t('simulation')}
        selectedPage={selectedPage}
        subMenuRouteResource={routeMonoSimulationGroup}
        subMenuPagesDivision={pagesDivision}
        getSubMenuPageRoute={getMonoSimulationGroupMenuPageRoute}
        getSubMenuItemTitleFromPage={getItemTitleFromPage}
      >
        {children}
      </WithProjectSubMenu>
    );
  };
}
