import { NewSubFormButton, StartReorderingButton, StopReorderingButton } from 'components/Forms/Buttons/SubFormButtons';
import { getReorderForm } from 'components/Forms/ReorderForm';
import { Section } from 'components/Section/Section.component';
import { _isEqual } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { IModification } from 'types/Obat/Modification';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { ModificationForm } from '../ModificationForm';

import { IProps } from './ModificationsFormSection.container';

interface IState {
  modificationsNumber: number;
  newModificationFormOpen: boolean;
}

const ReorderForm = getReorderForm<IModification>();

export class ModificationsFormSection extends PureComponent<IProps> {
  public state: IState = {
    newModificationFormOpen: false,
    modificationsNumber: 0,
  };

  public componentDidUpdate(prevProps: IProps): void {
    if (!_isEqual(prevProps.variant, this.props.variant)) {
      this.setState({ newModificationFormOpen: false });
      this.props.toggleReordering(false);
    }
  }

  public render(): ReactNode {
    const { variant, isReordering } = this.props;
    if (!variant) {
      return null;
    }

    const onStartReordering = (): void => {
      this.props.toggleReordering(true);
    };

    const onStopReordering = (): void => {
      this.props.toggleReordering(false);
    };

    return (
      <Section title={$t('modifications')} marginTop={20}>
        {!!isReordering && (
          <>
            <StopReorderingButton onClick={onStopReordering} text={$t('cancel')} />
            <ReorderForm resourcesToReorder={variant.modifications_contents} resourceClass={orc.modification} />
          </>
        )}

        {!isReordering && (
          <>
            {_size(variant.modifications_contents) > 1 && <StartReorderingButton onClick={onStartReordering} />}
            {_map(variant.modifications_contents, (modification: IModification, index: number) => (
              <ModificationForm
                variant={variant}
                modification={modification}
                key={modification.weak_ref}
                titleIndex={++index}
              />
            ))}
            {!this.state.newModificationFormOpen && (
              <NewSubFormButton onClick={this.openNewModificationForm} text={$t('addModification')} />
            )}
            {this.state.newModificationFormOpen && (
              <ModificationForm
                variant={variant}
                close={this.closeNewModificationForm}
                titleIndex={this.state.modificationsNumber}
              />
            )}
          </>
        )}
      </Section>
    );
  }

  private closeNewModificationForm = (): void => {
    this.setState({ newModificationFormOpen: false });
  };

  private openNewModificationForm = (): void => {
    this.setState({
      newModificationFormOpen: true,
      modificationsNumber: _size(this.props.variant.modifications_contents) + 1,
    });
  };
}
