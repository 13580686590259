import { Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { ISelectInputProps } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput/DropdownInput.component';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { $tcc } from 'utils/functions/translate';

export interface ICheckboxInputProps extends ISelectInputProps {
  value: boolean;
  noLabel?: boolean;
}

export class CheckboxInput extends PureComponent<ICheckboxInputProps> {
  public render(): ReactNode {
    const { value, errorText, isDisabled, inputRef, field, label } = this.props;
    const Label = <StyledBoxLabel>{label || $tcc(field)}</StyledBoxLabel>;

    return (
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              name={field}
              checked={!!value}
              value={value}
              onChange={this.onChange}
              color={'primary'}
              disabled={isDisabled}
              ref={inputRef}
            />
          }
          label={Label}
        />
        {!!errorText && <FormHelperText error>{errorText}</FormHelperText>}
      </FormControl>
    );
  }

  private onChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    const { field, onSelect } = this.props;
    onSelect(field, checked);
  };
}

const StyledBoxLabel = styled(FormLabel)`
  color: black;
` as typeof FormLabel;
