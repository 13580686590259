import Grid from '@material-ui/core/Grid';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { FormikProps } from 'formik';
import { getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IBridgeRequestParams } from 'types/Obat/Bridge';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './BridgeForm.container';

export interface IBridgeFormValues {
  construction_a: string;
  construction_b: string;
  psi: number | '';
  ref: string;
  filter_geometries: string;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IBridgeFormValues, IBridgeRequestParams>();

export class BridgeForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.bridge}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IBridgeFormValues>): ReactNode => {
      const { constructionOptions } = this.props;

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput field={'psi'} formikProps={formikProps} required label={$t('psi') + '[W/m,K]'} />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'construction_a'}
                label={$t('construction1')}
                formikProps={formikProps}
                options={constructionOptions}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'construction_b'}
                label={$t('construction2')}
                formikProps={formikProps}
                options={constructionOptions}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'filter_geometries'}
                formikProps={formikProps}
                label={$t('geometriesFilter')}
                multiline
              />
            </Grid>
          </Grid>
        </>
      );
    };
  };

  private getInitialFormValues = (): IBridgeFormValues => {
    const { bridgeToEdit } = this.props;

    return {
      construction_a: setFormInitialValue(bridgeToEdit, 'construction_a'),
      construction_b: setFormInitialValue(bridgeToEdit, 'construction_b'),
      psi: setFormInitialValue(bridgeToEdit, 'psi'),
      ref: setFormInitialValue(bridgeToEdit, 'ref'),
      filter_geometries: setFormInitialValue(bridgeToEdit, 'filter_geometries'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IBridgeFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      construction_a: getYupStringValidation(true),
      construction_b: getYupStringValidation(true),
      psi: getYupNumberValidation(true),
      filter_geometries: getYupStringValidation(),
    }) as yup.ObjectSchema<IBridgeFormValues>;
  };
}
