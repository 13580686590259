import { LogsConsole } from 'components/Consoles/LogsConsole';
import { ModalFrame } from 'components/Modals/ModalFrame';
import { SimulationConfigCard } from 'components/SimulationGroup/SimulationConfigCard';
import React, { PureComponent, ReactNode } from 'react';

import { IProps } from './LogsModal.container';

export class LogsModal extends PureComponent<IProps> {
  public render(): ReactNode {
    const { simulation, isLogsModalOpen, closeLogsModal } = this.props;

    if (!simulation) {
      return null;
    }

    const Title = <span>{simulation.name}</span>;

    const Content = (
      <>
        <SimulationConfigCard withStatus />
        <LogsConsole />
      </>
    );

    return (
      <ModalFrame isOpen={isLogsModalOpen} onClose={closeLogsModal} Content={Content} Title={Title} maxWidth={'xl'} />
    );
  }
}
