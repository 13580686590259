import { _omit } from 'libs/lodash';
import { IApiErrors } from 'types/Errors';

import {
  API_REQUEST_CALLBACK_SUCCESS_ACTION,
  API_REQUEST_ERROR_ACTION,
  API_REQUEST_SUCCESS_ACTION,
  CLEAR_ALL_API_REQUESTS_ACTION,
  CLEAR_SINGLE_API_REQUEST_ACTION,
  IApiRequestsActions,
  START_API_REQUEST_ACTION,
} from './actions';

export interface IApiRequestsState {
  [apiRequestKey: string]: {
    errorMessage?: string;
    errors?: IApiErrors;
    isPending: boolean;
    status?: number;
  };
}

export const initialState: IApiRequestsState = {};

export const apiRequestsReducer = (
  state: IApiRequestsState = initialState,
  action: IApiRequestsActions
): IApiRequestsState => {
  switch (action.type) {
    case START_API_REQUEST_ACTION: {
      return {
        ...state,
        [action.payload.apiRequestKey]: {
          isPending: true,
        },
      };
    }
    case API_REQUEST_SUCCESS_ACTION: {
      return action.payload.hasCallback ? state : _omit(state, [action.payload.apiRequestKey]);
    }
    case API_REQUEST_ERROR_ACTION: {
      return {
        ...state,
        [action.payload.apiRequestKey]: {
          ...state[action.payload.apiRequestKey],
          isPending: false,
          errors: action.payload.errors,
          errorMessage: action.payload.errorMessage,
          status: action.payload.status,
        },
      };
    }
    case API_REQUEST_CALLBACK_SUCCESS_ACTION: {
      return _omit(state, [action.payload.apiRequestKey]);
    }
    case CLEAR_SINGLE_API_REQUEST_ACTION: {
      return _omit(state, [action.payload.apiRequestKey]);
    }
    case CLEAR_ALL_API_REQUESTS_ACTION: {
      return initialState;
    }
    default:
      return state;
  }
};
