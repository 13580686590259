import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath, selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { IRootState } from 'redux/reducer';
import { fetchObatVariantsSagaAction } from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectSimulationGroupConfigOptions,
  selectVariantOptionsByObat,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { editRestResourceSagaAction } from 'redux/restResources/generic/actions';
import { selectRouteResource } from 'redux/routing/selectors';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { gra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { ConfigForm as ConfigFormComponent } from './ConfigForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeMonoSimulationGroup = selectRouteResource(rrc.mono_simulation_group)(rootState);

  return {
    routeMonoSimulationGroup,

    configOptions: selectSimulationGroupConfigOptions(rootState),
    variantOptionsByObat: selectVariantOptionsByObat(rootState),

    isSubmitting:
      !!routeMonoSimulationGroup &&
      selectIsApiRequestPending(
        getGenericApiRequestKey(gra.edit, rrc.mono_simulation_group, routeMonoSimulationGroup.id)
      )(rootState),
    editErrors:
      routeMonoSimulationGroup &&
      selectFormApiErrorsTranslationPath(
        getGenericApiRequestKey(gra.edit, rrc.mono_simulation_group, routeMonoSimulationGroup.id)
      )(rootState),
    createErrors: selectFormApiErrorsTranslationPath(getGenericApiRequestKey(gra.create, rrc.mono_simulation_group))(
      rootState
    ),
  };
};

const mapDispatchToProps = {
  editRestResource: editRestResourceSagaAction,
  fetchObatVariants: fetchObatVariantsSagaAction,
};

export const ConfigForm = connect(mapStateToProps, mapDispatchToProps)(ConfigFormComponent);
