import Grid from '@material-ui/core/Grid';
import { SubFormCard } from 'components/Cards';
import { CloseSubFormButton } from 'components/Forms/Buttons/SubFormButtons';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceForm } from 'components/Forms/ObatResourceForm';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import { PeriodsFormSection } from 'pages/ObatMenu/Calendars/PeriodsFormSection';
import React, { PureComponent, ReactNode } from 'react';
import { IDayGroupRequestParams } from 'types/Obat/DayGroup';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './DayGroupForm.container';

export interface IDayGroupFormValues {
  calendar: string;
  weak_ref: string;
}

const ObatResourceForm = getObatResourceForm<IDayGroupFormValues, IDayGroupRequestParams>();

export class DayGroupForm extends PureComponent<IProps> {
  public render(): ReactNode {
    const { dayGroupToEdit, close, titleIndex, isInitiallyCollapsed } = this.props;

    const dayGroupFormTitle = dayGroupToEdit
      ? `${titleIndex}. ${dayGroupToEdit.weak_ref}`
      : `${$t('dayGroup')} ${titleIndex}`;

    return (
      <SubFormCard>
        {!!close && <CloseSubFormButton onClick={close} />}
        <Section noDivider isInitiallyCollapsed={!!isInitiallyCollapsed} title={dayGroupFormTitle} marginTop={-10}>
          <ObatResourceForm
            initialObatResource={dayGroupToEdit}
            renderForm={this.renderForm()}
            getInitialFormValues={this.getInitialFormValues}
            getValidationSchema={this.getValidationSchema}
            resourceClass={orc.day_group}
            renderSubForm={this.renderSubForm}
          />
        </Section>
      </SubFormCard>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IDayGroupFormValues>): ReactNode => {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput field={'weak_ref'} formikProps={formikProps} label={$t('name')} required />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): IDayGroupFormValues => {
    const { dayGroupToEdit, calendar } = this.props;

    return {
      weak_ref: setFormInitialValue(dayGroupToEdit, 'weak_ref'),
      calendar: calendar.ref,
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IDayGroupFormValues> => {
    return yup.object().shape({
      weak_ref: getYupStringValidation(true, true),
    }) as yup.ObjectSchema<IDayGroupFormValues>;
  };

  private renderSubForm = (): ReactNode => {
    const { dayGroupToEdit } = this.props;

    if (dayGroupToEdit) {
      return <PeriodsFormSection dayGroup={dayGroupToEdit} />;
    }
  };
}
