// tslint:disable:no-any
import { _reduce } from 'libs/lodash';

export const convertEmptyFieldsToNull = (formValues: object): any => {
  return _reduce(
    formValues,
    (newFormValues: object, value: any, field: string): object => {
      return { ...newFormValues, [field]: field !== 'comment' && value === '' ? null : value };
    },
    {}
  );
};
