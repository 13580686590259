import Grid from '@material-ui/core/Grid';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikDateInput } from 'components/Forms/Inputs/TextInputs/FormikDateInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getRestResourceFormDrawer } from 'components/Forms/RestResourceFormDrawer';
import { FormikProps } from 'formik';
import { _find, _includes, _isEqual, _omit } from 'libs/lodash';
import { getYupStringValidation } from 'libs/yup';
import moment from 'moment';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { withEmptyOption } from 'utils/functions/forms/withEmptyOption';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './SimulationForm.container';

interface ISimulationFormValues {
  name: string;
  obat_id: string;
  weather_id: string;
  geometry_id: string;
  variant: string;
  start: string;
  end: string;
}

type ISimulationRequestParams = Partial<ISimulationFormValues>;

const RestResourceFormDrawer = getRestResourceFormDrawer<ISimulationFormValues, ISimulationRequestParams>();

export class SimulationForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <RestResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={rrc.simulation}
        forceSubmitEnabled={this.forceSubmitEnabled}
        getRequestParams={this.getRequestParams}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<ISimulationFormValues>): ReactNode => {
      const { configOptions, variantOptionsByObat } = this.props;

      const { obat_id, weather_id, start, end } = formikProps.values;

      const datePickerBounds = this.getDatePickerBounds(weather_id);

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput field={'name'} formikProps={formikProps} required inputRef={this.formFocusRef} />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'geometry_id'}
              formikProps={formikProps}
              options={configOptions.geometries}
              label={$t('geometry')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'obat_id'}
              label={$t('obat')}
              formikProps={formikProps}
              options={configOptions.obats}
              onSelect={this.onObatSelect(formikProps)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'variant'}
              formikProps={formikProps}
              options={withEmptyOption(variantOptionsByObat[obat_id] || [])}
              isDisabled={!obat_id}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'weather_id'}
              label={$t('weather')}
              formikProps={formikProps}
              options={configOptions.weathers}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDateInput
              field={'start'}
              formikProps={formikProps}
              startMode={'day'}
              format={'DD/MM/YYYY'}
              placeholder={'DD/MM/YYYY'}
              minDate={datePickerBounds.minStart}
              customMinDateMessage={
                datePickerBounds.minStart &&
                $t('simulationStartBeforeWeatherStartError', {
                  date: moment.utc(datePickerBounds.minStart).format('DD/MM/YYYY'),
                })
              }
              maxDate={end}
              customMaxDateMessage={$t('startAfterEndError')}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FormikDateInput
              field={'end'}
              formikProps={formikProps}
              endMode={'day'}
              format={'DD/MM/YYYY'}
              placeholder={'DD/MM/YYYY'}
              minDate={start}
              customMinDateMessage={$t('endBeforeStartError')}
              maxDate={datePickerBounds.maxEnd}
              customMaxDateMessage={
                datePickerBounds.maxEnd &&
                $t('simulationEndAfterWeatherEndError', {
                  date: moment.utc(datePickerBounds.maxEnd).format('DD/MM/YYYY'),
                })
              }
              required
            />
          </Grid>
        </Grid>
      );
    };
  };

  private getDatePickerBounds = (selectedWeatherId: string): { minStart?: string; maxEnd?: string } => {
    const { configOptions } = this.props;

    const selectedWeatherOption = _find(configOptions.weathers, ['value', selectedWeatherId]);

    return {
      minStart: selectedWeatherOption && selectedWeatherOption.start,
      maxEnd: selectedWeatherOption && selectedWeatherOption.end,
    };
  };

  private onObatSelect = (formikProps: FormikProps<ISimulationFormValues>) => {
    return (_field: string, selectedObatId: string): void => {
      const { fetchObatVariants } = this.props;
      fetchObatVariants(selectedObatId);
      formikProps.setValues({
        ...formikProps.values,
        obat_id: selectedObatId,
        variant: '',
      });
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<ISimulationFormValues> => {
    return yup.object().shape({
      name: getYupStringValidation(true),
      obat_id: getYupStringValidation(true),
      weather_id: getYupStringValidation(true),
      geometry_id: getYupStringValidation(true),
      variant: getYupStringValidation(),
      start: getYupStringValidation(true),
      end: getYupStringValidation(true),
    }) as yup.ObjectSchema<ISimulationFormValues>;
  };

  private getInitialFormValues = (): ISimulationFormValues => {
    const { simulationToEdit } = this.props;

    return {
      name: setFormInitialValue(simulationToEdit, 'name'),
      obat_id: setFormInitialValue(simulationToEdit, 'obat_id'),
      weather_id: setFormInitialValue(simulationToEdit, 'weather_id'),
      geometry_id: setFormInitialValue(simulationToEdit, 'geometry_id'),
      variant: setFormInitialValue(simulationToEdit, 'variant'),
      start: simulationToEdit ? simulationToEdit.start : moment.utc('2019-01-01').toISOString(),
      end: simulationToEdit
        ? simulationToEdit.end
        : moment
            .utc('2019-12-31')
            .endOf('day')
            .toISOString(),
    };
  };

  private getRequestParams = (formValues: ISimulationFormValues): ISimulationRequestParams => {
    const { simulationToEdit, variantOptionsByObat } = this.props;
    const { variant, obat_id } = formValues;

    const initialFormValues = this.getInitialFormValues();

    const filteredFinalFormValues = simulationToEdit ? filterEditedValues(initialFormValues, formValues) : formValues;

    const requestParamsFields =
      !!variant && !_includes(variantOptionsByObat[obat_id], variant)
        ? { ...filteredFinalFormValues, variant: '' }
        : filteredFinalFormValues;

    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams as ISimulationRequestParams;
  };

  private forceSubmitEnabled = (formikProps: FormikProps<ISimulationFormValues>): boolean => {
    const { variantOptionsByObat: variantOptionsByObat } = this.props;
    const {
      values: { obat_id },
    } = formikProps;

    const initialFormValues = this.getInitialFormValues();
    const initialVariant = initialFormValues.variant;

    return (
      !!initialVariant &&
      !_includes(variantOptionsByObat[obat_id], initialVariant) &&
      _isEqual(_omit(formikProps.values), _omit(initialFormValues))
    );
  };
}
