import { _map, _max } from 'libs/lodash';
import { IDayProfile, IDayProfileSeries } from 'types/Obat/DayProfile';

export const calculateDayScheduleSeries = (
  dayProfile: IDayProfile,
  value?: number,
  isOnOff?: boolean,
  baseload?: number | null
): IDayProfileSeries => {
  const series = { ...dayProfile.series };

  // set name
  series.name = dayProfile.id;

  // set value (not null or undefined)
  if (value || value === 0) {
    series.data = _map(series.data, (v: number): number => {
      return v * value;
    });
  }

  // baseload (not null or undefined)
  if (baseload || baseload === 0) {
    series.data = _map(series.data, (v: number): number => {
      // @ts-ignore
      return _max([v, baseload]);
    });
  }

  // as on/off
  if (isOnOff) {
    series.data = _map(series.data, (v: number): number => {
      return v ? 1 : 0;
    });
  }

  return series;
};
