import { IRestResourceClass, rrc } from 'utils/strings/resourceClasses';

export const navigationResourcesHierarchy: IRestResourceClass[][] = [
  [rrc.organization, rrc.project],
  [
    /* PROJECT */
    rrc.geometry,
    rrc.obat,
    rrc.weather,
    rrc.mono_simulation_group,
    rrc.multi_simulation_group,
    rrc.user_project_permission,

    /* ORGANIZATION */
    rrc.project_visible,
    rrc.user_organization_permission,
  ],
];
