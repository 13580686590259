import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import * as yup from 'yup';

import { IProps } from './ProjectionForm.container';

interface IProjectionFormValues {
  geometry: string;
}

const FormDrawer = getFormDrawer();
const Form = getForm<IProjectionFormValues>();

export class ProjectionForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { getInitialFormValues, getValidationSchema, props } = this;
    const { isProjectionTaskRunning } = props;

    return (
      <FormDrawer
        formKey={fk.projection}
        width={fdw.small}
        title={$t('selectGeometry')}
        formFocusRef={this.formFocusRef}
      >
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={getInitialFormValues}
          getValidationSchema={getValidationSchema}
          isSubmitting={isProjectionTaskRunning}
          onSubmit={this.onSubmit}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IProjectionFormValues>): ReactNode => {
      const { geometriesOptions } = this.props;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'geometry'}
              formikProps={formikProps}
              options={geometriesOptions}
              required
              inputRef={this.formFocusRef}
            />
          </Grid>
        </Grid>
      );
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IProjectionFormValues> => {
    return yup.object().shape({
      geometry: getYupStringValidation(true),
    }) as yup.ObjectSchema<IProjectionFormValues>;
  };

  private onSubmit = (formValues: IProjectionFormValues): void => {
    const { fetchProjectionData, closeFormDrawer } = this.props;
    const projectionParams = { ...formValues, with_bridges: false };
    fetchProjectionData(projectionParams);
    closeFormDrawer();
  };

  private getInitialFormValues = (): IProjectionFormValues => {
    const { projectionParams } = this.props;

    return {
      geometry: projectionParams.geometry,
    };
  };
}
