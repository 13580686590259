import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Button } from 'components/Buttons';
import { getForm } from 'components/Forms/Form';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { FormikProps } from 'formik';
import { getYupStringValidation, yupErrors } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { Check } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';
import { navigateRoute } from 'routing/navigateRoute';
import { getHomeMenuPageRoute, homeMenuPages } from 'routing/routes';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import * as yup from 'yup';

import { IProps } from './Login.container';

interface IState {
  isPasswordVisible: boolean;
}
export interface ILoginFormValues {
  email?: string;
  password?: string;
}

const Form = getForm<ILoginFormValues>();

export class Login extends PureComponent<IProps & RouteComponentProps> {
  public state: IState = {
    isPasswordVisible: false,
  };
  private formFocusRef = createRef<HTMLInputElement>();

  public componentDidUpdate(prevProps: IProps): void {
    const { accessToken, location } = this.props;

    if (accessToken && prevProps.accessToken !== accessToken) {
      const targetRoute =
        !!location &&
        !!location.state &&
        // @ts-ignore
        location.state.targetRoute;
      navigateRoute(targetRoute || getHomeMenuPageRoute(homeMenuPages.myProjects));
    }
  }

  public render(): ReactNode {
    const { loginErrorStatus } = this.props;

    return (
      <LoginContainer>
        <OpenergyLogoContainer>
          <img src={process.env.PUBLIC_URL + '/images/logoOpenergyWhite.png'} />
        </OpenergyLogoContainer>
        <LoginCard>
          <OplusLogoContainer>
            <img src={process.env.PUBLIC_URL + '/images/logoOplus.png'} />
          </OplusLogoContainer>
          <Heading>{$t('loginHeader')}</Heading>
          <Form
            renderForm={this.renderForm()}
            getInitialFormValues={this.getInitialFormValues}
            getValidationSchema={this.getValidationSchema}
            onSubmit={this.onSubmit}
            isSubmitting={false}
            renderBottomButtons={this.renderBottomButtons}
          />
          {loginErrorStatus && (
            <ErrorMessage>
              {loginErrorStatus === 401
                ? $t('invalidCredentials')
                : loginErrorStatus === 429
                ? $t('tooManyLoginAttempts')
                : $t('serverError')}
            </ErrorMessage>
          )}
        </LoginCard>
      </LoginContainer>
    );
  }

  public renderForm = () => {
    const { isPasswordVisible } = this.state;

    return (formikProps: FormikProps<ILoginFormValues>): ReactNode => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledFormikTextInput
              field={'email'}
              formikProps={formikProps}
              withSpaceForError={true}
              required
              inputRef={this.formFocusRef}
              onKeyDown={this.clearError}
              // autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <StyledFormikTextInput
              field={'password'}
              formikProps={formikProps}
              withSpaceForError={true}
              required
              type={isPasswordVisible ? 'text' : 'password'}
              onKeyDown={this.clearError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.togglePasswordVisibility}>
                      {isPasswordVisible ? (
                        <VisibilityOffIcon fontSize={'small'} />
                      ) : (
                        <VisibilityIcon fontSize={'small'} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      );
    };
  };

  public renderBottomButtons = (formikProps: FormikProps<ILoginFormValues>): ReactNode => {
    const { loginErrorStatus } = this.props;

    return (
      <SubmitContainer>
        <Button
          type={'submit'}
          Icon={<Check size={18} />}
          isDisabled={!(formikProps.isValid && formikProps.dirty)}
          isError={!!loginErrorStatus}
        />
      </SubmitContainer>
    );
  };

  private togglePasswordVisibility = (): void => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  private clearError = (): void => {
    this.props.clearSingleApiRequest('fetchTokens');
  };

  private getInitialFormValues = (): ILoginFormValues => {
    return {
      email: '',
      password: '',
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<ILoginFormValues> => {
    return yup.object().shape({
      email: getYupStringValidation(true).email(yupErrors.invalidEmail),
      password: getYupStringValidation(true),
    }) as yup.ObjectSchema<ILoginFormValues>;
  };

  private onSubmit = (formValues: ILoginFormValues): void => {
    this.props.fetchTokens(formValues.email || '', formValues.password || '');
  };
}

const OpenergyLogoContainer = styled.div`
  width: 307px;
  height: 79px;
  position: absolute;
  right: 25px;
  bottom: 25px;
  img {
    width: 100%;
  }
`;

const OplusLogoContainer = styled.div`
  position: unset;
  text-align: center;
  width: 100%;
  img {
    width: 110px;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${process.env.PUBLIC_URL + '/images/loginBackground.jpg'}) no-repeat;
  background-size: cover;
`;

const LoginCard = styled.div`
  width: 350px;
  height: 470px;
  border-radius: 8px;
  border: solid 1px white;
  padding: 25px 40px;
`;

const Heading = styled.p`
  text-align: center;
  font-size: 20px;
  margin: 0 0 50px;
  color: white;
  a {
    text-decoration: underline;
    color: white;
  }
`;

const ErrorMessage = styled.div`
  background-color: ${theme.colors.error};
  border-radius: 5px;
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 4px;
  text-align: center;
`;

const SubmitContainer = styled.div`
  margin: 16px 0;
  display: flex;
  justify-content: center;
`;

const StyledFormikTextInput = (styled(FormikWriteInput)`
  div {
    background-color: #ededed !important;
  }
` as unknown) as typeof FormikWriteInput;
