import { Action } from 'redux';

export const UPDATE_VARIANT_CONTEXT_ID_SAGA_ACTION = 'UPDATE_VARIANT_CONTEXT_SAGA_ACTION';
export interface IUpdateVariantContextIdSagaAction extends Action<'UPDATE_VARIANT_CONTEXT_SAGA_ACTION'> {
  payload: {
    variantId?: string;
  };
}
export const updateVariantContextIdSagaAction = (variantId?: string): IUpdateVariantContextIdSagaAction => ({
  type: UPDATE_VARIANT_CONTEXT_ID_SAGA_ACTION,
  payload: {
    variantId,
  },
});

export const clearVariantContextIdSagaAction = (): IUpdateVariantContextIdSagaAction => ({
  type: UPDATE_VARIANT_CONTEXT_ID_SAGA_ACTION,
  payload: {
    variantId: undefined,
  },
});

export const UPDATE_VARIANT_CONTEXT_ID_ACTION = 'UPDATE_VARIANT_CONTEXT_ACTION';
export interface IUpdateVariantContextIdAction extends Action<'UPDATE_VARIANT_CONTEXT_ACTION'> {
  payload: {
    variantId?: string;
  };
}
export const updateVariantContextIdAction = (variantId?: string): IUpdateVariantContextIdAction => ({
  type: UPDATE_VARIANT_CONTEXT_ID_ACTION,
  payload: {
    variantId,
  },
});

export const UPDATE_VARIANT_CONTEXT_ERROR_ACTION = 'UPDATE_VARIANT_CONTEXT_ERROR_MESSAGE_ACTION';
export interface IUpdateVariantContextErrorAction extends Action<'UPDATE_VARIANT_CONTEXT_ERROR_MESSAGE_ACTION'> {
  payload: {
    error?: string;
  };
}
export const updateVariantContextErrorAction = (error?: string): IUpdateVariantContextErrorAction => ({
  type: UPDATE_VARIANT_CONTEXT_ERROR_ACTION,
  payload: {
    error,
  },
});

export const clearVariantContextErrorAction = (): IUpdateVariantContextErrorAction => ({
  type: UPDATE_VARIANT_CONTEXT_ERROR_ACTION,
  payload: {
    error: undefined,
  },
});

export type IVariantContextActions = IUpdateVariantContextIdAction | IUpdateVariantContextErrorAction;
