import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { closeUserCreationModalAction } from 'redux/modals/userCreationModal/actions';
import { selectIsUserCreationModalOpen, selectUserCreationModalEmail } from 'redux/modals/userCreationModal/selectors';
import { IRootState } from 'redux/reducer';
import { fk } from 'utils/strings/formKeys';

import { UserCreationModal as Component } from './UserCreationModal.component';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  return {
    isOpen: selectIsUserCreationModalOpen(rootState),
    email: selectUserCreationModalEmail(rootState),
  };
};

const mapDispatchToProps = {
  openFormDrawer: openFormDrawerSagaAction,
  closeUserCreationModal: closeUserCreationModalAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openCreateAccountForm: () => dispatchProps.openFormDrawer(fk.createUser),
});

export const UserCreationModal = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
