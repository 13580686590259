// tslint:disable:no-any

import { Action } from 'redux';

// _____ DOWNLOAD_FLOORPLAN_SAGA_ACTION _____

export const DOWNLOAD_FLOORPLAN_SAGA_ACTION = 'DOWNLOAD_FLOORPLAN_SAGA_ACTION';
export interface IDownloadFloorplanSagaAction extends Action<'DOWNLOAD_FLOORPLAN_SAGA_ACTION'> {
  payload: {
    floorspaceId: string;
  };
}
export const downloadFloorplanSagaAction = (floorspaceId: string): IDownloadFloorplanSagaAction => ({
  type: DOWNLOAD_FLOORPLAN_SAGA_ACTION,
  payload: {
    floorspaceId,
  },
});

// _____ UPLOAD_FLOORPLAN_SAGA_ACTION _____

export const UPLOAD_FLOORPLAN_SAGA_ACTION = 'UPLOAD_FLOORPLAN_SAGA_ACTION';
export interface IUploadFloorplanSagaAction extends Action<'UPLOAD_FLOORPLAN_SAGA_ACTION'> {
  payload: {
    convert3DNext: boolean;
    floorplan: object;
    floorspaceId: string;
  };
}
export const uploadFloorplanSagaAction = (
  floorspaceId: string,
  floorplan: object,
  convert3DNext: boolean = false
): IUploadFloorplanSagaAction => ({
  type: UPLOAD_FLOORPLAN_SAGA_ACTION,
  payload: {
    floorspaceId,
    floorplan,
    convert3DNext,
  },
});

export const UPDATE_FLOORPLAN_ACTION = 'UPDATE_FLOORPLAN_ACTION';
export interface IUpdateFloorplanAction extends Action<'UPDATE_FLOORPLAN_ACTION'> {
  payload: {
    floorplan: object;
  };
}
export const updateFloorplanAction = (floorplan: object): IUpdateFloorplanAction => ({
  type: UPDATE_FLOORPLAN_ACTION,
  payload: {
    floorplan,
  },
});

export const TOGGLE_FLOORPLAN_JUST_SAVED_ACTION = 'TOGGLE_FLOORPLAN_JUST_SAVED_ACTION';
export interface IToggleFloorplanJustSavedAction extends Action<'TOGGLE_FLOORPLAN_JUST_SAVED_ACTION'> {
  payload: {
    isJustSaved: boolean;
  };
}
export const toggleFloorplanJustSavedAction = (isJustSaved: boolean): IToggleFloorplanJustSavedAction => ({
  type: TOGGLE_FLOORPLAN_JUST_SAVED_ACTION,
  payload: {
    isJustSaved,
  },
});

// _____ CLEAR_FLOORPLAN _____

export const CLEAR_FLOORPLAN = 'CLEAR_FLOORPLAN';
export interface IClearFloorplanAction extends Action<'CLEAR_FLOORPLAN'> {
  payload: {};
}
export const clearFloorplanAction = (): IClearFloorplanAction => ({
  type: CLEAR_FLOORPLAN,
  payload: {},
});

export type IFloorspaceActions = IUpdateFloorplanAction | IClearFloorplanAction | IToggleFloorplanJustSavedAction;
