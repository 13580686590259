import { CLOSE_NAVIGATION_MODAL, INavigationModalActions, OPEN_NAVIGATION_MODAL } from './actions';

export interface INavigationModalState {
  isOpen: boolean;
}

export const initialState = { isOpen: false };

export const navigationModalReducer = (
  state: INavigationModalState = initialState,
  action: INavigationModalActions
): INavigationModalState => {
  switch (action.type) {
    case OPEN_NAVIGATION_MODAL:
      return { ...state, isOpen: true };

    case CLOSE_NAVIGATION_MODAL:
      return { ...state, isOpen: false };

    default:
      return state;
  }
};
