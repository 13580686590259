import { _map } from 'libs/lodash';
import { IObatPositionOption } from 'redux/obatResources/selectors';
import { getPositionOptionLabel } from 'utils/functions/obatResources/position/getPositionOptionLabel';

export const getCreationPositionOptions = (refs: string[]): IObatPositionOption[] => {
  return [
    { value: '0', label: '1st' },
    ..._map(
      refs,
      (ref: string, index: number): IObatPositionOption => {
        const value = index + 1;
        const afterRef = ref;
        const label = getPositionOptionLabel(value, afterRef);

        return {
          value: `${value}`,
          afterRef,
          label,
        };
      }
    ),
  ];
};
