// tslint:disable:no-any
import { FormControl, FormLabel } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ISelectInputProps } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput/DropdownInput.component';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { getSelectOptions } from 'utils/functions/forms/getSelectOptions';
import { ISelectOption } from 'utils/functions/forms/getSelectOptions/getSelectOptions';

export interface IButtonGroupInputProps extends ISelectInputProps {
  values: any[];
  noLabel?: boolean;
}

export class ButtonGroupInput extends PureComponent<IButtonGroupInputProps> {
  public render(): ReactNode {
    const { values, inputRef, options, renderOptionLabel, noLabel } = this.props;

    const selectOptions = getSelectOptions(options, renderOptionLabel);

    return (
      <FormControl>
        {!noLabel && <FormLabel>{this.props.label}</FormLabel>}
        <StyledToggleButtonGroup value={values} onChange={this.onChange} ref={inputRef}>
          {_map(selectOptions, (option: ISelectOption) => {
            return (
              <StyledToggleButton key={option.value} value={option.value}>
                {option.label}.
              </StyledToggleButton>
            );
          })}
        </StyledToggleButtonGroup>
      </FormControl>
    );
  }

  private onChange = (_event: React.MouseEvent, value: string[]): void => {
    const { field, onSelect } = this.props;

    return onSelect(field, value);
  };
}

const StyledToggleButton = styled(ToggleButton)`
  min-width: 32px;
  font-size: 12px;
  height: 36px;
` as typeof ToggleButton;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-top: 8px;
  margin-bottom: 8px;
` as typeof ToggleButtonGroup;
