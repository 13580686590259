import { EventSeat } from '@material-ui/icons';
import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { MyOrganizationForm } from 'pages/HomeMenu/MyOrganizations/MyOrganizationForm';
import React, { PureComponent, ReactNode } from 'react';
import { getOrganizationMenuPageRoute, homeMenuPages, organizationMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { IOrganizationListed } from 'types/Organization';
import { renderHighestPremissionValue } from 'utils/functions/permissions/renderHighestPermissionValue';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { getSeatColor } from 'utils/functions/seats/getSeatColor';
import { getSeatTooltipContent } from 'utils/functions/seats/getSeatTooltipContent';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';
import { rm } from 'utils/strings/routingMenus';

export class MyOrganizationsList extends PureComponent {
  public render(): ReactNode {
    return (
      <RestResourcesListPage
        routingMenu={rm.home}
        selectedPage={homeMenuPages.myOrganizations}
        resourceClass={rrc.organization}
        columnsParams={this.getColumnsParams()}
        availableActions={['edit']}
        isCreationHidden
        FormComponent={MyOrganizationForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    {
      id: 'name',
      resourceField: 'name',
      getNavigationUrl: (organization: IOrganizationListed): string | undefined => {
        if (organization.is_member) {
          return getOrganizationMenuPageRoute(organizationMenuPages.organizationProjects, organization.id);
        }
      },
      getTooltipContent: (organization: IOrganizationListed): string | undefined => {
        if (!organization.is_member) {
          return $t('organizationNotMemberAlert');
        }
      },
    },
    {
      id: 'affiliation',
      resourceField: 'is_member',
      withFilterOptions: true,
      renderCustomText: (organization: IOrganizationListed): string => $t(organization.is_member ? 'member' : 'guest'),
    },
    {
      id: 'permission',
      withFilterOptions: true,
      renderCustomText: (organization: IOrganizationListed): string => {
        const {
          is_member,
          rights: { can_admin, can_write, can_read },
        } = organization;

        return renderHighestPremissionValue(can_admin, can_write, can_read, is_member);
      },
    },
    {
      id: 'seat',
      withFilterOptions: true,
      renderCustomDOM: (organization: IOrganizationListed): ReactNode => {
        const seatColor = getSeatColor(organization);
        const seatTooltipContent = getSeatTooltipContent(organization, 'table');

        return (
          <SimpleTooltip placement={'left'} content={seatTooltipContent}>
            <SeatContainer color={seatColor}>
              <EventSeat />
            </SeatContainer>
          </SimpleTooltip>
        );
      },
      getCustomDOMFilterComparisonValue: (organization: IOrganizationListed): string => {
        return getSeatTooltipContent(organization, 'table');
      },
    },
  ];
}

const SeatContainer = styled.div`
  margin-top: 4px;
  color: ${(props: { color: string }): string => props.color + ' !important'};
`;
