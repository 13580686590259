import Grid from '@material-ui/core/Grid';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getResourceForm } from 'components/Forms/ResourceForm';
import { FormikProps } from 'formik';
import { getYupStringValidation, yupErrors } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IRestResource } from 'types/RestResource';
import { fdw } from 'utils/configs/drawerWidths';
import { convertFormValuesEmptyStringToNull } from 'utils/functions/convertFormValuesEmptyStringToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { fk } from 'utils/strings/formKeys';
import { IRestResourceClass, rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './CreateAndInviteForm.container';

export interface ICreateAndInviteFormValues {
  first_name: string;
  last_name: string;
  email: string;
}
export interface ICreateAndInviteRequestParams extends ICreateAndInviteFormValues {
  language: string;
}

export class CreateAndInviteForm extends PureComponent<IProps> {
  public FormDrawer = getFormDrawer<IRestResourceClass>();
  public ResourceForm = getResourceForm<
    ICreateAndInviteFormValues,
    ICreateAndInviteRequestParams,
    IRestResourceClass
  >();
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { FormDrawer, ResourceForm } = this;

    return (
      <FormDrawer formKey={fk.createUser} width={fdw.medium} formFocusRef={this.formFocusRef} resourceClass={rrc.user}>
        <ResourceForm
          initialResource={undefined}
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          getRequestParams={this.getRequestParams}
          resourceClass={rrc.user}
          saveResource={this.saveResource}
          isObatResource={false}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<ICreateAndInviteFormValues>): ReactNode => {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput
              field={'email'}
              formikProps={formikProps}
              required
              inputRef={this.formFocusRef}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput field={'first_name'} formikProps={formikProps} required />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput field={'last_name'} formikProps={formikProps} required />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): ICreateAndInviteFormValues => {
    const { initialEmail } = this.props;

    return {
      first_name: '',
      last_name: '',
      email: initialEmail,
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<ICreateAndInviteFormValues> => {
    return yup.object().shape({
      first_name: getYupStringValidation(true),
      last_name: getYupStringValidation(true),
      email: getYupStringValidation(true).email(yupErrors.invalidEmail),
    }) as yup.ObjectSchema<ICreateAndInviteFormValues>;
  };

  private saveResource = (
    _resourceClass: IRestResourceClass,
    _initialRestResource: IRestResource,
    requestParams: ICreateAndInviteRequestParams
  ): void => {
    this.props.createUser(requestParams);
  };

  private getRequestParams = (formValues: ICreateAndInviteFormValues): ICreateAndInviteRequestParams => {
    const initialFormValues = this.getInitialFormValues();
    const filteredFormValues = filterEditedValues(initialFormValues, formValues);
    const requestParams = convertFormValuesEmptyStringToNull(filteredFormValues);
    requestParams.email = formValues.email;
    requestParams.language = 'fr';

    return requestParams;
  };
}
