import { Action } from 'redux';

// _____ OPEN_USER_CREATION_MODAL_ACTION _____

export const OPEN_USER_CREATION_MODAL_ACTION = 'OPEN_USER_CREATION_MODAL_ACTION';
export interface IOpenUserCreationModalAction extends Action<'OPEN_USER_CREATION_MODAL_ACTION'> {
  payload: {
    email: string;
  };
}
export const openUserCreationModalAction = (
  email: IOpenUserCreationModalAction['payload']['email']
): IOpenUserCreationModalAction => ({
  type: OPEN_USER_CREATION_MODAL_ACTION,
  payload: {
    email,
  },
});

// _____ CLOSE_USER_CREATION_MODAL_ACTION _____

export const CLOSE_USER_CREATION_MODAL_ACTION = 'CLOSE_USER_CREATION_MODAL_ACTION';
export interface ICloseUserCreationModalAction extends Action<'CLOSE_USER_CREATION_MODAL_ACTION'> {}
export const closeUserCreationModalAction = (): ICloseUserCreationModalAction => ({
  type: CLOSE_USER_CREATION_MODAL_ACTION,
});

export type IUserCreationModalActions = IOpenUserCreationModalAction | ICloseUserCreationModalAction;
