import { connect } from 'react-redux';
import { closeFormDrawerAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import { manageSeatSagaAction } from 'redux/restResources/detail/organization/actions';
import { selectNotTakingSeatUserPermissionOptions } from 'redux/restResources/detail/organization/selectors';

import { AffectSeatForm as Component } from './AffectSeatForm.component';

// tslint:disable-next-line
interface IOwnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export interface IProps extends IInjectedProps, IOwnProps {}

const mapStateToProps = (rootState: IRootState, _ownProps: IOwnProps) => {
  return {
    notTakingSeatUserPermissionOptions: selectNotTakingSeatUserPermissionOptions(rootState),
  };
};

const mapDispatchToProps = {
  manageSeat: manageSeatSagaAction,
  closeFormDrawer: closeFormDrawerAction,
};

export const AffectSeatForm = connect(mapStateToProps, mapDispatchToProps)(Component);
