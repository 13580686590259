import Grid from '@material-ui/core/Grid';
import { Alert } from 'components/Alerts';
import { BottomCenterButtons, Button } from 'components/Buttons';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { SimpleCard, SpaceBetweenCardHeader } from 'components/Cards';
import { AlertIcon } from 'components/Icons';
import { OplusLoader } from 'components/Loader';
import { LogsModal } from 'components/Modals/LogsModal';
import { Page } from 'components/Page';
import { _camelCase } from 'libs/lodash';
import { _get, _includes, _map, _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { Play } from 'react-feather';
import { multiSimulationGroupMenuPages } from 'routing/routes';
import theme from 'style/theme';
import styled from 'styled-components';
import { ISimulationListed } from 'types/SimulationGroup/Simulation';
import { calculateMultiSimulationRunProgress } from 'utils/functions/calculateMultiSimulationRunProgress';
import { getRunTooltip } from 'utils/functions/simulationGroup/getRunTooltip';
import { isRunEnabled } from 'utils/functions/simulationGroup/isRunEnabled';
import { $t } from 'utils/functions/translate';
import { as, IAlertStatus } from 'utils/strings/alertStatus';
import { ps } from 'utils/strings/progressStatus';
import { rrc } from 'utils/strings/resourceClasses';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './MonitorMulti.container';

export class MonitorMulti extends PureComponent<IProps> {
  public componentDidMount = (): void => {
    const { refreshSimulationGroup, fetchSimulationGroupConfigOptions } = this.props;

    refreshSimulationGroup(rrc.multi_simulation_group);

    /* need to fetch config options to display names in LogsModal */
    fetchSimulationGroupConfigOptions();
  };

  public render(): ReactNode {
    const { routeMultiSimulationGroup, isWritePermissionMissing, isSeatMissing, runSimulation } = this.props;

    const { starting, running_nb, status } = routeMultiSimulationGroup;

    return (
      <Page
        pageTitle={$t('monitor')}
        routingMenu={rm.multiSimulationGroup}
        selectedPage={multiSimulationGroupMenuPages.monitorMulti}
        renderAlert={this.renderAlert}
      >
        <LoaderContainer>
          {starting && running_nb === 0 && <OplusLoader progress={ps.starting} />}
          {running_nb > 0 && <OplusLoader progress={calculateMultiSimulationRunProgress(routeMultiSimulationGroup)} />}
          {status === ps.running && !starting && running_nb === 0 && <OplusLoader progress={ps.aggregatingResults} />}
        </LoaderContainer>

        <CardsContainer>
          {status !== ps.running && (
            <Grid container spacing={1}>
              <Grid item xs={false} sm={1} md={2} />

              <Grid item xs={12} sm={10} md={8}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    {this.renderStatusCard(as.empty)}
                  </Grid>
                  <Grid item xs={4}>
                    {this.renderStatusCard(as.success)}
                  </Grid>
                  <Grid item xs={4}>
                    {this.renderStatusCard(as.failed)}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={false} sm={1} md={2} />
            </Grid>
          )}

          {running_nb > 0 && (
            <Grid container spacing={1}>
              <Grid item xs={6} sm={5}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {this.renderStatusCard(as.pending)}
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderStatusCard(as.running)}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={false} sm={2} />

              <Grid item xs={6} sm={5}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {this.renderStatusCard(as.success)}
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderStatusCard(as.failed)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardsContainer>

        <BottomCenterButtons>
          <Button
            Icon={<Play size={12} />}
            text={$t('simulate')}
            onClick={runSimulation}
            isDisabled={!isRunEnabled(routeMultiSimulationGroup)}
            tooltipProps={{ content: getRunTooltip(routeMultiSimulationGroup), placement: 'top' }}
            isWritePermissionMissing={isWritePermissionMissing}
            isSeatMissing={isSeatMissing}
          />
        </BottomCenterButtons>

        <LogsModal />
      </Page>
    );
  }

  private renderStatusCard = (status: IAlertStatus): ReactNode => {
    const { openLogsModal, exportEplusOutputs, simulationsListByStatus } = this.props;

    const simulationsList =
      status === as.failed
        ? [...simulationsListByStatus.failed, ...simulationsListByStatus.server_error]
        : status === as.pending
        ? [...simulationsListByStatus.pending, ...simulationsListByStatus.empty]
        : _get(simulationsListByStatus, status);

    const simusNb = _size(simulationsList);
    const isNoSimu = simusNb === 0;
    const statusColor = _get(theme.colors, _camelCase(status));

    return (
      <SimpleCard bgColor={isNoSimu ? theme.colors.lightBackground : ''} borderColor={isNoSimu ? '' : statusColor}>
        <SpaceBetweenCardHeader>
          <CardTitleContainer isDisabled={isNoSimu}>
            <Grid container spacing={1}>
              <Grid item>
                <AlertIcon status={status} noRotation={true} isDisabled={isNoSimu} />
              </Grid>
              <Grid item>{$t(status)}</Grid>
            </Grid>
          </CardTitleContainer>
          <SimulationNumber isDisabled={isNoSimu}>{simusNb}</SimulationNumber>
        </SpaceBetweenCardHeader>
        {_map(simulationsList, (simulation: ISimulationListed) => {
          const onLogsClick = (): void => {
            openLogsModal(simulation.id);
          };
          const onExportClick = (): void => {
            exportEplusOutputs(simulation);
          };
          const isExportDisabled =
            (simulation.status === as.failed && !_includes(['eplus', 'outputs'], simulation.fail_step)) ||
            simulation.status === as.server_error;

          const exportTooltipContent = isExportDisabled ? $t('noEplusOutputsAvailable') : $t('exportEPlusOutputs');

          return (
            <SimulationRow key={simulation.id}>
              <Grid container alignItems={'center'}>
                <Grid item xs={9}>
                  <SimulationNameContainer>{simulation.name}</SimulationNameContainer>
                </Grid>
                <Grid item xs={3}>
                  <IconsContainer>
                    {_includes([as.running, as.success, as.failed, as.server_error], status) && (
                      <IconButton action={'seeDetails'} onClick={onLogsClick} />
                    )}
                    {_includes([as.success, as.failed, as.server_error], status) && (
                      <IconButton
                        action={'export'}
                        onClick={onExportClick}
                        tooltipContent={exportTooltipContent}
                        isDisabled={isExportDisabled}
                      />
                    )}
                  </IconsContainer>
                </Grid>
              </Grid>
            </SimulationRow>
          );
        })}
      </SimpleCard>
    );
  };

  private renderAlert = (): ReactNode => {
    const { routeMultiSimulationGroup, isAllSimulationsEmpty } = this.props;

    if (
      routeMultiSimulationGroup.success_nb > 0 &&
      routeMultiSimulationGroup.status === as.empty &&
      !isAllSimulationsEmpty
    ) {
      return (
        <Alert status={as.warning} opacity={0.8}>
          {$t('simulationGroupConfigModificationAlert')}
        </Alert>
      );
    }

    if (routeMultiSimulationGroup.obsolete_front) {
      return (
        <Alert status={as.warning} opacity={0.8}>
          {$t('simulationGroupObsoleteAlert')}
        </Alert>
      );
    }

    return null;
  };
}

const LoaderContainer = styled.div`
  height: 180px;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SimulationNumber = styled.div`
  font-size: 125%;
  color: ${(props: { isDisabled?: boolean }): string => (props.isDisabled ? theme.colors.input.disabled : '')};
`;

const SimulationNameContainer = styled.div`
  word-break: break-all;
`;

const SimulationRow = styled.div`
  margin-bottom: 8px;
  &:hover {
    background-color: ${theme.colors.listItemHover};
  }
`;

const CardsContainer = styled.div`
  margin-top: -180px;
`;

const CardTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  color: ${(props: { isDisabled?: boolean }): string => (props.isDisabled ? theme.colors.input.disabled : '')};
`;
