import { connect } from 'react-redux';
import { openPlotsModalAction } from 'redux/modals/plotsModal/actions';
import { ISystem } from 'types/Obat/System';

import { SystemDetail as SystemDetailComponent } from './SystemDetail.component';

interface IOwnProps {
  system: ISystem;
}

type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapDispatchToProps = {
  openPlotsModal: openPlotsModalAction,
};

export const SystemDetail = connect(undefined, mapDispatchToProps)(SystemDetailComponent);
