import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import React, { PureComponent, ReactNode } from 'react';
import { organizationMenuPages } from 'routing/routes';
import { rrc } from 'utils/strings/resourceClasses';
import { rm } from 'utils/strings/routingMenus';
import { IProps } from './OrganizationProjectsList.container';

export class OrganizationProjectsList extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeOrganization } = this.props;

    return (
      <RestResourcesListPage
        routingMenu={rm.organization}
        routeParentId={routeOrganization.id}
        selectedPage={organizationMenuPages.organizationProjects}
        resourceClass={rrc.project_visible}
        parentResourceClass={rrc.organization}
        columnsParams={columnsParams}
        isCreationHidden
      />
    );
  }
}

const columnsParams = [
  { id: 'name', resourceField: 'name' },
  { id: 'creationDate', resourceField: 'creation_date' },
];
