import { IRootState } from 'redux/reducer';
import { $t } from 'utils/functions/translate';

export const selectIsRestResourceCommitmentsModalOpen = (rootState: IRootState): boolean => {
  return rootState.modals.restResourceCommitmentsModal.isOpen;
};

export const selectRestResourceCommitmentTitle = (rootState: IRootState): string => {
  return $t(
    rootState.modals.restResourceCommitmentsModal.editionField
      ? 'editCommitmentModalTitle'
      : 'deleteCommitmentModalTitle'
  );
};

export const selectRestResourceCommitments = (rootState: IRootState): { [resourceKey: string]: string[] } => {
  const { restResource } = rootState.modals.restResourceCommitmentsModal;

  if (!restResource) {
    return {};
  }

  return restResource.mono_simulation_group_set;
};
