import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { FormikSwitchInput } from 'components/Forms/Inputs/BooleanInputs/FormikSwitchInput';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { WriteInput } from 'components/Forms/Inputs/TextInputs/WriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { ObatResourceFormSpecificFields } from 'components/Forms/ObatResourceFormSpecificFields';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { _includes } from 'libs/lodash';
import { getYupBooleanValidation, getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IScheduleRequestParams } from 'types/Obat/Schedule';
import { fdw } from 'utils/configs/drawerWidths';
import { calculateCorrectedValue } from 'utils/functions/calculateCorrectedValue';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './ScheduleForm.container';

export interface IScheduleFormValues {
  ref: string;
  type: string;
  calendar: string;
  method: string;
  nominal_value: number | '';
  reduction_ratio: number | '';
  unit: string;
  year_profile: string;
  /*  daylinghting control */
  daylighting_control: boolean;
  daylighting_control_setpoint: number | '';
  daylighting_control_minimum_light_ratio: number | '';
  /*  specific */
  baseload: number | '';
  delta_temperature: number | '';
  discharge_coefficient_for_opening: number | '';
  heat_gain_per_person: number | '';
  inlet_temperature: number | '';
  max_indoor_temperature: number | '';
  max_outdoor_temperature: number | '';
  min_indoor_temperature: number | '';
  min_outdoor_temperature: number | '';
  minimum_light_ratio: number | '';
  outlet_temperature: number | '';
  radiant_ratio: number | '';
  lost_ratio: number | '';
  setpoint: number | '';
  visible_ratio: number | '';
  variable_flow_constant_coefficient: number | '';
  variable_flow_temperature_coefficient: number | '';
  variable_flow_velocity_coefficient: number | '';
  variable_flow_velocity_squared_coefficient: number | '';
  latent_ratio: number | '';
  opening_area_ratio: number | '';
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IScheduleFormValues, IScheduleRequestParams>();

export class ScheduleForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.schedule}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IScheduleFormValues>): ReactNode => {
      const { openPlotsModal, scheduleTypeOptions, scheduleToEdit } = this.props;
      const { year_profile, nominal_value, reduction_ratio, daylighting_control, type, method } = formikProps.values;

      const onPlotsModalIconClick = (): void => {
        openPlotsModal(year_profile, orc.year_profile);
      };

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'type'}
                formikProps={formikProps}
                options={scheduleTypeOptions}
                onSelect={this.onSelectType(formikProps)}
                required
                renderOptionLabel={$tEnums}
                obatResourceToBeCheckedForCommitment={scheduleToEdit}
              />
            </Grid>
            {this.isMethodInputDisplayed(type) && (
              <Grid item xs={12}>
                <FormikDropdownInput
                  field={'method'}
                  formikProps={formikProps}
                  options={this.getMethodOptions(type)}
                  renderOptionLabel={$tEnums}
                  obatResourceToBeCheckedForCommitment={scheduleToEdit}
                  required
                  onSelect={this.onSelectMethod(formikProps)}
                />
              </Grid>
            )}
            {this.isYearProfileInputDisplayed(method) && (
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems={'center'}>
                  <Grid item xs={11}>
                    <FormikDropdownInput
                      field={'year_profile'}
                      formikProps={formikProps}
                      options={this.getYearProfileOptions(type)}
                      required
                    />
                  </Grid>
                  {year_profile && (
                    <Grid item xs={1}>
                      <IconButton size={16} action={'seeDetails'} onClick={onPlotsModalIconClick} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>

          {this.isValueSectionDisplayed(method) && (
            <Section title={$t('value')} marginTop={20}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormikDropdownInput
                    field={'unit'}
                    formikProps={formikProps}
                    options={this.getUnitOptions(type, method)}
                    required
                    renderOptionLabel={$tEnums}
                  />
                </Grid>
                {this.isNominalValueInputDisplayed(type) && (
                  <Grid item xs={6}>
                    <FormikWriteInput field={'nominal_value'} formikProps={formikProps} required />
                  </Grid>
                )}
                {this.isReductionRatioInputDisplayed(type) && (
                  <Grid item xs={6}>
                    <FormikWriteInput field={'reduction_ratio'} formikProps={formikProps} required />
                  </Grid>
                )}
                {this.isValueInputDisplayed(type) && (
                  <Grid item xs={6}>
                    <WriteInput
                      field={'value'}
                      value={calculateCorrectedValue(nominal_value, reduction_ratio)}
                      disabled
                    />
                  </Grid>
                )}
              </Grid>
            </Section>
          )}

          {this.isDaylightingControlSectionDisplayed(type, method) && (
            <Section title={$t('daylightingControl')} marginTop={20}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormikSwitchInput
                    field={'daylighting_control'}
                    formikProps={formikProps}
                    label={$t(daylighting_control ? 'active' : 'inactive')}
                  />
                </Grid>
                {this.isDaylightingControlActive(daylighting_control) && (
                  <>
                    <Grid item xs={12}>
                      <FormikWriteInput field={'daylighting_control_setpoint'} formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'daylighting_control_minimum_light_ratio'}
                        formikProps={formikProps}
                        label={$t('minimumLightRatio')}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Section>
          )}

          {this.isSpecificSectionDisplayed(type, method) && (
            <Section title={$t('advancedSettings')} marginTop={20} isInitiallyCollapsed>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormikWriteInput field={'baseload'} formikProps={formikProps} />
                </Grid>
                <ObatResourceFormSpecificFields
                  formikProps={formikProps}
                  specificDefaultValues={this.getSpecificDefaultValues(type, method)}
                />
              </Grid>
            </Section>
          )}
        </>
      );
    };
  };

  private getMethodOptions = (type: IScheduleFormValues['type']): string[] => {
    return this.props.selectMethodOptions(type);
  };

  private getYearProfileOptions = (type: IScheduleFormValues['type']): string[] => {
    return this.props.selectYearProfilesByTypeOptions(this.getYearProfileTypeFromScheduleType(type));
  };

  private getUnitOptions = (type: IScheduleFormValues['type'], method: IScheduleFormValues['method']): string[] => {
    return this.props.selectUnitOptions(type, method);
  };

  private getSpecificDefaultValues = (type: IScheduleFormValues['type'], method: IScheduleFormValues['method']) => {
    return this.props.selectSpecificDefaultValues(type, method);
  };

  private isNominalValueInputDisplayed = (type: IScheduleFormValues['type']): boolean =>
    !(this.isTemperatureType(type) || this.isControlType(type));

  private isReductionRatioInputDisplayed = (type: IScheduleFormValues['type']): boolean =>
    !(this.isTemperatureType(type) || this.isControlType(type));

  private isValueInputDisplayed = (type: IScheduleFormValues['type']): boolean =>
    !(this.isTemperatureType(type) || this.isControlType(type));

  private isDaylightingControlSectionDisplayed = (
    type: IScheduleFormValues['type'],
    method: IScheduleFormValues['method']
  ): boolean => type === 'light' && method !== '';

  private isDaylightingControlActive = (daylightingControl: IScheduleFormValues['daylighting_control']): boolean =>
    !!daylightingControl;

  private isSpecificSectionDisplayed = (
    type: IScheduleFormValues['type'],
    method: IScheduleFormValues['method']
  ): boolean => !(this.isTemperatureType(type) || this.isControlType(type)) && method !== '';

  private isMethodInputDisplayed = (type: IScheduleFormValues['type']): boolean => type !== '';

  private isYearProfileInputDisplayed = (method: IScheduleFormValues['method']): boolean => method !== '';

  private isValueSectionDisplayed = (method: IScheduleFormValues['method']): boolean => method !== '';

  private getYearProfileTypeFromScheduleType = (scheduleType: string): 'ratio' | 'temperature' => {
    return this.isTemperatureType(scheduleType) ? 'temperature' : 'ratio';
  };

  private isControlType = (scheduleType: string): boolean => _includes(['control'], scheduleType);

  private isTemperatureType = (scheduleType: string): boolean =>
    _includes(['heating_setpoint', 'cooling_setpoint'], scheduleType);

  private onSelectMethod = (formikProps: FormikProps<IScheduleFormValues>) => {
    return (_field: string, selectedMethod: string): void => {
      formikProps.setValues({
        ...formikProps.values,
        method: selectedMethod,
        unit: '',
      });
    };
  };

  private onSelectType = (formikProps: FormikProps<IScheduleFormValues>) => {
    return (_field: string, selectedType: string): void => {
      const { getYearProfileTypeFromScheduleType } = this;

      const previousType = formikProps.values.type;

      formikProps.setValues({
        ...formikProps.values,
        type: selectedType,
        method: '',
        year_profile:
          getYearProfileTypeFromScheduleType(previousType) === getYearProfileTypeFromScheduleType(selectedType)
            ? formikProps.values.year_profile
            : '',
        unit: '',
        nominal_value:
          this.isTemperatureType(selectedType) || this.isControlType(selectedType)
            ? 1
            : formikProps.values.nominal_value,
      });
    };
  };

  private getInitialFormValues = (): IScheduleFormValues => {
    const { scheduleToEdit } = this.props;

    return {
      calendar: setFormInitialValue(scheduleToEdit, 'calendar'),
      method: setFormInitialValue(scheduleToEdit, 'method'),
      nominal_value: setFormInitialValue(scheduleToEdit, 'nominal_value'),
      reduction_ratio: setFormInitialValue(scheduleToEdit, 'reduction_ratio', 1),
      ref: setFormInitialValue(scheduleToEdit, 'ref'),
      type: setFormInitialValue(scheduleToEdit, 'type'),
      unit: setFormInitialValue(scheduleToEdit, 'unit'),
      year_profile: setFormInitialValue(scheduleToEdit, 'year_profile'),

      daylighting_control: setFormInitialValue(scheduleToEdit, 'daylighting_control', false),
      daylighting_control_setpoint: setFormInitialValue(scheduleToEdit, 'daylighting_control_setpoint', 300),
      daylighting_control_minimum_light_ratio: setFormInitialValue(
        scheduleToEdit,
        'daylighting_control_minimum_light_ratio',
        0
      ),

      /* specific fields */
      baseload: setFormInitialValue(scheduleToEdit, 'baseload'),
      delta_temperature: setFormInitialValue(scheduleToEdit, 'delta_temperature'),
      discharge_coefficient_for_opening: setFormInitialValue(scheduleToEdit, 'discharge_coefficient_for_opening'),
      heat_gain_per_person: setFormInitialValue(scheduleToEdit, 'heat_gain_per_person'),
      inlet_temperature: setFormInitialValue(scheduleToEdit, 'inlet_temperature'),
      max_indoor_temperature: setFormInitialValue(scheduleToEdit, 'max_indoor_temperature'),
      max_outdoor_temperature: setFormInitialValue(scheduleToEdit, 'max_outdoor_temperature'),
      min_indoor_temperature: setFormInitialValue(scheduleToEdit, 'min_indoor_temperature'),
      min_outdoor_temperature: setFormInitialValue(scheduleToEdit, 'min_outdoor_temperature'),
      minimum_light_ratio: setFormInitialValue(scheduleToEdit, 'minimum_light_ratio'),
      outlet_temperature: setFormInitialValue(scheduleToEdit, 'outlet_temperature'),
      setpoint: setFormInitialValue(scheduleToEdit, 'setpoint'),
      visible_ratio: setFormInitialValue(scheduleToEdit, 'visible_ratio'),
      variable_flow_constant_coefficient: setFormInitialValue(scheduleToEdit, 'variable_flow_constant_coefficient'),
      variable_flow_temperature_coefficient: setFormInitialValue(
        scheduleToEdit,
        'variable_flow_temperature_coefficient'
      ),
      variable_flow_velocity_coefficient: setFormInitialValue(scheduleToEdit, 'variable_flow_velocity_coefficient'),
      variable_flow_velocity_squared_coefficient: setFormInitialValue(
        scheduleToEdit,
        'variable_flow_velocity_squared_coefficient'
      ),
      lost_ratio: setFormInitialValue(scheduleToEdit, 'lost_ratio'),
      radiant_ratio: setFormInitialValue(scheduleToEdit, 'radiant_ratio'),
      latent_ratio: setFormInitialValue(scheduleToEdit, 'latent_ratio'),
      opening_area_ratio: setFormInitialValue(scheduleToEdit, 'opening_area_ratio'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IScheduleFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      type: getYupStringValidation(true),
      method: getYupStringValidation(true),
      year_profile: getYupStringValidation(true),
      nominal_value: yup.number().when(['type', 'method'], {
        is: (type: string, method: string): boolean =>
          this.isTemperatureType(type) ||
          this.isControlType(type) ||
          (type === 'natural_ventilation' && method === 'wind_and_stack'),
        then: getYupNumberValidation(true, 0, 1),
        otherwise: getYupNumberValidation(true),
      }),
      reduction_ratio: getYupNumberValidation(true, 0, 1),
      unit: getYupStringValidation(true),

      daylighting_control: getYupBooleanValidation(),
      daylighting_control_setpoint: getYupNumberValidation(false, 0),
      daylighting_control_minimum_light_ratio: getYupNumberValidation(false, 0, 1),

      /* specific fields */
      baseload: getYupNumberValidation(),
      delta_temperature: getYupNumberValidation(),
      discharge_coefficient_for_opening: getYupNumberValidation(),
      heat_gain_per_person: getYupNumberValidation(),
      inlet_temperature: getYupNumberValidation(),
      minimum_light_ratio: getYupNumberValidation(false, 0, 1),
      outlet_temperature: getYupNumberValidation(),
      setpoint: getYupNumberValidation(),
      variable_flow_constant_coefficient: getYupNumberValidation(),
      variable_flow_temperature_coefficient: getYupNumberValidation(),
      variable_flow_velocity_coefficient: getYupNumberValidation(),
      variable_flow_velocity_squared_coefficient: getYupNumberValidation(),
      opening_area_ratio: getYupNumberValidation(false, 0, 1),
      min_outdoor_temperature: getYupNumberValidation().test({
        test(): boolean {
          return yupTestOutdoorTemperatures(this.parent);
        },
        message: 'yupTestMinMaxTemperatures',
      }),
      max_outdoor_temperature: getYupNumberValidation().test({
        test(): boolean {
          return yupTestOutdoorTemperatures(this.parent);
        },
        message: 'yupTestMinMaxTemperatures',
      }),
      min_indoor_temperature: getYupNumberValidation().test({
        test(): boolean {
          return yupTestIndoorTemperatures(this.parent);
        },
        message: 'yupTestMinMaxTemperatures',
      }),
      max_indoor_temperature: getYupNumberValidation().test({
        test(): boolean {
          return yupTestIndoorTemperatures(this.parent);
        },
        message: 'yupTestMinMaxTemperatures',
      }),
      latent_ratio: getYupNumberValidation(false, 0, 1).test({
        test(): boolean {
          return yupTestRatios(this.parent);
        },
        message: 'yupTestSumOfRatios',
      }),
      lost_ratio: getYupNumberValidation(false, 0, 1).test({
        test(): boolean {
          return yupTestRatios(this.parent);
        },
        message: 'yupTestSumOfRatios',
      }),
      radiant_ratio: getYupNumberValidation(false, 0, 1).test({
        test(): boolean {
          return yupTestRatios(this.parent);
        },
        message: 'yupTestSumOfRatios',
      }),
      visible_ratio: getYupNumberValidation(false, 0, 1).test({
        test(): boolean {
          return yupTestRatios(this.parent);
        },
        message: 'yupTestSumOfRatios',
      }),
    }) as yup.ObjectSchema<IScheduleFormValues>;
  };
}

const yupTestRatios = (formValues: IScheduleFormValues): boolean => {
  const { radiant_ratio, lost_ratio, latent_ratio, visible_ratio } = formValues;

  return (radiant_ratio || 0) + (latent_ratio || 0) + (lost_ratio || 0) + (visible_ratio || 0) <= 1;
};

const yupTestOutdoorTemperatures = (formValues: IScheduleFormValues): boolean => {
  const { min_outdoor_temperature, max_outdoor_temperature } = formValues;

  return !min_outdoor_temperature || !max_outdoor_temperature || max_outdoor_temperature >= 1 + min_outdoor_temperature;
};

const yupTestIndoorTemperatures = (formValues: IScheduleFormValues): boolean => {
  const { min_indoor_temperature, max_indoor_temperature } = formValues;

  return !min_indoor_temperature || !max_indoor_temperature || max_indoor_temperature >= 1 + min_indoor_temperature;
};
