import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { IUse } from 'types/Obat/Use';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { UseForm } from '../UseForm';

export class UsesPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'uses'}
        resourceClass={orc.use}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={UseForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 4 },
    { id: 'subUses', gridItemSize: 8 },
  ];

  private renderCardColumnData = (use: IUse, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return use.ref;
      case 'subUses':
        return _map(use.subuses, (sub: string) => <SubUsesContainer key={sub}>{$tEnums(sub)}</SubUsesContainer>);
    }
  };
}

const SubUsesContainer = styled.p`
  margin: 0 0 5px;
`;
