import { _size } from 'libs/lodash';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { selectFormDrawerWidth, selectIsResourceForm } from 'redux/form/selectors';
import { updateRefToScrollToAction } from 'redux/obatResources/actions';
import {
  selectObatContentsData,
  selectObatContentsId,
  selectObatResources,
  selectObatResourcesIdsList,
  selectRefToScrollTo,
} from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import {
  exportObatFileSagaAction,
  fetchObatGeometriesSagaAction,
  refreshImportObatTaskSagaAction,
} from 'redux/restResources/detail/obat/actions';
import { selectObatImportProgress } from 'redux/restResources/detail/obat/selectors';
import { updateRouteHashAction } from 'redux/routing/actions';
import {
  selectIsRefreshingRoutePage,
  selectIsSeatMissing,
  selectIsWritePermissionMissing,
  selectRouteResource,
} from 'redux/routing/selectors';
import { selectRouteHash } from 'redux/routing/selectors';
import { fetchTableInitialDataSagaAction, initializeTableAction } from 'redux/table/actions';
import { selectTableResources } from 'redux/table/selectors';
import { selectIsTaskInProgress } from 'redux/tasks/selectors';
import { clearVariantContextIdSagaAction } from 'redux/variantContext/actions';
import { selectIsVariantContext } from 'redux/variantContext/selectors';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { getTaskKey } from 'utils/functions/getTaskKey';
import {
  ITableColumnParams,
  pickCardColumnParams,
  pickColumnFilterParams,
} from 'utils/functions/pickColumnParams/pickColumnParams';
import { fk } from 'utils/strings/formKeys';
import { dra } from 'utils/strings/requestActions';
import { IObatResourceClass, orc } from 'utils/strings/resourceClasses';
import { rrc } from 'utils/strings/resourceClasses';

import { ObatCardsPage as Component } from './ObatCardsPage.component';

// tslint:disable:no-any
interface IOwnProps {
  selectedPage: string;
  resourceClass: IObatResourceClass;
  columnsParams: ITableColumnParams[];
  renderCardColumnData: (obatResource: any, columnId: string) => ReactNode;
  renderCardDetail?: (obatResource: any) => ReactNode;
  ObatResourceFormComponent: any;
  projectionSection?: string;
  hasContentToCalculate?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  const routeProject = selectRouteResource(rrc.project)(rootState);
  const routeObat = selectRouteResource(rrc.obat)(rootState);
  const tableResources = selectTableResources(rootState)(ownProps.resourceClass, routeObat.id);
  const isDataReady = !!tableResources;
  const isDataEmpty =
    !!tableResources &&
    (_size(tableResources.full) === 0 ||
      (_size(tableResources.full) === 1 && tableResources.full && tableResources.full[0].id === 'not_assigned'));

  const fetchObatContentsApiRequestKey = getDetailApiRequestKey(dra.fetchObatContents, routeObat.id);
  const isFetchingObatContents = selectIsApiRequestPending(fetchObatContentsApiRequestKey)(rootState);
  const obatImportProgress = selectObatImportProgress(rootState);
  const isImportingObat = obatImportProgress !== 'finished';

  const exportObatTaskKey = getTaskKey(dra.exportObat, routeObat.id);
  const isExportingObat = selectIsTaskInProgress(exportObatTaskKey)(rootState);

  const exportGbxmlTaskKey = getTaskKey(dra.exportGbxml, routeObat.id);
  const isExportingGbxml = selectIsTaskInProgress(exportGbxmlTaskKey)(rootState);

  return {
    routeProject,
    routeObat,
    tableResources,
    isDataReady,
    isDataEmpty,
    obatImportProgress,
    isImportingObat,
    isFetchingObatContents,
    isExportingObat,
    isExportingGbxml,
    tableFilterParams: pickColumnFilterParams(ownProps.columnsParams),
    cardColumnsParams: pickCardColumnParams(ownProps.columnsParams),
    refToScrollTo: selectRefToScrollTo(rootState),
    urlHash: selectRouteHash(rootState),
    obatData: selectObatContentsData(rootState),
    obatResources: selectObatResources(ownProps.resourceClass)(rootState),
    obatContentsId: selectObatContentsId(rootState),
    isVariantContext: selectIsVariantContext(rootState),
    variantOptions: selectObatResourcesIdsList(orc.variant)(rootState),
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
    formDrawerWidth: selectFormDrawerWidth(rootState),
    isResourceForm: selectIsResourceForm(rootState),
    isRefreshingRoutePage: selectIsRefreshingRoutePage(rootState),
  };
};

const mapDispatchToProps = {
  fetchTableInitialData: fetchTableInitialDataSagaAction,
  setRefToScrollTo: updateRefToScrollToAction,
  openFormDrawer: openFormDrawerSagaAction,
  updateRouteHash: updateRouteHashAction,
  initializeTable: initializeTableAction,
  clearVariantContext: clearVariantContextIdSagaAction,
  refreshImportObatTask: refreshImportObatTaskSagaAction,
  exportObat: exportObatFileSagaAction,
  fetchObatGeometries: fetchObatGeometriesSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openCreationForm: () => dispatchProps.openFormDrawer(fk.obatResource),
  openVariantContextForm: () => dispatchProps.openFormDrawer(fk.variantContext),
  openObatImportForm: () => dispatchProps.openFormDrawer(fk.import),
  openExportGbxmlForm: () => {
    dispatchProps.fetchObatGeometries();
    dispatchProps.openFormDrawer(fk.exportGbxml);
  },
});

export const ObatCardsPage = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
