// tslint:disable:no-any

import { apiEndpoints } from 'api/endPoints';
import { _get } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { ITokens } from 'types/Tokens';
import { gra, IGenericRequestAction } from 'utils/strings/requestActions';
import { IObatResourceClass } from 'utils/strings/resourceClasses';

export interface IObatApiUrlParams {
  obatId: string;
  resourceClass: IObatResourceClass;
  resourceId?: string;
  detailRoute?: string;
}

export const getObatApiUrl = (
  accessToken: ITokens['access'],
  action: IGenericRequestAction,
  apiUrlParams: IObatApiUrlParams
): any => {
  const { obatId, resourceClass, resourceId, detailRoute } = apiUrlParams;

  let url = _get(apiEndpoints.obatResources, resourceClass)(accessToken, obatId);

  url =
    resourceId && _includes([gra.retrieve, gra.copy, gra.edit, gra.delete, gra.detailRoute], action)
      ? url.url(`/${resourceId}`)
      : url;

  if (detailRoute) {
    return url.url(`/${detailRoute}`);
  }

  return url;
};
