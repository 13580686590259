import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Alert } from 'components/Alerts';
import { HyperLink } from 'components/Links/HyperLink';
import { OplusLoader } from 'components/Loader';
import { Page } from 'components/Page';
import { ResultsModeForm } from 'components/SimulationGroup/ResultsModeForm';
import { _map } from 'libs/lodash';
import React, { Component, ReactNode } from 'react';
import { navigateRoute } from 'routing/navigateRoute';
import {
  getMonoSimulationGroupMenuPageRoute,
  getMultiSimulationGroupMenuPageRoute,
  monoSimulationGroupMenuPages,
  multiSimulationGroupMenuPages,
} from 'routing/routes';
import styled from 'styled-components';
import { ISimulationListed } from 'types/SimulationGroup/Simulation';
import { $t, $tcc } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { ps } from 'utils/strings/progressStatus';
import { IMonthlyResultsSection, IResultsView } from 'utils/strings/results';
import { rm } from 'utils/strings/routingMenus';

import { ResultsTab } from '../ResultsTab';

import { IProps } from './MonthlyResultsPage.container';

export class MonthlyResultsPage extends Component<IProps> {
  public componentDidMount(): void {
    const { refreshSimulationGroup, routeSimulationGroupResourceClass, simulationsListByStatus } = this.props;

    refreshSimulationGroup(routeSimulationGroupResourceClass);
    this.onSimulationSelect(simulationsListByStatus.success[0]);
  }

  public componentWillUnmount(): void {
    const { clearMonthlyResults } = this.props;
    clearMonthlyResults();
  }

  public render(): ReactNode {
    const { isLoadingResults, isMono, isMulti, section, routeSimulationGroup, resultsMode, sectionTabs } = this.props;

    const resultsModeLabels = {
      bySimulation: $t('resultsBySimulation'),
      aggregate: $t('aggregatedResults'),
    };

    return (
      <Page
        pageTitle={$t('monthlyResults')}
        routingMenu={isMono ? rm.monoSimulationGroup : rm.multiSimulationGroup}
        selectedPage={
          isMono ? monoSimulationGroupMenuPages.monthlyResultsMono : multiSimulationGroupMenuPages.monthlyResultsMulti
        }
        renderAlert={this.renderAlert}
        withBottomButtons
      >
        {isMulti && (
          <ResultsModeForm
            resultsMode={resultsMode}
            onSimulationSelect={this.onSimulationSelect}
            onResultsModeToggle={this.onResultsModeToggle}
            resultsModeLabels={resultsModeLabels}
          />
        )}
        <TabsContainer position="static">
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={section}
            variant="scrollable"
            scrollButtons="auto"
            onChange={this.onTabChange}
          >
            {_map(sectionTabs, (tab: string) => {
              return <StyledTab key={tab} label={$tcc(tab)} value={tab} />;
            })}
          </Tabs>
        </TabsContainer>
        <TabContentContainer>
          {isLoadingResults ? (
            <OplusLoader progress={ps.fetchingData} />
          ) : (
            <ResultsTab routeSimulationGroup={routeSimulationGroup} />
          )}
        </TabContentContainer>
      </Page>
    );
  }

  private renderAlert = (): ReactNode => {
    const { routeSimulationGroup, routeProject, isMono, isMulti, isAllSimulationsEmpty } = this.props;

    const onMouseDown = (e: React.MouseEvent): void => {
      if (isMono) {
        const monoUrl = getMonoSimulationGroupMenuPageRoute(
          monoSimulationGroupMenuPages.monitorMono,
          routeProject.id,
          routeSimulationGroup.id
        );
        navigateRoute(monoUrl, e);
      } else if (isMulti) {
        const multiUrl = getMultiSimulationGroupMenuPageRoute(
          multiSimulationGroupMenuPages.monitorMulti,
          routeProject.id,
          routeSimulationGroup.id
        );
        navigateRoute(multiUrl, e);
      }
    };

    if (
      routeSimulationGroup.status === as.running ||
      (routeSimulationGroup.status === as.empty && !isAllSimulationsEmpty) ||
      routeSimulationGroup.status === as.failed ||
      routeSimulationGroup.obsolete
    ) {
      let hyperlink;
      let alertText;
      let alertStatus;

      if (routeSimulationGroup.status === as.running) {
        hyperlink = <HyperLink onMouseDown={onMouseDown}>{$t('seeProgress')}</HyperLink>;
        alertText = `${$t('simulationGroupRunningResultsAlert')}  `;
        alertStatus = as.info;
      }

      if (routeSimulationGroup.status === as.empty && !isAllSimulationsEmpty) {
        hyperlink = <HyperLink onMouseDown={onMouseDown}>{$t('simulate')}</HyperLink>;
        alertText = `${$t('simulationGroupConfigModificationAlert')}  `;
        alertStatus = as.warning;
      }

      if (routeSimulationGroup.status === as.failed) {
        hyperlink = null;
        alertText = `${$t('simulationGroupFailedSimulationsAlert')}  `;
        alertStatus = as.warning;
      }

      if (routeSimulationGroup.obsolete) {
        hyperlink = <HyperLink onMouseDown={onMouseDown}>{$t('simulate')}</HyperLink>;
        alertText = `${$t('simulationGroupObsoleteAlert')}  `;
        alertStatus = as.warning;
      }

      const content = (
        <span>
          {alertText}
          {hyperlink}
        </span>
      );

      return (
        // @ts-ignore
        <Alert status={alertStatus} opacity={0.8}>
          {content}
        </Alert>
      );
    }

    return null;
  };

  // tslint:disable-next-line:no-any
  private onTabChange = (_e: any, selectedSection: IMonthlyResultsSection) => {
    const { updateSection, downloadMonthlyResultsSectionData, closeFormDrawer } = this.props;
    closeFormDrawer();
    updateSection(selectedSection);
    downloadMonthlyResultsSectionData();
  };

  private onSimulationSelect = (selectedSimulation: ISimulationListed): void => {
    const { downloadMonthlyResultsSectionData, updateSimulation } = this.props;
    updateSimulation(selectedSimulation);
    downloadMonthlyResultsSectionData();
  };

  private onResultsModeToggle = (selectedMode: IResultsView): void => {
    const { updateResultsMode, downloadMonthlyResultsSectionData, closeFormDrawer } = this.props;
    closeFormDrawer();
    updateResultsMode(selectedMode);
    downloadMonthlyResultsSectionData();
  };
}

const StyledTab = styled(Tab)`
  text-transform: unset;
  text-shadow: 0 0 0;
  letter-spacing: 0.15px;
` as typeof Tab;

const TabsContainer = styled(AppBar)`
  margin-top: 12px;
  background-color: white;
` as typeof AppBar;

const TabContentContainer = styled.div`
  padding-top: 20px;
`;
