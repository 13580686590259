import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { IDayProfile } from 'types/Obat/DayProfile';
import { rrc } from 'utils/strings/resourceClasses';

import { DayProfileForm as DayProfileFormComponent } from './DayProfileForm.component';

interface IOwnProps {
  dayProfile: IDayProfile;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export interface IProps extends IInjectedProps, IOwnProps {}

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return {
    dayProfileToEdit: ownProps.dayProfile,
    routeObat: selectRouteResource(rrc.obat)(rootState),
  };
};

const mapDispatchToProps = {};

export const DayProfileForm = connect(mapStateToProps, mapDispatchToProps)(DayProfileFormComponent);
