import { _keys } from 'libs/lodash';
import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

/** copy and paste here from http://test-ossplatform.openergy.fr/api/v1/enums */

export const enums = {
  geometry: {
    import_format: ['floorspace', 'idf', 'ogw', 'gbxml', 'merge'],
    template_categories: ['template_geometry_use'],
    template_geometry_use: [
      {
        ref: 'housing',
        use: 'housing',
      },
      {
        ref: 'office',
        use: 'office',
      },
    ],
  },
  weather: {
    weather_format: ['generic', 'historical', 'openergy_historical'],
    weather_series_import_format: ['csv', 'epw'],
    weather_import_format: ['ow'],
    weather_series_export_format: ['csv', 'epw'],
    template_categories: ['template_weather_iwec', 'template_weather_meteonorm'],
    template_weather_iwec: [
      {
        ref: 'France_Acrazer',
        country: 'France',
        city: 'Acrazer',
      },
      {
        ref: 'France_Bordeaux',
        country: 'France',
        city: 'Bordeaux',
      },
      {
        ref: 'France_Brest',
        country: 'France',
        city: 'Brest',
      },
      {
        ref: 'France_Clermont-ferrand',
        country: 'France',
        city: 'Clermont-ferrand',
      },
      {
        ref: 'France_Dijon',
        country: 'France',
        city: 'Dijon',
      },
      {
        ref: 'France_Lyon',
        country: 'France',
        city: 'Lyon',
      },
      {
        ref: 'France_Marseille',
        country: 'France',
        city: 'Marseille',
      },
      {
        ref: 'France_Montpellier',
        country: 'France',
        city: 'Montpellier',
      },
      {
        ref: 'France_Nancy',
        country: 'France',
        city: 'Nancy',
      },
      {
        ref: 'France_Nantes',
        country: 'France',
        city: 'Nantes',
      },
      {
        ref: 'France_Nice',
        country: 'France',
        city: 'Nice',
      },
      {
        ref: 'France_Paris Orly',
        country: 'France',
        city: 'Paris Orly',
      },
      {
        ref: 'France_Strasbourg',
        country: 'France',
        city: 'Strasbourg',
      },
      {
        ref: 'France_pzacez',
        country: 'France',
        city: 'pzacez',
      },
    ],
    template_weather_meteonorm: [
      {
        ref: 'France_Abbeville',
        country: 'France',
        city: 'Abbeville',
      },
      {
        ref: 'France_Ajaccio',
        country: 'France',
        city: 'Ajaccio',
      },
      {
        ref: 'France_Alencon Valframbert',
        country: 'France',
        city: 'Alencon Valframbert',
      },
      {
        ref: 'France_Bale Mulhouse',
        country: 'France',
        city: 'Bale Mulhouse',
      },
      {
        ref: 'France_Bastia Poretta',
        country: 'France',
        city: 'Bastia Poretta',
      },
      {
        ref: 'France_Bordeaux',
        country: 'France',
        city: 'Bordeaux',
      },
      {
        ref: 'France_Bourges',
        country: 'France',
        city: 'Bourges',
      },
      {
        ref: 'France_Brest',
        country: 'France',
        city: 'Brest',
      },
      {
        ref: 'France_Caen',
        country: 'France',
        city: 'Caen',
      },
      {
        ref: 'France_Cape Cepet',
        country: 'France',
        city: 'Cape Cepet',
      },
      {
        ref: 'France_Chassiron',
        country: 'France',
        city: 'Chassiron',
      },
      {
        ref: 'France_Clermont-Ferrand',
        country: 'France',
        city: 'Clermont-Ferrand',
      },
      {
        ref: 'France_Dijon',
        country: 'France',
        city: 'Dijon',
      },
      {
        ref: 'France_Embrun',
        country: 'France',
        city: 'Embrun',
      },
      {
        ref: 'France_Gourdon',
        country: 'France',
        city: 'Gourdon',
      },
      {
        ref: 'France_La Hague',
        country: 'France',
        city: 'La Hague',
      },
      {
        ref: 'France_Le Puy-En-Velay',
        country: 'France',
        city: 'Le Puy-En-Velay',
      },
      {
        ref: 'France_Le Talut',
        country: 'France',
        city: 'Le Talut',
      },
      {
        ref: 'France_Lille Lesquin',
        country: 'France',
        city: 'Lille Lesquin',
      },
      {
        ref: 'France_Limoges',
        country: 'France',
        city: 'Limoges',
      },
      {
        ref: 'France_Lyon St Exupery',
        country: 'France',
        city: 'Lyon St Exupery',
      },
      {
        ref: 'France_Marseille',
        country: 'France',
        city: 'Marseille',
      },
      {
        ref: 'France_Millau',
        country: 'France',
        city: 'Millau',
      },
      {
        ref: 'France_Mont-De-Marsan Fafb',
        country: 'France',
        city: 'Mont-De-Marsan Fafb',
      },
      {
        ref: 'France_Montelimar Ancone',
        country: 'France',
        city: 'Montelimar Ancone',
      },
      {
        ref: 'France_Montpellier',
        country: 'France',
        city: 'Montpellier',
      },
      {
        ref: 'France_Nancy Ochey',
        country: 'France',
        city: 'Nancy Ochey',
      },
      {
        ref: 'France_Nantes',
        country: 'France',
        city: 'Nantes',
      },
      {
        ref: 'France_Nice',
        country: 'France',
        city: 'Nice',
      },
      {
        ref: 'France_Paris Orly',
        country: 'France',
        city: 'Paris Orly',
      },
      {
        ref: 'France_Pau',
        country: 'France',
        city: 'Pau',
      },
      {
        ref: 'France_Perpignan',
        country: 'France',
        city: 'Perpignan',
      },
      {
        ref: 'France_Poitiers Biard',
        country: 'France',
        city: 'Poitiers Biard',
      },
      {
        ref: 'France_Reims',
        country: 'France',
        city: 'Reims',
      },
      {
        ref: 'France_Rennes',
        country: 'France',
        city: 'Rennes',
      },
      {
        ref: 'France_Rouen Boos',
        country: 'France',
        city: 'Rouen Boos',
      },
      {
        ref: 'France_Saint-Brieuc Armor',
        country: 'France',
        city: 'Saint-Brieuc Armor',
      },
      {
        ref: 'France_St Girons Antichan',
        country: 'France',
        city: 'St Girons Antichan',
      },
      {
        ref: 'France_Strasbourg',
        country: 'France',
        city: 'Strasbourg',
      },
      {
        ref: 'France_Toulouse',
        country: 'France',
        city: 'Toulouse',
      },
      {
        ref: 'France_Tours',
        country: 'France',
        city: 'Tours',
      },
      {
        ref: 'France_Troyes Barberey',
        country: 'France',
        city: 'Troyes Barberey',
      },
    ],
  },
  simulation_group: {
    simulation_results: ['regular', 'detailed', 'no_generic_viz'],
    simulation_status: ['empty', 'pending', 'running', 'success', 'failed', 'server_error'],
    simulation_fail_step: ['prepare_calculation', 'inputs', 'eplus', 'outputs'],
    simulation_group_status: ['empty', 'pending', 'running', 'success', 'failed', 'server_error', 'incomplete'],
  },
  obat: {
    obat_contents: {
      sizing_methods: ['rt2012', 'ashrae'],
      weather_series_types: ['historical', 'generic'],
      solar_distributions: [
        'minimal_shadowing',
        'full_exterior',
        'full_interior_and_exterior',
        'full_exterior_with_reflections',
        'full_interior_and_exterior_with_reflections',
      ],
      surface_types: ['floor', 'wall', 'roof'],
      surface_conditions: ['interior', 'outdoor', 'ground', 'adiabatic'],
      surface_categories: [
        'floor_ground',
        'floor_interior',
        'floor_outdoor',
        'roof_outdoor',
        'roof_ground',
        'roof_interior',
        'wall_interior',
        'wall_outdoor',
        'wall_ground',
      ],
      subsurface_types: ['window', 'door'],
      layer_side: ['interior', 'exterior'],
      opening_types: ['window', 'door'],
      blind_types: ['vertical_shades', 'vertical_blinds', 'shutters'],
      blind_positions: ['interior', 'exterior'],
      blind_angle_methods: ['auto', 'fixed'],
      blind_control_methods: [
        'always_on',
        'always_off',
        'on_night_if_low_outdoor_temperature_and_on_day_if_cooling',
        'on_if_high_outdoor_air_temperature',
        'on_if_high_zone_air_temperature',
        'on_if_high_solar_on_window',
        'on_if_high_zone_cooling',
      ],
      zone_tag_attributes: ['zg1', 'zg2', 'zg3', 'story'],
      day_types: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      month_types: [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ],
      year_profile_types: ['ratio', 'temperature'],
      schedule_types: [
        'people',
        'outdoor_air',
        'infiltration',
        'electric_equipment',
        'light',
        'natural_ventilation',
        'dhw',
        'heating_setpoint',
        'cooling_setpoint',
        'control',
      ],
      schedule_methods: ['scheduled', 'wind_and_stack'],
      schedule_categories: ['positive_value', 'ratio', 'on_off', 'temperature'],
      schedule_units: [
        'person_per_zone',
        'person_per_m2',
        'm2_per_person',
        'w_per_zone',
        'w_per_m2',
        'm3_per_h_per_m2',
        'w_per_person',
        'volume_per_h',
        'm3_per_h_per_person',
        'm3_per_h_per_zone',
        'm3_per_h_per_m2ext',
        'm3_per_h_per_m2ext_under_4pa',
        'm3_per_h_per_m2extwalls',
        'm3_per_day_per_azg',
        'm3_per_day_per_person',
        'celsius',
        'ratio',
        'on_off',
      ],
      system_types: ['heating_loop', 'cooling_loop', 'air_loop', 'dhw_loop'],
      system_methods: [
        'convective_emitter',
        'radiative_emitter',
        'fixed_supply_temperature_ahu',
        'zone_temperature_control_ahu',
        'zone_temperature_control_ideal_ahu',
        'extract_only',
        'ideal_water_heater',
      ],
      energies: ['electricity', 'gas', 'heating_district', 'biomass', 'fuel_oil', 'cooling_district'],
      heating_energies: ['electricity', 'gas', 'heating_district', 'biomass', 'fuel_oil'],
      cooling_energies: ['electricity', 'gas', 'cooling_district'],
      script_types: ['pre', 'post'],
      output_variable_topics: [
        'consumption',
        'production',
        'thermal_balance',
        'miscellaneous',
        'environment',
        'weather',
        'comfort',
        'not_assigned',
      ],
      output_variable_names: [
        'ahu_auxiliary_fan',
        'ahu_cooling_auxiliary_other',
        'ahu_cooling_coil_thermal',
        'ahu_cooling_coil_thermal_supply',
        'ahu_cooling_recovery_thermal',
        'ahu_cooling_zone_heat_load',
        'ahu_exhaust_flow_rate',
        'ahu_heating_auxiliary_other',
        'ahu_heating_coil_thermal',
        'ahu_heating_coil_thermal_supply',
        'ahu_heating_recovery_thermal',
        'ahu_heating_zone_heat_load',
        'ahu_supply_flow_rate',
        'air_storage_zone_heat_load',
        'atmospheric_pressure',
        'cooling_emitter_auxiliary_fan',
        'cooling_emitter_auxiliary_other',
        'cooling_emitter_thermal',
        'cooling_emitter_zone_heat_load',
        'dew_point_temperature',
        'dhw',
        'dhw_emitter_auxiliary',
        'dhw_emitter_thermal',
        'diffuse_horizontal_radiation',
        'direct_normal_radiation',
        'dry_bulb_temperature',
        'electric_equipment',
        'electric_equipment_convective_zone_heat_load',
        'electric_light',
        'fanger_p_m_v',
        'fanger_p_p_d',
        'global_horizontal_radiation',
        'heating_emitter_auxiliary_fan',
        'heating_emitter_auxiliary_other',
        'heating_emitter_thermal',
        'heating_emitter_zone_heat_load',
        'horizontal_infrared_radiation_intensity_from_sky',
        'infiltration_flow_rate',
        'infiltration_zone_heat_load',
        'interzone_air_transfer_zone_heat_load',
        'lights_convective_zone_heat_load',
        'liquid_precipitation_depth',
        'mean_air_temperature',
        'mean_radiant_temperature',
        'natural_ventilation_flow_rate',
        'natural_ventilation_zone_heat_load',
        'opaque_convective_zone_heat_load',
        'opaque_losses_and_stored_zone_heat_load',
        'opaque_radiant_zone_heat_load',
        'opaque_sky_cover',
        'operative_temperature',
        'people_convective_zone_heat_load',
        'people_count',
        'present_weather_codes',
        'present_weather_observation',
        'relative_humidity',
        'snow_depth',
        'solar_altitude_angle',
        'solar_azimuth',
        'wind_direction',
        'wind_speed',
        'window_zone_heat_load',
        'windows_transmitted_solar_zone_heat_load',
        'zone_air_heat_balance_deviation_rate',
      ],
      output_variable_units: [
        'kwh',
        'm3_per_h',
        'celsius',
        'people',
        'tenth',
        'percent',
        'wh_per_m2',
        'm_per_s',
        'deg',
        'pa',
        'unitless',
        'mm',
        'cm',
      ],
      output_variable_energy_types: ['electricity', 'gas', 'heating_district', 'cooling_district'],
      output_variable_energy_categories: ['primary', 'final'],
      zone_exposition_type: [
        'Heated zone no façade exposed to outside',
        'Heated zone with single façade exposed',
        'Heated zone with multiple façades exposed',
      ],
      site_exposition_type: ['Open site', 'Medium sheltered site', 'Well sheltered site'],
      uses: [
        'consumption_cooling_emitter_thermal',
        'consumption_heating_emitter_thermal',
        'consumption_heating_emitter_auxiliary_fan',
        'consumption_cooling_emitter_auxiliary_fan',
        'consumption_heating_emitter_auxiliary_other',
        'consumption_cooling_emitter_auxiliary_other',
        'consumption_ahu_heating_coil_thermal',
        'consumption_ahu_cooling_coil_thermal',
        'consumption_ahu_auxiliary_fan',
        'consumption_ahu_heating_auxiliary_other',
        'consumption_ahu_cooling_auxiliary_other',
        'consumption_dhw_emitter_thermal',
        'consumption_dhw_emitter_auxiliary',
        'consumption_electric_equipment',
        'consumption_electric_light',
      ],
      schedule_category__by_type_and_method: {
        people: {
          scheduled: 'positive_value',
        },
        outdoor_air: {
          scheduled: 'positive_value',
        },
        infiltration: {
          scheduled: 'positive_value',
        },
        electric_equipment: {
          scheduled: 'positive_value',
        },
        light: {
          scheduled: 'positive_value',
        },
        natural_ventilation: {
          scheduled: 'positive_value',
          wind_and_stack: 'ratio',
        },
        dhw: {
          scheduled: 'positive_value',
        },
        heating_setpoint: {
          scheduled: 'temperature',
        },
        cooling_setpoint: {
          scheduled: 'temperature',
        },
        control: {
          scheduled: 'on_off',
        },
      },
      schedule_units__by_type_and_method: {
        people: {
          scheduled: ['person_per_zone', 'person_per_m2', 'm2_per_person'],
        },
        outdoor_air: {
          scheduled: ['m3_per_h_per_m2', 'volume_per_h', 'm3_per_h_per_person', 'm3_per_h_per_zone'],
        },
        infiltration: {
          scheduled: [
            'm3_per_h_per_m2',
            'volume_per_h',
            'm3_per_h_per_zone',
            'm3_per_h_per_m2extwalls',
            'm3_per_h_per_m2ext_under_4pa',
            'm3_per_h_per_m2ext',
          ],
        },
        electric_equipment: {
          scheduled: ['w_per_zone', 'w_per_m2', 'w_per_person'],
        },
        light: {
          scheduled: ['w_per_zone', 'w_per_m2', 'w_per_person'],
        },
        natural_ventilation: {
          scheduled: ['m3_per_h_per_m2', 'volume_per_h', 'm3_per_h_per_person', 'm3_per_h_per_zone'],
          wind_and_stack: ['ratio'],
        },
        dhw: {
          scheduled: ['m3_per_day_per_azg', 'm3_per_day_per_person'],
        },
        heating_setpoint: {
          scheduled: ['celsius'],
        },
        cooling_setpoint: {
          scheduled: ['celsius'],
        },
        control: {
          scheduled: ['on_off'],
        },
      },
      schedule_methods__by_type: {
        people: ['scheduled'],
        outdoor_air: ['scheduled'],
        infiltration: ['scheduled'],
        electric_equipment: ['scheduled'],
        light: ['scheduled'],
        natural_ventilation: ['scheduled', 'wind_and_stack'],
        dhw: ['scheduled'],
        heating_setpoint: ['scheduled'],
        cooling_setpoint: ['scheduled'],
        control: ['scheduled'],
      },
      schedule_specific_defaults__by_type_and_method: {
        people: {
          scheduled: {
            latent_ratio: null,
            radiant_ratio: 0,
            heat_gain_per_person: 110,
          },
        },
        electric_equipment: {
          scheduled: {
            latent_ratio: null,
            radiant_ratio: 0.15,
            lost_ratio: 0,
          },
        },
        light: {
          scheduled: {
            radiant_ratio: 0.7,
            visible_ratio: 0.25,
            daylighting_control: false,
            daylighting_control_setpoint: 300,
            daylighting_control_minimum_light_ratio: 0,
          },
        },
        natural_ventilation: {
          scheduled: {
            variable_flow_constant_coefficient: null,
            variable_flow_temperature_coefficient: null,
            variable_flow_velocity_coefficient: null,
            variable_flow_velocity_squared_coefficient: null,
            delta_temperature: null,
            min_indoor_temperature: null,
            min_outdoor_temperature: null,
            max_indoor_temperature: null,
            max_outdoor_temperature: null,
          },
          wind_and_stack: {
            delta_temperature: 1,
            min_indoor_temperature: null,
            min_outdoor_temperature: null,
            max_indoor_temperature: null,
            max_outdoor_temperature: null,
            discharge_coefficient_for_opening: null,
            opening_area_ratio: 0.3,
          },
        },
        dhw: {
          scheduled: {
            inlet_temperature: null,
            outlet_temperature: 40,
          },
        },
      },
      system_methods__by_type: {
        heating_loop: ['convective_emitter', 'radiative_emitter'],
        cooling_loop: ['convective_emitter', 'radiative_emitter'],
        air_loop: [
          'fixed_supply_temperature_ahu',
          'zone_temperature_control_ahu',
          'zone_temperature_control_ideal_ahu',
          'extract_only',
        ],
        dhw_loop: ['ideal_water_heater'],
      },
      system_specific_defaults__by_type_and_method: {
        air_loop: {
          fixed_supply_temperature_ahu: {
            supply_heating_trigger: 19,
            supply_cooling_trigger: 26,
            heat_exchanger_bypass: false,
            heat_exchanger_bypass_outdoor_low_temperature: 19,
            heat_exchanger_bypass_setpoint_at_outdoor_low_temperature: 26,
            heat_exchanger_bypass_outdoor_high_temperature: 26,
            heat_exchanger_bypass_setpoint_at_outdoor_high_temperature: 19,
          },
          zone_temperature_control_ahu: {
            supply_heating_trigger: 19,
            supply_cooling_trigger: 26,
            heat_exchanger_bypass: false,
            heat_exchanger_bypass_outdoor_low_temperature: 19,
            heat_exchanger_bypass_setpoint_at_outdoor_low_temperature: 26,
            heat_exchanger_bypass_outdoor_high_temperature: 26,
            heat_exchanger_bypass_setpoint_at_outdoor_high_temperature: 19,
          },
        },
      },
      modification_table_refs: [
        'activity_zone_group',
        'bridge',
        'construction',
        'material',
        'opening',
        'schedule',
        'surface_group',
        'system',
        'year_profile',
      ],
      modification_methods: [
        'baseload__multiply',
        'baseload__set',
        'blind_control_value__set',
        'conductance_ratio__multiply',
        'conductance_ratio__set',
        'conductivities_ratio__multiply',
        'conductivities_ratio__set',
        'construction__set',
        'construction_a__set',
        'construction_b__set',
        'cooling_aux_ratio__multiply',
        'cooling_aux_ratio__set',
        'cooling_availability__set',
        'cooling_capacity__multiply',
        'cooling_capacity__set',
        'cooling_efficiency__multiply',
        'cooling_efficiency__set',
        'cooling_setpoint__set',
        'cooling_system__set',
        'dhw__set',
        'dhw_system__set',
        'electric_equipment__set',
        'electric_equipment_bis__set',
        'fan_sfp__multiply',
        'fan_sfp__set',
        'filter_geometries__set',
        'filter_inner_zones__set',
        'filter_outer_zones__set',
        'filter_zones__set',
        'heating_aux_ratio__multiply',
        'heating_aux_ratio__set',
        'heating_availability__set',
        'heating_capacity__multiply',
        'heating_capacity__set',
        'heating_efficiency__multiply',
        'heating_efficiency__set',
        'heating_setpoint__set',
        'heating_system__set',
        'infiltration__set',
        'light__set',
        'loop_recovery__multiply',
        'loop_recovery__set',
        'mechanical_ventilation_system__set',
        'natural_ventilation__set',
        'natural_ventilation_bis__set',
        'nominal_conductance__multiply',
        'nominal_conductance__set',
        'nominal_conductivity__multiply',
        'nominal_conductivity__set',
        'nominal_value__multiply',
        'nominal_value__set',
        'opening__set',
        'outdoor_air__set',
        'people__set',
        'psi__multiply',
        'psi__set',
        'record__delete',
        'reduction_ratio__multiply',
        'reduction_ratio__set',
        'series__increase',
        'series__increase_lower_value',
        'series__increase_upper_value',
        'series__multiply',
        'series__multiply_lower_value',
        'series__multiply_upper_value',
        'solar_heat_gain__multiply',
        'solar_heat_gain__set',
        'thickness__multiply',
        'visible_transmittance__multiply',
        'visible_transmittance__set',
        'wireframe_position__set',
        'year_profile__set',
      ],
      modification_methods__by_table_ref: {
        activity_zone_group: [
          'cooling_setpoint__set',
          'cooling_system__set',
          'dhw__set',
          'dhw_system__set',
          'electric_equipment__set',
          'electric_equipment_bis__set',
          'filter_geometries__set',
          'filter_zones__set',
          'heating_setpoint__set',
          'heating_system__set',
          'infiltration__set',
          'light__set',
          'mechanical_ventilation_system__set',
          'natural_ventilation__set',
          'natural_ventilation_bis__set',
          'outdoor_air__set',
          'people__set',
          'record__delete',
        ],
        bridge: ['construction_a__set', 'construction_b__set', 'psi__multiply', 'psi__set', 'record__delete'],
        construction: [
          'conductivities_ratio__multiply',
          'conductivities_ratio__set',
          'thickness__multiply',
          'wireframe_position__set',
        ],
        material: ['nominal_conductivity__multiply', 'nominal_conductivity__set'],
        opening: [
          'blind_control_value__set',
          'conductance_ratio__multiply',
          'conductance_ratio__set',
          'nominal_conductance__multiply',
          'nominal_conductance__set',
          'solar_heat_gain__multiply',
          'solar_heat_gain__set',
          'visible_transmittance__multiply',
          'visible_transmittance__set',
        ],
        schedule: [
          'baseload__multiply',
          'baseload__set',
          'nominal_value__multiply',
          'nominal_value__set',
          'reduction_ratio__multiply',
          'reduction_ratio__set',
          'year_profile__set',
        ],
        surface_group: [
          'construction__set',
          'filter_geometries__set',
          'filter_inner_zones__set',
          'filter_outer_zones__set',
          'opening__set',
          'record__delete',
        ],
        system: [
          'cooling_aux_ratio__multiply',
          'cooling_aux_ratio__set',
          'cooling_availability__set',
          'cooling_capacity__multiply',
          'cooling_capacity__set',
          'cooling_efficiency__multiply',
          'cooling_efficiency__set',
          'fan_sfp__multiply',
          'fan_sfp__set',
          'heating_aux_ratio__multiply',
          'heating_aux_ratio__set',
          'heating_availability__set',
          'heating_capacity__multiply',
          'heating_capacity__set',
          'heating_efficiency__multiply',
          'heating_efficiency__set',
          'loop_recovery__multiply',
          'loop_recovery__set',
        ],
        year_profile: [
          'series__increase',
          'series__increase_lower_value',
          'series__increase_upper_value',
          'series__multiply',
          'series__multiply_lower_value',
          'series__multiply_upper_value',
        ],
      },
      modification_info__by_table_ref_and_method: {
        activity_zone_group: {
          filter_tags: [],
          methods: {
            cooling_setpoint__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            cooling_system__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'system',
              },
            },
            dhw__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            dhw_system__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'system',
              },
            },
            electric_equipment__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            electric_equipment_bis__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            filter_geometries__set: {
              value: {
                type: 'FilterSourceCodeField',
                required: false,
                missing: null,
              },
            },
            filter_zones__set: {
              value: {
                type: 'FilterSourceCodeField',
                required: false,
                missing: null,
              },
            },
            heating_setpoint__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            heating_system__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'system',
              },
            },
            infiltration__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            light__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            mechanical_ventilation_system__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'system',
              },
            },
            natural_ventilation__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            natural_ventilation_bis__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            outdoor_air__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            people__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            record__delete: {
              value: {
                type: 'Constant',
                required: false,
                missing: null,
              },
            },
          },
        },
        bridge: {
          filter_tags: [],
          methods: {
            construction_a__set: {
              value: {
                type: 'LinkField',
                required: true,
                target_table_ref: 'construction',
              },
            },
            construction_b__set: {
              value: {
                type: 'LinkField',
                required: true,
                target_table_ref: 'construction',
              },
            },
            psi__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            psi__set: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            record__delete: {
              value: {
                type: 'Constant',
                required: false,
                missing: null,
              },
            },
          },
        },
        construction: {
          filter_tags: [],
          methods: {
            conductivities_ratio__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            conductivities_ratio__set: {
              value: {
                type: 'Float',
                required: false,
                missing: 1,
              },
            },
            thickness__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            wireframe_position__set: {
              value: {
                type: 'Float',
                required: true,
              },
            },
          },
        },
        material: {
          filter_tags: [],
          methods: {
            nominal_conductivity__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            nominal_conductivity__set: {
              value: {
                type: 'Float',
                required: true,
              },
            },
          },
        },
        opening: {
          filter_tags: [],
          methods: {
            blind_control_value__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            conductance_ratio__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            conductance_ratio__set: {
              value: {
                type: 'Float',
                required: false,
                missing: 1,
              },
            },
            nominal_conductance__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            nominal_conductance__set: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            solar_heat_gain__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            solar_heat_gain__set: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            visible_transmittance__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            visible_transmittance__set: {
              value: {
                type: 'Float',
                required: true,
              },
            },
          },
        },
        schedule: {
          filter_tags: [],
          methods: {
            baseload__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            baseload__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            nominal_value__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            nominal_value__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            reduction_ratio__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            reduction_ratio__set: {
              value: {
                type: 'Float',
                required: false,
                missing: 1,
              },
            },
            year_profile__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'year_profile',
              },
            },
          },
        },
        surface_group: {
          filter_tags: [],
          methods: {
            construction__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'construction',
              },
            },
            filter_geometries__set: {
              value: {
                type: 'FilterSourceCodeField',
                required: false,
                missing: null,
              },
            },
            filter_inner_zones__set: {
              value: {
                type: 'FilterSourceCodeField',
                required: false,
                missing: null,
              },
            },
            filter_outer_zones__set: {
              value: {
                type: 'FilterSourceCodeField',
                required: false,
                missing: null,
              },
            },
            opening__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'opening',
              },
            },
            record__delete: {
              value: {
                type: 'Constant',
                required: false,
                missing: null,
              },
            },
          },
        },
        system: {
          filter_tags: [],
          methods: {
            cooling_aux_ratio__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            cooling_aux_ratio__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            cooling_availability__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            cooling_capacity__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            cooling_capacity__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            cooling_efficiency__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            cooling_efficiency__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            fan_sfp__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            fan_sfp__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            heating_aux_ratio__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            heating_aux_ratio__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            heating_availability__set: {
              value: {
                type: 'LinkField',
                required: false,
                missing: null,
                target_table_ref: 'schedule',
              },
            },
            heating_capacity__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            heating_capacity__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            heating_efficiency__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            heating_efficiency__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
            loop_recovery__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            loop_recovery__set: {
              value: {
                type: 'Float',
                required: false,
                missing: null,
              },
            },
          },
        },
        year_profile: {
          filter_tags: [],
          methods: {
            series__increase: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            series__increase_lower_value: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            series__increase_upper_value: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            series__multiply: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            series__multiply_lower_value: {
              value: {
                type: 'Float',
                required: true,
              },
            },
            series__multiply_upper_value: {
              value: {
                type: 'Float',
                required: true,
              },
            },
          },
        },
      },
      template_categories: ['template_obat_use_age'],
    },
    template_obat_use_age: [
      {
        ref: 'housing_ancient',
        use: 'housing',
        age: 'ancient',
      },
      {
        ref: 'housing_recent',
        use: 'housing',
        age: 'recent',
      },
      {
        ref: 'office_ancient',
        use: 'office',
        age: 'ancient',
      },
      {
        ref: 'office_recent',
        use: 'office',
        age: 'recent',
      },
    ],
  },
  task: {
    user_task_status: ['cancelled', 'finished', 'failed', 'server_error', 'running'],
  },
  oteams: {},
};

export type IEnums = typeof enums;
export type IGeometryEnums = typeof enums['geometry'];
export type IWeatherEnums = typeof enums['weather'];
export type ISimulationGroupEnums = typeof enums['simulation_group'];
export type IObatEnums = typeof enums['obat'];
export type IObatContentEnums = typeof enums['obat']['obat_contents'];

export type IObatContentsFlatEnums = IEnums['obat']['obat_contents'];
const obatContentsFlatEnumsKeys = _keys(enums.obat.obat_contents) as Array<keyof IObatContentsFlatEnums>;
const obatContentsFlatEnumsKeysTuple = stringsTuple(...obatContentsFlatEnumsKeys);
export type IObatContentsFlatEnumsKey = typeof obatContentsFlatEnumsKeysTuple[number];
export const ocfe = mapStrings<IObatContentsFlatEnumsKey>(obatContentsFlatEnumsKeysTuple);
