// tslint:disable:no-any
import { _includes } from 'libs/lodash';
import { ParseResult } from 'papaparse';
import { IMonthlyResultsTemplate } from 'redux/restResources/detail/simulationGroup/reducer';
import aggregateTemplates from 'utils/configs/monthlyResultsPivotTemplates/aggregate.json';
import bySimulationTemplates from 'utils/configs/monthlyResultsPivotTemplates/bySimulation.json';
import { IMonthlyResultsSection, IResultsView, mras, mrbss, rv } from 'utils/strings/results';

export const getPivotTableConfig = (
  resultsMode: IResultsView,
  section: IMonthlyResultsSection,
  sectionData: string | ParseResult['data'],
  template: IMonthlyResultsTemplate
): any => {
  const { name, mode, unit, energy } = template;

  const templates = resultsMode === rv.bySimulation ? bySimulationTemplates : aggregateTemplates;

  let pivotTemplate = {
    name: '',
    mode: '',
    unit: '',
    energy: '',
  };

  let pivotConfig = { data: sectionData };

  if (name === '' || name === 'empty') {
    // @ts-ignore
    pivotConfig = { ...pivotConfig, ...templates[section].empty };
  } else {
    // @ts-ignore
    if (name in templates[section]) {
      // @ts-ignore
      pivotTemplate = templates[section][name][mode];
      if (
        _includes(
          [mrbss.out_monthly_thermal_balance, mras.out_monthly_consumption_ef, mras.out_monthly_consumption_ep],
          section
        )
      ) {
        // @ts-ignore
        pivotTemplate = templates[section][name][mode][unit];
      }
      if (section === mrbss.out_monthly_consumption) {
        // @ts-ignore
        pivotTemplate = templates[section][name][mode][unit][energy];
      }
    }
    pivotConfig = { ...pivotConfig, ...pivotTemplate };
  }

  return pivotConfig;
};
