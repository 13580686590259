import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { orc } from 'utils/strings/resourceClasses';

import { EfEpForm as EfEpFormComponent } from './EfEpForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const efEpToEdit = selectFormInitialResourceFromTable(orc.ef_ep)(rootState);

  return {
    efEpToEdit,
  };
};

const mapDispatchToProps = {};

export const EfEpForm = connect(mapStateToProps, mapDispatchToProps)(EfEpFormComponent);
