import { _find } from 'libs/lodash';
import { _get } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { PlotParams } from 'react-plotly.js';
import { IHeatmap } from 'types/Obat/Calendar';
import { formatISODate } from 'utils/functions/datetimes/formatISODate';

import { getCalendarHeatmapColorscale } from '../getCalendarHeatmapColorscale';

export const getCalendarHeatmapData = (
  legend: IHeatmap['legend'],
  dayGroups: IHeatmap['day_groups'],
  days: IHeatmap['days']
): PlotParams['data'] => {
  const colorscale = getCalendarHeatmapColorscale(legend);

  const text = _map(dayGroups.data, (dayGroupRow: number[], dayGroupRowIndex: number) => {
    return _map(dayGroupRow, (dayGroupValue: number, dayGroupValueIndex: number) => {
      const rawDate = days.data[dayGroupRowIndex][dayGroupValueIndex];
      const formattedDate = rawDate && formatISODate(rawDate, 'DD/MM');

      const dayGroupText = dayGroupValue < 0 ? '' : ` ${_get(_find(legend, ['num', dayGroupValue]), 'ref')}`;

      return formattedDate && `${formattedDate} ${dayGroupText}`;
    });
  });

  return [
    {
      z: dayGroups.data, // day group number
      xgap: 1, // separators between boxes
      ygap: 1, // separators between boxes
      showscale: false, // no legend
      colorscale, // control colors
      hoverinfo: 'text', // use text field on hover
      // @ts-ignore
      text, // for hover
      type: 'heatmap',
    },
  ];
};
