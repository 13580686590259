import { FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { CodeEditor } from 'components/CodeEditor';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IScriptRequestParams } from 'types/Obat/Script';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './ScriptForm.container';

export interface IScriptFormValues {
  code: string;
  filter_geometries: string;
  ref: string;
  type: string;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IScriptFormValues, IScriptRequestParams>();

export class ScriptForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={800}
        resourceClass={orc.script}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IScriptFormValues>): ReactNode => {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikDropdownInput
                field={'type'}
                formikProps={formikProps}
                options={this.props.typeOptions}
                required
                renderOptionLabel={$tEnums}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'filter_geometries'}
                formikProps={formikProps}
                label={$t('geometriesFilter')}
                multiline
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormLabel>{$t('code')}</FormLabel>
              <CodeEditor onChange={this.onCodeChange(formikProps)} code={formikProps.values.code} />
            </Grid>
          </Grid>
        </>
      );
    };
  };

  private getInitialFormValues = (): IScriptFormValues => {
    const { scriptToEdit } = this.props;

    return {
      ref: setFormInitialValue(scriptToEdit, 'ref'),
      type: setFormInitialValue(scriptToEdit, 'type'),
      code: setFormInitialValue(scriptToEdit, 'code'),
      filter_geometries: setFormInitialValue(scriptToEdit, 'filter_geometries'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IScriptFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      type: getYupStringValidation(true),
      filter_geometries: getYupStringValidation(),
    }) as yup.ObjectSchema<IScriptFormValues>;
  };

  private onCodeChange = (formikProps: FormikProps<IScriptFormValues>) => {
    return (newCode: string): void => {
      formikProps.setFieldValue('code', newCode);
    };
  };
}
