import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Button } from 'components/Buttons';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';

interface IProps {
  isInitiallyCollapsed?: boolean;
  marginTop?: number;
  noCollapse?: boolean;
  noDivider?: boolean;
  title?: string;
}

interface IStyleProps {
  marginTop?: number;
}

interface IState {
  isCollapsed: boolean;
}

export class Section extends PureComponent<IProps> {
  public state: IState = {
    isCollapsed: !!this.props.isInitiallyCollapsed,
  };
  public render(): ReactNode {
    const { marginTop, children, title, noDivider, noCollapse } = this.props;
    const { isCollapsed } = this.state;

    return (
      <CollapseSectionContainer marginTop={marginTop}>
        <SectionHeader>
          <SectionDivider borderTop={noDivider ? 'none' : undefined} bgColor={noDivider ? 'transparent' : undefined}>
            <span>{title}</span>
          </SectionDivider>
          {!noCollapse && (
            <Button
              onClick={this.onCollapseToggle}
              Icon={isCollapsed ? <ExpandMore fontSize={'small'} /> : <ExpandLess fontSize={'small'} />}
              bgColor={'white'}
              color={theme.colors.main}
              borderColor={theme.colors.main}
              height={18}
              noShadow
            />
          )}
        </SectionHeader>
        <Collapse in={!isCollapsed}>
          <div>{children}</div>
        </Collapse>
      </CollapseSectionContainer>
    );
  }

  private onCollapseToggle = (): void => {
    this.setState((state: IState) => ({
      isCollapsed: !state.isCollapsed,
    }));
  };
}

const CollapseSectionContainer = styled.div`
  margin: ${(props: IStyleProps) => props.marginTop || '0'}px 0 4px;
`;

const SectionHeader = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
  button {
    position: absolute;
    top: 14px;
    right: 0;
    z-index: 1;
    svg {
      margin-top: -2px;
    }
  }
`;

interface IDividerProps {
  bgColor?: string;
  borderTop?: string;
}

export const SectionDivider = styled.div`
  width: 100%;
  display: block;
  line-height: 0;
  margin: 16px 0;
  span {
    position: relative;
    display: inline-block;
    background-color: ${(props: IDividerProps) => props.bgColor || '#fff'};
    padding: 5px;
    z-index: 1;
    color: ${theme.colors.main};
    letter-spacing: 0.2px;
  }
  ::before {
    content: '';
    display: block;
    position: relative;
    border-top: ${(props: IDividerProps) => props.borderTop || `1px solid ${theme.colors.main}`};
    top: 7px;
    z-index: 0;
    height: 1px;
  }
`;
