import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { updateProjectionTableSectionAction } from 'redux/restResources/detail/obat/actions';
import {
  selectIsProjectionTaskRunning,
  selectProjectionData,
  selectProjectionParams,
  selectProjectionSection,
} from 'redux/restResources/detail/obat/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { initializeTableAction } from 'redux/table/actions';
import { selectTableResources } from 'redux/table/selectors';
import { getTaskKey } from 'utils/functions/getTaskKey';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { ProjectionTable as Component } from './ProjectionTable.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeObat = selectRouteResource(rrc.obat)(rootState);
  const projectionData = selectProjectionData(rootState);
  const projectionTableData = projectionData && projectionData.table;

  return {
    routeObat,
    projectionTableData,
    isProjectionTaskRunning: selectIsProjectionTaskRunning(rootState),
    tableResources: selectTableResources(rootState)(),
    projectionSection: selectProjectionSection(rootState),
    projectionParams: selectProjectionParams(rootState),
    taskKey: getTaskKey(dra.projection, routeObat.id),
  };
};

const mapDispatchToProps = {
  initializeTable: initializeTableAction,
  updateProjectionSection: updateProjectionTableSectionAction,
};

export const ProjectionTable = connect(mapStateToProps, mapDispatchToProps)(Component);
