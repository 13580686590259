import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { openPlotsModalAction } from 'redux/modals/plotsModal/actions';
import {
  selectGroupedObatResourcesIdsListFunction,
  selectObatResourceCreationPositionOptions,
} from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { orc } from 'utils/strings/resourceClasses';

import { ActivityForm as ActivityFormComponent } from './ActivityZoneGroupForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const activityZoneGroupToEdit = selectFormInitialResourceFromTable(orc.activity_zone_group)(rootState);

  return {
    activityZoneGroupToEdit,
    createPositionOptions: selectObatResourceCreationPositionOptions(orc.activity_zone_group)(rootState),
    selectSchedulesByTypeOptions: selectGroupedObatResourcesIdsListFunction(orc.schedule, 'type')(rootState),
    selectSystemsByTypeOptions: selectGroupedObatResourcesIdsListFunction(orc.system, 'type')(rootState),
  };
};

const mapDispatchToProps = {
  openPlotsModal: openPlotsModalAction,
};

export const ActivityForm = connect(mapStateToProps, mapDispatchToProps)(ActivityFormComponent);
