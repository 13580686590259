import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';

interface IProps {
  email: string;
}

export class EmailLink extends PureComponent<IProps> {
  public render(): ReactNode {
    const { email } = this.props;

    return <LinkContainer href={'mailto:' + email}>{email}</LinkContainer>;
  }
}

const LinkContainer = styled.a`
  color: ${theme.colors.link};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
