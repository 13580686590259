import { CardHeader, SimpleCard } from 'components/Cards';
import { CodeEditor } from 'components/CodeEditor';
import React, { PureComponent, ReactNode } from 'react';
import { IScript } from 'types/Obat/Script';
import { $t } from 'utils/functions/translate';

interface IProps {
  script: IScript;
}

export class ScriptDetail extends PureComponent<IProps> {
  public render(): ReactNode {
    const { script } = this.props;

    return (
      <>
        <SimpleCard>
          <CardHeader>{$t('code')}</CardHeader>
          <CodeEditor code={script.code} />
        </SimpleCard>
      </>
    );
  }
}
