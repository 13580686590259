import { IGeometriesTemplates } from 'types/Geometry/Geometry';
import { IProgressStatus, ps } from 'utils/strings/progressStatus';

import {
  CLEAR_THREEJS_ACTION,
  IGeometryActions,
  UPDATE_GEOMETRIES_TEMPLATES_ACTION,
  UPDATE_GEOMETRY_IMPORT_PROGRESS_ACTION,
  UPDATE_THREEJS_ACTION,
} from './actions';

export interface IGeometryState {
  importProgress: IProgressStatus;
  threejs?: object;
  templates?: IGeometriesTemplates;
}

export const initialState = {
  importProgress: ps.finished,
  threejs: undefined,
  templates: undefined,
};

export const geometryReducer = (state: IGeometryState = initialState, action: IGeometryActions): IGeometryState => {
  switch (action.type) {
    case UPDATE_GEOMETRY_IMPORT_PROGRESS_ACTION:
      return {
        ...state,
        importProgress: action.payload.progress,
      };

    case UPDATE_THREEJS_ACTION:
      return {
        ...state,
        threejs: action.payload.threejs,
      };

    case CLEAR_THREEJS_ACTION:
      return {
        ...state,
        threejs: undefined,
      };

    case UPDATE_GEOMETRIES_TEMPLATES_ACTION:
      return {
        ...state,
        templates: action.payload.templates,
      };

    default:
      return state;
  }
};
