import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectIsLogsScrollEnabled, selectSimulationLogs } from 'redux/restResources/detail/simulationGroup/selectors';

import { LogsConsole as Component } from './LogsConsole.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    simulationLogs: selectSimulationLogs(rootState),
    isScrollEnabled: selectIsLogsScrollEnabled(rootState),
  };
};

const mapDispatchToProps = {};

export const LogsConsole = connect(mapStateToProps, mapDispatchToProps)(Component);
