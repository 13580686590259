// tslint:disable:no-any
import { _find } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { _split } from 'libs/lodash';
import { selectResourceInTableResources } from 'redux/table/selectors';
import { IApiResourceClass } from 'utils/strings/resourceClasses';
import { orc } from 'utils/strings/resourceClasses';

import { IRootState } from '../../reducer';

export const selectIsDeletionModalOpen = (rootState: IRootState): boolean => {
  return rootState.modals.deletionModal.isOpen;
};

export const selectResourceClassToDelete = (rootState: IRootState): IApiResourceClass | undefined => {
  return rootState.modals.deletionModal.resourceClass;
};

export const selectResourceToDelete = (rootState: IRootState): any => {
  const { resourceId, resourceClass } = rootState.modals.deletionModal;

  if (resourceId && resourceClass) {
    if (_has(orc, resourceClass)) {
      if (_includes(resourceId, '~')) {
        const parentId = _split(resourceId, '~')[0];
        const parent = selectResourceInTableResources(rootState)(parentId);
        if (parent) {
          switch (resourceClass) {
            case orc.layer: {
              return _find(parent.layers_contents, ['id', resourceId]);
            }
            case orc.day_group: {
              return _find(parent.day_groups_contents, ['id', resourceId]);
            }
            case orc.period: {
              const dayGroupWeakRef = _split(resourceId, '~')[1];
              const dayGroup = _find(parent.day_groups_contents, ['weak_ref', dayGroupWeakRef]);

              return _find(dayGroup.periods_contents, ['id', resourceId]);
            }
            case orc.modification: {
              return _find(parent.modifications_contents, ['id', resourceId]);
            }
          }
        }
      }
    }

    return selectResourceInTableResources(rootState)(resourceId);
  }
};
