import Grid from '@material-ui/core/Grid';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IOutputZoneGroupRequestParams } from 'types/Obat/OutputZoneGroup';
import { fdw } from 'utils/configs/drawerWidths';
import { getEditionPositionOptions } from 'utils/functions/obatResources/position/getEditionPositionOptions';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './OutputZoneGroupForm.container';

export interface IOutputZoneGroupFormValues {
  filter_geometries: string;
  filter_zones: string;
  ref: string;
  sort_index: number;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IOutputZoneGroupFormValues, IOutputZoneGroupRequestParams>();

export class OutputZoneGroupForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.output_zone_group}
        subFormResources={[orc.output_zone_group]}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IOutputZoneGroupFormValues>): ReactNode => {
      const { createPositionOptions, outputZoneGroupToEdit } = this.props;

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'sort_index'}
                formikProps={formikProps}
                options={
                  outputZoneGroupToEdit
                    ? getEditionPositionOptions(createPositionOptions, outputZoneGroupToEdit.ref)
                    : createPositionOptions
                }
                label={$t('position')}
                required
              />
            </Grid>
          </Grid>

          <Section title={$t('filters')} marginTop={20}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'filter_geometries'}
                  formikProps={formikProps}
                  label={$t('geometriesFilter')}
                  multiline
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'filter_zones'}
                  formikProps={formikProps}
                  label={$t('zonesFilter')}
                  multiline
                />
              </Grid>
            </Grid>
          </Section>
        </>
      );
    };
  };

  private getInitialFormValues = (): IOutputZoneGroupFormValues => {
    const { outputZoneGroupToEdit, createPositionOptions } = this.props;

    return {
      ref: setFormInitialValue(outputZoneGroupToEdit, 'ref'),
      sort_index: outputZoneGroupToEdit
        ? outputZoneGroupToEdit.sort_index.toString()
        : (createPositionOptions.length - 1).toString(),
      filter_geometries: setFormInitialValue(outputZoneGroupToEdit, 'filter_geometries'),
      filter_zones: setFormInitialValue(outputZoneGroupToEdit, 'filter_zones'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IOutputZoneGroupFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      filter_geometries: getYupStringValidation(),
      filter_zones: getYupStringValidation(),
      sort_index: getYupNumberValidation(true),
    }) as yup.ObjectSchema<IOutputZoneGroupFormValues>;
  };
}
