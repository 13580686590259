import { AlertIcon } from 'components/Icons';
import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import React, { PureComponent, ReactNode } from 'react';
import { geometryMenuPages, getGeometryMenuPageRoute, projectMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { IGeometryListed } from 'types/Geometry/Geometry';
import { IRestResource } from 'types/RestResource';
import { formatISODateToDstDatetime } from 'utils/functions/datetimes/formatISODate';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rrc } from 'utils/strings/resourceClasses';
import { rm } from 'utils/strings/routingMenus';

import { GeometryForm } from '../GeometryForm';
import { IProps } from './GeometriesList.container';

export class GeometriesList extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeProject } = this.props;

    return (
      <RestResourcesListPage
        routingMenu={rm.project}
        routeParentId={routeProject.id}
        selectedPage={projectMenuPages.geometries}
        FormComponent={GeometryForm}
        getCreationSuccessLandingPageUrl={this.getCreationSuccessLandingPageUrl}
        resourceClass={rrc.geometry}
        parentResourceClass={rrc.project}
        columnsParams={this.getColumnsParams()}
        availableActions={['edit', 'copy', 'delete']}
      />
    );
  }

  private getCreationSuccessLandingPageUrl = (routeProjectId: string, createdResource: IRestResource) => {
    const landingPage =
      createdResource.format === 'floorspace' ? geometryMenuPages.drawing2D : geometryMenuPages.default;

    return getGeometryMenuPageRoute(landingPage, routeProjectId, createdResource.id);
  };

  private getColumnsParams = (): ITableColumnParams[] => [
    {
      id: 'name',
      resourceField: 'name',
      getNavigationUrl: (geometry: IGeometryListed): string => {
        const landingPage = geometry.format === 'floorspace' ? geometryMenuPages.drawing2D : geometryMenuPages.default;

        return getGeometryMenuPageRoute(landingPage, geometry.project.id, geometry.id);
      },
    },
    {
      id: 'lastModification',
      resourceField: 'last_modification',
      renderCustomText: (geometry: IGeometryListed) => formatISODateToDstDatetime(geometry.last_modification),
    },
    { id: 'format', resourceField: 'format', withFilterOptions: true },
    {
      id: '3D',
      resourceField: 'empty',
      withFilterOptions: true,
      renderCustomDOM: (geometry: IGeometryListed): ReactNode => {
        return (
          <TableIcon>
            {geometry.empty ? (
              <AlertIcon status={as.empty} tooltipContent={$t('notImported')} />
            ) : (
              <AlertIcon status={as.success} tooltipContent={$t('imported')} />
            )}
          </TableIcon>
        );
      },
      getCustomDOMFilterComparisonValue: (geometry: IGeometryListed): string =>
        geometry.empty ? $t('notImported') : $t('imported'),
    },
  ];
}

const TableIcon = styled.div`
  margin-bottom: -4px;
`;
