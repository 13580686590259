import { _isNull } from 'libs/lodash';
import { _isNumber } from 'libs/lodash';
import { _isString } from 'libs/lodash';
import { _replace } from 'libs/lodash';
import { _toNumber } from 'libs/lodash';
import { renderFloat } from 'utils/functions/renderValue/renderValue';

export const calculateCorrectedValue = (nominalValue: number | '' | null, ratio: number | '' | null): string => {
  if (_isNull(nominalValue) || _isNull(ratio)) {
    return '-';
  }

  if (nominalValue === '' || ratio === '') {
    return '';
  }

  if (_isString(nominalValue)) {
    nominalValue = _toNumber(_replace(nominalValue, ',', '.'));
  }

  if (_isString(ratio)) {
    ratio = _toNumber(_replace(ratio, ',', '.'));
  }

  if (_isNumber(nominalValue) && _isNumber(ratio)) {
    return renderFloat(nominalValue * ratio);
  }

  return '';
};
