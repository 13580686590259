import { connect } from 'react-redux';
import {
  selectObatContentsFlatOptionsList,
  selectSystemMethodOptionsFunction,
  selectSystemSpecificDefaultValuesOptionsFunction,
} from 'redux/enums/selectors';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { openPlotsModalAction } from 'redux/modals/plotsModal/actions';
import { selectGroupedObatResourcesIdsListFunction } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { ocfe } from 'utils/configs/enums';
import { orc } from 'utils/strings/resourceClasses';

import { SystemForm as Component } from './SystemForm.component';

const mapStateToProps = (rootState: IRootState) => {
  const systemToEdit = selectFormInitialResourceFromTable(orc.system)(rootState);

  return {
    systemToEdit,
    /* enums */
    typeOptions: selectObatContentsFlatOptionsList(ocfe.system_types)(rootState),
    heatingEnergyOptions: selectObatContentsFlatOptionsList(ocfe.heating_energies)(rootState),
    coolingEnergyOptions: selectObatContentsFlatOptionsList(ocfe.cooling_energies)(rootState),
    selectSchedulesByTypeOptions: selectGroupedObatResourcesIdsListFunction(orc.schedule, 'type')(rootState),
    selectMethodOptions: selectSystemMethodOptionsFunction(rootState),
    selectSpecificDefaultValues: selectSystemSpecificDefaultValuesOptionsFunction(rootState),
  };
};

const mapDispatchToProps = {
  openPlotsModal: openPlotsModalAction,
};

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

export const SystemForm = connect(mapStateToProps, mapDispatchToProps)(Component);
