import { ModalFrame } from 'components/Modals/ModalFrame';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { IAlertStatus } from 'utils/strings/alertStatus';

import { IProps } from './AlertModal.container';

export class AlertModal extends PureComponent<IProps> {
  public render(): ReactNode {
    const { closeAlertModal, alertText, alertStatus, modalTitle } = this.props;

    const Title = <AlertTitle status={alertStatus}>{modalTitle || $t(alertStatus)}</AlertTitle>;

    const Content = <AlertTextContainer status={alertStatus}>{alertText}</AlertTextContainer>;

    /* Not able to make the copyToClipboard function works for the moment */
    // const Actions = (
    //   <ButtonsContainer>
    //     <Button onClick={this.copyToClipboard}>{$t('copyToClipboard')}</Button>
    //   </ButtonsContainer>
    // );

    return (
      <ModalFrame isOpen={!!alertText} onClose={closeAlertModal} Content={Content} Title={Title} maxWidth={'md'} />
    );
  }

  // private copyToClipboard = (): void => {
  //   const { errorText } = this.props;
  //   if (errorText) {
  //     copyToClipboard(errorText);
  //   }
  // };
}

interface IStatusColorProps {
  status: IAlertStatus | undefined;
}

const AlertTitle = styled.div`
  // @ts-ignore
  color: ${(props: IStatusColorProps): string => (props.status ? theme.colors[props.status] : 'black')};
`;

const AlertTextContainer = styled.pre`
  max-height: 75vh;
  overflow-y: auto;
  background-color: ${(props: IStatusColorProps): string =>
    // @ts-ignore
    (props.status ? theme.colors[props.status] : 'white') + '55'};
  color: black;
  padding: 8px;
  border-radius: ${theme.borderRadius.small};
  word-break: break-word;
`;

// const ButtonsContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
// `;
