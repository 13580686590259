// tslint:disable:no-any

import { _filter } from 'libs/lodash';
import { ITableResourcesList } from 'redux/table/reducer';
import { ITableFilterValues } from 'redux/table/reducer';
import { isAllColumnsFilterMatch } from 'utils/functions/filter/isAllColumnsFilterMatch';
import { ITableFilterParams } from 'utils/functions/pickColumnParams/pickColumnParams';

export const applyFilter = (
  tableFullResourcesList: any[],
  tableFilterParams: ITableFilterParams,
  tableFilterValues: ITableFilterValues
): ITableResourcesList => {
  return _filter(tableFullResourcesList, (resource: any): boolean => {
    return isAllColumnsFilterMatch(resource, tableFilterParams, tableFilterValues);
  });
};
