import { Grid } from '@material-ui/core';
import { Button } from 'components/Buttons';
import { CenterButtons } from 'components/Buttons/ButtonsContainers';
import { SimpleCard } from 'components/Cards';
import {
  ConfigCardField,
  ConfigCardFieldNamesCell,
  ConfigCardFieldValuesCell,
  ConfigCardSectionRow,
  ConfigCardSectionTitleCell,
  ConfigCardTable,
} from 'components/Cards/ConfigCard';
import { ConfigCardHeader } from 'components/Cards/ConfigCard/ConfigCardHeader';
import { Page } from 'components/Page';
import React, { PureComponent, ReactNode } from 'react';
import { Edit3 } from 'react-feather';
import { weatherMenuPages } from 'routing/routes';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { renderTextBoolean } from 'utils/functions/renderValue/renderValue';
import { $t } from 'utils/functions/translate';
import { rm } from 'utils/strings/routingMenus';

const Rt2012Method = 'rt2012';
const AshraeMethod = 'ashrae';

import { WeatherConfigForm } from '../WeatherConfigForm';

import { IProps } from './WeatherConfig.container';

export class WeatherConfig extends PureComponent<IProps> {
  public render(): ReactNode {
    const {
      routeWeather,
      openConfigForm,
      isWritePermissionMissing,
      isSeatMissing,
      stationName,
      formDrawerWidth,
    } = this.props;
    const { openergy_historical_weather_series } = routeWeather;

    return (
      <Page pageTitle={$t('config')} routingMenu={rm.weather} selectedPage={weatherMenuPages.weatherConfig}>
        {openergy_historical_weather_series && openergy_historical_weather_series.empty && formDrawerWidth === 0 && (
          <CenterButtons isPulsing>
            <Button
              Icon={<Edit3 size={12} />}
              text={$t('configure')}
              onClick={openConfigForm}
              isWritePermissionMissing={isWritePermissionMissing}
              isSeatMissing={isSeatMissing}
            />
          </CenterButtons>
        )}

        {(!openergy_historical_weather_series || openergy_historical_weather_series.empty === false) && (
          <Grid container>
            <Grid item xs={6}>
              <SimpleCard>
                <ConfigCardHeader title={$t('config')} onClick={openConfigForm} />
                <ConfigCardTable>
                  <tbody>
                    {openergy_historical_weather_series && (
                      <ConfigCardSectionRow isFirst>
                        <ConfigCardSectionTitleCell>{$t('station')}</ConfigCardSectionTitleCell>
                        <ConfigCardFieldNamesCell>
                          <ConfigCardField>{$t('name')}</ConfigCardField>
                        </ConfigCardFieldNamesCell>
                        <ConfigCardFieldValuesCell>
                          <ConfigCardField>{renderTextValueElseDash(stationName)}</ConfigCardField>
                        </ConfigCardFieldValuesCell>
                      </ConfigCardSectionRow>
                    )}
                    <ConfigCardSectionRow isFirst={!openergy_historical_weather_series} isLast>
                      <ConfigCardSectionTitleCell>{$t('location')}</ConfigCardSectionTitleCell>
                      <ConfigCardFieldNamesCell>
                        <ConfigCardField>{$t('latitude')}</ConfigCardField>
                        <ConfigCardField>{$t('longitude')}</ConfigCardField>
                        <ConfigCardField>{$t('altitude')}</ConfigCardField>
                        <ConfigCardField>{$t('timeZone')}</ConfigCardField>
                        {!!routeWeather.sizing && routeWeather.sizing.method === Rt2012Method ? (
                          <>
                            <ConfigCardField>{$t('department')}</ConfigCardField>
                            <ConfigCardField>{$t('coastal')}</ConfigCardField>
                          </>
                        ) : null}
                      </ConfigCardFieldNamesCell>
                      <ConfigCardFieldValuesCell>
                        <ConfigCardField>{renderTextValueElseDash(routeWeather.location_latitude)}</ConfigCardField>
                        <ConfigCardField>{renderTextValueElseDash(routeWeather.location_longitude)}</ConfigCardField>
                        <ConfigCardField>{renderTextValueElseDash(routeWeather.location_altitude)}</ConfigCardField>
                        <ConfigCardField>
                          {renderTextValueElseDash(routeWeather.location_time_zone_ref)}
                        </ConfigCardField>
                        {!!routeWeather.sizing && routeWeather.sizing.method === Rt2012Method ? (
                          <>
                            <ConfigCardField>
                              {routeWeather.sizing ? renderTextValueElseDash(routeWeather.sizing.data.department) : '-'}
                            </ConfigCardField>
                            <ConfigCardField>
                              {routeWeather.sizing ? renderTextBoolean(routeWeather.sizing.data.is_coast) : '-'}
                            </ConfigCardField>
                          </>
                        ) : null}
                      </ConfigCardFieldValuesCell>
                    </ConfigCardSectionRow>
                    {!!routeWeather.sizing && routeWeather.sizing.method === Rt2012Method && (
                      <ConfigCardSectionRow isLast>
                        <ConfigCardSectionTitleCell>{$t('sizing')}</ConfigCardSectionTitleCell>
                        <ConfigCardFieldNamesCell>
                          {<ConfigCardField>{$t('method')}</ConfigCardField>}
                        </ConfigCardFieldNamesCell>
                        <ConfigCardFieldValuesCell>
                          <ConfigCardField>
                            {routeWeather.sizing ? renderTextValueElseDash(routeWeather.sizing.method) : '-'}
                          </ConfigCardField>
                        </ConfigCardFieldValuesCell>
                      </ConfigCardSectionRow>
                    )}
                    {!!routeWeather.sizing && routeWeather.sizing.method === AshraeMethod && (
                      <ConfigCardSectionRow isLast>
                        <ConfigCardSectionTitleCell>{$t('sizing')}</ConfigCardSectionTitleCell>
                        <ConfigCardFieldNamesCell>
                          <ConfigCardField>{$t('method')}</ConfigCardField>
                          <ConfigCardField>{$t('ashrae_wmo')}</ConfigCardField>
                        </ConfigCardFieldNamesCell>
                        <ConfigCardFieldValuesCell>
                          <ConfigCardField>
                            {routeWeather.sizing ? renderTextValueElseDash(routeWeather.sizing.method) : '-'}
                          </ConfigCardField>
                          <ConfigCardField>
                            {routeWeather.sizing ? renderTextValueElseDash(routeWeather.sizing.data.ashrae_wmo) : '-'}
                          </ConfigCardField>
                        </ConfigCardFieldValuesCell>
                      </ConfigCardSectionRow>
                    )}
                  </tbody>
                </ConfigCardTable>
              </SimpleCard>
            </Grid>
          </Grid>
        )}

        <WeatherConfigForm />
      </Page>
    );
  }
}
