import Grid from '@material-ui/core/Grid';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { FormikProps } from 'formik';
import { _includes } from 'libs/lodash';
import { getYupStringValidation } from 'libs/yup';
import React, { Component, createRef, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './ObatResourceCopyFormDrawer.container';

export interface IObatResourceCopyFormValues {
  ref: string;
}

export interface IObatResourceCopyRequestParams extends IObatResourceCopyFormValues {
  sort_index?: number;
}

export class ObatResourceCopyFormDrawer extends Component<IProps> {
  public ObatResourceFormDrawer = getObatResourceFormDrawer<IObatResourceCopyFormValues, IObatResourceCopyFormValues>();
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { ObatResourceFormDrawer } = this;
    const { initialObatResource, resourceClass } = this.props;

    return (
      <ObatResourceFormDrawer
        isCopy
        title={$t('copyOf', { name: initialObatResource && initialObatResource.ref })}
        renderForm={this.renderForm}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getRequestParams={this.getRequestParams}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.small}
        resourceClass={resourceClass}
      />
    );
  }

  public renderForm = (formikProps: FormikProps<IObatResourceCopyFormValues>): ReactNode => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikWriteInput
            field={'ref'}
            formikProps={formikProps}
            label={$t('name')}
            required
            inputRef={this.formFocusRef}
          />
        </Grid>
      </Grid>
    );
  };

  private getInitialFormValues = (): IObatResourceCopyFormValues => {
    return { ref: '' };
  };

  private getRequestParams = (formValues: IObatResourceCopyFormValues): IObatResourceCopyRequestParams => {
    const { initialObatResource, resourceClass } = this.props;

    return _includes([orc.activity_zone_group, orc.surface_group, orc.output_zone_group], resourceClass)
      ? { ...formValues, sort_index: (initialObatResource.sort_index + 1).toString() }
      : formValues;
  };

  private getValidationSchema = (): yup.ObjectSchema<IObatResourceCopyFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
    }) as yup.ObjectSchema<IObatResourceCopyFormValues>;
  };
}
