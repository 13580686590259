import Grid from '@material-ui/core/Grid';
import { DropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput';
import { OplusLoader } from 'components/Loader';
import { WeatherMeasurePlot } from 'pages/WeatherMenu/Data/Measures/WeatherMeasurePlot';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { $tWeatherSeries } from 'utils/functions/translate/translate';
import { ps } from 'utils/strings/progressStatus';

import { IProps } from './WeatherMeasures.container';

export class WeatherMeasures extends PureComponent<IProps> {
  public componentDidMount(): void {
    const { routeWeather, downloadWeatherSeriesHourlyData } = this.props;
    if (!routeWeather.empty) {
      downloadWeatherSeriesHourlyData();
    }
  }

  public componentWillUnmount(): void {
    const { clearWeatherMeasures } = this.props;
    clearWeatherMeasures();
  }

  public render(): ReactNode {
    const {
      isFetchingWeatherData,
      plottedSeries,
      measuresSeriesIdOptions,
      plottedMeasure,
      measuresYearOptions,
      routeWeather,
    } = this.props;

    return (
      <FormAndPlotContainer>
        {plottedMeasure && (
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {!routeWeather.generic_weather_series && (
                <DropdownInput
                  field={'seriesYear'}
                  label={$t('year')}
                  value={plottedMeasure.year}
                  options={measuresYearOptions}
                  onSelect={this.onMeasureYearSelect}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <DropdownInput
                field={'seriesId'}
                label={$t('measure')}
                value={plottedMeasure.seriesId}
                options={measuresSeriesIdOptions}
                onSelect={this.onMeasureSeriesIdSelect}
                renderOptionLabel={$tWeatherSeries}
              />
            </Grid>
          </Grid>
        )}
        {isFetchingWeatherData && (
          <LoaderContainer>
            <OplusLoader progress={ps.fetchingData} />
          </LoaderContainer>
        )}
        {!isFetchingWeatherData && plottedSeries && plottedMeasure && (
          <Grid container spacing={1}>
            <WeatherMeasurePlot />
          </Grid>
        )}
      </FormAndPlotContainer>
    );
  }

  private onMeasureSeriesIdSelect = (_field: string, selectedId: string): void => {
    const { downloadWeatherSeriesHourlyData, plottedMeasure } = this.props;
    if (plottedMeasure) {
      downloadWeatherSeriesHourlyData({ ...plottedMeasure, seriesId: selectedId });
    }
  };

  private onMeasureYearSelect = (_field: string, selectedYear: string): void => {
    const { downloadWeatherSeriesHourlyData, plottedMeasure } = this.props;
    if (plottedMeasure) {
      downloadWeatherSeriesHourlyData({ ...plottedMeasure, year: selectedYear });
    }
  };
}

const FormAndPlotContainer = styled.div`
  margin-top: 12px;
`;

const LoaderContainer = styled.div`
  margin-top: 32px;
`;
