import { _includes } from 'libs/lodash';

export interface IConstructionBoundsLabels {
  left: string;
  right: string;
}

export const getConstructionBoundsLabels = (surfaceCategory: string): IConstructionBoundsLabels => {
  if (!_includes(surfaceCategory, 'wall')) {
    /* all floors and roofs */
    return { left: 'down', right: 'up' };
  } else if (!_includes(surfaceCategory, 'interior')) {
    /* exterior walls */
    return { left: 'int', right: 'ext' };
  } else {
    /* interior wall */
    return { left: '', right: '' };
  }
};
