// tslint:disable:no-any
import { ISelectInputProps } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput/DropdownInput.component';
import React, { PureComponent, ReactNode } from 'react';
import { displayFormFieldError } from 'utils/functions/forms/displayFormFieldError/displayFormFieldError';

import { SwitchInput } from '../SwitchInput';
import { ISwitchInputProps } from '../SwitchInput/SwitchInput.component';

interface IProps extends Pick<ISwitchInputProps, Exclude<keyof ISwitchInputProps, 'value' | 'onSelect'>> {
  formikProps: any;
  onSelect?: ISelectInputProps['onSelect'];
}

export class FormikSwitchInput extends PureComponent<IProps> {
  public render(): ReactNode {
    const { formikProps, field, onSelect, ...switchInputProps } = this.props;

    return (
      <SwitchInput
        {...switchInputProps}
        field={field}
        value={formikProps.values[field]}
        errorText={displayFormFieldError(field, formikProps)}
        onSelect={onSelect || formikProps.setFieldValue}
      />
    );
  }
}
