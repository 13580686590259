import { connect } from 'react-redux';
import { deleteObatResourceSagaAction } from 'redux/obatResources/actions';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { ICalendar } from 'types/Obat/Calendar';
import { IDayGroup } from 'types/Obat/DayGroup';
import { rrc } from 'utils/strings/resourceClasses';

import { DayGroupForm as DayGroupFormComponent } from './DayGroupForm.component';

interface IOwnProps {
  calendar: ICalendar;
  close?: () => void;
  dayGroup?: IDayGroup;
  isInitiallyCollapsed?: boolean;
  titleIndex?: number;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IInjectedProps, IOwnProps {}

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return {
    dayGroupToEdit: ownProps.dayGroup,
    routeObat: selectRouteResource(rrc.obat)(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
  };
};

const mapDispatchToProps = {
  deleteObatResource: deleteObatResourceSagaAction,
};

export type IInjectedProps = IStateProps & IDispatchProps;

export const DayGroupForm = connect(mapStateToProps, mapDispatchToProps)(DayGroupFormComponent);
