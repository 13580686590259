import { connect } from 'react-redux';
import { openObatResourceCommitmentsModalAction } from 'redux/modals/obatResourceCommitmentsModal/actions';
import { IObatResource } from 'types/Obat/ObatResource';

import { ObatResourceCommitmentsTooltip as Component } from './ObatResourceCommitmentsTooltip.component';

interface IOwnProps {
  obatResource: IObatResource;
  editionField?: string;
  placement: 'left' | 'right' | 'bottom';
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  openCommitmentsModal: openObatResourceCommitmentsModalAction,
};

export const ObatResourceCommitmentsTooltip = connect(mapStateToProps, mapDispatchToProps)(Component);
