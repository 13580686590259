import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import {
  exportEplusOutputsSagaAction,
  refreshSimulationGroupSagaAction,
  refreshSimulationSagaAction,
  runSimulationSagaAction,
  toggleLogsScrollAction,
} from 'redux/restResources/detail/simulationGroup/actions';
import { fetchSimulationGroupConfigOptionsSagaAction } from 'redux/restResources/detail/simulationGroup/actions';
import { selectIsLogsScrollEnabled, selectSimulation } from 'redux/restResources/detail/simulationGroup/selectors';
import { selectIsSeatMissing, selectIsWritePermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { MonitorMono as Component } from './MonitorMono.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeMonoSimulationGroup = selectRouteResource(rrc.mono_simulation_group)(rootState);
  const simulation = selectSimulation(rootState);

  return {
    routeMonoSimulationGroup,
    simulation,
    isLogsScrollEnabled: selectIsLogsScrollEnabled(rootState),
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
  };
};

const mapDispatchToProps = {
  fetchSimulationGroupConfigOptions: fetchSimulationGroupConfigOptionsSagaAction,
  runSimulation: runSimulationSagaAction,
  refreshSimulation: refreshSimulationSagaAction,
  refreshSimulationGroup: refreshSimulationGroupSagaAction,
  exportEplusOutputs: exportEplusOutputsSagaAction,
  toggleLogsScrollEnabled: toggleLogsScrollAction,
};

export const MonitorMono = connect(mapStateToProps, mapDispatchToProps)(Component);
