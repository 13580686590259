import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from './reducer';
import { rootSaga } from './sagas';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'tokens', // after refreshing you are still logged in
    'tasks', // after refreshing you keep on tracking a task status if it was in progress
    'variantContext', //  after refreshing you're still in variant context if you were before.
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const enhancers = [applyMiddleware(sagaMiddleware)];

export const store = reduxCreateStore(persistedReducer, composeEnhancers(...enhancers));
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
