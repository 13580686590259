import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import React, { PureComponent, ReactNode } from 'react';
import { getObatMenuPageRoute, obatMenuPages, projectMenuPages } from 'routing/routes';
import { IObatListed } from 'types/Obat/Obat';
import { IRestResource } from 'types/RestResource';
import { formatISODateToDstDatetime } from 'utils/functions/datetimes/formatISODate';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { rrc } from 'utils/strings/resourceClasses';

import { rm } from 'utils/strings/routingMenus';
import { ObatForm } from '../ObatForm';
import { IProps } from './ObatsList.container';

export class ObatsList extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeProject } = this.props;

    return (
      <RestResourcesListPage
        routingMenu={rm.project}
        routeParentId={routeProject.id}
        selectedPage={projectMenuPages.obats}
        FormComponent={ObatForm}
        getCreationSuccessLandingPageUrl={this.getCreationSuccessLandingPageUrl}
        resourceClass={rrc.obat}
        parentResourceClass={rrc.project}
        columnsParams={columnsParams}
        availableActions={['edit', 'copy', 'delete']}
      />
    );
  }

  private getCreationSuccessLandingPageUrl = (routeProjectId: string, createdResource: IRestResource) => {
    return getObatMenuPageRoute(obatMenuPages.default, routeProjectId, createdResource.id);
  };
}

const columnsParams: ITableColumnParams[] = [
  {
    id: 'name',
    resourceField: 'name',
    getNavigationUrl: (obat: IObatListed): string => {
      return getObatMenuPageRoute(obatMenuPages.default, obat.project.id, obat.id);
    },
  },
  {
    id: 'lastModification',
    resourceField: 'last_modification',
    renderCustomText: (obat: IObatListed) => formatISODateToDstDatetime(obat.last_modification),
  },
];
