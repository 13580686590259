import { IPermission } from 'types/Permissions';

export const getProjectOrganizationPermission = (
  bitsCanAdmin: number,
  bitsCanWrite: number,
  bitsCanRead: number
): IPermission => {
  return {
    can_admin: bitsCanAdmin >= 2,
    can_write: bitsCanWrite >= 2,
    can_read: bitsCanRead >= 2,
  };
};
