import { Grid } from '@material-ui/core';
import { DropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput';
import { OplusLoader } from 'components/Loader';
import { _map } from 'libs/lodash';
import { _range } from 'libs/lodash';
import { _toString } from 'libs/lodash';
import { DegreeDaysPlot } from 'pages/WeatherMenu/Data/DegreeDays/DegreeDaysPlot';
import { DegreeDaysTable } from 'pages/WeatherMenu/Data/DegreeDays/DegreeDaysTable';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { ps } from 'utils/strings/progressStatus';

import { IProps } from './DegreeDays.container';

export class DegreeDays extends PureComponent<IProps> {
  public componentDidMount(): void {
    const { routeWeather, fetchDegreeDaysYearData } = this.props;
    if (!routeWeather.empty) {
      fetchDegreeDaysYearData();
    }
  }

  public render(): ReactNode {
    const {
      degreeDaysData,
      degreeDaysTableData,
      isFetchingDegreeDaysData,
      params,
      routeWeather,
      yearOptions,
    } = this.props;

    return (
      <FormAndPlotContainer>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            {!routeWeather.generic_weather_series && params.year && (
              <DropdownInput
                field={'year'}
                label={$t('year')}
                value={params.year}
                options={yearOptions || []}
                onSelect={this.onYearSelect}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <DropdownInput
              field={'heating_base'}
              label={$t('baseHeating')}
              value={params.heatingBase}
              options={_map(_range(16, 27), _toString)}
              onSelect={this.onHeatingBaseSelect}
            />
          </Grid>
          <Grid item xs={2}>
            <DropdownInput
              field={'cooling_base'}
              label={$t('baseCooling')}
              value={params.coolingBase}
              options={_map(_range(16, 27), _toString)}
              onSelect={this.onCoolingBaseSelect}
            />
          </Grid>
        </Grid>
        {isFetchingDegreeDaysData && (
          <LoaderContainer>
            <OplusLoader progress={ps.fetchingData} />
          </LoaderContainer>
        )}
        {!isFetchingDegreeDaysData && degreeDaysTableData && degreeDaysData && (
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <DegreeDaysTable tableData={degreeDaysTableData} isGeneric={!!routeWeather.generic_weather_series} />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <PlotContainer>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <DegreeDaysPlot
                          title={$t('highDegreeDays')}
                          months={degreeDaysData.months}
                          degreeDays={degreeDaysData.hdd}
                          color={theme.colors.plot.default}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <DegreeDaysPlot
                          title={$t('coolingDegreeDays')}
                          months={degreeDaysData.months}
                          degreeDays={degreeDaysData.cdd}
                          color={theme.colors.plot.default}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </PlotContainer>
            </Grid>
          </Grid>
        )}
      </FormAndPlotContainer>
    );
  }

  private onHeatingBaseSelect = (_field: string, selectedBase: string): void => {
    const { fetchDegreeDaysYearData, params } = this.props;
    fetchDegreeDaysYearData({ ...params, heatingBase: selectedBase });
  };

  private onCoolingBaseSelect = (_field: string, selectedBase: string): void => {
    const { fetchDegreeDaysYearData, params } = this.props;
    fetchDegreeDaysYearData({ ...params, coolingBase: selectedBase });
  };

  private onYearSelect = (_field: string, selectedYear: string): void => {
    const { fetchDegreeDaysYearData, params } = this.props;
    fetchDegreeDaysYearData({ ...params, year: selectedYear });
  };
}

const PlotContainer = styled.div`
  margin-top: -22px;
`;

const LoaderContainer = styled.div`
  margin-top: 32px;
`;

const FormAndPlotContainer = styled.div`
  margin-top: 12px;
`;
