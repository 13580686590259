import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { rrc } from 'utils/strings/resourceClasses';

import { OrganizationPermissionsForm as OrganizationPermissionsFormComponent } from './OrganizationPermissionsForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    permissionToEdit: selectFormInitialResourceFromTable(rrc.user_organization_permission)(rootState),
  };
};

const mapDispatchToProps = {};

export const OrganizationPermissionsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationPermissionsFormComponent);
