import { RestResourcesRequests } from 'api/requests/RestResourcesRequests';
import { _keys } from 'libs/lodash';
import { getProjectionColumnsParams } from 'pages/ObatMenu/Projection/ProjectionTable/ProjectionTable.component';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiRequestSaga } from 'redux/apiRequests/sagas';
import { selectApiRequestErrors } from 'redux/apiRequests/selectors';
import { uploadFileToAzureSaga } from 'redux/azure/sagas';
import { closeFormDrawerAction } from 'redux/form/actions';
import { selectProjectionParams, selectProjectionSection } from 'redux/restResources/detail/obat/selectors';
import { listRestResourcesSaga, retrieveRestResourceSaga } from 'redux/restResources/generic/sagas';
import { fetchAllRouteResourcesSaga } from 'redux/routing/sagas';
import { selectIsRefreshingRoutePage, selectRouteResource } from 'redux/routing/selectors';
import { initializeTableAction } from 'redux/table/actions';
import { fetchTableUpdatedDataSaga } from 'redux/table/sagas';
import { selectTableResourceClass } from 'redux/table/selectors';
import { clearTaskAction, updateTaskAction } from 'redux/tasks/actions';
import { refreshTaskSaga } from 'redux/tasks/sagas';
import { selectTask } from 'redux/tasks/selectors';
import { selectVariantContextId } from 'redux/variantContext/selectors';
import { obatMenuPages } from 'routing/routes';
import { downloadFromAzureBlob } from 'utils/functions/azure/dowloadFromAzureBlob';
import { downloadFileFromUrl } from 'utils/functions/downloadFile/downloadFileFromUrl';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { getExportFileName } from 'utils/functions/getExportFileName';
import { getTaskKey } from 'utils/functions/getTaskKey';
import { pickColumnFilterParams } from 'utils/functions/pickColumnParams';
import { dr } from 'utils/strings/detailRoutes';
import { hm } from 'utils/strings/httpMethods';
import { ps } from 'utils/strings/progressStatus';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import {
  clearProjectionDataAction,
  EXPORT_GBXML_FILE_SAGA_ACTION,
  EXPORT_OBAT_FILE_SAGA_ACTION,
  FETCH_OBAT_GEOMETRIES_SAGA_ACTION,
  FETCH_OBATS_TEMPLATES_SAGA_ACTION,
  FETCH_PROJECTION_DATA_SAGA_ACTION,
  IExportGbxmlFileSagaAction,
  IFetchProjectionDataSagaAction,
  IImportObatSagaAction,
  IMPORT_OBAT_SAGA_ACTION,
  REFRESH_IMPORT_OBAT_TASK_SAGA_ACTION,
  toggleProjectionTaskRunningStatusAction,
  updateGeometriesForProjectionAction,
  updateObatImportProgressAction,
  updateObatsTemplatesAction,
  updateProjectionDataAction,
  updateProjectionParamsAction,
} from './actions';

export function* importObatSaga(payload: IImportObatSagaAction['payload']): SagaIterator {
  /* 1. Upload File To Blob */
  yield put(updateObatImportProgressAction(ps.uploadingFile));

  const { fileContentBlob, importFields } = payload;
  const routeObat = yield select(selectRouteResource(rrc.obat));
  const uploadApiRequestKey = getDetailApiRequestKey(dra.uploadObatFile, routeObat.id);
  const uploadApiUrlParams = {
    resourceClass: rrc.obat,
    resourceId: routeObat.id,
    detailRoute: dr.upload_url,
  };

  const blobUrlCallResult = yield call(
    apiRequestSaga,
    uploadApiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    uploadApiUrlParams,
    hm.get
  );

  const uploadApiRequestErrors = yield select(selectApiRequestErrors(uploadApiRequestKey));
  if (!uploadApiRequestErrors) {
    const blobUrl = blobUrlCallResult.blob_url;
    yield call(uploadFileToAzureSaga, blobUrl, fileContentBlob);

    /* 2. Import ObatResources from Blob to Resource */
    yield put(updateObatImportProgressAction(ps.importingObat));

    const importApiRequestKey = getDetailApiRequestKey(dra.importObat, routeObat.id);
    const importApiUrlParams = {
      resourceClass: rrc.obat,
      resourceId: routeObat.id,
      detailRoute: dr.import_data,
    };

    const callResult = yield call(
      apiRequestSaga,
      importApiRequestKey,
      false,
      RestResourcesRequests.detailRoute,
      importApiUrlParams,
      hm.patch,
      {},
      importFields
    );

    const importApiRequestErrors = yield select(selectApiRequestErrors(importApiRequestKey));
    if (!importApiRequestErrors) {
      yield put(closeFormDrawerAction());
      const taskId = callResult.user_task;
      const taskKey = getTaskKey(dra.importObat, routeObat.id);
      const task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
      yield put(updateTaskAction(task, taskKey));
      yield call(refreshImportObatTaskSaga);
    }
  }

  yield put(updateObatImportProgressAction(ps.finished));
}

export function* importObatActionSaga(action: IImportObatSagaAction): SagaIterator {
  yield call(importObatSaga, action.payload);
}

export function* refreshImportObatTaskSaga(): SagaIterator {
  const routeObat = yield select(selectRouteResource(rrc.obat));
  const taskKey = getTaskKey(dra.importObat, routeObat.id);
  const task = yield select(selectTask(taskKey));

  if (task) {
    yield put(updateObatImportProgressAction(ps.importingObat));
    const finishedTask = yield call(refreshTaskSaga, {
      taskKey,
      routeResourceToUpdateClass: rrc.obat,
      pagesWhereToRefresh: _keys(obatMenuPages),
    });
    if (finishedTask && finishedTask.status_code === 200) {
      yield put(updateObatImportProgressAction(ps.fetchingData));
      const currentResourceClass = yield select(selectTableResourceClass);
      yield call(fetchTableUpdatedDataSaga, { resourceClass: currentResourceClass });
    }
  }

  yield put(clearTaskAction(taskKey));
  yield put(updateObatImportProgressAction(ps.finished));
}

export function* refreshImportObatTaskActionSaga(): SagaIterator {
  yield call(refreshImportObatTaskSaga);
}

export function* exportObatFileSaga(): SagaIterator {
  const routeObat = yield select(selectRouteResource(rrc.obat));
  const apiRequestKey = getDetailApiRequestKey(dra.exportObat, routeObat.id);
  const apiUrlParams = {
    resourceClass: rrc.obat,
    resourceId: routeObat.id,
    detailRoute: dr.export_data,
  };

  const callResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get,
    { export_format: 'xlsx', file_name: getExportFileName(routeObat.name, 'xlsx') }
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const taskId = callResult.user_task;
    const taskKey = getTaskKey(dra.exportObat, routeObat.id);
    const task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
    yield put(updateTaskAction(task, taskKey));
    const finishedTask = yield call(refreshTaskSaga, {
      taskKey,
      routeResourceToUpdateClass: rrc.obat,
    });
    if (finishedTask && finishedTask.status_code === 200) {
      const fileUrl = finishedTask.data.blob_url;
      yield call(downloadFileFromUrl, fileUrl);
    }
  }
}

export function* exportObatFileActionSaga(): SagaIterator {
  yield call(exportObatFileSaga);
}

export function* exportGbxmlFileSaga(payload: IExportGbxmlFileSagaAction['payload']): SagaIterator {
  const { exportParams } = payload;

  const routeObat = yield select(selectRouteResource(rrc.obat));
  const apiRequestKey = getDetailApiRequestKey(dra.exportGbxml, routeObat.id);
  const apiUrlParams = {
    resourceClass: rrc.obat,
    resourceId: routeObat.id,
    detailRoute: dr.generate_gbxml,
  };

  const variantContextId = yield select(selectVariantContextId);
  const queryParams = variantContextId ? { ...exportParams, variant: variantContextId } : exportParams;

  const generateTaskCallResponse = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get,
    { ...queryParams, file_name: getExportFileName(routeObat.name, 'gbxml') }
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const taskId = generateTaskCallResponse.user_task;
    const taskKey = getTaskKey(dra.exportGbxml, routeObat.id);
    const task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
    yield put(updateTaskAction(task, taskKey));
    const finishedTask = yield call(refreshTaskSaga, {
      taskKey,
      routeResourceToUpdateClass: rrc.obat,
    });
    if (finishedTask && finishedTask.status_code === 200) {
      const fileUrl = finishedTask.data.blob_url;
      yield call(downloadFileFromUrl, fileUrl);
    }
  }
}

export function* exportGbxmlFileActionSaga(action: IExportGbxmlFileSagaAction): SagaIterator {
  yield call(exportGbxmlFileSaga, action.payload);
}

export function* fetchProjectionDataSaga(payload: IFetchProjectionDataSagaAction['payload']): SagaIterator {
  const { projectionParams } = payload;
  yield put(clearProjectionDataAction());
  yield put(toggleProjectionTaskRunningStatusAction(true));

  const routeObat = yield select(selectRouteResource(rrc.obat));
  const apiRequestKey = getDetailApiRequestKey(dra.projection, routeObat.id);
  const apiUrlParams = {
    resourceClass: rrc.obat,
    resourceId: routeObat.id,
    detailRoute: dr.projection,
  };

  const variantContextId = yield select(selectVariantContextId);
  const queryParams = variantContextId ? { ...projectionParams, variant: variantContextId } : projectionParams;

  const projection = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get,
    queryParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const taskId = projection.user_task;
    const taskKey = getTaskKey(dra.projection, routeObat.id);
    const task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
    yield put(updateTaskAction(task, taskKey));
    const finishedTask = yield call(refreshTaskSaga, {
      taskKey,
      routeResourceToUpdateClass: rrc.obat,
      pagesWhereToRefresh: [obatMenuPages.projection],
      noWarnings: true,
    });
    if (finishedTask && finishedTask.status_code === 200) {
      const tableBlobUrl = finishedTask.data[`table_blob_url`];
      const threejsBlobUrl = finishedTask.data[`threejs_blob_url`];

      const tableData = yield call(downloadFromAzureBlob, tableBlobUrl);
      const threejsData = yield call(downloadFromAzureBlob, threejsBlobUrl);
      const data = {
        table: tableData,
        threejs: threejsData,
      };

      if (!!tableData && !!threejsData) {
        const previousParams = yield select(selectProjectionParams);
        const previousGeometry = previousParams.geometry;
        if (previousGeometry !== projectionParams.geometry) {
          yield put(clearProjectionDataAction());
        }
        yield put(updateProjectionParamsAction(projectionParams));
        yield put(updateProjectionDataAction(data));

        /* initialize filter data after clicking onSubmit */
        const currentSection = yield select(selectProjectionSection);
        const sectionData = data.table[currentSection];
        const sectionColumnsParams = getProjectionColumnsParams(currentSection);
        const sectionFilterColumnsParams = pickColumnFilterParams(sectionColumnsParams);
        yield put(initializeTableAction(sectionData, sectionFilterColumnsParams));

        /* Special Case: If the user is reloading page (customPageRefresh), routeResource should also be refreshed */
        const isRefreshingRoutePage = yield select(selectIsRefreshingRoutePage);
        if (isRefreshingRoutePage) {
          yield call(fetchAllRouteResourcesSaga, { forceUpdate: true });
        }

        /* Once data are ready, display table */

        yield put(toggleProjectionTaskRunningStatusAction(false));
        yield put(closeFormDrawerAction());
      }
    }
  }
}

export function* fetchProjectionDataActionSaga(action: IFetchProjectionDataSagaAction): SagaIterator {
  yield call(fetchProjectionDataSaga, action.payload);
}

export function* fetchObatGeometriesSaga(): SagaIterator {
  const routeProject = yield select(selectRouteResource(rrc.project));
  const queryParams = { project: routeProject.id, empty: false };

  const geometries = yield call(listRestResourcesSaga, rrc.geometry, undefined, queryParams, false);

  yield put(updateGeometriesForProjectionAction(geometries));
}

export function* fetchObatGeometriesActionSaga(): SagaIterator {
  yield call(fetchObatGeometriesSaga);
}

export function* fetchObatsTemplatesSaga(): SagaIterator {
  /* Download Threejs Json in the store (not in browser) */

  const apiRequestKey = getDetailApiRequestKey(dra.fetchTemplates, 'obats');
  const apiUrlParams = {
    resourceClass: rrc.obat,
    detailRoute: dr.templates,
  };

  const templates = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  yield put(updateObatsTemplatesAction(templates));
}

export function* fetchObatsTemplatesActionSaga(): SagaIterator {
  yield call(fetchObatsTemplatesSaga);
}

export function* obatActionsSagas(): Generator {
  yield takeLatest(IMPORT_OBAT_SAGA_ACTION, importObatActionSaga);
  yield takeLatest(REFRESH_IMPORT_OBAT_TASK_SAGA_ACTION, refreshImportObatTaskActionSaga);
  yield takeEvery(EXPORT_OBAT_FILE_SAGA_ACTION, exportObatFileActionSaga); /* Each */
  yield takeEvery(EXPORT_GBXML_FILE_SAGA_ACTION, exportGbxmlFileActionSaga); /* Each */
  yield takeLatest(FETCH_PROJECTION_DATA_SAGA_ACTION, fetchProjectionDataActionSaga);
  yield takeLatest(FETCH_OBAT_GEOMETRIES_SAGA_ACTION, fetchObatGeometriesActionSaga);
  yield takeLatest(FETCH_OBATS_TEMPLATES_SAGA_ACTION, fetchObatsTemplatesActionSaga);
}
