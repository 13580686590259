import { RestResourcesRequests } from 'api/requests/RestResourcesRequests';
import { _difference } from 'libs/lodash';
import { _get } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { _sortBy } from 'libs/lodash';
import { _toString } from 'libs/lodash';
import { _uniq } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequestCallbackSuccessAction } from 'redux/apiRequests/actions';
import { apiRequestSaga } from 'redux/apiRequests/sagas';
import { selectApiRequestErrors } from 'redux/apiRequests/selectors';
import {
  downloadFromAzureBlobSaga,
  downloadMetadataFromAzureSaga,
  downloadSeriesYearDataFromAzureSaga,
} from 'redux/azure/sagas';
import { openAlertModalAction } from 'redux/modals/alertModal/actions';
import { selectIsLogsModalOpen } from 'redux/modals/logsModal/selectors';
import { listObatResourcesSaga } from 'redux/obatResources/sagas';
import {
  selectHourlyResultsMetadata,
  selectHourlyResultsYearlyData,
  selectMonthlyResultsMode,
  selectMonthlyResultsSection,
  selectMonthlyResultsSectionData,
  selectMonthlyResultsSectionDataKey,
  selectSimulation,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { retrieveRestResourceSaga } from 'redux/restResources/generic/sagas';
import { fetchSingleRouteResourceSaga } from 'redux/routing/sagas';
import {
  selectRoutePage,
  selectRouteResource,
  selectRouteSimulationGroup,
  selectRouteSimulationGroupTag,
} from 'redux/routing/selectors';
import { selectTableResources } from 'redux/table/selectors';
import { navigateRoute } from 'routing/navigateRoute';
import {
  getMonoSimulationGroupMenuPageRoute,
  getMultiSimulationGroupMenuPageRoute,
  monoSimulationGroupMenuPages,
  multiSimulationGroupMenuPages,
} from 'routing/routes';
import { IVariant } from 'types/Obat/Variant';
import { ISimulationGroupConfigOptions } from 'types/SimulationGroup/SimulationGroup';
import { downloadFileFromUrl } from 'utils/functions/downloadFile/downloadFileFromUrl';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { IRestApiUrlParams } from 'utils/functions/getApiUrl/getRestApiUrl/getRestApiUrl';
import { removeCsvLastEmptyLine } from 'utils/functions/removeCsvLastEmptyLine';
import { getHourlyResultsPlot } from 'utils/functions/simulationGroup/getHourlyResultsPlot';
import { getHourlyResultsPlotSeries } from 'utils/functions/simulationGroup/getHourlyResultsPlotSeries';
import { getUpdatedHourlyResultsYearlyData } from 'utils/functions/simulationGroup/getUpdatedHourlyResultsYearData';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { dr } from 'utils/strings/detailRoutes';
import { hm } from 'utils/strings/httpMethods';
import { dra } from 'utils/strings/requestActions';
import { orc } from 'utils/strings/resourceClasses';
import { rrc } from 'utils/strings/resourceClasses';
import { rv } from 'utils/strings/results';
import { selectHourlyResultsPlot } from './selectors';

import {
  DOWNLOAD_HOURLY_RESULTS_DATA_SAGA_ACTION,
  DOWNLOAD_MONTHLY_RESULTS_SECTION_DATA_SAGA_ACTION,
  DOWNLOAD_SIMULATION_METADATA_SAGA_ACTION,
  EXPORT_EPLUS_OUTPUTS_SAGA_ACTION,
  EXPORT_HOURLY_CSV_SAGA_ACTION,
  FETCH_OBAT_VARIANTS_SAGA_ACTION,
  FETCH_SIMULATION_GROUP_CONFIG_OPTIONS_SAGA_ACTION,
  IDownloadHourlyResultsDataSagaAction,
  IDownloadSimulationMetadataSagaAction,
  IExportEplusOutputsSagaAction,
  IExportHourlyCsvSagaAction,
  IFetchObatVariantsSagaAction,
  IRefreshSimulationGroupSagaAction,
  IRefreshSimulationSagaAction,
  REFRESH_SIMULATION_GROUP_SAGA_ACTION,
  REFRESH_SIMULATION_SAGA_ACTION,
  RUN_SIMULATION_SAGA_ACTION,
  toggleIsDownloadingHourlyResultsDataAction,
  toggleIsSimulationInProgressAction,
  toggleMonthlyResultsForcePivotUpdateAction,
  updateHourlyResultsDataAction,
  updateHourlyResultsMetadataAction,
  updateMonthlyResultsDataAction,
  updateSimulationGroupConfigOptionsAction,
  updateVariantOptionsByObatAction,
} from './actions';

export function* fetchSimulationGroupConfigOptionsSaga(): SagaIterator {
  const routeSimulationGroupTag = yield select(selectRouteSimulationGroupTag);
  const apiRequestKey = getDetailApiRequestKey(
    dra.fetchSimulationGroupConfigOptions,
    routeSimulationGroupTag.resourceId
  );
  const apiUrlParams = {
    ...routeSimulationGroupTag,
    detailRoute: dr.config_options,
  };

  const configOptions: ISimulationGroupConfigOptions = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    yield put(updateSimulationGroupConfigOptionsAction(configOptions));
  }
}

export function* fetchSimulationConfigOptionsActionSaga(): SagaIterator {
  yield call(fetchSimulationGroupConfigOptionsSaga);
}

export function* fetchObatVariantsSaga(payload: IFetchObatVariantsSagaAction['payload']): SagaIterator {
  const { obatId } = payload;
  const obatVariants: IVariant[] = yield call(listObatResourcesSaga, orc.variant, obatId);
  const obatVariantOptions = _map(obatVariants, 'ref');
  yield put(updateVariantOptionsByObatAction(obatId, obatVariantOptions));
}

export function* fetchObatVariantsActionSaga(action: IFetchObatVariantsSagaAction): SagaIterator {
  yield call(fetchObatVariantsSaga, action.payload);
}

export function* runSimulationSaga(): SagaIterator {
  const routeSimulationGroupTag = yield select(selectRouteSimulationGroupTag);
  const apiRequestKey = getDetailApiRequestKey(dra.runSimulation, routeSimulationGroupTag.resourceId);

  yield put(toggleIsSimulationInProgressAction(true));

  /* redirection if needed */
  const page = yield select(selectRoutePage);
  const routeProject = yield select(selectRouteResource(rrc.project));

  /* start simulation */
  const apiUrlParams = {
    ...routeSimulationGroupTag,
    detailRoute: dr.run,
  };

  yield call(apiRequestSaga, apiRequestKey, false, RestResourcesRequests.detailRoute, apiUrlParams, hm.post);

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    if (!_includes(page, 'monitor')) {
      /* refresh is managed via Monitor component */
      const monitorRoute =
        routeSimulationGroupTag.resourceClass === rrc.multi_simulation_group
          ? getMultiSimulationGroupMenuPageRoute(
              multiSimulationGroupMenuPages.monitorMulti,
              routeProject.id,
              routeSimulationGroupTag.resourceId
            )
          : getMonoSimulationGroupMenuPageRoute(
              monoSimulationGroupMenuPages.monitorMono,
              routeProject.id,
              routeSimulationGroupTag.resourceId
            );

      yield call(navigateRoute, monitorRoute);
    } else {
      /* if Monitor page already, refresh */
      yield call(refreshSimulationGroupSaga, { resourceClass: routeSimulationGroupTag.resourceClass });
    }
  } else {
    const detailedCode = _get(apiRequestErrors, 'GLOBAL[0].detailed_code');
    if (detailedCode === 'MaxParallelSimulationNumberReached') {
      const routeSimulationGroup = yield select(selectRouteSimulationGroup);
      yield put(
        openAlertModalAction(
          $t('maxParallelSimulationNumberReachedAlert', { max: routeSimulationGroup.project.max_parallel_simulations }),
          as.warning
        )
      );
    }
  }
}

export function* runSimulationActionSaga(): SagaIterator {
  yield call(runSimulationSaga);
}

export function* refreshSimulationGroupSaga(payload: IRefreshSimulationGroupSagaAction['payload']): SagaIterator {
  const { resourceClass } = payload;

  /* refresh at least one time */
  yield call(fetchSingleRouteResourceSaga, resourceClass);

  let routeSimulationGroup = yield select(selectRouteResource(resourceClass));
  let urlPage = yield select(selectRoutePage);

  if (routeSimulationGroup && routeSimulationGroup.status === as.running) {
    yield put(toggleIsSimulationInProgressAction(true));
  }

  /* start repeated retrieve */
  while (
    routeSimulationGroup &&
    routeSimulationGroup.status === as.running &&
    _includes([..._keys(monoSimulationGroupMenuPages), ..._keys(multiSimulationGroupMenuPages)], urlPage)
  ) {
    yield delay(3000);

    yield call(fetchSingleRouteResourceSaga, resourceClass);
    routeSimulationGroup = yield select(selectRouteResource(resourceClass));
    urlPage = yield select(selectRoutePage);
  }

  /* if the user leave the page to go elsewhere from routeSimulationGroupLevel, routeSimulationGroup gets undefined */
  if (!routeSimulationGroup || routeSimulationGroup.status !== as.running) {
    yield put(toggleIsSimulationInProgressAction(false));

    /* update project in case of user is leaving seat */
    yield call(fetchSingleRouteResourceSaga, rrc.project);
  }
}

export function* refreshSimulationGroupActionSaga(action: IRefreshSimulationGroupSagaAction): SagaIterator {
  yield call(refreshSimulationGroupSaga, action.payload);
}

/* _____ MULTI SIMULATION GROUP _____*/

/* _____ SIMULATION _____ */

export function* refreshSimulationSaga(payload: IRefreshSimulationSagaAction['payload']): SagaIterator {
  const { simulationId } = payload;

  /* retrieve simulation */
  let simulation = yield call(retrieveRestResourceSaga, rrc.simulation, simulationId);
  /* if the simulation is running we want to refresh it only if we are on the Simulate page */
  if (simulation && simulation.status === as.running) {
    let urlPage = yield select(selectRoutePage);
    let isLogsModalOpen = yield select(selectIsLogsModalOpen);

    while (
      simulation &&
      simulation.status === as.running &&
      (urlPage === monoSimulationGroupMenuPages.monitorMono || isLogsModalOpen)
    ) {
      yield delay(1000);

      simulation = yield call(retrieveRestResourceSaga, rrc.simulation, simulationId);
      urlPage = yield select(selectRoutePage);
      isLogsModalOpen = yield select(selectIsLogsModalOpen);
    }
  }
}

export function* refreshSimulationActionSaga(action: IRefreshSimulationSagaAction): SagaIterator {
  yield call(refreshSimulationSaga, action.payload);
}

function* exportEplusOutputsSaga(payload: IExportEplusOutputsSagaAction['payload']): SagaIterator {
  const { simulation } = payload;
  const apiRequestKey = getDetailApiRequestKey(dra.exportEplusOutputs, simulation.id);
  const routeSimulationGroupTag = yield select(selectRouteSimulationGroupTag);
  const apiUrlParams = {
    resourceClass: rrc.simulation,
    resourceId: simulation.id,
    parentResourceTag: routeSimulationGroupTag,
    detailRoute: dr.eplus_output,
  };

  const blobUrlCallResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const fileUrl = blobUrlCallResult.blob_url;
    // let fileName;

    // if (routeSimulationGroupTag.resourceClass === rrc.mono_simulation_group) {
    //   const routeMonoSimulationGroup = yield select(selectRouteResource(rrc.mono_simulation_group));
    //   fileName = routeMonoSimulationGroup.name;
    // } else {
    //   fileName = simulation.name;
    // }
    // fileName = fileName + '.zip';
    yield call(downloadFileFromUrl, fileUrl);
  }
}

export function* exportEplusOutputsActionSaga(action: IExportEplusOutputsSagaAction): SagaIterator {
  yield call(exportEplusOutputsSaga, action.payload);
}

/* _____ MONTHLY RESULTS _____ */

export function* downloadMonthlyResultsSectionDataSaga(): SagaIterator {
  const resultsMode = yield select(selectMonthlyResultsMode);
  const simulation = yield select(selectSimulation);

  const monthlyResultsDataKey = yield select(selectMonthlyResultsSectionDataKey);
  const section = yield select(selectMonthlyResultsSection);
  const sectionData = yield select(selectMonthlyResultsSectionData);

  if (sectionData) {
    /* don't download again if results already in store */
    /* only force trigger pivot update */
    yield put(toggleMonthlyResultsForcePivotUpdateAction(true));

    return;
  }

  const routeSimulationGroupTag = yield select(selectRouteSimulationGroupTag);
  const apiRequestKey = getDetailApiRequestKey(dra.downloadMonthlyResultsData, monthlyResultsDataKey);
  const apiUrlParams =
    resultsMode === rv.bySimulation
      ? {
          resourceClass: rrc.simulation,
          resourceId: simulation.id,
          parentResourceTag: routeSimulationGroupTag,
          detailRoute: section,
        }
      : {
          resourceClass: rrc.multi_simulation_group,
          resourceId: routeSimulationGroupTag.resourceId,
          detailRoute: section,
        };

  const blobUrlCallResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const blobUrl = blobUrlCallResult.blob_url;
    const outputFormat = 'csv';
    const sectionResultsCsv = yield call(downloadFromAzureBlobSaga, blobUrl, outputFormat);
    if (sectionResultsCsv) {
      yield put(
        updateMonthlyResultsDataAction(monthlyResultsDataKey, section, removeCsvLastEmptyLine(sectionResultsCsv))
      );
      yield put(apiRequestCallbackSuccessAction(apiRequestKey));
    }
  }
}

export function* downloadMonthlyResultsSectionDataActionSaga(): SagaIterator {
  yield call(downloadMonthlyResultsSectionDataSaga);
}

/* _____ HOURLY RESULTS _____ */

export function* fetchSimulationContainerAccessSaga(simulationId: string): SagaIterator {
  const routeSimulationGroupTag = yield select(selectRouteSimulationGroupTag);
  const apiRequestKey = getDetailApiRequestKey(dra.fetchSimulation, simulationId);
  const apiUrlParams: IRestApiUrlParams = {
    resourceClass: rrc.simulation,
    resourceId: simulationId,
    parentResourceTag: routeSimulationGroupTag,
    detailRoute: dr.generic_viz,
  };

  const containerAccessCall = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    return {
      containerUrl: containerAccessCall.container_url,
      sasToken: containerAccessCall.sas_token,
    };
  }
}

export function* downloadSimulationMetaDataSaga(
  payload: IDownloadSimulationMetadataSagaAction['payload']
): SagaIterator {
  const { simulationId } = payload;

  yield put(toggleIsDownloadingHourlyResultsDataAction(true));
  let simulationMetadata;

  const currentMetadata = yield select(selectHourlyResultsMetadata);
  if (_has(currentMetadata, simulationId)) {
    simulationMetadata = _get(currentMetadata, simulationId);
    yield delay(
      200
    ); /* otherwise it's too fast and there is no animation while switching simulation in hourly results */
  } else {
    const containerAccess = yield call(fetchSimulationContainerAccessSaga, simulationId);

    const { containerUrl, sasToken } = containerAccess;
    simulationMetadata = yield call(downloadMetadataFromAzureSaga, containerUrl, sasToken);
    yield put(updateHourlyResultsMetadataAction(simulationId, simulationMetadata));
  }

  yield put(toggleIsDownloadingHourlyResultsDataAction(false));

  return simulationMetadata;
}

export function* downloadSimulationMetaDataActionSaga(action: IDownloadSimulationMetadataSagaAction): SagaIterator {
  yield call(downloadSimulationMetaDataSaga, action.payload);
}

export function* downloadHourlyResultsDataSaga(payload: IDownloadHourlyResultsDataSagaAction['payload']): SagaIterator {
  let { plotYear } = payload;

  yield put(toggleIsDownloadingHourlyResultsDataAction(true));

  const routeSimulationGroupTag = yield select(selectRouteSimulationGroupTag);

  const tableResources = (yield select(selectTableResources))(rrc.series, routeSimulationGroupTag.resourceId);
  const selectedResourcesList = tableResources.selected;
  const plotSeries = getHourlyResultsPlotSeries(selectedResourcesList);

  const plotSeriesSimulationsIds = _keys(plotSeries);
  const metadata = yield select(selectHourlyResultsMetadata);

  /* get all possible year options considering the requested series */
  const plotYearOptions = _reduce(
    plotSeriesSimulationsIds,
    (_options: string[], simulationId: string) => {
      const simulationYears = _map(metadata[simulationId].years, _toString);

      return _sortBy(_uniq([..._options, ...simulationYears]));
    },
    []
  );

  /* if no year precised for the request, get the first one */
  plotYear = plotYear || plotYearOptions[0];

  const currentYearlyData = yield select(selectHourlyResultsYearlyData);

  /* we only retrieve the "new" data of the series for which we don't have data yet in currentYearlyData */
  let plotYearNewData = {};

  for (const simulationId of plotSeriesSimulationsIds) {
    const simulationYears = _map(metadata[simulationId].years, _toString);

    if (!_includes(simulationYears, plotYear)) {
      /* some simulations have no data for the requested year */
      continue;
    }

    const simulationPlotSeriesIds = _map(plotSeries[simulationId], 'id');

    let seriesToFetchDataIds;

    if (_has(currentYearlyData, plotYear)) {
      /* We may already have fetched the data of some series for the requested year, so we eliminate them */
      const seriesWithDataAlreadyIds = _keys(currentYearlyData[plotYear][simulationId]);
      seriesToFetchDataIds = _difference(['index', ...simulationPlotSeriesIds], seriesWithDataAlreadyIds);
    } else {
      seriesToFetchDataIds = ['index', ...simulationPlotSeriesIds];
    }

    if (_size(seriesToFetchDataIds) > 0) {
      const containerAccess = yield call(fetchSimulationContainerAccessSaga, simulationId);
      const { containerUrl, sasToken } = containerAccess;

      for (const seriesId of seriesToFetchDataIds) {
        const seriesData = yield call(downloadSeriesYearDataFromAzureSaga, containerUrl, sasToken, seriesId, plotYear);

        plotYearNewData = {
          ...plotYearNewData,
          [simulationId]: _has(plotYearNewData, simulationId)
            ? {
                ..._get(plotYearNewData, simulationId),
                [seriesId]: seriesData,
              }
            : { [seriesId]: seriesData },
        };
      }
    }
  }

  const yearsData = getUpdatedHourlyResultsYearlyData(currentYearlyData, plotYear, plotYearNewData);
  const currentPlot = yield select(selectHourlyResultsPlot);

  const plot = getHourlyResultsPlot(plotSeries, plotYearOptions, plotYear, yearsData, currentPlot.operation);
  yield put(updateHourlyResultsDataAction(yearsData, plot));

  yield put(toggleIsDownloadingHourlyResultsDataAction(false));
}

export function* downloadHourlyResultsDataActionSaga(action: IDownloadHourlyResultsDataSagaAction): SagaIterator {
  yield call(downloadHourlyResultsDataSaga, action.payload);
}

function* exportHoulryCsvSaga(payload: IExportHourlyCsvSagaAction['payload']): SagaIterator {
  const { simulation } = payload;
  const apiRequestKey = getDetailApiRequestKey(dra.exportHourlyCsv, simulation.id);
  const routeSimulationGroupTag = yield select(selectRouteSimulationGroupTag);
  const apiUrlParams = {
    resourceClass: rrc.simulation,
    resourceId: simulation.id,
    parentResourceTag: routeSimulationGroupTag,
    detailRoute: dr.hourly_csv,
  };

  const blobUrlCallResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const fileUrl = blobUrlCallResult.blob_url;

    // let fileName;
    // if (routeSimulationGroupTag.resourceClass === rrc.mono_simulation_group) {
    //   const routeMonoSimulationGroup = yield select(selectRouteResource(rrc.mono_simulation_group));
    //   fileName = routeMonoSimulationGroup.name;
    // } else {
    //   fileName = simulation.name;
    // }
    // fileName = fileName + '.csv';
    yield call(downloadFileFromUrl, fileUrl);
  }
}

export function* exportHourlyCsvActionSaga(action: IExportHourlyCsvSagaAction): SagaIterator {
  yield call(exportHoulryCsvSaga, action.payload);
}

export function* simulationGroupActionsSagas(): Generator {
  yield takeLatest(FETCH_SIMULATION_GROUP_CONFIG_OPTIONS_SAGA_ACTION, fetchSimulationConfigOptionsActionSaga);
  yield takeLatest(FETCH_OBAT_VARIANTS_SAGA_ACTION, fetchObatVariantsActionSaga);
  yield takeLatest(RUN_SIMULATION_SAGA_ACTION, runSimulationActionSaga);
  yield takeLatest(REFRESH_SIMULATION_GROUP_SAGA_ACTION, refreshSimulationGroupActionSaga);
  yield takeLatest(REFRESH_SIMULATION_SAGA_ACTION, refreshSimulationActionSaga);
  /*** MONTHLY ***/
  yield takeLatest(DOWNLOAD_MONTHLY_RESULTS_SECTION_DATA_SAGA_ACTION, downloadMonthlyResultsSectionDataActionSaga);
  /*** HOURLY ***/
  yield takeLatest(DOWNLOAD_SIMULATION_METADATA_SAGA_ACTION, downloadSimulationMetaDataActionSaga);
  yield takeLatest(DOWNLOAD_HOURLY_RESULTS_DATA_SAGA_ACTION, downloadHourlyResultsDataActionSaga);
  yield takeLatest(EXPORT_EPLUS_OUTPUTS_SAGA_ACTION, exportEplusOutputsActionSaga);
  yield takeLatest(EXPORT_HOURLY_CSV_SAGA_ACTION, exportHourlyCsvActionSaga);
}
