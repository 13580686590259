import { connect } from 'react-redux';
import { closeNoFreeSeatsModalAction } from 'redux/modals/noFreeSeatsModal/actions';
import { selectIsNoFreeSeatsModalOpen } from 'redux/modals/noFreeSeatsModal/selectors';
import { IRootState } from 'redux/reducer';
import { selectRoutePage, selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { NoFreeSeatsModal as Component } from './NoFreeSeatsModal.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  const isOpen = selectIsNoFreeSeatsModalOpen(rootState);
  const routePage = selectRoutePage(rootState);

  const routeProject = selectRouteResource(rrc.project)(rootState);
  const routeOrganization = selectRouteResource(rrc.organization)(rootState);
  const isMember = routeOrganization ? routeOrganization.is_member : routeProject.is_member;

  return {
    isOpen,
    isMember,
    routePage,
    routeOrganization: routeOrganization || routeProject.organization,
  };
};

const mapDispatchToProps = {
  closeModal: closeNoFreeSeatsModalAction,
};

export const NoFreeSeatsModal = connect(mapStateToProps, mapDispatchToProps)(Component);
