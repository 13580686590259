// tslint:disable:no-any
import { WriteInput } from 'components/Forms/Inputs/TextInputs/WriteInput';
import { IWriteInputProps } from 'components/Forms/Inputs/TextInputs/WriteInput/WriteInput.component';
import React, { PureComponent, ReactNode } from 'react';
import { displayFormFieldError } from 'utils/functions/forms/displayFormFieldError/displayFormFieldError';

interface IProps extends Partial<IWriteInputProps> {
  field: string;
  formikProps: any;
}

export class FormikWriteInput extends PureComponent<IProps> {
  public render(): ReactNode {
    const { formikProps, field, onChange, onBlur, ...textInputProps } = this.props;

    return (
      // @ts-ignore
      <WriteInput
        {...textInputProps}
        field={field}
        errorText={displayFormFieldError(field, formikProps)}
        value={formikProps.values[field]}
        onChange={onChange || formikProps.handleChange}
        onBlur={onBlur || formikProps.handleBlur}
      />
    );
  }
}
