import Grid from '@material-ui/core/Grid';
import { FormButtons } from 'components/Forms/Buttons/FormButtons';
import { Formik, FormikProps } from 'formik';
import React, { Component, ReactNode } from 'react';

import { IProps } from './Form.container';

export class Form<IFormValues> extends Component<IProps<IFormValues>> {
  public componentDidUpdate(): void {
    const { hasToReset, toggleFormReset } = this.props;

    if (hasToReset) {
      toggleFormReset(false);
    }
  }

  public render(): ReactNode {
    const {
      getInitialFormValues,
      getValidationSchema,
      onSubmit,
      renderForm,
      renderSubForm,
      renderBottomButtons,
      formApiErrors,
    } = this.props;

    return (
      <Formik<IFormValues>
        key={this.props.hasToReset ? 'reset' : 'form'}
        validationSchema={getValidationSchema()}
        initialValues={getInitialFormValues()}
        // @ts-ignore
        initialErrors={formApiErrors && formApiErrors.fields}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(props: FormikProps<IFormValues>) => {
          return (
            <>
              <form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    {renderForm(props)}
                  </Grid>
                  <Grid item xs={12}>
                    {renderBottomButtons ? renderBottomButtons(props) : this.renderBottomButtons(props)}
                  </Grid>
                </Grid>
              </form>
              {!!renderSubForm && renderSubForm()}
            </>
          );
        }}
      </Formik>
    );
  }

  private renderBottomButtons = (formikProps: FormikProps<IFormValues>): ReactNode => {
    const {
      isSubmitting,
      formApiErrors,
      forceSubmitDisabled,
      obatResourceDeleteProps,
      forceSubmitEnabled,
    } = this.props;

    const bottomButtons = (
      <FormButtons
        isSubmitting={isSubmitting}
        formApiErrors={formApiErrors}
        formikProps={formikProps}
        obatResourceDeleteProps={obatResourceDeleteProps}
        forceSubmitDisabled={forceSubmitDisabled}
        forceSubmitEnabled={forceSubmitEnabled}
      />
    );

    return bottomButtons;
  };
}
