import {
  IVariantContextActions,
  UPDATE_VARIANT_CONTEXT_ERROR_ACTION,
  UPDATE_VARIANT_CONTEXT_ID_ACTION,
} from './actions';

export interface IVariantContextState {
  variantId?: string;
  error?: string;
}

export const initialState = {
  variantId: undefined,
  error: undefined,
};

export const variantContextReducer = (
  state: IVariantContextState = initialState,
  action: IVariantContextActions
): IVariantContextState => {
  switch (action.type) {
    case UPDATE_VARIANT_CONTEXT_ID_ACTION:
      return { ...state, variantId: action.payload.variantId };

    case UPDATE_VARIANT_CONTEXT_ERROR_ACTION:
      return { ...state, error: action.payload.error };

    default:
      return state;
  }
};
