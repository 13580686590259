import { Grid } from '@material-ui/core';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { ChangeEvent, createRef, PureComponent, ReactNode } from 'react';
import { IObatImportFields } from 'types/Obat/Obat';
import { fdw } from 'utils/configs/drawerWidths';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import * as yup from 'yup';

import { IProps } from './ObatImportForm.container';

export interface ISettingsFormValues {
  file_name: string;
}

const FormDrawer = getFormDrawer();
const Form = getForm<ISettingsFormValues>();

export class ObatImportForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();
  private chosenFile: File | null = null;

  public render(): ReactNode {
    const { isUploadingToAzure, importFormErrors } = this.props;

    return (
      <FormDrawer formKey={fk.import} width={fdw.medium} title={$t('importObat')} formFocusRef={this.formFocusRef}>
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          onSubmit={this.onSubmit}
          isSubmitting={isUploadingToAzure}
          forceSubmitDisabled={this.forceSubmitDisabled}
          formApiErrors={importFormErrors}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<ISettingsFormValues>): ReactNode => {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput
              field={'file_name'}
              formikProps={formikProps}
              type={'file'}
              noLabel
              onChange={this.onFileChange(formikProps)}
              inputRef={this.formFocusRef}
            />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): ISettingsFormValues => {
    return {
      file_name: '',
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<ISettingsFormValues> => {
    return yup.object().shape({
      file_name: getYupStringValidation(),
    }) as yup.ObjectSchema<ISettingsFormValues>;
  };

  private onSubmit = (_formValues: ISettingsFormValues): void => {
    if (this.chosenFile) {
      const importFields: IObatImportFields = { import_format: 'xlsx' };
      this.importObat(importFields);
    }
  };

  private importObat = (importFields: IObatImportFields): void => {
    const { importObat } = this.props;

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      // @ts-ignore
      const fileContentBlob = new Blob([fileReader.result], { type: 'application/octet-binary' });

      if (fileContentBlob) {
        importObat(importFields, fileContentBlob);
      }
    };
    if (this.chosenFile) {
      fileReader.readAsArrayBuffer(this.chosenFile);
    }

    return;
  };

  private onFileChange = (formikProps: FormikProps<ISettingsFormValues>) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      formikProps.setFieldValue('file_name', e.target.value);
      this.chosenFile = e.target.files ? e.target.files[0] : null;
    };
  };

  private forceSubmitDisabled = (): boolean => !this.chosenFile;
}
