import { IGeometryListed } from 'types/Geometry/Geometry';
import { IObatsTemplates } from 'types/Obat/Obat';
import { IProgressStatus, ps } from 'utils/strings/progressStatus';

import {
  CLEAR_PROJECTION_DATA_ACTION,
  CLEAR_PROJECTION_PARAMS_ACTION,
  IObatActions,
  IProjectionData,
  SWITCH_PROJECTION_RESULTS_VIEW,
  TOGGLE_PROJECTION_TASK_RUNNING_STATUS_ACTION,
  UPDATE_GEOMETRIES_FOR_PROJECTION_ACTION,
  UPDATE_OBAT_IMPORT_PROGRESS_ACTION,
  UPDATE_OBATS_TEMPLATES_ACTION,
  UPDATE_PROJECTION_DATA_ACTION,
  UPDATE_PROJECTION_PARAMS_ACTION,
  UPDATE_PROJECTION_TABLE_SECTION_ACTION,
} from './actions';

export type IProjectionSection = 'surfaces' | 'subsurfaces' | 'zones'; // | 'bridges';

export interface IProjectionParams {
  geometry: string;
  // with_bridges: boolean;
}

export interface IObatState {
  importProgress: IProgressStatus;
  geometries: IGeometryListed[];
  projection: {
    isTaskRunning: boolean;
    data: IProjectionData;
    section: IProjectionSection;
    params: IProjectionParams;
    resultsView: string;
  };
  templates?: IObatsTemplates;
}

export const initialState = {
  importProgress: ps.finished,
  geometries: [],
  projection: {
    isTaskRunning: false,
    data: {
      table: {},
      threejs: {},
    },
    section: 'surfaces' as IProjectionSection /* Typescript weird error */,
    params: {
      geometry: '',
      with_bridges: false,
    },
    resultsView: 'threejs',
  },
  templates: undefined,
};

export const obatReducer = (state: IObatState = initialState, action: IObatActions): IObatState => {
  switch (action.type) {
    case UPDATE_OBAT_IMPORT_PROGRESS_ACTION:
      return {
        ...state,
        importProgress: action.payload.progress,
      };

    case UPDATE_PROJECTION_DATA_ACTION:
      const { data } = action.payload;

      return {
        ...state,
        projection: {
          ...state.projection,
          data,
        },
      };

    case CLEAR_PROJECTION_DATA_ACTION:
      return {
        ...state,
        projection: {
          ...state.projection,
          data: initialState.projection.data,
        },
      };

    case UPDATE_GEOMETRIES_FOR_PROJECTION_ACTION:
      const { geometries } = action.payload;

      return {
        ...state,
        geometries,
      };

    case TOGGLE_PROJECTION_TASK_RUNNING_STATUS_ACTION:
      const { isTaskRunning } = action.payload;

      return {
        ...state,
        projection: {
          ...state.projection,
          isTaskRunning,
        },
      };

    case UPDATE_PROJECTION_TABLE_SECTION_ACTION:
      return {
        ...state,
        projection: {
          ...state.projection,
          section: action.payload.section,
        },
      };

    case UPDATE_PROJECTION_PARAMS_ACTION:
      return {
        ...state,
        projection: {
          ...state.projection,
          params: action.payload.params,
        },
      };

    case CLEAR_PROJECTION_PARAMS_ACTION:
      return {
        ...state,
        projection: {
          ...state.projection,
          params: initialState.projection.params,
        },
      };

    case SWITCH_PROJECTION_RESULTS_VIEW:
      return {
        ...state,
        projection: {
          ...state.projection,
          resultsView: action.payload.resultsView,
        },
      };

    case UPDATE_OBATS_TEMPLATES_ACTION:
      return {
        ...state,
        templates: action.payload.templates,
      };

    default:
      return state;
  }
};
