import { IRootState } from '../reducer';

export const selectIsUploadingToAzure = (rootState: IRootState): boolean => {
  return rootState.azure.isUploading;
};

export const selectIsDownloadingFromAzure = (rootState: IRootState): boolean => {
  return rootState.azure.isDownloading;
};

export const selectAzureError = (rootState: IRootState): string | undefined => {
  return rootState.azure.error;
};
