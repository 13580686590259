import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from 'components/Buttons';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';

interface IProps {
  onClose?: () => void;
  fullWidth?: boolean;
  isOpen?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  Title?: ReactNode;
  Content?: ReactNode;
  Actions?: ReactNode;
}

export class ModalFrame extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isOpen, fullWidth, maxWidth, Actions, Content, Title, onClose } = this.props;

    return (
      <Dialog fullWidth={!!fullWidth} maxWidth={maxWidth} open={!!isOpen} onClose={this.onCloseModal}>
        <DialogTitle>{Title && <TextContainer>{Title}</TextContainer>}</DialogTitle>
        {Content && <DialogContent className="no-scrollbar">{Content}</DialogContent>}
        {Actions && <DialogContent>{Actions}</DialogContent>}
        {!!onClose && (
          <CloseIconContainer>
            <Button
              onClick={this.onCloseModal}
              Icon={<CloseIcon fontSize={'small'} />}
              color={'gray'}
              bgColor={theme.colors.transparent}
              noShadow
              height={24}
            />
          </CloseIconContainer>
        )}
      </Dialog>
    );
  }

  private onCloseModal = (event: React.MouseEvent): void => {
    const { onClose } = this.props;

    event.stopPropagation();
    if (onClose) {
      onClose();
    }
  };
}

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;
