import { AlertIcon } from 'components/Icons';
import { EmailLink } from 'components/Links/EmailLink';
import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import { CreateAndInviteForm } from 'pages/OrganizationMenu/Permissions/CreateAndInviteForm';
import { InviteForm } from 'pages/OrganizationMenu/Permissions/InviteForm';
import { OrganizationPermissionsForm } from 'pages/OrganizationMenu/Permissions/OrganizationPermissionsForm';
import React, { PureComponent, ReactNode } from 'react';
import { organizationMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { IUserOrganizationPermission } from 'types/Permissions';
import { IWeatherListed } from 'types/Weather/Weather';
import { renderHighestPremissionValue } from 'utils/functions/permissions/renderHighestPermissionValue';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rrc } from 'utils/strings/resourceClasses';
import { rm } from 'utils/strings/routingMenus';

import { UserCreationModal } from '../UserCreationModal';
import { IProps } from './OrganizationPermissionsList.container';

export class OrganizationPermissionsList extends PureComponent<IProps> {
  public render(): ReactNode {
    const { openGuestForm, initialEmail, routeOrganization } = this.props;

    return (
      <>
        <RestResourcesListPage
          routingMenu={rm.organization}
          routeParentId={routeOrganization.id}
          selectedPage={organizationMenuPages.organizationPermissions}
          resourceClass={rrc.user_organization_permission}
          parentResourceClass={rrc.organization}
          columnsParams={columnsParams}
          availableActions={['edit', 'delete']}
          FormComponent={OrganizationPermissionsForm}
          onTopBarButtonClick={openGuestForm}
          createButtonText={$t('invite')}
          defaultSort={{ columnId: 'email', order: 'asc' }}
        />
        <InviteForm />
        <CreateAndInviteForm initialEmail={initialEmail} />
        <UserCreationModal />
      </>
    );
  }
}

const columnsParams: ITableColumnParams[] = [
  { id: 'firstName', resourceField: 'user.first_name' },
  { id: 'lastName', resourceField: 'user.last_name' },
  {
    id: 'email',
    resourceField: 'user.email',
    renderCustomDOM: (organizationPermission: IUserOrganizationPermission) => (
      <EmailLink email={organizationPermission.user.email} />
    ),
  },
  {
    id: 'status',
    resourceField: 'is_member',
    withFilterOptions: true,
    renderCustomText: (organizationPermission: IUserOrganizationPermission) =>
      organizationPermission.is_member ? $t('member') : $t('guest'),
  },
  {
    id: 'permission',
    title: $t('permissionFromOrganization'),
    withFilterOptions: true,
    renderCustomText: (organizationPermission: IUserOrganizationPermission): string => {
      const { is_member, can_read, can_write, can_admin } = organizationPermission;

      return renderHighestPremissionValue(can_admin, can_write, can_read, is_member);
    },
  },
  {
    id: 'dailySeats',
    resourceField: 'can_spend_daily_seats',
    withFilterOptions: true,
    renderCustomDOM: (organizationPermission: IUserOrganizationPermission): ReactNode => {
      return (
        <TableIcon>
          {organizationPermission.can_spend_daily_seats ? (
            <AlertIcon status={as.success} tooltipContent={$t('authorized')} />
          ) : (
            <AlertIcon status={as.failed} tooltipContent={$t('unauthorized')} />
          )}
        </TableIcon>
      );
    },
    getCustomDOMFilterComparisonValue: (weather: IWeatherListed): string =>
      weather.configured ? $t('authorized') : $t('unauthorized'),
  },
];

const TableIcon = styled.div`
  margin-bottom: -4px;
`;
