import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { _camelCase } from 'libs/lodash';
import { _get } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { AlertTriangle, Check, Settings, X } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';
import { $tcc } from 'utils/functions/translate';
import { as, IAlertStatus } from 'utils/strings/alertStatus';

import { ITooltipProps } from '../Tooltip/SimpleTooltip/SimpleTooltip.component';

interface IProps {
  status: IAlertStatus;
  tooltipContent?: ITooltipProps['content'];
  tooltipPlacement?: ITooltipProps['placement'];
  noRotation?: boolean;
  isDisabled?: boolean;
  iconSize?: number;
}
export class AlertIcon extends PureComponent<IProps> {
  public render(): ReactNode {
    const { status, tooltipContent, tooltipPlacement, noRotation, isDisabled, iconSize } = this.props;

    const color = isDisabled ? theme.colors.input.disabled : _get(theme.colors, _camelCase(status));
    const size = iconSize || 20;
    let Icon = null;

    if (_includes([as.failed, as.server_error, as.error], status)) {
      Icon = <X color={color} size={size} />;
    }
    if (_includes([as.success], status)) {
      Icon = <Check color={color} size={size} />;
    }
    if (_includes([as.warning], status)) {
      Icon = <AlertTriangle color={color} size={size} />;
    }
    if (_includes([as.running], status)) {
      Icon = <Settings color={color} size={size} />;
    }
    if (_includes([as.pending], status)) {
      Icon = <span className={'icon-simulations-running'} color={color} />;
    }
    if (_includes([as.empty], status)) {
      Icon = <span className={'icon-simulations-empty'} color={color} />;
    }

    return (
      <SimpleTooltip content={tooltipContent || $tcc(status)} placement={tooltipPlacement || 'bottom'}>
        {!noRotation && _includes([as.running, as.pending], status) ? (
          <RotatingIcon>{Icon}</RotatingIcon>
        ) : (
          <StyledIcon>{Icon}</StyledIcon>
        )}
      </SimpleTooltip>
    );
  }
}

const StyledIcon = styled.div`
  font-size: 14px;
  width: 20px;
  height: 20px;
`;

const RotatingIcon = styled(StyledIcon)`
  -webkit-animation: rotating 1s ease infinite;
  animation: rotating 1s ease infinite;
`;
