import { _filter, _find, _get, _has, _keys, _map, _reduce, _size, _split, _uniq } from 'libs/lodash';
import { IRootState } from 'redux/reducer';
import { IProjectionData } from 'redux/restResources/detail/obat/actions';
import { IProjectionParams, IProjectionSection } from 'redux/restResources/detail/obat/reducer';
import { IGeometryListed } from 'types/Geometry/Geometry';
import { ISelectOption, ISelectOptions } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { $tResourceTemplates } from 'utils/functions/translate';
import { IProgressStatus } from 'utils/strings/progressStatus';

export const selectObatImportProgress = (rootState: IRootState): IProgressStatus => {
  return rootState.obat.importProgress;
};

export const selectProjectionData = (rootState: IRootState): IProjectionData | undefined => {
  const { data } = rootState.obat.projection;
  const { table } = data;

  return _size(_keys(table)) > 0 ? data : undefined;
};

export const selectObatGeometriesOptions = (rootState: IRootState): ISelectOptions => {
  const geometries = rootState.obat.geometries;

  return _map(
    geometries,
    (geometry: IGeometryListed): ISelectOption => {
      return { label: geometry.name, value: geometry.id };
    }
  );
};

export const selectIsProjectionTaskRunning = (rootState: IRootState): boolean => {
  return rootState.obat.projection.isTaskRunning;
};

export const selectProjectionSection = (rootState: IRootState): IProjectionSection => {
  return rootState.obat.projection.section;
};

export const selectProjectionParams = (rootState: IRootState): IProjectionParams => {
  return rootState.obat.projection.params;
};

export const selectProjectionGeometryName = (rootState: IRootState): string => {
  const {
    params: { geometry: geometryId },
  } = rootState.obat.projection;

  const geometriesOptions = selectObatGeometriesOptions(rootState);

  const geometry = _find(geometriesOptions, { value: geometryId });

  return geometry ? geometry.label : '';
};

export const selectProjectionResultsView = (rootState: IRootState): string => {
  return rootState.obat.projection.resultsView;
};

export const selectObatsTemplateOptions = (rootState: IRootState): ISelectOptions => {
  const { templates } = rootState.obat;

  return !templates
    ? []
    : _map(templates.template_obat_use_age, (template: string) => {
        return { label: $tResourceTemplates(template), value: template };
      });
};

export const selectObatsTemplateUseOptions = (rootState: IRootState): ISelectOptions => {
  const { templates } = rootState.obat;

  const usesOptions = !templates
    ? []
    : _map(templates.template_obat_use_age, (template: string): string => {
        return _split(template, '_')[0];
      });

  return _map(
    _uniq(usesOptions),
    (use: string): ISelectOption => {
      return { value: use, label: $tResourceTemplates(use) };
    }
  );
};

export const selectObatsTemplateAgeOptionsFunction = (rootState: IRootState) => {
  const { templates } = rootState.obat;

  return (selectedUse: string): ISelectOptions => {
    return !templates
      ? []
      : _map(
          _filter(templates.template_obat_use_age, (template: string): boolean => {
            const useOption = _split(template, '_')[0];

            return useOption === selectedUse;
          }),
          (template: string): ISelectOption => {
            const ageOption = _split(template, '_')[1];

            return { value: ageOption, label: $tResourceTemplates(ageOption) };
          }
        );
  };
};
