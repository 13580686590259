import { ALERT_HEIGHT } from 'components/Alerts';
import { BREADCRUMB_BAR_HEIGHT, BreadcrumbTopBar } from 'components/BreadcrumbTopBar';
import { OplusLoader } from 'components/Loader';
import { AlertModal } from 'components/Modals/AlertModal';
import { WithGeometryMenu } from 'pages/GeometryMenu/WithGeometryMenu';
import { WithHomeDrawer } from 'pages/HomeMenu/WithHomeMenu';
import { WithObatMenu } from 'pages/ObatMenu/WithObatsMenu';
import { WithOrganizationMenu } from 'pages/OrganizationMenu/WithOrganizationMenu';
import { WithProjectMenu } from 'pages/ProjectMenu/WithProjectMenu';
import { WithMonoSimulationGroupMenu } from 'pages/SimulationGroupMenu/WithMonoSimulationGroupMenu';
import { WithMultiSimulationGroupMenu } from 'pages/SimulationGroupMenu/WithMultiSimulationGroupMenu';
import { WithWeatherMenu } from 'pages/WeatherMenu/WithWeatherMenu';
import React, { PureComponent, ReactNode } from 'react';
import { obatMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { ps } from 'utils/strings/progressStatus';
import { rm } from 'utils/strings/routingMenus';

import { OBAT_TOP_BAR_HEIGHT, ObatHeadersBar } from '../ObatResources/ObatHeadersBar';

import { LogoutModal } from 'components/Modals/LogoutModal';
import { IProps } from './Page.container';

interface IState {
  displayRefreshLoader: boolean;
}

export class Page extends PureComponent<IProps> {
  public state: IState = {
    displayRefreshLoader: false,
  };

  public componentDidUpdate(prevProps: IProps): void {
    const { isRefreshingRoutePage } = this.props;

    if (!prevProps.isRefreshingRoutePage && isRefreshingRoutePage) {
      this.setState({ displayRefreshLoader: true });
      setTimeout(() => {
        this.setState({ displayRefreshLoader: false });
      }, 2000);
    }
  }

  public render(): ReactNode {
    const { routingMenu, selectedPage, isMenuDrawerCollapsed } = this.props;

    switch (routingMenu) {
      case rm.home:
        return <WithHomeDrawer selectedPage={selectedPage || 'projects'}>{this.renderContent()}</WithHomeDrawer>;
      case rm.organization:
        return (
          <WithOrganizationMenu selectedPage={selectedPage || 'projects'}>{this.renderContent()}</WithOrganizationMenu>
        );
      case rm.project:
        return <WithProjectMenu selectedPage={selectedPage || 'obats'}>{this.renderContent()}</WithProjectMenu>;
      case rm.obat:
        return (
          <WithObatMenu selectedPage={selectedPage || 'default'} isCollapsed={!!isMenuDrawerCollapsed}>
            {this.renderContent()}
          </WithObatMenu>
        );
      case rm.geometry:
        return (
          <WithGeometryMenu selectedPage={selectedPage || 'default'} isCollapsed={!!isMenuDrawerCollapsed}>
            {this.renderContent()}
          </WithGeometryMenu>
        );
      case rm.weather:
        return (
          <WithWeatherMenu selectedPage={selectedPage || 'default'} isCollapsed={!!isMenuDrawerCollapsed}>
            {this.renderContent()}
          </WithWeatherMenu>
        );
      case rm.monoSimulationGroup:
        return (
          <WithMonoSimulationGroupMenu selectedPage={selectedPage || 'default'} isCollapsed={!!isMenuDrawerCollapsed}>
            {this.renderContent()}
          </WithMonoSimulationGroupMenu>
        );
      case rm.multiSimulationGroup:
        return (
          <WithMultiSimulationGroupMenu selectedPage={selectedPage || 'default'} isCollapsed={!!isMenuDrawerCollapsed}>
            {this.renderContent()}
          </WithMultiSimulationGroupMenu>
        );
    }
  }

  private renderChildren = () => {
    const {
      children,
      noPadding,
      routingMenu,
      renderAlert,
      selectedPage,
      withBottomButtons,
      formDrawerWidth,
    } = this.props;

    const topBarHeight =
      routingMenu === rm.obat && selectedPage !== obatMenuPages.projection
        ? OBAT_TOP_BAR_HEIGHT
        : renderAlert && renderAlert()
        ? BREADCRUMB_BAR_HEIGHT + ALERT_HEIGHT
        : BREADCRUMB_BAR_HEIGHT;

    return (
      <ChildrenContainer
        id="pageBody"
        topBarHeight={topBarHeight}
        formDrawerWidth={formDrawerWidth}
        noPadding={noPadding}
        withBottomButtons={withBottomButtons}
      >
        {children}
      </ChildrenContainer>
    );
  };

  private renderContent = (): ReactNode => {
    const { pageTitle, customPageRefresh, renderAlert, obatHeaderProps } = this.props;
    const { displayRefreshLoader } = this.state;

    return displayRefreshLoader ? (
      <OplusLoader progress={ps.refreshing} />
    ) : (
      <>
        <ContentContainer>
          <BreadcrumbTopBar pageTitle={pageTitle} customPageRefresh={customPageRefresh} />
          {!!obatHeaderProps && obatHeaderProps.cardColumnsParams.length > 1 && <ObatHeadersBar {...obatHeaderProps} />}
          {!!renderAlert && renderAlert()}
          {this.renderChildren()}
        </ContentContainer>
        <AlertModal />
        <LogoutModal />
      </>
    );
  };
}

const ContentContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const SCROLLBAR_HEIGHT = 8;

interface IChildrenProps {
  formDrawerWidth: number;
  topBarHeight: number;
  noPadding?: boolean;
  withBottomButtons?: boolean;
}

const ChildrenContainer = styled.div`
  padding-bottom: ${(props: IChildrenProps): string => (props.withBottomButtons ? '54px' : '')};
  padding-left: ${(props: IChildrenProps): string => `${props.noPadding ? 0 : 16}px`};
  padding-right: ${(props: IChildrenProps): string => `${props.formDrawerWidth + (props.noPadding ? 0 : 16)}px`};
  margin-right: ${(props: IChildrenProps): string => `-${props.formDrawerWidth ? props.formDrawerWidth : 0}px`};
  height: ${(props: IChildrenProps) => `calc(100vh - ${props.topBarHeight}px - ${SCROLLBAR_HEIGHT}px)`};
  overflow-y: scroll;
  box-sizing: border-box; /* ignore paddings and margin for height and width */
`;
