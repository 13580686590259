import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { _merge } from 'libs/lodash';
import theme from 'style/theme';

export const projectSubMenuDrawerTheme = (muiTheme: Theme | null): Theme =>
  createMuiTheme(
    _merge({}, muiTheme, {
      overrides: {
        MuiListItem: {
          root: {
            paddingTop: '2px',
            paddingBottom: '2px',
            '&$selected': {
              borderLeft: 0,
              backgroundColor: theme.colors.menu.selectedItem,
              borderRadius: theme.borderRadius.small,
              paddingLeft: '4px',
            },
          },
          gutters: {
            paddingLeft: '4px',
            paddingRight: '4px',
          },
        },
        MuiListItemText: {
          primary: {
            fontSize: '14px',
          },
        },
        MuiDrawer: {
          paperAnchorDockedLeft: {
            backgroundColor: theme.colors.menu.secondary,
          },
        },
      },
    })
  );
