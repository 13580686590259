import { connect } from 'react-redux';
import { closeObatResourceCommitmentsModalAction } from 'redux/modals/obatResourceCommitmentsModal/actions';
import {
  selectIsObatResourceCommitmentsModalOpen,
  selectObatResourceCommitments,
  selectObatResourceCommitmentTitle,
} from 'redux/modals/obatResourceCommitmentsModal/selectors';
import { IRootState } from 'redux/reducer';

import { ObatResourceCommitmentsModal as ObatResourceCommitmentsModalComponent } from './ObatResourceCommitmentsModal.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  return {
    isOpen: selectIsObatResourceCommitmentsModalOpen(rootState),
    commitments: selectObatResourceCommitments(rootState),
    commitmentTitle: selectObatResourceCommitmentTitle(rootState),
  };
};

const mapDispatchToProps = {
  closeObatResourceCommitmentsModal: closeObatResourceCommitmentsModalAction,
};

export const ObatResourceCommitmentsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ObatResourceCommitmentsModalComponent);
