import {
  _concat,
  _constant,
  _first,
  _forEachRight,
  _includes,
  _indexOf,
  _last,
  _map,
  _reduce,
  _sortBy,
  _times,
} from 'libs/lodash';
import moment from 'moment';
import { ICalendar, IHeatmap, IHeatmapDays, IHeatmapLegendItem } from 'types/Obat/Calendar';
import { IDayGroup } from 'types/Obat/DayGroup';
import { IPeriod } from 'types/Obat/Period';
import { enums } from 'utils/configs/enums';

interface ILegendObj {
  [dayGroupNum: number]: IHeatmapLegendItem;
}

interface IPeriodBounds {
  start: moment.Moment;
  end: moment.Moment;
}

interface IBoundsByPeriodId {
  [periodId: string]: IPeriodBounds;
}

interface ICalendarShape {
  days: IHeatmapDays;
  months_separators: number[][][];
  months_ticks: number[];
}

export const calculateCalendarHeatmap = (calendar: ICalendar, year?: number): IHeatmap => {
  // set default year
  year = year || 2012;

  // prepare legend
  let legendObj: ILegendObj = {};
  legendObj[-2] = {
    ref: 'not_relevant',
    num: -2,
    empty: true,
  };
  legendObj[-1] = {
    ref: 'empty',
    num: -1,
    empty: true,
  };
  legendObj = _reduce(
    calendar.day_groups_contents,
    (_legendObj: ILegendObj, dayGroup: IDayGroup): ILegendObj => {
      _legendObj[dayGroup.sort_index] = {
        ref: dayGroup.weak_ref,
        num: dayGroup.sort_index,
        empty: true,
      };

      return _legendObj;
    },
    legendObj
  );

  // prepare flat days
  const currentDay = moment([year]);
  let flatDays: moment.Moment[] = [];
  while (currentDay.year() === year) {
    flatDays = [...flatDays, currentDay.clone()];
    currentDay.add(1, 'days');
  }

  // prepare periods bounds cache system
  const cachedBounds: IBoundsByPeriodId = {};

  // calculate flat day groups and legend
  let flatDayGroups: number[] = _map(flatDays, (day: moment.Moment): number => {
    // -1: no group, >=0: group num
    let dayGroupNum = -1;
    _forEachRight(calendar.day_groups_contents, (exploredDayGroup: IDayGroup): boolean => {
      _forEachRight(exploredDayGroup.periods_contents, (exploredPeriod: IPeriod): boolean | void => {
        if (periodContains(exploredPeriod, day, cachedBounds)) {
          dayGroupNum = exploredDayGroup.sort_index;

          return false; // stop loop
        }
      });

      return dayGroupNum === -1; // continue if no day group was affected, else stop
    });
    // explain day group as not empty
    legendObj[dayGroupNum].empty = false;

    return dayGroupNum;
  });

  // complete flat days with -2 (isweekday goes from 1 to 7 starting monday)
  // beginning: monday 0, tuesday: 1, ...
  // @ts-ignore
  const addAtBeginning = _first(flatDays).isoWeekday() - 1;
  // end: monday: 6, tuesday: 5, ...
  // @ts-ignore
  const addAtEnd = 6 - (_last(flatDays).isoWeekday() - 1);
  flatDayGroups = _concat(_times(addAtBeginning, _constant(-2)), flatDayGroups, _times(addAtEnd, _constant(-2)));

  // inform if -2 day group as empty
  legendObj[-2].empty = addAtBeginning + addAtEnd === 0;

  // create legend
  const legend = _sortBy(_map(legendObj), ['num']);

  // create days data
  const dayGroupsData = _reduce(
    flatDayGroups,
    (_dayGroupsData: number[][], dayNum: number, i: number): number[][] => {
      _dayGroupsData[i % 7].push(dayNum);

      return _dayGroupsData;
    },
    [[], [], [], [], [], [], []]
  );

  // retrieve calendar shape
  const calendarShape = getCalendarShape(year);

  // return heatmap
  return {
    day_groups: {
      data: dayGroupsData,
      columns: calendarShape.days.columns,
      index: calendarShape.days.index,
    },
    days: calendarShape.days,
    months_separators: calendarShape.months_separators,
    months_ticks: calendarShape.months_ticks,
    legend,
  };
};

const periodContains = (period: IPeriod, day: moment.Moment, cachedBounds?: IBoundsByPeriodId): boolean => {
  // set cachedBounds if not given
  cachedBounds = cachedBounds || {};

  // see if it not an affected day type
  if (!_includes(period.affected_day_types, enums.obat.obat_contents.day_types[day.isoWeekday() - 1])) {
    return false;
  }

  if (!cachedBounds[period.id]) {
    // calculate start
    const start = getDate(
      day.year(),
      period.start_number ? period.start_number : 1,
      period.start_day_type,
      period.start_month ? period.start_month : 1
    );
    // calculate end
    let end: moment.Moment;
    if (period.duration) {
      end = start.clone();
      end.add(period.duration - 1, 'days');
    } else {
      end = getDate(
        day.year(),
        period.end_number ? period.end_number : 31,
        period.end_day_type,
        period.end_month ? period.end_month : 12
      );
    }
    cachedBounds[period.id] = {
      start,
      end,
    };
  }

  return day.isSameOrAfter(cachedBounds[period.id].start) && day.isSameOrBefore(cachedBounds[period.id].end);
};

const getDate = (year: number, nb: number, dayType: string | null, month: number): moment.Moment => {
  if (!dayType) {
    // manage leap years
    if (nb === 29 && month === 2 && !moment([year]).isLeapYear()) {
      nb = 28;
    }

    return moment([year, month - 1, nb]);
  }

  // manage nth day type of month
  // first day of month
  const day = moment([year, month - 1, 1]);
  // first day_type of month
  // following formula ensures result of modulo as >= 0 (which as not the case for javascript)
  const delta = (_indexOf(enums.obat.obat_contents.day_types, dayType) - ((day.isoWeekday() - 1) % 7) + 7) % 7;
  day.add(delta, 'days');
  // nth day_type of month
  day.add((nb - 1) * 7, 'days');

  return day;
};

const getCalendarShape = (year: number): ICalendarShape => {
  if (year !== 2012) {
    throw new Error('not implemented');
  }

  return {
    days: {
      columns: [
        '2011-52',
        '2012-01',
        '2012-02',
        '2012-03',
        '2012-04',
        '2012-05',
        '2012-06',
        '2012-07',
        '2012-08',
        '2012-09',
        '2012-10',
        '2012-11',
        '2012-12',
        '2012-13',
        '2012-14',
        '2012-15',
        '2012-16',
        '2012-17',
        '2012-18',
        '2012-19',
        '2012-20',
        '2012-21',
        '2012-22',
        '2012-23',
        '2012-24',
        '2012-25',
        '2012-26',
        '2012-27',
        '2012-28',
        '2012-29',
        '2012-30',
        '2012-31',
        '2012-32',
        '2012-33',
        '2012-34',
        '2012-35',
        '2012-36',
        '2012-37',
        '2012-38',
        '2012-39',
        '2012-40',
        '2012-41',
        '2012-42',
        '2012-43',
        '2012-44',
        '2012-45',
        '2012-46',
        '2012-47',
        '2012-48',
        '2012-49',
        '2012-50',
        '2012-51',
        '2012-52',
        '2013-01',
      ],
      data: [
        [
          null,
          '2012-01-02T00:00:00.000Z',
          '2012-01-09T00:00:00.000Z',
          '2012-01-16T00:00:00.000Z',
          '2012-01-23T00:00:00.000Z',
          '2012-01-30T00:00:00.000Z',
          '2012-02-06T00:00:00.000Z',
          '2012-02-13T00:00:00.000Z',
          '2012-02-20T00:00:00.000Z',
          '2012-02-27T00:00:00.000Z',
          '2012-03-05T00:00:00.000Z',
          '2012-03-12T00:00:00.000Z',
          '2012-03-19T00:00:00.000Z',
          '2012-03-26T00:00:00.000Z',
          '2012-04-02T00:00:00.000Z',
          '2012-04-09T00:00:00.000Z',
          '2012-04-16T00:00:00.000Z',
          '2012-04-23T00:00:00.000Z',
          '2012-04-30T00:00:00.000Z',
          '2012-05-07T00:00:00.000Z',
          '2012-05-14T00:00:00.000Z',
          '2012-05-21T00:00:00.000Z',
          '2012-05-28T00:00:00.000Z',
          '2012-06-04T00:00:00.000Z',
          '2012-06-11T00:00:00.000Z',
          '2012-06-18T00:00:00.000Z',
          '2012-06-25T00:00:00.000Z',
          '2012-07-02T00:00:00.000Z',
          '2012-07-09T00:00:00.000Z',
          '2012-07-16T00:00:00.000Z',
          '2012-07-23T00:00:00.000Z',
          '2012-07-30T00:00:00.000Z',
          '2012-08-06T00:00:00.000Z',
          '2012-08-13T00:00:00.000Z',
          '2012-08-20T00:00:00.000Z',
          '2012-08-27T00:00:00.000Z',
          '2012-09-03T00:00:00.000Z',
          '2012-09-10T00:00:00.000Z',
          '2012-09-17T00:00:00.000Z',
          '2012-09-24T00:00:00.000Z',
          '2012-10-01T00:00:00.000Z',
          '2012-10-08T00:00:00.000Z',
          '2012-10-15T00:00:00.000Z',
          '2012-10-22T00:00:00.000Z',
          '2012-10-29T00:00:00.000Z',
          '2012-11-05T00:00:00.000Z',
          '2012-11-12T00:00:00.000Z',
          '2012-11-19T00:00:00.000Z',
          '2012-11-26T00:00:00.000Z',
          '2012-12-03T00:00:00.000Z',
          '2012-12-10T00:00:00.000Z',
          '2012-12-17T00:00:00.000Z',
          '2012-12-24T00:00:00.000Z',
          '2012-12-31T00:00:00.000Z',
        ],
        [
          null,
          '2012-01-03T00:00:00.000Z',
          '2012-01-10T00:00:00.000Z',
          '2012-01-17T00:00:00.000Z',
          '2012-01-24T00:00:00.000Z',
          '2012-01-31T00:00:00.000Z',
          '2012-02-07T00:00:00.000Z',
          '2012-02-14T00:00:00.000Z',
          '2012-02-21T00:00:00.000Z',
          '2012-02-28T00:00:00.000Z',
          '2012-03-06T00:00:00.000Z',
          '2012-03-13T00:00:00.000Z',
          '2012-03-20T00:00:00.000Z',
          '2012-03-27T00:00:00.000Z',
          '2012-04-03T00:00:00.000Z',
          '2012-04-10T00:00:00.000Z',
          '2012-04-17T00:00:00.000Z',
          '2012-04-24T00:00:00.000Z',
          '2012-05-01T00:00:00.000Z',
          '2012-05-08T00:00:00.000Z',
          '2012-05-15T00:00:00.000Z',
          '2012-05-22T00:00:00.000Z',
          '2012-05-29T00:00:00.000Z',
          '2012-06-05T00:00:00.000Z',
          '2012-06-12T00:00:00.000Z',
          '2012-06-19T00:00:00.000Z',
          '2012-06-26T00:00:00.000Z',
          '2012-07-03T00:00:00.000Z',
          '2012-07-10T00:00:00.000Z',
          '2012-07-17T00:00:00.000Z',
          '2012-07-24T00:00:00.000Z',
          '2012-07-31T00:00:00.000Z',
          '2012-08-07T00:00:00.000Z',
          '2012-08-14T00:00:00.000Z',
          '2012-08-21T00:00:00.000Z',
          '2012-08-28T00:00:00.000Z',
          '2012-09-04T00:00:00.000Z',
          '2012-09-11T00:00:00.000Z',
          '2012-09-18T00:00:00.000Z',
          '2012-09-25T00:00:00.000Z',
          '2012-10-02T00:00:00.000Z',
          '2012-10-09T00:00:00.000Z',
          '2012-10-16T00:00:00.000Z',
          '2012-10-23T00:00:00.000Z',
          '2012-10-30T00:00:00.000Z',
          '2012-11-06T00:00:00.000Z',
          '2012-11-13T00:00:00.000Z',
          '2012-11-20T00:00:00.000Z',
          '2012-11-27T00:00:00.000Z',
          '2012-12-04T00:00:00.000Z',
          '2012-12-11T00:00:00.000Z',
          '2012-12-18T00:00:00.000Z',
          '2012-12-25T00:00:00.000Z',
          null,
        ],
        [
          null,
          '2012-01-04T00:00:00.000Z',
          '2012-01-11T00:00:00.000Z',
          '2012-01-18T00:00:00.000Z',
          '2012-01-25T00:00:00.000Z',
          '2012-02-01T00:00:00.000Z',
          '2012-02-08T00:00:00.000Z',
          '2012-02-15T00:00:00.000Z',
          '2012-02-22T00:00:00.000Z',
          '2012-02-29T00:00:00.000Z',
          '2012-03-07T00:00:00.000Z',
          '2012-03-14T00:00:00.000Z',
          '2012-03-21T00:00:00.000Z',
          '2012-03-28T00:00:00.000Z',
          '2012-04-04T00:00:00.000Z',
          '2012-04-11T00:00:00.000Z',
          '2012-04-18T00:00:00.000Z',
          '2012-04-25T00:00:00.000Z',
          '2012-05-02T00:00:00.000Z',
          '2012-05-09T00:00:00.000Z',
          '2012-05-16T00:00:00.000Z',
          '2012-05-23T00:00:00.000Z',
          '2012-05-30T00:00:00.000Z',
          '2012-06-06T00:00:00.000Z',
          '2012-06-13T00:00:00.000Z',
          '2012-06-20T00:00:00.000Z',
          '2012-06-27T00:00:00.000Z',
          '2012-07-04T00:00:00.000Z',
          '2012-07-11T00:00:00.000Z',
          '2012-07-18T00:00:00.000Z',
          '2012-07-25T00:00:00.000Z',
          '2012-08-01T00:00:00.000Z',
          '2012-08-08T00:00:00.000Z',
          '2012-08-15T00:00:00.000Z',
          '2012-08-22T00:00:00.000Z',
          '2012-08-29T00:00:00.000Z',
          '2012-09-05T00:00:00.000Z',
          '2012-09-12T00:00:00.000Z',
          '2012-09-19T00:00:00.000Z',
          '2012-09-26T00:00:00.000Z',
          '2012-10-03T00:00:00.000Z',
          '2012-10-10T00:00:00.000Z',
          '2012-10-17T00:00:00.000Z',
          '2012-10-24T00:00:00.000Z',
          '2012-10-31T00:00:00.000Z',
          '2012-11-07T00:00:00.000Z',
          '2012-11-14T00:00:00.000Z',
          '2012-11-21T00:00:00.000Z',
          '2012-11-28T00:00:00.000Z',
          '2012-12-05T00:00:00.000Z',
          '2012-12-12T00:00:00.000Z',
          '2012-12-19T00:00:00.000Z',
          '2012-12-26T00:00:00.000Z',
          null,
        ],
        [
          null,
          '2012-01-05T00:00:00.000Z',
          '2012-01-12T00:00:00.000Z',
          '2012-01-19T00:00:00.000Z',
          '2012-01-26T00:00:00.000Z',
          '2012-02-02T00:00:00.000Z',
          '2012-02-09T00:00:00.000Z',
          '2012-02-16T00:00:00.000Z',
          '2012-02-23T00:00:00.000Z',
          '2012-03-01T00:00:00.000Z',
          '2012-03-08T00:00:00.000Z',
          '2012-03-15T00:00:00.000Z',
          '2012-03-22T00:00:00.000Z',
          '2012-03-29T00:00:00.000Z',
          '2012-04-05T00:00:00.000Z',
          '2012-04-12T00:00:00.000Z',
          '2012-04-19T00:00:00.000Z',
          '2012-04-26T00:00:00.000Z',
          '2012-05-03T00:00:00.000Z',
          '2012-05-10T00:00:00.000Z',
          '2012-05-17T00:00:00.000Z',
          '2012-05-24T00:00:00.000Z',
          '2012-05-31T00:00:00.000Z',
          '2012-06-07T00:00:00.000Z',
          '2012-06-14T00:00:00.000Z',
          '2012-06-21T00:00:00.000Z',
          '2012-06-28T00:00:00.000Z',
          '2012-07-05T00:00:00.000Z',
          '2012-07-12T00:00:00.000Z',
          '2012-07-19T00:00:00.000Z',
          '2012-07-26T00:00:00.000Z',
          '2012-08-02T00:00:00.000Z',
          '2012-08-09T00:00:00.000Z',
          '2012-08-16T00:00:00.000Z',
          '2012-08-23T00:00:00.000Z',
          '2012-08-30T00:00:00.000Z',
          '2012-09-06T00:00:00.000Z',
          '2012-09-13T00:00:00.000Z',
          '2012-09-20T00:00:00.000Z',
          '2012-09-27T00:00:00.000Z',
          '2012-10-04T00:00:00.000Z',
          '2012-10-11T00:00:00.000Z',
          '2012-10-18T00:00:00.000Z',
          '2012-10-25T00:00:00.000Z',
          '2012-11-01T00:00:00.000Z',
          '2012-11-08T00:00:00.000Z',
          '2012-11-15T00:00:00.000Z',
          '2012-11-22T00:00:00.000Z',
          '2012-11-29T00:00:00.000Z',
          '2012-12-06T00:00:00.000Z',
          '2012-12-13T00:00:00.000Z',
          '2012-12-20T00:00:00.000Z',
          '2012-12-27T00:00:00.000Z',
          null,
        ],
        [
          null,
          '2012-01-06T00:00:00.000Z',
          '2012-01-13T00:00:00.000Z',
          '2012-01-20T00:00:00.000Z',
          '2012-01-27T00:00:00.000Z',
          '2012-02-03T00:00:00.000Z',
          '2012-02-10T00:00:00.000Z',
          '2012-02-17T00:00:00.000Z',
          '2012-02-24T00:00:00.000Z',
          '2012-03-02T00:00:00.000Z',
          '2012-03-09T00:00:00.000Z',
          '2012-03-16T00:00:00.000Z',
          '2012-03-23T00:00:00.000Z',
          '2012-03-30T00:00:00.000Z',
          '2012-04-06T00:00:00.000Z',
          '2012-04-13T00:00:00.000Z',
          '2012-04-20T00:00:00.000Z',
          '2012-04-27T00:00:00.000Z',
          '2012-05-04T00:00:00.000Z',
          '2012-05-11T00:00:00.000Z',
          '2012-05-18T00:00:00.000Z',
          '2012-05-25T00:00:00.000Z',
          '2012-06-01T00:00:00.000Z',
          '2012-06-08T00:00:00.000Z',
          '2012-06-15T00:00:00.000Z',
          '2012-06-22T00:00:00.000Z',
          '2012-06-29T00:00:00.000Z',
          '2012-07-06T00:00:00.000Z',
          '2012-07-13T00:00:00.000Z',
          '2012-07-20T00:00:00.000Z',
          '2012-07-27T00:00:00.000Z',
          '2012-08-03T00:00:00.000Z',
          '2012-08-10T00:00:00.000Z',
          '2012-08-17T00:00:00.000Z',
          '2012-08-24T00:00:00.000Z',
          '2012-08-31T00:00:00.000Z',
          '2012-09-07T00:00:00.000Z',
          '2012-09-14T00:00:00.000Z',
          '2012-09-21T00:00:00.000Z',
          '2012-09-28T00:00:00.000Z',
          '2012-10-05T00:00:00.000Z',
          '2012-10-12T00:00:00.000Z',
          '2012-10-19T00:00:00.000Z',
          '2012-10-26T00:00:00.000Z',
          '2012-11-02T00:00:00.000Z',
          '2012-11-09T00:00:00.000Z',
          '2012-11-16T00:00:00.000Z',
          '2012-11-23T00:00:00.000Z',
          '2012-11-30T00:00:00.000Z',
          '2012-12-07T00:00:00.000Z',
          '2012-12-14T00:00:00.000Z',
          '2012-12-21T00:00:00.000Z',
          '2012-12-28T00:00:00.000Z',
          null,
        ],
        [
          null,
          '2012-01-07T00:00:00.000Z',
          '2012-01-14T00:00:00.000Z',
          '2012-01-21T00:00:00.000Z',
          '2012-01-28T00:00:00.000Z',
          '2012-02-04T00:00:00.000Z',
          '2012-02-11T00:00:00.000Z',
          '2012-02-18T00:00:00.000Z',
          '2012-02-25T00:00:00.000Z',
          '2012-03-03T00:00:00.000Z',
          '2012-03-10T00:00:00.000Z',
          '2012-03-17T00:00:00.000Z',
          '2012-03-24T00:00:00.000Z',
          '2012-03-31T00:00:00.000Z',
          '2012-04-07T00:00:00.000Z',
          '2012-04-14T00:00:00.000Z',
          '2012-04-21T00:00:00.000Z',
          '2012-04-28T00:00:00.000Z',
          '2012-05-05T00:00:00.000Z',
          '2012-05-12T00:00:00.000Z',
          '2012-05-19T00:00:00.000Z',
          '2012-05-26T00:00:00.000Z',
          '2012-06-02T00:00:00.000Z',
          '2012-06-09T00:00:00.000Z',
          '2012-06-16T00:00:00.000Z',
          '2012-06-23T00:00:00.000Z',
          '2012-06-30T00:00:00.000Z',
          '2012-07-07T00:00:00.000Z',
          '2012-07-14T00:00:00.000Z',
          '2012-07-21T00:00:00.000Z',
          '2012-07-28T00:00:00.000Z',
          '2012-08-04T00:00:00.000Z',
          '2012-08-11T00:00:00.000Z',
          '2012-08-18T00:00:00.000Z',
          '2012-08-25T00:00:00.000Z',
          '2012-09-01T00:00:00.000Z',
          '2012-09-08T00:00:00.000Z',
          '2012-09-15T00:00:00.000Z',
          '2012-09-22T00:00:00.000Z',
          '2012-09-29T00:00:00.000Z',
          '2012-10-06T00:00:00.000Z',
          '2012-10-13T00:00:00.000Z',
          '2012-10-20T00:00:00.000Z',
          '2012-10-27T00:00:00.000Z',
          '2012-11-03T00:00:00.000Z',
          '2012-11-10T00:00:00.000Z',
          '2012-11-17T00:00:00.000Z',
          '2012-11-24T00:00:00.000Z',
          '2012-12-01T00:00:00.000Z',
          '2012-12-08T00:00:00.000Z',
          '2012-12-15T00:00:00.000Z',
          '2012-12-22T00:00:00.000Z',
          '2012-12-29T00:00:00.000Z',
          null,
        ],
        [
          '2012-01-01T00:00:00.000Z',
          '2012-01-08T00:00:00.000Z',
          '2012-01-15T00:00:00.000Z',
          '2012-01-22T00:00:00.000Z',
          '2012-01-29T00:00:00.000Z',
          '2012-02-05T00:00:00.000Z',
          '2012-02-12T00:00:00.000Z',
          '2012-02-19T00:00:00.000Z',
          '2012-02-26T00:00:00.000Z',
          '2012-03-04T00:00:00.000Z',
          '2012-03-11T00:00:00.000Z',
          '2012-03-18T00:00:00.000Z',
          '2012-03-25T00:00:00.000Z',
          '2012-04-01T00:00:00.000Z',
          '2012-04-08T00:00:00.000Z',
          '2012-04-15T00:00:00.000Z',
          '2012-04-22T00:00:00.000Z',
          '2012-04-29T00:00:00.000Z',
          '2012-05-06T00:00:00.000Z',
          '2012-05-13T00:00:00.000Z',
          '2012-05-20T00:00:00.000Z',
          '2012-05-27T00:00:00.000Z',
          '2012-06-03T00:00:00.000Z',
          '2012-06-10T00:00:00.000Z',
          '2012-06-17T00:00:00.000Z',
          '2012-06-24T00:00:00.000Z',
          '2012-07-01T00:00:00.000Z',
          '2012-07-08T00:00:00.000Z',
          '2012-07-15T00:00:00.000Z',
          '2012-07-22T00:00:00.000Z',
          '2012-07-29T00:00:00.000Z',
          '2012-08-05T00:00:00.000Z',
          '2012-08-12T00:00:00.000Z',
          '2012-08-19T00:00:00.000Z',
          '2012-08-26T00:00:00.000Z',
          '2012-09-02T00:00:00.000Z',
          '2012-09-09T00:00:00.000Z',
          '2012-09-16T00:00:00.000Z',
          '2012-09-23T00:00:00.000Z',
          '2012-09-30T00:00:00.000Z',
          '2012-10-07T00:00:00.000Z',
          '2012-10-14T00:00:00.000Z',
          '2012-10-21T00:00:00.000Z',
          '2012-10-28T00:00:00.000Z',
          '2012-11-04T00:00:00.000Z',
          '2012-11-11T00:00:00.000Z',
          '2012-11-18T00:00:00.000Z',
          '2012-11-25T00:00:00.000Z',
          '2012-12-02T00:00:00.000Z',
          '2012-12-09T00:00:00.000Z',
          '2012-12-16T00:00:00.000Z',
          '2012-12-23T00:00:00.000Z',
          '2012-12-30T00:00:00.000Z',
          null,
        ],
      ],
      index: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    },
    months_separators: [
      [
        [0.018518518518518519, 0.0],
        [0.018518518518518519, 0.14285714285714286],
      ],
      [
        [0.018518518518518519, 0.14285714285714286],
        [0.018518518518518519, 0.2857142857142857],
      ],
      [
        [0.018518518518518519, 0.2857142857142857],
        [0.018518518518518519, 0.42857142857142857],
      ],
      [
        [0.018518518518518519, 0.42857142857142857],
        [0.018518518518518519, 0.5714285714285714],
      ],
      [
        [0.018518518518518519, 0.5714285714285714],
        [0.018518518518518519, 0.7142857142857142],
      ],
      [
        [0.018518518518518519, 0.7142857142857142],
        [0.018518518518518519, 0.8571428571428571],
      ],
      [
        [0.09259259259259259, 0.2857142857142857],
        [0.09259259259259259, 0.42857142857142857],
      ],
      [
        [0.09259259259259259, 0.42857142857142857],
        [0.09259259259259259, 0.5714285714285714],
      ],
      [
        [0.09259259259259259, 0.5714285714285714],
        [0.09259259259259259, 0.7142857142857142],
      ],
      [
        [0.09259259259259259, 0.7142857142857142],
        [0.09259259259259259, 0.8571428571428571],
      ],
      [
        [0.09259259259259259, 0.8571428571428571],
        [0.09259259259259259, 1.0],
      ],
      [
        [0.1111111111111111, 0.0],
        [0.1111111111111111, 0.14285714285714286],
      ],
      [
        [0.1111111111111111, 0.14285714285714286],
        [0.1111111111111111, 0.2857142857142857],
      ],
      [
        [0.16666666666666667, 0.42857142857142857],
        [0.16666666666666667, 0.5714285714285714],
      ],
      [
        [0.16666666666666667, 0.5714285714285714],
        [0.16666666666666667, 0.7142857142857142],
      ],
      [
        [0.16666666666666667, 0.7142857142857142],
        [0.16666666666666667, 0.8571428571428571],
      ],
      [
        [0.16666666666666667, 0.8571428571428571],
        [0.16666666666666667, 1.0],
      ],
      [
        [0.18518518518518518, 0.0],
        [0.18518518518518518, 0.14285714285714286],
      ],
      [
        [0.18518518518518518, 0.14285714285714286],
        [0.18518518518518518, 0.2857142857142857],
      ],
      [
        [0.18518518518518518, 0.2857142857142857],
        [0.18518518518518518, 0.42857142857142857],
      ],
      [
        [0.24074074074074074, 0.8571428571428571],
        [0.24074074074074074, 1.0],
      ],
      [
        [0.25925925925925927, 0.0],
        [0.25925925925925927, 0.14285714285714286],
      ],
      [
        [0.25925925925925927, 0.14285714285714286],
        [0.25925925925925927, 0.2857142857142857],
      ],
      [
        [0.25925925925925927, 0.2857142857142857],
        [0.25925925925925927, 0.42857142857142857],
      ],
      [
        [0.25925925925925927, 0.42857142857142857],
        [0.25925925925925927, 0.5714285714285714],
      ],
      [
        [0.25925925925925927, 0.5714285714285714],
        [0.25925925925925927, 0.7142857142857142],
      ],
      [
        [0.25925925925925927, 0.7142857142857142],
        [0.25925925925925927, 0.8571428571428571],
      ],
      [
        [0.3333333333333333, 0.14285714285714286],
        [0.3333333333333333, 0.2857142857142857],
      ],
      [
        [0.3333333333333333, 0.2857142857142857],
        [0.3333333333333333, 0.42857142857142857],
      ],
      [
        [0.3333333333333333, 0.42857142857142857],
        [0.3333333333333333, 0.5714285714285714],
      ],
      [
        [0.3333333333333333, 0.5714285714285714],
        [0.3333333333333333, 0.7142857142857142],
      ],
      [
        [0.3333333333333333, 0.7142857142857142],
        [0.3333333333333333, 0.8571428571428571],
      ],
      [
        [0.3333333333333333, 0.8571428571428571],
        [0.3333333333333333, 1.0],
      ],
      [
        [0.35185185185185188, 0.0],
        [0.35185185185185188, 0.14285714285714286],
      ],
      [
        [0.4074074074074074, 0.5714285714285714],
        [0.4074074074074074, 0.7142857142857142],
      ],
      [
        [0.4074074074074074, 0.7142857142857142],
        [0.4074074074074074, 0.8571428571428571],
      ],
      [
        [0.4074074074074074, 0.8571428571428571],
        [0.4074074074074074, 1.0],
      ],
      [
        [0.42592592592592595, 0.0],
        [0.42592592592592595, 0.14285714285714286],
      ],
      [
        [0.42592592592592595, 0.14285714285714286],
        [0.42592592592592595, 0.2857142857142857],
      ],
      [
        [0.42592592592592595, 0.2857142857142857],
        [0.42592592592592595, 0.42857142857142857],
      ],
      [
        [0.42592592592592595, 0.42857142857142857],
        [0.42592592592592595, 0.5714285714285714],
      ],
      [
        [0.48148148148148148, 0.8571428571428571],
        [0.48148148148148148, 1.0],
      ],
      [
        [0.5, 0.0],
        [0.5, 0.14285714285714286],
      ],
      [
        [0.5, 0.14285714285714286],
        [0.5, 0.2857142857142857],
      ],
      [
        [0.5, 0.2857142857142857],
        [0.5, 0.42857142857142857],
      ],
      [
        [0.5, 0.42857142857142857],
        [0.5, 0.5714285714285714],
      ],
      [
        [0.5, 0.5714285714285714],
        [0.5, 0.7142857142857142],
      ],
      [
        [0.5, 0.7142857142857142],
        [0.5, 0.8571428571428571],
      ],
      [
        [0.5740740740740741, 0.2857142857142857],
        [0.5740740740740741, 0.42857142857142857],
      ],
      [
        [0.5740740740740741, 0.42857142857142857],
        [0.5740740740740741, 0.5714285714285714],
      ],
      [
        [0.5740740740740741, 0.5714285714285714],
        [0.5740740740740741, 0.7142857142857142],
      ],
      [
        [0.5740740740740741, 0.7142857142857142],
        [0.5740740740740741, 0.8571428571428571],
      ],
      [
        [0.5740740740740741, 0.8571428571428571],
        [0.5740740740740741, 1.0],
      ],
      [
        [0.5925925925925926, 0.0],
        [0.5925925925925926, 0.14285714285714286],
      ],
      [
        [0.5925925925925926, 0.14285714285714286],
        [0.5925925925925926, 0.2857142857142857],
      ],
      [
        [0.6481481481481481, 0.7142857142857142],
        [0.6481481481481481, 0.8571428571428571],
      ],
      [
        [0.6481481481481481, 0.8571428571428571],
        [0.6481481481481481, 1.0],
      ],
      [
        [0.6666666666666666, 0.0],
        [0.6666666666666666, 0.14285714285714286],
      ],
      [
        [0.6666666666666666, 0.14285714285714286],
        [0.6666666666666666, 0.2857142857142857],
      ],
      [
        [0.6666666666666666, 0.2857142857142857],
        [0.6666666666666666, 0.42857142857142857],
      ],
      [
        [0.6666666666666666, 0.42857142857142857],
        [0.6666666666666666, 0.5714285714285714],
      ],
      [
        [0.6666666666666666, 0.5714285714285714],
        [0.6666666666666666, 0.7142857142857142],
      ],
      [
        [0.7407407407407407, 0.0],
        [0.7407407407407407, 0.14285714285714286],
      ],
      [
        [0.7407407407407407, 0.14285714285714286],
        [0.7407407407407407, 0.2857142857142857],
      ],
      [
        [0.7407407407407407, 0.2857142857142857],
        [0.7407407407407407, 0.42857142857142857],
      ],
      [
        [0.7407407407407407, 0.42857142857142857],
        [0.7407407407407407, 0.5714285714285714],
      ],
      [
        [0.7407407407407407, 0.5714285714285714],
        [0.7407407407407407, 0.7142857142857142],
      ],
      [
        [0.7407407407407407, 0.7142857142857142],
        [0.7407407407407407, 0.8571428571428571],
      ],
      [
        [0.7407407407407407, 0.8571428571428571],
        [0.7407407407407407, 1.0],
      ],
      [
        [0.8148148148148148, 0.42857142857142857],
        [0.8148148148148148, 0.5714285714285714],
      ],
      [
        [0.8148148148148148, 0.5714285714285714],
        [0.8148148148148148, 0.7142857142857142],
      ],
      [
        [0.8148148148148148, 0.7142857142857142],
        [0.8148148148148148, 0.8571428571428571],
      ],
      [
        [0.8148148148148148, 0.8571428571428571],
        [0.8148148148148148, 1.0],
      ],
      [
        [0.8333333333333333, 0.0],
        [0.8333333333333333, 0.14285714285714286],
      ],
      [
        [0.8333333333333333, 0.14285714285714286],
        [0.8333333333333333, 0.2857142857142857],
      ],
      [
        [0.8333333333333333, 0.2857142857142857],
        [0.8333333333333333, 0.42857142857142857],
      ],
      [
        [0.8888888888888888, 0.7142857142857142],
        [0.8888888888888888, 0.8571428571428571],
      ],
      [
        [0.8888888888888888, 0.8571428571428571],
        [0.8888888888888888, 1.0],
      ],
      [
        [0.9074074074074073, 0.0],
        [0.9074074074074073, 0.14285714285714286],
      ],
      [
        [0.9074074074074073, 0.14285714285714286],
        [0.9074074074074073, 0.2857142857142857],
      ],
      [
        [0.9074074074074073, 0.2857142857142857],
        [0.9074074074074073, 0.42857142857142857],
      ],
      [
        [0.9074074074074073, 0.42857142857142857],
        [0.9074074074074073, 0.5714285714285714],
      ],
      [
        [0.9074074074074073, 0.5714285714285714],
        [0.9074074074074073, 0.7142857142857142],
      ],
      [
        [0.9814814814814814, 0.14285714285714286],
        [0.9814814814814814, 0.2857142857142857],
      ],
      [
        [0.9814814814814814, 0.2857142857142857],
        [0.9814814814814814, 0.42857142857142857],
      ],
      [
        [0.9814814814814814, 0.42857142857142857],
        [0.9814814814814814, 0.5714285714285714],
      ],
      [
        [0.9814814814814814, 0.5714285714285714],
        [0.9814814814814814, 0.7142857142857142],
      ],
      [
        [0.9814814814814814, 0.7142857142857142],
        [0.9814814814814814, 0.8571428571428571],
      ],
      [
        [0.9814814814814814, 0.8571428571428571],
        [0.9814814814814814, 1.0],
      ],
      [
        [0.0, 0.8571428571428571],
        [0.018518518518518519, 0.8571428571428571],
      ],
      [
        [0.09259259259259259, 0.2857142857142857],
        [0.1111111111111111, 0.2857142857142857],
      ],
      [
        [0.16666666666666667, 0.42857142857142857],
        [0.18518518518518518, 0.42857142857142857],
      ],
      [
        [0.24074074074074074, 0.8571428571428571],
        [0.25925925925925927, 0.8571428571428571],
      ],
      [
        [0.3333333333333333, 0.14285714285714286],
        [0.35185185185185188, 0.14285714285714286],
      ],
      [
        [0.4074074074074074, 0.5714285714285714],
        [0.42592592592592595, 0.5714285714285714],
      ],
      [
        [0.48148148148148148, 0.8571428571428571],
        [0.5, 0.8571428571428571],
      ],
      [
        [0.5740740740740741, 0.2857142857142857],
        [0.5925925925925926, 0.2857142857142857],
      ],
      [
        [0.6481481481481481, 0.7142857142857142],
        [0.6666666666666666, 0.7142857142857142],
      ],
      [
        [0.8148148148148148, 0.42857142857142857],
        [0.8333333333333333, 0.42857142857142857],
      ],
      [
        [0.8888888888888888, 0.7142857142857142],
        [0.9074074074074073, 0.7142857142857142],
      ],
      [
        [0.9814814814814814, 0.14285714285714286],
        [1.0, 0.14285714285714286],
      ],
    ],
    months_ticks: [
      0.046296296296296297,
      0.12962962962962963,
      0.2037037037037037,
      0.28703703703703706,
      0.37037037037037037,
      0.4444444444444444,
      0.5277777777777778,
      0.6111111111111112,
      0.6944444444444444,
      0.7777777777777777,
      0.8518518518518519,
      0.9351851851851851,
    ],
  };
};
