import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Buttons';
import React, { PureComponent, ReactNode } from 'react';
import { X } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';

interface IProps {
  onClick: () => void;
}

export class CloseSubFormButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { onClick } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <IconContainer>
            <Button
              Icon={<X size={12} />}
              color={'black'}
              bgColor={theme.colors.transparent}
              onClick={onClick}
              noShadow
              height={30}
            />
          </IconContainer>
        </Grid>
      </Grid>
    );
  }
}

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -8px;
`;
