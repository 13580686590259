import { connect } from 'react-redux';
import { selectObatContentsFlatOptionsList } from 'redux/enums/selectors';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { openPlotsModalAction } from 'redux/modals/plotsModal/actions';
import { selectGroupedObatResourcesIdsListFunction } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { ocfe } from 'utils/configs/enums';
import { orc } from 'utils/strings/resourceClasses';

import { OpeningForm as OpeningFormComponent } from './OpeningForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const openingToEdit = selectFormInitialResourceFromTable(orc.opening)(rootState);

  return {
    openingToEdit,
    selectSchedulesByTypeOptions: selectGroupedObatResourcesIdsListFunction(orc.schedule, 'type')(rootState),
    surfaceCategoryOptions: selectObatContentsFlatOptionsList(ocfe.surface_categories)(rootState),
    blindTypeOptions: selectObatContentsFlatOptionsList(ocfe.blind_types)(rootState),
    blindPositionOptions: selectObatContentsFlatOptionsList(ocfe.blind_positions)(rootState),
    blindAngleMethodOptions: selectObatContentsFlatOptionsList(ocfe.blind_angle_methods)(rootState),
    blindControlMethodOptions: selectObatContentsFlatOptionsList(ocfe.blind_control_methods)(rootState),
  };
};

const mapDispatchToProps = {
  openPlotsModal: openPlotsModalAction,
};

export const OpeningForm = connect(mapStateToProps, mapDispatchToProps)(OpeningFormComponent);
