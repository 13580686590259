import { _forEach, _map, _reduce, _round, _split } from 'libs/lodash';
import { IConstruction } from 'types/Obat/Construction';
import { ILayer } from 'types/Obat/Layer';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';

import { getObatResourceIndex } from './getObatResource';

export const enrichConstructions = (obatContents: IObatContents): IObatData => {
  const { construction: constructions, layer: layers } = obatContents.data;

  let enrichedConstructions = _map(
    constructions,
    (construction: IConstruction): IConstruction => {
      return { ...construction, layers_contents: [] };
    }
  );

  // prepare enriched layers
  _forEach(layers, (layer: ILayer): void => {
    // add material content
    const materialIndex = getObatResourceIndex(obatContents, 'material', layer.material);
    layer.material_content = obatContents.data.material[materialIndex];

    // complete construction
    const constructionIndex = getObatResourceIndex(obatContents, 'construction', layer.construction);
    enrichedConstructions = _map(
      enrichedConstructions,
      (construction: IConstruction, index: number): IConstruction => {
        if (index === constructionIndex) {
          const enrichedLayer = {
            ...layer,
            conductivity: construction.conductivities_ratio * layer.material_content.nominal_conductivity,
          };

          return {
            ...construction,
            layers_contents: [...construction.layers_contents, enrichedLayer],
          };
        }

        return construction;
      }
    );
  });

  // calculate rt2012_u
  enrichedConstructions = _map(
    enrichedConstructions,
    (construction: IConstruction): IConstruction => {
      const rt2012U = getRt2012U(construction);

      return { ...construction, rt2012_u: rt2012U };
    }
  );

  return { ...obatContents.data, construction: enrichedConstructions };
};

// layer methods
const getLayerRt2012R = (layer: ILayer, conductivitiesRatio: number): number => {
  return layer.thickness / (layer.material_content.nominal_conductivity * conductivitiesRatio);
};

// construction methods
const getRt2012R = (construction: IConstruction): number | null => {
  const r = _reduce(
    construction.layers_contents,
    (currentR: number, layer: ILayer): number => {
      return currentR + getLayerRt2012R(layer, construction.conductivities_ratio);
    },
    0
  );

  return r === 0 ? null : r;
};

const getRt2012Rsi = (construction: IConstruction): number => {
  const [surfaceType] = [..._split(construction.surface_category, '_', 1)];
  switch (surfaceType) {
    case 'wall':
      return 0.13;
    case 'roof':
      return 0.1;
    case 'floor':
      return 0.17;
  }
  throw new Error('should not be here');
};

const getRt2012Rse = (construction: IConstruction): number | null => {
  const [surfaceType, surfaceCondition] = [..._split(construction.surface_category, '_', 2)];
  switch (surfaceCondition) {
    case 'ground':
      return null;
    case 'interior':
      return getRt2012Rsi(construction);
  }
  switch (surfaceType) {
    case 'wall':
      return 0.04;
    case 'roof':
      return 0.04;
    case 'floor':
      return 0.04;
  }
  throw new Error('should not be here');
};

const getRt2012U = (construction: IConstruction): number | null => {
  // retrieve r
  const r = getRt2012R(construction);
  if (!r) {
    return r;
  }

  // retrieve rse
  const rse = getRt2012Rse(construction);
  if (!rse) {
    return rse;
  }

  return _round(1 / (getRt2012Rsi(construction) + r + rse), 2);
};
