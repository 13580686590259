import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';

import { ObatResourceFormSpecificFields as Component } from './ObatResourceFormSpecificFields.component';

export interface IObatResourceFormSpecificDefaultValues {
  [field: string]: number;
}

interface IOwnProps {
  // tslint:disable-next-line:no-any
  formikProps: any;
  specificDefaultValues: IObatResourceFormSpecificDefaultValues;
  requiredFields?: string[];
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const mapStateToProps = (_rootState: IRootState) => {
  return {};
};

const mapDispatchToProps = {};

export const ObatResourceFormSpecificFields = connect(mapStateToProps, mapDispatchToProps)(Component);
