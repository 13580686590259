import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  required?: boolean;
}

export class FormLabelAsterisk extends PureComponent<IProps> {
  public render(): ReactNode {
    return <Asterisk required={this.props.required}>*</Asterisk>;
  }
}

const Asterisk = styled.span`
  color: ${(props: { required?: boolean }): string => (props.required ? 'red' : 'transparent')};
  font-size: 150%;
  position: relative;
  bottom: -3px;
`;
