import { Action } from 'redux';
import { ILogoutModalState } from './reducer';

export const UPDATE_LOGOUT_MODAL_TIMER_ACTION = 'UPDATE_LOGOUT_MODAL_TIMER_ACTION';
export interface IUpdateLogoutModalTimerAction extends Action<'UPDATE_LOGOUT_MODAL_TIMER_ACTION'> {
  payload: {
    timer: number;
  };
}

export const updateLogoutModalTimerAction = (timer: number): IUpdateLogoutModalTimerAction => ({
  type: UPDATE_LOGOUT_MODAL_TIMER_ACTION,
  payload: {
    timer,
  },
});

export const UPDATE_LOGOUT_MODAL_CAUSE_ACTION = 'UPDATE_LOGOUT_MODAL_CAUSE_ACTION';
export interface IUpdateLogoutModalCauseAction extends Action<'UPDATE_LOGOUT_MODAL_CAUSE_ACTION'> {
  payload: {
    cause: ILogoutModalState['cause'];
  };
}

export const updateLogoutModalCauseAction = (cause: ILogoutModalState['cause']): IUpdateLogoutModalCauseAction => ({
  type: UPDATE_LOGOUT_MODAL_CAUSE_ACTION,
  payload: {
    cause,
  },
});

export type ILogoutModalActions = IUpdateLogoutModalTimerAction | IUpdateLogoutModalCauseAction;
