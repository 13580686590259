import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { _includes } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';

import { IProps } from './DatatableActions.container';

interface IState {
  clickedRestResourceId: string | null;
}

export class DatatableActions extends PureComponent<IProps> {
  public state: IState = {
    clickedRestResourceId: null,
  };

  public render(): ReactNode {
    const {
      openDeletionModal,
      openCopyFormDrawer,
      openEditFormDrawer,
      openCommitmentsModal,
      availableActions,
      routeProject,
      isAdminPermissionMissing,
      isWritePermissionMissing,
      isSeatMissing,
      isRestResourceCommitted,
      isSimulationRunning,
      maxSimusNb,
      isMaxSimusNbReached,
      hasInheritedPermission,
      isLastOrganizationAdministrator,
      isMultiSimulationGroup,
    } = this.props;

    if (!availableActions) {
      return null;
    }

    return (
      <IconsContainer id={'datatableActions'}>
        {_includes(availableActions, 'edit') && (
          <IconButton
            action={'edit'}
            onClick={openEditFormDrawer}
            isWritePermissionMissing={isWritePermissionMissing}
            isSeatMissing={isSeatMissing}
            isAdminPermissionMissing={isAdminPermissionMissing}
            isDisabled={isSimulationRunning}
          />
        )}
        {_includes(availableActions, 'copy') && !isMultiSimulationGroup && (
          <IconButton
            action={'copy'}
            onClick={openCopyFormDrawer}
            isWritePermissionMissing={isWritePermissionMissing}
            isSeatMissing={isSeatMissing}
            isAdminPermissionMissing={isAdminPermissionMissing}
            isDisabled={isSimulationRunning || isMaxSimusNbReached}
            tooltipContent={isMaxSimusNbReached ? $t('maxSimusNbReachedAlert', { max: maxSimusNb }) : undefined}
          />
        )}
        {_includes(availableActions, 'delete') && (
          <IconButton
            action={'delete'}
            onClick={openDeletionModal}
            isWritePermissionMissing={isWritePermissionMissing}
            isSeatMissing={isSeatMissing}
            isAdminPermissionMissing={isAdminPermissionMissing}
            isDisabled={
              isRestResourceCommitted ||
              hasInheritedPermission ||
              isLastOrganizationAdministrator ||
              isSimulationRunning /* no tooltip in this case because already a topAlert on the page */
            }
            tooltipContent={
              isRestResourceCommitted
                ? $t('deleteCommitmentAlert')
                : hasInheritedPermission
                ? $t('hasInheritedPermissionAlert', { name: routeProject.organization.name })
                : isLastOrganizationAdministrator
                ? $t('lastAdminAlert')
                : undefined
            }
          />
        )}
        {isRestResourceCommitted && (
          <IconButton action={'seeDetails'} onClick={openCommitmentsModal} tooltipContent={$t('seeCommitments')} />
        )}
      </IconsContainer>
    );
  }
}

const IconsContainer = styled.div`
  display: flex; /* display on table row hover */
  align-items: center;
`;
