import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getResourceForm } from 'components/Forms/ResourceForm';
import { FormikProps } from 'formik';
import { getYupStringValidation, yupErrors } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import { IRestResourceClass, rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './InviteForm.container';

export interface IOrganizationGuestsFormValues {
  user_email: string;
}
export type IOrganizationGuestsRequestParams = IOrganizationGuestsFormValues;

export class InviteForm extends PureComponent<IProps> {
  public FormDrawer = getFormDrawer<IRestResourceClass>();
  public Form = getForm<IOrganizationGuestsFormValues>();
  public ResourceForm = getResourceForm<
    IOrganizationGuestsFormValues,
    IOrganizationGuestsRequestParams,
    IRestResourceClass
  >();
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { FormDrawer, ResourceForm } = this;

    return (
      <FormDrawer
        formKey={fk.inviteUser}
        width={fdw.medium}
        formFocusRef={this.formFocusRef}
        resourceClass={rrc.user_organization_permission}
      >
        <ResourceForm
          initialResource={undefined}
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          saveResource={this.saveResource}
          getValidationSchema={this.getValidationSchema}
          resourceClass={rrc.user_organization_permission}
          isObatResource={false}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IOrganizationGuestsFormValues>): ReactNode => {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput
              field={'user_email'}
              formikProps={formikProps}
              required
              inputRef={this.formFocusRef}
              label={$t('userEmail')}
            />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): IOrganizationGuestsFormValues => ({
    user_email: '',
  });

  private getValidationSchema = (): yup.ObjectSchema<IOrganizationGuestsFormValues> => {
    return yup.object().shape({
      user_email: getYupStringValidation(true).email(yupErrors.invalidEmail),
    }) as yup.ObjectSchema<IOrganizationGuestsFormValues>;
  };

  private saveResource = (
    _resourceClass: IRestResourceClass,
    _initialResource: undefined,
    requestParams: IOrganizationGuestsRequestParams
  ): void => {
    this.props.inviteGuest(requestParams);
  };
}
