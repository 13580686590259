import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { WithGeometryMenu as WithGeometryMenuComponent } from './WithGeometryMenu.component';

export interface IOwnProps {
  isCollapsed?: boolean;
  selectedPage: string;
}

export type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps & IDispatchProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    routeGeometry: selectRouteResource(rrc.geometry)(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
  };
};

const mapDispatchToProps = {};

export const WithGeometryMenu = connect(mapStateToProps, mapDispatchToProps)(WithGeometryMenuComponent);
