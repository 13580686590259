import { _get, _has, _includes, _reduce } from 'libs/lodash';
import { ITableFilterValues } from 'redux/table/reducer';
import { ITableColumnFilterParams, ITableFilterParams } from 'utils/functions/pickColumnParams/pickColumnParams';

export const initializeTableFilterValues = (
  tableFilterParams: ITableFilterParams,
  tableFilterValues?: ITableFilterValues
): ITableFilterValues => {
  /* Create an object with the column ids as keys. The value will be the input of the user in the column filter input */

  return _reduce(
    tableFilterParams,
    (result: ITableFilterValues, columnFilterParams: ITableColumnFilterParams): ITableFilterValues => {
      let filterValue = '';
      if (!!tableFilterValues && _has(tableFilterValues, columnFilterParams.id)) {
        filterValue = _get(tableFilterValues, columnFilterParams.id);
        if (!!columnFilterParams.filterOptions && !_includes(columnFilterParams.filterOptions, filterValue)) {
          filterValue = '';
        }
      }

      return { ...result, [columnFilterParams.id]: filterValue };
    },
    {}
  );
};
