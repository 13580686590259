import { WithMenu } from 'components/Menus/WithMenu';
import { IMenuItem } from 'components/Menus/WithMenu/WithMenu.component';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { Activity, Box, Layers, Settings, Sun } from 'react-feather';
import { getProjectMenuPageRoute, IProjectMenuPage, projectMenuPages } from 'routing/routes';
import { IProps } from './WithProjectMenu.container';

export const PROJECT_MENU_EXPANDED_WIDTH = 250;
export const PROJECT_MENU_COLLAPSED_WIDTH = 60;

interface IProjectMenuItem extends IMenuItem {
  page: IProjectMenuPage;
}
export class WithProjectMenu extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isCollapsed, selectedPage, routeProject, children } = this.props;

    const items: IProjectMenuItem[] = [
      { page: projectMenuPages.geometries, Icon: <Box /> },
      { page: projectMenuPages.obats, Icon: <Layers /> },
      { page: projectMenuPages.weathers, Icon: <Sun /> },
      { page: projectMenuPages.simulationGroups, Icon: <Activity /> },
      { page: projectMenuPages.projectPermissions, Icon: <Settings />, isDividerAbove: true },
    ];

    return (
      <WithMenu
        isCollapsed={!!isCollapsed}
        selectedPage={selectedPage}
        routeResourceId={routeProject.id}
        items={items}
        getMenuPageRoute={getProjectMenuPageRoute}
      >
        {children}
      </WithMenu>
    );
  }
}
