import { IObatResourceDeleteProps } from 'components/Forms/Buttons/FormDeleteButton/FormDeleteButton.container';
import { FormikProps } from 'formik';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { toggleFormResetAction } from 'redux/form/actions';
import { selectHasFormToReset } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { IFormApiErrorsTranslationPath } from 'types/Errors';
import * as yup from 'yup';

import { Form as Component } from './Form.component';

interface IOwnProps<IFormValues> {
  renderForm: (formikProps: FormikProps<IFormValues>) => ReactNode;
  getInitialFormValues: () => IFormValues;
  getValidationSchema: () => yup.ObjectSchema /*<IFormValues>*/;
  forceSubmitDisabled?: (formikProps: FormikProps<IFormValues>) => boolean;
  forceSubmitEnabled?: (formikProps: FormikProps<IFormValues>) => boolean;
  onSubmit: (formValues: IFormValues) => void;
  renderSubForm?: () => ReactNode;
  isSubmitting: boolean;
  formApiErrors?: IFormApiErrorsTranslationPath;
  obatResourceDeleteProps?: IObatResourceDeleteProps;
  renderBottomButtons?: (formikProps: FormikProps<IFormValues>) => ReactNode;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps<IFormValues> = IOwnProps<IFormValues> & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => ({
  hasToReset: selectHasFormToReset(rootState),
});

const mapDispatchToProps = {
  toggleFormReset: toggleFormResetAction,
};

export const getForm = <IFormValues>() => {
  return connect<IStateProps, IDispatchProps, IOwnProps<IFormValues>, IRootState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component as new () => Component<IFormValues>);
};
