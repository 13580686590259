import { Action } from 'redux';

export const OPEN_NO_FREE_SEATS_MODAL = 'OPEN_NO_FREE_SEATS_MODAL';
export interface IOpenNoFreeSeatsModal extends Action<'OPEN_NO_FREE_SEATS_MODAL'> {
  payload: {};
}
export const openNoFreeSeatsModalAction = (): IOpenNoFreeSeatsModal => ({
  type: OPEN_NO_FREE_SEATS_MODAL,
  payload: {},
});

export const CLOSE_NO_FREE_SEATS_MODAL = 'CLOSE_NO_FREE_SEATS_MODAL';
export interface ICloseNoFreeSeatsModal extends Action<'CLOSE_NO_FREE_SEATS_MODAL'> {}
export const closeNoFreeSeatsModalAction = (): ICloseNoFreeSeatsModal => ({
  type: CLOSE_NO_FREE_SEATS_MODAL,
});

export type INoFreeSeatsModalActions = IOpenNoFreeSeatsModal | ICloseNoFreeSeatsModal;
