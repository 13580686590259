import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';
import { createGenerateClassName, jssPreset } from '@material-ui/styles';
import { Main } from 'components/Main';
import { create } from 'jss';
import React, { PureComponent, ReactNode } from 'react';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/createStore';
import { Router } from 'routing/Router';
import { ThemeProvider } from 'styled-components';

import MUITheme from './style/muiTheme';
import theme from './style/theme';

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // Define a custom insertion for injecting the JSS styles in the DOM
  insertionPoint: document.getElementById('jss-insertion-point') || undefined,
});

class App extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <MuiThemeProvider theme={MUITheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DragDropContextProvider backend={HTML5Backend}>
                    <Main>
                      <Router />
                    </Main>
                  </DragDropContextProvider>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </StylesProvider>
    );
  }
}

export default App;
