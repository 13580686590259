import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import {
  selectIsSeatMissing,
  selectIsWritePermissionMissing,
  selectRoutePage,
  selectRouteResource,
} from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { AddButton as Component } from './AddButton.component';

interface IOwnProps {
  withText?: boolean | string;
  isDisabled?: boolean;
  tooltipContent?: string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = IStateProps & IDispatchProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    routePage: selectRoutePage(rootState),
    routeObat: selectRouteResource(rrc.obat)(rootState),
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
  };
};

const mapDispatchToProps = {
  openFormDrawer: openFormDrawerSagaAction,
};

export const AddButton = connect(mapStateToProps, mapDispatchToProps)(Component);
