// tslint:disable:no-any

import { _camelCase, _get, _join, _reduce, _replace, _snakeCase } from 'libs/lodash';
import { toPlural } from 'libs/pluralize';
import { memoizeMulti } from 'utils/functions/memoizeMulti';
import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';
import { IRestResourceClass, rrc } from 'utils/strings/resourceClasses';
import { IRoutingMenu, rm } from 'utils/strings/routingMenus';

/*** UTILS ***/

export const getRouteParamKey = (resourceClass: IRestResourceClass) => {
  return _camelCase(resourceClass) + 'Id';
};

const menuRoutesPathLinks: { [menu in IRoutingMenu]: string[] } = _reduce(
  rm,
  (_pathLinks: any, menu: IRoutingMenu) => {
    if (menu === rm.home) {
      return { ..._pathLinks, home: [] };
    }

    const pluralMenu = toPlural(menu);

    const resourceClass = _get(rrc, _snakeCase(menu));

    const param = ':' + getRouteParamKey(resourceClass);

    return { ..._pathLinks, [menu]: [pluralMenu, param] };
  },
  {}
);

const buildRoutePaths = <T extends string>(
  menu: keyof typeof rm,
  pages: { [key in T]: T },
  defaultPage: keyof typeof pages,
  parent?: keyof typeof rm
): { [key in T]: string } => {
  return _reduce(
    pages,
    (paths: any, page: T) => {
      let urlChain = [...menuRoutesPathLinks[menu], page !== 'default' ? page : pages[defaultPage]];
      urlChain = parent ? [...menuRoutesPathLinks[parent], ...urlChain] : urlChain;

      return { ...paths, [page]: '/' + _join(urlChain, '/') };
    },
    {}
  );
};

const replaceRoutePathParams = memoizeMulti(
  (routeWithParams: string, paramsToReplace: Array<{ param: string; id: string }> | undefined = undefined): string => {
    if (!paramsToReplace) {
      return routeWithParams;
    }

    return _reduce(
      paramsToReplace,
      (route: string, paramToReplace: { param: string; id: string }): string => {
        return _replace(route, ':' + paramToReplace.param, paramToReplace.id);
      },
      routeWithParams
    );
  }
);

export const homeRoute = '/';
export const loginRoute = '/login';
export const page404Route = '/page404';

/*** HOME MENU ***/

const homeMenuPagesTuple = stringsTuple('myOrganizations', 'myProjects', 'myAccount');
export type IHomeMenuPage = typeof homeMenuPagesTuple[number];
export const homeMenuPages = mapStrings<IHomeMenuPage>(homeMenuPagesTuple);
export const homeMenuRoutePaths = buildRoutePaths<IHomeMenuPage>(rm.home, homeMenuPages, homeMenuPages.myProjects);
export const getHomeMenuPageRoute = (page: IHomeMenuPage, _resourceId: string = ''): string => {
  return replaceRoutePathParams(homeMenuRoutePaths[page]);
};

/*** ORGANIZATION MENU ***/

const organizationMenuPagesTuple = stringsTuple('organizationProjects', 'organizationPermissions', 'seats', 'default');
export type IOrganizationMenuPage = typeof organizationMenuPagesTuple[number];
export const organizationMenuPages = mapStrings<IOrganizationMenuPage>(organizationMenuPagesTuple);
export const organizationMenuRoutePaths = buildRoutePaths<IOrganizationMenuPage>(
  rm.organization,
  organizationMenuPages,
  organizationMenuPages.organizationProjects
);

export const getOrganizationMenuPageRoute = (page: IOrganizationMenuPage, organizationId: string): string => {
  return replaceRoutePathParams(organizationMenuRoutePaths[page], [
    { param: getRouteParamKey(rrc.organization), id: organizationId },
  ]);
};

/*** PROJECT MENU ***/

const projectMenuPagesTuple = stringsTuple(
  'obats',
  'geometries',
  'weathers',
  'simulationGroups',
  'projectPermissions',
  'default'
);
export type IProjectMenuPage = typeof projectMenuPagesTuple[number];
export const projectMenuPages = mapStrings<IProjectMenuPage>(projectMenuPagesTuple);
export const projectMenuRoutePaths = buildRoutePaths<IProjectMenuPage>(
  rm.project,
  projectMenuPages,
  projectMenuPages.geometries
);

export const getProjectMenuPageRoute = (page: IProjectMenuPage, projectId: string): string => {
  return replaceRoutePathParams(projectMenuRoutePaths[page], [{ param: getRouteParamKey(rrc.project), id: projectId }]);
};

/*** OBAT MENU ***/

const obatMenuPagesTuple = stringsTuple(
  'yearProfiles',
  'calendars',
  'openings',
  'constructions',
  'schedules',
  'surfaceGroups',
  'bridges',
  'outputZoneGroups',
  'scripts',
  'systems',
  'activityZoneGroups',
  'variants',
  'materials',
  'uses',
  'projection',
  'efEp',
  'ePlusParameters',
  'default'
);
export type IObatMenuPage = typeof obatMenuPagesTuple[number];
export const obatMenuPages = mapStrings<IObatMenuPage>(obatMenuPagesTuple);
export const obatMenuRoutePaths = buildRoutePaths<IObatMenuPage>(
  rm.obat,
  obatMenuPages,
  obatMenuPages.materials,
  rm.project
);

export const getObatMenuPageRoute = (page: IObatMenuPage, projectId: string, obatId: string): string => {
  return replaceRoutePathParams(obatMenuRoutePaths[page], [
    { param: getRouteParamKey(rrc.project), id: projectId },
    { param: getRouteParamKey(rrc.obat), id: obatId },
  ]);
};

/*** GEOMETRY MENU ***/

const geometryMenuPagesTuple = stringsTuple('drawing2D', 'viewer3D', 'default');
export type IGeometryMenuPage = typeof geometryMenuPagesTuple[number];
export const geometryMenuPages = mapStrings<IGeometryMenuPage>(geometryMenuPagesTuple);
export const geometryMenuRoutePaths = buildRoutePaths<IGeometryMenuPage>(
  rm.geometry,
  geometryMenuPages,
  geometryMenuPages.viewer3D,
  rm.project
);

export const getGeometryMenuPageRoute = (page: IGeometryMenuPage, projectId: string, geometryId: string): string => {
  return replaceRoutePathParams(geometryMenuRoutePaths[page], [
    { param: getRouteParamKey(rrc.project), id: projectId },
    { param: getRouteParamKey(rrc.geometry), id: geometryId },
  ]);
};

/*** WEATHER MENU ***/

const weatherMenuPagesTuple = stringsTuple('weatherConfig', 'weatherData', 'default');
export type IWeatherMenulPage = typeof weatherMenuPagesTuple[number];
export const weatherMenuPages = mapStrings<IWeatherMenulPage>(weatherMenuPagesTuple);
export const weatherMenuRoutePaths = buildRoutePaths<IWeatherMenulPage>(
  rm.weather,
  weatherMenuPages,
  weatherMenuPages.weatherConfig,
  rm.project
);

export const getWeatherMenuPageRoute = (page: IWeatherMenulPage, projectId: string, weatherId: string): string => {
  return replaceRoutePathParams(weatherMenuRoutePaths[page], [
    { param: getRouteParamKey(rrc.project), id: projectId },
    { param: getRouteParamKey(rrc.weather), id: weatherId },
  ]);
};

/*** MONO SIMULATION GROUP MENU ***/

const monoSimulationGroupMenuPagesTuple = stringsTuple(
  'configMono',
  'monitorMono',
  'monthlyResultsMono',
  'hourlyResultsMono',
  'default'
);
export type IMonoSimulationGroupMenuPage = typeof monoSimulationGroupMenuPagesTuple[number];
export const monoSimulationGroupMenuPages = mapStrings<IMonoSimulationGroupMenuPage>(monoSimulationGroupMenuPagesTuple);
export const monoSimulationGroupMenuRoutePaths = buildRoutePaths<IMonoSimulationGroupMenuPage>(
  rm.monoSimulationGroup,
  monoSimulationGroupMenuPages,
  monoSimulationGroupMenuPages.configMono,
  rm.project
);

export const getMonoSimulationGroupMenuPageRoute = (
  page: IMonoSimulationGroupMenuPage,
  projectId: string,
  monoSimulationGroupId: string
): string => {
  return replaceRoutePathParams(monoSimulationGroupMenuRoutePaths[page], [
    { param: getRouteParamKey(rrc.project), id: projectId },
    { param: getRouteParamKey(rrc.mono_simulation_group), id: monoSimulationGroupId },
  ]);
};

/*** MULTI SIMULATION GROUP MENU ***/

const multiSimulationGroupMenuPagesTuple = stringsTuple(
  'configMulti',
  'monitorMulti',
  'monthlyResultsMulti',
  'hourlyResultsMulti',
  'default'
);
export type IMultiSimulationGroupMenuPage = typeof multiSimulationGroupMenuPagesTuple[number];
export const multiSimulationGroupMenuPages = mapStrings<IMultiSimulationGroupMenuPage>(
  multiSimulationGroupMenuPagesTuple
);
export const multiSimulationGroupMenuRoutePaths = buildRoutePaths<IMultiSimulationGroupMenuPage>(
  rm.multiSimulationGroup,
  multiSimulationGroupMenuPages,
  multiSimulationGroupMenuPages.configMulti,
  rm.project
);

export const getMultiSimulationGroupMenuPageRoute = (
  page: IMultiSimulationGroupMenuPage,
  projectId: string,
  multiSimulationGroupId: string
): string => {
  return replaceRoutePathParams(multiSimulationGroupMenuRoutePaths[page], [
    { param: getRouteParamKey(rrc.project), id: projectId },
    { param: getRouteParamKey(rrc.multi_simulation_group), id: multiSimulationGroupId },
  ]);
};
