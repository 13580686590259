// tslint:disable:no-any
import { Plot } from 'components/Plot';
import React, { PureComponent, ReactNode } from 'react';
import 'react-pivottable/pivottable.css';
// @ts-ignore
import PivotTableUI from 'react-pivottable/PivotTableUI';
// @ts-ignore
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
// @ts-ignore
import TableRenderers from 'react-pivottable/TableRenderers';
import theme from 'style/theme';
import styled from 'styled-components';

import { IProps } from './PivotTable.container';

interface IState {
  pivotState: any;
}

const PlotlyRenderers = createPlotlyRenderers(Plot);

export class PivotTable extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      pivotState: {},
    };
  }

  public componentDidUpdate(): void {
    const { forcePivotUpdate, toggleForcePivotUpdate, config } = this.props;
    if (forcePivotUpdate) {
      this.setState({ pivotState: { ...this.state.pivotState, ...config } });
      toggleForcePivotUpdate(false);
    }
  }

  public render(): ReactNode {
    const { config } = this.props;

    const onChange = (s: any): void => {
      this.setState({ pivotState: s });
    };

    return (
      <PivotTableContainer>
        <PivotTableUI
          {...config}
          onChange={onChange}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          plotlyOptions={{
            height: 550,
            font: {
              family: 'Source Sans Pro, sans-serif',
              color: theme.colors.font.dark,
              size: 12,
            },
            legend: {
              x: 0,
              y: -0.1,
              orientation: 'h',
            },
          }}
          {...this.state.pivotState} // compulsory so that the config can be modified on the flow
        />
      </PivotTableContainer>
    );
  }
}

const PivotTableContainer = styled.div`
  overflow: scroll;
  min-height: 460px;
`;
