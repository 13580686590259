// tslint:disable:no-any
import { IObatResource, IObatResourcesResponse } from 'types/Obat/ObatResource';
import { ITokens } from 'types/Tokens';
import { getObatApiUrl, IObatApiUrlParams } from 'utils/functions/getApiUrl/getObatApiUrl/getObatApiUrl';
import { gra } from 'utils/strings/requestActions';

import { apiEndpoints } from '../endPoints';

export const ObatResourcesRequests = {
  retrieveContents: async (accessToken: ITokens['access'], obatId: string, queryParams: any): Promise<IObatResource> =>
    apiEndpoints
      .obatContents(accessToken, obatId)
      .query(queryParams, true)
      .get()
      .json(),

  retrieve: async (accessToken: ITokens['access'], obatApiUrlParams: IObatApiUrlParams): Promise<IObatResource> => {
    const apiUrl = getObatApiUrl(accessToken, gra.retrieve, obatApiUrlParams);

    return apiUrl.get().json();
  },

  list: async (
    accessToken: ITokens['access'],
    obatApiUrlParams: IObatApiUrlParams
  ): Promise<IObatResourcesResponse> => {
    const apiUrl = getObatApiUrl(accessToken, gra.list, obatApiUrlParams);

    return apiUrl.get().json();
  },

  create: async (
    accessToken: ITokens['access'],
    obatApiUrlParams: IObatApiUrlParams,
    requestParams: any
  ): Promise<IObatResource> => {
    const apiUrl = getObatApiUrl(accessToken, gra.create, obatApiUrlParams);

    return apiUrl.post(requestParams).json();
  },

  copy: async (
    accessToken: ITokens['access'],
    obatApiUrlParams: IObatApiUrlParams,
    requestParams: any
  ): Promise<IObatResource> => {
    const apiUrl = getObatApiUrl(accessToken, gra.copy, obatApiUrlParams);

    return apiUrl.post(requestParams).json();
  },

  edit: async (
    accessToken: ITokens['access'],
    obatApiUrlParams: IObatApiUrlParams,
    requestParams: any
  ): Promise<IObatResource> => {
    const apiUrl = getObatApiUrl(accessToken, gra.edit, obatApiUrlParams);
    // temporary
    // const url = resourceClass === orc.day_profile ? `${initialResourceId}/from_text/` : `${initialResourceId}/`;

    return apiUrl.patch(requestParams).json();
  },

  editMulti: async (
    accessToken: ITokens['access'],
    obatApiUrlParams: IObatApiUrlParams,
    requestParamsList: any[]
  ): Promise<IObatResource[]> => {
    const apiUrl = getObatApiUrl(accessToken, gra.editMulti, obatApiUrlParams);

    return apiUrl.patch(requestParamsList).json();
  },

  delete: async (accessToken: ITokens['access'], obatApiUrlParams: IObatApiUrlParams): Promise<IObatResource> => {
    const apiUrl = getObatApiUrl(accessToken, gra.delete, obatApiUrlParams);

    return apiUrl.delete().res();
  },
};
