import Popover from '@material-ui/core/Popover';
import { _get } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { navigateRoute } from 'routing/navigateRoute';
import {
  geometryMenuPages,
  getGeometryMenuPageRoute,
  getHomeMenuPageRoute,
  getMonoSimulationGroupMenuPageRoute,
  getMultiSimulationGroupMenuPageRoute,
  getObatMenuPageRoute,
  getOrganizationMenuPageRoute,
  getProjectMenuPageRoute,
  getWeatherMenuPageRoute,
  homeMenuPages,
  monoSimulationGroupMenuPages,
  multiSimulationGroupMenuPages,
  obatMenuPages,
  organizationMenuPages,
  projectMenuPages,
  weatherMenuPages,
} from 'routing/routes';
import theme from 'style/theme';
import styled from 'styled-components';
import { IRestResource } from 'types/RestResource';
import { as } from 'utils/strings/alertStatus';
import { rrc } from 'utils/strings/resourceClasses';

import { IProps } from './BreadcrumbPopover.container';

export class BreadcrumbPopover extends PureComponent<IProps> {
  public render(): ReactNode {
    const { popoverAnchorEl, isOpen, closePopover, breadcrumbLevel } = this.props;

    const anchorEl = popoverAnchorEl as HTMLElement | null;

    return (
      <StyledPopover
        elevation={2}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <ListContainer>
          {_size(breadcrumbLevel.routeResourcesList) > 0 && (
            <StyledUl>
              {_map(breadcrumbLevel.routeResourcesList, (restResource: IRestResource) => (
                <li key={restResource.id} onMouseDown={this.redirect(restResource)}>
                  {restResource.name}
                </li>
              ))}
            </StyledUl>
          )}
          {_size(breadcrumbLevel.routeResourcesList) === 0 && (
            <StyledUl>
              <li>-</li>
            </StyledUl>
          )}
        </ListContainer>
      </StyledPopover>
    );
  }

  private redirect = (clickedRestResource: IRestResource) => {
    return (e: React.MouseEvent): void => {
      const { breadcrumbLevel, closePopover, urlPage } = this.props;

      let route = '';

      // tslint:disable-next-line:no-any
      let landingPage: any;

      switch (breadcrumbLevel.resourceClass) {
        case rrc.organization:
          route = getOrganizationMenuPageRoute(_get(organizationMenuPages, urlPage), clickedRestResource.id);
          break;

        case rrc.project:
          if (urlPage === projectMenuPages.geometries || _has(geometryMenuPages, urlPage)) {
            route = getProjectMenuPageRoute(projectMenuPages.geometries, clickedRestResource.id);
          } else if (urlPage === projectMenuPages.weathers || _has(weatherMenuPages, urlPage)) {
            route = getProjectMenuPageRoute(projectMenuPages.weathers, clickedRestResource.id);
          } else if (urlPage === projectMenuPages.simulationGroups || _has(monoSimulationGroupMenuPages, urlPage)) {
            route = getProjectMenuPageRoute(projectMenuPages.simulationGroups, clickedRestResource.id);
          } else {
            route = getProjectMenuPageRoute(projectMenuPages.obats, clickedRestResource.id);
          }
          break;

        case rrc.geometry:
          landingPage = !clickedRestResource.floorspace ? geometryMenuPages.default : _get(geometryMenuPages, urlPage);

          route = getGeometryMenuPageRoute(landingPage, clickedRestResource.project.id, clickedRestResource.id);
          break;
        case rrc.obat:
          route = getObatMenuPageRoute(
            _get(obatMenuPages, urlPage),
            clickedRestResource.project.id,
            clickedRestResource.id
          );
          break;
        case rrc.weather:
          route = getWeatherMenuPageRoute(
            _get(weatherMenuPages, urlPage),
            clickedRestResource.project.id,
            clickedRestResource.id
          );
          break;
        case rrc.mono_simulation_group:
        case rrc.multi_simulation_group:
          if (clickedRestResource.model === 'monosimulationgroup') {
            landingPage = monoSimulationGroupMenuPages.default;

            if (_includes(urlPage, 'config')) {
              landingPage = monoSimulationGroupMenuPages.configMono;
            } else if (_includes(urlPage, 'monitor')) {
              landingPage = monoSimulationGroupMenuPages.monitorMono;
            } else if (_includes(urlPage, 'monthly')) {
              landingPage =
                clickedRestResource.status === as.success
                  ? monoSimulationGroupMenuPages.monthlyResultsMono
                  : landingPage;
            } else if (_includes(urlPage, 'hourly')) {
              landingPage =
                clickedRestResource.status === as.success
                  ? monoSimulationGroupMenuPages.hourlyResultsMono
                  : landingPage;
            }

            route = getMonoSimulationGroupMenuPageRoute(
              landingPage,
              clickedRestResource.project.id,
              clickedRestResource.id
            );
          } else if (clickedRestResource.model === 'multisimulationgroup') {
            landingPage = multiSimulationGroupMenuPages.default;

            if (_includes(urlPage, 'config')) {
              landingPage = multiSimulationGroupMenuPages.configMulti;
            } else if (_includes(urlPage, 'monitor')) {
              landingPage = multiSimulationGroupMenuPages.monitorMulti;
            } else if (_includes(urlPage, 'monthly')) {
              landingPage =
                clickedRestResource.success_nb > 0 ? multiSimulationGroupMenuPages.monthlyResultsMulti : landingPage;
            } else if (_includes(urlPage, 'hourly')) {
              landingPage =
                clickedRestResource.success_nb > 0 ? multiSimulationGroupMenuPages.hourlyResultsMulti : landingPage;
            }

            route = getMultiSimulationGroupMenuPageRoute(
              landingPage,
              clickedRestResource.project.id,
              clickedRestResource.id
            );
          }
          break;
        default:
          route = getHomeMenuPageRoute(homeMenuPages.myProjects);
          break;
      }
      navigateRoute(route, e);
      closePopover();
    };
  };
}

const ListContainer = styled.div`
  min-width: 200px;
  width: auto;
  max-height: 265px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledPopover = styled(Popover)`
  > div {
    overflow: initial !important;
    margin-top: 6px;
    margin-left: -7px;
  }
` as typeof Popover;

const StyledUl = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style: none;
  > li {
    cursor: pointer;
    padding: 4px 8px;
    &:hover {
      background: ${theme.colors.listItemHover};
    }
  }
`;
