import { connect } from 'react-redux';
import { deleteObatResourceSagaAction } from 'redux/obatResources/actions';
import { selectObatResourcesIdsList } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { IConstruction } from 'types/Obat/Construction';
import { ILayer } from 'types/Obat/Layer';
import { orc, rrc } from 'utils/strings/resourceClasses';

import { LayerForm as LayerFormComponent } from './LayerForm.component';

interface IOwnProps {
  close?: () => void;
  construction: IConstruction;
  layer?: ILayer;
  titleIndex?: number;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IInjectedProps, IOwnProps {}

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return {
    layerToEdit: ownProps.layer,
    routeObat: selectRouteResource(rrc.obat)(rootState),
    materialOptions: selectObatResourcesIdsList(orc.material)(rootState),
  };
};

const mapDispatchToProps = {
  deleteObatResource: deleteObatResourceSagaAction,
};

export type IInjectedProps = IStateProps & IDispatchProps;

export const LayerForm = connect(mapStateToProps, mapDispatchToProps)(LayerFormComponent);
