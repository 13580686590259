import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { AlertIcon } from 'components/Icons';
import { _map, _toLower } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { IDegreeDaysTableData, IDegreeDaysTableRowData } from 'redux/restResources/detail/weather/selectors';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';

interface IProps {
  tableData: IDegreeDaysTableData;
  isGeneric: boolean;
}

export class DegreeDaysTable extends PureComponent<IProps> {
  public render(): ReactNode {
    const { tableData, isGeneric } = this.props;

    return (
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCell>{$t('month')}</StyledTableCell>
            <StyledTableCell align="right">{$t('highDegreeDays')}</StyledTableCell>
            <StyledTableCell align="right">{$t('coolingDegreeDays')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_map(tableData, (row: IDegreeDaysTableRowData, rowIndex: number) => (
            <TableRow key={row.month}>
              <StyledTableCell component="th" scope="row">
                <Month>
                  <span>{$t(_toLower(row.month))}</span>
                  {isGeneric && rowIndex === 1 && (
                    <AlertIcon
                      status={as.warning}
                      tooltipContent={$t('weatherLeapYearDegreeDaysAlert')}
                      tooltipPlacement={'right'}
                      iconSize={14}
                    />
                  )}
                </Month>
              </StyledTableCell>
              <StyledTableCell align="right">{row.hdd}</StyledTableCell>
              <StyledTableCell align="right">{row.cdd}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    );
  }
}

const StyledTableCell = (styled(TableCell)`
  padding: 8px 15px;
` as unknown) as typeof TableCell;

const StyledTable = (styled(Table)`
  margin: 15px 0;
` as unknown) as typeof Table;

const Month = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-left: 8px;
    margin-bottom: -4px;
  }
`;
