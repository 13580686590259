import { FormikProps } from 'formik';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import {
  copyObatResourceSagaAction,
  createObatResourceSagaAction,
  editObatResourceSagaAction,
} from 'redux/obatResources/actions';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { IObatResource } from 'types/Obat/ObatResource';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { gra } from 'utils/strings/requestActions';
import { IObatResourceClass } from 'utils/strings/resourceClasses';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { ObatResourceForm as Component } from './ObatResourceForm.component';

interface IOwnProps<IResourceFormValues, IResourceRequestParams> {
  initialObatResource: IObatResource | undefined;
  renderForm: (formikProps: FormikProps<IResourceFormValues>) => ReactNode;
  getInitialFormValues: () => IResourceFormValues;
  getRequestParams?: (finalFormValues: IResourceFormValues) => IResourceRequestParams;
  getValidationSchema: () => yup.ObjectSchema /*<IResourceFormValues>*/;
  renderSubForm?: () => ReactNode;
  resourceClass: IObatResourceClass;
  forceSubmitDisabled?: (formikProps: FormikProps<IResourceFormValues>) => boolean;
  deleteResource?: () => void;
  isCopy?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps<IResourceFormValues, IResourceRequestParams> = IOwnProps<
  IResourceFormValues,
  IResourceRequestParams
> &
  IInjectedProps;

const mapStateToProps = <IResourceFormValues, IResourceRequestParams>(
  rootState: IRootState,
  ownProps: IOwnProps<IResourceFormValues, IResourceRequestParams>
) => {
  const { initialObatResource, resourceClass } = ownProps;

  return {
    routeObat: selectRouteResource(rrc.obat)(rootState),

    isDeleting:
      !!initialObatResource &&
      // @ts-ignore
      selectIsApiRequestPending(getGenericApiRequestKey(gra.delete, resourceClass, initialObatResource.id))(rootState),
  };
};

const mapDispatchToProps = {
  createObatResource: createObatResourceSagaAction,
  editObatResource: editObatResourceSagaAction,
  copyObatResource: copyObatResourceSagaAction,
};

export const getObatResourceForm = <IResourceFormValues, IResourceRequestParams>() => {
  return connect<IStateProps, IDispatchProps, IOwnProps<IResourceFormValues, IResourceRequestParams>, IRootState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component as new () => Component<IResourceFormValues, IResourceRequestParams>);
};
