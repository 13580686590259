import { _filter, _size } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { IRootState } from 'redux/reducer';
import { IOrganizationListed } from 'types/Organization';
import { IUserBase } from 'types/User';
import { ISelectOptions } from 'utils/functions/forms/getSelectOptions/getSelectOptions';

export const selectMyUser = (rootState: IRootState): IUserBase | undefined => {
  return rootState.me.user;
};

export const selectMyFullName = (rootState: IRootState): string | undefined => {
  const myUser = selectMyUser(rootState);

  if (myUser) {
    return `${myUser.first_name} ${myUser.last_name}`;
  }
};

export const selectMyLanguage = (rootState: IRootState): string | undefined => {
  return rootState.me.user ? rootState.me.user.language : undefined;
};

export const selectMyOrganizations = (rootState: IRootState): IOrganizationListed[] => {
  return rootState.me.organizations;
};

export const selectMyMemberOrganizationOptions = (rootState: IRootState): ISelectOptions => {
  const userMemberOrganizations = _filter(
    rootState.me.organizations,
    (organization: IOrganizationListed): boolean => organization.is_member
  );

  return _map(userMemberOrganizations, (organization: IOrganizationListed) => {
    return {
      label: organization.name,
      value: organization.id,
      isSeatMissing: !organization.is_activated,
    };
  });
};

export const selectIsNoOrganizationMember = (rootState: IRootState): boolean => {
  const myMemberOrganizationsOptions = selectMyMemberOrganizationOptions(rootState);

  return _size(myMemberOrganizationsOptions) === 0;
};
