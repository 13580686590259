import { RefObject } from 'react';
import { connect } from 'react-redux';
import { clearAllApiRequestsAction } from 'redux/apiRequests/actions';
import { closeFormDrawerAction, collapseFormDrawerAction, updateDrawerWidthAction } from 'redux/form/actions';
import { selectIsFormDrawerOpen } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { IDrawerWidth } from 'utils/configs/drawerWidths';
import { IFormKey } from 'utils/strings/formKeys';
import { IApiResourceClass } from 'utils/strings/resourceClasses';

import { FormDrawer as Component } from './FormDrawer.component';

interface IOwnProps<IResourceClass> {
  formKey: IFormKey;
  width: IDrawerWidth;
  title?: string;
  formFocusRef?: RefObject<HTMLInputElement>;
  resourceClass?: IResourceClass;
  subFormResources?: IApiResourceClass[];
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps<IResourceClass> = IOwnProps<IResourceClass> & IInjectedProps;

const mapStateToProps = <IResourceClass>(rootState: IRootState, ownProps: IOwnProps<IResourceClass>) => {
  return { isFormDrawerOpen: selectIsFormDrawerOpen(ownProps.formKey)(rootState) };
};

const mapDispatchToProps = {
  closeFormDrawer: closeFormDrawerAction,
  collapseForm: collapseFormDrawerAction,
  clearAllApiRequests: clearAllApiRequestsAction,
  updateDrawerWidth: updateDrawerWidthAction,
};

export const getFormDrawer = <IResourceClass>() => {
  return connect<IStateProps, IDispatchProps, IOwnProps<IResourceClass>, IRootState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component as new () => Component<IResourceClass>);
};
