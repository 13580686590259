import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import { _isEmpty } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { IVariant } from 'types/Obat/Variant';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { orc } from 'utils/strings/resourceClasses';

import { ModificationsCard } from '../ModificationsCard';
import { VariantForm } from '../VariantForm';

export class VariantsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'variants'}
        resourceClass={orc.variant}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={VariantForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [{ id: 'name', resourceField: 'ref', gridItemSize: 12 }];

  private renderCardColumnData = (variant: IVariant, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return variant.ref;
    }
  };

  private renderCardDetail = (variant: IVariant): ReactNode => {
    if (!_isEmpty(variant.modifications_contents)) {
      return <ModificationsCard modifications={variant.modifications_contents} />;
    }
  };
}
