import { _get } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { IRootState } from 'redux/reducer';
import { $t } from 'utils/functions/translate';

export const selectIsObatResourceCommitmentsModalOpen = (rootState: IRootState): boolean => {
  return rootState.modals.obatResourceCommitmentsModal.isOpen;
};

export const selectObatResourceCommitmentTitle = (rootState: IRootState): string => {
  return $t(
    rootState.modals.obatResourceCommitmentsModal.editionField
      ? 'editCommitmentModalTitle'
      : 'deleteCommitmentModalTitle'
  );
};

export const selectObatResourceCommitments = (rootState: IRootState): { [resourceKey: string]: string[] } => {
  const { obatResource, editionField } = rootState.modals.obatResourceCommitmentsModal;

  if (!obatResource) {
    return {};
  }

  const { commitments } = obatResource;

  if (editionField) {
    return _has(commitments.update, editionField) ? _get(commitments.update, editionField) : {};
  }

  return obatResource.commitments.delete;
};
