import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { IEPlusParameters } from 'types/Obat/EPlusParameters';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t, $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { EPlusParametersForm } from '../EPlusParametersForm';
import { EPlusParametersTable } from '../EPlusParametersTable/EPlusParametersTable.component';

export class EPlusParametersPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'ePlusParameters'}
        resourceClass={orc.e_plus_parameters}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={EPlusParametersForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 6 },
    { id: 'value', resourceField: 'value', gridItemSize: 6 },
  ];

  private renderCardColumnData = (ePlusParameters: IEPlusParameters, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return $t(ePlusParameters.ref);
      case 'value':
        return $tEnums(ePlusParameters.value || '');
    }
  };

  private renderCardDetail = (ePlusParameters: IEPlusParameters): ReactNode => {
    return <EPlusParametersTable ePlusParameters={ePlusParameters} />;
  };
}
