import { _forEach } from 'libs/lodash';
import { _get } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { getRouteParamKey } from 'routing/routes';
import { navigationResourcesHierarchy } from 'utils/configs/resourcesHierarchy/restResourcesHierarchy';
import { IRestResourceClass } from 'utils/strings/resourceClasses';

export type IRouteResourcesToUpdate = Array<{ resourceId: string; resourceClass: IRestResourceClass }>;

export interface IRouteParams {
  [param: string]: string;
}

export const getResourcesToUpdateFromRouteParams = (routeParams: IRouteParams): IRouteResourcesToUpdate => {
  return _reduce(
    navigationResourcesHierarchy,
    (
      routeResourcesToUpdate: IRouteResourcesToUpdate,
      updateOrderLevel: IRestResourceClass[]
    ): IRouteResourcesToUpdate => {
      let resourceToUpdate;
      _forEach(updateOrderLevel, (resourceClass: IRestResourceClass) => {
        const paramIdCode = getRouteParamKey(resourceClass);
        if (_has(routeParams, paramIdCode)) {
          resourceToUpdate = { resourceClass, resourceId: _get(routeParams, paramIdCode) };
        }
      });

      return resourceToUpdate ? [...routeResourcesToUpdate, resourceToUpdate] : routeResourcesToUpdate;
    },
    []
  );
};
