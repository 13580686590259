import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';

interface IProps {
  onMouseDown: (e: React.MouseEvent) => void;
}

export class HyperLink extends PureComponent<IProps> {
  public render(): ReactNode {
    const { onMouseDown, children } = this.props;

    return <LinkContainer onMouseDown={onMouseDown}>{children}</LinkContainer>;
  }
}

const LinkContainer = styled.span`
  color: ${theme.colors.link};
  cursor: pointer;
  word-break: break-all;
  &:hover {
    text-decoration: underline;
  }
`;
