import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';

import { enrichCalendars } from './enrichCalendars';
import { enrichConstructions } from './enrichConstructions';
import { enrichEfEp } from './enrichEfEp';
import { enrichEPlusParameters } from './enrichEPlusParameters';
import { enrichOpenings } from './enrichOpenings';
import { enrichSchedules } from './enrichSchedules';
import { enrichUses } from './enrichUses';
import { enrichVariants } from './enrichVariants';
import { enrichYearProfiles } from './enrichYearProfiles';

export const enrichObatData = (obatContents: IObatContents, tableRef?: string): IObatData => {
  // all tables mode, take care of the order of the operations
  if (!tableRef) {
    let enrichedObatContents = { ...obatContents };
    enrichedObatContents = { ...enrichedObatContents, data: enrichConstructions(enrichedObatContents) };
    enrichedObatContents = { ...enrichedObatContents, data: enrichOpenings(enrichedObatContents) };
    enrichedObatContents = { ...enrichedObatContents, data: enrichCalendars(enrichedObatContents) };
    enrichedObatContents = { ...enrichedObatContents, data: enrichYearProfiles(enrichedObatContents) };
    enrichedObatContents = { ...enrichedObatContents, data: enrichSchedules(enrichedObatContents) };
    enrichedObatContents = { ...enrichedObatContents, data: enrichVariants(enrichedObatContents) };
    enrichedObatContents = { ...enrichedObatContents, data: enrichUses(enrichedObatContents) };
    enrichedObatContents = { ...enrichedObatContents, data: enrichEfEp(enrichedObatContents) };
    enrichedObatContents = { ...enrichedObatContents, data: enrichEPlusParameters(enrichedObatContents) };

    return enrichedObatContents.data;
  }

  // specific table mode
  switch (tableRef) {
    case 'construction':
      return enrichConstructions(obatContents);
    case 'calendar':
      return enrichCalendars(obatContents);
    case 'schedule':
      return enrichSchedules(obatContents);
    case 'year_profile':
      return enrichYearProfiles(obatContents);
    case 'variant':
      return enrichVariants(obatContents);
    case 'use':
      return enrichUses(obatContents);
    case 'ef_ep':
      return enrichEfEp(obatContents);
    case 'e_plus_parameters':
      return enrichEPlusParameters(obatContents);
    default:
      throw new Error('should not be here');
  }
};
