import Popover from '@material-ui/core/Popover';
import { Button } from 'components/Buttons/Button.component';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { _map } from 'libs/lodash';
import { _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { MoreVertical } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { pa } from 'utils/strings/permissionAlerts';

export interface ITool {
  text: string;
  onClick: (e: React.MouseEvent) => void;
  isWritePermissionMissing?: boolean;
  isSeatMissing?: boolean;
  isDisabled?: boolean;
  tooltipContent?: string;
}

interface IProps {
  tools: ITool[];
}

interface IState {
  popoverAnchorEl: (EventTarget & Element) | null;
  isPopoverOpen: boolean;
}

export class ToolsButton extends PureComponent<IProps, IState> {
  public state = {
    popoverAnchorEl: null,
    isPopoverOpen: false,
  };

  public render = (): ReactNode => {
    const { tools } = this.props;
    const { isPopoverOpen, popoverAnchorEl } = this.state;

    if (_size(tools) === 0) {
      return null;
    }

    return (
      <>
        <Button
          Icon={<MoreVertical />}
          onClick={this.openPopover}
          tooltipProps={{ content: $t('moreTools'), placement: 'left' }}
        />
        <StyledPopover
          elevation={2}
          open={isPopoverOpen}
          anchorEl={popoverAnchorEl}
          onClose={this.closePopover}
          anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        >
          <ToolsContainer>
            <StyledUl>
              {_map(
                tools,
                (tool: ITool): ReactNode => {
                  const listItem = (
                    <StyledLi
                      onMouseDown={this.onToolClick(tool)}
                      isDisabled={tool.isWritePermissionMissing || tool.isSeatMissing || tool.isDisabled}
                    >
                      {tool.text}
                    </StyledLi>
                  );

                  const tooltipContent = tool.isWritePermissionMissing
                    ? $t(pa.writePermissionRequired)
                    : tool.isSeatMissing
                    ? $t(pa.seatRequired)
                    : tool.isDisabled
                    ? tool.tooltipContent
                    : '';

                  return (
                    <div key={tool.text}>
                      <SimpleTooltip content={tooltipContent} placement={'right'}>
                        {listItem}
                      </SimpleTooltip>
                    </div>
                  );
                }
              )}
            </StyledUl>
          </ToolsContainer>
        </StyledPopover>
      </>
    );
  };

  private onToolClick = (tool: ITool) => {
    return (e: React.MouseEvent) => {
      if (!(tool.isWritePermissionMissing || tool.isSeatMissing || tool.isDisabled)) {
        tool.onClick(e);
        this.closePopover();
      }
    };
  };

  private openPopover = (e: React.MouseEvent): void => {
    this.setState({ isPopoverOpen: true, popoverAnchorEl: e.currentTarget });
  };

  private closePopover = (): void => {
    this.setState({ isPopoverOpen: false, popoverAnchorEl: null });
  };
}

const ToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-height: 265px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledPopover = styled(Popover)`
  > div {
    overflow: initial !important;
    margin-top: 6px;
    margin-left: -7px;
  }
` as typeof Popover;

const StyledUl = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style: none;
`;

const StyledLi = styled.li`
  font-size: 110%;
  padding: 8px 12px;
  cursor: ${(props: { isDisabled?: boolean }): string => (!props.isDisabled ? 'pointer' : '')};
  color: ${(props: { isDisabled?: boolean }): string => (props.isDisabled ? theme.colors.button.disabled : 'black')};
  &:hover {
    background: ${theme.colors.listItemHover};
  }
`;
