import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { _filter } from 'libs/lodash';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { $t } from 'utils/functions/translate';

import { IProps } from './EfEpTable.container';

export class EfEpTable extends PureComponent<IProps> {
  public render(): ReactNode {
    const { efEp } = this.props;

    return (
      <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '25%' }} />
              <TableCell align="center" style={{ width: '25%' }}>
                {$t('primaryEnergyFactor')}
              </TableCell>
              <TableCell align="center" style={{ width: '25%' }}>
                {$t('co2EmissionFactorKgCo2KWh')}
              </TableCell>
              <TableCell style={{ width: '25%' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {$t('electricity')}
              </TableCell>
              <TableCell align="center">{efEp.energy_coefficients_electricity}</TableCell>
              <TableCell align="center">{efEp.energy_co2_content_electricity}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {$t('gas')}
              </TableCell>
              <TableCell align="center">{efEp.energy_coefficients_gas}</TableCell>
              <TableCell align="center">{efEp.energy_co2_content_gas}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {$t('heatingDistrict')}
              </TableCell>
              <TableCell align="center">{efEp.energy_coefficients_heating_district}</TableCell>
              <TableCell align="center">{efEp.energy_co2_content_heating_district}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {$t('coolingDistrict')}
              </TableCell>
              <TableCell align="center">{efEp.energy_coefficients_cooling_district}</TableCell>
              <TableCell align="center">{efEp.energy_co2_content_cooling_district}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {$t('biomass')}
              </TableCell>
              <TableCell align="center">{efEp.energy_coefficients_biomass}</TableCell>
              <TableCell align="center">{efEp.energy_co2_content_biomass}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {$t('fuelOil')}
              </TableCell>
              <TableCell align="center">{efEp.energy_coefficients_fuel_oil}</TableCell>
              <TableCell align="center">{efEp.energy_co2_content_fuel_oil}</TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
