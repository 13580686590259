// tslint:disable:no-any

import { Action } from 'redux';
import { IHeatmap } from 'types/Obat/Calendar';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatResourceClass } from 'utils/strings/resourceClasses';

/* _____ FETCH_OBAT_CONTENTS_SAGA_ACTION _____ */

export const FETCH_OBAT_CONTENTS_SAGA_ACTION = 'FETCH_OBAT_CONTENTS_SAGA_ACTION';
export interface IFetchObatContentsSagaAction extends Action<'FETCH_OBAT_CONTENTS_SAGA_ACTION'> {
  payload: {
    obatId: string;
    variantId?: string;
  };
}
export const fetchObatContentsSagaAction = (obatId: string, variantId?: string): IFetchObatContentsSagaAction => ({
  type: FETCH_OBAT_CONTENTS_SAGA_ACTION,
  payload: {
    obatId,
    variantId,
  },
});

/* _____ UPDATE_OBAT_DB_ACTION _____ */

export const UPDATE_OBAT_CONTENTS_ACTION = 'UPDATE_OBAT_CONTENTS_ACTION';
export interface IUpdateObatContentsAction extends Action<'UPDATE_OBAT_CONTENTS_ACTION'> {
  payload: {
    obatContents: IObatContents;
  };
}
export const updateObatContentsAction = (obatContents: IObatContents): IUpdateObatContentsAction => ({
  type: UPDATE_OBAT_CONTENTS_ACTION,
  payload: {
    obatContents,
  },
});

/* _____ CLEAR_OBAT_CONTENTS_ACTION _____ */

export const CLEAR_OBAT_CONTENTS_ACTION = 'CLEAR_OBAT_CONTENTS_ACTION';
export interface IClearObatContentsAction extends Action<'CLEAR_OBAT_CONTENTS_ACTION'> {}
export const clearObatContentsAction = (): IClearObatContentsAction => ({
  type: CLEAR_OBAT_CONTENTS_ACTION,
});

/* _____ CLEAR_ALL_HEATMAPS_ACTION _____ */

export const CLEAR_ALL_HEATMAPS_ACTION = 'CLEAR_HEATMAPS_ACTION';
export interface IClearAllHeatmapsAction extends Action<'CLEAR_HEATMAPS_ACTION'> {}
export const clearAllHeatmapsAction = (): IClearAllHeatmapsAction => ({
  type: CLEAR_ALL_HEATMAPS_ACTION,
});

/* _____ CLEAR_SINGLE_HEATMAP_ACTION _____ */

export const CLEAR_SINGLE_HEATMAP_ACTION = 'CLEAR_SINGLE_HEATMAP_ACTION';
export interface IClearSingleHeatmapAction extends Action<'CLEAR_SINGLE_HEATMAP_ACTION'> {
  payload: {
    calendarRef: string;
  };
}
export const clearSingleHeatmapAction = (calendarRef: string): IClearSingleHeatmapAction => ({
  type: CLEAR_SINGLE_HEATMAP_ACTION,
  payload: {
    calendarRef,
  },
});

/* _____ UPDATE_HEATMAP_ACTION _____ */

export const UPDATE_HEATMAP_ACTION = 'UPDATE_HEATMAP_ACTION';
export interface IUpdateHeatmapAction extends Action<'UPDATE_HEATMAP_ACTION'> {
  payload: {
    calendarRef: string;
    heatmap: IHeatmap;
  };
}
export const updateHeatmapAction = (calendarRef: string, heatmap: IHeatmap): IUpdateHeatmapAction => ({
  type: UPDATE_HEATMAP_ACTION,
  payload: {
    calendarRef,
    heatmap,
  },
});

/* _____ CREATE_OBAT_RESOURCE_SAGA_ACTION _____ */

export const CREATE_OBAT_RESOURCE_SAGA_ACTION = 'CREATE_OBAT_RESOURCE_SAGA_ACTION';
export interface ICreateObatResourceSagaAction extends Action<'CREATE_OBAT_RESOURCE_SAGA_ACTION'> {
  payload: {
    resourceClass: IObatResourceClass;
    requestParams: any;
    obatId: string;
  };
}
export const createObatResourceSagaAction = (
  resourceClass: IObatResourceClass,
  requestParams: any,
  obatId: string
): ICreateObatResourceSagaAction => ({
  type: CREATE_OBAT_RESOURCE_SAGA_ACTION,
  payload: {
    resourceClass,
    requestParams,
    obatId,
  },
});

/* _____ COPY_OBAT_RESOURCE_SAGA_ACTION _____ */

export const COPY_OBAT_RESOURCE_SAGA_ACTION = 'COPY_OBAT_RESOURCE_SAGA_ACTION';
export interface ICopyObatResourceSagaAction extends Action<'COPY_OBAT_RESOURCE_SAGA_ACTION'> {
  payload: {
    resourceId: string;
    resourceClass: IObatResourceClass;
    requestParams: any;
    obatId: string;
  };
}
export const copyObatResourceSagaAction = (
  resourceId: string,
  resourceClass: IObatResourceClass,
  requestParams: any,
  obatId: string
): ICopyObatResourceSagaAction => ({
  type: COPY_OBAT_RESOURCE_SAGA_ACTION,
  payload: {
    resourceId,
    resourceClass,
    requestParams,
    obatId,
  },
});

/* _____ EDIT_OBAT_RESOURCE_SAGA_ACTION _____ */

export const EDIT_OBAT_RESOURCE_SAGA_ACTION = 'EDIT_OBAT_RESOURCE_SAGA_ACTION';
export interface IEditObatResourceSagaAction extends Action<'EDIT_OBAT_RESOURCE_SAGA_ACTION'> {
  payload: {
    initialResourceId: string;
    resourceClass: IObatResourceClass;
    requestParams: any;
    obatId: string;
  };
}
export const editObatResourceSagaAction = (
  initialResourceId: string,
  resourceClass: IObatResourceClass,
  requestParams: any,
  obatId: string
): IEditObatResourceSagaAction => ({
  type: EDIT_OBAT_RESOURCE_SAGA_ACTION,
  payload: {
    initialResourceId,
    resourceClass,
    requestParams,
    obatId,
  },
});

/* _____ EDIT_MULTI_OBAT_RESOURCES_SAGA_ACTION _____ */

export const EDIT_MULTI_OBAT_RESOURCES_SAGA_ACTION = 'EDIT_MULTI_OBAT_RESOURCES_SAGA_ACTION';
export interface IEditMultiObatResourcesSagaAction extends Action<'EDIT_MULTI_OBAT_RESOURCES_SAGA_ACTION'> {
  payload: {
    requestParamsList: any[];
    obatId: string;
    resourceClass: IObatResourceClass;
  };
}
export const editMultiObatResourcesSagaAction = (
  resourceClass: IObatResourceClass,
  requestParamsList: any[],
  obatId: string
): IEditMultiObatResourcesSagaAction => ({
  type: EDIT_MULTI_OBAT_RESOURCES_SAGA_ACTION,
  payload: {
    resourceClass,
    requestParamsList,
    obatId,
  },
});

/* _____ DELETE_OBAT_RESOURCE_SAGA_ACTION _____ */

export const DELETE_OBAT_RESOURCE_SAGA_ACTION = 'DELETE_OBAT_RESOURCE_SAGA_ACTION';
export interface IDeleteObatResourceSagaAction extends Action<'DELETE_OBAT_RESOURCE_SAGA_ACTION'> {
  payload: {
    resourceClass: IObatResourceClass;
    resourceId: string;
    obatId: string;
  };
}
export const deleteObatResourceSagaAction = (
  resourceClass: IObatResourceClass,
  resourceId: string,
  obatId: string
): IDeleteObatResourceSagaAction => ({
  type: DELETE_OBAT_RESOURCE_SAGA_ACTION,
  payload: {
    resourceClass,
    resourceId,
    obatId,
  },
});

/* _____ UPDATE_REF_TO_SCROLL_TO_ACTION _____ */

export const UPDATE_REF_TO_SCROLL_TO_ACTION = 'UPDATE_REF_TO_SCROLL_TO_ACTION';
export interface IUpdateRefToScrollToAction extends Action<'UPDATE_REF_TO_SCROLL_TO_ACTION'> {
  payload: {
    refToScrollTo?: string;
  };
}
export const updateRefToScrollToAction = (refToScrollTo: string | undefined): IUpdateRefToScrollToAction => ({
  type: UPDATE_REF_TO_SCROLL_TO_ACTION,
  payload: {
    refToScrollTo,
  },
});

export type IObatResourcesActions =
  | IUpdateObatContentsAction
  | IClearObatContentsAction
  | IClearAllHeatmapsAction
  | IClearSingleHeatmapAction
  | IUpdateHeatmapAction
  | IUpdateRefToScrollToAction;
