import { connect } from 'react-redux';
import { IDayGroup } from 'types/Obat/DayGroup';

import { DayGroupCard as DayGroupCardComponent } from './DayGroupCard.component';

interface IOwnProps {
  dayGroup: IDayGroup;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IInjectedProps = IStateProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = () => {
  return {};
};

export const DayGroupCard = connect(mapStateToProps)(DayGroupCardComponent);
