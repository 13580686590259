import {
  ISimulationListed,
  ISimulationMetadata,
  ISimulationRetrieved,
  ISimulationSeries,
  ISimulationSeriesData,
} from 'types/SimulationGroup/Simulation';
import { ISimulationGroupConfigOptions } from 'types/SimulationGroup/SimulationGroup';
import { getMonthlyResultsInitialTemplate } from 'utils/functions/simulationGroup/getMonthlyResultsInitialTemplate';
import { IMonthlyResultsSection, IResultsView, mras, mrbss, rv } from 'utils/strings/results';

import {
  CLEAR_HOURLY_RESULTS,
  CLEAR_MONTHLY_RESULTS,
  CLEAR_SIMULATION_ACTION,
  ISimulationGroupActions,
  TOGGLE_IS_DOWNLOADING_HOURLY_RESULTS_DATA_ACTION,
  TOGGLE_IS_SIMULATION_IN_PROGRESS_ACTION,
  TOGGLE_LOGS_SCROLL_ACTION,
  TOGGLE_MONTHLY_RESULTS_IS_FORCE_PIVOT_UPDATE_ACTION,
  UPDATE_HOURLY_RESULTS_DATA_ACTION,
  UPDATE_HOURLY_RESULTS_METADATA_ACTION,
  UPDATE_HOURLY_RESULTS_MODE_ACTION,
  UPDATE_HOURLY_RESULTS_PLOT_OPERATION_ACTION,
  UPDATE_MONTHLY_RESULTS_DATA_ACTION,
  UPDATE_MONTHLY_RESULTS_MODE_ACTION,
  UPDATE_MONTHLY_RESULTS_SECTION_ACTION,
  UPDATE_MONTHLY_RESULTS_TEMPLATE_ACTION,
  UPDATE_SIMULATION_ACTION,
  UPDATE_SIMULATION_GROUP_CONFIG_OPTIONS_ACTION,
  UPDATE_SIMULATIONS_LIST_ACTION,
  UPDATE_VARIANT_OPTIONS_BY_OBAT_ACTION,
} from './actions';

export interface IMonthlyResultsTemplate {
  name: string;
  energy: string;
  mode: string;
  unit: string;
}

export interface IMonthlyResultsSectionData {
  [section: string]: string;
}

export interface IMonthlyResultsData {
  [simulationId: string]: IMonthlyResultsSectionData;
}

export interface IHourlyResultsMetadata {
  [simulationId: string]: ISimulationMetadata;
}

export interface IHourlyResultsYearData {
  [simulationId: string]: ISimulationSeriesData;
}

export interface IHourlyResultsYearlyData {
  [year: string]: IHourlyResultsYearData;
}

export interface IHourlyResultsPlotSeries {
  [simulationId: string]: ISimulationSeries[];
}

export interface IHourlyResultsPlotLine {
  values: Array<number | null>;
  text: string;
  measureType: string;
}

export interface IHourlyResultsPlot {
  series: IHourlyResultsPlotSeries;
  yearOptions: string[];
  index: string[];
  lines: IHourlyResultsPlotLine[];
  year: string | undefined;
  operation: 'sum' | 'mean' | 'normalization' | 'none';
}

export interface IVariantOptionsByObat {
  [obatId: string]: string[];
}

export interface ISimulationGroupState {
  configOptions: ISimulationGroupConfigOptions;
  variantOptionsByObat: IVariantOptionsByObat;
  simulationsList: ISimulationListed[];

  simulation?: ISimulationListed | ISimulationRetrieved;
  isSimulationInProgress: boolean;
  isLogsScrollEnabled: boolean;

  monthlyResults: {
    resultsMode: IResultsView;
    section: IMonthlyResultsSection;
    sectionTabs: { [section: string]: string };
    isDownloading: boolean;
    forcePivotUpdate: boolean;
    template: IMonthlyResultsTemplate;
    data: IMonthlyResultsData;
  };

  hourlyResults: {
    resultsMode: IResultsView;
    isDownloading: boolean;
    metadata: IHourlyResultsMetadata;
    yearsData: IHourlyResultsYearlyData;
    plot: IHourlyResultsPlot;
  };
}

export const initialState: ISimulationGroupState = {
  configOptions: {
    weathers: [],
    geometries: [],
    obats: [],
  },
  variantOptionsByObat: {},
  simulationsList: [],

  simulation: undefined,
  isSimulationInProgress: false,
  isLogsScrollEnabled: true,

  monthlyResults: {
    resultsMode: rv.bySimulation,
    section: mrbss.out_monthly_consumption,
    sectionTabs: mrbss,
    isDownloading: false,
    forcePivotUpdate: false,
    template: getMonthlyResultsInitialTemplate(rv.bySimulation, mrbss.out_monthly_consumption),
    data: {},
  },

  hourlyResults: {
    resultsMode: rv.bySimulation,
    plot: {
      series: {},
      yearOptions: [],
      operation: 'none',
      lines: [],
      index: [],
      year: undefined,
    },
    isDownloading: false,
    metadata: {},
    yearsData: {},
  },
};

export const simulationGroupReducer = (
  state: ISimulationGroupState = initialState,
  action: ISimulationGroupActions
): ISimulationGroupState => {
  switch (action.type) {
    case UPDATE_SIMULATION_GROUP_CONFIG_OPTIONS_ACTION:
      const { configOptions } = action.payload;

      return {
        ...state,
        configOptions: {
          ...state.configOptions,
          ...configOptions,
        },
      };

    case UPDATE_VARIANT_OPTIONS_BY_OBAT_ACTION:
      return {
        ...state,
        variantOptionsByObat: {
          ...state.variantOptionsByObat,
          [action.payload.obatId]: action.payload.obatVariantOptions,
        },
      };

    case TOGGLE_IS_SIMULATION_IN_PROGRESS_ACTION:
      return {
        ...state,
        isSimulationInProgress: action.payload.isSimulationInProgress,
      };

    case UPDATE_SIMULATIONS_LIST_ACTION:
      return {
        ...state,
        simulationsList: action.payload.simulationsList,
      };

    case TOGGLE_LOGS_SCROLL_ACTION:
      return { ...state, isLogsScrollEnabled: !state.isLogsScrollEnabled };

    /* SIMULATION */

    case UPDATE_SIMULATION_ACTION:
      return {
        ...state,
        simulation: action.payload.simulation,
      };

    case CLEAR_SIMULATION_ACTION:
      return {
        ...state,
        simulation: initialState.simulation,
      };

    /* MONTHLY RESULTS */

    case TOGGLE_MONTHLY_RESULTS_IS_FORCE_PIVOT_UPDATE_ACTION:
      return {
        ...state,
        monthlyResults: {
          ...state.monthlyResults,
          forcePivotUpdate: action.payload.forcePivotUpdate,
        },
      };

    case UPDATE_MONTHLY_RESULTS_TEMPLATE_ACTION:
      return {
        ...state,
        monthlyResults: {
          ...state.monthlyResults,
          template: action.payload.template,
          forcePivotUpdate: !action.payload.isInitialTemplate,
        },
      };

    case UPDATE_MONTHLY_RESULTS_SECTION_ACTION:
      return {
        ...state,
        monthlyResults: {
          ...state.monthlyResults,
          section: action.payload.section,
          template: getMonthlyResultsInitialTemplate(state.monthlyResults.resultsMode, action.payload.section),
        },
      };

    case UPDATE_MONTHLY_RESULTS_MODE_ACTION:
      const { resultsMode } = action.payload;

      const sectionTabs = resultsMode === rv.bySimulation ? mrbss : mras;
      const initialSection =
        resultsMode === rv.bySimulation ? mrbss.out_monthly_consumption : mras.out_monthly_consumption_ef;
      const initialTemplate = getMonthlyResultsInitialTemplate(resultsMode, initialSection);

      return {
        ...state,
        monthlyResults: {
          ...state.monthlyResults,
          resultsMode,
          sectionTabs,
          section: initialSection,
          template: initialTemplate,
        },
      };

    case UPDATE_MONTHLY_RESULTS_DATA_ACTION:
      const { section, sectionData, simulationId } = action.payload;

      return {
        ...state,
        monthlyResults: {
          ...state.monthlyResults,
          data: {
            ...state.monthlyResults.data,
            [simulationId]: {
              ...state.monthlyResults.data[simulationId],
              [section]: sectionData,
            },
          },
        },
      };

    case CLEAR_MONTHLY_RESULTS:
      return {
        ...state,
        monthlyResults: initialState.monthlyResults,
      };

    /* HOURLY RESULTS */

    case UPDATE_HOURLY_RESULTS_MODE_ACTION:
      return {
        ...state,
        hourlyResults: {
          ...state.hourlyResults,
          resultsMode: action.payload.resultsMode,
        },
      };

    case UPDATE_HOURLY_RESULTS_METADATA_ACTION:
      return {
        ...state,
        hourlyResults: {
          ...state.hourlyResults,
          metadata: {
            ...state.hourlyResults.metadata,
            [action.payload.simulationId]: action.payload.simulationMetadata,
          },
        },
      };

    case UPDATE_HOURLY_RESULTS_DATA_ACTION:
      const { yearsData, plot } = action.payload;

      return {
        ...state,
        hourlyResults: {
          ...state.hourlyResults,
          yearsData,
          plot,
        },
      };

    case TOGGLE_IS_DOWNLOADING_HOURLY_RESULTS_DATA_ACTION:
      return {
        ...state,
        hourlyResults: {
          ...state.hourlyResults,
          isDownloading: action.payload.isDownloading,
        },
      };

    case UPDATE_HOURLY_RESULTS_PLOT_OPERATION_ACTION:
      return {
        ...state,
        hourlyResults: {
          ...state.hourlyResults,
          plot: {
            ...state.hourlyResults.plot,
            operation: action.payload.operation,
          },
        },
      };

    case CLEAR_HOURLY_RESULTS:
      return {
        ...state,
        hourlyResults: initialState.hourlyResults,
      };

    default:
      return state;
  }
};
