import { ActivityIcon } from 'components/Icons';
import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { IActivityZoneGroup } from 'types/Obat/ActivityZoneGroup';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { orc } from 'utils/strings/resourceClasses';

import { ActivityDetail } from '../ActivityZoneGroupDetail';
import { ActivityForm } from '../ActivityZoneGroupForm';

export class ActivityZoneGroupsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'activityZoneGroups'}
        resourceClass={orc.activity_zone_group}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={ActivityForm}
        projectionSection={'zones'}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 3 },
    { id: 'activity', gridItemSize: 4, centerTitle: true },
    { id: 'control', gridItemSize: 3, centerTitle: true },
    { id: 'system', gridItemSize: 2, centerTitle: true },
  ];

  private renderCardColumnData = (activityZoneGroup: IActivityZoneGroup, columnId: string): ReactNode => {
    const internalMassCoefficient: string = activityZoneGroup.internal_mass_coefficient
      ? activityZoneGroup.internal_mass_coefficient.toString()
      : '';
    switch (columnId) {
      case 'name':
        return activityZoneGroup.ref;
      case 'activity':
        return (
          <IconsGroup>
            <ActivityIcon iconName="icon-light" values={[activityZoneGroup.light]} name="light" />
            <ActivityIcon iconName="icon-people" values={[activityZoneGroup.people]} name="people" />
            <ActivityIcon
              iconName="icon-plug"
              values={[activityZoneGroup.electric_equipment, activityZoneGroup.electric_equipment_bis]}
              name="electricEquipment"
            />
            <ActivityIcon
              iconName="icon-ventilation-old"
              values={[activityZoneGroup.natural_ventilation, activityZoneGroup.natural_ventilation_bis]}
              name="naturalVentilation"
            />
            <ActivityIcon iconName="icon-infiltration" values={[activityZoneGroup.infiltration]} name="infiltration" />
            <ActivityIcon
              iconName="icon-internal-mass"
              values={[internalMassCoefficient]}
              name="internalMassCoefficient"
            />
          </IconsGroup>
        );
      case 'control':
        return (
          <IconsGroup>
            <ActivityIcon
              iconName="icon-heating"
              values={[activityZoneGroup.heating_setpoint]}
              name="heatingSetpoint"
            />
            <ActivityIcon
              iconName="icon-cooling"
              values={[activityZoneGroup.cooling_setpoint]}
              name="coolingSetpoint"
            />
            <ActivityIcon iconName="icon-oa" values={[activityZoneGroup.outdoor_air]} name="outdoorAir" />
            <ActivityIcon iconName="icon-water" values={[activityZoneGroup.dhw]} name="dhw" />
          </IconsGroup>
        );
      case 'system':
        return (
          <IconsGroup>
            <ActivityIcon iconName="icon-heat" values={[activityZoneGroup.heating_system]} name="heating" />
            <ActivityIcon iconName="icon-cool" values={[activityZoneGroup.cooling_system]} name="cooling" />
            <ActivityIcon
              iconName="icon-mechanical-vent"
              values={[activityZoneGroup.mechanical_ventilation_system]}
              name="mechanicalVentilation"
            />
            <ActivityIcon iconName="icon-water" values={[activityZoneGroup.dhw_system]} name="dhw" />
          </IconsGroup>
        );
    }
  };

  private renderCardDetail = (activityZoneGroup: IActivityZoneGroup): ReactNode => {
    return <ActivityDetail activityZoneGroup={activityZoneGroup} />;
  };
}

const IconsGroup = styled.div`
  width: 100%;
  text-align: center;
`;
