// tslint:disable:no-any
import { Button } from 'components/Buttons';
import { _isEmpty, _isEqual } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { Check } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';
import { IFormApiErrorsTranslationPath } from 'types/Errors';
import { $t, $tErrorsDetailedCode } from 'utils/functions/translate';

interface IProps {
  formApiErrors?: IFormApiErrorsTranslationPath;
  isSubmitting: boolean;
  formikProps: any;
  forceSubmitDisabled?: (formikProps: any) => boolean;
  forceSubmitEnabled?: (formikProps: any) => boolean;
}

export class FormSubmitButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { formApiErrors, isSubmitting, formikProps, forceSubmitDisabled, forceSubmitEnabled } = this.props;

    // console.log('forceSubmitEnabled', !!forceSubmitEnabled && forceSubmitEnabled(formikProps));
    // console.log('formikProps.isValid', formikProps.isValid);
    // console.log('formikProps.dirty', formikProps.dirty);
    // console.log('forceSubmitDisabled', !!forceSubmitDisabled && forceSubmitDisabled(formikProps));

    const isDisabled =
      !(!!forceSubmitEnabled && forceSubmitEnabled(formikProps)) &&
      (isSubmitting ||
        !formikProps.isValid ||
        !formikProps.dirty ||
        (!!forceSubmitDisabled && forceSubmitDisabled(formikProps)));

    let errorText: string | undefined;

    if (formApiErrors) {
      if (formApiErrors.GLOBAL) {
        errorText = $tErrorsDetailedCode(formApiErrors.GLOBAL);
      } else if (!_isEmpty(formApiErrors.fields) && _isEqual(formApiErrors.fields, formikProps.errors)) {
        errorText = $t('somethingWentWrong');
      }
    }

    return (
      <ButtonContainer>
        <Button
          type={isDisabled ? 'button' : 'submit'}
          isError={!!errorText}
          isDisabled={isDisabled}
          isLoading={isSubmitting}
          Icon={<Check size={16} />}
          height={24}
        />
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </ButtonContainer>
    );
  }
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

// const StyledButton = (styled(Button)`
//   padding: 2px 12px;
//   min-width: 0;
// ` as unknown) as typeof Button;

const ErrorText = styled.div`
  color: ${theme.colors.error};
  padding-top: 5px;
`;
