import Grid from '@material-ui/core/Grid';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import { DayGroupsFormSection } from 'pages/ObatMenu/Calendars/DayGroupsFormSection';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { ICalendarRequestParams } from 'types/Obat/Calendar';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './CalendarForm.container';

export interface ICalendarFormValues {
  ref: string;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<ICalendarFormValues, ICalendarRequestParams>();

export class CalendarForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderSubForm={this.renderSubForm}
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.large}
        resourceClass={orc.calendar}
        subFormResources={[orc.day_group, orc.period]}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<ICalendarFormValues>): ReactNode => {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput
              field={'ref'}
              formikProps={formikProps}
              label={$t('name')}
              required
              inputRef={this.formFocusRef}
            />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): ICalendarFormValues => {
    const { calendarToEdit } = this.props;

    return { ref: setFormInitialValue(calendarToEdit, 'ref') };
  };

  private getValidationSchema = (): yup.ObjectSchema<ICalendarFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
    }) as yup.ObjectSchema<ICalendarFormValues>;
  };

  private renderSubForm = (): ReactNode => {
    return <DayGroupsFormSection />;
  };
}
