import { OplusLoader } from 'components/Loader';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { as } from 'utils/strings/alertStatus';
import { ps } from 'utils/strings/progressStatus';

import { IProps } from './ThreejsViewer.container';

interface IState {
  isIframeLoading: boolean;
  isThreejsLoading: boolean;
}

export class ThreejsViewer extends PureComponent<IProps, IState> {
  public state = {
    isIframeLoading: true,
    isThreejsLoading: true,
  };

  // tslint:disable-next-line:no-any
  private frameWindow: any = null;
  private hasPostedData: boolean = false;

  public componentDidMount(): void {
    window.addEventListener('message', this.stopLoading);
  }

  public componentDidUpdate(_prevProps: IProps, prevState: IState): void {
    const { routeGeometry, openAlertModal, importProgress, updateImportProgress } = this.props;
    const { isThreejsLoading } = this.state;

    /* display warnings modal after rendering after import only */
    if (prevState.isThreejsLoading && !isThreejsLoading) {
      if (importProgress === 'rendering') {
        if (routeGeometry.last_import_message) {
          openAlertModal(routeGeometry.last_import_message, as.warning);
        }
        updateImportProgress(ps.finished);
      }
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('message', this.stopLoading);
  }

  public render(): ReactNode {
    const { isIframeLoading, isThreejsLoading } = this.state;
    const { threejsData, userMeLanguage } = this.props;

    const threejsViewerFileUrl = `${window.location.origin}/frames/threejsViewer_1_3_3.html`;

    const frameStyle = {
      height: `100%`,
      width: `100%`,
      border: 'none',
    };

    const iFrameMessage = {
      threejs: threejsData,
      language: userMeLanguage,
    };

    if (this.props.threejsData && this.frameWindow && !this.hasPostedData) {
      this.frameWindow.postMessage(iFrameMessage, '*');
      /* This component should be mounted only one time */
      this.hasPostedData = true;
    }

    return (
      <FrameContainer>
        {(isIframeLoading || isThreejsLoading) && (
          <div style={frameStyle}>
            <OplusLoader progress={ps.rendering} />
          </div>
        )}
        <iframe src={threejsViewerFileUrl} title="threejsViewer" style={frameStyle} onLoad={this.displayViewer} />
      </FrameContainer>
    );
  }

  private displayViewer = (): void => {
    const iframe = document.querySelector('iframe');
    if (iframe && iframe.contentWindow) {
      this.frameWindow = iframe.contentWindow;
      this.setState({ isIframeLoading: false });
    }
  };

  private stopLoading = (event: { data: string }): void => {
    if (event.data === 'loadingComplete') {
      this.setState({ isThreejsLoading: false });
    }
  };
}

const FrameContainer = styled.div`
  height: 100%;
  margin: 0 -16px;
`;
