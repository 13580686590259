import { EventSeat } from '@material-ui/icons';
import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { MyProjectForm } from 'pages/HomeMenu/MyProjects/MyProjectForm';
import React, { PureComponent, ReactNode } from 'react';
import { getProjectMenuPageRoute, homeMenuPages, projectMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { IProjectListed } from 'types/Project';
import { IRestResource } from 'types/RestResource';
import { renderHighestPremissionValue } from 'utils/functions/permissions/renderHighestPermissionValue';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { getSeatColor } from 'utils/functions/seats/getSeatColor';
import { getSeatTooltipContent } from 'utils/functions/seats/getSeatTooltipContent';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';

import { rm } from 'utils/strings/routingMenus';
import { IProps } from './MyProjectsList.container';

export class MyProjectsList extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isNoOrganizationMember } = this.props;

    const creationTooltipContent = isNoOrganizationMember ? $t('noOrganizationMemberAlert') : undefined;

    return (
      <RestResourcesListPage
        routingMenu={rm.home}
        selectedPage={homeMenuPages.myProjects}
        FormComponent={MyProjectForm}
        getCreationSuccessLandingPageUrl={this.getCreationSuccessLandingPageUrl}
        resourceClass={rrc.project}
        columnsParams={this.getColumnsParams()}
        availableActions={['edit', 'delete']}
        defaultSort={{ columnId: 'creationDate', order: 'desc' }}
        isCreationDisabled={isNoOrganizationMember}
        creationTooltipContent={creationTooltipContent}
      />
    );
  }

  private getCreationSuccessLandingPageUrl = (_routeProjectId: string, createdResource: IRestResource) => {
    return getProjectMenuPageRoute(projectMenuPages.default, createdResource.id);
  };

  private getColumnsParams = (): ITableColumnParams[] => {
    return [
      {
        id: 'name',
        resourceField: 'name',
        getNavigationUrl: (project: IProjectListed) => {
          return getProjectMenuPageRoute(projectMenuPages.default, project.id);
        },
      },
      {
        id: 'organization',
        resourceField: 'organization.name',
        withFilterOptions: true,
        renderCustomDOM: (project: IProjectListed): ReactNode => {
          const seatColor = getSeatColor(project);
          const seatTooltipContent = getSeatTooltipContent(project, 'table');

          return (
            <OrganizationCell>
              <SimpleTooltip placement={'left'} content={seatTooltipContent}>
                <SeatContainer color={seatColor}>
                  <EventSeat fontSize={'small'} />
                </SeatContainer>
              </SimpleTooltip>
              <span>{project.organization.name}</span>
            </OrganizationCell>
          );
        },
      },
      { id: 'creationDate', resourceField: 'creation_date' },
      {
        id: 'permission',
        withFilterOptions: true,
        renderCustomText: (project: IProjectListed): string => {
          const {
            rights: { can_admin, can_write, can_read },
          } = project;

          return renderHighestPremissionValue(can_admin, can_write, can_read);
        },
      },
    ];
  };
}

const OrganizationCell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SeatContainer = styled.div`
  margin-right: 8px;
  margin-top: 4px;
  color: ${(props: { color: string }): string => props.color + ' !important'};
`;
