import { _filter, _get, _includes, _keys, _orderBy, _range, _size } from 'libs/lodash';
import { IRouteResources, IRouteResourcesLists } from 'redux/routing/reducer';
import { IProjectListed } from 'types/Project';
import { IRestResource } from 'types/RestResource';
import { navigationResourcesHierarchy } from 'utils/configs/resourcesHierarchy/restResourcesHierarchy';
import { rrc } from 'utils/strings/resourceClasses';

export interface IBreadcrumbLevel {
  resourceClass: string;
  routeResource: IRestResource;
  routeResourcesList?: IRestResource[];
}

export type IBreadcrumbList = IBreadcrumbLevel[];

const adaptResourcesListForBreadcrumb = (
  resourceClass: string,
  routeResources: IRouteResources,
  routeResourcesLists: IRouteResourcesLists
) => {
  let resourcesListForBreadcrumb = [];
  if (resourceClass === rrc.project) {
    const allProjectsList = _get(routeResourcesLists, rrc.project);
    const routeProject = _get(routeResources, rrc.project);
    const sameOrganizationProjectsList = _filter(
      allProjectsList,
      (project: IProjectListed): boolean => project.organization.id === routeProject.organization.id
    );
    resourcesListForBreadcrumb = sameOrganizationProjectsList;
  } else if (_includes([rrc.mono_simulation_group, rrc.multi_simulation_group], resourceClass)) {
    resourcesListForBreadcrumb = _get(routeResourcesLists, rrc.simulation_group);
  } else {
    resourcesListForBreadcrumb = _get(routeResourcesLists, resourceClass);
  }

  /** remove current resource from list */
  const routeResource = _get(routeResources, resourceClass);
  resourcesListForBreadcrumb = _filter(
    resourcesListForBreadcrumb,
    (resource: { name: string }): boolean => resource.name !== routeResource.name
  );

  /** order list by name */
  return _orderBy(
    resourcesListForBreadcrumb,
    [(resource: { name: string }): string => resource.name.toLowerCase()],
    ['asc']
  );
};

export const getBreadcrumbList = (
  routeResources: IRouteResources,
  routeResourcesLists: IRouteResourcesLists
): IBreadcrumbList => {
  let breadcrumbList: IBreadcrumbList = [];

  if (!_includes(_keys(routeResources), rrc.project) && !_includes(_keys(routeResources), rrc.organization)) {
    return breadcrumbList;
  }

  for (const level of _range(_size(navigationResourcesHierarchy))) {
    for (const resourceClass of _keys(routeResources)) {
      if (_includes(navigationResourcesHierarchy[level], resourceClass)) {
        breadcrumbList = [
          ...breadcrumbList,
          {
            resourceClass,
            routeResource: _get(routeResources, resourceClass),
            routeResourcesList: adaptResourcesListForBreadcrumb(resourceClass, routeResources, routeResourcesLists),
          },
        ];
      }
    }
  }

  return breadcrumbList;
};
