// tslint:disable:no-any
import { ISelectInputProps } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput/DropdownInput.component';
import { RadioInput } from 'components/Forms/Inputs/SingleSelectInputs/RadioInput';
import { IRadioInputProps } from 'components/Forms/Inputs/SingleSelectInputs/RadioInput/RadioInput.component';
import React, { PureComponent, ReactNode } from 'react';
import { displayFormFieldError } from 'utils/functions/forms/displayFormFieldError/displayFormFieldError';

interface IProps extends Pick<IRadioInputProps, Exclude<keyof IRadioInputProps, 'value' | 'onSelect'>> {
  formikProps: any;
  onSelect?: ISelectInputProps['onSelect'];
}

export class FormikRadioInput extends PureComponent<IProps> {
  public render(): ReactNode {
    const { formikProps, field, onSelect, label, ...radioInputProps } = this.props;

    return (
      <RadioInput
        {...radioInputProps}
        field={field}
        label={label}
        value={formikProps.values[field].toString()}
        errorText={displayFormFieldError(field, formikProps)}
        onSelect={onSelect || formikProps.setFieldValue}
      />
    );
  }
}
