import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

const alertStatus = stringsTuple(
  'starting',
  'running',
  'success',
  'failed',
  'server_error',
  'error',
  'warning',
  'empty',
  'info',
  'pending'
);
export type IAlertStatus = typeof alertStatus[number];
export const as = mapStrings<IAlertStatus>(alertStatus);
