import { OplusLoader } from 'components/Loader';
import { Plot } from 'components/Plot';
import { PlotLegend } from 'components/PlotLegend';
import { _get, _has, _includes, _map, _max, _reduce, _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { IHourlyResultsPlotLine } from 'redux/restResources/detail/simulationGroup/reducer';
import theme from 'style/theme';
import styled from 'styled-components';
import { getSeriesPlotHoverTooltip } from 'utils/functions/getSeriesPlotHoverTooltip';
import { ps } from 'utils/strings/progressStatus';

import { $t } from 'utils/functions/translate';
import { IProps } from './HourlyResultsPlot.container';

interface IPlotRange {
  xStart: string | undefined;
  xEnd: string | undefined;
  yStart: string | undefined;
  yEnd: string | undefined;
}

interface ISeriesTrace {
  name: string;
  x: string[];
  y: Array<number | null>;
  line: {
    width: number;
    shape: string;
  };
  hovertemplate: string;
  text: string[];
  visible: string | boolean;
}

interface IState {
  width: number;
  blurredLines: { [ref: string]: boolean };
}
export class HourlyResultsPlot extends PureComponent<IProps, IState> {
  public state: IState = {
    width: 0,
    blurredLines: {},
  };

  public plotRange: IPlotRange = {
    xStart: undefined,
    xEnd: undefined,
    yStart: undefined,
    yEnd: undefined,
  };

  public refCallback = (element: HTMLDivElement | null): void => {
    if (element) {
      this.setState({ width: element.getBoundingClientRect().width });
    }
  };

  public componentDidMount(): void {
    this.toggleAllLinesBlur(false);
  }

  public componentDidUpdate(prevProps: IProps): void {
    const {
      hourlyResultsPlot: { operation },
    } = this.props;

    if (prevProps.hourlyResultsPlot.operation !== operation) {
      this.toggleAllLinesBlur(_includes(['sum', 'mean'], operation));
    }
  }

  public render(): ReactNode {
    const { hourlyResultsPlot } = this.props;
    const { width, blurredLines } = this.state;
    const { plotRange } = this;

    const plotlyLayout = {
      width: 0.97 * width,
      height: 475,
      font: {
        family: 'Source Sans Pro, sans-serif',
        color: theme.colors.font.dark,
        size: 12,
      },
      margin: {
        l: 30,
        r: 30,
        b: 30,
        t: 30,
        pad: 4,
      },
      showlegend: false,
      xaxis: {
        showgrid: false,
        linewidth: 1.5,
        linecolor: '#CDD6E9',
        gridcolor: '#E6E6E6',
        rangeslider: {},
        type: 'date',
        tickformat: '%d/%m/%Y',
        zeroline: false,
        autorange: !(plotRange.xStart || plotRange.xEnd),
        range: [plotRange.xStart, plotRange.xEnd],
      },
      yaxis: {
        zeroline: false,
        gridcolor: '#E6E6E6',
        fixedrange: false,
        autorange: !(plotRange.yStart || plotRange.yEnd),
        automargin: true,
        range: [plotRange.yStart, plotRange.yEnd],
      },
      hovermode: 'closest',
      colorway: theme.colors.plot.seriesColorway,
    };

    const plotlyData = this.getPlotlyData();

    const legendItems = _map(hourlyResultsPlot.lines, (line: IHourlyResultsPlotLine, position: number) => {
      return {
        title: line.text,
        dotColor: theme.colors.plot.seriesColorway[position % 9],
        blurred: blurredLines[line.text],
      };
    });

    // @ts-ignore todo: understand this old hack
    plotlyData.push({});

    return (
      <PlotContainer ref={this.refCallback}>
        {_size(plotlyData) > 0 ? (
          <>
            {/*
            // @ts-ignore */}
            <Plot data={plotlyData} layout={plotlyLayout} onRelayout={this.onRelayout} />
            <LegendContainer>
              <PlotLegend
                onClick={_includes(['sum', 'mean'], hourlyResultsPlot.operation) ? undefined : this.onLegendClick}
                itemsPerLine={2}
                legendItems={legendItems}
                noTooltip
              />
            </LegendContainer>
          </>
        ) : (
          <OplusLoader progress={ps.rendering} />
        )}
      </PlotContainer>
    );
  }

  private getPlotlyData = (): ISeriesTrace[] => {
    const { hourlyResultsPlot } = this.props;
    const { blurredLines } = this.state;

    const { lines, operation, index } = hourlyResultsPlot;

    if (_includes(['sum', 'mean'], operation)) {
      /** sum values */
      let values = _reduce(
        lines,
        (result: number[], line: IHourlyResultsPlotLine) => {
          return _map(result, (value_: number, i: number): number => {
            return (value_ || 0) + (line.values[i] || 0);
          });
        },
        new Array(_size(index))
      );

      if (operation === 'mean') {
        values = _map(values, (sumValue: number): number => {
          return sumValue / _size(lines);
        });
      }

      const seriesName = $t(operation);
      const seriesHover = getSeriesPlotHoverTooltip(index, seriesName);

      return [
        {
          name: seriesName, // useless for Plotly
          x: index,
          y: values,
          line: { width: 1, shape: 'linear' },
          visible: true,
          ...seriesHover,
        },
      ];
    } else {
      return _map(
        lines,
        (line: IHourlyResultsPlotLine): ISeriesTrace => {
          const seriesHover = getSeriesPlotHoverTooltip(index, line.text);

          let yValues = line.values;
          if (operation === 'normalization') {
            const lineMax = (_max(
              _map(line.values, (value: number): number => Math.abs(value || 0))
            ) as unknown) as number;

            // @ts-ignore: TS bug with _map expecting boolean
            yValues = _map(line.values, (value: number) => (value || 0) / lineMax);
          }

          return {
            name: line.text, // useless for Plotly
            x: index,
            y: yValues,
            line: {
              width: 1,
              shape: line.measureType === 'stamp' ? 'linear' : 'hv',
            },
            visible: blurredLines[line.text] ? 'legendonly' : true,
            ...seriesHover,
          };
        }
      );
    }
  };

  private toggleAllLinesBlur = (isBlurred: boolean): void => {
    const { hourlyResultsPlot } = this.props;

    const blurredLines = _reduce(
      hourlyResultsPlot.lines,
      (_blurredLines: { [key: string]: boolean }, line: IHourlyResultsPlotLine) => {
        return { ..._blurredLines, [line.text]: isBlurred };
      },
      {}
    );
    this.setState({ blurredLines });
  };

  private onLegendClick = (ref: string) => (): void => {
    const { blurredLines } = this.state;

    this.setState({
      blurredLines: {
        ...this.state.blurredLines,
        [ref]: !blurredLines[ref],
      },
    });
  };

  // tslint:disable-next-line:no-any
  private onRelayout = (eventData: any) => {
    /* Store zoom parameters to keep zoom when clicking on a series in the legend that causes plot re-render */
    const { plotRange } = this;

    const xAutorangeKey = 'xaxis.autorange'; /* when de-zooming with home button or double click */
    const xRangeKey = 'xaxis.range'; /* when using range slider */
    const xStartKey = 'xaxis.range[0]';
    const xEndKey = 'xaxis.range[1]';

    const xStart = _has(eventData, xAutorangeKey)
      ? undefined
      : _has(eventData, xRangeKey)
      ? _get(eventData, xRangeKey)[0]
      : _has(eventData, xStartKey)
      ? _get(eventData, xStartKey)
      : plotRange.xStart;

    const xEnd = _has(eventData, xAutorangeKey)
      ? undefined
      : _has(eventData, xRangeKey)
      ? _get(eventData, xRangeKey)[1]
      : _has(eventData, xEndKey)
      ? _get(eventData, xEndKey)
      : plotRange.xEnd;

    const yAutorangeKey = 'yaxis.autorange'; /* when de-zooming with home button or double click */
    const yStartKey = 'yaxis.range[0]';
    const yEndKey = 'yaxis.range[1]';

    const yStart = _has(eventData, yAutorangeKey)
      ? undefined
      : _has(eventData, yStartKey)
      ? _get(eventData, yStartKey)
      : plotRange.yStart;

    const yEnd = _has(eventData, yAutorangeKey)
      ? undefined
      : _has(eventData, yEndKey)
      ? _get(eventData, yEndKey)
      : plotRange.yEnd;

    this.plotRange = { xStart, xEnd, yStart, yEnd };
  };
}

const LegendContainer = styled.div`
  padding-left: 30px;
  padding-right: 65px;
`;

const PlotContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  .hovertext > rect:first-of-type {
    display: none;
  }
  .hovertext > text:first-of-type {
    display: none;
  }
`;
