import { _includes } from 'libs/lodash';

export const isIncludedInString = (stringToFilter: string, filters: string[][]): boolean => {
  /* it returns true if at least 1 of the filterGroup as totally included in the stringToFilter */
  for (const filterGroup of filters) {
    let included = 0;
    for (const filter of filterGroup) {
      if (_includes(stringToFilter, filter)) {
        included += 1;
      }
    }
    if (included === filterGroup.length) {
      return true;
    }
  }

  return false;
};
