import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { ISystem } from 'types/Obat/System';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { SystemDetail } from '../SystemDetail';
import { SystemForm } from '../SystemForm';

export class SystemsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'systems'}
        resourceClass={orc.system}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={SystemForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 4 },
    {
      id: 'type',
      resourceField: 'type',
      withFilterOptions: true,
      gridItemSize: 3,
      renderCustomText: (system: ISystem) => $tEnums(system.type),
    },
    {
      id: 'method',
      resourceField: 'method',
      withFilterOptions: true,
      gridItemSize: 5,
      renderCustomText: (system: ISystem) => $tEnums(system.method),
    },
  ];

  private renderCardColumnData = (system: ISystem, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return system.ref;
      case 'type':
        return $tEnums(renderTextValueElseDash(system.type));
      case 'method':
        return $tEnums(renderTextValueElseDash(system.method));
    }
  };

  private renderCardDetail = (system: ISystem): ReactNode => {
    return <SystemDetail system={system} />;
  };
}
