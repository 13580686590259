import { _map } from 'libs/lodash';
import { _range } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { _toString } from 'libs/lodash';
import { IHourlyResultsPlot, IHourlyResultsPlotLine } from 'redux/restResources/detail/simulationGroup/reducer';
import { convertToCsv } from 'utils/functions/downloadFile/convertToCsv';

export const convertHourlyResultsPlotDataToCsv = (
  hourlyResultsPlot: Pick<IHourlyResultsPlot, 'index' | 'lines'>,
  language: string | undefined
): string => {
  const { index, lines } = hourlyResultsPlot;

  const headerRow = ['index', ..._map(lines, 'text')];

  const csvRowsAsArrays = _reduce(
    _range(_size(index)),
    (_input: Array<Array<number | null> | string[]>, r: number) => {
      const dataRow = [index[r], ..._map(lines, (line: IHourlyResultsPlotLine): string => _toString(line.values[r]))];

      return [..._input, dataRow];
    },
    [headerRow]
  );

  return convertToCsv(csvRowsAsArrays, language);
};
