// tslint:disable:no-any
import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { toggleRowSelectionAction } from 'redux/table/actions';
import { selectIsSelectionDisplay } from 'redux/table/selectors';
import { IDatatableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';

import { DatatableRow as Component } from './DatatableRow.component';

export interface IOwnProps {
  tableColumnsParams: IDatatableColumnParams[];
  tableResource: any;
  isSelectable?: boolean;
  isSelected?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    isSelectionDisplay: selectIsSelectionDisplay(rootState),
  };
};

const mapDispatchToProps = {
  toggleRowSelection: toggleRowSelectionAction,
};

export const DatatableRow = connect(mapStateToProps, mapDispatchToProps)(Component);
