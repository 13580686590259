import wretch from 'wretch';

export const downloadSeriesYearDataFromAzureContainer = async (
  containerUrl: string,
  sasToken: string,
  seriesId: string,
  year: string | number
) => {
  const url = `${containerUrl}${year}/${seriesId}.json?${sasToken}`;

  try {
    const wretcher = await wretch(url).get();

    return wretcher.json();
  } catch (e) {
    throw new Error(`Error while downloading series data from azure: ${e}`);
  }
};
