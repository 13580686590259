import theme from 'style/theme';
import styled from 'styled-components';

export const Console = styled.pre`
  background-color: ${theme.colors.lightBackground};
  color: black;
  width: 100%;
  padding: 8px;
  overflow: auto;
  max-height: 64vh;
`;
