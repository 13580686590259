import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

const formKeys = stringsTuple(
  'obatResource',
  'restResource',
  'userPassword',
  'variantContext',
  'configObat',
  'projection',
  'weatherConfig',
  'configMono',
  'configMulti',
  'copy',
  'import',
  'template',
  'inviteUser',
  'createUser',
  'allocateSeat',
  'exportGbxml'
);
export type IFormKey = typeof formKeys[number];
export const fk = mapStrings<IFormKey>(formKeys);
