import { connect } from 'react-redux';
import { closeAlertModalAction } from 'redux/modals/alertModal/actions';
import { selectAlertModalStatus, selectAlertModalText, selectAlertModalTitle } from 'redux/modals/alertModal/selectors';
import { IRootState } from 'redux/reducer';

import { AlertModal as Component } from './AlertModal.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  return {
    alertText: selectAlertModalText(rootState),
    alertStatus: selectAlertModalStatus(rootState),
    modalTitle: selectAlertModalTitle(rootState),
  };
};

const mapDispatchToProps = {
  closeAlertModal: closeAlertModalAction,
};

export const AlertModal = connect(mapStateToProps, mapDispatchToProps)(Component);
