import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import { updateMonthlyResultsTemplateAction } from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectMonthlyResultsForcePivotUpdate,
  selectMonthlyResultsMode,
  selectMonthlyResultsSection,
  selectMonthlyResultsSectionData,
  selectMonthlyResultsSectionDataCsv,
  selectMonthlyResultsTemplate,
  selectSimulation,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { IRestResource } from 'types/RestResource';

import { ResultsTab as Component } from './ResultsTab.component';

interface IOwnProps {
  routeSimulationGroup: IRestResource;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = IOwnProps & IStateProps & IDispatchProps;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  const section = selectMonthlyResultsSection(rootState);
  const template = selectMonthlyResultsTemplate(rootState);
  const isMulti = ownProps.routeSimulationGroup.model === 'multisimulationgroup';
  const isMono = ownProps.routeSimulationGroup.model === 'monosimulationgroup';

  return {
    template,
    section,
    isMulti,
    isMono,
    resultsMode: selectMonthlyResultsMode(rootState),
    simulation: selectSimulation(rootState),
    sectionData: selectMonthlyResultsSectionData(rootState),
    sectionDataCsv: selectMonthlyResultsSectionDataCsv(rootState),
    forcePivotUpdate: selectMonthlyResultsForcePivotUpdate(rootState),
  };
};

const mapDispatchToProps = {
  updateTemplate: updateMonthlyResultsTemplateAction,
  openFormDrawer: openFormDrawerSagaAction,
};

export const ResultsTab = connect(mapStateToProps, mapDispatchToProps)(Component);
