import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { selectTableResources } from 'redux/table/selectors';
import { orc } from 'utils/strings/resourceClasses';
import { rrc } from 'utils/strings/resourceClasses';

import { UseForm as UseFormComponent } from './UseForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeObat = selectRouteResource(rrc.obat)(rootState);
  const useToEdit = selectFormInitialResourceFromTable(orc.use)(rootState);
  const tableResources = selectTableResources(rootState)(orc.use, routeObat.id);

  return {
    useToEdit,
    uses: tableResources ? tableResources.filtered : undefined,
  };
};

const mapDispatchToProps = {};

export const UseForm = connect(mapStateToProps, mapDispatchToProps)(UseFormComponent);
