import { Grid } from '@material-ui/core';
import { Button } from 'components/Buttons';
import { ModalFrame } from 'components/Modals/ModalFrame';
import { CenteredText } from 'components/Texts/CenteredText';
import { Location as ILocation } from 'history';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { Check, X } from 'react-feather';
import { Prompt } from 'react-router';
import { navigateRoute } from 'routing/navigateRoute';
import theme from 'style/theme';
import { $t } from 'utils/functions/translate';

import { IProps } from './LeaveFloorspaceModal.container';

interface IState {
  confirmedNavigation: boolean;
  nextLocation?: ILocation;
}

export class LeaveFloorspaceModal extends PureComponent<IProps, IState> {
  public state: IState = {
    confirmedNavigation: false,
    nextLocation: undefined,
  };

  public componentDidUpdate = () => {
    if (this.state.nextLocation && !this.props.when() && this.props.isOpen) {
      // @ts-ignore
      navigateRoute(this.state.nextLocation);
    }
  };

  public componentWillUnmount = () => {
    this.props.closeNavigationModal();
  };

  public render(): ReactNode {
    const { isOpen, closeNavigationModal, when, isLoading, save } = this.props;

    const Title = <CenteredText>{$t('leavePageAlertWithoutSave')}</CenteredText>;

    const Actions = (
      <Grid container spacing={3} justify={'center'} alignItems={'center'}>
        <Grid item>
          <Button Icon={<X size={20} />} onClick={this.handleNavigateClick} bgColor={theme.colors.error} noShadow />
        </Grid>
        <Grid item>
          <Button Icon={<Check size={20} />} onClick={save} isLoading={isLoading} noShadow />
        </Grid>
      </Grid>
    );

    return (
      <>
        <ModalFrame isOpen={isOpen} onClose={closeNavigationModal} Title={Title} Actions={Actions} />;
        <Prompt when={when()} message={this.handleBlockedNavigation} />
      </>
    );
  }

  private handleNavigateClick = (): void => {
    this.props.closeNavigationModal();
    // @ts-ignore
    this.setState({ confirmedNavigation: true }, () => navigateRoute(this.state.nextLocation));
  };

  private handleBlockedNavigation = (nextLocation: ILocation): boolean => {
    if (!this.state.confirmedNavigation) {
      this.props.openNavigationModal();
      this.setState({ nextLocation });

      return false;
    }

    return true;
  };
}
