import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { orc } from 'utils/strings/resourceClasses';

import { MaterialForm as MaterialFormComponent } from './MaterialForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const materialToEdit = selectFormInitialResourceFromTable(orc.material)(rootState);

  return { materialToEdit };
};

const mapDispatchToProps = {};

export const MaterialForm = connect(mapStateToProps, mapDispatchToProps)(MaterialFormComponent);
