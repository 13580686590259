import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { obatMenuPages } from 'routing/routes';
import { IBridge } from 'types/Obat/Bridge';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { BridgeForm } from '../BridgeForm';

export class BridgesPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={obatMenuPages.bridges}
        resourceClass={orc.bridge}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={BridgeForm}
        projectionSection={'bridges'}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 4 },
    { id: 'psi', resourceField: 'psi', title: $t('psiWithUnit'), gridItemSize: 2 },
    { id: 'construction1', resourceField: 'construction_a', withFilterOptions: true, gridItemSize: 3 },
    { id: 'construction2', resourceField: 'construction_b', withFilterOptions: true, gridItemSize: 3 },
  ];

  private renderCardColumnData = (bridge: IBridge, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return bridge.ref;
      case 'psi':
        return bridge.psi;
      case 'construction1':
        return renderTextValueElseDash(bridge.construction_a);
      case 'construction2':
        return renderTextValueElseDash(bridge.construction_b);
    }
  };
}
