import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath } from 'redux/apiRequests/selectors';
import { selectIsUploadingToAzure } from 'redux/azure/selectors';
import { IRootState } from 'redux/reducer';
import { importObatSagaAction } from 'redux/restResources/detail/obat/actions';
import { selectRouteResource } from 'redux/routing/selectors';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { ObatImportForm as Component } from './ObatImportForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeObat = selectRouteResource(rrc.obat)(rootState);
  const obatImportApiRequestKey = getDetailApiRequestKey(dra.importObat, routeObat.id);

  return {
    isUploadingToAzure: selectIsUploadingToAzure(rootState),
    importFormErrors: selectFormApiErrorsTranslationPath(obatImportApiRequestKey)(rootState),
  };
};

const mapDispatchToProps = {
  importObat: importObatSagaAction,
};

export const ObatImportForm = connect(mapStateToProps, mapDispatchToProps)(Component);
