import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { selectFormDrawerWidth } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { selectOpenergyHistoricalWeatherStationName } from 'redux/restResources/detail/weather/selectors';
import { selectIsSeatMissing, selectIsWritePermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { fk } from 'utils/strings/formKeys';
import { rrc } from 'utils/strings/resourceClasses';

import { WeatherConfig as Component } from './WeatherConfig.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  const routeWeather = selectRouteResource(rrc.weather)(rootState);
  const stationName = selectOpenergyHistoricalWeatherStationName(rootState);

  return {
    routeWeather,
    stationName,
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
    formDrawerWidth: selectFormDrawerWidth(rootState),
  };
};

const mapDispatchToProps = {
  openFormDrawer: openFormDrawerSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: {}) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openConfigForm: () =>
    dispatchProps.openFormDrawer(fk.weatherConfig, {
      resourceId: stateProps.routeWeather.id,
      resourceClass: rrc.weather,
    }),
});

export const WeatherConfig = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
