import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';

interface IProps {
  progress?: string;
}

export class Loader extends PureComponent<IProps> {
  public render(): ReactNode {
    const { progress } = this.props;

    return (
      <LoaderContainer>
        {this.props.children}
        {progress && <ProgressContainer>{$t(progress)}</ProgressContainer>}
      </LoaderContainer>
    );
  }
}

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ProgressContainer = styled.div`
  color: ${theme.colors.main};
  margin-top: 20px;
  font-size: 130%;
`;
