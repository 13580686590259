// tslint:disable:no-any

import { Action } from 'redux';
import { ITableResourcesList } from 'redux/table/reducer';
import { ITableFilterParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { IApiResourceClass } from 'utils/strings/resourceClasses';

export const FETCH_TABLE_INITIAL_DATA_SAGA_ACTION = 'FETCH_TABLE_INITIAL_DATA_SAGA_ACTION';
export interface IFetchTableInitialDataSagaAction extends Action<'FETCH_TABLE_INITIAL_DATA_SAGA_ACTION'> {
  payload: {
    tableFilterParams: ITableFilterParams;
    resourceClass: IApiResourceClass;
    parentId?: string;
  };
}
export const fetchTableInitialDataSagaAction = (
  tableFilterParams: ITableFilterParams,
  resourceClass: IApiResourceClass,
  parentId?: string
): IFetchTableInitialDataSagaAction => ({
  type: FETCH_TABLE_INITIAL_DATA_SAGA_ACTION,
  payload: {
    tableFilterParams,
    resourceClass,
    parentId,
  },
});

export const INITIALIZE_TABLE_ACTION = 'INITIALIZE_TABLE_ACTION';
export interface IInitializeTableAction extends Action<'INITIALIZE_TABLE_ACTION'> {
  payload: {
    tableFullResources: ITableResourcesList;
    tableFilterParams: ITableFilterParams;
    parentId?: string;
    resourceClass?: IApiResourceClass;
  };
}
export const initializeTableAction = (
  tableFullResources: ITableResourcesList,
  tableFilterParams: ITableFilterParams,
  resourceClass?: IApiResourceClass,
  parentId?: string
): IInitializeTableAction => ({
  type: INITIALIZE_TABLE_ACTION,
  payload: {
    tableFullResources,
    tableFilterParams,
    resourceClass,
    parentId,
  },
});

export const FETCH_TABLE_UPDATED_DATA_SAGA_ACTION = 'FETCH_TABLE_UPDATED_DATA_SAGA_ACTION';
export interface IFetchTableUpdatedDataSagaAction extends Action<'FETCH_TABLE_UPDATED_DATA_SAGA_ACTION'> {
  payload: {
    resourceClass: IApiResourceClass;
  };
}
export const fetchTableUpdatedDataSagaAction = (
  resourceClass: IApiResourceClass
): IFetchTableUpdatedDataSagaAction => ({
  type: FETCH_TABLE_UPDATED_DATA_SAGA_ACTION,
  payload: {
    resourceClass,
  },
});

export const UPDATE_TABLE_FULL_DATA_ACTION = 'UPDATE_TABLE_FULL_DATA_ACTION';
export interface IUpdateTableFullDataAction extends Action<'UPDATE_TABLE_FULL_DATA_ACTION'> {
  payload: {
    tableFullResources: ITableResourcesList;
  };
}
export const updateTableFullDataAction = (tableFullResources: ITableResourcesList): IUpdateTableFullDataAction => ({
  type: UPDATE_TABLE_FULL_DATA_ACTION,
  payload: {
    tableFullResources,
  },
});

export const UPDATE_COLUMN_FILTER_VALUE_ACTION = 'UPDATE_COLUMN_FILTER_VALUE_ACTION';
export interface IUpdateFilterColumnValueAction extends Action<'UPDATE_COLUMN_FILTER_VALUE_ACTION'> {
  payload: {
    columnId: string;
    value: string;
  };
}
export const updateFilterColumnValueAction = (columnId: string, value: string): IUpdateFilterColumnValueAction => ({
  type: UPDATE_COLUMN_FILTER_VALUE_ACTION,
  payload: {
    columnId,
    value,
  },
});

export const TOGGLE_ROW_SELECTION_ACTION = 'TOGGLE_ROW_SELECTION_ACTION';
export interface IToggleRowSelectionAction extends Action<'TOGGLE_ROW_SELECTION_ACTION'> {
  payload: {
    clickedRowData: any;
  };
}
export const toggleRowSelectionAction = (clickedRowData: any): IToggleRowSelectionAction => ({
  type: TOGGLE_ROW_SELECTION_ACTION,
  payload: {
    clickedRowData,
  },
});

export const INITIALIZE_TABLE_WITH_SELECTION_ACTION = 'INITIALIZE_TABLE_WITH_SELECTION_ACTION';
export interface IInitializeTableWithSelectionAction extends Action<'INITIALIZE_TABLE_WITH_SELECTION_ACTION'> {
  payload: {
    tableFilterParams?: ITableFilterParams;
  };
}
export const initializeTableWithSelectionAction = (
  tableFilterParams: ITableFilterParams
): IInitializeTableWithSelectionAction => ({
  type: INITIALIZE_TABLE_WITH_SELECTION_ACTION,
  payload: {
    tableFilterParams,
  },
});

export const SELECT_ALL_FILTERED_ROWS_ACTION = 'SELECT_ALL_FILTERED_ROWS_ACTION';
export interface ISelectAllFilteredRowsAction extends Action<'SELECT_ALL_FILTERED_ROWS_ACTION'> {}
export const selectAllFilteredRowsAction = (): ISelectAllFilteredRowsAction => ({
  type: SELECT_ALL_FILTERED_ROWS_ACTION,
});

export const UNSELECT_ALL_FILTERED_ROWS_ACTION = 'UNSELECT_ALL_FILTERED_ROWS_ACTION';
export interface IUnselectAllFilteredRowsAction extends Action<'UNSELECT_ALL_FILTERED_ROWS_ACTION'> {}
export const unselectAllFilteredRowsAction = (): IUnselectAllFilteredRowsAction => ({
  type: UNSELECT_ALL_FILTERED_ROWS_ACTION,
});

export const CLEAR_SELECTED_DATA = 'CLEAR_SELECTED_DATA';
export interface IClearSelectedDataAction extends Action<'CLEAR_SELECTED_DATA'> {}
export const clearSelectedDataAction = (): IClearSelectedDataAction => ({
  type: CLEAR_SELECTED_DATA,
});

export type ITableActions =
  | IInitializeTableAction
  | IUpdateTableFullDataAction
  | IUpdateFilterColumnValueAction
  | IInitializeTableWithSelectionAction
  | IToggleRowSelectionAction
  | ISelectAllFilteredRowsAction
  | IUnselectAllFilteredRowsAction
  | IClearSelectedDataAction;
