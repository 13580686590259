import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { IObatResourceClass } from 'utils/strings/resourceClasses';

import { ObatResourceCopyFormDrawer as Component } from './ObatResourceCopyFormDrawer.component';

interface IOwnProps {
  resourceClass: IObatResourceClass;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return { initialObatResource: selectFormInitialResourceFromTable(ownProps.resourceClass)(rootState) };
};

const mapDispatchToProps = {};

export const ObatResourceCopyFormDrawer = connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
