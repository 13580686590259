import { connect } from 'react-redux';
import { closeDailySeatModalAction } from 'redux/modals/dailySeatModal/actions';
import { selectIsDailySeatModalOpen } from 'redux/modals/dailySeatModal/selectors';
import { IRootState } from 'redux/reducer';
import { spendDailySeatsSagaAction } from 'redux/restResources/detail/organization/actions';

import { DailySeatsModal as Component } from './DailySeatsModal.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  return {
    isOpen: selectIsDailySeatModalOpen(rootState),
  };
};

const mapDispatchToProps = {
  closeModal: closeDailySeatModalAction,
  spendDailySeats: spendDailySeatsSagaAction,
};

export const DailySeatsModal = connect(mapStateToProps, mapDispatchToProps)(Component);
