import Grid from '@material-ui/core/Grid';
import { DropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput';
import { ToggleInput } from 'components/Forms/Inputs/SingleSelectInputs/ToggleInput';
import { SimulationConfigCard } from 'components/SimulationGroup/SimulationConfigCard';
import { _find, _includes } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { multiSimulationGroupMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { ISimulationListed } from 'types/SimulationGroup/Simulation';
import { ISelectOption } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { IResultsView, rv } from 'utils/strings/results';

import { IProps } from './ResultsModeForm.container';

export class ResultsModeForm extends PureComponent<IProps> {
  public componentDidUpdate(): void {
    const { tableResources, resultsMode, urlPage, onResultsModeToggle } = this.props;

    /* Back to bySimulation mode if the all series gets unselected */
    if (
      _includes(urlPage, 'hourly') &&
      resultsMode === rv.aggregate &&
      !!tableResources &&
      _size(tableResources.selected) === 0
    ) {
      onResultsModeToggle(rv.bySimulation);
    }
  }

  public render(): ReactNode {
    const {
      simulation,
      simulationsListByStatus,
      isMulti,
      resultsMode,
      routeMultiSimulationGroup,
      urlPage,
      tableResources,
    } = this.props;

    const modeOptions = [rv.bySimulation, rv.aggregate];

    const simulationOptions = _map(
      simulationsListByStatus.success,
      (simu: ISimulationListed): ISelectOption => ({ value: simu.id, label: simu.name })
    );

    const isMonthlyAggregatedResultsDisbaled =
      urlPage === multiSimulationGroupMenuPages.monthlyResultsMulti &&
      _includes([as.empty, as.failed, as.running], routeMultiSimulationGroup.status);

    const isHourlySeriesSelectionEmpty =
      _includes(urlPage, 'hourly') && tableResources && _size(tableResources.selected) === 0;

    const disabledOptions =
      isMonthlyAggregatedResultsDisbaled || isHourlySeriesSelectionEmpty ? ['aggregate'] : undefined;

    return (
      <FormContainer>
        <Grid container spacing={1}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <ToggleInput
              value={resultsMode}
              field={'resultsMode'}
              onSelect={this.onModeToggle}
              options={modeOptions}
              renderOptionLabel={this.formatModeOptionsLabels}
              disabledOptions={disabledOptions}
            />
          </Grid>
        </Grid>
        {isMulti && resultsMode === rv.bySimulation && !!simulation && (
          <Grid container spacing={1} style={{ marginTop: '12px' }}>
            <Grid item xs={2}>
              <DropdownInput
                field={'simulation'}
                label={$t('simulation')}
                value={simulation.id}
                options={simulationOptions}
                onSelect={this.onSimulationSelect}
              />
            </Grid>
            <Grid item xs={10}>
              <SimulationConfigCard />
            </Grid>
          </Grid>
        )}
      </FormContainer>
    );
  }

  private formatModeOptionsLabels = (field: IResultsView): string => {
    const { resultsModeLabels } = this.props;

    return resultsModeLabels[field];
  };

  private onSimulationSelect = (_field: string, selectedSimulationId: string) => {
    const { onSimulationSelect, simulationsList } = this.props;
    const selectedSimulation = _find(simulationsList, ['id', selectedSimulationId]);
    if (selectedSimulation) {
      onSimulationSelect(selectedSimulation);
    }
  };

  private onModeToggle = (_field: string, selectedMode: IResultsView) => {
    const { onResultsModeToggle } = this.props;
    onResultsModeToggle(selectedMode);
  };
}

const FormContainer = styled.div`
  margin-bottom: 12px;
`;
