// tslint:disable:no-any

import { _filter } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _uniqBy } from 'libs/lodash';
import { applyFilter } from 'utils/functions/filter/applyFilter';
import { initializeTableFilterParams } from 'utils/functions/filter/initializeTableFilterParams';
import { initializeTableFilterValues } from 'utils/functions/filter/initializeTableFilterValues';
import { ITableFilterParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { IApiResourceClass } from 'utils/strings/resourceClasses';

import {
  CLEAR_SELECTED_DATA,
  INITIALIZE_TABLE_ACTION,
  INITIALIZE_TABLE_WITH_SELECTION_ACTION,
  ITableActions,
  SELECT_ALL_FILTERED_ROWS_ACTION,
  TOGGLE_ROW_SELECTION_ACTION,
  UNSELECT_ALL_FILTERED_ROWS_ACTION,
  UPDATE_COLUMN_FILTER_VALUE_ACTION,
  UPDATE_TABLE_FULL_DATA_ACTION,
} from './actions';

export type ITableResourcesList = any[];

export interface ITableFilterValues {
  [columnId: string]: string;
}
export interface ITableResources {
  filtered: ITableResourcesList;
  full: ITableResourcesList;
  selected: ITableResourcesList;
}

export interface ITableState {
  resources: ITableResources;
  filter: { params: ITableFilterParams; values: ITableFilterValues };
  isSelectionDisplay: boolean;
  parentId: string | undefined;
  resourceClass: IApiResourceClass | undefined;
}

export const initialState = {
  resources: { full: [], filtered: [], selected: [] },
  filter: { params: [], values: {} },
  isSelectionDisplay: false,
  parentId: undefined,
  resourceClass: undefined,
};

export const tableReducer = (state: ITableState = initialState, action: ITableActions): ITableState => {
  let filterParams;
  let filterValues;

  switch (action.type) {
    case INITIALIZE_TABLE_ACTION:
      const { resourceClass, tableFullResources, tableFilterParams, parentId } = action.payload;

      filterParams = initializeTableFilterParams(tableFullResources, tableFilterParams);
      filterValues = initializeTableFilterValues(tableFilterParams);

      return {
        ...state,
        resourceClass,
        parentId,
        isSelectionDisplay: false,
        filter: {
          params: filterParams,
          values: filterValues,
        },
        resources: {
          ...state.resources,
          full: tableFullResources,
          filtered: tableFullResources,
        },
      };

    case UPDATE_TABLE_FULL_DATA_ACTION: // when creating, editing, deleting data
      filterParams = initializeTableFilterParams(action.payload.tableFullResources, state.filter.params);
      filterValues = initializeTableFilterValues(filterParams, state.filter.values);

      return {
        ...state,
        filter: {
          params: filterParams,
          values: filterValues,
        },
        resources: {
          ...state.resources,
          full: action.payload.tableFullResources,
          filtered: applyFilter(action.payload.tableFullResources, filterParams, filterValues),
        },
      };

    case UPDATE_COLUMN_FILTER_VALUE_ACTION:
      filterValues = {
        ...state.filter.values,
        [action.payload.columnId]: action.payload.value,
      };

      return {
        ...state,
        filter: {
          ...state.filter,
          values: filterValues,
        },
        resources: {
          ...state.resources,
          filtered: applyFilter(state.resources.full, state.filter.params, filterValues),
        },
      };

    case TOGGLE_ROW_SELECTION_ACTION:
      const { clickedRowData } = action.payload;
      const selectedIdsList = _map(state.resources.selected, 'id');

      const newSelectedResourcesList = _includes(selectedIdsList, clickedRowData.id)
        ? _filter(state.resources.selected, (tableResource: any): boolean => clickedRowData.id !== tableResource.id)
        : [...state.resources.selected, clickedRowData];

      return {
        ...state,
        resources: {
          ...state.resources,
          selected: newSelectedResourcesList,
          filtered: state.isSelectionDisplay
            ? applyFilter(newSelectedResourcesList, state.filter.params, state.filter.values)
            : state.resources.filtered,
          full: state.isSelectionDisplay ? newSelectedResourcesList : state.resources.full,
        },
      };

    case SELECT_ALL_FILTERED_ROWS_ACTION:
      return {
        ...state,
        resources: {
          ...state.resources,
          selected: _uniqBy([...state.resources.selected, ...state.resources.filtered], 'id'),
        },
      };

    case UNSELECT_ALL_FILTERED_ROWS_ACTION:
      const filteredIdsList = _map(state.resources.filtered, 'id');

      const remainingSelectedResourcesList = _filter(
        state.resources.selected,
        (tableResource: any): boolean => !_includes(filteredIdsList, tableResource.id)
      );

      return {
        ...state,
        resources: {
          ...state.resources,
          selected: remainingSelectedResourcesList,
          filtered: state.isSelectionDisplay
            ? applyFilter(remainingSelectedResourcesList, state.filter.params, state.filter.values)
            : state.resources.filtered,
          full: state.isSelectionDisplay ? remainingSelectedResourcesList : state.resources.full,
        },
      };

    case CLEAR_SELECTED_DATA:
      return {
        ...state,
        resources: {
          ...state.resources,
          selected: [],
        },
      };

    case INITIALIZE_TABLE_WITH_SELECTION_ACTION:
      return {
        ...state,
        isSelectionDisplay: true,
        filter: {
          params: initializeTableFilterParams(
            state.resources.selected,
            action.payload.tableFilterParams || state.filter.params
          ),
          values: initializeTableFilterValues(action.payload.tableFilterParams || state.filter.params),
        },
        resources: {
          ...state.resources,
          full: state.resources.selected,
          filtered: state.resources.selected,
        },
      };

    default:
      return state;
  }
};
