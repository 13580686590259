import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { selectFormDrawerWidth } from 'redux/form/selectors';
import { openAlertModalAction } from 'redux/modals/alertModal/actions';
import { IRootState } from 'redux/reducer';
import { selectMyLanguage } from 'redux/restResources/detail/me/selectors';
import {
  exportDegreeDaysFileSagaAction,
  exportWeatherFileSagaAction,
  refreshImportWeatherTaskSagaAction,
} from 'redux/restResources/detail/weather/actions';
import {
  selectDegreeDaysParams,
  selectWeatherImportProgress,
  selectWeatherMeasuresSeriesIdOptions,
} from 'redux/restResources/detail/weather/selectors';
import { selectIsSeatMissing, selectIsWritePermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { selectIsTaskInProgress } from 'redux/tasks/selectors';
import { getTaskKey } from 'utils/functions/getTaskKey';
import { fk } from 'utils/strings/formKeys';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { WeatherData as WeatherDataComponent } from './WeatherData.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  const routeWeather = selectRouteResource(rrc.weather)(rootState);

  const weatherImportProgress = selectWeatherImportProgress(rootState);
  const isImportingWeather = weatherImportProgress !== 'finished';

  const exportWeatherFileTaskKey = getTaskKey(dra.exportWeatherFile, routeWeather.id);
  const isExportingWeatherFile = selectIsTaskInProgress(exportWeatherFileTaskKey)(rootState);
  const exportDegreeDaysFileTaskKey = getTaskKey(dra.exportDegreeDaysFile, routeWeather.id);
  const isExportingDegreeDaysFile = selectIsTaskInProgress(exportDegreeDaysFileTaskKey)(rootState);
  const isExporting = isExportingWeatherFile || isExportingDegreeDaysFile;

  const measuresSeriesIdOptions = selectWeatherMeasuresSeriesIdOptions(rootState);
  const degreeDaysParams = selectDegreeDaysParams(rootState);

  const isWritePermissionMissing = selectIsWritePermissionMissing(rootState);
  const isSeatMissing = selectIsSeatMissing(rootState);
  const formDrawerWidth = selectFormDrawerWidth(rootState);
  const userMeLanguage = selectMyLanguage(rootState);

  return {
    routeWeather,
    weatherImportProgress,
    isImportingWeather,
    isExporting,
    measuresSeriesIdOptions,
    degreeDaysParams,
    isWritePermissionMissing,
    isSeatMissing,
    formDrawerWidth,
    userMeLanguage,
  };
};

const mapDispatchToProps = {
  openAlertModal: openAlertModalAction,
  openFormDrawer: openFormDrawerSagaAction,
  exportWeatherFile: exportWeatherFileSagaAction,
  exportDegreeDaysFile: exportDegreeDaysFileSagaAction,
  refreshImportWeatherTask: refreshImportWeatherTaskSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: {}) => {
  const openImportForm = () =>
    dispatchProps.openFormDrawer(fk.import, {
      resourceId: stateProps.routeWeather.id,
      resourceClass: rrc.weather,
    });

  const exportCsvWeatherFile = () => dispatchProps.exportWeatherFile('csv');
  const exportEpwWeatherFile = () => dispatchProps.exportWeatherFile('epw');

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    openImportForm,
    exportCsvWeatherFile,
    exportEpwWeatherFile,
  };
};

export const WeatherData = connect(mapStateToProps, mapDispatchToProps, mergeProps)(WeatherDataComponent);
