import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath, selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { closeFormDrawerAction } from 'redux/form/actions';
import { selectIsFormDrawerOpen } from 'redux/form/selectors';
import { selectObatResourcesIdsList } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { updateVariantContextIdSagaAction } from 'redux/variantContext/actions';
import { selectVariantContextId } from 'redux/variantContext/selectors';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { fk } from 'utils/strings/formKeys';
import { dra } from 'utils/strings/requestActions';
import { orc, rrc } from 'utils/strings/resourceClasses';

import { VariantContextForm as Component } from './VariantContextForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  const routeObat = selectRouteResource(rrc.obat)(rootState);
  const apiRequestKey = getDetailApiRequestKey(dra.fetchObatContents, routeObat.id);

  return {
    variantOptions: selectObatResourcesIdsList(orc.variant)(rootState),
    isFormDrawerOpen: selectIsFormDrawerOpen(fk.variantContext)(rootState),
    variantId: selectVariantContextId(rootState),
    editErrors: selectFormApiErrorsTranslationPath(apiRequestKey)(rootState),
    isSubmitting: selectIsApiRequestPending(apiRequestKey)(rootState),
  };
};

const mapDispatchToProps = {
  updateVariantContext: updateVariantContextIdSagaAction,
  closeFormDrawer: closeFormDrawerAction,
};

export const VariantContextForm = connect(mapStateToProps, mapDispatchToProps)(Component);
