import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { ICardsTableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { rrc } from 'utils/strings/resourceClasses';

import { ObatHeadersBar as Component } from './ObatHeadersBar.component';

export interface IObatHeaderOwnProps {
  cardColumnsParams: ICardsTableColumnParams[];
  isDataEmpty?: boolean;
  isDataReady?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps & IObatHeaderOwnProps;

const mapStateToProps = (rootState: IRootState) => ({
  routeProject: selectRouteResource(rrc.project)(rootState),
  routeObat: selectRouteResource(rrc.obat)(rootState),
});

const mapDispatchToProps = {};

export const ObatHeadersBar = connect(mapStateToProps, mapDispatchToProps)(Component);
