import { _filter } from 'libs/lodash';
import { _forEach } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { IHeatmap, IHeatmapLegendItem } from 'types/Obat/Calendar';

import { getDayGroupColor } from '../getColorByLegendItem';

export const getCalendarHeatmapColorscale = (legend: IHeatmap['legend']) => {
  const nonEmptyLegendNums = _map(
    _filter(legend, (info: IHeatmapLegendItem) => !info.empty),
    (info: IHeatmapLegendItem) => info.num
  );

  const maxNum = Math.max(...nonEmptyLegendNums);
  const minNum = Math.min(...nonEmptyLegendNums);
  const delta = maxNum - minNum + 1; // total will be done on i+1, not i
  let colorscale: Array<[number, string]> = [];
  _forEach(legend, (legendItem: IHeatmapLegendItem, index: number) => {
    if (legendItem.empty) {
      return;
    }

    const color = getDayGroupColor(legendItem.num);

    // we use discrete colorscale technique (double each color to cover range)
    // see: https://community.plot.ly/t/colors-for-discrete-ranges-in-heatmaps/7780
    colorscale = [...colorscale, [index / delta, color], [(index + 1) / delta, color]];
  });

  // we need to make sure the colorscale goes up to 1,
  // otherwise the data with the max value will have an extrapolated color
  const lastColorscaleItem = colorscale[colorscale.length - 1];
  if (lastColorscaleItem[0] < 1) {
    colorscale = [...colorscale, [1, lastColorscaleItem[1]]];
  }

  return colorscale;
};
