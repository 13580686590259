import { Button } from 'components/Buttons';
import { ObatResourceCommitmentsTooltip } from 'components/Tooltip/ObatResourceCommitmentsTooltip';
import React, { PureComponent, ReactNode } from 'react';
import { Trash2 } from 'react-feather';
import theme from 'style/theme';
import { isCommittedForDelete } from 'utils/functions/obatResources/commitments/isCommitted';
import { $t } from 'utils/functions/translate';

import { IProps } from './FormDeleteButton.container';

export class FormDeleteButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { obatResourceDeleteProps } = this.props;

    const { obatResource } = obatResourceDeleteProps;
    const isCommitted = isCommittedForDelete(obatResource);

    return isCommitted ? (
      <ObatResourceCommitmentsTooltip obatResource={obatResource} placement={'left'}>
        <Button Icon={<Trash2 size={14} />} isDisabled height={24} noShadow />
      </ObatResourceCommitmentsTooltip>
    ) : (
      <Button
        Icon={<Trash2 size={14} />}
        tooltipProps={{ content: $t('delete'), placement: 'left' }}
        onClick={this.onDeleteClick}
        isLoading={obatResourceDeleteProps.isDeleting}
        bgColor={theme.colors.error}
        height={24}
        noShadow
      />
    );
  }

  private onDeleteClick = (): void => {
    const { openDeletionModal, obatResourceDeleteProps } = this.props;

    if (!obatResourceDeleteProps) {
      return;
    }

    const { obatResource, resourceClass } = obatResourceDeleteProps;
    openDeletionModal(obatResource.id, resourceClass);
  };
}
