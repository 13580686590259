import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectRoutePage, selectRouteResource } from 'redux/routing/selectors';
import { IBreadcrumbLevel } from 'utils/functions/getBreadcrumbList/getBreadcrumbList';
import { rrc } from 'utils/strings/resourceClasses';

import { BreadcrumbPopover as BreadcrumbPopoverComponent } from './BreadcrumbPopover.component';

interface IOwnProps {
  breadcrumbLevel: IBreadcrumbLevel;
  closePopover: () => void;
  isOpen: boolean;
  popoverAnchorEl: (EventTarget & Element) | null;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    routeProject: selectRouteResource(rrc.project)(rootState),
    urlPage: selectRoutePage(rootState),
  };
};

const mapDispatchToProps = {};

export const BreadcrumbPopover = connect(mapStateToProps, mapDispatchToProps)(BreadcrumbPopoverComponent);
