import { FormikProps } from 'formik';
import { ReactNode, RefObject } from 'react';
import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { IRestResource } from 'types/RestResource';
import { IDrawerWidth } from 'utils/configs/drawerWidths';
import { IRestResourceClass } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { RestResourceFormDrawer as Component } from './RestResourceFormDrawer.component';

interface IOwnProps<IResourceFormValues, IResourceRequestParams> {
  formFocusRef: RefObject<HTMLInputElement>;
  renderForm: (formikProps: FormikProps<IResourceFormValues>) => ReactNode;
  getInitialFormValues: () => IResourceFormValues;
  getRequestParams?: (finalFormValues: IResourceFormValues) => IResourceRequestParams;
  getValidationSchema: () => yup.ObjectSchema /*<IResourceFormValues>*/;
  renderSubForm?: () => ReactNode;
  formDrawerWidth: IDrawerWidth;
  resourceClass: IRestResourceClass;
  subFormResources?: IRestResourceClass[];
  forceSubmitDisabled?: (formikProps: FormikProps<IResourceFormValues>) => boolean;
  forceSubmitEnabled?: (formikProps: FormikProps<IResourceFormValues>) => boolean;
  title?: string;
  getCreationSuccessLandingPageUrl?: (routeProjectId: string, createdGeometry: IRestResource) => string;
  isCopy?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps<IResourceFormValues, IResourceRequestParams> = IOwnProps<
  IResourceFormValues,
  IResourceRequestParams
> &
  IInjectedProps;

const mapStateToProps = <IResourceFormValues, IResourceRequestParams>(
  rootState: IRootState,
  ownProps: IOwnProps<IResourceFormValues, IResourceRequestParams>
) => {
  return { initialRestResource: selectFormInitialResourceFromTable(ownProps.resourceClass)(rootState) };
};

const mapDispatchToProps = {};

export const getRestResourceFormDrawer = <IResourceFormValues, IResourceRequestParams>() => {
  return connect<IStateProps, IDispatchProps, IOwnProps<IResourceFormValues, IResourceRequestParams>, IRootState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component as new () => Component<IResourceFormValues, IResourceRequestParams>);
};
