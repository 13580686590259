import { _size } from 'libs/lodash';
import { connect } from 'react-redux';
import { closeFormDrawerAction, openFormDrawerSagaAction } from 'redux/form/actions';
import { selectFormDrawerWidth, selectIsResourceForm } from 'redux/form/selectors';
import { openDeletionModalAction } from 'redux/modals/deletionModal/actions';
import { IRootState } from 'redux/reducer';
import {
  fetchSimulationGroupConfigOptionsSagaAction,
  refreshSimulationGroupSagaAction,
  runSimulationSagaAction,
} from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectIsAllSimulationsEmpty,
  selectIsSimulationInProgress,
  selectSimulationsListByStatus,
  selectVariantOptionsByObat,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { selectIsSeatMissing, selectIsWritePermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { fetchTableInitialDataSagaAction } from 'redux/table/actions';
import { selectTableResources } from 'redux/table/selectors';
import { fk } from 'utils/strings/formKeys';
import { rrc } from 'utils/strings/resourceClasses';

import { ConfigMulti as Component } from './ConfigMulti.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  const routeMultiSimulationGroup = selectRouteResource(rrc.multi_simulation_group)(rootState);
  const tableResources = selectTableResources(rootState)(rrc.simulation, routeMultiSimulationGroup.id);
  const isDataEmpty = !!tableResources && _size(tableResources.full) === 0;

  return {
    isDataEmpty,
    routeMultiSimulationGroup,
    routeProject: selectRouteResource(rrc.project)(rootState),
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
    isSimulationInProgress: selectIsSimulationInProgress(rootState),
    simulationsListByStatus: selectSimulationsListByStatus(rootState),
    isAllSimulationsEmpty: selectIsAllSimulationsEmpty(rootState),
    variantOptionsByObat: selectVariantOptionsByObat(rootState),
    formDrawerWidth: selectFormDrawerWidth(rootState),
    isResourceForm: selectIsResourceForm(rootState),
  };
};

const mapDispatchToProps = {
  fetchSimulationGroupConfigOptions: fetchSimulationGroupConfigOptionsSagaAction,
  fetchTableInitialData: fetchTableInitialDataSagaAction,
  openFormDrawer: openFormDrawerSagaAction,
  closeFormDrawer: closeFormDrawerAction,
  openDeletionModal: openDeletionModalAction,
  refreshSimulationGroup: refreshSimulationGroupSagaAction,
  runSimulation: runSimulationSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: {}) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openCreationFormDrawer: () => dispatchProps.openFormDrawer(fk.restResource),
  openConfigObatFormDrawer: () =>
    dispatchProps.openFormDrawer(fk.configObat, {
      resourceId: stateProps.routeMultiSimulationGroup.id,
      resourceClass: rrc.multi_simulation_group,
    }),
});

export const ConfigMulti = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
