import { _get, _includes } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { _last } from 'libs/lodash';
import { _split } from 'libs/lodash';
import { IRouteResources, IRouteResourcesLists } from 'redux/routing/reducer';
import { organizationMenuPages, projectMenuPages } from 'routing/routes';
import { IResourceTag } from 'utils/functions/getApiUrl/getRestApiUrl/getRestApiUrl';
import { getBreadcrumbList, IBreadcrumbList } from 'utils/functions/getBreadcrumbList/getBreadcrumbList';
import { IRouteParams } from 'utils/functions/routing/getResourcesToUpdateFromRouteParams';
import { IRestResourceClass, rrc } from 'utils/strings/resourceClasses';

import { IRootState } from '../reducer';

export const selectRoute = (rootState: IRootState): string => {
  return rootState.routing.route;
};

export const selectRoutePage = (rootState: IRootState): string => {
  // @ts-ignore (can't be undefined)
  return _split(_last(_split(rootState.routing.route, '/')), '#')[0];
};

export const selectRouteHash = (rootState: IRootState): string | undefined => {
  return rootState.routing.routeHash;
};

export const selectIsRefreshingRoutePage = (rootState: IRootState): boolean => {
  return rootState.routing.isRefreshingRoutePage;
};

export const selectIsRouteResourcesUpToDate = (rootState: IRootState): boolean | undefined => {
  return rootState.routing.isRouteResourcesUpToDate;
};

export const selectBreadcrumbList = (rootState: IRootState): IBreadcrumbList => {
  const routeResources = selectRouteResources(rootState);
  const routeResourcesLists = selectRouteResourcesLists(rootState);

  return getBreadcrumbList(routeResources, routeResourcesLists);
};

export const selectRouteParams = (rootState: IRootState): IRouteParams => {
  return rootState.routing.routeParams;
};

export const selectRouteResource = (resourceClass: string) => {
  return (rootState: IRootState) => {
    // TODO: manage the return type! But once managed the undefined case should be managed too...
    return _get(rootState.routing.routeResources, resourceClass);
  };
};

export const selectRouteResources = (rootState: IRootState): IRouteResources => {
  return rootState.routing.routeResources;
};

export const selectRouteResourcesClasses = (rootState: IRootState): IRestResourceClass[] => {
  // @ts-ignore
  return _keys(rootState.routing.routeResources);
};

export const selectRouteResourcesLists = (rootState: IRootState): IRouteResourcesLists => {
  return rootState.routing.routeResourcesLists;
};

export const selectRouteResourceParentTag = (resourceClass: IRestResourceClass) => (
  rootState: IRootState
): IResourceTag | undefined => {
  const { routeResources } = rootState.routing;

  if (resourceClass === rrc.simulation) {
    if (_has(routeResources, rrc.mono_simulation_group)) {
      const routeMonoSimulationGroup = selectRouteResource(rrc.mono_simulation_group)(rootState);

      return { resourceClass: rrc.mono_simulation_group, resourceId: routeMonoSimulationGroup.id };
    }

    if (_has(routeResources, rrc.multi_simulation_group)) {
      const routeMultiSimulationGroup = selectRouteResource(rrc.multi_simulation_group)(rootState);

      return { resourceClass: rrc.multi_simulation_group, resourceId: routeMultiSimulationGroup.id };
    }
  }
};

export const selectIsWritePermissionMissing = (rootState: IRootState): boolean => {
  const routeProject = selectRouteResource(rrc.project)(rootState);

  return !!routeProject ? routeProject.rights.can_write === 0 : false;
};

export const selectIsAdminPermissionMissing = (rootState: IRootState): boolean => {
  const routePage = selectRoutePage(rootState);
  const routeProject = selectRouteResource(rrc.project)(rootState);
  const routeOrganization = selectRouteResource(rrc.organization)(rootState);

  if (routeProject && routePage === projectMenuPages.projectPermissions) {
    return routeProject.rights.can_admin === 0;
  }

  if (routeOrganization) {
    return routeOrganization.rights.can_admin === 0;
  }

  return false;
};

export const selectIsSeatMissing = (rootState: IRootState): boolean | undefined => {
  const routePage = selectRoutePage(rootState);

  /* Admin don't need a seat to manage permissions */
  if (_includes([projectMenuPages.projectPermissions, organizationMenuPages.organizationPermissions], routePage)) {
    return false;
  }

  const routeProject = selectRouteResource(rrc.project)(rootState);
  const routeOrganization = selectRouteResource(rrc.organization)(rootState);

  return !!routeProject ? !routeProject.is_activated : !!routeOrganization ? !routeOrganization.is_activated : false;
};

export const selectRouteSimulationGroup = (rootState: IRootState) => {
  const routeMultiSimulationGroup = selectRouteResource(rrc.multi_simulation_group)(rootState);
  const routeMonoSimulationGroup = selectRouteResource(rrc.mono_simulation_group)(rootState);

  return routeMultiSimulationGroup || routeMonoSimulationGroup;
};

export const selectRouteSimulationGroupTag = (rootState: IRootState): IResourceTag | undefined => {
  return selectRouteResourceParentTag(rrc.simulation)(rootState);
};

export const selectRouteWeatherTag = (rootState: IRootState): IResourceTag | undefined => {
  const routeWeather = selectRouteResource(rrc.weather)(rootState);

  if (!routeWeather) {
    return undefined;
  }

  if (routeWeather.generic_weather_series) {
    return { resourceClass: rrc.generic_weather_series, resourceId: routeWeather.generic_weather_series.id };
  }

  if (routeWeather.openergy_historical_weather_series) {
    return {
      resourceClass: rrc.openergy_historical_weather_series,
      resourceId: routeWeather.openergy_historical_weather_series.id,
    };
  }

  if (routeWeather.historical_weather_series) {
    return {
      resourceClass: rrc.historical_weather_series,
      resourceId: routeWeather.historical_weather_series.id,
    };
  }
};
