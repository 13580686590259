import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { IRootState } from 'redux/reducer';
import {
  clearFloorplanAction,
  downloadFloorplanSagaAction,
  uploadFloorplanSagaAction,
} from 'redux/restResources/detail/floorspace/actions';
import { selectFloorplan, selectFloorplanIsJustSaved } from 'redux/restResources/detail/floorspace/selectors';
import { selectMyLanguage } from 'redux/restResources/detail/me/selectors';
import { fetchAllRouteResourcesSagaAction, toggleIsRefreshingRoutePageAction } from 'redux/routing/actions';
import { selectIsSeatMissing, selectIsWritePermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { Drawing2D as Drawing2DComponent } from './Drawing2D.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeGeometry = selectRouteResource(rrc.geometry)(rootState);
  const floorspace = routeGeometry.floorspace;
  const downloadApiRequestKey = getDetailApiRequestKey(dra.downloadFloorplan, floorspace.id);
  const uploadApiRequestKey = getDetailApiRequestKey(dra.uploadFloorplan, floorspace.id);

  return {
    routeGeometry,
    floorspace,
    floorplan: selectFloorplan(rootState),
    floorplanIsJustSaved: selectFloorplanIsJustSaved(rootState),
    isLoadingDownload: selectIsApiRequestPending(downloadApiRequestKey)(rootState),
    isLoadingUpload: selectIsApiRequestPending(uploadApiRequestKey)(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
    userMeLanguage: selectMyLanguage(rootState),
  };
};

const mapDispatchToProps = {
  uploadFloorplan: uploadFloorplanSagaAction,
  downloadFloorplan: downloadFloorplanSagaAction,
  clearFloorplan: clearFloorplanAction,
  toggleIsRefreshingRoutePage: toggleIsRefreshingRoutePageAction,
  fetchAllRouteResources: fetchAllRouteResourcesSagaAction,
};

export const Drawing2D = connect(mapStateToProps, mapDispatchToProps)(Drawing2DComponent);
