import { SagaIterator } from 'redux-saga';
import { call, delay, put, putResolve, select, takeLatest } from 'redux-saga/effects';

import {
  closeFormDrawerAction,
  IOpenFormDrawerSagaAction,
  OPEN_FORM_DRAWER_SAGA_ACTION,
  openFormDrawerAction,
} from './actions';
import { selectActiveFormKey } from './selectors';

/* _____ openFormDrawerSaga _____  */

export function* openFormDrawerSaga(payload: IOpenFormDrawerSagaAction['payload']): SagaIterator {
  const { formKey, initialResource } = payload;
  const activeFormKey = yield select(selectActiveFormKey);
  if (activeFormKey) {
    yield putResolve(closeFormDrawerAction());
    yield delay(500);
  }
  yield put(openFormDrawerAction(formKey, initialResource));
}

export function* openFormDrawerActionSaga(action: IOpenFormDrawerSagaAction): SagaIterator {
  yield call(openFormDrawerSaga, action.payload);
}

export function* formActionsSagas(): Generator {
  yield takeLatest(OPEN_FORM_DRAWER_SAGA_ACTION, openFormDrawerActionSaga);
}
