import { _concat } from 'libs/lodash';
import moment from 'moment';
import { IPlotTimeSeries } from 'types/Series';

export const applySpanOffset = (series: IPlotTimeSeries): IPlotTimeSeries => {
  const { datetimeIndex } = series;
  const newFirstIndex = moment
    .utc(datetimeIndex[0])
    .subtract(1, 'hours')
    .toISOString();
  datetimeIndex.pop();

  return { ...series, datetimeIndex: _concat(newFirstIndex, datetimeIndex) };
};
