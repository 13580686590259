import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { IRestResource } from 'types/RestResource';
import { rrc } from 'utils/strings/resourceClasses';

import { SimulationGroupForm as SimulationGroupFormComponent } from './SimulationGroupForm.component';

interface IOwnProps {
  getCreationSuccessLandingPageUrl: (routeProjectId: string, createdGeometry: IRestResource) => string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState, _ownProps: IOwnProps) => {
  return {
    simulationGroupToEdit: selectFormInitialResourceFromTable(rrc.simulation_group)(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
  };
};

const mapDispatchToProps = {};

export const SimulationGroupForm = connect(mapStateToProps, mapDispatchToProps)(SimulationGroupFormComponent);
