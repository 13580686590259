import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { closeDeletionModalAction } from 'redux/modals/deletionModal/actions';
import {
  selectIsDeletionModalOpen,
  selectResourceClassToDelete,
  selectResourceToDelete,
} from 'redux/modals/deletionModal/selectors';
import { deleteObatResourceSagaAction } from 'redux/obatResources/actions';
import { IRootState } from 'redux/reducer';
import { selectMyUser } from 'redux/restResources/detail/me/selectors';
import { deleteRestResourceSagaAction } from 'redux/restResources/generic/actions';
import { selectRouteResource } from 'redux/routing/selectors';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { gra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { DeletionModal as Component } from './DeletionModal.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  const resourceClass = selectResourceClassToDelete(rootState);
  const resourceToDelete = selectResourceToDelete(rootState);
  const isDeleting =
    resourceToDelete && resourceClass
      ? selectIsApiRequestPending(getGenericApiRequestKey(gra.delete, resourceClass, resourceToDelete.id))(rootState)
      : false;

  return {
    routeObat: selectRouteResource(rrc.obat)(rootState),
    isOpen: selectIsDeletionModalOpen(rootState),
    myUser: selectMyUser(rootState),
    resourceClass,
    resourceToDelete,
    isDeleting,
  };
};

const mapDispatchToProps = {
  deleteObatResource: deleteObatResourceSagaAction,
  deleteRestResource: deleteRestResourceSagaAction,
  closeDeletionModal: closeDeletionModalAction,
};

export const DeletionModal = connect(mapStateToProps, mapDispatchToProps)(Component);
