import { connect } from 'react-redux';
import {
  selectModificationMethodOptionsFunction,
  selectObatContentsFlatOptionsList,
  selectVariantValueOptionsFunction,
} from 'redux/enums/selectors';
import { deleteObatResourceSagaAction } from 'redux/obatResources/actions';
import { selectObatResourcesIdsListFunction } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { IModification } from 'types/Obat/Modification';
import { IVariant } from 'types/Obat/Variant';
import { ocfe } from 'utils/configs/enums';
import { rrc } from 'utils/strings/resourceClasses';

import { ModificationForm as ModificationFormComponent } from './ModificationForm.component';

interface IOwnProps {
  close?: () => void;
  modification?: IModification;
  titleIndex?: number;
  variant: IVariant;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IInjectedProps, IOwnProps {}

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return {
    modificationToEdit: ownProps.modification,
    routeObat: selectRouteResource(rrc.obat)(rootState),
    tableRefOptions: selectObatContentsFlatOptionsList(ocfe.modification_table_refs)(rootState),
    selectMethodOptions: selectModificationMethodOptionsFunction(rootState),
    selectVariantValue: selectVariantValueOptionsFunction(rootState),
    selectObatRefsList: selectObatResourcesIdsListFunction(rootState),
  };
};

const mapDispatchToProps = {
  deleteObatResource: deleteObatResourceSagaAction,
};

export type IInjectedProps = IStateProps & IDispatchProps;

export const ModificationForm = connect(mapStateToProps, mapDispatchToProps)(ModificationFormComponent);
