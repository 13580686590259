// tslint:disable:no-any
import { _includes } from 'libs/lodash';
import { _reduce } from 'libs/lodash';

const exceptionsList: string[] = ['comment', 'site_address'];

export const convertFormValuesEmptyStringToNull = (formValues: object): any => {
  return _reduce(
    formValues,
    (newFormValues: object, value: any, key: string): object => {
      return { ...newFormValues, [key]: value === '' ? (_includes(exceptionsList, key) ? '' : null) : value };
    },
    {}
  );
};

export const convertFieldsNullToEmptyString = (formValues: object): any => {
  return _reduce(
    formValues,
    (newFormValues: object, value: any, key: string): object => {
      return { ...newFormValues, [key]: value === null ? '' : value };
    },
    {}
  );
};
