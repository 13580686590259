import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

const genericRequestActionsTuple = stringsTuple(
  'retrieve',
  'list',
  'create',
  'edit',
  'editMulti',
  'delete',
  'copy',
  'detailRoute'
);
export type IGenericRequestAction = typeof genericRequestActionsTuple[number];
export const gra = mapStrings<IGenericRequestAction>(genericRequestActionsTuple);

const detailRequestActionsTuple = stringsTuple(
  'downloadFloorplan',
  'uploadFloorplan',
  'importGeometry',
  'uploadGeometry',
  'exportGeometry',
  'downloadThreejs',
  'fetchObatContents',
  'uploadObatFile',
  'importObat',
  'exportObat',
  'exportGbxml',
  'projection',
  'fetchSimulation',
  'fetchSurface',
  'downloadMonthlyResultsData',
  'exportEplusOutputs',
  'exportHourlyCsv',
  'retrieveSimulation',
  'fetchSimulationGroupConfigOptions',
  'listSimulations',
  'runSimulation',
  'uploadWeatherData',
  'importWeatherData',
  'fetchWeatherSeries',
  'fetchDegreeDaysData',
  'exportWeatherFile',
  'exportDegreeDaysFile',
  'fetchExpandedPermissions',
  'changePassword',
  'updateObatConfig',
  'addSimulation',
  'updateSimulation',
  'deleteSimulation',
  'configWeather',
  'autoConfigWeather',
  'fetchOpenergyHistoricalWeatherSeriesConfigOptions',
  'manageSeat',
  'spendDailySeat',
  'fetchTemplates'
);
export type IDetailRequestAction = typeof detailRequestActionsTuple[number];
export const dra = mapStrings<IDetailRequestAction>(detailRequestActionsTuple);
