import { connect } from 'react-redux';
import { toggleReorderingAction } from 'redux/form/actions';
import { selectFormInitialResourceFromTable, selectIsReordering } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { orc } from 'utils/strings/resourceClasses';

import { DayGroupsFormSection as Component } from './DayGroupsFormSection.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    calendar: selectFormInitialResourceFromTable(orc.calendar)(rootState),
    isReordering: selectIsReordering(rootState),
  };
};

const mapDispatchToProps = {
  toggleReordering: toggleReorderingAction,
};

export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

export const DayGroupsFormSection = connect(mapStateToProps, mapDispatchToProps)(Component);
