import { _map } from 'libs/lodash';
import { Shape } from 'plotly.js';
import { PlotParams } from 'react-plotly.js';
import theme from 'style/theme';
import { IHeatmap } from 'types/Obat/Calendar';
import { enums } from 'utils/configs/enums';
import { $tEnums } from 'utils/functions/translate';

export const getCalendarHeatmapLayout = (
  dayGroups: IHeatmap['day_groups'],
  monthsTicks: IHeatmap['months_ticks'],
  monthsSeparators: IHeatmap['months_separators']
): PlotParams['layout'] => {
  const daysIndexText = _map(enums.obat.obat_contents.day_types, (day: string) => $tEnums(day).slice(0, 3));
  const daysIndexValues = Array.from({ length: daysIndexText.length }, (_: null, index: number) => index); // [0, 1, ..., 6]

  const weeksNb = dayGroups.columns.length;
  const daysNb = 7;
  const monthsIndexValues = _map(monthsTicks, (monthTick: number) => monthTick * weeksNb - 0.5); // normalized grid to plotly grid
  const monthsIndexText = _map(enums.obat.obat_contents.month_types, (month: string) => $tEnums(month).slice(0, 3));

  const shapes = _map(
    monthsSeparators,
    (edge: number[][]): Partial<Shape> => ({
      type: 'line',
      line: {
        color: '#696969',
        width: 2,
      },
      x0: edge[0][0] * weeksNb - 0.5, // normalized grid to plotly grid
      y0: edge[0][1] * daysNb - 0.5, // normalized grid to plotly grid
      x1: edge[1][0] * weeksNb - 0.5,
      y1: edge[1][1] * daysNb - 0.5,
    })
  );

  return {
    height: 130, // fixed height
    margin: {
      l: 30,
      r: 0,
      t: 0,
      b: 15,
    },
    yaxis: {
      zeroline: false, // we don't want axis in background
      showgrid: false, // hide grid (appear on ticks)
      autorange: 'reversed', // top: monday, bottom: sunday
      tickmode: 'array', // array ticks
      tickvals: daysIndexValues,
      ticktext: daysIndexText,
      ticks: '', // no ticks
    },
    xaxis: {
      zeroline: false, // we don't want axis in background
      showgrid: false, // hide grid (appear on ticks)
      tickmode: 'array', // array ticks
      tickvals: monthsIndexValues,
      ticktext: monthsIndexText,
      ticks: '', // no ticks
    },
    shapes, // month edges
    plot_bgcolor: theme.colors.transparent,
    paper_bgcolor: theme.colors.transparent,
  };
};
