// tslint:disable:no-any
import { CheckboxInput } from 'components/Forms/Inputs/BooleanInputs/CheckboxInput';
import { ICheckboxInputProps } from 'components/Forms/Inputs/BooleanInputs/CheckboxInput/CheckboxInput.component';
import React, { PureComponent, ReactNode } from 'react';
import { displayFormFieldError } from 'utils/functions/forms/displayFormFieldError/displayFormFieldError';

interface IProps extends Pick<ICheckboxInputProps, Exclude<keyof ICheckboxInputProps, 'value' | 'onSelect'>> {
  formikProps: any;
  onSelect?: any;
}

export class FormikCheckboxInput extends PureComponent<IProps> {
  public render(): ReactNode {
    const { formikProps, field, onSelect, ...checkboxInputProps } = this.props;

    return (
      <CheckboxInput
        {...checkboxInputProps}
        field={field}
        value={formikProps.values[field]}
        errorText={displayFormFieldError(field, formikProps)}
        onSelect={onSelect || formikProps.setFieldValue}
      />
    );
  }
}
