import { _includes } from 'libs/lodash';
import { selectResourceInTableResources } from 'redux/table/selectors';
import { fk, IFormKey } from 'utils/strings/formKeys';
import { IApiResourceClass } from 'utils/strings/resourceClasses';
import { IRootState } from '../reducer';

export const selectFormInitialResourceFromTable = (formResourceClass: IApiResourceClass) => {
  // tslint:disable-next-line:no-any
  return (rootState: IRootState): any => {
    const { initialResource } = rootState.form;

    if (!initialResource) {
      return;
    }

    const { resourceId, resourceClass } = initialResource;

    if (resourceId && resourceClass === formResourceClass) {
      return selectResourceInTableResources(rootState)(resourceId);
    }
  };
};

export const selectIsReordering = (rootState: IRootState): boolean => rootState.form.isReordering;

export const selectIsFormDrawerCollapsed = (rootState: IRootState): boolean => rootState.form.drawer.isCollapsed;

export const selectIsFormDrawerOpen = (formKey: IFormKey) => (rootState: IRootState): boolean => {
  return rootState.form.drawer.formKey === formKey && !selectIsFormDrawerCollapsed(rootState);
};

export const selectActiveFormKey = (rootState: IRootState): IFormKey | undefined => rootState.form.drawer.formKey;

export const selectIsResourceForm = (rootState: IRootState): boolean =>
  _includes([fk.restResource, fk.obatResource], rootState.form.drawer.formKey);

export const selectHasFormToReset = (rootState: IRootState): boolean => rootState.form.hasToReset;

export const selectFormDrawerWidth = (rootState: IRootState): number =>
  rootState.form.drawer.formKey && !selectIsFormDrawerCollapsed(rootState) ? rootState.form.drawer.width : 0;
