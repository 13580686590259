import { connect } from 'react-redux';
import { closeNavigationModalAction, openNavigationModalAction } from 'redux/modals/navigationModal/actions';
import { isNavigationModalOpen } from 'redux/modals/navigationModal/selectors';
import { IRootState } from 'redux/reducer';

import { LeaveFloorspaceModal as Component } from './LeaveFloorspaceModal.component';

interface IOwnProps {
  when: () => boolean | boolean;
  save: () => void;
  isLoading: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IInjectedProps, IOwnProps {}

const mapStateToProps = (rootState: IRootState) => {
  return {
    isOpen: isNavigationModalOpen(rootState),
  };
};

const mapDispatchToProps = {
  openNavigationModal: openNavigationModalAction,
  closeNavigationModal: closeNavigationModalAction,
};

export type IInjectedProps = IStateProps & IDispatchProps;

export const LeaveFloorspaceModal = connect(mapStateToProps, mapDispatchToProps)(Component);
