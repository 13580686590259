import Grid from '@material-ui/core/Grid';
import { SimpleCard } from 'components/Cards';
import { CloseSubFormButton } from 'components/Forms/Buttons/SubFormButtons';
import { FormikButtonGroupInput } from 'components/Forms/Inputs/MultiSelectInputs/FormikButtonGroupInput/FormikButtonGroupInput.component';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceForm } from 'components/Forms/ObatResourceForm';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { _map } from 'libs/lodash';
import { _omit } from 'libs/lodash';
import { _range } from 'libs/lodash';
import { getYupNumberValidation, getYupStringArrayValidation, getYupStringValidation } from 'libs/yup';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { IPeriodRequestParams } from 'types/Obat/Period';
import { dayNameList, dayNameOptions, dayNumberList, monthNameOptions } from 'utils/configs/optionsLists';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { ISelectOption, ISelectOptions } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { ordinalSuffix } from 'utils/functions/ordinalSuffix';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './PeriodForm.container';

export interface IPeriodFormValues {
  affected_day_types: string[];
  day_group: string;
  duration: number | '';
  endMode: string;
  end_day_type: string;
  end_month: number | '';
  end_number: number | '';
  startMode: string;
  start_day_type: string;
  start_month: number | '';
  start_number: number | '';
  weak_ref: string;
}

const ObatResourceForm = getObatResourceForm<IPeriodFormValues, IPeriodRequestParams>();

export class PeriodForm extends PureComponent<IProps> {
  public render(): ReactNode {
    const { periodToEdit, close, titleIndex, isInitiallyCollapsed } = this.props;

    const periodFormTitle = periodToEdit ? `${titleIndex}. ${periodToEdit.weak_ref}` : `${$t('period')} ${titleIndex}`;

    return (
      <Container>
        <SimpleCard>
          {!!close && <CloseSubFormButton onClick={close} />}
          <Section noDivider isInitiallyCollapsed={!!isInitiallyCollapsed} title={periodFormTitle} marginTop={-10}>
            <ObatResourceForm
              resourceClass={orc.period}
              initialObatResource={periodToEdit}
              renderForm={this.renderForm()}
              getInitialFormValues={this.getInitialFormValues}
              getValidationSchema={this.getValidationSchema}
              getRequestParams={this.getRequestParams}
            />
          </Section>
        </SimpleCard>
      </Container>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IPeriodFormValues>): ReactNode => {
      const { startMode, endMode } = formikProps.values;
      const { myLanguage } = this.props;

      const renderDateModeForm = (mode: 'start' | 'end'): ReactNode => {
        const dayOrdinalOptions = _map(
          dayNumberList,
          (dayNumber: number): ISelectOption => {
            return {
              value: dayNumber.toString(),
              label:
                dayNumber.toString() + (dayNumber === 1 || myLanguage === 'en' ? $t(ordinalSuffix(dayNumber)) : ''),
            };
          }
        );

        return (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormikDropdownInput
                field={`${mode}_number`}
                formikProps={formikProps}
                options={dayOrdinalOptions}
                label={$t('day')}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormikDropdownInput
                field={`${mode}_month`}
                formikProps={formikProps}
                options={monthNameOptions}
                label={$t('month')}
                required
              />
            </Grid>
            <Grid item xs={7} />
          </Grid>
        );
      };

      const renderDurationModeForm = (): ReactNode => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <FormikWriteInput field={'duration'} formikProps={formikProps} label={$t('durationWithUnit')} required />
            </Grid>
          </Grid>
        );
      };

      const renderRelativeDateModeForm = (mode: 'start' | 'end'): ReactNode => {
        const numberOptions = _map(_range(1, 5), (option: number) => {
          return { value: option.toString(), label: option + $t(ordinalSuffix(option)) };
        });

        return (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormikDropdownInput
                field={`${mode}_number`}
                formikProps={formikProps}
                options={numberOptions}
                label={$t('number')}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormikDropdownInput
                field={`${mode}_day_type`}
                formikProps={formikProps}
                options={dayNameOptions}
                label={$t('weekday')}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <SlashDiv>{$t('of')}</SlashDiv>
            </Grid>
            <Grid item xs={4}>
              <FormikDropdownInput
                field={`${mode}_month`}
                formikProps={formikProps}
                options={monthNameOptions}
                label={$t('month')}
                required
              />
            </Grid>
          </Grid>
        );
      };

      const renderModeSelectForm = (mode: 'start' | 'end'): ReactNode => {
        let modeOptions: ISelectOptions = [
          { value: 'date', label: $t('date') },
          { value: 'relativeDate', label: $t('relativeDate') },
        ];
        if (mode === 'end') {
          modeOptions = [...modeOptions, { value: 'duration', label: $t('duration') }];
        }

        return (
          <FormikDropdownInput
            field={`${mode}Mode`}
            formikProps={formikProps}
            options={modeOptions}
            onSelect={this.onModeSelect(formikProps)}
            required
          />
        );
      };

      const affectedDayTypesOptions: ISelectOptions = _map(
        dayNameList,
        (dayName: string): ISelectOption => {
          return { value: dayName, label: $tEnums(dayName).slice(0, 3) };
        }
      );

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput field={'weak_ref'} formikProps={formikProps} label={$t('name')} required />
          </Grid>
          <Grid item xs={12}>
            <FormikButtonGroupInput
              field={'affected_day_types'}
              formikProps={formikProps}
              options={affectedDayTypesOptions}
            />
          </Grid>
          <Grid item xs={12}>
            {renderModeSelectForm('start')}
          </Grid>
          <Grid item xs={12}>
            {this.isDateMode(startMode) ? renderDateModeForm('start') : renderRelativeDateModeForm('start')}
          </Grid>
          <Grid item xs={12}>
            {renderModeSelectForm('end')}
          </Grid>
          <Grid item xs={12}>
            {this.isDateMode(endMode)
              ? renderDateModeForm('end')
              : this.isDurationMode(endMode)
              ? renderDurationModeForm()
              : renderRelativeDateModeForm('end')}
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): IPeriodFormValues => {
    const { dayGroup, periodToEdit } = this.props;

    return {
      day_group: dayGroup.id,
      weak_ref: setFormInitialValue(periodToEdit, 'weak_ref'),
      affected_day_types: setFormInitialValue(periodToEdit, 'affected_day_types', dayNameList),
      startMode: periodToEdit ? (periodToEdit.start_day_type ? 'relativeDate' : 'date') : 'date',
      start_day_type: setFormInitialValue(periodToEdit, 'start_day_type'),
      start_number: setFormInitialValue(periodToEdit, 'start_number', '1'),
      start_month: setFormInitialValue(periodToEdit, 'start_month', '1'),
      endMode: periodToEdit
        ? periodToEdit.duration
          ? 'duration'
          : periodToEdit.end_day_type
          ? 'relativeDate'
          : 'date'
        : 'date',
      end_day_type: setFormInitialValue(periodToEdit, 'end_day_type'),
      end_number: setFormInitialValue(periodToEdit, 'end_number', '31'),
      end_month: setFormInitialValue(periodToEdit, 'end_month', '12'),
      duration: setFormInitialValue(periodToEdit, 'duration'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IPeriodFormValues> => {
    return yup.object().shape({
      weak_ref: getYupStringValidation(true, true),
      day_group: getYupStringValidation(true),
      affected_day_types: getYupStringArrayValidation(true),
      startMode: getYupStringValidation(true),
      start_day_type: yup.string().when(['startMode'], {
        is: this.isRelativeDateMode,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
      start_number: getYupNumberValidation(true),
      start_month: getYupNumberValidation(true),
      endMode: getYupStringValidation(true),
      end_day_type: yup.string().when(['endMode'], {
        is: this.isRelativeDateMode,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
      end_number: yup.number().when(['endMode'], {
        is: !this.isDurationMode,
        then: getYupNumberValidation(true),
        otherwise: getYupNumberValidation(false),
      }),
      end_month: yup.number().when(['endMode'], {
        is: !this.isDurationMode,
        then: getYupNumberValidation(true),
        otherwise: getYupNumberValidation(false),
      }),
      duration: yup.number().when(['endMode'], {
        is: this.isDurationMode,
        then: getYupNumberValidation(true),
        otherwise: getYupNumberValidation(false),
      }),
    }) as yup.ObjectSchema<IPeriodFormValues>;
  };

  private getRequestParams = (formValues: IPeriodFormValues): IPeriodRequestParams => {
    const { periodToEdit } = this.props;
    const { startMode, start_day_type, endMode, end_day_type, duration } = formValues;

    const finalFormValues = {
      ...formValues,
      start_day_type: this.isRelativeDateMode(startMode) ? start_day_type : '',
      end_day_type: this.isRelativeDateMode(endMode) ? end_day_type : '',
      duration: this.isDurationMode(endMode) ? duration : '',
    };

    const initialFormValues = this.getInitialFormValues();
    const filteredFinalFormValues = periodToEdit
      ? filterEditedValues(initialFormValues, finalFormValues)
      : finalFormValues;
    const requestParamsFields = _omit(filteredFinalFormValues, ['startMode', 'endMode']);
    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams as IPeriodRequestParams;
  };

  private onModeSelect = (formikProps: FormikProps<IPeriodFormValues>) => {
    return (field: keyof IPeriodFormValues, selectedValue: string): void => {
      formikProps.setFieldValue(field, selectedValue);

      if (field === 'startMode') {
        if (this.isRelativeDateMode(selectedValue)) {
          formikProps.setFieldValue('start_number', '');
          formikProps.setFieldValue('start_day_type', '');
          formikProps.setFieldValue('start_month', '');
        } else {
          formikProps.setFieldValue('start_number', '1');
          formikProps.setFieldValue('start_month', '1');
        }
      } else {
        if (this.isRelativeDateMode(selectedValue)) {
          formikProps.setFieldValue('end_number', '');
          formikProps.setFieldValue('end_day_type', '');
          formikProps.setFieldValue('end_month', '');
        } else if (this.isDateMode(selectedValue)) {
          formikProps.setFieldValue('end_number', '31');
          formikProps.setFieldValue('end_month', '12');
        }
      }
    };
  };

  private isDateMode = (mode: IPeriodFormValues['startMode'] | IPeriodFormValues['endMode']) => mode === 'date';
  private isRelativeDateMode = (mode: IPeriodFormValues['startMode'] | IPeriodFormValues['endMode']) =>
    mode === 'relativeDate';
  private isDurationMode = (mode: IPeriodFormValues['startMode'] | IPeriodFormValues['endMode']) => mode === 'duration';
}

const Container = styled.div`
  margin-top: 16px;
`;

const SlashDiv = styled.div`
  padding-top: 20px;
  text-align: center;
`;
