import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

export const FilterTextInput = withStyles({
  root: {
    '& .MuiInput-input': {
      padding: '8px 10px',
    },
  },
})(TextField);
