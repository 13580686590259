import { RestResourcesRequests } from 'api/requests/RestResourcesRequests';
import { _get, _includes } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequestCallbackSuccessAction, apiRequestErrorAction } from 'redux/apiRequests/actions';
import { apiRequestSaga } from 'redux/apiRequests/sagas';
import { selectApiRequestErrors } from 'redux/apiRequests/selectors';
import { closeFormDrawerAction } from 'redux/form/actions';
import { closeDailySeatModalAction } from 'redux/modals/dailySeatModal/actions';
import { openNoFreeSeatsModalAction } from 'redux/modals/noFreeSeatsModal/actions';
import { openUserCreationModalAction } from 'redux/modals/userCreationModal/actions';
import { selectMyUser } from 'redux/restResources/detail/me/selectors';
import { createRestResourceSaga, listRestResourcesSaga } from 'redux/restResources/generic/sagas';
import { fetchSingleRouteResourceSaga } from 'redux/routing/sagas';
import { selectRoutePage, selectRouteResource } from 'redux/routing/selectors';
import { fetchTableUpdatedDataSaga } from 'redux/table/sagas';
import { organizationMenuPages } from 'routing/routes';
import { getDetailApiRequestKey, getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dr } from 'utils/strings/detailRoutes';
import { hm } from 'utils/strings/httpMethods';
import { dra, gra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import {
  CREATE_USER_SAGA_ACTION,
  FETCH_USER_ORGANIZATION_PERMISSIONS_SAGA_ACTION,
  ICreateUserSagaAction,
  IFecthUserOrganizationPermissionsSagaAction,
  IInviteGuestSagaAction,
  IManageSeatSagaAction,
  INVITE_GUEST_SAGA_ACTION,
  ISpendDailySeatsSagaAction,
  MANAGE_SEAT_SAGA_ACTION,
  SPEND_DAILY_SEATS_SAGA_ACTION,
  updateUserOrganizationPermissionsAction,
} from './actions';

export function* inviteGuestSaga(
  payload: IInviteGuestSagaAction['payload'],
  _isCalledAfterCreation: boolean = false
): SagaIterator {
  const { requestParams } = payload;

  const apiRequestKey = getGenericApiRequestKey(gra.create, rrc.user_organization_permission);
  const apiUrlParams = { resourceClass: rrc.user_organization_permission };
  const routeOrganization = yield select(selectRouteResource(rrc.organization));

  yield call(apiRequestSaga, apiRequestKey, true, RestResourcesRequests.create, apiUrlParams, {
    ...requestParams,
    organization: routeOrganization.id,
  });

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    yield call(fetchTableUpdatedDataSaga, { resourceClass: rrc.user_organization_permission });
    yield put(closeFormDrawerAction());
    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  } else {
    const message = _get(apiRequestErrors, 'GLOBAL[0].message');
    /* TODO: enhance this condition */
    if (message === 'user with given email not found') {
      yield put(openUserCreationModalAction(requestParams.user_email));
    }

    const detailedCode = _get(apiRequestErrors, 'user[0].detailed_code');
    if (detailedCode === 'NotUniqueTogether') {
      yield put(apiRequestErrorAction(apiRequestKey, { user_email: apiRequestErrors.user }));
    }
  }
}

export function* inviteGuestActionSaga(action: IInviteGuestSagaAction): SagaIterator {
  yield call(inviteGuestSaga, action.payload);
}

export function* createUserSaga(payload: ICreateUserSagaAction['payload']): SagaIterator {
  const { requestParams } = payload;

  yield call(createRestResourceSaga, {
    requestParams,
    resourceClass: rrc.user,
  });

  yield call(inviteGuestSaga, { requestParams: { user_email: requestParams.email } }, true);
}

export function* createUserActionSaga(action: ICreateUserSagaAction): SagaIterator {
  yield call(createUserSaga, action.payload);
}

export function* fetchUserOrganizationPermissionsSaga(
  payload: IFecthUserOrganizationPermissionsSagaAction['payload']
): SagaIterator {
  const { organizationId } = payload;

  const queryParams = { organization: organizationId };

  const userPermissions = yield call(listRestResourcesSaga, rrc.user_organization_permission, undefined, queryParams);

  yield put(updateUserOrganizationPermissionsAction(userPermissions));
}

export function* fetchUserOrganizationPermissionsActionSaga(
  action: IFecthUserOrganizationPermissionsSagaAction
): SagaIterator {
  yield call(fetchUserOrganizationPermissionsSaga, action.payload);
}

export function* manageSeatSaga(payload: IManageSeatSagaAction['payload']): SagaIterator {
  const { takeOrLeave, userOrganizationPermissionId } = payload;

  const routePage = yield select(selectRoutePage);
  const routeOrganization = yield select(selectRouteResource(rrc.organization));
  const routeProject = yield select(selectRouteResource(rrc.project));

  let apiRequestKey;
  let apiRequestErrors;

  const detailRoute = takeOrLeave === 'take' ? dr.take_up_seat : dr.leave_seat;

  if (userOrganizationPermissionId) {
    /* If an id is precised, only an admin could have done this request from Seats page */
    apiRequestKey = getDetailApiRequestKey(dra.manageSeat, userOrganizationPermissionId);
    const apiUrlParams = {
      resourceClass: rrc.user_organization_permission,
      resourceId: userOrganizationPermissionId,
      detailRoute,
    };

    yield call(apiRequestSaga, apiRequestKey, true, RestResourcesRequests.detailRoute, apiUrlParams, hm.patch);
    apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  } else {
    /* If no id, it's necessarily a request from my user */
    const organizationId = _includes(organizationMenuPages, routePage)
      ? routeOrganization.id
      : routeProject.organization.id;

    const myUser = yield select(selectMyUser);
    apiRequestKey = getDetailApiRequestKey(dra.manageSeat, myUser.id);
    const apiUrlParams = {
      resourceClass: rrc.organization,
      resourceId: organizationId,
      detailRoute,
    };

    yield call(apiRequestSaga, apiRequestKey, true, RestResourcesRequests.detailRoute, apiUrlParams, hm.patch);

    apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
    if (apiRequestErrors) {
      const message = _get(apiRequestErrors, 'GLOBAL[0].message');
      /* TODO: enhance this condition */
      if (message === 'There are no more available seats on this organization') {
        yield put(openNoFreeSeatsModalAction());
      }
    }
  }

  if (!apiRequestErrors) {
    if (_includes(organizationMenuPages, routePage)) {
      yield call(fetchSingleRouteResourceSaga, rrc.organization);
      if (routePage === organizationMenuPages.seats) {
        yield call(fetchUserOrganizationPermissionsSaga, { organizationId: routeOrganization.id });
      }
    } else {
      yield call(fetchSingleRouteResourceSaga, rrc.project);
    }

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }
}

export function* manageSeatActionSaga(action: IManageSeatSagaAction): SagaIterator {
  yield call(manageSeatSaga, action.payload);
}

export function* spendDailySeatsSaga(payload: ISpendDailySeatsSagaAction['payload']): SagaIterator {
  const { amount } = payload;
  const routeOrganization = yield select(selectRouteResource(rrc.organization));
  const apiRequestKey = getDetailApiRequestKey(dra.spendDailySeat, routeOrganization.id);
  const apiUrlParams = {
    resourceClass: rrc.organization,
    resourceId: routeOrganization.id,
    detailRoute: dr.spend_daily_seats,
  };
  const requestParams = { amount };

  yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.patch,
    {},
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    yield call(fetchSingleRouteResourceSaga, rrc.organization);
    yield put(closeDailySeatModalAction());

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }
}

export function* spendDailySeatsActionSaga(action: ISpendDailySeatsSagaAction): SagaIterator {
  yield call(spendDailySeatsSaga, action.payload);
}

export function* organizationActionsSagas(): Generator {
  yield takeLatest(INVITE_GUEST_SAGA_ACTION, inviteGuestActionSaga);
  yield takeLatest(CREATE_USER_SAGA_ACTION, createUserActionSaga);
  yield takeLatest(FETCH_USER_ORGANIZATION_PERMISSIONS_SAGA_ACTION, fetchUserOrganizationPermissionsActionSaga);
  yield takeLatest(MANAGE_SEAT_SAGA_ACTION, manageSeatActionSaga);
  yield takeLatest(SPEND_DAILY_SEATS_SAGA_ACTION, spendDailySeatsActionSaga);
}
