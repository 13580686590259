import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { closeFormDrawerAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import {
  clearMonthlyResultsAction,
  downloadMonthlyResultsSectionDataSagaAction,
  refreshSimulationGroupSagaAction,
  updateMonthlyResultsModeAction,
  updateMonthlyResultsSectionAction,
  updateSimulationAction,
} from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectIsAllSimulationsEmpty,
  selectMonthlyResultsMode,
  selectMonthlyResultsSection,
  selectMonthlyResultsSectionTabs,
  selectMonthlyResultsTemplate,
  selectSimulation,
  selectSimulationsList,
  selectSimulationsListByStatus,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { IRestResource } from 'types/RestResource';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';
import { rv } from 'utils/strings/results';

import { MonthlyResultsPage as Component } from './MonthlyResultsPage.component';

interface IOwnProps {
  routeSimulationGroup: IRestResource;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = IOwnProps & IStateProps & IDispatchProps;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  const isMulti = ownProps.routeSimulationGroup.model === 'multisimulationgroup';
  const isMono = ownProps.routeSimulationGroup.model === 'monosimulationgroup';
  const routeSimulationGroupResourceClass = isMono ? rrc.mono_simulation_group : rrc.multi_simulation_group;
  const simulation = selectSimulation(rootState);
  const isLoadingBySimulationResults =
    simulation &&
    selectIsApiRequestPending(getDetailApiRequestKey(dra.downloadMonthlyResultsData, simulation.id))(rootState);
  const isLoadingAggregateResults = selectIsApiRequestPending(
    getDetailApiRequestKey(dra.downloadMonthlyResultsData, rv.aggregate)
  )(rootState);
  const section = selectMonthlyResultsSection(rootState);

  return {
    isMono,
    isMulti,
    routeSimulationGroupResourceClass,
    section,
    simulation,
    routeProject: selectRouteResource(rrc.project)(rootState),
    isLoadingResults: isLoadingBySimulationResults || isLoadingAggregateResults,
    template: selectMonthlyResultsTemplate(rootState),
    simulationsList: selectSimulationsList(rootState),
    simulationsListByStatus: selectSimulationsListByStatus(rootState),
    isAllSimulationsEmpty: selectIsAllSimulationsEmpty(rootState),
    resultsMode: selectMonthlyResultsMode(rootState),
    sectionTabs: selectMonthlyResultsSectionTabs(rootState),
  };
};

const mapDispatchToProps = {
  downloadMonthlyResultsSectionData: downloadMonthlyResultsSectionDataSagaAction,
  updateSection: updateMonthlyResultsSectionAction,
  updateResultsMode: updateMonthlyResultsModeAction,
  updateSimulation: updateSimulationAction,
  refreshSimulationGroup: refreshSimulationGroupSagaAction,
  clearMonthlyResults: clearMonthlyResultsAction,
  closeFormDrawer: closeFormDrawerAction,
};

export const MonthlyResultsPage = connect(mapStateToProps, mapDispatchToProps)(Component);
