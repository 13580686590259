import { Plot } from 'components/Plot';
import { PlotLegend } from 'components/PlotLegend';
import { IPlotLegendItem } from 'components/PlotLegend/PlotLegend.component';
import { _filter } from 'libs/lodash';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { IHeatmap, IHeatmapLegendItem } from 'types/Obat/Calendar';
import { getCalendarHeatmapData } from 'utils/functions/obatResources/calendar/calendarHeatmap/getCalendarHeatmapData';
import { getCalendarHeatmapLayout } from 'utils/functions/obatResources/calendar/calendarHeatmap/getCalendarHeatmapLayout';
import { getDayGroupColor } from 'utils/functions/obatResources/calendar/calendarHeatmap/getColorByLegendItem';
import { calculateCalendarHeatmap } from 'utils/obatContents/calculateCalendarHeatmap';

import { IProps } from './CalendarPlot.container';

interface IState {
  heatmap: IHeatmap | undefined;
}

export class CalendarPlot extends PureComponent<IProps, IState> {
  public state: IState = {
    heatmap: this.props.heatmap,
  };

  public componentDidMount(): void {
    if (!this.state.heatmap) {
      this.calculateHeatmap();
    }
  }

  public componentDidUpdate(): void {
    const { heatmap } = this.props;
    if (!heatmap) {
      this.calculateHeatmap();
    }
  }

  public render(): ReactNode {
    const { heatmap } = this.state;

    if (!heatmap) {
      return null;
    }

    const legendItems = _map(
      _filter(heatmap.legend, (heatmapLegendItem: IHeatmapLegendItem): boolean => {
        return heatmapLegendItem.num >= 0;
      }),
      (heatmapLegendItem: IHeatmapLegendItem): IPlotLegendItem => {
        return {
          title: heatmapLegendItem.ref,
          dotColor: getDayGroupColor(heatmapLegendItem.num),
        };
      }
    );

    const heatmapData = getCalendarHeatmapData(heatmap.legend, heatmap.day_groups, heatmap.days);

    const heatmapLayout = getCalendarHeatmapLayout(heatmap.day_groups, heatmap.months_ticks, heatmap.months_separators);

    const heatmapConfig = { displayModeBar: false, responsive: true };

    return (
      <>
        <StyledPlot data={heatmapData} layout={heatmapLayout} config={heatmapConfig} />
        <LegendContainer>
          <PlotLegend legendItems={legendItems} itemsPerLine={5} />
        </LegendContainer>
      </>
    );
  }

  private calculateHeatmap = () => {
    const { calendar, updateCalendarHeatmap } = this.props;
    const calculatedHeatmap = calculateCalendarHeatmap(calendar);
    this.setState({ heatmap: calculatedHeatmap });
    updateCalendarHeatmap(calendar.ref, calculatedHeatmap);
  };
}

const StyledPlot = styled(Plot)`
  width: 100%;
` as typeof Plot;

const LegendContainer = styled.div`
  padding: 0 12px 0 30px;
`;
