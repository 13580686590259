import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { ITableResources } from 'redux/table/reducer';
import {
  selectHasTableFilteredResourcesSelectedResources,
  selectSelectedTableResourcesIdList,
} from 'redux/table/selectors';
import { IDatatableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';

import { Datatable as Component } from './Datatable.component';

export interface IOwnProps {
  tableColumnsParams: IDatatableColumnParams[];
  tableResources: ITableResources | undefined;
  defaultSort?: {
    columnId: string;
    order: 'asc' | 'desc';
  };
  rowIdKey?: string;
  isSelectable?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return {
    rowIdKey: ownProps.rowIdKey || 'id',
    hasTableFilteredResourcesSelectedResources: selectHasTableFilteredResourcesSelectedResources(rootState),
    selectedTableResourcesIdList: selectSelectedTableResourcesIdList(rootState),
  };
};

const mapDispatchToProps = {};

export const Datatable = connect(mapStateToProps, mapDispatchToProps)(Component);
