import { IApiResourceClass } from 'utils/strings/resourceClasses';

import { CLOSE_DELETION_MODAL, IDeletionModalActions, OPEN_DELETION_MODAL } from './actions';

export interface IDeletionModalState {
  isOpen: boolean;
  resourceId?: string;
  resourceClass?: IApiResourceClass;
}

export const initialState = {
  isOpen: false,
  resourceId: undefined,
  resourceClass: undefined,
};

export const deletionModalReducer = (
  state: IDeletionModalState = initialState,
  action: IDeletionModalActions
): IDeletionModalState => {
  switch (action.type) {
    case OPEN_DELETION_MODAL:
      return {
        ...state,
        isOpen: true,
        resourceId: action.payload.resourceId,
        resourceClass: action.payload.resourceClass,
      };

    case CLOSE_DELETION_MODAL:
      return { ...state, isOpen: false, resourceId: undefined, resourceClass: undefined };

    default:
      return state;
  }
};
