import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';

import { Page404 as Component } from './Page404.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (_rootState: IRootState) => {
  return {
    // myUser: selectMyUser(rootState),
  };
};

const mapDispatchToProps = {
  //
};

export const Page404 = connect(mapStateToProps, mapDispatchToProps)(Component);
