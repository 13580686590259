import { _find } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { _indexOf } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _pick } from 'libs/lodash';
import { _range } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { _sortBy } from 'libs/lodash';
import { _uniq } from 'libs/lodash';
import {
  IHourlyResultsPlot,
  IHourlyResultsPlotLine,
  IHourlyResultsPlotSeries,
  IHourlyResultsYearData,
  IHourlyResultsYearlyData,
} from 'redux/restResources/detail/simulationGroup/reducer';
import { ISimulationSeries } from 'types/SimulationGroup/Simulation';

const getHourlyResultsPlotIndex = (
  seriesYearData: IHourlyResultsYearData,
  plotSeries: IHourlyResultsPlotSeries
): string[] => {
  /* Merge all series indexes to get the same one for every series */
  const plotSeriesSimulationIds = _keys(plotSeries);

  return _reduce(
    plotSeriesSimulationIds,
    (_index: string[], simulationId: string): string[] => {
      if (_has(seriesYearData, simulationId)) {
        const simulationSeriesIndex = seriesYearData[simulationId].index;

        // @ts-ignore
        return _sortBy(_uniq([..._index, ...simulationSeriesIndex]));
      } else {
        return _index;
      }
    },
    []
  );
};

export const getHourlyResultsPlotLineValues = (
  plotIndex: string[],
  values?: number[],
  valuesFirstIndex?: string
): Array<number | null> => {
  /* Extend series values list with null so that their length matches the length of plotIndex */
  const lineValues: Array<number | null> = _map(_range(_size(plotIndex)), () => null);

  if (!values || !valuesFirstIndex) {
    return lineValues;
  } else {
    lineValues.splice(_indexOf(plotIndex, valuesFirstIndex), _size(values), ...values);

    return lineValues;
  }
};

const getHourlyResultsPlotLines = (
  seriesYearData: IHourlyResultsYearData,
  plotSeries: IHourlyResultsPlotSeries,
  plotIndex: string[]
): IHourlyResultsPlotLine[] => {
  /* Build the list of lines for the plot */
  return _reduce(
    plotSeries,
    (_plotLines: IHourlyResultsPlotLine[], simulationPlotSeries: ISimulationSeries[], simulationId: string) => {
      let simulationPlotSeriesLines;

      if (_has(seriesYearData, simulationId)) {
        /* if there is data for this simulation this year, adapt values of each series for plotIndex */
        const simulationSeriesData = seriesYearData[simulationId];
        const { index: simulationSeriesIndex, ...simulationSeriesValues } = simulationSeriesData;
        const simulationSeriesValuesFirstIndex = simulationSeriesIndex[0];

        const simulationPlotSeriesIds = _map(simulationPlotSeries, 'id');
        const simulationPlotSeriesValues = _pick(simulationSeriesValues, simulationPlotSeriesIds);

        simulationPlotSeriesLines = _map(
          simulationPlotSeriesValues,
          (seriesValues: number[], seriesId: string): IHourlyResultsPlotLine => {
            const series = _find(simulationPlotSeries, ['id', seriesId]);

            const values = getHourlyResultsPlotLineValues(plotIndex, seriesValues, simulationSeriesValuesFirstIndex);
            const text = !!series ? `${series.ref} (${series.simulation.name})` : 'Not Found';
            const measureType = !!series && series.measure_type === 'stamp' ? 'stamp' : 'span';

            return { values, text, measureType };
          }
        );
      } else {
        /* if there is no data for this simulation this year, return for each series null values for each index */
        simulationPlotSeriesLines = _map(
          simulationPlotSeries,
          (series: ISimulationSeries): IHourlyResultsPlotLine => {
            const values = getHourlyResultsPlotLineValues(plotIndex);
            const text = !!series ? `${series.ref} (${series.simulation.name})` : 'Not Found';
            const measureType = !!series && series.measure_type === 'stamp' ? 'stamp' : 'span';

            return { values, text, measureType };
          }
        );
      }

      return [..._plotLines, ...simulationPlotSeriesLines];
    },
    []
  );
};

export const getHourlyResultsPlot = (
  series: IHourlyResultsPlotSeries,
  yearOptions: string[],
  year: string,
  yearsData: IHourlyResultsYearlyData,
  operation: IHourlyResultsPlot['operation']
): IHourlyResultsPlot => {
  const seriesYearData = yearsData[year];
  const index = getHourlyResultsPlotIndex(seriesYearData, series);
  const lines = getHourlyResultsPlotLines(seriesYearData, series, index);

  return { series, yearOptions, year, index, lines, operation };
};
