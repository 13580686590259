import Grid from '@material-ui/core/Grid';
import { FormikSwitchInput } from 'components/Forms/Inputs/BooleanInputs/FormikSwitchInput';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getRestResourceFormDrawer } from 'components/Forms/RestResourceFormDrawer';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { FormikProps } from 'formik';
import { _find, _get, _omit } from 'libs/lodash';
import { getYupBooleanValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IProjectRequestParams } from 'types/Project';
import { fdw } from 'utils/configs/drawerWidths';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { HyperLink } from 'components/Links/HyperLink';
import { navigateRoute } from 'routing/navigateRoute';
import { getOrganizationMenuPageRoute, organizationMenuPages } from 'routing/routes';
import { IProps } from './MyProjectForm.container';

export interface IProjectFormValues {
  name: string;
  organization: string;
  comment: string;
  fromTemplate: boolean;
  template_location: string;
  template_use: string;
  template_age: string;
}

const RestResourceFormDrawer = getRestResourceFormDrawer<IProjectFormValues, IProjectRequestParams>();

export class MyProjectForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public componentDidMount = () => {
    const { fetchGeometriesTemplates, fetchWeathersTemplates, fetchObatsTemplates } = this.props;
    fetchGeometriesTemplates();
    fetchWeathersTemplates();
    fetchObatsTemplates();
  };

  public render(): ReactNode {
    return (
      <RestResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={rrc.project}
        getRequestParams={this.getRequestParams}
        getCreationSuccessLandingPageUrl={this.props.getCreationSuccessLandingPageUrl}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IProjectFormValues>): ReactNode => {
      const {
        projectToEdit,
        organizationOptions,
        templateUseOptions,
        selectTemplateAgeOptions,
        meteonormTemplateOptions,
      } = this.props;
      const { fromTemplate, template_use, organization } = formikProps.values;

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput field={'name'} formikProps={formikProps} required inputRef={this.formFocusRef} />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'organization'}
                formikProps={formikProps}
                options={organizationOptions}
                required
                isDisabled={!!projectToEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput field={'comment'} formikProps={formikProps} multiline />
            </Grid>
            {!projectToEdit && (
              <>
                <Grid item xs={12}>
                  <SimpleTooltip
                    placement={'left'}
                    content={this.renderIsSeatMissingTooltipContent(organization)}
                    interactive
                  >
                    <FormikSwitchInput
                      field={'fromTemplate'}
                      formikProps={formikProps}
                      isDisabled={this.getIsSeatMissing(organization)}
                    />
                  </SimpleTooltip>
                </Grid>
                {fromTemplate && (
                  <>
                    <Grid item xs={12}>
                      <FormikDropdownInput
                        field={'template_location'}
                        formikProps={formikProps}
                        options={meteonormTemplateOptions}
                        label={$t('location')}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikDropdownInput
                        field={'template_use'}
                        formikProps={formikProps}
                        options={templateUseOptions}
                        label={$t('buildingMainUse')}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikDropdownInput
                        field={'template_age'}
                        formikProps={formikProps}
                        options={selectTemplateAgeOptions(template_use)}
                        label={$t('epoch')}
                        required
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </>
      );
    };
  };

  private getIsSeatMissing = (organizationId: string): boolean => {
    const { organizationOptions } = this.props;

    if (!organizationId) {
      return true;
    }

    return !!_get(_find(organizationOptions, ['value', organizationId]), 'isSeatMissing');
  };

  private renderIsSeatMissingTooltipContent = (organizationId: string): ReactNode | undefined => {
    const { organizationOptions } = this.props;

    const selectedOrganizationOption = _find(organizationOptions, ['value', organizationId]);

    if (selectedOrganizationOption && _get(selectedOrganizationOption, 'isSeatMissing')) {
      const organizationName = selectedOrganizationOption.label;

      const onMouseDown = (event: React.MouseEvent) => {
        const route = getOrganizationMenuPageRoute(organizationMenuPages.seats, organizationId);
        navigateRoute(route, event, true);
      };

      return (
        <>
          <span> {$t('missingSeatWithinOrganizationAlert', { name: organizationName })} </span>
          <HyperLink onMouseDown={onMouseDown}>{$t('manageSeats')}</HyperLink>
        </>
      );
    }
  };

  private getInitialFormValues = (): IProjectFormValues => {
    const { projectToEdit } = this.props;

    return {
      organization: setFormInitialValue(projectToEdit, 'organization.id'),
      name: setFormInitialValue(projectToEdit, 'name'),
      comment: setFormInitialValue(projectToEdit, 'comment'),
      fromTemplate: false,
      template_location: '',
      template_use: '',
      template_age: '',
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IProjectFormValues> => {
    return yup.object().shape({
      name: getYupStringValidation(true),
      comment: getYupStringValidation(),
      organization: getYupStringValidation(true),
      fromTemplate: getYupBooleanValidation(),
      template_location: yup.string().when(['fromTemplate'], {
        is: true,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
      template_use: yup.string().when(['fromTemplate'], {
        is: true,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
      template_age: yup.string().when(['fromTemplate'], {
        is: true,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
    }) as yup.ObjectSchema<IProjectFormValues>;
  };

  private getRequestParams = (formValues: IProjectFormValues): IProjectRequestParams => {
    const { projectToEdit } = this.props;
    const { fromTemplate, template_location, template_use, template_age } = formValues;

    const initialFormValues = this.getInitialFormValues();
    const filteredFinalFormValues = projectToEdit ? filterEditedValues(initialFormValues, formValues) : formValues;

    /* add fake parameter template_ref here to trigger createProjectFromTemplateSaga */
    let requestParamsFields = {
      ...filteredFinalFormValues,
      template_ref: { template_location, template_use, template_age },
    };

    let paramsToOmit = ['fromTemplate', 'template_location', 'template_use', 'template_age'];
    paramsToOmit = !fromTemplate ? [...paramsToOmit, 'template_ref'] : paramsToOmit;

    requestParamsFields = _omit(requestParamsFields, paramsToOmit);

    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams as IProjectRequestParams;
  };
}
