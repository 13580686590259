import { NewSubFormButton, StartReorderingButton, StopReorderingButton } from 'components/Forms/Buttons/SubFormButtons';
import { getReorderForm } from 'components/Forms/ReorderForm';
import { Section } from 'components/Section/Section.component';
import { _isEqual } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { ILayer } from 'types/Obat/Layer';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { LayerForm } from '../LayerForm';

import { IProps } from './LayersFormSection.container';

interface IState {
  layersNumber: number;
  newLayerFormOpen: boolean;
}

const ReorderForm = getReorderForm<ILayer>();

export class LayersFormSection extends PureComponent<IProps> {
  public state: IState = {
    newLayerFormOpen: false,
    layersNumber: 0,
  };

  public componentDidUpdate(prevProps: IProps): void {
    const { construction } = this.props;

    if (!_isEqual(prevProps.construction, construction)) {
      this.setState({ newLayerFormOpen: false });
      this.props.toggleReordering(false);
    }
  }

  public render(): ReactNode {
    const { construction, isReordering } = this.props;
    if (!construction) {
      return null;
    }

    const onStartReordering = (): void => {
      this.props.toggleReordering(true);
    };

    const onStopReordering = (): void => {
      this.props.toggleReordering(false);
    };

    const getLayerTitle = (layer: ILayer) => `${layer.material} : ${layer.thickness} m`;

    return (
      <Section title={$t('layers')} marginTop={20}>
        {!!isReordering && (
          <>
            <StopReorderingButton onClick={onStopReordering} text={$t('cancel')} />
            <ReorderForm
              resourcesToReorder={construction.layers_contents}
              resourceClass={orc.layer}
              getCardTitle={getLayerTitle}
            />
          </>
        )}

        {!isReordering && (
          <>
            {_size(construction.layers_contents) > 1 && <StartReorderingButton onClick={onStartReordering} />}
            {_map(construction.layers_contents, (layer: ILayer, index: number) => (
              <LayerForm construction={construction} layer={layer} key={layer.id} titleIndex={++index} />
            ))}
            {!this.state.newLayerFormOpen && <NewSubFormButton onClick={this.openNewLayerForm} text={$t('addLayer')} />}
            {this.state.newLayerFormOpen && (
              <LayerForm
                construction={construction}
                close={this.closeNewLayerForm}
                titleIndex={this.state.layersNumber}
              />
            )}
          </>
        )}
      </Section>
    );
  }

  private closeNewLayerForm = (): void => {
    this.setState({ newLayerFormOpen: false });
  };

  private openNewLayerForm = (): void => {
    this.setState({
      newLayerFormOpen: true,
      layersNumber: _size(this.props.construction.layers_contents) + 1,
    });
  };
}
