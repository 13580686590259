import { Console } from 'components/Consoles/Console';
import React, { PureComponent, ReactNode } from 'react';
import { animateScroll } from 'react-scroll/modules';
import styled from 'styled-components';

import { IProps } from './LogsConsole.container';

export class LogsConsole extends PureComponent<IProps> {
  public componentDidMount(): void {
    this.scroll();
  }

  public componentDidUpdate(_prevProps: IProps): void {
    this.scroll();
  }

  public render(): ReactNode {
    const { simulationLogs } = this.props;

    return (
      <ConsoleContainer>
        <Console id={'console'}>{simulationLogs || ''}</Console>
      </ConsoleContainer>
    );
  }

  private scroll(): void {
    const { isScrollEnabled } = this.props;

    if (isScrollEnabled) {
      animateScroll.scrollToBottom({ containerId: 'console' });
    }
  }
}

const ConsoleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
