import { OplusLoader } from 'components/Loader';
import React, { Component, ReactNode } from 'react';
import styled from 'styled-components';

import { IProps } from './Interceptor.container';

export class Interceptor extends Component<IProps> {
  private isUpdatingRestResources: boolean = false;
  private renderIteration: number = 0;

  public componentDidMount(): void {
    // console.log('Interceptor did mount');

    const { location, updateRouteHash, fetchAllRouteResources, match } = this.props;
    fetchAllRouteResources(match.params);

    const { hash } = location;
    const routeHash = hash.length > 0 ? hash.slice(1, hash.length) : undefined; // we remove #
    updateRouteHash(routeHash);
  }

  public render(): ReactNode {
    const { InterceptedComponent, isRouteResourcesUpToDate } = this.props;

    return isRouteResourcesUpToDate ? (
      <InterceptedComponent key={this.renderIteration} />
    ) : (
      <LoaderContainer>
        <OplusLoader />
      </LoaderContainer>
    );
  }

  public shouldComponentUpdate(nextProps: IProps): boolean {
    // console.log('Interceptor should update');

    const {
      fetchAllRouteResources,
      cleanRouteResourcesAfterRender,
      route,
      toggleIsRefreshingRoutePage,
      updateRoute,
      closeFormDrawer,
    } = this.props;

    /* If the route is different, we trigger the update of the url restResources but don't render now */
    /* We wait for the route resources to be up to date */
    const isRouteDifferent = route !== nextProps.match.url;
    let forceUpdate = false;

    if ((isRouteDifferent || nextProps.isRefreshingRoutePage) && !this.isUpdatingRestResources) {
      this.isUpdatingRestResources = true;

      if (nextProps.isRefreshingRoutePage) {
        forceUpdate = true;
        toggleIsRefreshingRoutePage(false);
      }

      if (isRouteDifferent) {
        /* close FormDrawer systematically while changing page */
        closeFormDrawer();
      }

      updateRoute(nextProps.match.url);
      fetchAllRouteResources(nextProps.match.params, forceUpdate);

      /* I need isUpdatingRestResources because the routeParams does not update well.
      routeParams are updated via updateAllRouteResourcesAction but the selectRouteParams does not update instantly... */

      return false;
    }

    /* When the current restResources have been updated we render and toggle back the status to false */
    /* We render only when the restResources are up to date, otherwise we see the loader between each page */
    if (nextProps.isRouteResourcesUpToDate) {
      this.isUpdatingRestResources = false;
      ++this.renderIteration;
      cleanRouteResourcesAfterRender();

      return true;
    }

    return false;
  }
}

const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;
