// tslint:disable:no-any
import { _keys } from 'libs/lodash';
import { _reduce } from 'libs/lodash';

export const filterEditedValues = (initialValues: any, finalValues: any): any => {
  return _reduce(
    _keys(finalValues),
    (diff: any, key: any) => {
      if (initialValues[key] === finalValues[key]) {
        return diff;
      }

      return {
        ...diff,
        [key]: finalValues[key],
      };
    },
    {}
  );
};
