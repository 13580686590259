import { HyperLink } from 'components/Links/HyperLink';
import React, { PureComponent, ReactNode } from 'react';
import { navigateRoute } from 'routing/navigateRoute';
import { getObatMenuPageRoute, obatMenuPages } from 'routing/routes';

import { Grid } from '@material-ui/core';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { _includes } from 'libs/lodash';
import { AlertTriangle } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { IProps } from './SimulationVariantConfig.container';

export class SimulationVariantConfig extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeProject, variantRef, obatId, variantOptionsByObat } = this.props;

    if (!variantRef) {
      return '-';
    }

    const isVariantMissing = !_includes(variantOptionsByObat[obatId], variantRef);

    if (isVariantMissing) {
      return (
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item>
            <WarningText>{variantRef}</WarningText>
          </Grid>
          <Grid item>
            <AlertIconContainer>
              <SimpleTooltip content={$t(`missingVariantAlert`)} placement={'bottom'}>
                <AlertTriangle color={theme.colors.warning} size={14} />
              </SimpleTooltip>
            </AlertIconContainer>
          </Grid>
        </Grid>
      );
    }

    const navigateToVariant = (e: React.MouseEvent): void => {
      const variantPageUrl = getObatMenuPageRoute(obatMenuPages.variants, routeProject.id, obatId);
      navigateRoute(`${variantPageUrl}#${variantRef}`, e);
    };

    return <HyperLink onMouseDown={navigateToVariant}>{variantRef}</HyperLink>;
  }
}

const AlertIconContainer = styled.div`
  margin-bottom: -4px;
`;

const WarningText = styled.span`
  color: ${theme.colors.warning};
`;
