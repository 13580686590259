import { _reduce } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { ILayer } from 'types/Obat/Layer';
import { ISelectOptions } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { getConstructionBoundsLabels } from 'utils/functions/obatResources/construction/getConstructionBoundsLabels';
import { $t } from 'utils/functions/translate';

export const getWireframePositionOptions = (layers: ILayer[], surfaceCategory: string): ISelectOptions => {
  const boundsLabels = getConstructionBoundsLabels(surfaceCategory);

  if (_size(layers) === 0) {
    return [{ label: $t(boundsLabels.right), value: '0' }];
  }
  if (_size(layers) === 1) {
    return [
      { label: $t(boundsLabels.left), value: '0' },
      { label: $t(boundsLabels.right), value: '1' },
    ];
  } else {
    let options = [{ label: $t(boundsLabels.left), value: '0' }];
    options = _reduce(
      layers,
      (_options: ISelectOptions, layer: ILayer, index: number): ISelectOptions => {
        return index === _size(layers) - 1
          ? _options
          : [..._options, { label: `${$t('after')} ${layer.material}`, value: `${index + 1}` }];
      },
      options
    );

    return [...options, { label: $t(boundsLabels.right), value: `${_size(layers)}` }];
  }
};
