import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { _filter } from 'libs/lodash';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';

import { $t } from 'utils/functions/translate';
import { IProps } from './EPlusParametersTable.container';

export class EPlusParametersTable extends PureComponent<IProps> {
  public render(): ReactNode {
    const { ePlusParameters } = this.props;

    return (
      <>
        {ePlusParameters.value === 'manual' &&
          (ePlusParameters.ref === 'groundTemperatures' || ePlusParameters.ref === 'waterTemperature') && (
            <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '25%' }} />
                    <TableCell align="center" style={{ width: '25%' }}>
                      {ePlusParameters.ref === 'groundTemperatures'
                        ? $t('groundTemperatureForConditionedZones')
                        : $t('waterTemperature')}
                    </TableCell>
                    <TableCell align="center" style={{ width: '25%' }}>
                      {ePlusParameters.ref === 'groundTemperatures' && $t('groundTemperatureForUnconditionedZones')}
                    </TableCell>
                    <TableCell align="center" style={{ width: '25%' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('january')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_january}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_january}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('february')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_february}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_february}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('march')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_march}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_march}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('april')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_april}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_april}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('may')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_may}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_may}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('june')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_june}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_june}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('july')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_july}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_july}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('august')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_august}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_august}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('september')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_september}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_september}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('october')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_october}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_october}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('november')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_november}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_november}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {$t('december')}
                    </TableCell>
                    <TableCell align="center">{ePlusParameters.distributed_december}</TableCell>
                    <TableCell align="center">
                      {ePlusParameters.ref === 'groundTemperatures' && ePlusParameters.undistributed_december}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
      </>
    );
  }
}
