import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikRadioInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikRadioInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { _map } from 'libs/lodash';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IDayProfile } from 'types/Obat/DayProfile';
import { IYearProfileRequestParams } from 'types/Obat/YearProfile';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { DayProfileForm } from '../DayProfileForm';

import { IProps } from './YearProfileForm.container';

export interface IYearProfileFormValues {
  ref: string;
  calendar: string;
  type: string;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IYearProfileFormValues, IYearProfileRequestParams>();

export class YearProfileForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        renderSubForm={this.renderSubForm}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.year_profile}
        subFormResources={[orc.day_profile]}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IYearProfileFormValues>): ReactNode => {
      const { yearProfileToEdit, calendarsOptions, openPlotsModal } = this.props;
      const { calendar } = formikProps.values;

      const onPlotsModalIconClick = (): void => {
        openPlotsModal(calendar, orc.calendar);
      };

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item xs={11}>
                  <FormikDropdownInput
                    field={'calendar'}
                    formikProps={formikProps}
                    options={calendarsOptions}
                    obatResourceToBeCheckedForCommitment={yearProfileToEdit}
                    required
                  />
                </Grid>
                {calendar && (
                  <Grid item xs={1}>
                    <IconButton size={16} action={'seeDetails'} onClick={onPlotsModalIconClick} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikRadioInput
                field={'type'}
                formikProps={formikProps}
                options={[
                  { value: 'ratio', label: $t('ratio') },
                  { value: 'temperature', label: $t('temperature') },
                ]}
                isDisabled={!!yearProfileToEdit}
              />
            </Grid>
          </Grid>
        </>
      );
    };
  };

  private getInitialFormValues = (): IYearProfileFormValues => {
    const { yearProfileToEdit } = this.props;

    return {
      ref: setFormInitialValue(yearProfileToEdit, 'ref'),
      calendar: setFormInitialValue(yearProfileToEdit, 'calendar'),
      type: setFormInitialValue(yearProfileToEdit, 'type', 'ratio'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IYearProfileFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      calendar: getYupStringValidation(true),
      type: getYupStringValidation().oneOf(this.props.typeOptions),
    }) as yup.ObjectSchema<IYearProfileFormValues>;
  };

  private renderSubForm = (): ReactNode => {
    const { yearProfileToEdit } = this.props;
    if (yearProfileToEdit && yearProfileToEdit.day_profiles_contents) {
      return (
        <Section title={$t('dayProfiles')} marginTop={20}>
          {_map(yearProfileToEdit.day_profiles_contents, (dayProfile: IDayProfile) => {
            return <DayProfileForm key={dayProfile.id} dayProfile={dayProfile} />;
          })}
        </Section>
      );
    }

    return;
  };
}
