import Grid from '@material-ui/core/Grid';
import { Alert } from 'components/Alerts';
import { BottomCenterButtons, BottomRightButtons, Button } from 'components/Buttons';
import { ToolsButton } from 'components/Buttons/ToolsButton';
import { ITool } from 'components/Buttons/ToolsButton/ToolsButton.component';
import { PivotTable } from 'components/SimulationGroup/MonthlyResults/PivotTable';
import { TemplateForm } from 'components/SimulationGroup/MonthlyResults/TemplateForm';
import React, { PureComponent, ReactNode } from 'react';
import { Edit3 } from 'react-feather';
import styled from 'styled-components';
import { downloadCustomTextFile } from 'utils/functions/downloadFile/downloadCustomTextFile';
import { getPivotTableConfig } from 'utils/functions/simulationGroup/getPivotTableConfig';
import { $t, $tcc } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { fk } from 'utils/strings/formKeys';
import { rv } from 'utils/strings/results';

import { IProps } from './ResultsTab.container';

export class ResultsTab extends PureComponent<IProps> {
  public render(): ReactNode {
    const { sectionData, section, template, resultsMode, openFormDrawer } = this.props;

    if (!sectionData) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CenterDiv>
              <Alert status={as.info}>{$t('noResultInThisSection')}</Alert>
            </CenterDiv>
          </Grid>
        </Grid>
      );
    }

    const pivotTableConfig = getPivotTableConfig(resultsMode, section, sectionData, template);
    const onClick = () => openFormDrawer(fk.template);

    return (
      <>
        <PivotTable config={pivotTableConfig} />
        <BottomCenterButtons>
          <Button Icon={<Edit3 size={12} />} text={$t('applyTemplate')} onClick={onClick} />
        </BottomCenterButtons>
        <BottomRightButtons>
          <ToolsButton tools={this.getTools()} />
        </BottomRightButtons>
        <TemplateForm resultsMode={resultsMode} />
      </>
    );
  }

  private getTools = (): ITool[] => {
    const { resultsMode, simulation, isMono, routeSimulationGroup, section } = this.props;

    let text;

    if (isMono) {
      text = $t('exportSimulationMonthlyResults', {
        simulationName: routeSimulationGroup.name,
        sectionName: $tcc(section),
      });
    } else {
      if (resultsMode === rv.bySimulation && simulation) {
        text = $t('exportSimulationMonthlyResults', { simulationName: simulation.name, sectionName: $tcc(section) });
      } else {
        text = $t('exportAggregatedMonthlyResults', { sectionName: $tcc(section) });
      }
    }

    return [{ text, onClick: this.exportSectionResults }];
  };

  private exportSectionResults = (): void => {
    const { sectionDataCsv, routeSimulationGroup, section, resultsMode, simulation, isMono } = this.props;

    let fileName;

    if (isMono) {
      fileName = `${routeSimulationGroup.name}_${section}.csv`;
    } else {
      if (resultsMode === rv.bySimulation && simulation) {
        fileName = `${simulation.name}_${section}.csv`;
      } else {
        fileName = `${routeSimulationGroup.name}_${section}.csv`;
      }
    }

    // @ts-ignore
    downloadCustomTextFile(sectionDataCsv, fileName);
  };
}

const CenterDiv = styled.span`
  display: flex;
  justify-content: center;
`;
