import { fork, ForkEffectDescriptor, SimpleEffect } from 'redux-saga/effects';
import { azureActionsSagas } from 'redux/azure/sagas';
import { formActionsSagas } from 'redux/form/sagas';
import { logsModalActionsSagas } from 'redux/modals/logsModal/sagas';
import { obatResourcesActionsSagas } from 'redux/obatResources/sagas';
import { floorspaceActionsSagas } from 'redux/restResources/detail/floorspace/sagas';
import { geometryActionsSagas } from 'redux/restResources/detail/geometry/sagas';
import { meActionsSagas } from 'redux/restResources/detail/me/sagas';
import { obatActionsSagas } from 'redux/restResources/detail/obat/sagas';
import { organizationActionsSagas } from 'redux/restResources/detail/organization/sagas';
import { simulationGroupActionsSagas } from 'redux/restResources/detail/simulationGroup/sagas';
import { weatherActionsSagas } from 'redux/restResources/detail/weather/sagas';
import { restResourcesActionsSagas } from 'redux/restResources/generic/sagas';
import { routingActionsSagas } from 'redux/routing/sagas';
import { tableActionsSagas } from 'redux/table/sagas';
import { taskActionsSagas } from 'redux/tasks/sagas';
import { tokenActionsSagas } from 'redux/tokens/sagas';
import { variantContextActionsSagas } from 'redux/variantContext/sagas';

export function* rootSaga(): IterableIterator<SimpleEffect<'FORK', ForkEffectDescriptor>> {
  yield fork(tokenActionsSagas);
  yield fork(restResourcesActionsSagas);
  yield fork(obatResourcesActionsSagas);
  yield fork(floorspaceActionsSagas);
  yield fork(geometryActionsSagas);
  yield fork(weatherActionsSagas);
  yield fork(obatActionsSagas);
  yield fork(simulationGroupActionsSagas);
  yield fork(routingActionsSagas);
  yield fork(taskActionsSagas);
  yield fork(tableActionsSagas);
  yield fork(meActionsSagas);
  yield fork(organizationActionsSagas);
  yield fork(azureActionsSagas);
  yield fork(variantContextActionsSagas);
  yield fork(formActionsSagas);
  yield fork(logsModalActionsSagas);
}
