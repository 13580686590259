import { Action } from 'redux';

export const OPEN_DAILY_SEAT_MODAL = 'OPEN_DAILY_SEAT_MODAL';
export interface IOpenDailySeatModal extends Action<'OPEN_DAILY_SEAT_MODAL'> {
  payload: {};
}
export const openDailySeatModalAction = (): IOpenDailySeatModal => ({
  type: OPEN_DAILY_SEAT_MODAL,
  payload: {},
});

export const CLOSE_DAILY_SEAT_MODAL = 'CLOSE_DAILY_SEAT_MODAL';
export interface ICloseDailySeatModal extends Action<'CLOSE_DAILY_SEAT_MODAL'> {}
export const closeDailySeatModalAction = (): ICloseDailySeatModal => ({
  type: CLOSE_DAILY_SEAT_MODAL,
});

export type IDailySeatModalActions = IOpenDailySeatModal | ICloseDailySeatModal;
