import { connect } from 'react-redux';

import { ModalInfoTooltip as Component } from './ModalInfoTooltip.component';

interface IOwnProps {
  openModal: () => void;
  message: string;
  placement: 'left' | 'right' | 'bottom';
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export const ModalInfoTooltip = connect(mapStateToProps, mapDispatchToProps)(Component);
