import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { closeFormDrawerAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import { selectMyUser } from 'redux/restResources/detail/me/selectors';
import {
  cleanRouteResourcesAfterRenderAction,
  fetchAllRouteResourcesSagaAction,
  toggleIsRefreshingRoutePageAction,
  updateRouteAction,
  updateRouteHashAction,
} from 'redux/routing/actions';
import {
  selectIsRefreshingRoutePage,
  selectIsRouteResourcesUpToDate,
  selectRoute,
  selectRouteResources,
} from 'redux/routing/selectors';

import { Interceptor as InterceptorComponent } from './Interceptor.component';

interface IOwnProps {
  InterceptedComponent: ComponentType;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
interface IRouteParams {
  [resourceId: string]: string;
}
type IInjectedProps = IStateProps & IDispatchProps & RouteComponentProps<IRouteParams>;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => ({
  routeResources: selectRouteResources(rootState),
  route: selectRoute(rootState),
  isRefreshingRoutePage: selectIsRefreshingRoutePage(rootState),
  isRouteResourcesUpToDate: selectIsRouteResourcesUpToDate(rootState),
  myUser: selectMyUser(rootState),
});

const mapDispatchToProps = {
  fetchAllRouteResources: fetchAllRouteResourcesSagaAction,
  toggleIsRefreshingRoutePage: toggleIsRefreshingRoutePageAction,
  cleanRouteResourcesAfterRender: cleanRouteResourcesAfterRenderAction,
  updateRoute: updateRouteAction,
  updateRouteHash: updateRouteHashAction,
  closeFormDrawer: closeFormDrawerAction,
};

export const Interceptor = compose<IProps, IOwnProps>(
  withRouter,
  connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(mapStateToProps, mapDispatchToProps)
)(InterceptorComponent);
