// tslint:disable-next-line:no-any
export const uploadFileToAzureBlob = async (blobUrl: string, fileContentBlob: Blob) => {
  try {
    return await new Promise((resolve: () => void) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', blobUrl, true);
      xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
      xhr.onload = () => {
        resolve();
      };
      xhr.send(fileContentBlob);
    });
  } catch (e) {
    throw new Error(`Impossible to upload ${fileContentBlob} to ${blobUrl}: ${e}`);
  }
};
