import Grid from '@material-ui/core/Grid';
import { Alert } from 'components/Alerts';
import { HyperLink } from 'components/Links/HyperLink';
import { ModalFrame } from 'components/Modals/ModalFrame';
import { IProps } from 'components/Modals/PlotsModal/PlotsModal.container';
import { Section } from 'components/Section/Section.component';
import { CenteredText } from 'components/Texts/CenteredText';
import { _camelCase } from 'libs/lodash';
import { toPlural } from 'libs/pluralize';
import { CalendarPlot } from 'pages/ObatMenu/Calendars/CalendarPlot';
import { SchedulesPlot } from 'pages/ObatMenu/Schedules/SchedulesPlot/SchedulesPlot.component';
import { DayProfilesPlot } from 'pages/ObatMenu/YearProfiles/DayProfilesPlot/DayProfilesPlot.component';
import React, { PureComponent, ReactNode } from 'react';
import { navigateRoute } from 'routing/navigateRoute';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { IObatResourceClass, orc } from 'utils/strings/resourceClasses';

export class PlotsModal extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isOpen, plotsModalData, closePlotsModal } = this.props;
    const { calendar, yearProfile, schedule } = plotsModalData;

    const Content = (
      <>
        {schedule && (
          <Section title={$t('schedule')}>
            <PlotTitle>
              <HyperLink onMouseDown={this.goToResource(orc.schedule)}>{schedule.id}</HyperLink>
            </PlotTitle>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PlotContainer>
                  <SchedulesPlot daySchedules={schedule.day_schedules_contents} daySchedulesUnit={schedule.unit} />
                </PlotContainer>
              </Grid>
            </Grid>
          </Section>
        )}
        {yearProfile && (
          <Section title={`${$t('yearProfile')}`}>
            <PlotTitle>
              <HyperLink onMouseDown={this.goToResource(orc.year_profile)}>{yearProfile.id}</HyperLink>
            </PlotTitle>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PlotContainer>
                  <DayProfilesPlot dayProfiles={yearProfile.day_profiles_contents} yearProfileType={yearProfile.type} />
                </PlotContainer>
              </Grid>
            </Grid>
          </Section>
        )}
        {calendar && (
          <Section title={`${$t('calendar')}`}>
            <PlotTitle>
              <HyperLink onMouseDown={this.goToResource(orc.calendar)}>{calendar.ref}</HyperLink>
            </PlotTitle>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PlotContainer>
                  <CalendarPlot
                    // @ts-ignore
                    calendar={calendar}
                  />
                </PlotContainer>
              </Grid>
            </Grid>
          </Section>
        )}
        {this.isDataEmpty() && isOpen && <Alert status={as.warning}>{$t('noPlotsModalDataAlert')}</Alert>}
      </>
    );

    return <ModalFrame isOpen={isOpen} onClose={closePlotsModal} fullWidth={true} maxWidth={'md'} Content={Content} />;
  }

  private goToResource = (resourceClass: IObatResourceClass) => {
    return (mouseEvent: React.MouseEvent): void => {
      const { closePlotsModal } = this.props;

      closePlotsModal();
      const calendarRef = mouseEvent.currentTarget.textContent;
      navigateRoute(`${_camelCase(toPlural(resourceClass))}#${calendarRef}`, mouseEvent, true);
    };
  };

  private isDataEmpty = (): boolean => {
    const { calendar, yearProfile, schedule } = this.props.plotsModalData;

    return !calendar && !yearProfile && !schedule;
  };
}

const PlotContainer = styled.div`
  padding-right: 24px;
`;

const PlotTitle = styled(CenteredText)`
  margin-bottom: 8px;
`;
