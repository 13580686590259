import { IObatResourceFormSpecificDefaultValues } from 'components/Forms/ObatResourceFormSpecificFields/ObatResourceFormSpecificFields.container';
import { _get, _has, _map, _omit, _reduce, _replace, _split, _uniq } from 'libs/lodash';
import { IModificationFormValues } from 'pages/ObatMenu/Variants/ModificationForm/ModificationForm.component';
import { IRootState } from 'redux/reducer';

/*** OBAT CONTENTS ***/

export const selectObatContentsFlatOptionsList = (key: string) => {
  return (rootState: IRootState): string[] => {
    return _get(rootState.enums.obat.obat_contents, key);
  };
};

/*** OBAT SCHEDULES ***/

export const selectScheduleMethodOptionsFunction = (rootState: IRootState) => {
  return (type: string): string[] => {
    return _get(rootState.enums.obat.obat_contents.schedule_methods__by_type, type);
  };
};

export const selectScheduleUnitOptionsFunction = (rootState: IRootState) => {
  return (type: string, method: string): string[] => {
    return _get(rootState.enums.obat.obat_contents.schedule_units__by_type_and_method, `${type}.${method}`);
  };
};

export const selectScheduleSpecificDefaultValuesOptionsFunction = (rootState: IRootState) => {
  return (type: string, method: string): IObatResourceFormSpecificDefaultValues => {
    const defaultValues = _get(
      rootState.enums.obat.obat_contents.schedule_specific_defaults__by_type_and_method,
      `${type}.${method}`
    );

    // HACK: Because specific fields are not totally well designed in backend
    if (type === 'light') {
      return _omit(defaultValues, [
        'daylighting_control',
        'daylighting_control_setpoint',
        'daylighting_control_minimum_light_ratio',
      ]);
    }

    return defaultValues;
  };
};

/*** OBAT SYSTEMS ***/

export const selectSystemMethodOptionsFunction = (rootState: IRootState) => {
  return (type: string): string[] => {
    return _get(rootState.enums.obat.obat_contents.system_methods__by_type, type);
  };
};

export const selectSystemSpecificDefaultValuesOptionsFunction = (rootState: IRootState) => {
  return (type: string, method: string): IObatResourceFormSpecificDefaultValues => {
    const defaultValues = _get(
      rootState.enums.obat.obat_contents.system_specific_defaults__by_type_and_method,
      `${type}.${method}`
    );

    // HACK: Because specific fields are not totally well designed in backend
    if (type === 'air_loop') {
      return _omit(defaultValues, [
        'heat_exchanger_bypass',
        'heat_exchanger_bypass_outdoor_low_temperature',
        'heat_exchanger_bypass_setpoint_at_outdoor_low_temperature',
        'heat_exchanger_bypass_outdoor_high_temperature',
        'heat_exchanger_bypass_setpoint_at_outdoor_high_temperature',
      ]);
    }

    return defaultValues;
  };
};

/*** MODIFICATIONS ***/

export const selectModificationMethodOptionsFunction = (rootState: IRootState) => {
  return (tableRef: string): string[] => {
    return _get(rootState.enums.obat.obat_contents.modification_methods__by_table_ref, tableRef);
  };
};

export interface IModificationValueFieldDescriptor {
  type: 'Float' | 'LinkField' | 'Constant' | 'String' | 'Integer' | 'Boolean' | 'FilterSourceCodeField';
  required: boolean;
  // tslint:disable-next-line:no-any
  missing?: any;
  target_table_ref?: string;
}

export const selectVariantValueOptionsFunction = (rootState: IRootState) => {
  return (
    tableRef: string,
    method: string
  ): { [field in keyof IModificationFormValues]: IModificationValueFieldDescriptor } => {
    return _get(
      rootState.enums.obat.obat_contents.modification_info__by_table_ref_and_method,
      `${tableRef}.methods.${method}`
    );
  };
};
