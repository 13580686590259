import { _has } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { IHourlyResultsPlotSeries } from 'redux/restResources/detail/simulationGroup/reducer';
import { ITableResources } from 'redux/table/reducer';
import { ISimulationSeries } from 'types/SimulationGroup/Simulation';

export const getHourlyResultsPlotSeries = (
  selectedResourcesList: ITableResources['selected']
): IHourlyResultsPlotSeries => {
  return _reduce(
    selectedResourcesList,
    (_plotSeries: IHourlyResultsPlotSeries, series: ISimulationSeries) => {
      const simulationId = series.simulation.id;

      return {
        ..._plotSeries,
        [simulationId]: _has(_plotSeries, simulationId) ? [..._plotSeries[simulationId], series] : [series],
      };
    },
    {}
  );
};
