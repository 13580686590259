import { _map, _padStart, _size } from 'libs/lodash';
import { _range } from 'libs/lodash';
import { ISelectOptions } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { $t } from 'utils/functions/translate';

export const dayNameList = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const dayNumberList = _range(1, 32);

export const dayNumberOptions: ISelectOptions = _map(dayNumberList, (dayNumber: number) => {
  return { value: dayNumber.toString(), label: _padStart(dayNumber.toString(), 2, '0') };
});

export const dayNameOptions = _map(dayNameList, (day: string) => {
  return { value: day, label: $t(day) };
});

export const monthNameList = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const monthNumberList = _range(1, _size(monthNameList) + 1);

export const monthNumberOptions: ISelectOptions = _map(monthNumberList, (month: number) => {
  return { value: month.toString(), label: _padStart(month.toString(), 2, '0') };
});

export const monthNameOptions: ISelectOptions = _map(monthNameList, (month: string, position: number) => {
  return { value: (position + 1).toString(), label: $t(month) };
});

export const departmentOptions = _map(_range(1, 96), (n: number) => _padStart(n.toString(), 2, '0'));
