import { connect } from 'react-redux';
import { toggleReorderingAction } from 'redux/form/actions';
import { selectFormInitialResourceFromTable, selectIsReordering } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { orc } from 'utils/strings/resourceClasses';

import { ModificationsFormSection as ModificationsFormSectionComponent } from './ModificationsFormSection.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    variant: selectFormInitialResourceFromTable(orc.variant)(rootState),
    isReordering: selectIsReordering(rootState),
  };
};

const mapDispatchToProps = {
  toggleReordering: toggleReorderingAction,
};

export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

export const ModificationsFormSection = connect(mapStateToProps, mapDispatchToProps)(ModificationsFormSectionComponent);
