import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { fetchMyUserSagaAction } from 'redux/restResources/detail/me/actions';
import { selectMyUser } from 'redux/restResources/detail/me/selectors';
import { selectAccessToken } from 'redux/tokens/selectors';

import Component from './MainComponent.component';

const mapStateToProps = (rootState: IRootState) => ({
  myUser: selectMyUser(rootState),
  accessToken: selectAccessToken(rootState),
});

const mapDispatchToProps = {
  fetchMyUser: fetchMyUserSagaAction,
};

interface IOwnProps {
  children: ReactNode;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export const Main = connect(mapStateToProps, mapDispatchToProps)(Component);
