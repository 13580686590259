import { IObatResourceDeleteProps } from 'components/Forms/Buttons/FormDeleteButton/FormDeleteButton.container';
import { FormikProps } from 'formik';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath, selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { IRootState } from 'redux/reducer';
import { IObatResource } from 'types/Obat/ObatResource';
import { IRestResource } from 'types/RestResource';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { gra } from 'utils/strings/requestActions';
import * as yup from 'yup';

import { ResourceForm as Component } from './ResourceForm.component';

interface IOwnProps<IResourceFormValues, IResourceRequestParams, IResourceClass> {
  initialResource: IRestResource | IObatResource | undefined;
  renderForm: (formikProps: FormikProps<IResourceFormValues>) => ReactNode;
  getInitialFormValues: () => IResourceFormValues;
  getRequestParams?: (formValues: IResourceFormValues) => IResourceRequestParams;
  getValidationSchema: () => yup.ObjectSchema /*<IResourceFormValues>*/;
  resourceClass: IResourceClass;
  forceSubmitDisabled?: (formikProps: FormikProps<IResourceFormValues>) => boolean;
  forceSubmitEnabled?: (formikProps: FormikProps<IResourceFormValues>) => boolean;
  deleteResource?: () => void;
  saveResource: (
    resourceClass: IResourceClass,
    // tslint:disable-next-line:no-any
    initialResource: any,
    requestParams: IResourceRequestParams
  ) => void;
  isObatResource: boolean;
  renderSubForm?: () => ReactNode;
  obatResourceDeleteProps?: IObatResourceDeleteProps;
  isCopy?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps<IResourceFormValues, IResourceRequestParams, IResourceClass> = IOwnProps<
  IResourceFormValues,
  IResourceRequestParams,
  IResourceClass
> &
  IInjectedProps;

const mapStateToProps = <IResourceFormValues, IResourceRequestParams, IResourceClass>(
  state: IRootState,
  ownProps: IOwnProps<IResourceFormValues, IResourceRequestParams, IResourceClass>
) => {
  const { initialResource, resourceClass } = ownProps;

  const createApiRequestKey = getGenericApiRequestKey(gra.create, resourceClass);
  const editApiRequestKey = getGenericApiRequestKey(gra.edit, resourceClass, initialResource && initialResource.id);
  const copyApiRequestKey = getGenericApiRequestKey(gra.copy, resourceClass, initialResource && initialResource.id);
  const deleteApiRequestKey = getGenericApiRequestKey(gra.delete, resourceClass, initialResource && initialResource.id);

  return {
    initialResource,

    isSubmittingCreate: selectIsApiRequestPending(createApiRequestKey)(state),
    createErrors: selectFormApiErrorsTranslationPath(createApiRequestKey)(state),

    isSubmittingEdit: !!initialResource && selectIsApiRequestPending(editApiRequestKey)(state),
    editErrors: initialResource ? selectFormApiErrorsTranslationPath(editApiRequestKey)(state) : undefined,

    isSubmittingCopy: !!initialResource && selectIsApiRequestPending(copyApiRequestKey)(state),
    copyErrors: initialResource ? selectFormApiErrorsTranslationPath(copyApiRequestKey)(state) : undefined,

    isDeleting: !!initialResource && selectIsApiRequestPending(deleteApiRequestKey)(state),
  };
};

const mapDispatchToProps = {};

export const getResourceForm = <IResourceFormValues, IResourceRequestParams, IResourceClass>() => {
  return connect<
    IStateProps,
    IDispatchProps,
    IOwnProps<IResourceFormValues, IResourceRequestParams, IResourceClass>,
    IRootState
  >(
    mapStateToProps,
    mapDispatchToProps
  )(Component as new () => Component<IResourceFormValues, IResourceRequestParams, IResourceClass>);
};
