import { IRootState } from 'redux/reducer';

export const selectAccessToken = (rootState: IRootState): string | null => {
  return rootState.tokens.access;
};

export const selectRefreshToken = (rootState: IRootState): string | null => {
  return rootState.tokens.refresh;
};

export const selectIsRefreshingAccess = (rootState: IRootState): boolean => {
  return rootState.tokens.isRefreshingAccess;
};
