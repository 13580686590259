import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

const progressStatusTuple = stringsTuple(
  'starting',
  'refreshing',
  'rendering',
  'running',
  'fetchingData',
  'crossCheckingData',
  'uploadingFile',
  'importingGeometry',
  'importingObat',
  'importingWeather',
  'finished',
  'aggregatingResults',
  'disconnection'
);
export type IProgressStatus = typeof progressStatusTuple[number];
export const ps = mapStrings<IProgressStatus>(progressStatusTuple);
