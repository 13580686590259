import { connect } from 'react-redux';
import { closeFormDrawerAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import { updateMonthlyResultsTemplateAction } from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectMonthlyResultsSection,
  selectMonthlyResultsTemplate,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { IResultsView } from 'utils/strings/results';

import { TemplateForm as Component } from './TemplateForm.component';

interface IOwnProps {
  resultsMode: IResultsView;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    template: selectMonthlyResultsTemplate(rootState),
    section: selectMonthlyResultsSection(rootState),
  };
};

const mapDispatchToProps = {
  updateTemplate: updateMonthlyResultsTemplateAction,
  closeFormDrawer: closeFormDrawerAction,
};

export const TemplateForm = connect(mapStateToProps, mapDispatchToProps)(Component);
