import { RestResourcesRequests } from 'api/requests/RestResourcesRequests';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { apiRequestSaga } from 'redux/apiRequests/sagas';
import { selectApiRequestErrors } from 'redux/apiRequests/selectors';
import { closeFormDrawerAction } from 'redux/form/actions';
import { listRestResourcesSaga } from 'redux/restResources/generic/sagas';
import { getDetailApiRequestKey, getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dr } from 'utils/strings/detailRoutes';
import { hm } from 'utils/strings/httpMethods';
import { dra, gra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import {
  EDIT_MY_PASSWORD_SAGA_ACTION,
  FETCH_MY_ORGANIZATIONS_SAGA_ACTION,
  FETCH_MY_USER_SAGA_ACTION,
  IEditMyPasswordSagaAction,
  updateMyOrganizationsAction,
  updateMyUserAction,
} from './actions';

export function* fetchMyOrganizationsSaga(): SagaIterator {
  const organizations = yield call(listRestResourcesSaga, rrc.organization);
  yield put(updateMyOrganizationsAction(organizations));
}

export function* fetchMyOrganizationsActionSaga(): SagaIterator {
  yield call(fetchMyOrganizationsSaga);
}

export function* fetchMyUserSaga(): SagaIterator {
  const apiRequestKey = getGenericApiRequestKey(gra.retrieve, rrc.user, 'me');
  const apiUrlParams = { resourceClass: rrc.user, resourceId: 'me' };

  const user = yield call(apiRequestSaga, apiRequestKey, false, RestResourcesRequests.retrieve, apiUrlParams);
  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));

  if (!apiRequestErrors) {
    yield put(updateMyUserAction(user));
  }
}

export function* fetchMyUserActionSaga(): SagaIterator {
  yield call(fetchMyUserSaga);
}

export function* editMyPasswordSaga(payload: IEditMyPasswordSagaAction['payload']): SagaIterator {
  const { myUserId, requestParams } = payload;

  const apiRequestKey = getDetailApiRequestKey(dra.changePassword, myUserId);
  const apiUrlParams = { resourceClass: rrc.user, resourceId: myUserId, detailRoute: dr.password };

  yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.patch,
    {},
    requestParams
  );
  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    yield put(closeFormDrawerAction());
  }
}

export function* editMyPasswordActionSaga(action: IEditMyPasswordSagaAction): SagaIterator {
  yield call(editMyPasswordSaga, action.payload);
}

export function* meActionsSagas(): Generator {
  yield takeLatest(FETCH_MY_ORGANIZATIONS_SAGA_ACTION, fetchMyOrganizationsActionSaga);
  yield takeLeading(FETCH_MY_USER_SAGA_ACTION, fetchMyUserActionSaga);
  yield takeLatest(EDIT_MY_PASSWORD_SAGA_ACTION, editMyPasswordActionSaga);
}
