import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectAccessToken, selectRefreshToken } from 'redux/tokens/selectors';

import { RouterComponent } from './Router.component';

const mapStateToProps = (rootState: IRootState) => ({
  accessToken: selectAccessToken(rootState),
  refreshToken: selectRefreshToken(rootState),
});

type IStateProps = ReturnType<typeof mapStateToProps>;
export type IProps = IStateProps;

export const Router = connect(mapStateToProps)(RouterComponent);
