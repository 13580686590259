// tslint:disable:no-any
import { _find } from 'libs/lodash';
import { _get } from 'libs/lodash';
import { toPlural } from 'libs/pluralize';
import { selectObatContentsFlatOptionsList } from 'redux/enums/selectors';
import { IHeatmap } from 'types/Obat/Calendar';
import { IObatData } from 'types/Obat/ObatData';
import { IObatResource } from 'types/Obat/ObatResource';
import { ISelectOption } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { getCreationPositionOptions } from 'utils/functions/obatResources/position/getCreationPositionOptions';
import { getGroupedCreationPositionOptions } from 'utils/functions/obatResources/position/getGroupedCreationPositionOptions';
import {
  getGroupedObatResourcesIds,
  listObatResourcesIds,
  listObatResourcesIdsGroupedBy,
} from 'utils/obatContents/listIds';
import { IObatResourceClass } from 'utils/strings/resourceClasses';

import { IRootState } from '../reducer';

export const selectObatContentsId = (rootState: IRootState): string | undefined => {
  const obatContents = rootState.obatResources.obatContents;

  return obatContents ? obatContents.id : undefined;
};

export const selectObatContentsData = (rootState: IRootState): IObatData | undefined => {
  const obatContents = rootState.obatResources.obatContents;

  return obatContents ? obatContents.data : undefined;
};

export const selectObatResources = (resourceClass: IObatResourceClass) => {
  return (rootState: IRootState): IObatResource[] | undefined => {
    const obatData = selectObatContentsData(rootState);

    return obatData ? _get(obatData, resourceClass) : undefined;
  };
};

export const selectObatDataResourcesFunction = (rootState: IRootState) => {
  return (resourceClass: string): IObatResource[] | undefined => {
    const obatData = selectObatContentsData(rootState);

    return obatData ? _get(obatData, resourceClass) : undefined;
  };
};

export const selectSingleObatResource = (resourceClass: IObatResourceClass, resourceId: string) => {
  return (rootState: IRootState): IObatResource | undefined => {
    const obatResources = selectObatResources(resourceClass)(rootState);

    return obatResources ? _find(obatResources, ['id', resourceId]) : undefined;
  };
};

export const selectCalendarHeatmap = (calendarRef: string) => {
  return (rootState: IRootState): IHeatmap | undefined => {
    return _get(rootState.obatResources.heatmaps, calendarRef);
  };
};

/* Ids */
export const selectObatResourcesIdsList = (resourceClass: string) => {
  return (rootState: IRootState): string[] => {
    const obatData = rootState.obatResources.obatContents && rootState.obatResources.obatContents.data;

    // @ts-ignore
    return obatData ? listObatResourcesIds(obatData, resourceClass) : [];
  };
};

export const selectObatResourcesIdsListFunction = (rootState: IRootState) => {
  return (resourceClass: string): string[] => {
    const obatData = rootState.obatResources.obatContents && rootState.obatResources.obatContents.data;

    // @ts-ignore
    return obatData ? listObatResourcesIds(obatData, resourceClass) : [];
  };
};

export const selectGroupedObatResourcesIdsListFunction = (resourceClass: string, groupedBy: string) => {
  return (rootState: IRootState) => {
    return (groupByKey: string): string[] => {
      const obatData = rootState.obatResources.obatContents && rootState.obatResources.obatContents.data;

      return obatData ? listObatResourcesIdsGroupedBy(obatData, resourceClass, groupedBy, groupByKey) : [];
    };
  };
};

/* Positions */
export interface IObatPositionOption extends ISelectOption {
  afterRef?: string;
}
export type IObatPositionOptions = IObatPositionOption[];

export const selectObatResourceCreationPositionOptions = (resourceField: string) => {
  return (rootState: IRootState): IObatPositionOptions => {
    const refs = selectObatResourcesIdsList(resourceField)(rootState);

    return getCreationPositionOptions(refs);
  };
};

export interface IObatGroupedPositionOptions {
  [category: string]: IObatPositionOptions;
}

export const selectGroupedObatResourcesCreationPositionOptions = (resourceClass: string, groupedBy: string) => {
  return (rootState: IRootState): IObatGroupedPositionOptions => {
    const obatData = rootState.obatResources.obatContents && rootState.obatResources.obatContents.data;
    const groupByOptions = selectObatContentsFlatOptionsList(toPlural(groupedBy))(rootState);

    const obatResourcesIdsGroupedByObj = obatData
      ? getGroupedObatResourcesIds(obatData, resourceClass, groupedBy, groupByOptions)
      : {};

    return getGroupedCreationPositionOptions(obatResourcesIdsGroupedByObj);
  };
};

/* Ref to scroll to */
export const selectRefToScrollTo = (rootState: IRootState): string | undefined => {
  return rootState.obatResources.refToScrollTo;
};
