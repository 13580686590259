// tslint:disable:no-any
import { _reduce } from 'libs/lodash';
import { IApiErrors, IFieldApiError, IFormApiErrorsTranslationPath } from 'types/Errors';

export const getFormApiErrorsTranslationPath = (apiRequestErrors: IApiErrors): IFormApiErrorsTranslationPath => {
  const emptyFormApiErrors = { fields: {}, GLOBAL: undefined };

  return _reduce(
    apiRequestErrors,
    (
      formApiErrors$: IFormApiErrorsTranslationPath,
      errorsList: IFieldApiError[],
      field: string
    ): IFormApiErrorsTranslationPath => {
      const firstError = errorsList[0];

      if (field === 'GLOBAL') {
        return { ...formApiErrors$, GLOBAL: firstError.detailed_code };
      }

      return {
        ...formApiErrors$,
        fields: {
          ...formApiErrors$.fields,
          [field]: getFieldApiErrorTranslationPath(firstError),
        },
      };
    },
    emptyFormApiErrors
  );
};

const getFieldApiErrorTranslationPath = (fieldError: IFieldApiError): string => {
  const { detailed_code, extra } = fieldError;
  const { min_strict, max_strict, min_value, max_value } = extra;

  if (min_value && max_value) {
    let translationPath;
    if (min_strict && max_strict) {
      translationPath = `extraErrorMinExcludedMaxExcluded|${min_value}|${max_value}`;
    } else if (min_strict && !max_strict) {
      translationPath = `extraErrorMinExcludedMaxIncluded|${min_value}|${max_value}`;
    } else if (!min_strict && max_strict) {
      translationPath = `extraErrorMinIncludedMaxExcluded|${min_value}|${max_value}`;
    } else {
      translationPath = `extraErrorMinIncludedMaxIncluded|${min_value}|${max_value}`;
    }

    return `${translationPath}|${min_value}|${max_value}`;
  } else {
    return detailed_code;
  }
};
