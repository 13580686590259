import { Grid } from '@material-ui/core';
import { Button } from 'components/Buttons';
import { ModalFrame } from 'components/Modals/ModalFrame';
import { CenteredText } from 'components/Texts/CenteredText';
import React, { PureComponent, ReactNode } from 'react';
import { navigateRoute } from 'routing/navigateRoute';
import { getOrganizationMenuPageRoute, organizationMenuPages } from 'routing/routes';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';

import { IProps } from './NoFreeSeatsModal.container';

export class NoFreeSeatsModal extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isOpen, closeModal, routeOrganization, isMember, routePage } = this.props;

    const Title = <CenteredText>{$t('noFreeSeats')}</CenteredText>;

    const Content = (
      <AlertTextContainer>{$t('noFreeSeatsAlert', { organizationName: routeOrganization.name })}</AlertTextContainer>
    );

    const onSeatsManagementClick = (e: React.MouseEvent) => {
      const route = getOrganizationMenuPageRoute(organizationMenuPages.seats, routeOrganization.id);
      navigateRoute(route, e, true);
      closeModal();
    };

    const Actions = (
      <Grid container spacing={3} justify={'center'} alignItems={'center'}>
        <Grid item>
          <Button text={$t('cancel')} onClick={closeModal} bgColor={theme.colors.warning} noShadow />
        </Grid>
        {isMember && routePage !== organizationMenuPages.seats && (
          <Grid item>
            <Button text={$t('manageSeats')} onClick={onSeatsManagementClick} noShadow />
          </Grid>
        )}
      </Grid>
    );

    return <ModalFrame isOpen={isOpen} onClose={closeModal} Title={Title} Content={Content} Actions={Actions} />;
  }
}

const AlertTextContainer = styled.div`
  max-height: 75vh;
  overflow-y: auto;
  background-color: ${theme.colors.info};
  color: black;
  padding: 8px;
  border-radius: ${theme.borderRadius.small};
  word-break: break-word;
`;
