import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath, selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { IRootState } from 'redux/reducer';
import {
  editWeatherConfigSagaAction,
  fetchOpenergyHistoricalWeatherConfigOptionsSagaAction,
} from 'redux/restResources/detail/weather/actions';
import { selectOpenergyHistoricalWeatherStationOptions } from 'redux/restResources/detail/weather/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { selectAccessToken } from 'redux/tokens/selectors';
import { getDetailApiRequestKey, getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra, gra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { WeatherConfigForm as Component } from './WeatherConfigForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeWeather = selectRouteResource(rrc.weather)(rootState);
  const isOpenergyWeather = !!routeWeather.openergy_historical_weather_series;
  const weatherToEdit = routeWeather;
  const stationOptions = selectOpenergyHistoricalWeatherStationOptions(rootState);

  /* custom api key only to track loading */
  const configWeatherLoadingKey = getDetailApiRequestKey(dra.configWeather, routeWeather.id);
  const isSubmitting = !!weatherToEdit && selectIsApiRequestPending(configWeatherLoadingKey)(rootState);

  const editApiRequestKey = getGenericApiRequestKey(gra.edit, rrc.weather, weatherToEdit.id);
  const editErrors = weatherToEdit && selectFormApiErrorsTranslationPath(editApiRequestKey)(rootState);
  const accessToken = selectAccessToken(rootState);

  return {
    routeWeather,
    isOpenergyWeather,
    weatherToEdit,
    stationOptions,
    isSubmitting,
    editErrors,
    accessToken,
    rootState,
  };
};

const mapDispatchToProps = {
  fetchOpenergyHistoricalWeatherConfigOptions: fetchOpenergyHistoricalWeatherConfigOptionsSagaAction,
  editWeatherConfig: editWeatherConfigSagaAction,
};

export const WeatherConfigForm = connect(mapStateToProps, mapDispatchToProps)(Component);
