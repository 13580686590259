import { _forEach } from 'libs/lodash';
import { _join } from 'libs/lodash';
import { _last } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _split } from 'libs/lodash';
import { IDayProfileExpandedSeries, IDayProfileSeries } from 'types/Obat/DayProfile';

import { formatISODate } from '../functions/datetimes/formatISODate';

export const expandSeries = (series: IDayProfileSeries, dayGroup: string): IDayProfileExpandedSeries => {
  const expandedSeries: IDayProfileExpandedSeries = {
    data: [],
    index: [],
    name: series.name,
    day_group: _split(dayGroup, '~')[1],
  };

  /* create */
  _forEach(series.index, (instant: string, i: number): void => {
    if (i > 0) {
      /* duplicate instant, and put las value */
      expandedSeries.index = [...expandedSeries.index, instant];
      // @ts-ignore (last not undefined because i>0)
      expandedSeries.data = [...expandedSeries.data, _last(expandedSeries.data)];
    }
    /* store */
    expandedSeries.index = [...expandedSeries.index, instant];
    expandedSeries.data = [...expandedSeries.data, series.data[i]];
  });

  /* add 00:00 of next day */
  expandedSeries.index = [...expandedSeries.index, '2012-01-02T00:00:00.000Z'];
  // @ts-ignore (last not undefined because i>0)
  expandedSeries.data = [...expandedSeries.data, _last(series.data)];

  return expandedSeries;
};

export const seriesToText = (series: IDayProfileSeries): string => {
  return _join(
    _map(series.index, (iso: string, i: number): string => {
      return formatISODate(iso, 'HH:mm') + ' ' + series.data[i].toString();
    }),
    '\n'
  );
};
