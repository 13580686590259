import { _forEach, _map } from 'libs/lodash';
import { ICalendar } from 'types/Obat/Calendar';
import { IDayGroup } from 'types/Obat/DayGroup';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';
import { IPeriod } from 'types/Obat/Period';

import { getObatResourceIndex } from './getObatResource';

export const enrichCalendars = (obatContents: IObatContents): IObatData => {
  const { calendar: calendars, day_group: dayGroups, period: periods } = obatContents.data;

  /* attach periods to day groups */
  let enrichedDayGroups = _map(
    dayGroups,
    (dayGroup: IDayGroup): IDayGroup => {
      return { ...dayGroup, periods_contents: [] };
    }
  );

  _forEach(periods, (period: IPeriod): void => {
    const dayGroupIndex = getObatResourceIndex(obatContents, 'day_group', period.day_group);

    enrichedDayGroups = _map(
      enrichedDayGroups,
      (dayGroup: IDayGroup, index: number): IDayGroup => {
        if (dayGroupIndex === index) {
          const enrichedPeriod = { ...period, calendar: dayGroup.calendar };

          return {
            ...dayGroup,
            periods_contents: [...dayGroup.periods_contents, enrichedPeriod],
          };
        }

        return dayGroup;
      }
    );
  });

  /* attach day groups to calendars */
  let enrichedCalendars = _map(
    calendars,
    (calendar: ICalendar): ICalendar => {
      return { ...calendar, day_groups_contents: [] };
    }
  );

  _forEach(enrichedDayGroups, (dayGroup: IDayGroup): void => {
    const calendarIndex = getObatResourceIndex(obatContents, 'calendar', dayGroup.calendar);

    enrichedCalendars = _map(
      enrichedCalendars,
      (calendar: ICalendar, index: number): ICalendar => {
        if (calendarIndex === index) {
          return {
            ...calendar,
            day_groups_contents: [...calendar.day_groups_contents, dayGroup],
          };
        }

        return calendar;
      }
    );
  });

  return { ...obatContents.data, calendar: enrichedCalendars };
};
