import { _parseInt } from 'libs/lodash';
import { _replace } from 'libs/lodash';

export const hexToRgba = (hex: string, a: number) => {
  hex = _replace(hex, '#', '');
  const r = _parseInt(hex.substring(0, 2), 16);
  const g = _parseInt(hex.substring(2, 4), 16);
  const b = _parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};
