import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { LegendItem } from './LegendItem.component';

export interface IPlotLegendItem {
  dotColor: string;
  title: string;
  blurred?: boolean;
}
interface IProps {
  itemsPerLine: number;
  legendItems: IPlotLegendItem[];
  onClick?: (id: string) => () => void;
  noTooltip?: boolean;
}

export class PlotLegend extends PureComponent<IProps> {
  public render(): ReactNode {
    const { legendItems, itemsPerLine, onClick, noTooltip } = this.props;

    return (
      <LegendWrapper>
        {_map(
          legendItems,
          (legendItem: IPlotLegendItem, index: number): ReactNode => {
            return (
              <LegendItem
                key={legendItem.title + index} /* Can have same title... */
                dotColor={legendItem.dotColor}
                text={legendItem.title}
                rightSpacing={0}
                itemWidth={`${100 / itemsPerLine}%`}
                onClick={onClick}
                noTooltip={noTooltip}
                blurred={legendItem.blurred}
              />
            );
          }
        )}
      </LegendWrapper>
    );
  }
}

const LegendWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
