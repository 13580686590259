import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { FormikSwitchInput } from 'components/Forms/Inputs/BooleanInputs/FormikSwitchInput';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { ObatResourceFormSpecificFields } from 'components/Forms/ObatResourceFormSpecificFields';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { _filter, _includes } from 'libs/lodash';
import { getYupBooleanValidation, getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { ISystemRequestParams } from 'types/Obat/System';
import { fdw } from 'utils/configs/drawerWidths';
import { withEmptyOption } from 'utils/functions/forms/withEmptyOption';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './SystemForm.container';

export interface ISystemFormValues {
  ref: string;
  type: string;
  method: string;
  fan_sfp: number | '';
  heating_aux_ratio: number | '';
  heating_aux_baseload: number | '';
  heating_capacity: number | '';
  heating_efficiency: number | '';
  heating_energy: string;
  heating_availability: string;
  cooling_aux_ratio: number | '';
  cooling_aux_baseload: number | '';
  cooling_capacity: number | '';
  cooling_efficiency: number | '';
  cooling_energy: string;
  cooling_availability: string;
  loop_recovery: number | '';
  heat_exchanger_bypass: boolean;
  heat_exchanger_bypass_outdoor_low_temperature: number | '';
  heat_exchanger_bypass_setpoint_at_outdoor_low_temperature: number | '';
  heat_exchanger_bypass_outdoor_high_temperature: number | '';
  heat_exchanger_bypass_setpoint_at_outdoor_high_temperature: number | '';
  /* specific fields */
  supply_cooling_trigger: number | '';
  supply_heating_trigger: number | '';
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<ISystemFormValues, ISystemRequestParams>();

export class SystemForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.system}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<ISystemFormValues>): ReactNode => {
      const {
        typeOptions,
        systemToEdit,
        selectSchedulesByTypeOptions,
        openPlotsModal,
        selectSpecificDefaultValues,
      } = this.props;

      const {
        type,
        method,
        heating_energy,
        heating_availability,
        cooling_energy,
        cooling_availability,
        heat_exchanger_bypass,
      } = formikProps.values;

      const onPlotsModalIconClick = (scheduleRef: string) => {
        return (): void => {
          openPlotsModal(scheduleRef, orc.schedule);
        };
      };

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'type'}
                formikProps={formikProps}
                options={typeOptions}
                onSelect={this.onSystemTypeSelect(formikProps)}
                renderOptionLabel={$tEnums}
                required
                obatResourceToBeCheckedForCommitment={systemToEdit}
              />
            </Grid>
          </Grid>
          {this.isMethodInputDisplayed(type) && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikDropdownInput
                  field={'method'}
                  formikProps={formikProps}
                  options={this.getMethodsByScheduleTypeOptions(type)}
                  renderOptionLabel={$tEnums}
                  obatResourceToBeCheckedForCommitment={systemToEdit}
                  required
                  onSelect={this.onSystemMethodSelect(formikProps)}
                />
              </Grid>
            </Grid>
          )}
          {this.isAirLoopSectionDisplayed(type, method) && (
            <Section title={$t('ventilationEquipment')} marginTop={20}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormikWriteInput field={'fan_sfp'} formikProps={formikProps} label={$t('fanSfpWithUnit')} />
                </Grid>
              </Grid>
            </Section>
          )}
          {this.isHeatExchangerSectionDisplayed(type, method) && (
            <Section title={$t('heatExchanger')} marginTop={20}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormikWriteInput
                    field={'loop_recovery'}
                    formikProps={formikProps}
                    required={this.isBypassSettingsInputsDisplayed(type, method, heat_exchanger_bypass)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormikSwitchInput field={'heat_exchanger_bypass'} formikProps={formikProps} />
                </Grid>
              </Grid>
              {this.isBypassSettingsInputsDisplayed(type, method, heat_exchanger_bypass) && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'heat_exchanger_bypass_outdoor_low_temperature'}
                        formikProps={formikProps}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'heat_exchanger_bypass_outdoor_high_temperature'}
                        formikProps={formikProps}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'heat_exchanger_bypass_setpoint_at_outdoor_low_temperature'}
                        formikProps={formikProps}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'heat_exchanger_bypass_setpoint_at_outdoor_high_temperature'}
                        formikProps={formikProps}
                        required
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Section>
          )}
          {this.isHeatingSectionDisplayed(type, method) && (
            <Section title={$t('heating')} marginTop={20}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormikDropdownInput
                    field={'heating_energy'}
                    formikProps={formikProps}
                    options={this.getHeatingEnergyOptions(type, method)}
                    label={$t('energy')}
                    renderOptionLabel={$tEnums}
                    obatResourceToBeCheckedForCommitment={systemToEdit}
                    required={this.isHeatingEnergyRequired(type)}
                  />
                </Grid>
                {this.isHeatingEnergySelected(heating_energy) && (
                  <>
                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'heating_efficiency'}
                        formikProps={formikProps}
                        label={$t('efficiency')}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'heating_aux_ratio'}
                        formikProps={formikProps}
                        label={$t('auxiliaryRatio')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'heating_aux_baseload'}
                        formikProps={formikProps}
                        label={$t('auxiliaryBaseloadWithUnit')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={11}>
                          <FormikDropdownInput
                            field={'heating_availability'}
                            label={$t('availabilitySchedule')}
                            formikProps={formikProps}
                            options={withEmptyOption(selectSchedulesByTypeOptions('control'))}
                          />
                        </Grid>
                        {heating_availability && (
                          <Grid item xs={1}>
                            <IconButton
                              size={16}
                              action={'seeDetails'}
                              onClick={onPlotsModalIconClick(heating_availability)}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {this.isHeatingCapacityInputDisplayed(type, method) && (
                      <Grid item xs={12}>
                        <FormikWriteInput
                          field={'heating_capacity'}
                          formikProps={formikProps}
                          label={$t('capacityWithUnit')}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Section>
          )}
          {this.isCoolingSectionDisplayed(type, method) && (
            <Section title={$t('cooling')} marginTop={20}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormikDropdownInput
                    field={'cooling_energy'}
                    formikProps={formikProps}
                    options={this.getCoolingEnergyOptions(type, method)}
                    label={$t('energy')}
                    renderOptionLabel={$tEnums}
                    obatResourceToBeCheckedForCommitment={systemToEdit}
                    required={this.isCoolingEnergyRequired(type)}
                  />
                </Grid>

                {this.isCoolingEnergySelected(cooling_energy) && (
                  <>
                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'cooling_efficiency'}
                        formikProps={formikProps}
                        label={$t('efficiency')}
                        disabled={cooling_energy === ''}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'cooling_aux_ratio'}
                        formikProps={formikProps}
                        label={$t('auxiliaryRatio')}
                        disabled={cooling_energy === ''}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'cooling_aux_baseload'}
                        formikProps={formikProps}
                        label={$t('auxiliaryBaseloadWithUnit')}
                        disabled={cooling_energy === ''}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={11}>
                          <FormikDropdownInput
                            field={'cooling_availability'}
                            label={$t('availabilitySchedule')}
                            formikProps={formikProps}
                            options={withEmptyOption(selectSchedulesByTypeOptions('control'))}
                          />
                        </Grid>
                        {cooling_availability && (
                          <Grid item xs={1}>
                            <IconButton
                              size={16}
                              action={'seeDetails'}
                              onClick={onPlotsModalIconClick(cooling_availability)}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <FormikWriteInput
                        field={'cooling_capacity'}
                        formikProps={formikProps}
                        label={$t('capacityWithUnit')}
                        disabled={cooling_energy === ''}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Section>
          )}
          {this.isSpecificSectionDisplayed(type, method) && (
            <Section title={$t('advancedSettings')} marginTop={20} isInitiallyCollapsed>
              <Grid container spacing={1}>
                <ObatResourceFormSpecificFields
                  formikProps={formikProps}
                  specificDefaultValues={selectSpecificDefaultValues(type, method)}
                  requiredFields={this.getRequiredSpecificFields(type, method)}
                />
              </Grid>
            </Section>
          )}
        </>
      );
    };
  };

  private getInitialFormValues = (): ISystemFormValues => {
    const { systemToEdit } = this.props;

    return {
      ref: setFormInitialValue(systemToEdit, 'ref'),
      type: setFormInitialValue(systemToEdit, 'type'),
      method: setFormInitialValue(systemToEdit, 'method', ''),
      fan_sfp: setFormInitialValue(systemToEdit, 'fan_sfp'),
      loop_recovery: setFormInitialValue(systemToEdit, 'loop_recovery'),
      heating_aux_ratio: setFormInitialValue(systemToEdit, 'heating_aux_ratio'),
      heating_aux_baseload: setFormInitialValue(systemToEdit, 'heating_aux_baseload'),
      heating_capacity: setFormInitialValue(systemToEdit, 'heating_capacity'),
      heating_efficiency: setFormInitialValue(systemToEdit, 'heating_efficiency'),
      heating_energy: setFormInitialValue(systemToEdit, 'heating_energy'),
      heating_availability: setFormInitialValue(systemToEdit, 'heating_availability'),
      cooling_aux_ratio: setFormInitialValue(systemToEdit, 'cooling_aux_ratio'),
      cooling_aux_baseload: setFormInitialValue(systemToEdit, 'cooling_aux_baseload'),
      cooling_capacity: setFormInitialValue(systemToEdit, 'cooling_capacity'),
      cooling_efficiency: setFormInitialValue(systemToEdit, 'cooling_efficiency'),
      cooling_energy: setFormInitialValue(systemToEdit, 'cooling_energy'),
      cooling_availability: setFormInitialValue(systemToEdit, 'cooling_availability'),
      heat_exchanger_bypass: setFormInitialValue(systemToEdit, 'heat_exchanger_bypass'),
      heat_exchanger_bypass_outdoor_low_temperature: setFormInitialValue(
        systemToEdit,
        'heat_exchanger_bypass_outdoor_low_temperature',
        19
      ),
      heat_exchanger_bypass_setpoint_at_outdoor_low_temperature: setFormInitialValue(
        systemToEdit,
        'heat_exchanger_bypass_setpoint_at_outdoor_low_temperature',
        19
      ),
      heat_exchanger_bypass_outdoor_high_temperature: setFormInitialValue(
        systemToEdit,
        'heat_exchanger_bypass_outdoor_high_temperature',
        26
      ),
      heat_exchanger_bypass_setpoint_at_outdoor_high_temperature: setFormInitialValue(
        systemToEdit,
        'heat_exchanger_bypass_setpoint_at_outdoor_high_temperature',
        26
      ),
      /* specific fields */
      supply_heating_trigger: setFormInitialValue(systemToEdit, 'supply_heating_trigger'),
      supply_cooling_trigger: setFormInitialValue(systemToEdit, 'supply_cooling_trigger'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<ISystemFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      type: getYupStringValidation(true),
      method: getYupStringValidation(true),
      cooling_aux_ratio: getYupNumberValidation(false, 0, 1),
      cooling_aux_baseload: getYupNumberValidation(false, 0),
      cooling_capacity: getYupNumberValidation(),
      cooling_efficiency: yup.number().when(['type', 'method', 'cooling_energy'], {
        is: (
          type: ISystemFormValues['type'],
          method: ISystemFormValues['method'],
          coolingEnergy: ISystemFormValues['cooling_energy']
        ): boolean => this.isCoolingSectionDisplayed(type, method) && this.isCoolingEnergySelected(coolingEnergy),
        then: getYupNumberValidation(true, 0),
        otherwise: getYupNumberValidation(false, 0),
      }),
      cooling_energy: yup.string().when(['type'], {
        is: this.isCoolingEnergyRequired,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
      cooling_availability: getYupStringValidation(),
      fan_sfp: getYupNumberValidation(),
      heating_aux_ratio: getYupNumberValidation(false, 0, 1),
      heating_aux_baseload: getYupNumberValidation(false, 0),
      heating_capacity: getYupNumberValidation(),
      heating_efficiency: yup.number().when(['type', 'method', 'heating_energy'], {
        is: (
          type: ISystemFormValues['type'],
          method: ISystemFormValues['method'],
          heatingEnergy: ISystemFormValues['heating_energy']
        ): boolean => this.isHeatingSectionDisplayed(type, method) && this.isHeatingEnergySelected(heatingEnergy),
        then: getYupNumberValidation(true, 0),
        otherwise: getYupNumberValidation(false, 0),
      }),
      heating_energy: yup.string().when(['type'], {
        is: this.isHeatingEnergyRequired,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
      heating_availability: getYupStringValidation(),
      loop_recovery: yup.number().when(['type', 'method'], {
        is: (
          type: ISystemFormValues['type'],
          method: ISystemFormValues['method'],
          heatExchangerBypass: ISystemFormValues['heat_exchanger_bypass']
        ): boolean => this.isBypassSettingsInputsDisplayed(type, method, heatExchangerBypass),
        then: getYupNumberValidation(true, 0, 1),
        otherwise: getYupNumberValidation(false, 0, 1),
      }),
      heat_exchanger_bypass: getYupBooleanValidation(false),
      heat_exchanger_bypass_outdoor_low_temperature: yup.number().when(['type', 'method', 'heat_exchanger_bypass'], {
        is: (
          type: ISystemFormValues['type'],
          method: ISystemFormValues['method'],
          heatExchangerBypass: ISystemFormValues['heat_exchanger_bypass']
        ): boolean => this.isBypassSettingsInputsDisplayed(type, method, heatExchangerBypass),
        then: getYupNumberValidation(true, 0).test({
          test(): boolean {
            return yupTestBypassTemperatures(this.parent);
          },
          message: 'yupTestMinMaxTemperatures',
        }),
        otherwise: getYupNumberValidation(false, 0),
      }),
      heat_exchanger_bypass_setpoint_at_outdoor_low_temperature: yup
        .number()
        .when(['type', 'method', 'heat_exchanger_bypass'], {
          is: (
            type: ISystemFormValues['type'],
            method: ISystemFormValues['method'],
            heatExchangerBypass: ISystemFormValues['heat_exchanger_bypass']
          ): boolean => this.isBypassSettingsInputsDisplayed(type, method, heatExchangerBypass),
          then: getYupNumberValidation(true, 0),
          otherwise: getYupNumberValidation(false, 0),
        }),
      heat_exchanger_bypass_outdoor_high_temperature: yup.number().when(['type', 'method', 'heat_exchanger_bypass'], {
        is: (
          type: ISystemFormValues['type'],
          method: ISystemFormValues['method'],
          heatExchangerBypass: ISystemFormValues['heat_exchanger_bypass']
        ): boolean => this.isBypassSettingsInputsDisplayed(type, method, heatExchangerBypass),
        then: getYupNumberValidation(true, 0).test({
          test(): boolean {
            return yupTestBypassTemperatures(this.parent);
          },
          message: 'yupTestMinMaxTemperatures',
        }),
        otherwise: getYupNumberValidation(false, 0),
      }),
      heat_exchanger_bypass_setpoint_at_outdoor_high_temperature: yup
        .number()
        .when(['type', 'method', 'heat_exchanger_bypass'], {
          is: (
            type: ISystemFormValues['type'],
            method: ISystemFormValues['method'],
            heatExchangerBypass: ISystemFormValues['heat_exchanger_bypass']
          ): boolean => this.isBypassSettingsInputsDisplayed(type, method, heatExchangerBypass),
          then: getYupNumberValidation(true, 0),
          otherwise: getYupNumberValidation(false, 0),
        }),
      /* specific fields */
      supply_heating_trigger: yup
        .number()
        .when(['type', 'method'], {
          is: (type: ISystemFormValues['type'], method: ISystemFormValues['method']): boolean =>
            _includes(this.getRequiredSpecificFields(type, method), 'supply_heating_trigger'),
          then: getYupNumberValidation(true),
          otherwise: getYupNumberValidation(false),
        })
        .test({
          test(): boolean {
            return yupTestSupplyTemperatures(this.parent);
          },
          message: 'yupTestHeatCoolTemperatures',
        }),
      supply_cooling_trigger: yup
        .number()
        .when(['type', 'method'], {
          is: (type: ISystemFormValues['type'], method: ISystemFormValues['method']): boolean =>
            _includes(this.getRequiredSpecificFields(type, method), 'supply_cooling_trigger'),
          then: getYupNumberValidation(true),
          otherwise: getYupNumberValidation(false),
        })
        .test({
          test(): boolean {
            return yupTestSupplyTemperatures(this.parent);
          },
          message: 'yupTestHeatCoolTemperatures',
        }),
    }) as yup.ObjectSchema<ISystemFormValues>;
  };

  /** System */
  private onSystemTypeSelect = (formikProps: FormikProps<ISystemFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      formikProps.setValues({
        ...formikProps.values,
        type: selectedValue,
        method: '',
      });
    };
  };

  private onSystemMethodSelect = (formikProps: FormikProps<ISystemFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      const { type } = formikProps.values;

      formikProps.setValues({
        ...formikProps.values,
        method: selectedValue,
        ...this.props.selectSpecificDefaultValues(type, selectedValue),
      });
    };
  };

  /** Method */
  private isMethodInputDisplayed = (type: ISystemFormValues['type']): boolean => type !== '';

  private getMethodsByScheduleTypeOptions = (type: ISystemFormValues['type']): string[] => {
    if (type === '') {
      return [];
    }

    /* Filter radiative emitter until Antoine is ready */
    return _filter(this.props.selectMethodOptions(type), (method: string): boolean => method !== 'radiative_emitter');
  };

  /** Air Loop */
  private isAirLoopSectionDisplayed = (type: ISystemFormValues['type'], method: ISystemFormValues['method']): boolean =>
    method !== '' && _includes(['heating_loop', 'cooling_loop', 'air_loop'], type);

  /** Heat Exchanger */
  private isHeatExchangerSectionDisplayed = (
    type: ISystemFormValues['type'],
    method: ISystemFormValues['method']
  ): boolean => type === 'air_loop' && !_includes(['', 'extract-only', 'zone_temperature_control_ideal_ahu'], method);

  private isBypassSettingsInputsDisplayed = (
    type: ISystemFormValues['type'],
    method: ISystemFormValues['method'],
    heatExchangerBypass: ISystemFormValues['heat_exchanger_bypass']
  ): boolean => this.isHeatExchangerSectionDisplayed(type, method) && !!heatExchangerBypass;

  /** Cooling */
  private isCoolingSectionDisplayed = (type: ISystemFormValues['type'], method: ISystemFormValues['method']): boolean =>
    method !== '' && (type === 'cooling_loop' || (type === 'air_loop' && 'extract_only' !== method));

  private isCoolingEnergySelected = (coolingEnergy: ISystemFormValues['cooling_energy']): boolean => !!coolingEnergy;

  private isCoolingEnergyRequired = (type: ISystemFormValues['type']): boolean => type === 'cooling_loop';

  private getCoolingEnergyOptions = (
    type: ISystemFormValues['type'],
    method: ISystemFormValues['method']
  ): string[] => {
    if (!method) {
      return [];
    }
    if (type === 'air_loop' && method !== 'extract_only') {
      return withEmptyOption(this.props.coolingEnergyOptions);
    }

    return this.props.coolingEnergyOptions;
  };

  /** Heating */
  private isHeatingSectionDisplayed = (type: ISystemFormValues['type'], method: ISystemFormValues['method']): boolean =>
    _includes(['heating_loop', 'dhw_loop'], type) || (type === 'air_loop' && !_includes(['', 'extract_only'], method));

  private isHeatingCapacityInputDisplayed = (
    type: ISystemFormValues['type'],
    method: ISystemFormValues['method']
  ): boolean => method !== '' && (type === 'heating_loop' || (type === 'air_loop' && method !== 'extract_only'));

  private isHeatingEnergySelected = (heatingEnergy: ISystemFormValues['heating_energy']): boolean => !!heatingEnergy;

  private isHeatingEnergyRequired = (type: ISystemFormValues['type']): boolean =>
    _includes(['heating_loop', 'dhw_loop'], type);

  private getHeatingEnergyOptions = (
    type: ISystemFormValues['type'],
    method: ISystemFormValues['method']
  ): string[] => {
    if (!method) {
      return [];
    }
    if (type === 'air_loop' && method !== 'extract_only') {
      return withEmptyOption(this.props.heatingEnergyOptions);
    }

    return this.props.heatingEnergyOptions;
  };

  /** Specifics */
  private isSpecificSectionDisplayed = (
    type: ISystemFormValues['type'],
    method: ISystemFormValues['method']
  ): boolean =>
    method !== '' &&
    type === 'air_loop' &&
    _includes(['fixed_supply_temperature_ahu', 'zone_temperature_control_ahu'], method);

  private getRequiredSpecificFields = (
    type: ISystemFormValues['type'],
    method: ISystemFormValues['method']
  ): string[] => {
    const specificFields = ['supply_heating_trigger', 'supply_cooling_trigger'];

    return this.isSpecificSectionDisplayed(type, method) ? specificFields : [];
  };
}

const yupTestSupplyTemperatures = (formValues: ISystemFormValues): boolean => {
  const { supply_cooling_trigger, supply_heating_trigger } = formValues;

  return !supply_cooling_trigger || !supply_heating_trigger || supply_cooling_trigger >= 1 + supply_heating_trigger;
};

const yupTestBypassTemperatures = (formValues: ISystemFormValues): boolean => {
  const { heat_exchanger_bypass_outdoor_high_temperature, heat_exchanger_bypass_outdoor_low_temperature } = formValues;

  return (
    !heat_exchanger_bypass_outdoor_high_temperature ||
    !heat_exchanger_bypass_outdoor_low_temperature ||
    heat_exchanger_bypass_outdoor_high_temperature >= 1 + heat_exchanger_bypass_outdoor_low_temperature
  );
};
