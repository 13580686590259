import { _has } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { IHourlyResultsYearData, IHourlyResultsYearlyData } from 'redux/restResources/detail/simulationGroup/reducer';
import { ISimulationSeriesData } from 'types/SimulationGroup/Simulation';

export const getUpdatedHourlyResultsYearlyData = (
  currentYearlyData: IHourlyResultsYearlyData,
  plotYear: string,
  plotYearNewData: IHourlyResultsYearData
): IHourlyResultsYearlyData => {
  /* Update yearsData with the plotYearNewData */

  if (_has(currentYearlyData, plotYear)) {
    const updatedPlotYearData = _reduce(
      plotYearNewData,
      (
        _updatedPlotYearData: IHourlyResultsYearData,
        simulationSeriesData: ISimulationSeriesData,
        simulationId: string
      ) => {
        return {
          ..._updatedPlotYearData,
          [simulationId]: _has(_updatedPlotYearData, simulationId)
            ? { ..._updatedPlotYearData[simulationId], ...simulationSeriesData }
            : simulationSeriesData,
        };
      },
      currentYearlyData[plotYear]
    );

    return { ...currentYearlyData, [plotYear]: updatedPlotYearData };
  } else {
    return { ...currentYearlyData, [plotYear]: plotYearNewData };
  }
};
