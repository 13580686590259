import { $t } from 'utils/functions/translate';

export const getSeatTooltipContent = (
  projectOrOrganization: { is_activated: boolean; is_taking_up_seat: boolean; is_leaving_seat: boolean },
  buttonOrTable: 'button' | 'table'
): string => {
  const { is_activated, is_taking_up_seat, is_leaving_seat } = projectOrOrganization;

  return is_activated && !is_taking_up_seat
    ? buttonOrTable === 'table'
      ? $t('youCanWorkGratis')
      : $t('gratisAlert')
    : is_leaving_seat
    ? buttonOrTable === 'table'
      ? $t('youAreLeavingSeat')
      : $t('takeSeat')
    : is_taking_up_seat
    ? buttonOrTable === 'table'
      ? $t('youAreTakingSeat')
      : $t('leaveSeat')
    : $t('takeSeat');
};
