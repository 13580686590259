import { _map } from 'libs/lodash';
import { _range } from 'libs/lodash';
import { _toString } from 'libs/lodash';
import moment from 'moment';

export const getHistoricalYearRange = (startIso: string, endIso: string): string[] => {
  const startYear = moment.utc(startIso).year();
  const endYear = moment.utc(endIso).year();
  const yearRange = _range(startYear, endYear + 1);

  return _map(yearRange, _toString);
};
