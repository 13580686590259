// tslint:disable:no-any
import { _includes } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { updateLogoutModalCauseAction, updateLogoutModalTimerAction } from 'redux/modals/logoutModal/actions';
import { resetAppAction } from 'redux/routing/actions';

import { callApiWithAuthenticationSaga } from '../tokens/sagas';

import { apiRequestErrorAction, apiRequestSuccessAction, startApiRequestAction } from './actions';

export function* apiRequestSaga(
  apiRequestKey: string,
  hasCallback: boolean,
  request: (...requestArgs: any[]) => void,
  ...requestArgs: any[]
): SagaIterator {
  try {
    yield put(startApiRequestAction(apiRequestKey, hasCallback));
    // console.log('api request call started', { apiRequestKey, requestArgs });
    const requestResponse = yield call(callApiWithAuthenticationSaga, request, ...requestArgs);
    // console.log('api request call finished', { requestResponse });
    yield put(apiRequestSuccessAction(apiRequestKey, hasCallback));

    return requestResponse;
  } catch (requestError) {
    if (requestError.status === 401) {
      /** no or invalid token */
      if (_includes(requestError.text, 'from another computer')) {
        yield put(updateLogoutModalCauseAction('kickedOut'));
      }
      let logoutTimer = 5;
      while (logoutTimer > 0) {
        yield put(updateLogoutModalTimerAction(logoutTimer));
        yield delay(1000);
        logoutTimer = logoutTimer - 1;
      }
      yield put(resetAppAction());
    }

    /** this should happen after reset app so that the error is stored in the new store */
    const errors = { ...JSON.parse(requestError.text), status: requestError.status };
    console.warn('apiRequestKey', apiRequestKey);
    console.warn('request errors: ', errors.errors);
    console.warn('request error message: ', errors.error_message);
    yield put(apiRequestErrorAction(apiRequestKey, errors.errors, errors.error_message, errors.status));
  }
}
