import { AlertIcon } from 'components/Icons';
import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import React, { PureComponent, ReactNode } from 'react';
import { getWeatherMenuPageRoute, projectMenuPages, weatherMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { IRestResource } from 'types/RestResource';
import { IWeatherListed } from 'types/Weather/Weather';
import { formatISODateToDstDatetime } from 'utils/functions/datetimes/formatISODate';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rrc } from 'utils/strings/resourceClasses';

import { rm } from 'utils/strings/routingMenus';
import { WeatherForm } from '../WeatherForm';
import { IProps } from './WeathersList.container';

export class WeathersList extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeProject } = this.props;

    return (
      <RestResourcesListPage
        routingMenu={rm.project}
        routeParentId={routeProject.id}
        selectedPage={projectMenuPages.weathers}
        FormComponent={WeatherForm}
        getCreationSuccessLandingPageUrl={this.getCreationSuccessLandingPageUrl}
        resourceClass={rrc.weather}
        parentResourceClass={rrc.project}
        columnsParams={columnsParams}
        availableActions={['edit', 'copy', 'delete']}
      />
    );
  }

  private getCreationSuccessLandingPageUrl = (routeProjectId: string, createdResource: IRestResource) => {
    return getWeatherMenuPageRoute(weatherMenuPages.default, routeProjectId, createdResource.id);
  };
}

const columnsParams: ITableColumnParams[] = [
  {
    id: 'name',
    resourceField: 'name',
    getNavigationUrl: (weather: IWeatherListed): string => {
      return getWeatherMenuPageRoute(weatherMenuPages.default, weather.project.id, weather.id);
    },
  },
  {
    id: 'format',
    withFilterOptions: true,
    renderCustomText: (weather: IWeatherListed) =>
      !!weather.generic_weather_series ? $t('generic') : $t('historical'),
  },
  {
    id: 'lastModification',
    resourceField: 'last_modification',
    renderCustomText: (weather: IWeatherListed) => formatISODateToDstDatetime(weather.last_modification),
  },
  {
    id: 'configuration',
    resourceField: 'configured',
    withFilterOptions: true,
    renderCustomDOM: (weather: IWeatherListed): ReactNode => {
      return (
        <TableIcon>
          {weather.configured ? (
            <AlertIcon status={as.success} tooltipContent={$t('configured')} />
          ) : (
            <AlertIcon status={as.failed} tooltipContent={$t('notConfigured')} />
          )}
        </TableIcon>
      );
    },
    getCustomDOMFilterComparisonValue: (weather: IWeatherListed): string =>
      weather.configured ? $t('configured') : $t('notConfigured'),
  },
];

const TableIcon = styled.div`
  margin-bottom: -4px;
`;
