import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectIsAdminPermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { ProjectPermissionsList as Component } from './ProjectPermissionsList.component';

// tslint:disable-next-line
interface IOwnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  const routeProject = selectRouteResource(rrc.project)(rootState);

  return {
    routeProject,
    isAdminPermissionMissing: selectIsAdminPermissionMissing(rootState),
  };
};

const mapDispatchToProps = {};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export const ProjectPermissionsList = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
