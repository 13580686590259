import { _find } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { getSelectOptions } from 'utils/functions/forms/getSelectOptions';
import { ISelectOption } from 'utils/functions/forms/getSelectOptions/getSelectOptions';

import { IDropdownInputProps } from '../DropdownInput/DropdownInput.component';
import { ReactSelect, SelectWrapper } from '../ReactSelect';

export class FilterDropdownInput extends PureComponent<IDropdownInputProps> {
  public render(): ReactNode {
    const {
      options,
      field,
      value,
      isDisabled,
      optionsPlacement,
      optionsPosition,
      renderOptionLabel,
      onBlur,
    } = this.props;

    const selectOptions = getSelectOptions(options, renderOptionLabel);
    let selectedOption: ISelectOption = { value: '', label: '' };

    selectedOption = _find(selectOptions, ['value', value]) || selectedOption;

    return (
      <SelectWrapper>
        <ReactSelect
          name={field}
          isClearable={true}
          isDisabled={isDisabled}
          optionsPlacement={optionsPlacement}
          optionsPosition={optionsPosition}
          onBlur={onBlur}
          handleChange={this.handleChange}
          options={selectOptions}
          selectedOption={selectedOption}
          minColumnWidth={160}
        />
      </SelectWrapper>
    );
  }

  // tslint:disable-next-line:no-any
  private handleChange = (selectedOption: any): void => {
    const { onSelect, field } = this.props;
    selectedOption === null ? onSelect(field, '') : onSelect(field, selectedOption.value);
  };
}
