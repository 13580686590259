import { i18n } from 'libs/i18n';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import React, { PureComponent, ReactNode } from 'react';

import { IProps } from './MainComponent.container';

export default class MainComponent extends PureComponent<IProps> {
  public componentDidMount(): void {
    const { accessToken, fetchMyUser } = this.props;
    if (accessToken) {
      fetchMyUser();
    }
  }

  public componentDidUpdate(prevProps: IProps): void {
    const { myUser } = this.props;

    if (myUser && (!prevProps.myUser || (prevProps.myUser && prevProps.myUser.language !== myUser.language))) {
      i18n.changeLanguage(myUser.language, () => this.forceUpdate());
      switch (myUser.language) {
        case 'fr': {
          moment.locale('fr');
          break;
        }
        default: {
          moment.locale('en');
        }
      }
    }
  }

  public render(): ReactNode {
    /* the key "language" enables to re-render this Main component after each language change */
    return (
      <div key={i18n.language} className={'no-scrollbar'}>
        {this.props.children}
      </div>
    );
  }
}
