import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { ocfe } from 'utils/configs/enums';
import { orc } from 'utils/strings/resourceClasses';

import { selectObatContentsFlatOptionsList } from 'redux/enums/selectors';
import { EPlusParametersForm as EPlusParametersFormComponent } from './EPlusParametersForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const ePlusParametersToEdit = selectFormInitialResourceFromTable(orc.e_plus_parameters)(rootState);

  return {
    ePlusParametersToEdit,
    solarDistributionOptions: selectObatContentsFlatOptionsList(ocfe.solar_distributions)(rootState),
  };
};

const mapDispatchToProps = {};

export const EPlusParametersForm = connect(mapStateToProps, mapDispatchToProps)(EPlusParametersFormComponent);
