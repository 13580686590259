// tslint:disable:no-any
import { Action } from 'redux';
import { IUserTaskRetrieved } from 'types/UserTask';
import { IRestResourceClass } from 'utils/strings/resourceClasses';

// _____ UPDATE_TASK_ACTION _____

export const UPDATE_TASK_ACTION = 'UPDATE_TASK_ACTION';
export interface IUpdateTaskAction extends Action<'UPDATE_TASK_ACTION'> {
  payload: {
    task: IUserTaskRetrieved;
    taskKey: string;
  };
}
export const updateTaskAction = (task: IUserTaskRetrieved, taskKey: string): IUpdateTaskAction => ({
  type: UPDATE_TASK_ACTION,
  payload: {
    task,
    taskKey,
  },
});

// _____ REFRESH_TASK_SAGA_ACTION _____

export const REFRESH_TASK_SAGA_ACTION = 'REFRESH_TASK_SAGA_ACTION';
export interface IRefreshTaskSagaAction extends Action<'REFRESH_TASK_SAGA_ACTION'> {
  payload: {
    taskKey: string;
    routeResourceToUpdateClass?: IRestResourceClass;
    pagesWhereToRefresh?: string[];
    noWarnings?: boolean;
  };
}
export const refreshTaskSagaAction = (
  taskKey: string,
  routeResourceToUpdateClass?: IRestResourceClass,
  pagesWhereToRefresh?: string[],
  noWarnings?: boolean
): IRefreshTaskSagaAction => ({
  type: REFRESH_TASK_SAGA_ACTION,
  payload: {
    taskKey,
    routeResourceToUpdateClass,
    pagesWhereToRefresh,
    noWarnings,
  },
});

// _____ CLEAR_TASK_ACTION _____

export const CLEAR_TASK_ACTION = 'CLEAR_TASK_ACTION';
export interface IClearTaskAction extends Action<'CLEAR_TASK_ACTION'> {
  payload: {
    taskKey: string;
  };
}
export const clearTaskAction = (taskKey: string): IClearTaskAction => ({
  type: CLEAR_TASK_ACTION,
  payload: {
    taskKey,
  },
});

export type ITasksActions = IUpdateTaskAction | IClearTaskAction;
