import { connect } from 'react-redux';
import { deleteObatResourceSagaAction } from 'redux/obatResources/actions';
import { IRootState } from 'redux/reducer';
import { selectMyLanguage } from 'redux/restResources/detail/me/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { IDayGroup } from 'types/Obat/DayGroup';
import { IPeriod } from 'types/Obat/Period';
import { rrc } from 'utils/strings/resourceClasses';

import { PeriodForm as PeriodFormComponent } from './PeriodForm.component';

interface IOwnProps {
  close?: () => void;
  dayGroup: IDayGroup;
  period?: IPeriod;
  isInitiallyCollapsed?: boolean;
  titleIndex?: number;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IInjectedProps, IOwnProps {}

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return {
    periodToEdit: ownProps.period,
    routeObat: selectRouteResource(rrc.obat)(rootState),
    myLanguage: selectMyLanguage(rootState),
  };
};

const mapDispatchToProps = {
  deleteObatResource: deleteObatResourceSagaAction,
};

export type IInjectedProps = IStateProps & IDispatchProps;

export const PeriodForm = connect(mapStateToProps, mapDispatchToProps)(PeriodFormComponent);
