import { Action } from 'redux';
import { IGeometriesTemplates, IImportGeometryFields } from 'types/Geometry/Geometry';
import { IProgressStatus } from 'utils/strings/progressStatus';

// _____ IMPORT_GEOMETRY_SAGA_ACTION _____

export const IMPORT_GEOMETRY_SAGA_ACTION = 'IMPORT_GEOMETRY_SAGA_ACTION';
export interface IImportGeometrySagaAction extends Action<'IMPORT_GEOMETRY_SAGA_ACTION'> {
  payload: {
    fileContentBlob?: Blob;
    importFields: IImportGeometryFields;
  };
}
export const importGeometrySagaAction = (
  importFields: IImportGeometryFields,
  fileContentBlob: Blob | undefined = undefined
): IImportGeometrySagaAction => ({
  type: IMPORT_GEOMETRY_SAGA_ACTION,
  payload: {
    importFields,
    fileContentBlob,
  },
});

// _____ UPDATE_GEOMETRY_IMPORT_PROGRESS_ACTION _____

export const UPDATE_GEOMETRY_IMPORT_PROGRESS_ACTION = 'UPDATE_GEOMETRY_IMPORT_PROGRESS_ACTION';
export interface IUpdateGeometryImportProgressAction extends Action<'UPDATE_GEOMETRY_IMPORT_PROGRESS_ACTION'> {
  payload: {
    progress: IProgressStatus;
  };
}
export const updateGeometryImportProgressAction = (progress: IProgressStatus): IUpdateGeometryImportProgressAction => ({
  type: UPDATE_GEOMETRY_IMPORT_PROGRESS_ACTION,
  payload: {
    progress,
  },
});

// _____ REFRESH_IMPORT_GEOMETRY_TASK_SAGA_ACTION _____

export const REFRESH_IMPORT_GEOMETRY_TASK_SAGA_ACTION = 'REFRESH_IMPORT_GEOMETRY_TASK_SAGA_ACTION';
export interface IRefreshImportGeometryTaskSagaAction extends Action<'REFRESH_IMPORT_GEOMETRY_TASK_SAGA_ACTION'> {}
export const refreshImportGeometryTaskSagaAction = (): IRefreshImportGeometryTaskSagaAction => ({
  type: REFRESH_IMPORT_GEOMETRY_TASK_SAGA_ACTION,
});

// _____ EXPORT_GEOMETRY_SAGA_ACTION _____

export const EXPORT_GEOMETRY_SAGA_ACTION = 'EXPORT_GEOMETRY_SAGA_ACTION';
export interface IExportGeometrySagaAction extends Action<'EXPORT_GEOMETRY_SAGA_ACTION'> {}
export const exportGeometrySagaAction = (): IExportGeometrySagaAction => ({
  type: EXPORT_GEOMETRY_SAGA_ACTION,
});

// _____ CLEAR_FLOORSPACE_ACTION _____

export const CLEAR_FLOORSPACE_ACTION = 'CLEAR_FLOORSPACE_ACTION';
export interface IClearFloorspaceAction extends Action<'CLEAR_FLOORSPACE_ACTION'> {
  payload: {};
}
export const clearFloorspaceAction = (): IClearFloorspaceAction => ({
  type: CLEAR_FLOORSPACE_ACTION,
  payload: {},
});

// _____ DOWNLOAD_GEOMETRY_THREEJS_SAGA_ACTION _____

export const DOWNLOAD_GEOMETRY_THREEJS_SAGA_ACTION = 'DOWNLOAD_GEOMETRY_THREEJS_SAGA_ACTION';
export interface IDownloadGeometryThreejsSagaAction extends Action<'DOWNLOAD_GEOMETRY_THREEJS_SAGA_ACTION'> {}
export const downloadGeometryThreejsSagaAction = (): IDownloadGeometryThreejsSagaAction => ({
  type: DOWNLOAD_GEOMETRY_THREEJS_SAGA_ACTION,
});

export const UPDATE_THREEJS_ACTION = 'UPDATE_THREEJS_ACTION';
export interface IUpdateThreejsAction extends Action<'UPDATE_THREEJS_ACTION'> {
  payload: {
    threejs: object;
  };
}
export const updateThreejsAction = (threejs: object): IUpdateThreejsAction => ({
  type: UPDATE_THREEJS_ACTION,
  payload: {
    threejs,
  },
});

// _____ CLEAR_THREEJS_ACTION _____

export const CLEAR_THREEJS_ACTION = 'CLEAR_THREEJS_ACTION';
export interface IClearGeometryThreejsAction extends Action<'CLEAR_THREEJS_ACTION'> {}
export const clearGeometryThreejsAction = (): IClearGeometryThreejsAction => ({
  type: CLEAR_THREEJS_ACTION,
});

// _____ FETCH_GEOMETRIES_TEMPLATES_SAGA_ACTION _____

export const FETCH_GEOMETRIES_TEMPLATES_SAGA_ACTION = 'FETCH_GEOMETRIES_TEMPLATES_SAGA_ACTION';
export interface IFetchGeometriesTemplatesSagaAction extends Action<'FETCH_GEOMETRIES_TEMPLATES_SAGA_ACTION'> {}
export const fetchGeometriesTemplatesSagaAction = (): IFetchGeometriesTemplatesSagaAction => ({
  type: FETCH_GEOMETRIES_TEMPLATES_SAGA_ACTION,
});

// _____ UPDATE_GEOMETRIES_TEMPLATES_ACTION _____

export const UPDATE_GEOMETRIES_TEMPLATES_ACTION = 'UPDATE_GEOMETRIES_TEMPLATES_ACTION';
export interface IUpdateGeometriesTemplatesAction extends Action<'UPDATE_GEOMETRIES_TEMPLATES_ACTION'> {
  payload: {
    templates: IGeometriesTemplates;
  };
}
export const updateGeometriesTemplatesAction = (templates: IGeometriesTemplates): IUpdateGeometriesTemplatesAction => ({
  type: UPDATE_GEOMETRIES_TEMPLATES_ACTION,
  payload: {
    templates,
  },
});

export type IGeometryActions =
  | IUpdateThreejsAction
  | IUpdateGeometryImportProgressAction
  | IUpdateGeometriesTemplatesAction
  | IClearGeometryThreejsAction;
