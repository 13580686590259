import { WithProjectSubMenu } from 'components/Menus/WithProjectSubMenu';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { geometryMenuPages, getGeometryMenuPageRoute, projectMenuPages } from 'routing/routes';
import { $t } from 'utils/functions/translate';
import { IProps } from './WithGeometryMenu.container';

export class WithGeometryMenu extends PureComponent<IProps> {
  public render(): ReactNode {
    const { selectedPage, routeGeometry, children } = this.props;

    const pagesDivision =
      routeGeometry.format === 'floorspace'
        ? [[geometryMenuPages.drawing2D, geometryMenuPages.viewer3D]]
        : [[geometryMenuPages.viewer3D]];

    return (
      <WithProjectSubMenu
        selectedSubMenu={projectMenuPages.geometries}
        subMenuTitle={$t('geometry')}
        selectedPage={selectedPage}
        subMenuRouteResource={routeGeometry}
        subMenuPagesDivision={pagesDivision}
        getSubMenuPageRoute={getGeometryMenuPageRoute}
        isSubMenuCollapsed={true}
      >
        {children}
      </WithProjectSubMenu>
    );
  }
}
