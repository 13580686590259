import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { IMaterial } from 'types/Obat/Material';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { MaterialForm } from '../MaterialForm';

export class MaterialsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'materials'}
        resourceClass={orc.material}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={MaterialForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 4 },
    { id: 'conductivity', resourceField: 'nominal_conductivity', title: $t('conductivityWithUnit'), gridItemSize: 3 },
    { id: 'density', resourceField: 'density', title: $t('densityWithUnit'), gridItemSize: 2 },
    { id: 'specificHeat', resourceField: 'specific_heat', title: $t('specificHeatWithUnit'), gridItemSize: 3 },
  ];

  private renderCardColumnData = (material: IMaterial, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return material.ref;
      case 'conductivity':
        return renderTextValueElseDash(material.nominal_conductivity);
      case 'density':
        return renderTextValueElseDash(material.density);
      case 'specificHeat':
        return renderTextValueElseDash(material.specific_heat);
    }
  };
}
