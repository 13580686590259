import { _get } from 'libs/lodash';

export interface ICsvParams {
  csv_decimal: string;
  csv_separator: string;
}

export const getCsvParams = (language: string): ICsvParams => {
  const csvDecimal = {
    en: '.',
    fr: ',',
  };

  const csvSeparator = {
    en: ',',
    fr: ';',
  };

  return {
    csv_decimal: _get(csvDecimal, language),
    csv_separator: _get(csvSeparator, language),
  };
};
