import { connect } from 'react-redux';
import { closeFormDrawerAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import { fetchProjectionDataSagaAction } from 'redux/restResources/detail/obat/actions';
import {
  selectIsProjectionTaskRunning,
  selectObatGeometriesOptions,
  selectProjectionParams,
  selectProjectionSection,
} from 'redux/restResources/detail/obat/selectors';
import { selectVariantContextId } from 'redux/variantContext/selectors';

import { ProjectionForm as ProjectionFormComponent } from './ProjectionForm.component';

// tslint:disable-next-line
interface IOwnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export interface IProps extends IInjectedProps, IOwnProps {}

const mapStateToProps = (rootState: IRootState, _ownProps: IOwnProps) => {
  return {
    geometriesOptions: selectObatGeometriesOptions(rootState),
    projectionSection: selectProjectionSection(rootState),
    projectionParams: selectProjectionParams(rootState),
    variantContextId: selectVariantContextId(rootState),
    isProjectionTaskRunning: selectIsProjectionTaskRunning(rootState),
  };
};

const mapDispatchToProps = {
  fetchProjectionData: fetchProjectionDataSagaAction,
  closeFormDrawer: closeFormDrawerAction,
};

export const ProjectionForm = connect(mapStateToProps, mapDispatchToProps)(ProjectionFormComponent);
