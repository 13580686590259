import { IAvailableActions } from 'components/Datatable/DatatableActions/DatatableActions.container';
import { _size } from 'libs/lodash';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { selectFormDrawerWidth } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { selectMyMemberOrganizationOptions } from 'redux/restResources/detail/me/selectors';
import {
  selectIsAdminPermissionMissing,
  selectIsSeatMissing,
  selectIsWritePermissionMissing,
} from 'redux/routing/selectors';
import { selectTableResources } from 'redux/table/selectors';
import { IRestResource } from 'types/RestResource';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { fk } from 'utils/strings/formKeys';
import { gra } from 'utils/strings/requestActions';
import { IRestResourceClass } from 'utils/strings/resourceClasses';

import { IRoutingMenu } from 'utils/strings/routingMenus';
import { RestResourcesListPage as Component } from './RestResourcesListPage.component';

interface IOwnProps {
  defaultSort?: { columnId: string; order: 'asc' | 'desc' };
  routingMenu: IRoutingMenu;
  routeParentId?: string;
  selectedPage: string;
  selectedPageTitle?: string;
  FormComponent?: ReactNode;
  getCreationSuccessLandingPageUrl?: (routeProjectId: string, createdResource: IRestResource) => string;
  resourceClass: IRestResourceClass;
  parentResourceClass?: IRestResourceClass;
  columnsParams: ITableColumnParams[];
  availableActions?: IAvailableActions;
  createButtonText?: string;
  onTopBarButtonClick?: () => void;
  isCreationHidden?: boolean;
  isCreationDisabled?: boolean;
  creationTooltipContent?: string;
  renderAlert?: () => ReactNode;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  const { resourceClass, routeParentId } = ownProps;

  const tableResources = selectTableResources(rootState)(resourceClass, routeParentId);
  const isDataEmpty = !!tableResources && _size(tableResources.full) === 0;

  return {
    isDataEmpty,
    isLoading: selectIsApiRequestPending(getGenericApiRequestKey(gra.list, resourceClass))(rootState),
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
    isAdminPermissionMissing: selectIsAdminPermissionMissing(rootState),
    myMemberOrganizations: selectMyMemberOrganizationOptions(rootState),
    formDrawerWidth: selectFormDrawerWidth(rootState),
  };
};

const mapDispatchToProps = {
  openFormDrawer: openFormDrawerSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onTopBarButtonClick: ownProps.onTopBarButtonClick || (() => dispatchProps.openFormDrawer(fk.restResource)),
});

export const RestResourcesListPage = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
