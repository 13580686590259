import { getFormDrawer } from 'components/Forms/FormDrawer';
import { getRestResourceForm } from 'components/Forms/RestResourceForm';
import React, { Component, ReactNode } from 'react';
import { fk } from 'utils/strings/formKeys';
import { IRestResourceClass } from 'utils/strings/resourceClasses';

import { IProps } from './RestResourceFormDrawer.container';

export class RestResourceFormDrawer<IRestResourceFormValues, IRestResourceRequestParams> extends Component<
  IProps<IRestResourceFormValues, IRestResourceRequestParams>
> {
  public FormDrawer = getFormDrawer<IRestResourceClass>();
  public RestResourceForm = getRestResourceForm<IRestResourceFormValues, IRestResourceRequestParams>();

  public render(): ReactNode {
    const { props, FormDrawer, RestResourceForm } = this;
    const {
      formFocusRef,
      initialRestResource,
      resourceClass,
      getInitialFormValues,
      getRequestParams,
      getValidationSchema,
      renderSubForm,
      formDrawerWidth,
      forceSubmitDisabled,
      forceSubmitEnabled,
      renderForm,
      title,
      isCopy,
      getCreationSuccessLandingPageUrl,
    } = props;

    return (
      <FormDrawer
        formKey={isCopy ? fk.copy : fk.restResource}
        width={formDrawerWidth}
        title={title ? title : initialRestResource ? initialRestResource.name : undefined}
        formFocusRef={formFocusRef}
        resourceClass={resourceClass}
      >
        <RestResourceForm
          isCopy={!!isCopy}
          initialRestResource={initialRestResource}
          renderForm={renderForm}
          getInitialFormValues={getInitialFormValues}
          getRequestParams={getRequestParams}
          getValidationSchema={getValidationSchema}
          resourceClass={resourceClass}
          forceSubmitDisabled={forceSubmitDisabled}
          forceSubmitEnabled={forceSubmitEnabled}
          renderSubForm={renderSubForm}
          getCreationSuccessLandingPageUrl={getCreationSuccessLandingPageUrl}
        />
      </FormDrawer>
    );
  }
}
