import { Action } from 'redux';
import { IObatResource } from 'types/Obat/ObatResource';

export const OPEN_OBATCONTENT_COMMITMENTS_MODAL_ACTION = 'OPEN_OBATCONTENT_COMMITMENTS_MODAL_ACTION';
export interface IOpenObatResourceCommitmentsModalAction extends Action<'OPEN_OBATCONTENT_COMMITMENTS_MODAL_ACTION'> {
  payload: {
    obatResource: IObatResource;
    editionField?: string;
  };
}

export const openObatResourceCommitmentsModalAction = (
  obatResource: IObatResource,
  editionField?: string
): IOpenObatResourceCommitmentsModalAction => ({
  type: OPEN_OBATCONTENT_COMMITMENTS_MODAL_ACTION,
  payload: {
    obatResource,
    editionField,
  },
});

export const CLOSE_OBATCONTENT_COMMITMENTS_MODAL_ACTION = 'CLOSE_OBATCONTENT_COMMITMENTS_MODAL_ACTION';
export interface ICloseObatResourceCommitmentsModalAction
  extends Action<'CLOSE_OBATCONTENT_COMMITMENTS_MODAL_ACTION'> {}
export const closeObatResourceCommitmentsModalAction = (): ICloseObatResourceCommitmentsModalAction => ({
  type: CLOSE_OBATCONTENT_COMMITMENTS_MODAL_ACTION,
});

export type IObatResourceCommitmentsModalActions =
  | IOpenObatResourceCommitmentsModalAction
  | ICloseObatResourceCommitmentsModalAction;
