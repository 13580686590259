import {
  IAzureActions,
  TOGGLE_AZURE_DOWNLOADING_STATUS_ACTION,
  TOGGLE_UPLOADING_STATUS_ACTION,
  UPDATE_AZURE_ERROR_ACTION,
} from 'redux/azure/actions';

export interface IAzureState {
  isUploading: boolean;
  isDownloading: boolean;
  error: string | undefined;
}

export const initialState = {
  isUploading: false,
  isDownloading: false,
  error: undefined,
};

export const azureReducer = (state: IAzureState = initialState, action: IAzureActions): IAzureState => {
  switch (action.type) {
    case UPDATE_AZURE_ERROR_ACTION:
      const { error } = action.payload;

      return { ...state, error };

    case TOGGLE_UPLOADING_STATUS_ACTION:
      const { isUploading } = action.payload;

      return { ...state, isUploading, error: isUploading ? undefined : state.error };

    case TOGGLE_AZURE_DOWNLOADING_STATUS_ACTION:
      const { isDownloading } = action.payload;

      return { ...state, isDownloading, error: isDownloading ? undefined : state.error };

    default:
      return state;
  }
};
