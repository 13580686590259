// tslint:disable:no-any

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { BREADCRUMB_BAR_HEIGHT } from 'components/BreadcrumbTopBar';
import { StyledDivider, WithMenuDrawer } from 'components/Menus/WithMenuDrawer';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { envUrls } from 'environment';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { HelpCircle, Home, LogOut, User } from 'react-feather';
import { navigateRoute } from 'routing/navigateRoute';
import { getHomeMenuPageRoute, homeMenuPages, homeRoute } from 'routing/routes';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';

import { IProps } from './WithMenu.container';

export const MENU_EXPANDED_WIDTH = 250;
export const MENU_COLLAPSED_WIDTH = 60;

export interface IMenuItem {
  page: string;
  Icon: ReactElement<any>;
  isDividerAbove?: boolean;
}

export class WithMenu extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <WithMenuDrawer
        isCollapsed={!!this.props.isCollapsed}
        Menu={this.renderMenu()}
        expandedWidth={MENU_EXPANDED_WIDTH}
        collapsedWidth={MENU_COLLAPSED_WIDTH}
      >
        {this.props.children}
      </WithMenuDrawer>
    );
  }

  public renderMenuListItem = (
    itemTitle: string,
    Icon: ReactElement<any>,
    isSelected: boolean,
    showText: boolean,
    route?: string,
    handleClick?: () => void,
    height?: number
  ): ReactNode => {
    const onMouseDown = (e: React.MouseEvent): void => {
      if (route) {
        navigateRoute(route, e, route === envUrls.doc);
      }
      if (handleClick) {
        handleClick();
      }
    };

    return (
      <ListItem
        button
        key={itemTitle}
        selected={isSelected}
        onMouseDown={onMouseDown}
        onClick={handleClick}
        style={{ height: height ? height + 'px' : '' }}
      >
        {showText ? (
          <ListItemIcon>{Icon}</ListItemIcon>
        ) : (
          <SimpleTooltip content={itemTitle} placement={'bottom'}>
            <ListItemIcon>{Icon}</ListItemIcon>
          </SimpleTooltip>
        )}
        {showText && <ListItemText primary={itemTitle} />}
      </ListItem>
    );
  };

  private renderHome = (): ReactNode => {
    return this.renderMenuListItem(
      $t('home'),
      <Home />,
      false,
      !this.props.isCollapsed,
      homeRoute,
      undefined,
      BREADCRUMB_BAR_HEIGHT
    );
  };

  private renderMyAccount = (): ReactNode => {
    const { selectedPage, myFullName } = this.props;

    return this.renderMenuListItem(
      myFullName || $t('myAccount'),
      <User />,
      selectedPage === homeMenuPages.myAccount,
      !this.props.isCollapsed,
      getHomeMenuPageRoute(homeMenuPages.myAccount)
    );
  };

  private renderHelp = (): ReactNode => {
    return this.renderMenuListItem($t('help'), <HelpCircle />, false, !this.props.isCollapsed, envUrls.doc);
  };

  private renderLogout = (): ReactNode => {
    const { resetApp } = this.props;

    const handleClick = (): void => {
      navigateRoute(homeRoute); /** temporary dirty hack: set targetLocation after redirect to login to home */
      resetApp();
    };

    return this.renderMenuListItem($t('logout'), <LogOut />, false, !this.props.isCollapsed, undefined, handleClick);
  };

  private renderMenu = (): ReactNode => {
    const { routeResourceId, items, selectedPage, isCollapsed, getMenuPageRoute } = this.props;

    return (
      <DrawerContainer>
        <div>
          {routeResourceId ? (
            <HomeContainer component="nav">{this.renderHome()}</HomeContainer>
          ) : (
            <LogoContainer>
              <img src={process.env.PUBLIC_URL + '/images/logoOplus.png'} />
            </LogoContainer>
          )}
          <MenuContainer component="nav">
            {_map(
              items,
              (item: IMenuItem): ReactNode => {
                const { page, Icon, isDividerAbove } = item;

                const isSelected = page === selectedPage;
                const showText = !isCollapsed;
                const title = $t(page);
                const route = routeResourceId ? getMenuPageRoute(page, routeResourceId) : getMenuPageRoute(page, '');

                return (
                  <div key={page}>
                    {isDividerAbove && <StyledDivider variant="middle" />}
                    {this.renderMenuListItem(title, Icon, isSelected, showText, route)}
                  </div>
                );
              }
            )}
          </MenuContainer>
        </div>
        <List component="nav">
          {this.renderMyAccount()}
          {this.renderHelp()}
          {this.renderLogout()}
        </List>
      </DrawerContainer>
    );
  };
}

const DrawerContainer = styled.div`
  margin-top: -16px;
  height: calc(100vh - 16px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const HomeContainer = styled(List)`
  padding-bottom: 2px;
  margin-top: -9px;
` as typeof List;

const LogoContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  img {
    height: 100px;
  }
`;

const MenuContainer = styled(List)`
  margin-top: 150px;
` as typeof List;
