import { Grid } from '@material-ui/core';
import { Alert } from 'components/Alerts';
import { Button } from 'components/Buttons/Button.component';
import { BottomRightButtons, CenterButtons } from 'components/Buttons/ButtonsContainers';
import { Page } from 'components/Page';
import { MonoSimulationGroupConfigCard } from 'components/SimulationGroup/MonoSimulationGroupConfigCard';
import { ConfigForm } from 'pages/SimulationGroupMenu/Mono/Config/ConfigForm';
import React, { PureComponent, ReactNode } from 'react';
import { Edit3, Play } from 'react-feather';
import { monoSimulationGroupMenuPages } from 'routing/routes';
import { getRunTooltip } from 'utils/functions/simulationGroup/getRunTooltip';
import { isRunEnabled } from 'utils/functions/simulationGroup/isRunEnabled';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rrc } from 'utils/strings/resourceClasses';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './ConfigMono.container';

export class ConfigMono extends PureComponent<IProps> {
  public componentDidMount = (): void => {
    const { fetchSimulationGroupConfigOptions, refreshSimulationGroup } = this.props;

    refreshSimulationGroup(rrc.mono_simulation_group);
    fetchSimulationGroupConfigOptions();
  };

  public render(): ReactNode {
    const {
      routeMonoSimulationGroup,
      isWritePermissionMissing,
      isSeatMissing,
      openConfigFormDrawer,
      runSimulation,
      formDrawerWidth,
    } = this.props;

    return (
      <Page
        pageTitle={$t('config')}
        routingMenu={rm.monoSimulationGroup}
        selectedPage={monoSimulationGroupMenuPages.configMono}
        renderAlert={this.renderAlert}
      >
        {!routeMonoSimulationGroup.configured && formDrawerWidth === 0 && (
          <CenterButtons isPulsing>
            <Button
              Icon={<Edit3 size={12} />}
              text={$t('configure')}
              onClick={openConfigFormDrawer}
              isWritePermissionMissing={isWritePermissionMissing}
              isSeatMissing={isSeatMissing}
            />
          </CenterButtons>
        )}

        {routeMonoSimulationGroup.configured && (
          <>
            <Grid container>
              <Grid item xs={6}>
                <MonoSimulationGroupConfigCard />
              </Grid>
            </Grid>
            <BottomRightButtons>
              <Button
                Icon={<Play />}
                onClick={runSimulation}
                isWritePermissionMissing={isWritePermissionMissing}
                isSeatMissing={isSeatMissing}
                isDisabled={!isRunEnabled(routeMonoSimulationGroup)}
                tooltipProps={{ content: getRunTooltip(routeMonoSimulationGroup), placement: 'left' }}
              />
            </BottomRightButtons>
          </>
        )}
        <ConfigForm />
      </Page>
    );
  }

  private renderAlert = (): ReactNode => {
    const { routeMonoSimulationGroup } = this.props;

    if (routeMonoSimulationGroup.status === as.running) {
      return (
        <Alert status={as.info} opacity={0.8}>
          {$t('simulationGroupRunningConfigAlert')}
        </Alert>
      );
    }

    if (routeMonoSimulationGroup.obsolete_front) {
      return (
        <Alert status={as.warning} opacity={0.8}>
          {$t('simulationGroupObsoleteAlert')}
        </Alert>
      );
    }

    return null;
  };
}
