import { connect } from 'react-redux';
import { openAlertModalAction } from 'redux/modals/alertModal/actions';
import { IRootState } from 'redux/reducer';
import { updateGeometryImportProgressAction } from 'redux/restResources/detail/geometry/actions';
import { selectGeometryImportProgress } from 'redux/restResources/detail/geometry/selectors';
import { selectMyLanguage } from 'redux/restResources/detail/me/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { ThreejsViewer as Component } from './ThreejsViewer.component';

interface IOwnProps {
  threejsData: object;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    routeGeometry: selectRouteResource(rrc.geometry)(rootState),
    importProgress: selectGeometryImportProgress(rootState),
    userMeLanguage: selectMyLanguage(rootState),
  };
};

const mapDispatchToProps = {
  openAlertModal: openAlertModalAction,
  updateImportProgress: updateGeometryImportProgressAction,
};

export const ThreejsViewer = connect(mapStateToProps, mapDispatchToProps)(Component);
