import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { WriteInput } from 'components/Forms/Inputs/TextInputs/WriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { _includes, _omit } from 'libs/lodash';
import { getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IOpeningRequestParams } from 'types/Obat/Opening';
import { fdw } from 'utils/configs/drawerWidths';
import { calculateCorrectedValue } from 'utils/functions/calculateCorrectedValue';
import { convertFormValuesEmptyStringToNull } from 'utils/functions/convertFormValuesEmptyStringToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { withEmptyOption } from 'utils/functions/forms/withEmptyOption';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './OpeningForm.container';

export interface IOpeningFormValues {
  ref: string;
  nominal_conductance: number | '';
  conductance_ratio: number | '';
  surface_category: string;
  type: string;
  blind_angle_method: string;
  blind_angle_value: number | '';
  blind_position: string;
  blind_control_availability: string;
  blind_control_method: string;
  blind_control_value: number | '';
  blind_reflectance: number | '';
  blind_transmittance: number | '';
  blind_type: string;
  shading_fin_ratio: number | '';
  shading_overhang_ratio: number | '';
  solar_heat_gain: number | '';
  visible_transmittance: number | '';
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IOpeningFormValues, IOpeningRequestParams>();

export class OpeningForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.opening}
        getRequestParams={this.getRequestParams}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IOpeningFormValues>): ReactNode => {
      const {
        blindPositionOptions,
        surfaceCategoryOptions,
        blindTypeOptions,
        openingToEdit,
        selectSchedulesByTypeOptions,
        openPlotsModal,
      } = this.props;
      const {
        surface_category,
        nominal_conductance,
        conductance_ratio,
        blind_type,
        blind_control_method,
        blind_angle_method,
        blind_control_availability,
      } = formikProps.values;

      const onPlotsModalIconClick = (scheduleRef: string) => {
        return (): void => {
          openPlotsModal(scheduleRef, orc.schedule);
        };
      };

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'surface_category'}
                formikProps={formikProps}
                options={surfaceCategoryOptions}
                required
                renderOptionLabel={$tEnums}
                obatResourceToBeCheckedForCommitment={openingToEdit}
              />
            </Grid>
          </Grid>
          <Section title={$t('opening')} marginTop={20}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'nominal_conductance'}
                  formikProps={formikProps}
                  required
                  label={$t('nominalConductanceWithUnit')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput field={'conductance_ratio'} formikProps={formikProps} required />
              </Grid>
              <Grid item xs={12}>
                <WriteInput
                  field={'conductance'}
                  label={$t('conductanceWithUnit')}
                  value={calculateCorrectedValue(nominal_conductance, conductance_ratio)}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput field={'solar_heat_gain'} formikProps={formikProps} required />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput field={'visible_transmittance'} formikProps={formikProps} required />
              </Grid>
            </Grid>
          </Section>
          {this.isExteriorSurface(surface_category) && (
            <>
              <Section title={$t('blind')} marginTop={20}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormikDropdownInput
                      field={'blind_type'}
                      formikProps={formikProps}
                      options={withEmptyOption(blindTypeOptions)}
                      label={$t('type')}
                      renderOptionLabel={$tEnums}
                    />
                  </Grid>
                  {this.isBlindTypeSelected(blind_type) && (
                    <>
                      <Grid item xs={12}>
                        <FormikDropdownInput
                          field={'blind_position'}
                          formikProps={formikProps}
                          options={blindPositionOptions}
                          label={$t('position')}
                          renderOptionLabel={$tEnums}
                          required
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikDropdownInput
                          field={'blind_control_method'}
                          formikProps={formikProps}
                          options={this.getBlindControlMethodOptions(blind_type)}
                          onSelect={this.onBlindControlMethodSelect(formikProps)}
                          label={$t('controlMethod')}
                          renderOptionLabel={$tEnums}
                          required
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'blind_control_value'}
                          formikProps={formikProps}
                          label={`${$t('controlValue')} ${this.getControlValueUnit(blind_control_method)}`}
                          disabled={_includes(['', 'always_on', 'always_off'], blind_control_method)}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={1} alignItems={'center'}>
                          <Grid item xs={11}>
                            <FormikDropdownInput
                              field={'blind_control_availability'}
                              label={$t('availabilitySchedule')}
                              formikProps={formikProps}
                              options={withEmptyOption(selectSchedulesByTypeOptions('control'))}
                            />
                          </Grid>
                          {!!blind_control_availability && (
                            <Grid item xs={1}>
                              <IconButton
                                size={16}
                                action={'seeDetails'}
                                onClick={onPlotsModalIconClick(blind_control_availability)}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {this.isBlindTypeVertical(blind_type) && (
                    <>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'blind_reflectance'}
                          formikProps={formikProps}
                          label={$t('reflectance')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'blind_transmittance'}
                          formikProps={formikProps}
                          label={$t('transmittance')}
                        />
                      </Grid>
                    </>
                  )}

                  {this.isBlindTypeVerticalBlinds(blind_type) && (
                    <>
                      <Grid item xs={6}>
                        <FormikDropdownInput
                          field={'blind_angle_method'}
                          formikProps={formikProps}
                          options={this.getBlindAngleMethodOptions(blind_type)}
                          onSelect={this.onBlindAngleMethodSelect(formikProps)}
                          label={$t('angleMethod')}
                          renderOptionLabel={$tEnums}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikWriteInput
                          field={'blind_angle_value'}
                          formikProps={formikProps}
                          label={$t('angleValue')}
                          disabled={blind_angle_method !== 'fixed'}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Section>
              <Section title={$t('shading')} marginTop={20}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormikWriteInput
                      field={'shading_overhang_ratio'}
                      formikProps={formikProps}
                      label={$t('overhangRatio')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikWriteInput field={'shading_fin_ratio'} formikProps={formikProps} label={$t('finRatio')} />
                  </Grid>
                </Grid>
              </Section>
            </>
          )}
        </>
      );
    };
  };

  private getBlindControlMethodOptions = (blindType: string): string[] => {
    const { blindControlMethodOptions } = this.props;

    return blindType === 'vertical_blinds' ? blindControlMethodOptions : withEmptyOption(blindControlMethodOptions);
  };

  private getBlindAngleMethodOptions = (blindType: string): string[] => {
    const { blindAngleMethodOptions } = this.props;

    return blindType === 'vertical_blinds' ? blindAngleMethodOptions : withEmptyOption(blindAngleMethodOptions);
  };

  private getControlValueUnit = (blindControlMethod: string): string =>
    blindControlMethod === 'on_night_if_low_outdoor_temperature_and_on_day_if_cooling'
      ? 'W/m2'
      : blindControlMethod === 'on_if_high_outdoor_air_temperature_and_high_solar_on_window'
      ? '°C'
      : '';

  private isExteriorSurface = (surfaceCategory: string): boolean =>
    !_includes(['wall_interior', 'floor_interior', 'roof_interior'], surfaceCategory);

  private isBlindTypeSelected = (blindType: string): boolean => !!blindType;

  private isBlindTypeVertical = (blindType: string): boolean =>
    _includes(['vertical_shades', 'vertical_blinds'], blindType);

  private isBlindTypeVerticalBlinds = (blindType: string): boolean => blindType === 'vertical_blinds';

  private getInitialFormValues = (): IOpeningFormValues => {
    const { openingToEdit } = this.props;

    return {
      ref: setFormInitialValue(openingToEdit, 'ref'),
      type: 'window',
      nominal_conductance: setFormInitialValue(openingToEdit, 'nominal_conductance'),
      conductance_ratio: setFormInitialValue(openingToEdit, 'conductance_ratio', 1),
      visible_transmittance: setFormInitialValue(openingToEdit, 'visible_transmittance'),
      solar_heat_gain: setFormInitialValue(openingToEdit, 'solar_heat_gain'),
      shading_fin_ratio: setFormInitialValue(openingToEdit, 'shading_fin_ratio'),
      shading_overhang_ratio: setFormInitialValue(openingToEdit, 'shading_overhang_ratio'),
      surface_category: setFormInitialValue(openingToEdit, 'surface_category'),
      blind_type: setFormInitialValue(openingToEdit, 'blind_type'),
      blind_angle_method: setFormInitialValue(openingToEdit, 'blind_angle_method'),
      blind_angle_value: setFormInitialValue(openingToEdit, 'blind_angle_value'),
      blind_control_method: setFormInitialValue(openingToEdit, 'blind_control_method'),
      blind_control_value: setFormInitialValue(openingToEdit, 'blind_control_value'),
      blind_position: setFormInitialValue(openingToEdit, 'blind_position'),
      blind_control_availability: setFormInitialValue(openingToEdit, 'blind_control_availability'),
      blind_reflectance: setFormInitialValue(openingToEdit, 'blind_reflectance', 0.65),
      blind_transmittance: setFormInitialValue(openingToEdit, 'blind_transmittance', 0.2),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IOpeningFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      nominal_conductance: getYupNumberValidation(true, 0, 6),
      conductance_ratio: getYupNumberValidation(true, 0),
      surface_category: getYupStringValidation(true),
      type: getYupStringValidation(true),
      blind_type: getYupStringValidation(),
      blind_angle_method: getYupStringValidation(),
      blind_angle_value: getYupNumberValidation(false, 0, 180),
      blind_position: yup.string().when(['blind_type'], {
        is: this.isBlindTypeSelected,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
      blind_control_method: yup.string().when(['blind_type'], {
        is: this.isBlindTypeSelected,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
      blind_control_value: getYupNumberValidation(),
      blind_control_availability: getYupStringValidation(),
      blind_reflectance: getYupNumberValidation(false, 0, 1).test({
        test(): boolean {
          return yupTestRatios(this.parent);
        },
        message: 'yupTestSumOfRatios',
      }),
      blind_transmittance: getYupNumberValidation(false, 0, 1).test({
        test(): boolean {
          return yupTestRatios(this.parent);
        },
        message: 'yupTestSumOfRatios',
      }),
      shading_fin_ratio: getYupNumberValidation(false, 0),
      shading_overhang_ratio: getYupNumberValidation(false, 0),
      solar_heat_gain: getYupNumberValidation(true, 0, 1, true, false),
      visible_transmittance: getYupNumberValidation(true, 0, 1),
    }) as yup.ObjectSchema<IOpeningFormValues>;
  };

  /* TODO : backend should take care of that! */
  private getRequestParams = (formValues: IOpeningFormValues): IOpeningRequestParams => {
    const { openingToEdit } = this.props;

    const initialFormValues = this.getInitialFormValues();
    const filteredFormValues = openingToEdit ? filterEditedValues(initialFormValues, formValues) : formValues;

    const requestParamsFields = {
      ...filteredFormValues,
      blind_type: this.isExteriorSurface(formValues.surface_category) ? filteredFormValues.blind_type : '',
      blind_control_method: this.isBlindTypeSelected(formValues.blind_type)
        ? filteredFormValues.blind_control_method
        : '',
      blind_control_value: this.isBlindTypeSelected(formValues.blind_type)
        ? filteredFormValues.blind_control_value
        : '',
      blind_position: this.isBlindTypeSelected(formValues.blind_type) ? filteredFormValues.blind_position : '',
      blind_control_availability: this.isBlindTypeSelected(formValues.blind_type)
        ? filteredFormValues.blind_control_availability
        : '',
      blind_reflectance: this.isBlindTypeVertical(formValues.blind_type) ? filteredFormValues.blind_reflectance : '',
      blind_transmittance: this.isBlindTypeVertical(formValues.blind_type)
        ? filteredFormValues.blind_transmittance
        : '',
      blind_angle_method: this.isBlindTypeVerticalBlinds(formValues.blind_type)
        ? filteredFormValues.blind_angle_method
        : '',
      blind_angle_value: this.isBlindTypeVerticalBlinds(formValues.blind_type)
        ? filteredFormValues.blind_angle_value
        : '',
      shading_fin_ratio: this.isExteriorSurface(formValues.surface_category)
        ? filteredFormValues.shading_fin_ratio
        : '',
      shading_overhang_ratio: this.isExteriorSurface(formValues.surface_category)
        ? filteredFormValues.shading_overhang_ratio
        : '',
    };

    return convertFormValuesEmptyStringToNull(requestParamsFields);
  };

  private onBlindAngleMethodSelect = (formikProps: FormikProps<IOpeningFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      formikProps.setValues({
        ...formikProps.values,
        blind_angle_method: selectedValue,
        blind_angle_value: '',
      });
    };
  };

  private onBlindControlMethodSelect = (formikProps: FormikProps<IOpeningFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      formikProps.setValues({
        ...formikProps.values,
        blind_control_method: selectedValue,
        blind_control_value: '',
      });
    };
  };
}

const yupTestRatios = (formValues: IOpeningFormValues): boolean => {
  const { blind_reflectance, blind_transmittance } = formValues;

  return !blind_reflectance || !blind_transmittance || blind_reflectance + blind_transmittance <= 1;
};
