import { Action } from 'redux';

export const OPEN_LOGS_MODAL_SAGA_ACTION = 'OPEN_LOGS_MODAL_SAGA_ACTION';
export interface IOpenLogsModalSagaAction extends Action<'OPEN_LOGS_MODAL_SAGA_ACTION'> {
  payload: {
    simulationId: string;
  };
}

export const openLogsModalSagaAction = (simulationId: string): IOpenLogsModalSagaAction => ({
  type: OPEN_LOGS_MODAL_SAGA_ACTION,
  payload: {
    simulationId,
  },
});

export const UPDATE_LOGS_MODAL_SIMULATION_ACTION = 'UPDATE_LOGS_MODAL_SIMULATION_ACTION';
export interface IUpdateLogsModalSimulationAction extends Action<'UPDATE_LOGS_MODAL_SIMULATION_ACTION'> {
  payload: {
    simulationId?: string;
  };
}

export const updateLogsModalSimulationAction = (simulationId?: string): IUpdateLogsModalSimulationAction => ({
  type: UPDATE_LOGS_MODAL_SIMULATION_ACTION,
  payload: {
    simulationId,
  },
});

export const CLOSE_LOGS_MODAL_SAGA_ACTION = 'CLOSE_LOGS_MODAL_SAGA_ACTION';
export interface ICloseLogsModalSagaAction extends Action<'CLOSE_LOGS_MODAL_SAGA_ACTION'> {}
export const closeLogsModalSagaAction = (): ICloseLogsModalSagaAction => ({
  type: CLOSE_LOGS_MODAL_SAGA_ACTION,
});

export type ILogsModalActions = IUpdateLogsModalSimulationAction | ICloseLogsModalSagaAction;
