import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import * as yup from 'yup';

import { IProps } from './ExportGbxmlForm.container';

interface IExportGbxmlFormValues {
  geometry: string;
}

const FormDrawer = getFormDrawer();
const Form = getForm<IExportGbxmlFormValues>();

export class ExportGbxmlForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <FormDrawer formKey={fk.exportGbxml} width={fdw.small} title={$t('exportGbxml')} formFocusRef={this.formFocusRef}>
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          isSubmitting={false}
          onSubmit={this.onSubmit}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IExportGbxmlFormValues>): ReactNode => {
      const { geometriesOptions } = this.props;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'geometry'}
              formikProps={formikProps}
              options={geometriesOptions}
              required
              inputRef={this.formFocusRef}
            />
          </Grid>
        </Grid>
      );
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IExportGbxmlFormValues> => {
    return yup.object().shape({
      geometry: getYupStringValidation(true),
    }) as yup.ObjectSchema<IExportGbxmlFormValues>;
  };

  private onSubmit = (formValues: IExportGbxmlFormValues): void => {
    const { closeFormDrawer, exportGbxml } = this.props;
    const exportParams = { ...formValues };
    exportGbxml(exportParams);
    closeFormDrawer();
  };

  private getInitialFormValues = (): IExportGbxmlFormValues => {
    return {
      geometry: '',
    };
  };
}
