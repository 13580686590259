// tslint:disable:no-any
import {
  DropdownInput,
  IDropdownInputProps,
} from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput/DropdownInput.component';
import React, { PureComponent, ReactNode } from 'react';
import { displayFormFieldError } from 'utils/functions/forms/displayFormFieldError/displayFormFieldError';

interface IProps extends Pick<IDropdownInputProps, Exclude<keyof IDropdownInputProps, 'value' | 'onSelect'>> {
  formikProps: any;
  onSelect?: IDropdownInputProps['onSelect'];
}

export class FormikDropdownInput extends PureComponent<IProps> {
  public render(): ReactNode {
    const { formikProps, field, onSelect, ...dropdownInputProps } = this.props;

    return (
      <DropdownInput
        {...dropdownInputProps}
        field={field}
        value={formikProps.values[field]}
        errorText={displayFormFieldError(field, formikProps)}
        onBlur={this.handleBlur}
        onSelect={onSelect || formikProps.setFieldValue}
      />
    );
  }

  private handleBlur = (_e: React.FocusEvent) => {
    const { formikProps, field } = this.props;

    /* With formikProps.handleBlur, the field is not set to touched. */
    formikProps.setFieldTouched(field);
  };
}
