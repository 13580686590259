// tslint:disable:no-any
import { Action } from 'redux';
import { IProjectionParams, IProjectionSection } from 'redux/restResources/detail/obat/reducer';
import { IGeometryListed } from 'types/Geometry/Geometry';
import { IObatsTemplates } from 'types/Obat/Obat';
import { IObatImportFields } from 'types/Obat/Obat';
import { IProgressStatus } from 'utils/strings/progressStatus';

// _____ IMPORT_OBAT_SAGA_ACTION _____

export const IMPORT_OBAT_SAGA_ACTION = 'IMPORT_OBAT_SAGA_ACTION';
export interface IImportObatSagaAction extends Action<'IMPORT_OBAT_SAGA_ACTION'> {
  payload: {
    importFields: IObatImportFields;
    fileContentBlob: Blob;
  };
}
export const importObatSagaAction = (
  importFields: IObatImportFields,
  fileContentBlob: Blob
): IImportObatSagaAction => ({
  type: IMPORT_OBAT_SAGA_ACTION,
  payload: {
    importFields,
    fileContentBlob,
  },
});

// _____ UPDATE_OBAT_IMPORT_PROGRESS_ACTION _____

export const UPDATE_OBAT_IMPORT_PROGRESS_ACTION = 'UPDATE_OBAT_IMPORT_PROGRESS_ACTION';
export interface IUpdateObatImportProgressAction extends Action<'UPDATE_OBAT_IMPORT_PROGRESS_ACTION'> {
  payload: {
    progress: IProgressStatus;
  };
}
export const updateObatImportProgressAction = (progress: IProgressStatus): IUpdateObatImportProgressAction => ({
  type: UPDATE_OBAT_IMPORT_PROGRESS_ACTION,
  payload: {
    progress,
  },
});

// _____ REFRESH_IMPORT_OBAT_TASK_SAGA_ACTION _____

export const REFRESH_IMPORT_OBAT_TASK_SAGA_ACTION = 'REFRESH_IMPORT_OBAT_TASK_SAGA_ACTION';
export interface IRefreshImportObatTaskSagaAction extends Action<'REFRESH_IMPORT_OBAT_TASK_SAGA_ACTION'> {}
export const refreshImportObatTaskSagaAction = (): IRefreshImportObatTaskSagaAction => ({
  type: REFRESH_IMPORT_OBAT_TASK_SAGA_ACTION,
});

// _____ EXPORT_OBAT_FILE_SAGA_ACTION _____

export const EXPORT_OBAT_FILE_SAGA_ACTION = 'EXPORT_OBAT_FILE_SAGA_ACTION';
export interface IExportObatFileSagaAction extends Action<'EXPORT_OBAT_FILE_SAGA_ACTION'> {}
export const exportObatFileSagaAction = (): IExportObatFileSagaAction => ({
  type: EXPORT_OBAT_FILE_SAGA_ACTION,
});

// _____ EXPORT_GBXML_FILE_SAGA_ACTION _____

export const EXPORT_GBXML_FILE_SAGA_ACTION = 'EXPORT_GBXML_FILE_SAGA_ACTION';
export interface IExportGbxmlFileSagaAction extends Action<'EXPORT_GBXML_FILE_SAGA_ACTION'> {
  payload: {
    exportParams: { geometry: string };
  };
}
export const exportGbxmlFileSagaAction = (exportParams: { geometry: string }): IExportGbxmlFileSagaAction => ({
  type: EXPORT_GBXML_FILE_SAGA_ACTION,
  payload: {
    exportParams,
  },
});

// _____ CLEAR_OBAT_ACTION _____

export const CLEAR_OBAT_ACTION = 'CLEAR_OBAT_ACTION';
export interface IClearObatAction extends Action<'CLEAR_OBAT_ACTION'> {}
export const clearObatAction = (): IClearObatAction => ({
  type: CLEAR_OBAT_ACTION,
});

// _____ FETCH_PROJECTION_DATA_SAGA_ACTION _____

export const FETCH_PROJECTION_DATA_SAGA_ACTION = 'FETCH_PROJECTION_DATA_SAGA_ACTION';
export interface IFetchProjectionDataSagaAction extends Action<'FETCH_PROJECTION_DATA_SAGA_ACTION'> {
  payload: {
    projectionParams: IProjectionParams;
  };
}
export const fetchProjectionDataSagaAction = (projectionParams: IProjectionParams): IFetchProjectionDataSagaAction => ({
  type: FETCH_PROJECTION_DATA_SAGA_ACTION,
  payload: {
    projectionParams,
  },
});

// _____ UPDATE_PROJECTION_DATA_ACTION _____

export interface IProjectionData {
  table: {
    [projectionSection: string]: object[];
  };
  threejs: object;
}

export const UPDATE_PROJECTION_DATA_ACTION = 'UPDATE_PROJECTION_DATA_ACTION';
export interface IUpdateProjectionDataAction extends Action<'UPDATE_PROJECTION_DATA_ACTION'> {
  payload: {
    data: IProjectionData;
  };
}
export const updateProjectionDataAction = (data: IProjectionData): IUpdateProjectionDataAction => ({
  type: UPDATE_PROJECTION_DATA_ACTION,
  payload: {
    data,
  },
});

// _____ FETCH_OBAT_GEOMETRIES_SAGA_ACTION _____

export const FETCH_OBAT_GEOMETRIES_SAGA_ACTION = 'FETCH_OBAT_GEOMETRIES_SAGA_ACTION';
export interface IFetchObatGeometriesSagaAction extends Action<'FETCH_OBAT_GEOMETRIES_SAGA_ACTION'> {}
export const fetchObatGeometriesSagaAction = (): IFetchObatGeometriesSagaAction => ({
  type: FETCH_OBAT_GEOMETRIES_SAGA_ACTION,
});

// _____ UPDATE_GEOMETRIES_FOR_PROJECTION_ACTION _____

export const UPDATE_GEOMETRIES_FOR_PROJECTION_ACTION = 'UPDATE_GEOMETRIES_FOR_PROJECTION_ACTION';
export interface IUpdateGeometriesForProjectionAction extends Action<'UPDATE_GEOMETRIES_FOR_PROJECTION_ACTION'> {
  payload: {
    geometries: IGeometryListed[];
  };
}
export const updateGeometriesForProjectionAction = (
  geometries: IGeometryListed[]
): IUpdateGeometriesForProjectionAction => ({
  type: UPDATE_GEOMETRIES_FOR_PROJECTION_ACTION,
  payload: {
    geometries,
  },
});

// _____ TOGGLE_PROJECTION_TASK_RUNNING_STATUS_ACTION _____

export const TOGGLE_PROJECTION_TASK_RUNNING_STATUS_ACTION = 'TOGGLE_PROJECTION_TASK_RUNNING_STATUS_ACTION';
export const toggleProjectionTaskRunningStatusAction = (
  isTaskRunning: boolean
): IToggleProjectionTaskRunningStatusAction => ({
  type: TOGGLE_PROJECTION_TASK_RUNNING_STATUS_ACTION,
  payload: {
    isTaskRunning,
  },
});
export interface IToggleProjectionTaskRunningStatusAction
  extends Action<'TOGGLE_PROJECTION_TASK_RUNNING_STATUS_ACTION'> {
  payload: {
    isTaskRunning: boolean;
  };
}

// _____ CLEAR_PROJECTION_DATA_ACTION _____

export const CLEAR_PROJECTION_DATA_ACTION = 'CLEAR_PROJECTION_DATA_ACTION';
export interface IClearProjectionDataAction extends Action<'CLEAR_PROJECTION_DATA_ACTION'> {}
export const clearProjectionDataAction = (): IClearProjectionDataAction => ({
  type: CLEAR_PROJECTION_DATA_ACTION,
});

// _____ UPDATE_PROJECTION_TABLE_SECTION_ACTION _____

export const UPDATE_PROJECTION_TABLE_SECTION_ACTION = 'UPDATE_PROJECTION_TABLE_SECTION_ACTION';
export interface IUpdateProjectionTableSectionAction extends Action<'UPDATE_PROJECTION_TABLE_SECTION_ACTION'> {
  payload: {
    section: IProjectionSection;
  };
}
export const updateProjectionTableSectionAction = (
  section: IProjectionSection
): IUpdateProjectionTableSectionAction => ({
  type: UPDATE_PROJECTION_TABLE_SECTION_ACTION,
  payload: {
    section,
  },
});

// _____ UPDATE_PROJECTION_PARAMS_ACTION _____

export const UPDATE_PROJECTION_PARAMS_ACTION = 'UPDATE_PROJECTION_PARAMS_ACTION';
export interface IUpdateProjectionParamsAction extends Action<'UPDATE_PROJECTION_PARAMS_ACTION'> {
  payload: {
    params: IProjectionParams;
  };
}
export const updateProjectionParamsAction = (params: IProjectionParams): IUpdateProjectionParamsAction => ({
  type: UPDATE_PROJECTION_PARAMS_ACTION,
  payload: {
    params,
  },
});

// _____ CLEAR_PROJECTION_PARAMS_ACTION _____

export const CLEAR_PROJECTION_PARAMS_ACTION = 'CLEAR_PROJECTION_PARAMS_ACTION';
export interface IClearProjectionParamsAction extends Action<'CLEAR_PROJECTION_PARAMS_ACTION'> {}
export const clearProjectionParamsAction = (): IClearProjectionParamsAction => ({
  type: CLEAR_PROJECTION_PARAMS_ACTION,
});

// _____ SWITCH_PROJECTION_RESULTS_VIEW _____

export const SWITCH_PROJECTION_RESULTS_VIEW = 'SWITCH_PROJECTION_RESULTS_VIEW';
export interface ISwitchProjectionResultsViewAction extends Action<'SWITCH_PROJECTION_RESULTS_VIEW'> {
  payload: {
    resultsView: string;
  };
}
export const switchProjectionResultsViewAction = (resultsView: string): ISwitchProjectionResultsViewAction => ({
  type: SWITCH_PROJECTION_RESULTS_VIEW,
  payload: {
    resultsView,
  },
});

// _____ FETCH_OBATS_TEMPLATES_SAGA_ACTION _____

export const FETCH_OBATS_TEMPLATES_SAGA_ACTION = 'FETCH_OBATS_TEMPLATES_SAGA_ACTION';
export interface IFetchObatsTemplatesSagaAction extends Action<'FETCH_OBATS_TEMPLATES_SAGA_ACTION'> {}
export const fetchObatsTemplatesSagaAction = (): IFetchObatsTemplatesSagaAction => ({
  type: FETCH_OBATS_TEMPLATES_SAGA_ACTION,
});

// _____ UPDATE_OBATS_TEMPLATES_ACTION _____

export const UPDATE_OBATS_TEMPLATES_ACTION = 'UPDATE_OBATS_TEMPLATES_ACTION';
export interface IUpdateObatsTemplatesAction extends Action<'UPDATE_OBATS_TEMPLATES_ACTION'> {
  payload: {
    templates: IObatsTemplates;
  };
}
export const updateObatsTemplatesAction = (templates: IObatsTemplates): IUpdateObatsTemplatesAction => ({
  type: UPDATE_OBATS_TEMPLATES_ACTION,
  payload: {
    templates,
  },
});

export type IObatActions =
  | IUpdateObatImportProgressAction
  | IUpdateProjectionDataAction
  | IUpdateGeometriesForProjectionAction
  | IClearProjectionDataAction
  | IToggleProjectionTaskRunningStatusAction
  | IUpdateProjectionTableSectionAction
  | IUpdateProjectionParamsAction
  | IClearProjectionParamsAction
  | ISwitchProjectionResultsViewAction
  | IUpdateObatsTemplatesAction;
