import { ITokens } from 'types/Tokens';

import { apiEndpoints } from '../endPoints';

export const TokenRequests = {
  fetchTokens: async (email: string, password: string): Promise<ITokens> =>
    // tslint:disable-next-line
    apiEndpoints
      .token()
      .post({ email, password })
      .json(),

  refreshTokens: async (refreshToken: ITokens['refresh']): Promise<ITokens> =>
    // tslint:disable-next-line
    apiEndpoints
      .token()
      .url('refresh/')
      .post({ refresh: refreshToken })
      .json(),
};
