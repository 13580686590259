import { connect } from 'react-redux';
import { selectScheduleSpecificDefaultValuesOptionsFunction } from 'redux/enums/selectors';
import { openPlotsModalAction } from 'redux/modals/plotsModal/actions';
import { IRootState } from 'redux/reducer';
import { ISchedule } from 'types/Obat/Schedule';

import { ScheduleDetail as ScheduleDetailComponent } from './ScheduleDetail.component';

interface IOwnProps {
  schedule: ISchedule;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => ({
  selectSpecificDefaultValues: selectScheduleSpecificDefaultValuesOptionsFunction(rootState),
});

const mapDispatchToProps = {
  openPlotsModal: openPlotsModalAction,
};

export const ScheduleDetail = connect(mapStateToProps, mapDispatchToProps)(ScheduleDetailComponent);
