import theme from 'style/theme';

export const getDayGroupColor = (num: number) => {
  if (num === -2) {
    return theme.colors.transparent;
  } else if (num === -1) {
    return theme.colors.empty;
  } else {
    return theme.colors.plot.obatColorway[num];
  }
};
