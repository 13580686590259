import React, { Component, ReactNode } from 'react';
import { IRestResource } from 'types/RestResource';
import { IRestResourceClass } from 'utils/strings/resourceClasses';

import { getResourceForm } from '../ResourceForm';

import { IProps } from './RestResourceForm.container';

export class RestResourceForm<IRestResourceFormValues, IRestResourceRequestParams> extends Component<
  IProps<IRestResourceFormValues, IRestResourceRequestParams>
> {
  public ResourceForm = getResourceForm<IRestResourceFormValues, IRestResourceRequestParams, IRestResourceClass>();

  public render(): ReactNode {
    const { ResourceForm, props } = this;
    const { initialRestResource } = props;

    return (
      <ResourceForm
        {...props}
        initialResource={initialRestResource}
        saveResource={this.saveResource}
        isObatResource={false}
      />
    );
  }

  private saveResource = (
    resourceClass: IRestResourceClass,
    initialRestResource: IRestResource,
    requestParams: IRestResourceRequestParams
  ): void => {
    const {
      editRestResource,
      createRestResource,
      copyRestResource,
      getCreationSuccessLandingPageUrl,
      isCopy,
    } = this.props;

    if (initialRestResource) {
      if (isCopy) {
        copyRestResource(resourceClass, initialRestResource.id, requestParams, getCreationSuccessLandingPageUrl);
      } else {
        editRestResource(resourceClass, requestParams, initialRestResource.id);
      }
    } else {
      createRestResource(resourceClass, requestParams, getCreationSuccessLandingPageUrl);
    }
  };
}
