import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath } from 'redux/apiRequests/selectors';
import { selectIsUploadingToAzure } from 'redux/azure/selectors';
import { IRootState } from 'redux/reducer';
import { importGeometrySagaAction } from 'redux/restResources/detail/geometry/actions';
import { selectRouteResource } from 'redux/routing/selectors';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { ImportGeometryForm as Component } from './ImportGeometryForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeGeometry = selectRouteResource(rrc.geometry)(rootState);
  const importApiRequestKey = getDetailApiRequestKey(dra.importGeometry, routeGeometry.id);

  return {
    routeGeometry,
    isUploadingToAzure: selectIsUploadingToAzure(rootState),
    importFormErrors: selectFormApiErrorsTranslationPath(importApiRequestKey)(rootState),
  };
};

const mapDispatchToProps = {
  importGeometry: importGeometrySagaAction,
};

export const ImportGeometryForm = connect(mapStateToProps, mapDispatchToProps)(Component);
