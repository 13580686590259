import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Button } from 'components/Buttons';
import { FilterForm } from 'components/Forms/FilterForm/FilterForm.container';
import { BoldText } from 'components/Texts/BoldText';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { _map } from 'libs/lodash';
import React, { Component, ReactNode } from 'react';
import { XSquare } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';
import { IDatatableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t } from 'utils/functions/translate';

import { IProps } from './DatatableHeader.container';

export class DatatableHeader extends Component<IProps> {
  public render(): ReactNode {
    const { sortColumnId, sortOrder, onSort, isSelectable, tableColumnsParams, isSelectionDisplay } = this.props;

    return (
      <TableHead>
        <TableRow>
          {isSelectable && (
            <CheckboxHeaderCell>
              <SimpleTooltip placement={'bottom'} content={this.getCheckboxTooltipContent()}>
                {isSelectionDisplay ? (
                  <SelectionXContaier>
                    <Button
                      onClick={this.onCheckboxClick}
                      Icon={<XSquare size={20} />}
                      bgColor={theme.colors.transparent}
                      color={'black'}
                      noShadow
                    />
                  </SelectionXContaier>
                ) : (
                  <Checkbox
                    indeterminate={this.isCheckboxIndeterminate()}
                    checked={this.isCheckboxChecked()}
                    onChange={this.onCheckboxClick}
                  />
                )}
              </SimpleTooltip>
            </CheckboxHeaderCell>
          )}
          {_map(tableColumnsParams, (columnParams: IDatatableColumnParams) => (
            <StickyHeaderCell key={columnParams.id} align={columnParams.align}>
              {columnParams.noSort || !columnParams.resourceField ? (
                <Title>{columnParams.title || $t(columnParams.id)}</Title>
              ) : (
                <TableSortLabel
                  active={sortColumnId === columnParams.id}
                  direction={sortOrder}
                  onClick={onSort(columnParams.id)}
                >
                  <Title>{columnParams.title || $t(columnParams.id)}</Title>
                </TableSortLabel>
              )}
              {!columnParams.noFilter && (
                <FilterForm
                  columnId={columnParams.id}
                  minWidth={columnParams.minWidth}
                  maxWidth={columnParams.maxWidth}
                />
              )}
            </StickyHeaderCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  private onCheckboxClick = () => {
    const { selectAllFilteredRows, unselectAllFilteredRows } = this.props;

    this.isCheckboxChecked() || this.isCheckboxIndeterminate() ? unselectAllFilteredRows() : selectAllFilteredRows();
  };

  private isCheckboxIndeterminate = (): boolean => {
    const { rowCount, selectedCount } = this.props;

    return selectedCount > 0 && selectedCount < rowCount;
  };

  private isCheckboxChecked = (): boolean => {
    const { rowCount, selectedCount } = this.props;

    return selectedCount > 0 && selectedCount >= rowCount;
  };

  private getCheckboxTooltipContent = (): string => {
    if (this.isCheckboxIndeterminate() || this.isCheckboxChecked()) {
      return $t('unselectAllRows');
    } else {
      return $t('selectAllRows');
    }
  };
}

const StickyHeaderCell = styled(TableCell)`
  vertical-align: text-top;
  position: sticky;
  top: 0;
  z-index: 2;
` as typeof TableCell;

const CheckboxHeaderCell = styled(StickyHeaderCell)`
  vertical-align: inherit;
  padding: 20px 0 0 7px;
` as typeof TableCell;

const SelectionXContaier = styled.div`
  padding-left: 8px;
`;

const Title = styled(BoldText)`
  white-space: nowrap;
`;
