import { Action } from 'redux';
import { IRestResource } from 'types/RestResource';

export const OPEN_REST_RESOURCE_COMMITMENTS_MODAL_ACTION = 'OPEN_REST_RESOURCE_COMMITMENTS_MODAL_ACTION';
export interface IOpenRestResourceCommitmentsModalAction extends Action<'OPEN_REST_RESOURCE_COMMITMENTS_MODAL_ACTION'> {
  payload: {
    restResource: IRestResource;
    editionField?: string;
  };
}

export const openRestResourceCommitmentsModalAction = (
  restResource: IRestResource,
  editionField?: string
): IOpenRestResourceCommitmentsModalAction => ({
  type: OPEN_REST_RESOURCE_COMMITMENTS_MODAL_ACTION,
  payload: {
    restResource,
    editionField,
  },
});

export const CLOSE_REST_RESOURCE_COMMITMENTS_MODAL_ACTION = 'CLOSE_REST_RESOURCE_COMMITMENTS_MODAL_ACTION';
export interface ICloseRestResourceCommitmentsModalAction
  extends Action<'CLOSE_REST_RESOURCE_COMMITMENTS_MODAL_ACTION'> {}
export const closeRestResourceCommitmentsModalAction = (): ICloseRestResourceCommitmentsModalAction => ({
  type: CLOSE_REST_RESOURCE_COMMITMENTS_MODAL_ACTION,
});

export type IRestResourceCommitmentsModalActions =
  | IOpenRestResourceCommitmentsModalAction
  | ICloseRestResourceCommitmentsModalAction;
