import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikProps } from 'formik';
import { _omit } from 'libs/lodash';
import { getYupStringValidation } from 'libs/yup';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { IMonoSimulationGroupConfigRequestParams } from 'types/SimulationGroup/MonoSimulationGroup';
import { fdw } from 'utils/configs/drawerWidths';
import { convertFormValuesEmptyStringToNull } from 'utils/functions/convertFormValuesEmptyStringToNull';
import { getISOPeriodBounds } from 'utils/functions/datetimes/getISOPeriodBounds';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { withEmptyOption } from 'utils/functions/forms/withEmptyOption';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './ConfigForm.container';

const FormDrawer = getFormDrawer();
const Form = getForm<IMonoSimulationGroupConfigFormValues>();

export interface IMonoSimulationGroupConfigFormValues {
  config_geometry: string;
  config_obat: string;
  config_weather: string;
  config_variant: string;
  year: string;
}

export class ConfigForm extends PureComponent<IProps> {
  public componentDidMount(): void {
    const { routeMonoSimulationGroup, fetchObatVariants } = this.props;
    const { config_obat } = routeMonoSimulationGroup;
    if (!!config_obat) {
      fetchObatVariants(config_obat);
    }
  }

  public render(): ReactNode {
    const { isSubmitting, editErrors, createErrors, routeMonoSimulationGroup } = this.props;

    return (
      <FormDrawer formKey={fk.configMono} width={fdw.medium} title={$t('configuration')}>
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          onSubmit={this.onSubmit}
          isSubmitting={isSubmitting}
          formApiErrors={routeMonoSimulationGroup && editErrors ? editErrors : createErrors}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    /* Need a function here so that the form as rerendered when the props are ready (configOptions) */
    return (formikProps: FormikProps<IMonoSimulationGroupConfigFormValues>): ReactNode => {
      const { configOptions, variantOptionsByObat } = this.props;
      const { config_obat } = formikProps.values;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'config_weather'}
              formikProps={formikProps}
              options={configOptions.weathers}
              label={$t('weather')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'config_geometry'}
              formikProps={formikProps}
              options={configOptions.geometries}
              label={$t('geometry')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'config_obat'}
              formikProps={formikProps}
              options={configOptions.obats}
              onSelect={this.onObatSelect(formikProps)}
              label={$t('obat')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'config_variant'}
              formikProps={formikProps}
              options={withEmptyOption(variantOptionsByObat[config_obat])}
              label={$t('variant')}
              isDisabled={!config_obat}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput field={'year'} formikProps={formikProps} options={configOptions.years} required />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): IMonoSimulationGroupConfigFormValues => {
    const { routeMonoSimulationGroup } = this.props;

    const currentYear: string = moment()
      .year()
      .toString();
    const configStart: string = routeMonoSimulationGroup
      ? moment(routeMonoSimulationGroup.config_start)
          .year()
          .toString()
      : currentYear;

    return {
      config_weather: setFormInitialValue(routeMonoSimulationGroup, 'config_weather'),
      config_geometry: setFormInitialValue(routeMonoSimulationGroup, 'config_geometry'),
      config_obat: setFormInitialValue(routeMonoSimulationGroup, 'config_obat'),
      config_variant: setFormInitialValue(routeMonoSimulationGroup, 'config_variant'),
      year: routeMonoSimulationGroup ? configStart : currentYear,
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IMonoSimulationGroupConfigFormValues> => {
    return yup.object().shape({
      config_geometry: getYupStringValidation(true),
      config_obat: getYupStringValidation(true),
      config_weather: getYupStringValidation(true),
      config_variant: getYupStringValidation(),
      year: getYupStringValidation(true),
    }) as yup.ObjectSchema<IMonoSimulationGroupConfigFormValues>;
  };

  private onObatSelect = (formikProps: FormikProps<IMonoSimulationGroupConfigFormValues>) => {
    return (_field: string, selectedObatId: string): void => {
      this.props.fetchObatVariants(selectedObatId);
      formikProps.setValues({
        ...formikProps.values,
        config_obat: selectedObatId,
        config_variant: '',
      });
    };
  };

  private getRequestParams = (
    formValues: IMonoSimulationGroupConfigFormValues
  ): IMonoSimulationGroupConfigRequestParams => {
    const initialFormValues = this.getInitialFormValues();
    const filteredFinalFormValues = filterEditedValues(initialFormValues, formValues);

    let requestParamsFields = filteredFinalFormValues;
    if (filteredFinalFormValues.year) {
      const yearBounds = getISOPeriodBounds(filteredFinalFormValues.year);
      requestParamsFields = _omit({ ...formValues, config_start: yearBounds.start, config_end: yearBounds.end }, [
        'year',
      ]);
    }
    const requestParams = convertFormValuesEmptyStringToNull(requestParamsFields);

    return requestParams;
  };

  private onSubmit = (formValues: IMonoSimulationGroupConfigFormValues): void => {
    const { editRestResource, routeMonoSimulationGroup } = this.props;

    if (routeMonoSimulationGroup) {
      const requestParams = this.getRequestParams(formValues);
      editRestResource(rrc.mono_simulation_group, requestParams, routeMonoSimulationGroup.id);
    }
  };
}
