import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { projectSubMenuDrawerTheme } from 'components/Menus/WithProjectSubMenu';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { navigateRoute } from 'routing/navigateRoute';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { WithProjectMenu } from '../../../pages/ProjectMenu/WithProjectMenu';
import { WithMenuDrawer } from '../WithMenuDrawer';
import { IProps } from './WithProjectSubMenu.container';

export const PROJECT_EXPANDED_SUB_DRAWER_WIDTH = 200;
export const PROJECT_COLLAPSED_SUB_DRAWER_WIDTH = 16;

export class WithProjectSubMenu extends PureComponent<IProps> {
  public render = (): ReactNode => {
    const { subMenuTitle, isSubMenuCollapsed, selectedSubMenu, children } = this.props;

    const Menu = this.renderMenu();

    return (
      <WithProjectMenu isCollapsed selectedPage={selectedSubMenu}>
        <WithMenuDrawer
          menuTitle={subMenuTitle}
          collapsible
          isCollapsed={!!isSubMenuCollapsed}
          drawerTheme={projectSubMenuDrawerTheme}
          expandedWidth={PROJECT_EXPANDED_SUB_DRAWER_WIDTH}
          collapsedWidth={PROJECT_COLLAPSED_SUB_DRAWER_WIDTH}
          Menu={Menu}
        >
          {children}
        </WithMenuDrawer>
      </WithProjectMenu>
    );
  };

  private renderMenu = (): ReactNode => {
    const {
      selectedPage,
      routeProject,
      subMenuRouteResource,
      subMenuPagesDivision,
      getSubMenuPageRoute,
      getSubMenuItemTitleFromPage,
    } = this.props;

    return (
      <StyledList component="nav">
        {_map(subMenuPagesDivision, (itemsList: string[], groupIndex: number): ReactNode[] =>
          _map(
            itemsList,
            (page: string, itemIndex: number): ReactNode => {
              const isSelected = page === selectedPage;
              const hasDividerAbove = itemIndex === 0 && groupIndex !== 0;
              const itemTitle = !!getSubMenuItemTitleFromPage ? getSubMenuItemTitleFromPage(page) : $t(page);
              const onMouseDown = (e: React.MouseEvent): void => {
                const route = getSubMenuPageRoute(page, routeProject.id, subMenuRouteResource.id);
                navigateRoute(route, e);
              };

              return (
                <div key={page}>
                  {hasDividerAbove && <StyledDivider variant="middle" />}
                  {this.renderMenuListItem(itemTitle, isSelected, onMouseDown)}
                </div>
              );
            }
          )
        )}
      </StyledList>
    );
  };

  private renderMenuListItem = (
    itemTitle: string,
    isSelected: boolean,
    onMouseDown: (e: React.MouseEvent) => void
  ): ReactNode => {
    return (
      <ListItemContainer key={itemTitle}>
        <ListItem button selected={isSelected} onMouseDown={onMouseDown}>
          <ListItemText primary={itemTitle} />
        </ListItem>
      </ListItemContainer>
    );
  };
}

const ListItemContainer = styled.div`
  margin: 4px 16px;
`;

const StyledDivider = styled(Divider)`
  background-color: ${theme.colors.divider};
  opacity: 0.4;
` as typeof Divider;

const StyledList = styled(List)`
  margin-top: 40px;
` as typeof List;
