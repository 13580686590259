import { environment } from 'environment';
import { toPlural } from 'libs/pluralize';
import { ITokens } from 'types/Tokens';
import { orc, rrc } from 'utils/strings/resourceClasses';
import wretch, { Wretcher } from 'wretch';

const apiBaseCall = (accessToken: ITokens['access'] | null): Wretcher => {
  const request = wretch(environment.API_URL);
  /* accessToken can be null, specifically when calling `fetchToken` (i.e. when logging in) */
  if (accessToken === null) {
    return request;
  }

  return request.auth(`Bearer ${accessToken}`);
};

export const apiEndpoints = {
  token: (): Wretcher => {
    return apiBaseCall(null).url('/oteams/token/');
  },
  obatContents: (accessToken: ITokens['access'], obatId: string): Wretcher => {
    return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/`);
  },
  // @ts-ignore: (resourceClass "simulation" has no endPoint)
  restResources: {
    /* oteams */
    organization: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/oteams/${toPlural(rrc.organization)}/`);
    },
    user_organization_permission: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/oteams/${toPlural(rrc.user_organization_permission)}/`);
    },
    project: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/oteams/${toPlural(rrc.project)}/`);
    },
    project_visible: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/oteams/${'projects-visible'}/`);
    },
    user_project_permission: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/oteams/expanded_user_project_permissions/`);
    },
    user: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/oteams/${toPlural(rrc.user)}/`);
    },

    /* osstasks */
    user_task: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/osstasks/${toPlural(rrc.user_task)}/`);
    },

    /* ossgeometry */
    floorspace: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossgeometry/${toPlural(rrc.floorspace)}/`);
    },
    geometry: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossgeometry/${toPlural(rrc.geometry)}/`);
    },

    /* ossbat */
    obat: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/${toPlural(rrc.obat)}/`);
    },

    /* ossweather */
    weather: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossweather/${toPlural(rrc.weather)}/`);
    },
    generic_weather_series: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossweather/${toPlural(rrc.generic_weather_series)}/`);
    },
    historical_weather_series: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossweather/${toPlural(rrc.historical_weather_series)}/`);
    },
    openergy_historical_weather_series: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossweather/${toPlural(rrc.openergy_historical_weather_series)}/`);
    },

    /* osssimulations */
    simulation_group: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/osssimulations/${toPlural(rrc.simulation_group)}/`);
    },
    mono_simulation_group: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/osssimulations/${toPlural(rrc.mono_simulation_group)}/`);
    },
    multi_simulation_group: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/osssimulations/${toPlural(rrc.multi_simulation_group)}/`);
    },
    generic_simulation_group: (accessToken: ITokens['access']): Wretcher => {
      return apiBaseCall(accessToken).url(`/osssimulations/${toPlural(rrc.generic_simulation_group)}/`);
    },
  },
  obatResources: {
    calendar: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.calendar)}/`);
    },
    day_group: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.day_group)}/`);
    },
    day_profile: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.day_profile)}/`);
    },
    period: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.period)}/`);
    },
    year_profile: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.year_profile)}/`);
    },
    opening: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.opening)}/`);
    },
    construction: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.construction)}/`);
    },
    layer: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.layer)}/`);
    },
    schedule: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.schedule)}/`);
    },
    surface_group: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.surface_group)}/`);
    },
    output_zone_group: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.output_zone_group)}/`);
    },
    bridge: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.bridge)}/`);
    },
    script: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.script)}/`);
    },
    system: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.system)}/`);
    },
    activity_zone_group: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.activity_zone_group)}/`);
    },
    variant: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.variant)}/`);
    },
    modification: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.modification)}/`);
    },
    material: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.material)}/`);
    },
    use: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${toPlural(orc.use)}/`);
    },
    general: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${orc.general}/`);
    },
    ef_ep: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${orc.general}/`);
    },
    e_plus_parameters: (accessToken: ITokens['access'], obatId: string): Wretcher => {
      return apiBaseCall(accessToken).url(`/ossbat/obat_contents/${obatId}/${orc.general}/`);
    },
  },
};
