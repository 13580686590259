import { _map } from 'libs/lodash';
import { IRootState } from 'redux/reducer';
import { ISelectOptions } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { $tResourceTemplates } from 'utils/functions/translate';
import { IProgressStatus } from 'utils/strings/progressStatus';

export const selectGeometryImportProgress = (rootState: IRootState): IProgressStatus => {
  return rootState.geometry.importProgress;
};

export const selectGeometryThreejsData = (rootState: IRootState): object | undefined => {
  return rootState.geometry.threejs;
};

export const selectGeometriesTemplateOptions = (rootState: IRootState): ISelectOptions => {
  const { templates } = rootState.geometry;

  return !templates
    ? []
    : _map(templates.template_geometry_use, (template: string) => {
        return { label: $tResourceTemplates(template), value: template };
      });
};
