// tslint:disable:no-any

import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { toggleMonthlyResultsForcePivotUpdateAction } from 'redux/restResources/detail/simulationGroup/actions';
import { selectMonthlyResultsForcePivotUpdate } from 'redux/restResources/detail/simulationGroup/selectors';

import { PivotTable as Component } from './PivotTable.component';

interface IOwnProps {
  config: any;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  return {
    forcePivotUpdate: selectMonthlyResultsForcePivotUpdate(rootState),
  };
};

const mapDispatchToProps = {
  toggleForcePivotUpdate: toggleMonthlyResultsForcePivotUpdateAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export const PivotTable = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
