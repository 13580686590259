import { Grid } from '@material-ui/core';
import { Plot } from 'components/Plot';
import { PlotLegend } from 'components/PlotLegend';
import { _map } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { Data } from 'plotly.js';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { IConstruction } from 'types/Obat/Construction';
import { ILayer } from 'types/Obat/Layer';
import { getConstructionBoundsLabels } from 'utils/functions/obatResources/construction/getConstructionBoundsLabels';
import { $t } from 'utils/functions/translate';

interface IProps {
  construction: IConstruction;
}

export class ConstructionPlot extends PureComponent<IProps> {
  public render(): ReactNode {
    const {
      construction: {
        layers_contents: layers,
        wireframe_position: wireframePosition,
        surface_category: surfaceCategory,
      },
    } = this.props;

    if (!layers || _size(layers) === 0) {
      return '-';
    }

    const constructionThickness: number = _reduce(
      layers,
      (thickness: number, layer: ILayer): number => {
        return thickness + layer.thickness;
      },
      0
    );

    const wireframeData: Data = {
      x: [0.01 * constructionThickness],
      type: 'bar',
      orientation: 'h',
      // @ts-ignore Type Data doesn't know hovertext...
      hovertext: [$t('wireframe')],
      hoverinfo: 'text',
      marker: {
        color: 'black',
      },
    };

    const plotlyData: Data[] = _map(
      layers,
      (layer: ILayer, i: number): Data => {
        return {
          x: [layer.thickness],
          type: 'bar',
          orientation: 'h',
          hoverinfo: 'x',
          marker: {
            color: theme.colors.plot.obatColorway[i],
          },
        };
      }
    );

    if (surfaceCategory !== 'wall_interior') {
      plotlyData.splice(wireframePosition, 0, wireframeData);
    }

    const layout = {
      showlegend: false,
      barmode: 'stack',
      hovermode: 'closest',
      height: 35,
      margin: {
        l: 0,
        r: 0,
        t: 0,
        b: 0,
      },
      id: '',
      xaxis: {
        visible: false,
      },
      yaxis: {
        visible: false,
      },
      plot_bgcolor: theme.colors.transparent,
      paper_bgcolor: theme.colors.transparent,
    };

    const config = { displayModeBar: false, responsive: true };
    const legendItems = _map(layers, (layer: ILayer, i: number) => {
      return {
        title: layer.material,
        dotColor: theme.colors.plot.obatColorway[i],
      };
    });

    const boundsLabels = getConstructionBoundsLabels(surfaceCategory);

    return (
      <div onClick={this.onClick}>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <LeftDiv>{$t(boundsLabels.left)}</LeftDiv>
          </Grid>
          <Grid item xs={9}>
            <PlotAndLegendContainer>
              <PlotContainer
                // @ts-ignore Plotly typing issue
                layout={layout}
                data={plotlyData}
                config={config}
              />
              <PlotLegend legendItems={legendItems} itemsPerLine={3} />
            </PlotAndLegendContainer>
          </Grid>
          <Grid item xs={1}>
            <RightDiv>{$t(boundsLabels.right)}</RightDiv>
          </Grid>
        </Grid>
      </div>
    );
  }

  private onClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
  };
}

const PlotAndLegendContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const PlotContainer = styled(Plot)`
  width: 100%;
` as typeof Plot;

const LeftDiv = styled.div`
  padding-top: 8px;
  padding-right: 12px;
  text-align: right;
  color: ${theme.colors.font.optional};
`;

const RightDiv = styled.div`
  padding-top: 8px;
  margin-left: -12px;
  color: ${theme.colors.font.optional};
`;
