import { connect } from 'react-redux';
import { clearSingleApiRequestAction } from 'redux/apiRequests/actions';
import { selectApiRequestErrorStatus } from 'redux/apiRequests/selectors';
import { IRootState } from 'redux/reducer';
import { fetchTokensSagaAction } from 'redux/tokens/actions';
import { selectAccessToken } from 'redux/tokens/selectors';

import { Login as Component } from './Login.component';

const mapStateToProps = (rootState: IRootState) => ({
  accessToken: selectAccessToken(rootState),
  loginErrorStatus: selectApiRequestErrorStatus('fetchTokens')(rootState),
});

const mapDispatchToProps = {
  fetchTokens: fetchTokensSagaAction,
  clearSingleApiRequest: clearSingleApiRequestAction,
};

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

export const Login = connect(mapStateToProps, mapDispatchToProps)(Component);
