import { _difference, _filter } from 'libs/lodash';
import { _find } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { IRootState } from 'redux/reducer';
import { selectMyUser } from 'redux/restResources/detail/me/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { selectTableResources } from 'redux/table/selectors';
import { createSelector } from 'reselect';
import { IUserOrganizationPermission } from 'types/Permissions';
import { ISelectOption } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { rrc } from 'utils/strings/resourceClasses';

export const selectUserOrganizationPermissions = (rootState: IRootState): IUserOrganizationPermission[] => {
  return rootState.organization.userPermissions;
};

export const selectCanSpendDailySeats = (rootState: IRootState): boolean => {
  const userPermissions = selectUserOrganizationPermissions(rootState);
  const myUser = selectMyUser(rootState);
  const myPermission = _find(userPermissions, ['user.id', !!myUser && myUser.id]);

  return !!myPermission && myPermission.can_spend_daily_seats;
};

export const selectTakingSeatUserPermissions = createSelector(
  selectUserOrganizationPermissions,
  (userPermissions: IUserOrganizationPermission[]): IUserOrganizationPermission[] => {
    return _filter(userPermissions, (userPermission: IUserOrganizationPermission) => userPermission.is_taking_up_seat);
  }
);

export const selectNotTakingSeatUserPermissionOptions = createSelector(
  [selectUserOrganizationPermissions],
  (userPermissions: IUserOrganizationPermission[]): ISelectOption[] => {
    const notTakinSeatUserPermissions = _filter(
      userPermissions,
      (userPermission: IUserOrganizationPermission) => !userPermission.is_taking_up_seat
    );

    return _map(notTakinSeatUserPermissions, (userPermission: IUserOrganizationPermission) => {
      return { value: userPermission.id, label: userPermission.user.email };
    });
  }
);

export const selectRouteProjectUserOptions = (rootState: IRootState): ISelectOption[] => {
  const userOrganizationPermissions = rootState.organization.userPermissions;
  const organizationUsersId = _map(userOrganizationPermissions, 'user.id');
  const routeProject = selectRouteResource(rrc.project)(rootState);
  const tableResources = selectTableResources(rootState)(rrc.user_project_permission, routeProject.id);
  const projectUserPermissions = tableResources && tableResources.full;
  const projectUsersIds = _map(projectUserPermissions, 'user.id');
  const userIdOptions = _difference(organizationUsersId, projectUsersIds);

  // @ts-ignore bug lodash _map boolean
  return _map(
    userIdOptions,
    (userId: string): ISelectOption => {
      const userPermission = _find(userOrganizationPermissions, ['user.id', userId]);

      return { value: userId, label: userPermission && userPermission.user.email };
    }
  );
};
