import { _size } from 'libs/lodash';
import moment from 'moment';

export const getExportFileName = (fileName: string, fileFormat?: string): string => {
  /* export file name should not be longer than 64 characters */
  const maxSize = 64;
  const dateFormat = 'YYYY-MM-DD HH-mm';
  const now = moment().format(dateFormat);
  const suffixSize =
    1 /* for blank space */ + _size(dateFormat) + (fileFormat ? 1 /* for dot */ + _size(fileFormat) : 0);
  const slicedFileName = _size(fileName) + suffixSize > maxSize ? fileName.slice(0, maxSize - suffixSize) : fileName;

  return `${slicedFileName} ${now}${fileFormat ? '.' + fileFormat : ''}`;
};
