import Grid from '@material-ui/core/Grid';
import { LegendItem } from 'components/PlotLegend';
import { LightText } from 'components/Texts';
import { _map } from 'libs/lodash';
import { _range } from 'libs/lodash';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { IDayProfileSeries } from 'types/Obat/DayProfile';

interface IProps {
  dayGroup: string;
  dayProfileSeries: IDayProfileSeries;
  position: number;
}

export class DayGroupDetail extends PureComponent<IProps> {
  public render(): ReactNode {
    const { dayGroup, position } = this.props;

    if (position === 0) {
      return <FirstDayGroupRow>{this.renderRowContent(dayGroup, position)}</FirstDayGroupRow>;
    } else {
      return <DayGroupRow>{this.renderRowContent(dayGroup, position)}</DayGroupRow>;
    }
  }

  private mapSeries = (i: number): ReactNode => {
    const { index, data } = this.props.dayProfileSeries;
    if (i < index.length) {
      const value = data[i];
      const iso = index[i];
      const hour = moment.utc(iso).format('HH:mm');

      return (
        <Grid item xs={1} key={i.toString()}>
          <Col>
            {hour}
            <LightText>{value}</LightText>
          </Col>
        </Grid>
      );
    } else {
      return <Grid item xs={1} key={i.toString()} />;
    }
  };

  private renderRowContent = (dayGroup: string, position: number): ReactNode => (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <LegendItem dotColor={theme.colors.plot.obatColorway[position]} text={dayGroup} />
      </Grid>
      {_map(_range(10), this.mapSeries)}
    </Grid>
  );
}

const DayGroupRow = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-top: solid 1px ${theme.colors.divider};
  padding: 4px;
`;

const FirstDayGroupRow = styled(DayGroupRow)`
  border-top: none;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
