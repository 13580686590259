import { connect } from 'react-redux';
import { openDeletionModalAction } from 'redux/modals/deletionModal/actions';
import { IRootState } from 'redux/reducer';
import { IObatResource } from 'types/Obat/ObatResource';
import { IObatResourceClass } from 'utils/strings/resourceClasses';

import { FormDeleteButton as FormDeleteButtonComponent } from './FormDeleteButton.component';

export interface IObatResourceDeleteProps {
  obatResource: IObatResource;
  resourceClass: IObatResourceClass;
  isDeleting: boolean;
}

interface IOwnProps {
  obatResourceDeleteProps: IObatResourceDeleteProps;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const mapStateToProps = (_rootState: IRootState) => {
  return {};
};

const mapDispatchToProps = {
  openDeletionModal: openDeletionModalAction,
};

export const FormDeleteButton = connect(mapStateToProps, mapDispatchToProps)(FormDeleteButtonComponent);
