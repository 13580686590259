// tslint:disable:no-any
import { i18n } from 'libs/i18n';
import { _camelCase, _includes } from 'libs/lodash';

export const $t = (path: any, params?: any): string => {
  return i18n.t(path, params);
};

export const $tcc = (path: any, params?: any): string => {
  return i18n.t(_camelCase(path), params);
};

export const $tEnums = (enumsKey: string | null): string => {
  switch (enumsKey) {
    case '':
      return '';
    case null:
      return '-';
    case '-':
      return '-';
    default:
      return $t(`_enums.${enumsKey}`);
  }
};

export const $tErrorsYup = (yupError: string, params?: any): string => {
  return $t('_errors.yup.' + yupError, params);
};

export const $tErrorsDetailedCode = (errorDetailedCode: string): string => {
  return $t('_errors.detailedCode.' + errorDetailedCode);
};

export const $tErrorsExtra = (extraError: string, params: any): string => {
  return $t('_errors.extra.' + extraError, params);
};

export const $tPivotTemplates = (name?: string): string => {
  return name ? $t('_pivotTemplates.' + name) : '';
};

export const $tWeatherSeries = (name: string): string => {
  return $t('_weatherSeries.' + name);
};

export const $tResourceTemplates = (name?: string): string => {
  return name ? $t('_resourceTemplates.' + name) : '';
};
