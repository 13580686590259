import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import {
  fetchObatVariantsSagaAction,
  fetchSimulationGroupConfigOptionsSagaAction,
} from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectSimulation,
  selectSimulationGroupConfigOptions,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { SimulationConfigCard as Component } from './SimulationConfigCard.component';

interface IOwnProps {
  withName?: boolean;
  withStatus?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => ({
  routeMultiSimulationGroup: selectRouteResource(rrc.multi_simulation_group)(rootState),
  simulation: selectSimulation(rootState),
  routeProject: selectRouteResource(rrc.project)(rootState),
  configOptions: selectSimulationGroupConfigOptions(rootState),
});

const mapDispatchToProps = {
  fetchSimulationGroupConfigOptions: fetchSimulationGroupConfigOptionsSagaAction,
  fetchObatVariants: fetchObatVariantsSagaAction,
};

export const SimulationConfigCard = connect(mapStateToProps, mapDispatchToProps)(Component);
