import {
  CLEAR_TOKENS_ACTION,
  ITokensActions,
  UPDATE_IS_REFRESHING_ACCESS_ACTION,
  UPDATE_TOKENS_ACTION,
} from './actions';

export interface ITokensState {
  access: string | null;
  refresh: string | null;
  isRefreshingAccess: boolean;
}

export const initialState = {
  access: null,
  refresh: null,
  isRefreshingAccess: false,
};

export const tokensReducer = (state: ITokensState = initialState, action: ITokensActions): ITokensState => {
  switch (action.type) {
    case UPDATE_IS_REFRESHING_ACCESS_ACTION:
      return { ...state, ...action.payload };
    case UPDATE_TOKENS_ACTION:
      return { ...state, ...action.payload };
    case CLEAR_TOKENS_ACTION:
      return initialState;
    default:
      return state;
  }
};
