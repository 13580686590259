import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';

export const getObatResourceIndex = (
  obatContents: IObatContents,
  tableRef: keyof IObatData,
  contentId: string
): number => {
  return obatContents.meta.indexes[tableRef][contentId];
};
