import { _get, _map } from 'libs/lodash';
import { IDayProfile } from 'types/Obat/DayProfile';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';
import { ISchedule } from 'types/Obat/Schedule';
import { enums } from 'utils/configs/enums';

import { calculateDayScheduleSeries } from './calculateDayScheduleSeries';
import { getObatResourceIndex } from './getObatResource';
import { expandSeries } from './series';

export const enrichSchedules = (obatContents: IObatContents): IObatData => {
  const { schedule: schedules, year_profile: yearProfiles } = obatContents.data;

  const enrichedSchedules = _map(
    schedules,
    (schedule: ISchedule): ISchedule => {
      schedule.category = _get(
        enums.obat.obat_contents.schedule_category__by_type_and_method,
        `${schedule.type}.${schedule.method}`
      );
      schedule.value = schedule.nominal_value * schedule.reduction_ratio;
      schedule.isOnOff = schedule.type === 'control';

      /* prepare day schedules */
      if (!schedule.year_profile) {
        return schedule;
      }

      const yearProfileIndex = getObatResourceIndex(obatContents, 'year_profile', schedule.year_profile);
      const yearProfile = yearProfiles[yearProfileIndex];
      schedule.day_schedules_contents = _map(
        yearProfile.day_profiles_contents,
        (dayProfile: IDayProfile): IDayProfile => {
          const isOnOff = schedule.category === 'on_off';
          const series = calculateDayScheduleSeries(dayProfile, schedule.value, isOnOff, schedule.baseload);

          return {
            ...dayProfile,
            series,
            expanded_series: expandSeries(series, dayProfile.day_group),
          };
        }
      );

      // calendar
      schedule.calendar = yearProfile.calendar;

      return schedule;
    }
  );

  return { ...obatContents.data, schedule: enrichedSchedules };
};
