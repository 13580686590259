import { _includes } from 'libs/lodash';
import React, { Component, ReactNode } from 'react';
import { IObatResource } from 'types/Obat/ObatResource';
import { IObatResourceClass, orc } from 'utils/strings/resourceClasses';

import { getResourceForm } from '../ResourceForm';

import { IEfEpRequestParams } from 'types/Obat/EfEp';
import { IEPlusParametersRequestParams } from 'types/Obat/EPlusParameters';
import { IProps } from './ObatResourceForm.container';

export class ObatResourceForm<IObatResourceFormValues, IObatResourceRequestParams> extends Component<
  IProps<IObatResourceFormValues, IObatResourceRequestParams>
> {
  public ResourceForm = getResourceForm<IObatResourceFormValues, IObatResourceRequestParams, IObatResourceClass>();

  public render(): ReactNode {
    const { ResourceForm, props } = this;
    const { initialObatResource, isDeleting, resourceClass } = props;

    let obatResourceDeleteProps;
    if (initialObatResource && _includes([orc.layer, orc.day_group, orc.period, orc.modification], resourceClass)) {
      obatResourceDeleteProps = {
        obatResource: initialObatResource,
        resourceClass,
        isDeleting,
      };
    }

    return (
      <ResourceForm
        {...props}
        initialResource={initialObatResource}
        saveResource={this.saveResource}
        isObatResource
        obatResourceDeleteProps={obatResourceDeleteProps}
      />
    );
  }

  private saveResource = (
    resourceClass: IObatResourceClass,
    initialObatResource: IObatResource,
    requestParams: IObatResourceRequestParams | IEfEpRequestParams | IEPlusParametersRequestParams
  ): void => {
    const { editObatResource, copyObatResource, createObatResource, routeObat, isCopy } = this.props;

    let request = requestParams as IEPlusParametersRequestParams;
    // fixme: form is mapped to expected resource formats here. better place could be found
    if (_includes([orc.ef_ep, orc.e_plus_parameters], resourceClass)) {
      if (initialObatResource.id === 'ef_ep') {
        request.ref = 'ef_ep';
      } else if (initialObatResource.id === 'solar_distribution') {
        request = {
          ref: 'solar_distribution',
          obat_eplus_conf_solar_distribution: request.value ? request.value : initialObatResource.value,
        };
      } else if (initialObatResource.id === 'ground_temperatures') {
        request = {
          ref: 'ground_temperatures',
          obat_eplus_conf_ground_temperature_method: request.value ? request.value : initialObatResource.value,
          site_heated_ground_temperatures: [
            request.distributed_january ? Number(request.distributed_january) : initialObatResource.distributed_january,
            request.distributed_february
              ? Number(request.distributed_february)
              : initialObatResource.distributed_february,
            request.distributed_march ? Number(request.distributed_march) : initialObatResource.distributed_march,
            request.distributed_april ? Number(request.distributed_april) : initialObatResource.distributed_april,
            request.distributed_may ? Number(request.distributed_may) : initialObatResource.distributed_may,
            request.distributed_june ? Number(request.distributed_june) : initialObatResource.distributed_june,
            request.distributed_july ? Number(request.distributed_july) : initialObatResource.distributed_july,
            request.distributed_august ? Number(request.distributed_august) : initialObatResource.distributed_august,
            request.distributed_september
              ? Number(request.distributed_september)
              : initialObatResource.distributed_september,
            request.distributed_october ? Number(request.distributed_october) : initialObatResource.distributed_october,
            request.distributed_november
              ? Number(request.distributed_november)
              : initialObatResource.distributed_november,
            request.distributed_december
              ? Number(request.distributed_december)
              : initialObatResource.distributed_december,
          ],
          site_undisturbed_ground_temperatures: [
            request.undistributed_january
              ? Number(request.undistributed_january)
              : initialObatResource.undistributed_january,
            request.undistributed_february
              ? Number(request.undistributed_february)
              : initialObatResource.undistributed_february,
            request.undistributed_march ? Number(request.undistributed_march) : initialObatResource.undistributed_march,
            request.undistributed_april ? Number(request.undistributed_april) : initialObatResource.undistributed_april,
            request.undistributed_may ? Number(request.undistributed_may) : initialObatResource.undistributed_may,
            request.undistributed_june ? Number(request.undistributed_june) : initialObatResource.undistributed_june,
            request.undistributed_july ? Number(request.undistributed_july) : initialObatResource.undistributed_july,
            request.undistributed_august
              ? Number(request.undistributed_august)
              : initialObatResource.undistributed_august,
            request.undistributed_september
              ? Number(request.undistributed_september)
              : initialObatResource.undistributed_september,
            request.undistributed_october
              ? Number(request.undistributed_october)
              : initialObatResource.undistributed_october,
            request.undistributed_november
              ? Number(request.undistributed_november)
              : initialObatResource.undistributed_november,
            request.undistributed_december
              ? Number(request.undistributed_december)
              : initialObatResource.undistributed_december,
          ],
        };
      } else if (initialObatResource.id === 'water_temperatures') {
        request = {
          ref: 'water_temperatures',
          obat_eplus_conf_cold_water_temperature_method: request.value ? request.value : initialObatResource.value,
          site_water_temperatures: [
            request.distributed_january ? Number(request.distributed_january) : initialObatResource.distributed_january,
            request.distributed_february
              ? Number(request.distributed_february)
              : initialObatResource.distributed_february,
            request.distributed_march ? Number(request.distributed_march) : initialObatResource.distributed_march,
            request.distributed_april ? Number(request.distributed_april) : initialObatResource.distributed_april,
            request.distributed_may ? Number(request.distributed_may) : initialObatResource.distributed_may,
            request.distributed_june ? Number(request.distributed_june) : initialObatResource.distributed_june,
            request.distributed_july ? Number(request.distributed_july) : initialObatResource.distributed_july,
            request.distributed_august ? Number(request.distributed_august) : initialObatResource.distributed_august,
            request.distributed_september
              ? Number(request.distributed_september)
              : initialObatResource.distributed_september,
            request.distributed_october ? Number(request.distributed_october) : initialObatResource.distributed_october,
            request.distributed_november
              ? Number(request.distributed_november)
              : initialObatResource.distributed_november,
            request.distributed_december
              ? Number(request.distributed_december)
              : initialObatResource.distributed_december,
          ],
        };
      }
    }

    if (initialObatResource) {
      if (isCopy) {
        copyObatResource(initialObatResource.id, resourceClass, request, routeObat.id);
      } else {
        editObatResource(initialObatResource.id, resourceClass, request, routeObat.id);
      }
    } else {
      createObatResource(resourceClass, request, routeObat.id);
    }
  };
}
