import { AlertIcon } from 'components/Icons';
import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import React, { PureComponent, ReactNode } from 'react';
import {
  getMonoSimulationGroupMenuPageRoute,
  getMultiSimulationGroupMenuPageRoute,
  monoSimulationGroupMenuPages,
  multiSimulationGroupMenuPages,
  projectMenuPages,
} from 'routing/routes';
import styled from 'styled-components';
import { IRestResource } from 'types/RestResource';
import { ISimulationGroupListed } from 'types/SimulationGroup/SimulationGroup';
import { formatISODateToDstDatetime } from 'utils/functions/datetimes/formatISODate';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t, $tcc } from 'utils/functions/translate/translate';
import { as } from 'utils/strings/alertStatus';
import { rrc } from 'utils/strings/resourceClasses';

import { rm } from 'utils/strings/routingMenus';
import { SimulationGroupForm } from '../SimulationGroupForm';
import { IProps } from './SimulationGroupsList.container';

export class SimulationGroupsList extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeProject } = this.props;

    return (
      <RestResourcesListPage
        routingMenu={rm.project}
        routeParentId={routeProject.id}
        selectedPage={projectMenuPages.simulationGroups}
        FormComponent={SimulationGroupForm}
        getCreationSuccessLandingPageUrl={this.getCreationSuccessLandingPageUrl}
        resourceClass={rrc.simulation_group}
        parentResourceClass={rrc.project}
        columnsParams={columnsParams}
        defaultSort={{ columnId: 'lastSimulation', order: 'desc' }}
        availableActions={['edit', 'copy', 'delete']}
      />
    );
  }

  private getCreationSuccessLandingPageUrl = (routeProjectId: string, createdResource: IRestResource) => {
    return createdResource.model === 'monosimulationgroup'
      ? getMonoSimulationGroupMenuPageRoute(monoSimulationGroupMenuPages.default, routeProjectId, createdResource.id)
      : getMultiSimulationGroupMenuPageRoute(multiSimulationGroupMenuPages.default, routeProjectId, createdResource.id);
  };
}

const getNavigationUrl = (simulationGroup: ISimulationGroupListed): string => {
  return simulationGroup.model === 'monosimulationgroup'
    ? getMonoSimulationGroupMenuPageRoute(
        simulationGroup.status === 'success'
          ? monoSimulationGroupMenuPages.monthlyResultsMono
          : monoSimulationGroupMenuPages.configMono,
        simulationGroup.project.id,
        simulationGroup.id
      )
    : getMultiSimulationGroupMenuPageRoute(
        simulationGroup.success_nb > 0
          ? multiSimulationGroupMenuPages.monthlyResultsMulti
          : multiSimulationGroupMenuPages.configMulti,
        simulationGroup.project.id,
        simulationGroup.id
      );
};

const columnsParams: ITableColumnParams[] = [
  { id: 'name', resourceField: 'name', getNavigationUrl },
  {
    id: 'lastSimulation',
    resourceField: 'last_run',
    renderCustomText: (simulationGroup: ISimulationGroupListed) => formatISODateToDstDatetime(simulationGroup.last_run),
  },
  {
    id: 'model',
    resourceField: 'model',
    title: $t('type'),
    withFilterOptions: true,
    renderCustomText: (simulationGroup: ISimulationGroupListed) => $t(simulationGroup.model),
  },
  {
    id: 'status',
    resourceField: 'status',
    withFilterOptions: true,
    renderCustomDOM: (simulationGroup: ISimulationGroupListed): ReactNode => (
      <TableIcon>
        <AlertIcon status={simulationGroup.status} />
      </TableIcon>
    ),
    getCustomDOMFilterComparisonValue: (simulationGroup: ISimulationGroupListed) => $tcc(simulationGroup.status),
  },
  {
    id: 'upToDate',
    resourceField: 'obsolete_front',
    withFilterOptions: true,
    renderCustomDOM: (simulationGroup: ISimulationGroupListed): ReactNode => {
      return (
        <TableIcon>
          {simulationGroup.obsolete_front ? (
            <AlertIcon status={as.warning} tooltipContent={$t('obsolete')} />
          ) : (
            <AlertIcon status={as.success} tooltipContent={$t('upToDate')} />
          )}
        </TableIcon>
      );
    },
    getCustomDOMFilterComparisonValue: (simulationGroup: ISimulationGroupListed) =>
      simulationGroup.obsolete_front ? $t('obsolete') : $t('upToDate'),
  },
];

const TableIcon = styled.div`
  margin-bottom: -4px;
`;
