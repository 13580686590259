import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { closeFormDrawerAction } from 'redux/form/actions';
import { fetchObatContentsSaga } from 'redux/obatResources/sagas';
import { selectObatResources } from 'redux/obatResources/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { updateTableFullDataAction } from 'redux/table/actions';
import { selectTableResourceClass } from 'redux/table/selectors';
import { rrc } from 'utils/strings/resourceClasses';
import { openAlertModalAction } from './../modals/alertModal/actions';

import { as } from 'utils/strings/alertStatus';
import {
  clearVariantContextErrorAction,
  IUpdateVariantContextIdSagaAction,
  UPDATE_VARIANT_CONTEXT_ID_SAGA_ACTION,
  updateVariantContextIdAction,
} from './actions';
import { selectVariantContextError } from './selectors';

export function* updateVariantContextIdSaga(payload: IUpdateVariantContextIdSagaAction['payload']): SagaIterator {
  const { variantId } = payload;
  const routeObat = yield select(selectRouteResource(rrc.obat));
  const currentResourceClass = yield select(selectTableResourceClass);

  yield call(fetchObatContentsSaga, routeObat.id, true, variantId);
  const variantError = yield select(selectVariantContextError);
  if (!variantError) {
    const data = yield select(selectObatResources(currentResourceClass));
    yield put(updateTableFullDataAction(data));
    yield put(updateVariantContextIdAction(variantId));
    yield put(closeFormDrawerAction());
  } else {
    yield put(openAlertModalAction(variantError, as.error));
    yield put(clearVariantContextErrorAction());
  }
}

export function* updateVariantContextIdActionSaga(action: IUpdateVariantContextIdSagaAction): SagaIterator {
  yield call(updateVariantContextIdSaga, action.payload);
}

export function* variantContextActionsSagas(): Generator {
  yield takeLatest(UPDATE_VARIANT_CONTEXT_ID_SAGA_ACTION, updateVariantContextIdActionSaga);
}
