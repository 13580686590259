import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import * as yup from 'yup';

import { IProps } from './AffectSeatForm.container';

interface IAffectSeatFormValues {
  userPermissionId: string;
}

const FormDrawer = getFormDrawer();
const Form = getForm<IAffectSeatFormValues>();

export class AffectSeatForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <FormDrawer
        formKey={fk.allocateSeat}
        width={fdw.small}
        title={$t('allocateSeat')}
        formFocusRef={this.formFocusRef}
      >
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          isSubmitting={false}
          onSubmit={this.onSubmit}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IAffectSeatFormValues>): ReactNode => {
      const { notTakingSeatUserPermissionOptions } = this.props;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'userPermissionId'}
              label={$t('email')}
              formikProps={formikProps}
              options={notTakingSeatUserPermissionOptions}
              required
              inputRef={this.formFocusRef}
            />
          </Grid>
        </Grid>
      );
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IAffectSeatFormValues> => {
    return yup.object().shape({
      userPermissionId: getYupStringValidation(true),
    }) as yup.ObjectSchema<IAffectSeatFormValues>;
  };

  private onSubmit = (formValues: IAffectSeatFormValues): void => {
    const { manageSeat, closeFormDrawer } = this.props;
    manageSeat('take', formValues.userPermissionId);
    closeFormDrawer();
  };

  private getInitialFormValues = (): IAffectSeatFormValues => {
    return {
      userPermissionId: '',
    };
  };
}
