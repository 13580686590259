import { _get, _reduce } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _range } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { ParseResult } from 'papaparse';
import Papa from 'papaparse';
import { IRootState } from 'redux/reducer';
import {
  IHourlyResultsMetadata,
  IHourlyResultsPlot,
  IHourlyResultsYearlyData,
  IMonthlyResultsData,
  IMonthlyResultsTemplate,
  IVariantOptionsByObat,
} from 'redux/restResources/detail/simulationGroup/reducer';
import { selectRouteSimulationGroupTag } from 'redux/routing/selectors';
import { createSelector } from 'reselect';
import { ISimulationListed, ISimulationRetrieved } from 'types/SimulationGroup/Simulation';
import { ISimulationConfigOption, ISimulationGroupConfigOptions } from 'types/SimulationGroup/SimulationGroup';
import { isCsvEmpty } from 'utils/functions/csv/isCsvEmpty';
import { ISelectOption } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { IMonthlyResultsSection, IResultsView, rv } from 'utils/strings/results';

interface ISimulationsListByStatus {
  success: ISimulationListed[];
  empty: ISimulationListed[];
  failed: ISimulationListed[];
  server_error: ISimulationListed[];
  pending: ISimulationListed[];
  running: ISimulationListed[];
}

export const selectIsSimulationInProgress = (rootState: IRootState): boolean =>
  rootState.simulationGroup.isSimulationInProgress;
export const selectIsLogsScrollEnabled = (rootState: IRootState): boolean =>
  rootState.simulationGroup.isLogsScrollEnabled;
export const selectSimulationsList = (rootState: IRootState): ISimulationListed[] =>
  rootState.simulationGroup.simulationsList;
export const selectConfigOptions = (rootState: IRootState): ISimulationGroupConfigOptions =>
  rootState.simulationGroup.configOptions;
export const selectVariantOptionsByObat = (rootState: IRootState): IVariantOptionsByObat =>
  rootState.simulationGroup.variantOptionsByObat;

export const selectSimulationGroupConfigOptions = createSelector(
  selectConfigOptions,
  (
    configOptions: ISimulationGroupConfigOptions
  ): {
    years: ISelectOption[];
    obats: ISelectOption[];
    weathers: Array<ISelectOption & { start?: string; end?: string }>;
    geometries: ISelectOption[];
  } => {
    const years = _map(_range(1991, 2036), (val: number) => ({
      label: val.toString(),
      value: val.toString(),
    }));
    const obats = _map(configOptions.obats, (obat: ISimulationConfigOption) => ({
      value: obat.id,
      label: obat.name,
    }));
    const weathers = _map(configOptions.weathers, (weather: ISimulationConfigOption) => ({
      value: weather.id,
      label: weather.name,
      start: weather.start,
      end: weather.end,
    }));
    const geometries = _map(configOptions.geometries, (geometry: ISimulationConfigOption) => ({
      value: geometry.id,
      label: geometry.name,
    }));

    return { years, obats, weathers, geometries };
  }
);

// export const selectVariantRefOptions = createSelector(
//   selectVariantOptions,
//   (variantOptions: IVariant[]): string[] => _map(variantOptions, 'ref')
// );

export const selectSimulationsListByStatus = createSelector(
  selectSimulationsList,
  (simulationsList: ISimulationListed[]): ISimulationsListByStatus => {
    return _reduce(
      simulationsList,
      (_byStatus: ISimulationsListByStatus, simulation: ISimulationListed) => {
        const { status } = simulation;

        return { ..._byStatus, [status]: [..._get(_byStatus, status), simulation] };
      },
      {
        success: [],
        empty: [],
        failed: [],
        server_error: [],
        pending: [],
        running: [],
      }
    );
  }
);

export const selectIsAllSimulationsEmpty = createSelector(
  [selectSimulationsList, selectSimulationsListByStatus],
  (simulationsList: ISimulationListed[], simulationsListByStatus: ISimulationsListByStatus): boolean => {
    return _size(simulationsList) === _size(simulationsListByStatus.empty);
  }
);

/* SIMULATION */

export const selectSimulation = (rootState: IRootState): ISimulationListed | ISimulationRetrieved | undefined => {
  const routeSimulationGroupTag = selectRouteSimulationGroupTag(rootState);
  const { simulation } = rootState.simulationGroup;

  /* security check simulation group to be sure we are not mixing simulations */

  return simulation && routeSimulationGroupTag && simulation.simulation_group_id === routeSimulationGroupTag.resourceId
    ? simulation
    : undefined;
};

export const selectSimulationLogs = (rootState: IRootState): string | undefined => {
  const simulation = selectSimulation(rootState);
  if (simulation && _has(simulation, 'logs')) {
    return simulation.logs;
  }
};

/* MONTHLY RESULTS */

export const selectMonthlyResultsTemplate = (rootState: IRootState): IMonthlyResultsTemplate =>
  rootState.simulationGroup.monthlyResults.template;

export const selectMonthlyResultsForcePivotUpdate = (rootState: IRootState): boolean =>
  rootState.simulationGroup.monthlyResults.forcePivotUpdate;

export const selectMonthlyResultsSection = (rootState: IRootState): IMonthlyResultsSection =>
  rootState.simulationGroup.monthlyResults.section;

export const selectMonthlyResultsMode = (rootState: IRootState): IResultsView =>
  rootState.simulationGroup.monthlyResults.resultsMode;

export const selectMonthlyResultsData = (rootState: IRootState): IMonthlyResultsData =>
  rootState.simulationGroup.monthlyResults.data;

export const selectMonthlyResultsSectionTabs = (rootState: IRootState): { [section: string]: string } =>
  rootState.simulationGroup.monthlyResults.sectionTabs;

export const selectMonthlyResultsSectionDataKey = createSelector(
  [selectMonthlyResultsMode, selectSimulation],
  (resultsMode: IResultsView, simulation: ISimulationListed | undefined): string /* simulationId | aggregate */ => {
    return resultsMode === rv.bySimulation && simulation ? simulation.id : rv.aggregate;
  }
);

export const selectMonthlyResultsSectionData = createSelector(
  [selectMonthlyResultsData, selectMonthlyResultsSection, selectMonthlyResultsSectionDataKey],
  (
    data: IMonthlyResultsData,
    section: IMonthlyResultsSection,
    sectionDataKey: string
  ): ParseResult['data'] | undefined => {
    const simulationData = data[sectionDataKey];

    const sectionDataCsv = !!simulationData && simulationData[section];

    if (sectionDataCsv && !isCsvEmpty(sectionDataCsv)) {
      return Papa.parse(sectionDataCsv, { header: true, dynamicTyping: true }).data;
    }
  }
);

export const selectMonthlyResultsSectionDataCsv = createSelector(
  [selectMonthlyResultsData, selectMonthlyResultsSection, selectMonthlyResultsSectionDataKey],
  (data: IMonthlyResultsData, section: IMonthlyResultsSection, sectionDataKey: string): string | undefined => {
    const simulationData = data[sectionDataKey];

    const sectionDataCsv = !!simulationData && simulationData[section];

    if (sectionDataCsv && !isCsvEmpty(sectionDataCsv)) {
      return sectionDataCsv;
    }
  }
);

/* HOURLY RESULTS */

export const selectHourlyResultsYearlyData = (rootState: IRootState): IHourlyResultsYearlyData =>
  rootState.simulationGroup.hourlyResults.yearsData;

export const selectHourlyResultsPlot = (rootState: IRootState): IHourlyResultsPlot =>
  rootState.simulationGroup.hourlyResults.plot;

export const selectHourlyResultsMetadata = (rootState: IRootState): IHourlyResultsMetadata =>
  rootState.simulationGroup.hourlyResults.metadata;

export const selectIsDownloadingHourlyResults = (rootState: IRootState): boolean =>
  rootState.simulationGroup.hourlyResults.isDownloading;

export const selectHourlyResultsMode = (rootState: IRootState): IResultsView =>
  rootState.simulationGroup.hourlyResults.resultsMode;
