import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { openDeletionModalAction } from 'redux/modals/deletionModal/actions';
import { openObatResourceCommitmentsModalAction } from 'redux/modals/obatResourceCommitmentsModal/actions';
import { IRootState } from 'redux/reducer';
import { selectIsSeatMissing, selectIsWritePermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { selectIsVariantContext } from 'redux/variantContext/selectors';
import { IObatResource } from 'types/Obat/ObatResource';
import { isCommittedForDelete } from 'utils/functions/obatResources/commitments/isCommitted';
import { ICardsTableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { fk } from 'utils/strings/formKeys';
import { IObatResourceClass } from 'utils/strings/resourceClasses';
import { rrc } from 'utils/strings/resourceClasses';

import { ObatCard as Component } from './ObatCard.component';

interface IOwnProps {
  cardColumnsParams: ICardsTableColumnParams[];
  isExpanded?: boolean;
  leftColor?: string;
  obatResource: IObatResource;
  page: string;
  renderCardDetail?: (obatResource: IObatResource) => ReactNode;
  renderColumnData: (obatResource: IObatResource, columnId: string) => ReactNode;
  resourceClass: IObatResourceClass;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  return {
    routeObat: selectRouteResource(rrc.obat)(rootState),
    obatResourceToEdit: selectFormInitialResourceFromTable(ownProps.resourceClass)(rootState),
    isVariantContext: selectIsVariantContext(rootState),
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
  };
};

const mapDispatchToProps = {
  openFormDrawer: openFormDrawerSagaAction,
  openDeletionModal: openDeletionModalAction,
  openCommitmentsModal: openObatResourceCommitmentsModalAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    openEditFormDrawer: () => {
      dispatchProps.openFormDrawer(fk.obatResource, {
        resourceId: ownProps.obatResource.id,
        resourceClass: ownProps.resourceClass,
      });
    },
    openCopyFormDrawer: () => {
      dispatchProps.openFormDrawer(fk.copy, {
        resourceId: ownProps.obatResource.id,
        resourceClass: ownProps.resourceClass,
      });
    },
    openDeletionModal: () => {
      if (!isCommittedForDelete(ownProps.obatResource)) {
        dispatchProps.openDeletionModal(ownProps.obatResource.id, ownProps.resourceClass);
      }
    },
    openCommitmentsModal: () => {
      dispatchProps.openCommitmentsModal(ownProps.obatResource);
    },
  });

export const ObatCard = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
