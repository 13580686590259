import Grid from '@material-ui/core/Grid';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { getYupNumberValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IEfEpRequestParams } from 'types/Obat/EfEp';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './EfEpForm.container';

export interface IEfEpFormValues {
  energy_coefficients_electricity: number;
  energy_coefficients_gas: number;
  energy_coefficients_heating_district: number;
  energy_coefficients_cooling_district: number;
  energy_coefficients_biomass: number;
  energy_coefficients_fuel_oil: number;
  energy_co2_content_electricity: number;
  energy_co2_content_gas: number;
  energy_co2_content_heating_district: number;
  energy_co2_content_cooling_district: number;
  energy_co2_content_biomass: number;
  energy_co2_content_fuel_oil: number;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IEfEpFormValues, IEfEpRequestParams>();

export class EfEpForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { efEpToEdit } = this.props;
    let title = '';

    if (efEpToEdit) {
      title = efEpToEdit.ref;
    }

    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.ef_ep}
        subFormResources={[orc.ef_ep]}
        title={$t(title)}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IEfEpFormValues>): ReactNode => {
      return (
        <>
          <Section title={$t('primaryEnergyFactor')} marginTop={20}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_coefficients_electricity'}
                  formikProps={formikProps}
                  label={$t('electricity')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_coefficients_gas'}
                  formikProps={formikProps}
                  label={$t('gas')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_coefficients_heating_district'}
                  formikProps={formikProps}
                  label={$t('heatingDistrict')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_coefficients_cooling_district'}
                  formikProps={formikProps}
                  label={$t('coolingDistrict')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_coefficients_biomass'}
                  formikProps={formikProps}
                  label={$t('biomass')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_coefficients_fuel_oil'}
                  formikProps={formikProps}
                  label={$t('fuelOil')}
                  required
                />
              </Grid>
            </Grid>
          </Section>
          <Section title={$t('co2EmissionFactorKgCo2KWh')} marginTop={20}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_co2_content_electricity'}
                  formikProps={formikProps}
                  label={$t('electricity')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_co2_content_gas'}
                  formikProps={formikProps}
                  label={$t('gas')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_co2_content_heating_district'}
                  formikProps={formikProps}
                  label={$t('heatingDistrict')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_co2_content_cooling_district'}
                  formikProps={formikProps}
                  label={$t('coolingDistrict')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_co2_content_biomass'}
                  formikProps={formikProps}
                  label={$t('biomass')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'energy_co2_content_fuel_oil'}
                  formikProps={formikProps}
                  label={$t('fuelOil')}
                  required
                />
              </Grid>
            </Grid>
          </Section>
        </>
      );
    };
  };

  private getInitialFormValues = (): IEfEpFormValues => {
    const { efEpToEdit } = this.props;

    return {
      energy_coefficients_electricity: setFormInitialValue(efEpToEdit, 'energy_coefficients_electricity'),
      energy_coefficients_gas: setFormInitialValue(efEpToEdit, 'energy_coefficients_gas'),
      energy_coefficients_heating_district: setFormInitialValue(efEpToEdit, 'energy_coefficients_heating_district'),
      energy_coefficients_cooling_district: setFormInitialValue(efEpToEdit, 'energy_coefficients_cooling_district'),
      energy_coefficients_biomass: setFormInitialValue(efEpToEdit, 'energy_coefficients_biomass'),
      energy_coefficients_fuel_oil: setFormInitialValue(efEpToEdit, 'energy_coefficients_fuel_oil'),
      energy_co2_content_electricity: setFormInitialValue(efEpToEdit, 'energy_co2_content_electricity'),
      energy_co2_content_gas: setFormInitialValue(efEpToEdit, 'energy_co2_content_gas'),
      energy_co2_content_heating_district: setFormInitialValue(efEpToEdit, 'energy_co2_content_heating_district'),
      energy_co2_content_cooling_district: setFormInitialValue(efEpToEdit, 'energy_co2_content_cooling_district'),
      energy_co2_content_biomass: setFormInitialValue(efEpToEdit, 'energy_co2_content_biomass'),
      energy_co2_content_fuel_oil: setFormInitialValue(efEpToEdit, 'energy_co2_content_fuel_oil'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IEfEpFormValues> => {
    return yup.object().shape({
      energy_coefficients_electricity: getYupNumberValidation(true),
      energy_coefficients_gas: getYupNumberValidation(true),
      energy_coefficients_heating_district: getYupNumberValidation(true),
      energy_coefficients_cooling_district: getYupNumberValidation(true),
      energy_coefficients_biomass: getYupNumberValidation(true),
      energy_coefficients_fuel_oil: getYupNumberValidation(true),
      energy_co2_content_electricity: getYupNumberValidation(true),
      energy_co2_content_gas: getYupNumberValidation(true),
      energy_co2_content_heating_district: getYupNumberValidation(true),
      energy_co2_content_cooling_district: getYupNumberValidation(true),
      energy_co2_content_biomass: getYupNumberValidation(true),
      energy_co2_content_fuel_oil: getYupNumberValidation(true),
    }) as yup.ObjectSchema<IEfEpFormValues>;
  };
}
