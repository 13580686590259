import { ModalFrame } from 'components/Modals/ModalFrame';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { $tcc } from 'utils/functions/translate';

import { IProps } from './ObatResourceCommitmentsModal.container';

export class ObatResourceCommitmentsModal extends PureComponent<IProps> {
  public render = (): ReactNode => {
    const { isOpen, commitments, commitmentTitle, closeObatResourceCommitmentsModal } = this.props;
    const Content = (
      <CommitmentsDiv>
        {_map(
          commitments,
          (refs: string[], resourceClass: string): ReactNode => {
            return (
              <div key={resourceClass}>
                <ResourceDiv>{$tcc(resourceClass)} :</ResourceDiv>
                <RefsDiv>
                  {_map(
                    refs,
                    (ref: string): ReactNode => {
                      return <RefDiv key={ref}>"{ref}"</RefDiv>;
                    }
                  )}
                </RefsDiv>
              </div>
            );
          }
        )}
      </CommitmentsDiv>
    );

    return (
      <ModalFrame
        isOpen={isOpen}
        onClose={closeObatResourceCommitmentsModal}
        maxWidth={'lg'}
        Title={commitmentTitle}
        Content={Content}
      />
    );
  };
}

const CommitmentsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const ResourceDiv = styled.div`
  font-size: 110%;
  margin-top: 4px;
  margin-left: 12px;
`;

const RefsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const RefDiv = styled.div`
  margin-top: 4px;
  margin-left: 32px;
`;
