import Grid from '@material-ui/core/Grid';
import { FormikSwitchInput } from 'components/Forms/Inputs/BooleanInputs/FormikSwitchInput';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getRestResourceFormDrawer } from 'components/Forms/RestResourceFormDrawer';
import { FormikProps } from 'formik';
import { _omit } from 'libs/lodash';
import { getYupBooleanValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IObatRequestParams } from 'types/Obat/Obat';
import { fdw } from 'utils/configs/drawerWidths';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './ObatForm.container';

export interface IObatFormValues {
  project: string;
  name: string;
  comment: string;
  fromTemplate: boolean;
  template_category: string;
  template_use: string;
  template_age: string;
}

const RestResourceFormDrawer = getRestResourceFormDrawer<IObatFormValues, IObatRequestParams>();

export class ObatForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public componentDidMount = () => {
    const { fetchObatsTemplates } = this.props;
    fetchObatsTemplates();
  };

  public render(): ReactNode {
    return (
      <RestResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.small}
        resourceClass={rrc.obat}
        getRequestParams={this.getRequestParams}
        getCreationSuccessLandingPageUrl={this.props.getCreationSuccessLandingPageUrl}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IObatFormValues>): ReactNode => {
      const { obatToEdit, templateUseOptions, selectTemplateAgeOptions } = this.props;
      const { template_use, fromTemplate } = formikProps.values;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput field={'name'} formikProps={formikProps} required inputRef={this.formFocusRef} />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput field={'comment'} formikProps={formikProps} multiline />
          </Grid>
          {!obatToEdit && (
            <>
              <Grid item xs={12}>
                <FormikSwitchInput field={'fromTemplate'} formikProps={formikProps} />
              </Grid>
              {this.isTemplateSectionDisplayed(fromTemplate) && (
                <>
                  <Grid item xs={12}>
                    <FormikDropdownInput
                      field={'template_use'}
                      formikProps={formikProps}
                      required
                      options={templateUseOptions}
                      label={$t('buildingMainUse')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDropdownInput
                      field={'template_age'}
                      formikProps={formikProps}
                      required
                      options={selectTemplateAgeOptions(template_use)}
                      label={$t('epoch')}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): IObatFormValues => {
    const { obatToEdit, routeProject } = this.props;

    return {
      project: routeProject.id,
      name: setFormInitialValue(obatToEdit, 'name'),
      comment: setFormInitialValue(obatToEdit, 'comment'),
      fromTemplate: true,
      template_category: 'template_obat_use_age',
      template_use: '',
      template_age: '',
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IObatFormValues> => {
    return yup.object().shape({
      name: getYupStringValidation(true),
      comment: getYupStringValidation(),
      fromTemplate: getYupBooleanValidation(),
      template_use: yup.string().when(['fromTemplate'], {
        is: this.isTemplateRequired,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
      template_age: yup.string().when(['fromTemplate'], {
        is: this.isTemplateRequired,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
    }) as yup.ObjectSchema<IObatFormValues>;
  };

  private getRequestParams = (formValues: IObatFormValues): IObatRequestParams => {
    const { obatToEdit } = this.props;
    const { fromTemplate, template_use, template_age } = formValues;

    const initialFormValues = this.getInitialFormValues();
    const filteredFinalFormValues = obatToEdit ? filterEditedValues(initialFormValues, formValues) : formValues;

    let requestParamsFields = { ...filteredFinalFormValues, template_ref: `${template_use}_${template_age}` };

    let paramsToOmit = ['fromTemplate', 'template_use', 'template_age'];
    paramsToOmit = obatToEdit || !fromTemplate ? [...paramsToOmit, 'template_ref', 'template_category'] : paramsToOmit;

    requestParamsFields = { ..._omit(requestParamsFields, paramsToOmit) };
    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams as IObatRequestParams;
  };

  private isTemplateSectionDisplayed = (fromTemplate: IObatFormValues['fromTemplate']): boolean => !!fromTemplate;

  private isTemplateRequired = (fromTemplate: IObatFormValues['fromTemplate']): boolean =>
    !this.props.obatToEdit && this.isTemplateSectionDisplayed(fromTemplate);
}
