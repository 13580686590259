import { connect } from 'react-redux';
import { IEfEp } from 'types/Obat/EfEp';

import { IRootState } from 'redux/reducer';
import { updateProjectionTableSectionAction } from 'redux/restResources/detail/obat/actions';
import { initializeTableAction } from 'redux/table/actions';
import { selectTableResources } from 'redux/table/selectors';
import { EfEpTable as EfEpTableComponent } from './EfEpTable.component';

interface IOwnProps {
  efEp: IEfEp;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IInjectedProps = IStateProps;

export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    tableResources: selectTableResources(rootState)(),
  };
};

const mapDispatchToProps = {
  initializeTable: initializeTableAction,
  updateProjectionSection: updateProjectionTableSectionAction,
};

export const EfEpTable = connect(mapStateToProps, mapDispatchToProps)(EfEpTableComponent);
