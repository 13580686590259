import { connect } from 'react-redux';
import { openPlotsModalAction } from 'redux/modals/plotsModal/actions';
import { IOpening } from 'types/Obat/Opening';

import { OpeningDetail as Component } from './OpeningDetail.component';

interface IOwnProps {
  opening: IOpening;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  openPlotsModal: openPlotsModalAction,
};

export const OpeningDetail = connect(mapStateToProps, mapDispatchToProps)(Component);
