import theme from 'style/theme';
import styled from 'styled-components';

interface ISimpleCardProps {
  bgColor?: string;
  isExpanded?: boolean;
  isHovered?: boolean;
  minHeight?: number;
  borderColor?: string;
}

export const SimpleCard = styled.div`
  border: ${(props: ISimpleCardProps): string => (props.borderColor ? 'solid 1px ' + props.borderColor : '')};
  border-radius: ${theme.borderRadius.small};
  box-shadow: ${theme.shadows.card.standard};
  background-color: ${(props: ISimpleCardProps): string => props.bgColor || 'white'};
  margin: 12px 0;
  padding: 16px;
  min-height: ${(props: ISimpleCardProps & { minHeight?: number }) =>
    props.minHeight ? props.minHeight + 'px' : 'auto'};
`;

export const SubFormCard = styled(SimpleCard)`
  background-color: white;
  padding: 8px;
  box-shadow: ${theme.shadows.card.edited};
`;
