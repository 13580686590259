import { RestResourcesRequests } from 'api/requests/RestResourcesRequests';
import { _has, _size } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  apiRequestCallbackSuccessAction,
  apiRequestSuccessAction,
  startApiRequestAction,
} from 'redux/apiRequests/actions';
import { apiRequestSaga } from 'redux/apiRequests/sagas';
import { selectApiRequestErrors } from 'redux/apiRequests/selectors';
import {
  downloadMetadataFromAzureSaga,
  downloadSeriesYearDataFromAzureSaga,
  uploadFileToAzureSaga,
} from 'redux/azure/sagas';
import { closeFormDrawerAction } from 'redux/form/actions';
import { selectMyLanguage } from 'redux/restResources/detail/me/selectors';
import { IDegreeDaysData } from 'redux/restResources/detail/weather/reducer';
import {
  selectDegreeDaysParams,
  selectRouteWeatherHistoricalYearRange,
  selectWeatherMeasuresMetadata,
  selectWeatherMeasuresYearsData,
} from 'redux/restResources/detail/weather/selectors';
import { editRestResourceSaga, retrieveRestResourceSaga } from 'redux/restResources/generic/sagas';
import { fetchSingleRouteResourceSaga } from 'redux/routing/sagas';
import { selectRouteResource, selectRouteWeatherTag } from 'redux/routing/selectors';
import { clearTaskAction, updateTaskAction } from 'redux/tasks/actions';
import { refreshTaskSaga } from 'redux/tasks/sagas';
import { selectTask } from 'redux/tasks/selectors';
import { weatherMenuPages } from 'routing/routes';
import { downloadFileFromUrl } from 'utils/functions/downloadFile/downloadFileFromUrl';
import { getCsvParams } from 'utils/functions/downloadFile/getCsvParams';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { getExportFileName } from 'utils/functions/getExportFileName';
import { getTaskKey } from 'utils/functions/getTaskKey';
import { sortListFromModel } from 'utils/functions/sortListFromModel';
import { $t } from 'utils/functions/translate';
import { dr } from 'utils/strings/detailRoutes';
import { hm } from 'utils/strings/httpMethods';
import { ps } from 'utils/strings/progressStatus';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';
import { weatherMeasuresOrder } from './../../../../utils/configs/weatherMeasures';
import { selectAzureError } from './../../../azure/selectors';

import {
  DOWNLOAD_WEATHER_SERIES_HOURLY_DATA_SAGA_ACTION,
  EDIT_WEATHER_CONFIG_SAGA_ACTION,
  EXPORT_DEGREE_DAYS_FILE_SAGA_ACTION,
  EXPORT_WEATHER_FILE_SAGA_ACTION,
  FETCH_DEGREE_DAYS_YEAR_DATA_SAGA_ACTION,
  FETCH_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_SAGA_ACTION,
  FETCH_WEATHERS_TEMPLATES_SAGA_ACTION,
  IDownloadWeatherSeriesHourlyDataSagaAction,
  IEditWeatherConfigSagaAction,
  IExportWeatherFileSagaAction,
  IFetchDegreeDaysYearDataSagaAction,
  IFetchOpenergyHistoricalWeatherConfigOptionsSagaAction,
  IImportWeatherDataSagaAction,
  IMPORT_WEATHER_DATA_SAGA_ACTION,
  REFRESH_IMPORT_WEATHER_TASK_SAGA_ACTION,
  updateDegreeDaysDataAction,
  updateDegreeDaysParamsAction,
  updateOpenergyWeatherSeriesConfigOptionsAction,
  updateWeatherImportProgressAction,
  updateWeatherMeasuresMetadataAction,
  updateWeatherMeasuresPlottedAction,
  updateWeatherMeasuresYearsDataAction,
  updateWeathersTemplatesAction,
} from './actions';

export function* fetchOpenergyHistoricalWeatherConfigOptionsSaga(
  payload: IFetchOpenergyHistoricalWeatherConfigOptionsSagaAction['payload']
): SagaIterator {
  let { params } = payload;
  if (!params) {
    params = {
      dataSource: 'openergy',
    };
  }

  const routeWeather = yield select(selectRouteResource(rrc.weather));
  const apiRequestKey = getDetailApiRequestKey(dra.fetchOpenergyHistoricalWeatherSeriesConfigOptions, routeWeather.id);
  const apiUrlParams = {
    resourceClass: rrc.openergy_historical_weather_series,
    detailRoute: dr.config_options,
  };

  const requestParams = {
    data_source: params.dataSource,
  };

  const configOptions = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get,
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    yield put(updateOpenergyWeatherSeriesConfigOptionsAction(configOptions));
  }
}

export function* fetchOpenergyHistoricalWeatherConfigOptionsActionSaga(
  action: IFetchOpenergyHistoricalWeatherConfigOptionsSagaAction
): SagaIterator {
  yield call(fetchOpenergyHistoricalWeatherConfigOptionsSaga, action.payload);
}

export function* importWeatherDataSaga(payload: IImportWeatherDataSagaAction['payload']): SagaIterator {
  const { importFields, fileContentBlob } = payload;

  yield put(updateWeatherImportProgressAction(ps.uploadingFile));

  /* 1. Upload File To Blob */
  const routeWeather = yield select(selectRouteResource(rrc.weather));
  const weatherSeriesTag = yield select(selectRouteWeatherTag);
  const uploadApiRequestKey = getDetailApiRequestKey(dra.uploadWeatherData, routeWeather.id);
  const uploadApiUrlParams = {
    ...weatherSeriesTag,
    detailRoute: dr.upload_url,
  };

  const blobUrlCallResult = yield call(
    apiRequestSaga,
    uploadApiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    uploadApiUrlParams,
    hm.get
  );

  const uploadApiRequestErrors = yield select(selectApiRequestErrors(uploadApiRequestKey));
  if (!uploadApiRequestErrors) {
    const blobUrl = blobUrlCallResult.blob_url;
    yield call(uploadFileToAzureSaga, blobUrl, fileContentBlob);
    const azureError = yield select(selectAzureError);

    if (!azureError) {
      /* 2. Import Blob Content To Generic Weather Hourly Outputs*/
      yield put(updateWeatherImportProgressAction(ps.importingWeather));
      const importApiRequestKey = getDetailApiRequestKey(dra.importWeatherData, routeWeather.id);
      const importApiUrlParams = {
        ...weatherSeriesTag,
        detailRoute: dr.import_data,
      };
      const callResult = yield call(
        apiRequestSaga,
        importApiRequestKey,
        true,
        RestResourcesRequests.detailRoute,
        importApiUrlParams,
        hm.patch,
        {},
        importFields
      );

      const importApiRequestErrors = yield select(selectApiRequestErrors(importApiRequestKey));
      if (!importApiRequestErrors) {
        yield put(closeFormDrawerAction());
        const taskId = callResult.user_task;
        const taskKey = getTaskKey(dra.importWeatherData, routeWeather.id);
        const task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
        yield put(updateTaskAction(task, taskKey));
        yield call(refreshImportWeatherTaskSaga);
      }
    }
  }

  yield put(updateWeatherImportProgressAction(ps.finished));
}

export function* importWeatherDataActionSaga(action: IImportWeatherDataSagaAction): SagaIterator {
  yield call(importWeatherDataSaga, action.payload);
}

export function* refreshImportWeatherTaskSaga(): SagaIterator {
  const routeWeather = yield select(selectRouteResource(rrc.weather));
  const taskKey = getTaskKey(dra.importWeatherData, routeWeather.id);
  const task = yield select(selectTask(taskKey));

  if (task) {
    yield put(updateWeatherImportProgressAction(ps.importingWeather));
    const finishedTask = yield call(refreshTaskSaga, {
      taskKey,
      routeResourceToUpdateClass: rrc.weather,
      pagesWhereToRefresh: [weatherMenuPages.weatherData],
    });
    if (finishedTask && finishedTask.status_code === 200) {
      yield put(updateWeatherImportProgressAction(ps.fetchingData));
      yield call(downloadWeatherSeriesHourlyDataSaga, {});
      const params = yield select(selectDegreeDaysParams);
      yield call(fetchDegreeDaysYearDataSaga, { params });
    }
  }

  yield put(clearTaskAction(taskKey));
  yield put(updateWeatherImportProgressAction(ps.finished));
}

export function* refreshImportWeatherTaskActionSaga(): SagaIterator {
  yield call(refreshImportWeatherTaskSaga);
}

export function* fetchWeatherSeriesContainerAccessSaga(): SagaIterator {
  const routeWeather = yield select(selectRouteResource(rrc.weather));
  const weatherSeriesTag = yield select(selectRouteWeatherTag);
  const apiRequestKey = getDetailApiRequestKey(dra.fetchWeatherSeries, routeWeather.id);
  const apiUrlParams = {
    ...weatherSeriesTag,
    detailRoute: dr.generic_viz,
  };

  const containerAccess = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    return {
      containerUrl: containerAccess.container_url,
      sasToken: containerAccess.sas_token,
    };
  }
}

export function* downloadWeatherSeriesHourlyDataSaga(
  payload: IDownloadWeatherSeriesHourlyDataSagaAction['payload']
): SagaIterator {
  const { measureToPlot } = payload;

  const routeWeather = yield select(selectRouteResource(rrc.weather));

  /* 1. Get container access */
  const containerAccess = yield call(fetchWeatherSeriesContainerAccessSaga);
  const { containerUrl, sasToken } = containerAccess;

  /* 2. Get data */
  let metadata = yield select(selectWeatherMeasuresMetadata);
  if (_size(metadata.series) === 0) {
    const rawMetadata = yield call(downloadMetadataFromAzureSaga, containerUrl, sasToken);
    /** need to reorder series manually because backend sends the list randomly sorted */
    metadata = { ...rawMetadata, series: sortListFromModel(weatherMeasuresOrder, rawMetadata.series) };
    yield put(updateWeatherMeasuresMetadataAction(metadata));
  }

  if (measureToPlot) {
    const yearsData = yield select(selectWeatherMeasuresYearsData);

    if (!_has(yearsData, `${measureToPlot.year}.index`)) {
      const yearDatetimeIndex = yield call(
        downloadSeriesYearDataFromAzureSaga,
        containerUrl,
        sasToken,
        'index',
        measureToPlot.year
      );
      yield put(updateWeatherMeasuresYearsDataAction('index', measureToPlot.year, yearDatetimeIndex));
    }
    if (!_has(yearsData, `${measureToPlot.year}.${measureToPlot.seriesId}`)) {
      const measureSeriesData = yield call(
        downloadSeriesYearDataFromAzureSaga,
        containerUrl,
        sasToken,
        measureToPlot.seriesId,
        measureToPlot.year
      );
      yield put(updateWeatherMeasuresYearsDataAction(measureToPlot.seriesId, measureToPlot.year, measureSeriesData));
    }

    yield put(updateWeatherMeasuresPlottedAction(measureToPlot));
  } else {
    const year = metadata.years ? metadata.years[_size(metadata.years) - 1] : 'generic';

    const yearDatetimeIndex = yield call(downloadSeriesYearDataFromAzureSaga, containerUrl, sasToken, 'index', year);
    yield put(updateWeatherMeasuresYearsDataAction('index', year, yearDatetimeIndex));

    const seriesId = metadata.series[0];

    const seriesYearData = yield call(downloadSeriesYearDataFromAzureSaga, containerUrl, sasToken, seriesId, year);

    yield put(
      updateWeatherMeasuresPlottedAction({
        seriesId,
        year,
      })
    );

    yield put(updateWeatherMeasuresYearsDataAction(seriesId, year, seriesYearData));
  }

  /* finish callback */
  const apiRequestKey = getDetailApiRequestKey(dra.fetchWeatherSeries, routeWeather.id);
  yield put(apiRequestCallbackSuccessAction(apiRequestKey));
}

export function* downloadWeatherHourlyDataActionSaga(action: IDownloadWeatherSeriesHourlyDataSagaAction): SagaIterator {
  yield call(downloadWeatherSeriesHourlyDataSaga, action.payload);
}

export function* exportWeatherFileSaga(payload: IExportWeatherFileSagaAction['payload']): SagaIterator {
  const { exportFormat } = payload;

  const routeWeather = yield select(selectRouteResource(rrc.weather));
  const weatherSeriesTag = yield select(selectRouteWeatherTag);
  const apiRequestKey = getDetailApiRequestKey(dra.exportWeatherFile, routeWeather.id);
  const apiUrlParams = {
    ...weatherSeriesTag,
    detailRoute: dr.export_data,
  };

  const myLanguage = yield select(selectMyLanguage);

  const requestParams = {
    export_format: exportFormat,
    file_name: getExportFileName(routeWeather.name, exportFormat),
    ...getCsvParams(myLanguage),
  };

  const callResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get,
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));

  if (!apiRequestErrors) {
    const taskId = callResult.user_task;
    const taskKey = getTaskKey(dra.exportWeatherFile, routeWeather.id);
    const task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
    yield put(updateTaskAction(task, taskKey));
    const finishedTask = yield call(refreshTaskSaga, {
      taskKey,
      routeResourceToUpdateClass: rrc.weather,
    });
    if (finishedTask && finishedTask.status_code === 200) {
      const fileUrl = finishedTask.data.blob_url;
      yield call(downloadFileFromUrl, fileUrl);
    }
  }
}

export function* exportWeatherFileActionSaga(action: IExportWeatherFileSagaAction): SagaIterator {
  yield call(exportWeatherFileSaga, action.payload);
}

/***********************************************************************************************************************
 DEGREE DAYS
 **********************************************************************************************************************/

export function* fetchDegreeDaysYearDataSaga(payload: IFetchDegreeDaysYearDataSagaAction['payload']): SagaIterator {
  let { params } = payload;

  const routeWeather = yield select(selectRouteResource(rrc.weather));

  if (!params) {
    params = {
      coolingBase: '23',
      heatingBase: '18',
    };
    if (!routeWeather.generic_weather_series) {
      const yearRange = yield select(selectRouteWeatherHistoricalYearRange);
      params = { ...params, year: yearRange[_size(yearRange) - 1] };
    }
  }
  yield put(updateDegreeDaysParamsAction(params));

  const weatherSeriesTag = yield select(selectRouteWeatherTag);
  const apiRequestKey = getDetailApiRequestKey(dra.fetchDegreeDaysData, routeWeather.id);
  const apiUrlParams = {
    ...weatherSeriesTag,
    detailRoute: dr.dju,
  };

  const requestParams = {
    cooling_base: params.coolingBase,
    heating_base: params.heatingBase,
    year: params.year,
  };

  const rawDegreeDaysData = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get,
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const degreeDaysData: IDegreeDaysData = {
      cdd: rawDegreeDaysData.djr,
      hdd: rawDegreeDaysData.djc,
      months: rawDegreeDaysData.index,
      sumCdd: rawDegreeDaysData.sum_djr,
      sumHdd: rawDegreeDaysData.sum_djc,
    };

    yield put(updateDegreeDaysDataAction(degreeDaysData));

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }
}

export function* fetchDegreeDaysDataActionSaga(action: IFetchDegreeDaysYearDataSagaAction): SagaIterator {
  yield call(fetchDegreeDaysYearDataSaga, action.payload);
}

export function* exportDegreeDaysFileSaga(): SagaIterator {
  const routeWeather = yield select(selectRouteResource(rrc.weather));
  const apiRequestKey = getDetailApiRequestKey(dra.exportDegreeDaysFile, routeWeather.id);

  const apiUrlParams = {
    resourceClass: rrc.weather,
    resourceId: routeWeather.id,
    detailRoute: dr.export_dju,
  };

  const degreeDaysParams = yield select(selectDegreeDaysParams);
  const { coolingBase, heatingBase } = degreeDaysParams;
  const fileName = getExportFileName(`${routeWeather.name}_${$t('dju')}`, 'csv');
  const myLanguage = yield select(selectMyLanguage);

  const requestParams = {
    cooling_base: coolingBase,
    heating_base: heatingBase,
    file_name: fileName,
    ...getCsvParams(myLanguage),
  };

  const callResult = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get,
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const taskId = callResult.user_task;
    const taskKey = getTaskKey(dra.exportDegreeDaysFile, routeWeather.id);
    const task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
    yield put(updateTaskAction(task, taskKey));
    const finishedTask = yield call(refreshTaskSaga, { taskKey });
    if (finishedTask && finishedTask.status_code === 200) {
      const fileUrl = finishedTask.data.blob_url;
      yield call(downloadFileFromUrl, fileUrl);
    }
  }
}

export function* exportDegreeDaysFileActionSaga(): SagaIterator {
  yield call(exportDegreeDaysFileSaga);
}

/***********************************************************************************************************************
 CONFIG
 **********************************************************************************************************************/

export function* editWeatherConfigSaga(payload: IEditWeatherConfigSagaAction['payload']): SagaIterator {
  const { requestParams } = payload;
  const { historical_weather_series_id, ...weatherRequestParams } = requestParams;

  const routeWeather = yield select(selectRouteResource(rrc.weather));
  const weatherSeriesTag = yield select(selectRouteWeatherTag);

  /* fake apiKey only to track loading */
  const configWeatherLoadingKey = getDetailApiRequestKey(dra.configWeather, routeWeather.id);
  yield put(startApiRequestAction(configWeatherLoadingKey));

  if (routeWeather.openergy_historical_weather_series && historical_weather_series_id) {
    yield call(editRestResourceSaga, {
      ...weatherSeriesTag,
      requestParams: { historical_weather_series: historical_weather_series_id },
    });
  }

  if (_size(weatherRequestParams) === 0) {
    /* Auto config in auto mode */
    const apiRequestKey = getDetailApiRequestKey(dra.autoConfigWeather, routeWeather.id);
    const apiUrlParams = {
      ...weatherSeriesTag,
      detailRoute: dr.auto_config_weather,
    };
    yield call(
      apiRequestSaga,
      apiRequestKey,
      false,
      RestResourcesRequests.detailRoute,
      apiUrlParams,
      hm.patch,
      {},
      {},
      'empty'
    );

    const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
    if (!apiRequestErrors) {
      yield call(fetchSingleRouteResourceSaga, rrc.weather);
    }
  } else {
    yield call(editRestResourceSaga, {
      resourceClass: rrc.weather,
      resourceId: routeWeather.id,
      requestParams: weatherRequestParams,
    });
  }

  yield put(apiRequestSuccessAction(configWeatherLoadingKey));
}

export function* editWeatherConfigActionSaga(action: IEditWeatherConfigSagaAction): SagaIterator {
  yield call(editWeatherConfigSaga, action.payload);
}

export function* fetchWeathersTemplatesSaga(): SagaIterator {
  const apiRequestKey = getDetailApiRequestKey(dra.fetchTemplates, 'weathers');
  const apiUrlParams = {
    resourceClass: rrc.weather,
    detailRoute: dr.templates,
  };

  const templates = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.detailRoute,
    apiUrlParams,
    hm.get
  );

  yield put(updateWeathersTemplatesAction(templates));
}

export function* fetchWeathersTemplatesActionSaga(): SagaIterator {
  yield call(fetchWeathersTemplatesSaga);
}

export function* weatherActionsSagas(): Generator {
  yield takeLatest(
    FETCH_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_SAGA_ACTION,
    fetchOpenergyHistoricalWeatherConfigOptionsActionSaga
  );
  yield takeLatest(IMPORT_WEATHER_DATA_SAGA_ACTION, importWeatherDataActionSaga);
  yield takeLatest(REFRESH_IMPORT_WEATHER_TASK_SAGA_ACTION, refreshImportWeatherTaskActionSaga);
  yield takeLatest(DOWNLOAD_WEATHER_SERIES_HOURLY_DATA_SAGA_ACTION, downloadWeatherHourlyDataActionSaga);
  yield takeLatest(FETCH_DEGREE_DAYS_YEAR_DATA_SAGA_ACTION, fetchDegreeDaysDataActionSaga);
  yield takeLatest(EDIT_WEATHER_CONFIG_SAGA_ACTION, editWeatherConfigActionSaga);
  yield takeEvery(EXPORT_WEATHER_FILE_SAGA_ACTION, exportWeatherFileActionSaga);
  yield takeEvery(EXPORT_DEGREE_DAYS_FILE_SAGA_ACTION, exportDegreeDaysFileActionSaga);
  yield takeEvery(FETCH_WEATHERS_TEMPLATES_SAGA_ACTION, fetchWeathersTemplatesActionSaga);
}
