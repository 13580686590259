import { connect } from 'react-redux';
import { closeLogsModalSagaAction } from 'redux/modals/logsModal/actions';
import { selectIsLogsModalOpen } from 'redux/modals/logsModal/selectors';
import { IRootState } from 'redux/reducer';
import { selectSimulation } from 'redux/restResources/detail/simulationGroup/selectors';

import { LogsModal as Component } from './LogsModal.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  return {
    simulation: selectSimulation(rootState),
    isLogsModalOpen: selectIsLogsModalOpen(rootState),
  };
};

const mapDispatchToProps = {
  closeLogsModal: closeLogsModalSagaAction,
};

export const LogsModal = connect(mapStateToProps, mapDispatchToProps)(Component);
