import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { Alert, IAlertProps } from 'components/Alerts/Alert.component';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps extends IAlertProps {
  isOpen: boolean;
}

export class TopSnackbarAlert extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isOpen, ...alertProps } = this.props;

    const anchorOrigin: SnackbarOrigin = {
      vertical: 'top',
      horizontal: 'center',
    };

    return (
      <StyledSnackbar anchorOrigin={anchorOrigin} open={isOpen}>
        <Alert {...alertProps} opacity={alertProps.opacity || 0.5} />
      </StyledSnackbar>
    );
  }
}

const StyledSnackbar = styled(Snackbar)`
  top: 11px;
  left: 50%;
  position: absolute;
` as typeof Snackbar;
