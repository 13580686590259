import { _includes, _reduce } from 'libs/lodash';

export const sortListFromModel = (modelList: string[], listToSort: string[]): string[] => {
  return _reduce(
    modelList,
    (result: string[], item: string) => {
      return _includes(listToSort, item) ? [...result, item] : result;
    },
    []
  );
};
