import Grid from '@material-ui/core/Grid';
import { CardHeader, SimpleCard } from 'components/Cards';
import { LightText } from 'components/Texts';
import React, { PureComponent, ReactNode } from 'react';
import { ISurfaceGroup } from 'types/Obat/SurfaceGroup';
import {
  getFilterInnerZonesFieldLabel,
  getFilterOuterZonesFieldLabel,
  isFilterOuterZonesFieldDisplayed,
  isOrientationFieldsDisplayed,
} from 'utils/functions/obatResources/surfaceGroup/fieldsDisplay';
import { renderTextValueElseDash } from 'utils/functions/renderValue/renderValue';
import { $t } from 'utils/functions/translate';
interface IProps {
  surfaceGroup: ISurfaceGroup;
}

export class SurfaceGroupDetail extends PureComponent<IProps> {
  public render(): ReactNode {
    const {
      surfaceGroup: {
        surface_category,
        filter_geometries,
        filter_surfaces,
        filter_inner_zones,
        filter_outer_zones,
        filter_subsurfaces,
        orientation_direction,
        orientation_tolerance,
      },
    } = this.props;

    return (
      <>
        <SimpleCard>
          <CardHeader>{$t('surfaceFilters')}</CardHeader>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              {$t('geometriesFilter')}:
            </Grid>
            <Grid item xs={9}>
              <LightText>{renderTextValueElseDash(filter_geometries)}</LightText>
            </Grid>

            <Grid item xs={3}>
              {$t('surfacesFilter')}:
            </Grid>
            <Grid item xs={9}>
              <LightText>{renderTextValueElseDash(filter_surfaces)}</LightText>
            </Grid>

            <Grid item xs={3}>
              {getFilterInnerZonesFieldLabel(surface_category)}:
            </Grid>
            <Grid item xs={9}>
              <LightText>{renderTextValueElseDash(filter_inner_zones)}</LightText>
            </Grid>

            {isFilterOuterZonesFieldDisplayed(surface_category) && (
              <>
                <Grid item xs={3}>
                  {getFilterOuterZonesFieldLabel(surface_category)}:
                </Grid>
                <Grid item xs={9}>
                  <LightText>{renderTextValueElseDash(filter_outer_zones)}</LightText>
                </Grid>
              </>
            )}

            {isOrientationFieldsDisplayed(surface_category) && (
              <>
                <Grid item xs={3}>
                  {$t('orientationDirectionWithUnit')}:
                </Grid>
                <Grid item xs={9}>
                  <LightText>{renderTextValueElseDash(orientation_direction)}</LightText>
                </Grid>

                <Grid item xs={3}>
                  {$t('orientationToleranceWithUnit')}:
                </Grid>
                <Grid item xs={9}>
                  <LightText>{renderTextValueElseDash(orientation_tolerance)}</LightText>
                </Grid>
              </>
            )}
          </Grid>
        </SimpleCard>

        <SimpleCard>
          <CardHeader>{$t('windowFilters')}</CardHeader>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              {$t('windowsFilter')}:
            </Grid>
            <Grid item xs={9}>
              <LightText>{renderTextValueElseDash(filter_subsurfaces)}</LightText>
            </Grid>
          </Grid>
        </SimpleCard>
      </>
    );
  }
}
