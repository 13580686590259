import { connect } from 'react-redux';
import {
  selectObatContentsFlatOptionsList,
  selectScheduleMethodOptionsFunction,
  selectScheduleSpecificDefaultValuesOptionsFunction,
  selectScheduleUnitOptionsFunction,
} from 'redux/enums/selectors';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { openPlotsModalAction } from 'redux/modals/plotsModal/actions';
import { selectGroupedObatResourcesIdsListFunction } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { ocfe } from 'utils/configs/enums';
import { orc } from 'utils/strings/resourceClasses';

import { ScheduleForm as ScheduleFormComponent } from './ScheduleForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const scheduleToEdit = selectFormInitialResourceFromTable(orc.schedule)(rootState);

  return {
    scheduleToEdit,

    scheduleTypeOptions: selectObatContentsFlatOptionsList(ocfe.schedule_types)(rootState),
    selectMethodOptions: selectScheduleMethodOptionsFunction(rootState),
    selectYearProfilesByTypeOptions: selectGroupedObatResourcesIdsListFunction(orc.year_profile, 'type')(rootState),
    selectUnitOptions: selectScheduleUnitOptionsFunction(rootState),
    selectSpecificDefaultValues: selectScheduleSpecificDefaultValuesOptionsFunction(rootState),
  };
};

const mapDispatchToProps = { openPlotsModal: openPlotsModalAction };

export const ScheduleForm = connect(mapStateToProps, mapDispatchToProps)(ScheduleFormComponent);
