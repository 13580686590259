import { Button } from 'components/Buttons';
import React, { PureComponent, ReactNode } from 'react';
import { X } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';
import { hexToRgba } from 'utils/functions/hexToRgba';
import { IAlertStatus } from 'utils/strings/alertStatus';

export interface IAlertProps {
  opacity?: number;
  status: IAlertStatus;
  onClose?: () => void;
}

export class Alert extends PureComponent<IAlertProps> {
  public render(): ReactNode {
    const { status, onClose, opacity, children } = this.props;

    return (
      <AlertBody status={status} opacity={opacity}>
        {children}
        {!!onClose && (
          <IconContainer>
            <Button
              Icon={<X size={16} />}
              onClick={onClose}
              color={'black'}
              bgColor={theme.colors.transparent}
              noShadow
            />
          </IconContainer>
        )}
      </AlertBody>
    );
  }
}

export const ALERT_HEIGHT = 34;

const IconContainer = styled.div`
  margin-left: 20px;
`;

interface IAlertBodyStyledProps {
  opacity?: number;
  status: IAlertStatus;
}

const AlertBody = styled.div`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props: IAlertBodyStyledProps): string =>
    // @ts-ignore
    hexToRgba(theme.colors[props.status], props.opacity || 1)};
`;
