import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import { clearVariantContextIdSagaAction } from 'redux/variantContext/actions';
import { selectVariantContextId } from 'redux/variantContext/selectors';
import { fk } from 'utils/strings/formKeys';

import { VariantContextButtons as Component } from './VariantContextButtons.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  return {
    variantContextId: selectVariantContextId(rootState),
  };
};

const mapDispatchToProps = {
  openFormDrawer: openFormDrawerSagaAction,
  clearVariantContext: clearVariantContextIdSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: {}) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    openVariantContextForm: () => dispatchProps.openFormDrawer(fk.variantContext),
  });

export const VariantContextButtons = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
