import Grid from '@material-ui/core/Grid';
import { SubFormCard } from 'components/Cards';
import { CloseSubFormButton } from 'components/Forms/Buttons/SubFormButtons';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceForm } from 'components/Forms/ObatResourceForm';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { PureComponent, ReactNode } from 'react';
import { ILayerRequestParams } from 'types/Obat/Layer';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './LayerForm.container';

export interface ILayerFormValues {
  construction: string;
  material: string;
  thickness: number;
}

const ObatResourceForm = getObatResourceForm<ILayerFormValues, ILayerRequestParams>();

export class LayerForm extends PureComponent<IProps> {
  public render(): ReactNode {
    const { layerToEdit, close, titleIndex } = this.props;

    const layerFormTitle = layerToEdit
      ? `${titleIndex}. ${layerToEdit.material_content.ref} : ${layerToEdit.thickness} m`
      : `${$t('layers')} ${titleIndex}`;

    return (
      <SubFormCard>
        {!!close && <CloseSubFormButton onClick={close} />}
        <Section noDivider title={layerFormTitle} marginTop={-10}>
          <ObatResourceForm
            initialObatResource={layerToEdit}
            renderForm={this.renderForm()}
            getInitialFormValues={this.getInitialFormValues}
            getValidationSchema={this.getValidationSchema}
            resourceClass={orc.layer}
          />
        </Section>
      </SubFormCard>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<ILayerFormValues>): ReactNode => {
      const { materialOptions } = this.props;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'material'}
              formikProps={formikProps}
              options={materialOptions}
              label={$t('name')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput field={'thickness'} formikProps={formikProps} required label={$t('thicknessWithUnit')} />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): ILayerFormValues => {
    const { layerToEdit, construction } = this.props;

    return {
      construction: construction.ref,
      thickness: setFormInitialValue(layerToEdit, 'thickness'),
      material: setFormInitialValue(layerToEdit, 'material'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<ILayerFormValues> => {
    return yup.object().shape({
      material: getYupStringValidation(true, true),
      thickness: getYupNumberValidation(true, 0, 1, false),
    }) as yup.ObjectSchema<ILayerFormValues>;
  };
}
