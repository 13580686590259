import { FormControl, FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Alert } from 'components/Alerts';
import { FormikCheckboxInput } from 'components/Forms/Inputs/BooleanInputs/FormikCheckboxInput';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { getRestResourceFormDrawer } from 'components/Forms/RestResourceFormDrawer';
import { FormikProps } from 'formik';
import { getYupBooleanValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { fdw } from 'utils/configs/drawerWidths';
import { formatUserName } from 'utils/functions/formatUserName';
import { cumulatePermissions } from 'utils/functions/forms/cumulatePermissions';
import { renderHighestPremissionValue } from 'utils/functions/permissions/renderHighestPermissionValue';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './ProjectPermissionsForm.container';

interface IProjectPermissionsFormValues {
  project: string;
  user: string;
  can_read: boolean;
  can_write: boolean;
  can_admin: boolean;
}

type IProjectPermissionsRequestParams = IProjectPermissionsFormValues;

const RestResourceFormDrawer = getRestResourceFormDrawer<
  IProjectPermissionsFormValues,
  IProjectPermissionsRequestParams
>();

export class ProjectPermissionsForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public componentDidMount(): void {
    const { fetchUserOrganizationPermissions, routeProject } = this.props;
    fetchUserOrganizationPermissions(routeProject.organization.id);
  }

  public render(): ReactNode {
    const { permissionToEdit } = this.props;

    return (
      <RestResourceFormDrawer
        title={permissionToEdit ? formatUserName(permissionToEdit.user) : undefined}
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.large}
        resourceClass={rrc.user_project_permission}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IProjectPermissionsFormValues>): ReactNode => {
      const { permissionToEdit, userOptions, hasNoInheritedPermission, routeProject } = this.props;

      return (
        <>
          {!permissionToEdit && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikDropdownInput
                  field={'user'}
                  label={$t('userAffiliatedToOrganization', { name: routeProject.organization.name })}
                  formikProps={formikProps}
                  options={userOptions}
                  required
                />
              </Grid>
            </Grid>
          )}
          {this.renderOrganizationPermissionAlert()}
          {formikProps.values.user && (
            <PermissionFormContainer>
              <Grid container spacing={1}>
                <FormControl>
                  <FormLabel>{$t('projectPermission')}</FormLabel>
                  <Grid item xs={12}>
                    <FormikCheckboxInput
                      field={'can_read'}
                      label={$t('canRead')}
                      formikProps={formikProps}
                      onSelect={this.onPermissionSelect(formikProps)}
                      isDisabled={!permissionToEdit || hasNoInheritedPermission}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikCheckboxInput
                      field={'can_write'}
                      label={$t('canWrite')}
                      formikProps={formikProps}
                      onSelect={this.onPermissionSelect(formikProps)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikCheckboxInput
                      field={'can_admin'}
                      label={$t('canAdmin')}
                      formikProps={formikProps}
                      onSelect={this.onPermissionSelect(formikProps)}
                    />
                  </Grid>
                </FormControl>
              </Grid>
            </PermissionFormContainer>
          )}
        </>
      );
    };
  };

  private renderOrganizationPermissionAlert = (): ReactNode => {
    const { routeProject, organizationPermission, hasNoInheritedPermission, permissionToEdit } = this.props;

    if (!permissionToEdit || hasNoInheritedPermission) {
      return null;
    }

    const userName = formatUserName(permissionToEdit.user);
    const organizationName = routeProject.organization.name;

    const organizationPermissionValue =
      organizationPermission &&
      renderHighestPremissionValue(
        organizationPermission.can_admin,
        organizationPermission.can_write,
        organizationPermission.can_read
      );

    return (
      <Alert status={as.info}>
        <div>
          <span>{`${userName}  ${$t('ownsAtLeastPermission')} `}</span>
          <Bold>{organizationPermissionValue}</Bold>
          <span>{` ${$t('permissionInheritedFromOrganization')} ${organizationName}`}</span>
        </div>
      </Alert>
    );
  };

  private getInitialFormValues = (): IProjectPermissionsFormValues => {
    const { permissionToEdit, routeProject, hasNoInheritedPermission } = this.props;

    return {
      project: routeProject.id,
      user: setFormInitialValue(permissionToEdit, 'user.id'),
      can_read: setFormInitialValue(permissionToEdit, 'can_read', !!(!permissionToEdit || hasNoInheritedPermission)),
      can_write: setFormInitialValue(permissionToEdit, 'can_write', false),
      can_admin: setFormInitialValue(permissionToEdit, 'can_admin', false),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IProjectPermissionsFormValues> => {
    return yup.object().shape({
      user: getYupStringValidation(true),
      can_read: getYupBooleanValidation(),
      can_write: getYupBooleanValidation(),
      can_admin: getYupBooleanValidation(),
    }) as yup.ObjectSchema<IProjectPermissionsFormValues>;
  };

  private onPermissionSelect = (formikProps: FormikProps<IProjectPermissionsFormValues>) => {
    return (field: string, isSelected: boolean): void => {
      const { can_read, can_write, can_admin } = cumulatePermissions(field, isSelected, formikProps.values);

      formikProps.setValues({
        ...formikProps.values,
        can_read,
        can_write,
        can_admin,
      });
    };
  };
}

const PermissionFormContainer = styled.div`
  margin-top: 16px;
  margin-left: 16px;
`;

const Bold = styled.span`
  font-weight: bold;
`;
