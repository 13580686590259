import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { selectObatResourcesIdsList } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { withEmptyOption } from 'utils/functions/forms/withEmptyOption';
import { orc } from 'utils/strings/resourceClasses';

import { BridgeForm as BridgeFormComponent } from './BridgeForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const bridgeToEdit = selectFormInitialResourceFromTable(orc.bridge)(rootState);

  return {
    bridgeToEdit,
    constructionOptions: withEmptyOption(selectObatResourcesIdsList(orc.construction)(rootState)),
  };
};

const mapDispatchToProps = {};

export const BridgeForm = connect(mapStateToProps, mapDispatchToProps)(BridgeFormComponent);
