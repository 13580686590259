import { Grid } from '@material-ui/core';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getRestResourceFormDrawer } from 'components/Forms/RestResourceFormDrawer';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { ISimulationGroupRequestParams } from 'types/SimulationGroup/SimulationGroup';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate/translate';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './SimulationGroupForm.container';

export interface ISimulationGroupFormValues {
  project: string;
  name: string;
  comment: string;
  model: string;
}

const RestResourceFormDrawer = getRestResourceFormDrawer<ISimulationGroupFormValues, ISimulationGroupRequestParams>();

export class SimulationGroupForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <RestResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.small}
        resourceClass={rrc.simulation_group}
        getCreationSuccessLandingPageUrl={this.props.getCreationSuccessLandingPageUrl}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<ISimulationGroupFormValues>): ReactNode => {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput field={'name'} formikProps={formikProps} required inputRef={this.formFocusRef} />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput field={'comment'} formikProps={formikProps} multiline />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'model'}
              label={$t('type')}
              formikProps={formikProps}
              options={['monosimulationgroup', 'multisimulationgroup']}
              renderOptionLabel={$t}
              required
              isDisabled={!!this.props.simulationGroupToEdit}
            />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): ISimulationGroupFormValues => {
    const { simulationGroupToEdit, routeProject } = this.props;

    return {
      project: routeProject.id,
      name: setFormInitialValue(simulationGroupToEdit, 'name'),
      comment: setFormInitialValue(simulationGroupToEdit, 'comment'),
      model: setFormInitialValue(simulationGroupToEdit, 'model'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<ISimulationGroupFormValues> => {
    return yup.object().shape({
      name: getYupStringValidation(true),
      comment: getYupStringValidation(),
      model: getYupStringValidation(true),
    }) as yup.ObjectSchema<ISimulationGroupFormValues>;
  };
}
