// tslint:disable:no-any
import { _replace } from 'libs/lodash';
import Papa from 'papaparse';

export const convertToCsv = (unparseInput: any, language: string | undefined): string => {
  let csv = Papa.unparse(unparseInput, {
    quotes: false, // or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: language === 'fr' ? ';' : ',',
    header: true,
    newline: '\r\n',
  });

  if (language === 'fr') {
    /* match all dots between two digits and then replace the dot by a comma */
    csv = _replace(csv, /\d\.\d/g, (match: string): string => {
      return _replace(match, '.', ',');
    });
  }

  return csv;
};
