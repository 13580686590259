import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { CardHeader, SimpleCard } from 'components/Cards';
import { LightText } from 'components/Texts';
import { _includes } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { renderTextBoolean, renderTextFloatElseDash } from 'utils/functions/renderValue/renderValue';
import { $t, $tcc } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { IProps } from './ScheduleDetail.container';

export class ScheduleDetail extends PureComponent<IProps> {
  public render(): ReactNode {
    const { schedule } = this.props;

    return (
      <>
        <SimpleCard>
          <CardHeader>{$t('schedule')}</CardHeader>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {$t('method')}
            </Grid>
            <Grid item xs={10}>
              <LightText>{$tEnums(renderTextValueElseDash(schedule.method))}</LightText>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {$t('yearProfile')}
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item>
                  <LightText>{renderTextValueElseDash(schedule.year_profile)}</LightText>
                </Grid>
                <Grid item>
                  <IconButton size={16} action={'seeDetails'} onClick={this.openYearProfilePlotsModal} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {$t('calendar')}
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item>
                  <LightText>{renderTextValueElseDash(schedule.calendar)}</LightText>
                </Grid>
                <Grid item>
                  <IconButton size={16} action={'seeDetails'} onClick={this.openCalendarPlotsModal} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SimpleCard>

        <SimpleCard>
          <CardHeader>{$t('value')}</CardHeader>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {$t('nominalValue')}
            </Grid>
            <Grid item xs={10}>
              <LightText>{renderTextValueElseDash(schedule.nominal_value)}</LightText>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {$t('reductionRatio')}
            </Grid>
            <Grid item xs={10}>
              <LightText>{schedule.reduction_ratio !== null ? `${schedule.reduction_ratio * 100} %` : '-'}</LightText>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {$t('value')}
            </Grid>
            <Grid item xs={10}>
              <LightText>{renderTextFloatElseDash(schedule.value)}</LightText>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {$t('unit')}
            </Grid>
            <Grid item xs={10}>
              <LightText>{$tEnums(renderTextValueElseDash(schedule.unit))}</LightText>
            </Grid>
          </Grid>
        </SimpleCard>

        {schedule.daylighting_control && (
          <SimpleCard>
            <CardHeader>{$t('daylightingControl')}</CardHeader>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                {$t('active')}
              </Grid>
              <Grid item xs={10}>
                <LightText>{renderTextBoolean(schedule.daylighting_control)}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                {$t('setpoint')}
              </Grid>
              <Grid item xs={10}>
                <LightText>{renderTextValueElseDash(schedule.daylighting_control_setpoint)}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                {$t('minimumLightRatio')}
              </Grid>
              <Grid item xs={10}>
                <LightText>{renderTextValueElseDash(schedule.daylighting_control_minimum_light_ratio)}</LightText>
              </Grid>
            </Grid>
          </SimpleCard>
        )}

        {!_includes(['control', 'heating_setpoint', 'cooling_setpoint'], schedule.type) && (
          <SimpleCard>
            <CardHeader>{$t('advancedSettings')}</CardHeader>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                {$t('baseload')}
              </Grid>
              <Grid item xs={10}>
                <LightText>{renderTextValueElseDash(schedule.baseload)}</LightText>
              </Grid>
            </Grid>
            {this.renderSpecificFields()}
          </SimpleCard>
        )}
      </>
    );
  }

  private renderSpecificFields = (): ReactNode => {
    const { schedule, selectSpecificDefaultValues } = this.props;
    const { type, method } = schedule;

    const specificDefaultValues = selectSpecificDefaultValues(type, method);

    return _map(
      _keys(specificDefaultValues),
      (specificField: string): ReactNode => {
        if (_includes($tcc(specificField), '_')) {
          /* If it's not translated. It supposes there is at least one underscore in each specific field... */
          return null;
        }

        return (
          <Grid container spacing={1} key={specificField}>
            <Grid item xs={2}>
              {$tcc(specificField)}
            </Grid>
            <Grid item xs={10}>
              <LightText>{renderTextValueElseDash(schedule[specificField])}</LightText>
            </Grid>
          </Grid>
        );
      }
    );
  };

  private openCalendarPlotsModal = (event: React.MouseEvent): void => {
    event.stopPropagation();
    const { schedule, openPlotsModal } = this.props;
    openPlotsModal(schedule.calendar, orc.calendar);
  };

  private openYearProfilePlotsModal = (event: React.MouseEvent): void => {
    event.stopPropagation();
    const { schedule, openPlotsModal } = this.props;
    openPlotsModal(schedule.year_profile, orc.year_profile);
  };
}
