import { Button } from 'components/Buttons';
import React, { PureComponent, ReactNode } from 'react';
// @ts-ignore
import rubberBand from 'react-animations/lib/rubberBand';
import { ArrowLeftCircle, Home } from 'react-feather';
import { RouteComponentProps } from 'react-router';
import { navigateRoute } from 'routing/navigateRoute';
import { homeRoute } from 'routing/routes';
import theme from 'style/theme';
import styled, { keyframes } from 'styled-components';
import { getRandomItem } from 'utils/functions/random/getRandomIndex';
import { $t } from 'utils/functions/translate';

export class Page404 extends PureComponent<RouteComponentProps> {
  public render(): ReactNode {
    const gifLink = getRandomItem(gifLinks);

    return (
      <MainContainer>
        <RubberDiv>
          <Div4>4</Div4>
          <img src={process.env.PUBLIC_URL + '/images/symbolOplus.png'} width={50} />
          <Div4>4</Div4>
        </RubberDiv>
        <Title>{$t('pageNotFound')}</Title>
        <GifContainer src={gifLink} />
        <ButtonsDiv>
          <Button text={$t('goBack')} onClick={this.goBack} Icon={<ArrowLeftCircle />} />
          <Button text={$t('goHome')} onClick={this.goHome} Icon={<Home />} />
        </ButtonsDiv>
      </MainContainer>
    );
  }

  private goBack = () => {
    this.props.history.goBack();
  };

  private goHome = () => {
    navigateRoute(homeRoute);
  };
}

const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 200%;
  margin-bottom: 12px;
`;

const RubberDiv = styled.div`
  animation: 2s ${keyframes`${rubberBand}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
`;

const Div4 = styled.div`
  font-size: 500%;
  font-weight: bold;
  color: ${theme.colors.main};
`;

const GifContainer = styled.img`
  max-height: 50vh;
  max-width: 50vh;
  width: auto;
  height: auto;
`;

const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 400px;
  margin-top: 24px;
`;

const gifLinks = [
  'https://media.giphy.com/media/ym2LzNCuEGVry/giphy.gif',
  'https://media.giphy.com/media/EXHHMS9caoxAA/giphy.gif',
  'https://media.giphy.com/media/nKN7E76a27Uek/giphy.gif',
  'https://media.giphy.com/media/11StaZ9Lj74oCY/giphy.gif',
  'https://media.giphy.com/media/EimNpKJpihLY4/giphy.gif',
  'https://media.giphy.com/media/iVhLISAEmtpFS/giphy.gif',
  'https://media.giphy.com/media/3oEjHVtFOfQKzqG8ms/giphy.gif',
  'https://media.giphy.com/media/O34WuYSsXQL96/giphy.gif',
  'https://media.giphy.com/media/11StaZ9Lj74oCY/giphy.gif',
  'https://media.giphy.com/media/S6GmIwq0c6zSAC7OYZ/giphy.gif',
  'https://media.giphy.com/media/kMkTJV4a32ba8/giphy.gif',
  'https://media.giphy.com/media/9DeFJdCCSryecYYts2/giphy.gif',
  'https://media.giphy.com/media/xTiTncVep2khPGhK1i/giphy.gif',
  'https://media.giphy.com/media/nNH7LvI9NhaRq/giphy.gif',
  'https://media.giphy.com/media/3o6ozoD2BgEK8KNtfi/giphy.gif',
  'https://media.giphy.com/media/l4Ki5iMwlTt1ojWHC/giphy.gif',
  'https://media.giphy.com/media/SjRgoSG1RTpYs/giphy.gif',
  'https://media.giphy.com/media/26tPhNJomQlAbEBy0/giphy.gif',
  'https://media.giphy.com/media/N6HtUxeEd6U24/giphy.gif',
  'https://media.giphy.com/media/wLe5jbljmiVsA/giphy.gif',
  'https://media.giphy.com/media/1eUtlOkkugRX2/giphy.gif',
];
