import { IRestResource } from 'types/RestResource';

import {
  CLOSE_REST_RESOURCE_COMMITMENTS_MODAL_ACTION,
  IRestResourceCommitmentsModalActions,
  OPEN_REST_RESOURCE_COMMITMENTS_MODAL_ACTION,
} from './actions';

export interface IRestResourceCommitmentsModalState {
  restResource?: IRestResource;
  editionField?: string;
  isOpen: boolean;
}

export const initialState = {
  restResource: undefined,
  editionField: undefined,
  isOpen: false,
};

export const restResourceCommitmentsModalReducer = (
  state: IRestResourceCommitmentsModalState = initialState,
  action: IRestResourceCommitmentsModalActions
): IRestResourceCommitmentsModalState => {
  switch (action.type) {
    case OPEN_REST_RESOURCE_COMMITMENTS_MODAL_ACTION:
      const { restResource, editionField } = action.payload;

      return { ...state, restResource, editionField, isOpen: true };

    case CLOSE_REST_RESOURCE_COMMITMENTS_MODAL_ACTION:
      return { ...state, restResource: undefined, editionField: undefined, isOpen: false };

    default:
      return state;
  }
};
