import { connect } from 'react-redux';
import { openPlotsModalAction } from 'redux/modals/plotsModal/actions';
import { IActivityZoneGroup } from 'types/Obat/ActivityZoneGroup';

import { ActivityDetail as ActivityDetailComponent } from './ActivityZoneGroupDetail.component';

interface IOwnProps {
  activityZoneGroup: IActivityZoneGroup;
}

type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapDispatchToProps = {
  openPlotsModal: openPlotsModalAction,
};

export const ActivityDetail = connect(undefined, mapDispatchToProps)(ActivityDetailComponent);
