import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { clearSimulationAction } from 'redux/restResources/detail/simulationGroup/actions';
import { refreshSimulationSaga } from 'redux/restResources/detail/simulationGroup/sagas';

import {
  CLOSE_LOGS_MODAL_SAGA_ACTION,
  IOpenLogsModalSagaAction,
  OPEN_LOGS_MODAL_SAGA_ACTION,
  updateLogsModalSimulationAction,
} from './actions';

/* _____ openLogsModalSaga _____  */

export function* openLogsModalSaga(payload: IOpenLogsModalSagaAction['payload']): SagaIterator {
  const { simulationId } = payload;
  yield put(updateLogsModalSimulationAction(simulationId));
  yield call(refreshSimulationSaga, { simulationId });
}

export function* openLogsModalActionSaga(action: IOpenLogsModalSagaAction): SagaIterator {
  yield call(openLogsModalSaga, action.payload);
}

export function* closeLogsModalSaga(): SagaIterator {
  yield put(updateLogsModalSimulationAction(undefined));
  yield put(clearSimulationAction());
}

export function* closeLogsModalActionSaga(): SagaIterator {
  yield call(closeLogsModalSaga);
}

export function* logsModalActionsSagas(): Generator {
  yield takeLatest(OPEN_LOGS_MODAL_SAGA_ACTION, openLogsModalActionSaga);
  yield takeLatest(CLOSE_LOGS_MODAL_SAGA_ACTION, closeLogsModalActionSaga);
}
