import { IFormKey } from 'utils/strings/formKeys';
import { IApiResourceClass } from 'utils/strings/resourceClasses';

import {
  CLOSE_FORM_DRAWER_ACTION,
  COLLAPSE_FORM_DRAWER_ACTION,
  EXPAND_FORM_DRAWER_ACTION,
  IFormActions,
  OPEN_FORM_DRAWER_ACTION,
  TOGGLE_FORM_RESET_ACTION,
  TOGGLE_REORDERING_ACTION,
  UPDATE_DRAWER_WIDTH_ACTION,
} from './actions';

export interface IFormInitialResource {
  resourceId: string;
  resourceClass: IApiResourceClass;
}

export interface IFormState {
  drawer: {
    formKey: IFormKey | undefined;
    isCollapsed: boolean;
    width: number;
  };
  initialResource: IFormInitialResource | undefined;
  hasToReset: boolean;
  isReordering: boolean;
}

export const initialState = {
  drawer: {
    formKey: undefined,
    isCollapsed: false,
    width: 0,
  },
  initialResource: undefined,
  hasToReset: false,
  isReordering: false,
};

export const formReducer = (state: IFormState = initialState, action: IFormActions): IFormState => {
  switch (action.type) {
    case OPEN_FORM_DRAWER_ACTION:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          formKey: action.payload.formKey,
          isCollapsed: false,
        },
        initialResource: action.payload.initialResource,
        hasToReset: false,
      };

    case CLOSE_FORM_DRAWER_ACTION:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          formKey: undefined,
          isCollapsed: false,
          width: 0,
        },
        hasToReset: true,
        initialResource: undefined,
      };

    case COLLAPSE_FORM_DRAWER_ACTION:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          isCollapsed: true,
        },
      };

    case EXPAND_FORM_DRAWER_ACTION:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          isCollapsed: false,
        },
      };

    case TOGGLE_REORDERING_ACTION:
      return { ...state, isReordering: action.payload.isReordering };

    case TOGGLE_FORM_RESET_ACTION:
      return { ...state, hasToReset: action.payload.hasToReset };

    case UPDATE_DRAWER_WIDTH_ACTION:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          width: action.payload.width,
        },
      };

    default:
      return state;
  }
};
