import { Grid } from '@material-ui/core';
import { Button } from 'components/Buttons';
import { ModalFrame } from 'components/Modals/ModalFrame';
import { CenteredText } from 'components/Texts/CenteredText';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { Check } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';

import { IProps } from './DailySeatsModal.container';

export class DailySeatsModal extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isOpen, closeModal, spendDailySeats } = this.props;

    const date = moment.utc().format('LL');

    const Title = <CenteredText>{$t('spendDailySeat')}</CenteredText>;

    const Content = <AlertTextContainer>{$t('spendDailySeatAlert', { date })}</AlertTextContainer>;

    const onSpendClick = () => {
      spendDailySeats(1);
    };

    const Actions = (
      <Grid container spacing={3} justify={'center'} alignItems={'center'}>
        <Grid item>
          <Button Icon={<Check size={20} />} onClick={onSpendClick} noShadow />
        </Grid>
      </Grid>
    );

    return <ModalFrame isOpen={isOpen} onClose={closeModal} Title={Title} Content={Content} Actions={Actions} />;
  }
}

const AlertTextContainer = styled.div`
  max-height: 75vh;
  overflow-y: auto;
  background-color: ${theme.colors.info};
  color: black;
  padding: 8px;
  border-radius: ${theme.borderRadius.small};
  word-break: break-word;
`;
