import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

const routingMenusTuple = stringsTuple(
  'home',
  'organization',
  'project',
  'geometry',
  'obat',
  'weather',
  'monoSimulationGroup',
  'multiSimulationGroup'
);
export type IRoutingMenu = typeof routingMenusTuple[number];
export const rm = mapStrings<IRoutingMenu>(routingMenusTuple);
