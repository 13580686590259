import { MonthlyResultsPage } from 'components/SimulationGroup/MonthlyResults/MonthlyResultsPage';
import React, { PureComponent, ReactNode } from 'react';

import { IProps } from './MonthlyResultsMulti.container';

export class MonthlyResultsMulti extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeMultiSimulationGroup } = this.props;

    return <MonthlyResultsPage routeSimulationGroup={routeMultiSimulationGroup} />;
  }
}
