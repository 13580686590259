import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';

interface IProps {
  dotColor: string;
  itemWidth?: string;
  rightSpacing?: number;
  text: string;
  textFontWeight?: string;
  onClick?: (id: string) => () => void;
  noTooltip?: boolean;
  blurred?: boolean;
}

export class LegendItem extends PureComponent<IProps> {
  public render(): ReactNode {
    const { dotColor, rightSpacing, text, textFontWeight, itemWidth } = this.props;

    return (
      <ItemContainer rightSpacing={rightSpacing} legendWidth={itemWidth}>
        <ColoredDot color={dotColor} />
        {this.renderText(text, textFontWeight)}
      </ItemContainer>
    );
  }

  public renderText = (text: string, textFontWeight: string | undefined): ReactNode => {
    const { noTooltip, onClick, blurred } = this.props;

    return (
      <LegendText
        fontWeight={textFontWeight}
        onClick={onClick && onClick(text)}
        blurred={blurred}
        isClickable={!!onClick}
      >
        <SimpleTooltip interactive content={noTooltip ? '' : text} placement="bottom">
          <span>{text}</span>
        </SimpleTooltip>
      </LegendText>
    );
  };
}

interface IItemContainerProps {
  legendWidth?: string;
  rightSpacing?: number;
}

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props: IItemContainerProps): string =>
    props.rightSpacing || props.rightSpacing === 0 ? props.rightSpacing + 'px' : '12px'};
  width: ${(props: IItemContainerProps): string => props.legendWidth || 'auto'};
  margin-top: 2px;
  margin-bottom: 2px;
`;

interface ILegendTextProps {
  fontWeight?: string;
  isClickable?: boolean;
  blurred?: boolean;
}

export const LegendText = styled.div`
  margin: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: ${(props: ILegendTextProps) => props.fontWeight};
  font-size: ${(props: ILegendTextProps) => (props.isClickable ? '12px' : '')};
  cursor: ${(props: ILegendTextProps) => (props.isClickable ? 'pointer' : 'default')};
  color: ${(props: ILegendTextProps) =>
    props.blurred ? theme.colors.button.disabled : props.isClickable ? theme.colors.font.dark : 'black'};
`;

interface IColoredDotProps {
  color: string;
  size?: number;
}

export const ColoredDot = styled.div`
  float: left;
  background-color: ${(props: IColoredDotProps) => props.color};
  width: ${(props: IColoredDotProps): string => (props.size ? props.size + 'px' : '12px')};
  height: ${(props: IColoredDotProps): string => (props.size ? props.size + 'px' : '12px')};
  border-radius: 50%;
  flex-shrink: 0;
`;
