import { IObatContentsMetaCommitmentsMap, IObatContentsMetaLinksMap } from 'types/Obat/ObatMeta';
export const obatContentsLinksMap: IObatContentsMetaLinksMap = {
  activity_zone_group: {
    light: 'schedule',
    people: 'schedule',
    electric_equipment: 'schedule',
    electric_equipment_bis: 'schedule',
    natural_ventilation: 'schedule',
    natural_ventilation_bis: 'schedule',
    infiltration: 'schedule',
    mechanical_ventilation_system: 'system',
    heating_system: 'system',
    cooling_system: 'system',
    outdoor_air: 'schedule',
    heating_setpoint: 'schedule',
    cooling_setpoint: 'schedule',
    dhw_system: 'system',
    dhw: 'schedule',
  },
  bridge: {
    construction_a: 'construction',
    construction_b: 'construction',
  },
  calendar: {},
  construction: {},
  day_group: {
    calendar: 'calendar',
  },
  day_profile: {
    year_profile: 'year_profile',
    day_group: 'day_group',
  },
  general: {},
  layer: {
    construction: 'construction',
    material: 'material',
  },
  material: {},
  modification: {
    variant: 'variant',
  },
  opening: {
    blind_control_availability: 'schedule',
  },
  output_zone_group: {},
  period: {
    day_group: 'day_group',
  },
  schedule: {
    year_profile: 'year_profile',
  },
  surface_group: {
    construction: 'construction',
    opening: 'opening',
  },
  system: {
    heating_availability: 'schedule',
    cooling_availability: 'schedule',
  },
  uses: {},
  variant: {},
  year_profile: {
    calendar: 'calendar',
  },
  ef_ep: {},
  e_plus_parameters: {},
};
export const obatContentsCommitmentsMap: IObatContentsMetaCommitmentsMap = {
  activity_zone_group: {
    update: {},
    delete: ['zone'],
  },
  bridge: {
    update: {},
    delete: [],
  },
  calendar: {
    update: {},
    delete: ['year_profile'],
  },
  construction: {
    update: {
      surface_category: ['surface_group'],
    },
    delete: ['bridge', 'surface', 'surface_group'],
  },
  day_group: {
    update: {
      calendar: ['day_profile'],
    },
    delete: ['day_profile'],
  },
  day_profile: {
    update: {},
    delete: [],
  },
  general: {
    update: {},
    delete: [],
  },
  layer: {
    update: {},
    delete: [],
  },
  material: {
    update: {},
    delete: ['layer'],
  },
  modification: {
    update: {},
    delete: [],
  },
  opening: {
    update: {
      surface_category: ['surface_group'],
    },
    delete: ['subsurface', 'surface_group'],
  },
  output_zone_group: {
    update: {},
    delete: [],
  },
  period: {
    update: {},
    delete: [],
  },
  schedule: {
    update: {
      type: ['activity_zone_group', 'opening', 'system'],
    },
    delete: ['activity_zone_group', 'opening', 'system'],
  },
  surface_group: {
    update: {},
    delete: [],
  },
  system: {
    update: {
      cooling_energy: ['activity_zone_group'],
      heating_energy: ['activity_zone_group'],
      method: ['activity_zone_group'],
      type: ['activity_zone_group'],
    },
    delete: ['activity_zone_group'],
  },
  uses: {
    update: {},
    delete: [],
  },
  variant: {
    update: {},
    delete: [],
  },
  year_profile: {
    update: {
      calendar: ['day_profile'],
      type: ['day_profile', 'schedule'],
    },
    delete: ['schedule'],
  },
  ef_ep: {
    update: {},
    delete: [],
  },
  e_plus_parameters: {
    update: {},
    delete: [],
  },
};
