import jwtDecode from 'jwt-decode';
import moment from 'moment';

interface ITokenDetails {
  deviceClock: string;
  expirationAt: string;
  expirationIn: string;
  isValid: boolean;
}

export const getTokenDetails = (token: string): ITokenDetails => {
  const decodedToken = jwtDecode<{ exp: number }>(token);

  const now = moment().unix();
  const expirationAt = decodedToken.exp;
  const expirationIn = expirationAt - now;

  const limitBeforeExpiration = 10; /* seconds */
  // If the token expires in less than 10 seconds, it is considered invalid
  const isValid = expirationIn >= limitBeforeExpiration;

  return {
    deviceClock: `${moment.unix(now).format('L')} ${moment.unix(now).format('LTS')}`,
    expirationAt: `${moment.unix(expirationAt).format('L')} ${moment.unix(expirationAt).format('LTS')}`,
    expirationIn: `${Math.floor(expirationIn / 60)}m ${expirationIn % 60}s`,
    isValid,
  };
};

export const isTokenValid = (token: string): boolean => {
  const tokenDetails = getTokenDetails(token);

  return tokenDetails.isValid;
};
