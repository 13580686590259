import { Action } from 'redux';
import { IApiResourceClass } from 'utils/strings/resourceClasses';

export const OPEN_DELETION_MODAL = 'OPEN_DELETION_MODAL';
export interface IOpenDeletionModal extends Action<'OPEN_DELETION_MODAL'> {
  payload: {
    resourceId: string;
    resourceClass: IApiResourceClass;
  };
}
export const openDeletionModalAction = (resourceId: string, resourceClass: IApiResourceClass): IOpenDeletionModal => ({
  type: OPEN_DELETION_MODAL,
  payload: {
    resourceId,
    resourceClass,
  },
});

export const CLOSE_DELETION_MODAL = 'CLOSE_DELETION_MODAL';
export interface ICloseDeletionModal extends Action<'CLOSE_DELETION_MODAL'> {}
export const closeDeletionModalAction = (): ICloseDeletionModal => ({
  type: CLOSE_DELETION_MODAL,
});

export type IDeletionModalActions = IOpenDeletionModal | ICloseDeletionModal;
