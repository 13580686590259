import { connect } from 'react-redux';
import { closeFormDrawerAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import { selectObatGeometriesOptions } from 'redux/restResources/detail/obat/selectors';

import { exportGbxmlFileSagaAction } from './../../../redux/restResources/detail/obat/actions';
import { ExportGbxmlForm as Component } from './ExportGbxmlForm.component';

// tslint:disable-next-line
interface IOwnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState, _ownProps: IOwnProps) => {
  return {
    geometriesOptions: selectObatGeometriesOptions(rootState),
  };
};

const mapDispatchToProps = {
  exportGbxml: exportGbxmlFileSagaAction,
  closeFormDrawer: closeFormDrawerAction,
};

export const ExportGbxmlForm = connect(mapStateToProps, mapDispatchToProps)(Component);
