import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';

interface IProps {
  iconName: string;
  name: string;
  values: Array<string | null>;
}
export class ActivityIcon extends PureComponent<IProps> {
  public render(): ReactNode {
    const { iconName, values, name } = this.props;
    let tooltipValue: string = $t(name);
    let emptyIcon: boolean = true;

    if (values[0]) {
      tooltipValue += ': ';
      _map(values, (v: string | null, i: number) => {
        if (v) {
          emptyIcon = false;
          if (i < v.length - 1) {
            tooltipValue += v + '\n';
          } else {
            tooltipValue += v;
          }
        }
      });
    } else {
      tooltipValue += ': -';
    }

    return (
      <SimpleTooltip interactive content={tooltipValue} placement="top">
        <Icon className={iconName} emptyIcon={emptyIcon} />
      </SimpleTooltip>
    );
  }
}

const Icon = styled.span`
  font-size: 25px;
  margin-right: 10px;
  color: ${(props: { emptyIcon: boolean }) => (props.emptyIcon ? theme.colors.empty : theme.colors.main)};
`;
