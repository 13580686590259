// tslint:disable:no-any

import { IWeatherConfigRequestParams } from 'pages/WeatherMenu/Config/WeatherConfigForm/WeatherConfigForm.component';
import { Action } from 'redux';
import {
  IConfigOptionsParams,
  IDegreeDaysData,
  IDegreeDaysParams,
  IOpenergyHistoricalWeatherConfigOption,
  IWeatherMeasuresMetadata,
  IWeatherMeasuresPlotted,
} from 'redux/restResources/detail/weather/reducer';
import { IGenericWeatherImportFields } from 'types/Weather/GenericWeather';
import { IWeathersTemplates } from 'types/Weather/Weather';
import { IProgressStatus } from 'utils/strings/progressStatus';

/*** FETCH_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_SAGA_ACTION ***/

export const FETCH_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_SAGA_ACTION =
  'FETCH_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_SAGA_ACTION';
export interface IFetchOpenergyHistoricalWeatherConfigOptionsSagaAction
  extends Action<'FETCH_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_SAGA_ACTION'> {
  payload: {
    params?: IConfigOptionsParams;
  };
}
export const fetchOpenergyHistoricalWeatherConfigOptionsSagaAction = (
  params?: IConfigOptionsParams
): IFetchOpenergyHistoricalWeatherConfigOptionsSagaAction => ({
  type: FETCH_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_SAGA_ACTION,
  payload: {
    params,
  },
});

/*** UPDATE_OPENERGY_WEATHER_SERIES_CONFIG_OPTIONS_ACTION ***/

export const UPDATE_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_ACTION =
  'UPDATE_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_ACTION';
export interface IUpdateOpenergyHistoricalWeatherConfigOptionsAction
  extends Action<'UPDATE_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_ACTION'> {
  payload: {
    configOptions: IOpenergyHistoricalWeatherConfigOption[];
  };
}
export const updateOpenergyWeatherSeriesConfigOptionsAction = (
  configOptions: IOpenergyHistoricalWeatherConfigOption[]
): IUpdateOpenergyHistoricalWeatherConfigOptionsAction => ({
  type: UPDATE_OPENERGY_HISTORICAL_WEATHER_CONFIG_OPTIONS_ACTION,
  payload: {
    configOptions,
  },
});

/*** IMPORT_WEATHER_DATA_SAGA_ACTION ***/

export const IMPORT_WEATHER_DATA_SAGA_ACTION = 'IMPORT_WEATHER_DATA_SAGA_ACTION';
export interface IImportWeatherDataSagaAction extends Action<'IMPORT_WEATHER_DATA_SAGA_ACTION'> {
  payload: {
    fileContentBlob: Blob;
    importFields: IGenericWeatherImportFields;
  };
}
export const importWeatherDataSagaAction = (
  importFields: IGenericWeatherImportFields,
  fileContentBlob: Blob
): IImportWeatherDataSagaAction => ({
  type: IMPORT_WEATHER_DATA_SAGA_ACTION,
  payload: {
    importFields,
    fileContentBlob,
  },
});

/*** UPDATE_WEATHER_IMPORT_PROGRESS_ACTION ***/

export const UPDATE_WEATHER_IMPORT_PROGRESS_ACTION = 'UPDATE_WEATHER_IMPORT_PROGRESS_ACTION';
export interface IUpdateWeatherImportProgressAction extends Action<'UPDATE_WEATHER_IMPORT_PROGRESS_ACTION'> {
  payload: {
    progress: IProgressStatus;
  };
}
export const updateWeatherImportProgressAction = (progress: IProgressStatus): IUpdateWeatherImportProgressAction => ({
  type: UPDATE_WEATHER_IMPORT_PROGRESS_ACTION,
  payload: {
    progress,
  },
});

/*** UPDATE_WEATHER_MEASURES_PLOTTED_ACTION ***/

export const UPDATE_WEATHER_MEASURES_PLOTTED_ACTION = 'UPDATE_WEATHER_MEASURES_PLOTTED_ACTION';
export interface IUpdateWeatherMeasuresPlottedAction extends Action<'UPDATE_WEATHER_MEASURES_PLOTTED_ACTION'> {
  payload: {
    plotted: IWeatherMeasuresPlotted;
  };
}
export const updateWeatherMeasuresPlottedAction = (
  plotted: IWeatherMeasuresPlotted
): IUpdateWeatherMeasuresPlottedAction => ({
  type: UPDATE_WEATHER_MEASURES_PLOTTED_ACTION,
  payload: {
    plotted,
  },
});

/*** UPDATE_WEATHER_MEASURES_METADATA_ACTION ***/

export const UPDATE_WEATHER_MEASURES_METADATA_ACTION = 'UPDATE_WEATHER_MEASURES_METADATA_ACTION';
export interface IUpdateWeatherMeasuresMetadataAction extends Action<'UPDATE_WEATHER_MEASURES_METADATA_ACTION'> {
  payload: {
    metadata: IWeatherMeasuresMetadata;
  };
}
export const updateWeatherMeasuresMetadataAction = (
  metadata: IWeatherMeasuresMetadata
): IUpdateWeatherMeasuresMetadataAction => ({
  type: UPDATE_WEATHER_MEASURES_METADATA_ACTION,
  payload: {
    metadata,
  },
});

/*** REFRESH_IMPORT_WEATHER_TASK_SAGA_ACTION ***/

export const REFRESH_IMPORT_WEATHER_TASK_SAGA_ACTION = 'REFRESH_IMPORT_WEATHER_TASK_SAGA_ACTION';
export interface IRefreshImportWeatherTaskSagaAction extends Action<'REFRESH_IMPORT_WEATHER_TASK_SAGA_ACTION'> {}
export const refreshImportWeatherTaskSagaAction = (): IRefreshImportWeatherTaskSagaAction => ({
  type: REFRESH_IMPORT_WEATHER_TASK_SAGA_ACTION,
});

/*** DOWNLOAD_WEATHER_SERIES_HOURLY_DATA_SAGA_ACTION ***/

export const DOWNLOAD_WEATHER_SERIES_HOURLY_DATA_SAGA_ACTION = 'DOWNLOAD_WEATHER_SERIES_HOURLY_DATA_SAGA_ACTION';
export interface IDownloadWeatherSeriesHourlyDataSagaAction
  extends Action<'DOWNLOAD_WEATHER_SERIES_HOURLY_DATA_SAGA_ACTION'> {
  payload: {
    measureToPlot?: { seriesId: string; year: string };
  };
}
export const downloadWeatherSeriesHourlyDataSagaAction = (measureToPlot?: {
  seriesId: string;
  year: string;
}): IDownloadWeatherSeriesHourlyDataSagaAction => ({
  type: DOWNLOAD_WEATHER_SERIES_HOURLY_DATA_SAGA_ACTION,
  payload: {
    measureToPlot,
  },
});

/*** UPDATE_WEATHER_MEASURES_YEARS_DATA_ACTION ***/

export const UPDATE_WEATHER_MEASURES_YEARS_DATA_ACTION = 'UPDATE_WEATHER_MEASURES_YEARS_DATA_ACTION';
export interface IUpdateWeatherMeasuresYearsDataAction extends Action<'UPDATE_WEATHER_MEASURES_YEARS_DATA_ACTION'> {
  payload: {
    seriesId: string;
    year: string;
    seriesYearData: number[] | string[];
  };
}
export const updateWeatherMeasuresYearsDataAction = (
  seriesId: string,
  year: string,
  seriesYearData: number[] | string[]
): IUpdateWeatherMeasuresYearsDataAction => ({
  type: UPDATE_WEATHER_MEASURES_YEARS_DATA_ACTION,
  payload: {
    seriesId,
    year,
    seriesYearData,
  },
});

/*** EXPORT_WEATHER_FILE_SAGA_ACTION ***/

export const EXPORT_WEATHER_FILE_SAGA_ACTION = 'EXPORT_WEATHER_FILE_SAGA_ACTION';
export interface IExportWeatherFileSagaAction extends Action<'EXPORT_WEATHER_FILE_SAGA_ACTION'> {
  payload: {
    exportFormat: string;
  };
}
export const exportWeatherFileSagaAction = (exportFormat: string): IExportWeatherFileSagaAction => ({
  type: EXPORT_WEATHER_FILE_SAGA_ACTION,
  payload: {
    exportFormat,
  },
});

/*** CLEAR_WEATHER_MEASURES_ACTION ***/

export const CLEAR_WEATHER_MEASURES_ACTION = 'CLEAR_WEATHER_MEASURES_ACTION';
export interface IClearWeatherMeasuresAction extends Action<'CLEAR_WEATHER_MEASURES_ACTION'> {}
export const clearWeatherMeasuresAction = (): IClearWeatherMeasuresAction => ({
  type: CLEAR_WEATHER_MEASURES_ACTION,
});

/*** FETCH_DEGREE_DAYS_YEAR_DATA_SAGA_ACTION ***/

export const FETCH_DEGREE_DAYS_YEAR_DATA_SAGA_ACTION = 'FETCH_DEGREE_DAYS_YEAR_DATA_SAGA_ACTION';
export interface IFetchDegreeDaysYearDataSagaAction extends Action<'FETCH_DEGREE_DAYS_YEAR_DATA_SAGA_ACTION'> {
  payload: {
    params?: IDegreeDaysParams;
  };
}
export const fetchDegreeDaysYearDataSagaAction = (params?: IDegreeDaysParams): IFetchDegreeDaysYearDataSagaAction => ({
  type: FETCH_DEGREE_DAYS_YEAR_DATA_SAGA_ACTION,
  payload: {
    params,
  },
});

export const UPDATE_DEGREE_DAYS_DATA_ACTION = 'UPDATE_DEGREE_DAYS_DATA_ACTION';
export interface IUpdateDegreeDaysDataActionAction extends Action<'UPDATE_DEGREE_DAYS_DATA_ACTION'> {
  payload: {
    data: IDegreeDaysData;
  };
}
export const updateDegreeDaysDataAction = (data: IDegreeDaysData): IUpdateDegreeDaysDataActionAction => ({
  type: UPDATE_DEGREE_DAYS_DATA_ACTION,
  payload: {
    data,
  },
});

/*** EXPORT_DEGREE_DAYS_FILE_SAGA_ACTION ***/

export const EXPORT_DEGREE_DAYS_FILE_SAGA_ACTION = 'EXPORT_DEGREE_DAYS_FILE_SAGA_ACTION';
export interface IExportDegreeDaysFileSagaAction extends Action<'EXPORT_DEGREE_DAYS_FILE_SAGA_ACTION'> {}
export const exportDegreeDaysFileSagaAction = (): IExportDegreeDaysFileSagaAction => ({
  type: EXPORT_DEGREE_DAYS_FILE_SAGA_ACTION,
});

/*** UPDATE_DEGREE_DAYS_PARAMS_ACTION ***/

export const UPDATE_DEGREE_DAYS_PARAMS_ACTION = 'UPDATE_DEGREE_DAYS_PARAMS_ACTION';
export interface IUpdateDegreeDaysParamsAction extends Action<'UPDATE_DEGREE_DAYS_PARAMS_ACTION'> {
  payload: {
    params: IDegreeDaysParams;
  };
}
export const updateDegreeDaysParamsAction = (params: IDegreeDaysParams): IUpdateDegreeDaysParamsAction => ({
  type: UPDATE_DEGREE_DAYS_PARAMS_ACTION,
  payload: {
    params,
  },
});

/*** EDIT_WEATHER_CONFIG_SAGA_ACTION ***/

export const EDIT_WEATHER_CONFIG_SAGA_ACTION = 'EDIT_WEATHER_CONFIG_SAGA_ACTION';
export interface IEditWeatherConfigSagaAction extends Action<'EDIT_WEATHER_CONFIG_SAGA_ACTION'> {
  payload: {
    requestParams: IWeatherConfigRequestParams;
  };
}
export const editWeatherConfigSagaAction = (
  requestParams: IWeatherConfigRequestParams
): IEditWeatherConfigSagaAction => ({
  type: EDIT_WEATHER_CONFIG_SAGA_ACTION,
  payload: {
    requestParams,
  },
});

// _____ FETCH_WEATHERS_TEMPLATES_SAGA_ACTION _____

export const FETCH_WEATHERS_TEMPLATES_SAGA_ACTION = 'FETCH_WEATHERS_TEMPLATES_SAGA_ACTION';
export interface IFetchWeathersTemplatesSagaAction extends Action<'FETCH_WEATHERS_TEMPLATES_SAGA_ACTION'> {}
export const fetchWeathersTemplatesSagaAction = (): IFetchWeathersTemplatesSagaAction => ({
  type: FETCH_WEATHERS_TEMPLATES_SAGA_ACTION,
});

// _____ UPDATE_WEATHERS_TEMPLATES_ACTION _____

export const UPDATE_WEATHERS_TEMPLATES_ACTION = 'UPDATE_WEATHERS_TEMPLATES_ACTION';
export interface IUpdateWeathersTemplatesAction extends Action<'UPDATE_WEATHERS_TEMPLATES_ACTION'> {
  payload: {
    templates: IWeathersTemplates;
  };
}
export const updateWeathersTemplatesAction = (templates: IWeathersTemplates): IUpdateWeathersTemplatesAction => ({
  type: UPDATE_WEATHERS_TEMPLATES_ACTION,
  payload: {
    templates,
  },
});

// _____ TOGGLE_IS_WEATHER_DATA_DOWNLOAD_ERROR_ACTION _____

export const TOGGLE_IS_WEATHER_DATA_DOWNLOAD_ERROR_ACTION = 'TOGGLE_IS_WEATHER_DATA_DOWNLOAD_ERROR_ACTION';
export interface IToggleIsWeatherDataDownloadErrorAction
  extends Action<'TOGGLE_IS_WEATHER_DATA_DOWNLOAD_ERROR_ACTION'> {
  payload: {
    isError: boolean;
  };
}
export const toggleIsWeatherDataDownloadErrorAction = (isError: boolean): IToggleIsWeatherDataDownloadErrorAction => ({
  type: TOGGLE_IS_WEATHER_DATA_DOWNLOAD_ERROR_ACTION,
  payload: {
    isError,
  },
});

export type IWeatherDataActions =
  | IUpdateWeatherMeasuresYearsDataAction
  | IUpdateWeatherMeasuresMetadataAction
  | IUpdateWeatherImportProgressAction
  | IUpdateWeatherMeasuresPlottedAction
  | IUpdateDegreeDaysDataActionAction
  | IUpdateDegreeDaysParamsAction
  | IClearWeatherMeasuresAction
  | IUpdateOpenergyHistoricalWeatherConfigOptionsAction
  | IUpdateWeathersTemplatesAction
  | IToggleIsWeatherDataDownloadErrorAction;
