import { TopSnackbarAlert } from 'components/Alerts';
import { AddButton } from 'components/Buttons/AddButton';
import {
  BottomLeftButtons,
  BottomRightButtons,
  CenterButtons,
  TopRightButtons,
} from 'components/Buttons/ButtonsContainers';
import { ITool, ToolsButton } from 'components/Buttons/ToolsButton/ToolsButton.component';
import { ObatResourceCopyFormDrawer } from 'components/Forms/ObatResourceCopyFormDrawer';
import { OplusLoader } from 'components/Loader';
import { DeletionModal } from 'components/Modals/DeletionModal';
import { ObatResourceCommitmentsModal } from 'components/Modals/ObatResourceCommitmentsModal';
import { PlotsModal } from 'components/Modals/PlotsModal';
import { ObatCard } from 'components/ObatResources/ObatCard';
import { OBAT_TOP_BAR_HEIGHT } from 'components/ObatResources/ObatHeadersBar';
import { ObatImportForm } from 'components/ObatResources/ObatImportForm';
import { VariantContextButtons } from 'components/ObatResources/VariantContext/VariantContextButtons';
import { VariantContextForm } from 'components/ObatResources/VariantContext/VariantContextForm';
import { Page } from 'components/Page';
import { _get, _includes } from 'libs/lodash';
import { _isEqual } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { Element as ScrollElement, scroller } from 'react-scroll/modules';
import { navigateRoute } from 'routing/navigateRoute';
import { getObatMenuPageRoute, obatMenuPages } from 'routing/routes';
import theme from 'style/theme';
import { IObatResource } from 'types/Obat/ObatResource';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { ps } from 'utils/strings/progressStatus';
import { orc } from 'utils/strings/resourceClasses';
import { rm } from 'utils/strings/routingMenus';

import { ExportGbxmlForm } from '../ExportGbxmlForm/ExportGbxmlForm.container';

import { IProps } from './ObatCardsPage.container';

interface IState {
  isAllExpanded?: boolean;
}

export class ObatCardsPage extends PureComponent<IProps, IState> {
  public state: IState = {
    isAllExpanded: false,
  };

  public componentDidMount(): void {
    const {
      routeObat,
      urlHash,
      setRefToScrollTo,
      updateRouteHash,
      initializeTable,
      obatResources,
      tableFilterParams,
      resourceClass,
      obatContentsId,
      refreshImportObatTask,
      fetchTableInitialData,
    } = this.props;

    refreshImportObatTask();

    if (!obatResources || !_isEqual(routeObat.id, obatContentsId)) {
      fetchTableInitialData(tableFilterParams, resourceClass, routeObat.id);
    } else {
      initializeTable(obatResources, tableFilterParams, resourceClass, routeObat.id);
    }

    if (urlHash) {
      setRefToScrollTo(urlHash);
    }
    updateRouteHash(undefined);
  }

  public componentDidUpdate(): void {
    const {
      refToScrollTo,
      setRefToScrollTo,
      isImportingObat,
      isFetchingObatContents,
      isRefreshingRoutePage,
      fetchTableInitialData,
      tableFilterParams,
      resourceClass,
      routeObat,
    } = this.props;

    if (isRefreshingRoutePage) {
      /* if page refresh, force obat contents update (can't catch isRefreshingRoutePage === true in componentDidMount) */
      fetchTableInitialData(tableFilterParams, resourceClass, routeObat.id);
    }

    const isLoading = isFetchingObatContents || isImportingObat;

    if (!isLoading && refToScrollTo && scroller.get(refToScrollTo)) {
      setTimeout(() => {
        /** scroll to the right resource while opening a new tab via a link to the resource */
        scroller.scrollTo(refToScrollTo, {
          smooth: 'easeInOutQuart',
          containerId: 'pageBody',
          offset: -OBAT_TOP_BAR_HEIGHT - 5,
          delay: 100,
        });
      }, 100);
      setRefToScrollTo(undefined);
    }
    forceCheck();
  }

  public render(): ReactNode {
    const {
      selectedPage,
      obatImportProgress,
      isFetchingObatContents,
      isImportingObat,
      isExportingGbxml,
      isExportingObat,
      ObatResourceFormComponent,
      resourceClass,
      formDrawerWidth,
      isResourceForm,
      isDataEmpty,
      isDataReady,
      cardColumnsParams,
    } = this.props;

    return (
      <Page
        pageTitle={$t(selectedPage)}
        obatHeaderProps={{
          cardColumnsParams,
          isDataEmpty,
          isDataReady,
        }}
        routingMenu={rm.obat}
        selectedPage={selectedPage}
        withBottomButtons
      >
        <>
          {isFetchingObatContents && <OplusLoader progress={ps.fetchingData} />}
          {isImportingObat && !isFetchingObatContents && <OplusLoader progress={obatImportProgress} />}
          {!isImportingObat && !isFetchingObatContents && this.renderCards()}
        </>

        <TopSnackbarAlert isOpen={isExportingObat || isExportingGbxml} status={as.info}>
          {$t('exportInProgress')}
        </TopSnackbarAlert>
        {!_includes([orc.ef_ep, orc.e_plus_parameters], resourceClass) && !isDataEmpty && (
          <TopRightButtons formDrawerWidth={isResourceForm ? formDrawerWidth : 0}>
            <AddButton />
          </TopRightButtons>
        )}
        <BottomLeftButtons>
          <VariantContextButtons />
        </BottomLeftButtons>
        <BottomRightButtons formDrawerWidth={formDrawerWidth}>
          <ToolsButton tools={this.getTools()} />
        </BottomRightButtons>

        <VariantContextForm />
        <ObatImportForm />
        <ExportGbxmlForm />
        <ObatResourceCopyFormDrawer resourceClass={resourceClass} />
        <ObatResourceFormComponent />
        <DeletionModal />

        <ObatResourceCommitmentsModal />
        <PlotsModal />
      </Page>
    );
  }

  private renderCards = (): ReactNode => {
    const {
      isDataReady,
      isDataEmpty,
      tableResources,
      refToScrollTo,
      resourceClass,
      selectedPage,
      renderCardColumnData,
      renderCardDetail,
      cardColumnsParams,
      formDrawerWidth,
    } = this.props;
    const { isAllExpanded } = this.state;

    return (
      <>
        {isDataReady &&
          !isDataEmpty &&
          _map(
            // @ts-ignore tableResources can't be undefined because of isDataReady
            tableResources.filtered,
            (obatResource: IObatResource): ReactNode => (
              <ScrollElement name={obatResource.id} key={obatResource.id}>
                {/* height 82 correspond to minHeight of the child card with the top and bottom paddings */}
                {/* we deactivate lazy load if refToScrollTo because we don't know the real height of the cards */}
                {/* and so the scroll will miss the target if it's not rendered */}
                <LazyLoad
                  key={obatResource.id}
                  height={refToScrollTo ? undefined : 82}
                  offset={100}
                  scrollContainer="#pageBody"
                >
                  <ObatCard
                    key={obatResource.id}
                    isExpanded={isAllExpanded}
                    resourceClass={resourceClass}
                    page={selectedPage}
                    obatResource={obatResource}
                    cardColumnsParams={cardColumnsParams}
                    renderColumnData={renderCardColumnData}
                    renderCardDetail={renderCardDetail}
                    leftColor={
                      resourceClass === orc.surface_group
                        ? _get(theme.colors.surfaceCategories, obatResource.surface_category)
                        : ''
                    }
                  />
                </LazyLoad>
              </ScrollElement>
            )
          )}
        {!_includes([orc.ef_ep, orc.e_plus_parameters], resourceClass) && isDataEmpty && formDrawerWidth === 0 && (
          <CenterButtons isPulsing>
            <AddButton withText />
          </CenterButtons>
        )}
      </>
    );
  };

  private getTools = (): ITool[] => {
    const {
      openVariantContextForm,
      variantOptions,
      openObatImportForm,
      exportObat,
      routeProject,
      routeObat,
      isExportingObat,
      openExportGbxmlForm,
      isExportingGbxml,
      isWritePermissionMissing,
      isSeatMissing,
    } = this.props;

    const onProjectionClick = (e: React.MouseEvent) => {
      const projectionUrl = getObatMenuPageRoute(obatMenuPages.projection, routeProject.id, routeObat.id);
      navigateRoute(projectionUrl, e, true);
    };

    let tools: ITool[] = [
      { text: $t('projection'), onClick: onProjectionClick },
      {
        text: $t('importObat'),
        onClick: openObatImportForm,
        isWritePermissionMissing,
        isSeatMissing,
      },
    ];

    if (_size(variantOptions) > 0) {
      tools = [{ text: $t('applyVariant'), onClick: openVariantContextForm }, ...tools];
    }

    if (!isExportingObat) {
      tools = [
        ...tools,
        {
          text: $t('exportObat'),
          onClick: exportObat,
          isWritePermissionMissing,
          isSeatMissing,
        },
      ];
    }

    if (!isExportingGbxml) {
      tools = [
        ...tools,
        {
          text: $t('exportGbxml'),
          onClick: openExportGbxmlForm,
          isWritePermissionMissing,
          isSeatMissing,
        },
      ];
    }

    return tools;
  };
}
