import { connect } from 'react-redux';
import { selectObatContentsFlatOptionsList } from 'redux/enums/selectors';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { openPlotsModalAction } from 'redux/modals/plotsModal/actions';
import { selectObatResourcesIdsList } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { ocfe } from 'utils/configs/enums';
import { orc } from 'utils/strings/resourceClasses';

import { YearProfileForm as YearProfileFormComponent } from './YearProfileForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const yearProfileToEdit = selectFormInitialResourceFromTable(orc.year_profile)(rootState);

  return {
    yearProfileToEdit,
    typeOptions: selectObatContentsFlatOptionsList(ocfe.year_profile_types)(rootState),
    calendarsOptions: selectObatResourcesIdsList(orc.calendar)(rootState),
  };
};

const mapDispatchToProps = { openPlotsModal: openPlotsModalAction };

export const YearProfileForm = connect(mapStateToProps, mapDispatchToProps)(YearProfileFormComponent);
