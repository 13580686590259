import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { selectMyMemberOrganizationOptions } from 'redux/restResources/detail/me/selectors';
import {
  selectObatsTemplateAgeOptionsFunction,
  selectObatsTemplateUseOptions,
} from 'redux/restResources/detail/obat/selectors';
import { selectWeathersTemplateOptionsFunction } from 'redux/restResources/detail/weather/selectors';
import { IRestResource } from 'types/RestResource';
import { rrc } from 'utils/strings/resourceClasses';

import { fetchGeometriesTemplatesSagaAction } from 'redux/restResources/detail/geometry/actions';
import { fetchObatsTemplatesSagaAction } from 'redux/restResources/detail/obat/actions';
import { fetchWeathersTemplatesSagaAction } from 'redux/restResources/detail/weather/actions';
import { MyProjectForm as Component } from './MyProjectForm.component';

interface IOwnProps {
  getCreationSuccessLandingPageUrl: (routeProjectId: string, createdGeometry: IRestResource) => string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    projectToEdit: selectFormInitialResourceFromTable(rrc.project)(rootState),
    organizationOptions: selectMyMemberOrganizationOptions(rootState),
    templateUseOptions: selectObatsTemplateUseOptions(rootState),
    selectTemplateAgeOptions: selectObatsTemplateAgeOptionsFunction(rootState),
    meteonormTemplateOptions: selectWeathersTemplateOptionsFunction(rootState)('template_weather_meteonorm'),
  };
};

const mapDispatchToProps = {
  fetchObatsTemplates: fetchObatsTemplatesSagaAction,
  fetchWeathersTemplates: fetchWeathersTemplatesSagaAction,
  fetchGeometriesTemplates: fetchGeometriesTemplatesSagaAction,
};

export const MyProjectForm = connect(mapStateToProps, mapDispatchToProps)(Component);
