import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { selectGeometriesTemplateOptions } from 'redux/restResources/detail/geometry/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { IRestResource } from 'types/RestResource';
import { rrc } from 'utils/strings/resourceClasses';

import { fetchGeometriesTemplatesSagaAction } from './../../../../redux/restResources/detail/geometry/actions';
import { GeometryForm as Component } from './GeometryForm.component';
interface IOwnProps {
  getCreationSuccessLandingPageUrl: (routeProjectId: string, createdGeometry: IRestResource) => string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    geometryToEdit: selectFormInitialResourceFromTable(rrc.geometry)(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
    templateOptions: selectGeometriesTemplateOptions(rootState),
  };
};

const mapDispatchToProps = {
  fetchGeometriesTemplates: fetchGeometriesTemplatesSagaAction,
};

export const GeometryForm = connect(mapStateToProps, mapDispatchToProps)(Component);
