import { CLOSE_NO_FREE_SEATS_MODAL, INoFreeSeatsModalActions, OPEN_NO_FREE_SEATS_MODAL } from './actions';

export interface INoFreeSeatsModalState {
  isOpen: boolean;
}

export const initialState = {
  isOpen: false,
};

export const noFreeSeatsModalReducer = (
  state: INoFreeSeatsModalState = initialState,
  action: INoFreeSeatsModalActions
): INoFreeSeatsModalState => {
  switch (action.type) {
    case OPEN_NO_FREE_SEATS_MODAL:
      return { ...state, isOpen: true };

    case CLOSE_NO_FREE_SEATS_MODAL:
      return { ...state, isOpen: false };

    default:
      return state;
  }
};
