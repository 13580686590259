import { TokenRequests } from 'api/requests/TokenRequests';
import { SagaIterator } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { getTokenDetails, isTokenValid } from 'utils/functions/isTokenValid';
import { fetchMyUserSaga } from '../restResources/detail/me/sagas';

import { apiRequestErrorAction } from 'redux/apiRequests/actions';
import {
  FETCH_TOKENS_SAGA_ACTION,
  IFetchTokensSagaAction,
  UPDATE_IS_REFRESHING_ACCESS_ACTION,
  updateIsRefreshingAccessAction,
  updateTokensAction,
} from './actions';
import { selectAccessToken, selectIsRefreshingAccess, selectRefreshToken } from './selectors';

// FETCH TOKENS, happens only when a user signs in.

export function* fetchTokensSaga(payload: IFetchTokensSagaAction['payload']): SagaIterator {
  const { email, password } = payload;

  try {
    const tokens = yield call(TokenRequests.fetchTokens, email, password);
    yield put(updateTokensAction(tokens));
    yield call(fetchMyUserSaga);
  } catch (requestError) {
    /** this request is performed without authentication so we should manage the error here */
    const errors = { ...JSON.parse(requestError.text), status: requestError.status };
    yield put(apiRequestErrorAction('fetchTokens', errors.errors, errors.error_message, errors.status));
  }
}

export function* fetchTokensActionSaga(action: IFetchTokensSagaAction): SagaIterator {
  yield call(fetchTokensSaga, action.payload);
}

export function* fetchFreshAccessTokenSaga(refreshToken: string): SagaIterator {
  const freshAccessToken = yield call(TokenRequests.refreshTokens, refreshToken);
  // check in case if freshAccessToken is valid (can bug when the device time is not exact !!)
  if (isTokenValid(freshAccessToken.access)) {
    yield put(updateTokensAction(freshAccessToken));
  } else {
    yield put(
      updateTokensAction({
        access: null,
      })
    );
    console.error(
      'Check the device clock is correctly set, because the new access token is already expired',
      getTokenDetails(freshAccessToken.access)
    );
  }
}

// GET VALID ACCESS TOKEN, verify if the token is valid before each apiRequestSaga
export function* getValidAccessTokenSaga(): SagaIterator {
  let accessToken = yield select(selectAccessToken);

  if (!accessToken) {
    return null; /** the user will get a 401 and be redirected to login */
  }

  if (isTokenValid(accessToken)) {
    return accessToken; /**  if access token is still valid, all right, let's use it */
  }

  /** if access token is not valid anymore, should make a request to get a new one (refresh) using the refresh token */
  const refreshToken = yield select(selectRefreshToken);

  if (!refreshToken) {
    return null; /** the user will get a 401 and be redirected to login */
  }

  try {
    yield put(updateIsRefreshingAccessAction(true));
    yield call(fetchFreshAccessTokenSaga, refreshToken);
    accessToken = yield select(selectAccessToken);

    return accessToken;
  } catch (requestError) {
    throw requestError;
  } finally {
    yield put(updateIsRefreshingAccessAction(false));
  }
}

// CALL API WITH AUTHENTICATION, add authentication to each api request

// tslint:disable-next-line:no-any
export function* callApiWithAuthenticationSaga(request: (...args: any[]) => void, ...args: any[]): SagaIterator {
  try {
    const isRefreshingAccess = yield select(selectIsRefreshingAccess);
    if (isRefreshingAccess) {
      // wait for the access token is retrieved to launch further requests
      yield take(UPDATE_IS_REFRESHING_ACCESS_ACTION);
    }
    const accessToken = yield call(getValidAccessTokenSaga);

    return yield call(request, accessToken, ...args);
  } catch (requestError) {
    throw requestError;
  }
}

export function* tokenActionsSagas(): Generator {
  yield takeLatest(FETCH_TOKENS_SAGA_ACTION, fetchTokensActionSaga);
}
