import { connect } from 'react-redux';
import { selectObatContentsFlatOptionsList } from 'redux/enums/selectors';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { ocfe } from 'utils/configs/enums';
import { orc } from 'utils/strings/resourceClasses';

import { ScriptForm as ScriptFormComponent } from './ScriptForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const scriptToEdit = selectFormInitialResourceFromTable(orc.script)(rootState);

  return {
    scriptToEdit,
    typeOptions: selectObatContentsFlatOptionsList(ocfe.script_types)(rootState),
  };
};

const mapDispatchToProps = {};

export const ScriptForm = connect(mapStateToProps, mapDispatchToProps)(ScriptFormComponent);
