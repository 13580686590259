import { _parseInt } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { IObatPositionOption, IObatPositionOptions } from 'redux/obatResources/selectors';
import { getPositionOptionLabel } from 'utils/functions/obatResources/position/getPositionOptionLabel';

export const getEditionPositionOptions = (
  positionOptions: IObatPositionOptions,
  refToAdaptTo: string
): IObatPositionOptions => {
  let afterRefToAdaptTo = false;

  return _reduce(
    positionOptions,
    (adaptedPositionOptions: IObatPositionOptions, positionOption: IObatPositionOption): IObatPositionOptions => {
      if (positionOption.afterRef === refToAdaptTo) {
        afterRefToAdaptTo = true;

        return adaptedPositionOptions;
      }

      if (!afterRefToAdaptTo) {
        return [...adaptedPositionOptions, positionOption];
      }

      const value = _parseInt(positionOption.value, 10) - 1;
      const afterRef = positionOption.afterRef;
      const label = afterRef ? getPositionOptionLabel(value, afterRef) : '';

      return [
        ...adaptedPositionOptions,
        {
          value: `${value}`,
          afterRef,
          label,
        },
      ];
    },
    []
  );
};
