import { Action } from 'redux';
import { ITokens } from 'types/Tokens';

export const FETCH_TOKENS_SAGA_ACTION = 'FETCH_TOKENS_SAGA_ACTION';
export interface IFetchTokensSagaAction extends Action<'FETCH_TOKENS_SAGA_ACTION'> {
  payload: {
    email: string;
    password: string;
  };
}
export const fetchTokensSagaAction = (email: string, password: string): IFetchTokensSagaAction => ({
  type: FETCH_TOKENS_SAGA_ACTION,
  payload: { email, password },
});

export const UPDATE_IS_REFRESHING_ACCESS_ACTION = 'UPDATE_IS_REFRESHING_ACCESS_ACTION';
export interface IUpdateIsRefreshingAccessAction extends Action<'UPDATE_IS_REFRESHING_ACCESS_ACTION'> {
  payload: {
    isRefreshingAccess: boolean;
  };
}
export const updateIsRefreshingAccessAction = (isRefreshingAccess: boolean): IUpdateIsRefreshingAccessAction => ({
  type: UPDATE_IS_REFRESHING_ACCESS_ACTION,
  payload: { isRefreshingAccess },
});

export const UPDATE_TOKENS_ACTION = 'UPDATE_TOKENS_ACTION';
export interface IUpdateTokensAction extends Action<'UPDATE_TOKENS_ACTION'> {
  payload: Partial<ITokens>;
}
export const updateTokensAction = (payload: Partial<ITokens>): IUpdateTokensAction => ({
  type: UPDATE_TOKENS_ACTION,
  payload,
});

export const CLEAR_TOKENS_ACTION = 'CLEAR_TOKENS_ACTION';
export interface IClearTokensAction extends Action<'CLEAR_TOKENS_ACTION'> {}
export const clearTokensAction = (): IClearTokensAction => ({
  type: CLEAR_TOKENS_ACTION,
});

export type ITokensActions = IUpdateTokensAction | IClearTokensAction | IUpdateIsRefreshingAccessAction;
