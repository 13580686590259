import { FilterCrossIcon } from 'components/Forms/FilterForm/FilterCrossIcon';
import { FilterTextInput } from 'components/Forms/FilterForm/FilterTextInput';
import { FilterDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FilterDropdownInput';
import { _find, _get } from 'libs/lodash';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { IProps } from './FilterForm.container';

interface IState {
  isHovered: boolean;
}
export class FilterForm extends React.Component<IProps> {
  public state: IState = {
    isHovered: false,
  };
  public render(): ReactNode {
    const { columnId, minWidth, maxWidth, tableFilterParams, tableFilterValues } = this.props;

    const filterValue = _get(tableFilterValues, columnId) || '';
    const columnFilterParams = _find(tableFilterParams, ['id', columnId]);
    const selectOptions = columnFilterParams && columnFilterParams.filterOptions;

    return (
      <Container minWidth={minWidth} maxWidth={maxWidth}>
        {selectOptions && (
          <FilterDropdownInput
            field={columnId}
            value={filterValue}
            options={selectOptions}
            onSelect={this.onSelect}
            optionsPosition={'fixed'}
          />
        )}
        {!selectOptions && (
          <FilterTextInput
            value={filterValue}
            onChange={this.onTextFieldChange}
            InputProps={{
              endAdornment: (
                <FilterCrossIcon
                  onMouseEnter={this.onMouseEnterX}
                  onMouseLeave={this.onMouseLeaveX}
                  onClick={this.resetColumnInput}
                  isHovered={this.state.isHovered}
                />
              ),
            }}
          />
        )}
      </Container>
    );
  }

  private onMouseEnterX = () => {
    this.setState({ isHovered: true });
  };
  private onMouseLeaveX = () => {
    this.setState({ isHovered: false });
  };
  private onSelect = (_field: string, value: string) => {
    const { updateColumnValue, columnId } = this.props;

    updateColumnValue(columnId, value);
  };

  private onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { updateColumnValue, columnId } = this.props;
    updateColumnValue(columnId, event.target.value);
  };

  private resetColumnInput = (): void => {
    const { updateColumnValue, columnId } = this.props;

    updateColumnValue(columnId, '');
  };
}

interface IContainerProps {
  minWidth: number | undefined;
  maxWidth: number | undefined;
}

const Container = styled.div`
  min-width: ${(props: IContainerProps): string => (props.minWidth ? props.minWidth + 'px' : '100px')};
  max-width: ${(props: IContainerProps): string => (props.maxWidth ? props.maxWidth + 'px' : '')};
  width: 100%;
`;
