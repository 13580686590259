import { IAvailableActions } from 'components/Datatable/DatatableActions/DatatableActions.container';
import { _size } from 'libs/lodash';
import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { openDeletionModalAction } from 'redux/modals/deletionModal/actions';
import { openRestResourceCommitmentsModalAction } from 'redux/modals/restResourceCommitmentsModal/actions';
import { IRootState } from 'redux/reducer';
import { fetchMyOrganizationsSagaAction } from 'redux/restResources/detail/me/actions';
import { selectMyOrganizations } from 'redux/restResources/detail/me/selectors';
import { copyRestResourceSagaAction } from 'redux/restResources/generic/actions';
import { selectRouteResource } from 'redux/routing/selectors';
import { fetchTableInitialDataSagaAction } from 'redux/table/actions';
import { selectTableResources } from 'redux/table/selectors';
import { IRestResource } from 'types/RestResource';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { gra } from 'utils/strings/requestActions';
import { IRestResourceClass } from 'utils/strings/resourceClasses';
import { rrc } from 'utils/strings/resourceClasses';

import { RestResourcesList as Component } from './RestResourcesList.component';

interface IOwnProps {
  // tslint:disable-next-line:no-any
  FormComponent?: any;
  defaultSort?: { columnId: string; order: 'asc' | 'desc' };
  getCreationSuccessLandingPageUrl?: (routeProjectId: string, createdResource: IRestResource) => string;
  resourceClass: IRestResourceClass;
  parentResourceClass?: IRestResourceClass;
  columnsParams: ITableColumnParams[];
  availableActions?: IAvailableActions;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  const { resourceClass } = ownProps;

  const routeProject = selectRouteResource(rrc.project)(rootState);
  const routeOrganization = selectRouteResource(rrc.organization)(rootState);
  const routeMultiSimulationGroup = selectRouteResource(rrc.multi_simulation_group)(rootState);

  let parentId;
  switch (ownProps.parentResourceClass) {
    case rrc.multi_simulation_group:
      parentId = routeMultiSimulationGroup.id;
      break;
    case rrc.project:
      parentId = routeProject.id;
      break;
    case rrc.organization:
      parentId = routeOrganization.id;
      break;
    default:
      parentId = '';
      break;
  }

  const tableResources = selectTableResources(rootState)(resourceClass, parentId);
  const isDataEmpty = !!tableResources && _size(tableResources.full) === 0;

  return {
    routeProject,
    parentId,
    tableResources,
    isDataEmpty,
    isLoading: selectIsApiRequestPending(getGenericApiRequestKey(gra.list, resourceClass))(rootState),
    myOrganizations: selectMyOrganizations(rootState),
  };
};

const mapDispatchToProps = {
  copyRestResource: copyRestResourceSagaAction,
  fetchTableInitialData: fetchTableInitialDataSagaAction,
  openFormDrawer: openFormDrawerSagaAction,
  openDeletionModal: openDeletionModalAction,
  fetchMyOrganizations: fetchMyOrganizationsSagaAction,
  openCommitmentsModal: openRestResourceCommitmentsModalAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export const RestResourcesList = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
