import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { ISelectInputProps } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput/DropdownInput.component';
import React, { PureComponent, ReactNode } from 'react';
import { $tcc } from 'utils/functions/translate';

export interface ISwitchInputProps extends ISelectInputProps {
  value: boolean;
  noLabel?: boolean;
}

export class SwitchInput extends PureComponent<ISwitchInputProps> {
  public render(): ReactNode {
    const { value, errorText, isDisabled, noLabel, inputRef, field } = this.props;
    const label = noLabel ? '' : <span>{this.props.label || $tcc(field)}</span>;

    return (
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              name={field}
              checked={!!value} /* to avoid a warning in the console */
              onChange={this.onChange}
              value={!!value}
              color={'primary'}
              disabled={isDisabled}
              ref={inputRef}
            />
          }
          label={label}
        />
        {!!errorText && <FormHelperText error>{errorText}</FormHelperText>}
      </FormControl>
    );
  }

  private onChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    const { field, onSelect } = this.props;
    onSelect(field, checked);
  };
}
