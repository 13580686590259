import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { rrc } from 'utils/strings/resourceClasses';

import { MyOrganizationForm as Component } from './MyOrganizationForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    organizationToEdit: selectFormInitialResourceFromTable(rrc.organization)(rootState),
  };
};

const mapDispatchToProps = {};

export const MyOrganizationForm = connect(mapStateToProps, mapDispatchToProps)(Component);
