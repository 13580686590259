import { _includes } from 'libs/lodash';
import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath } from 'redux/apiRequests/selectors';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { selectFormDrawerWidth } from 'redux/form/selectors';
import { openAlertModalAction } from 'redux/modals/alertModal/actions';
import { IRootState } from 'redux/reducer';
import {
  clearGeometryThreejsAction,
  downloadGeometryThreejsSagaAction,
  exportGeometrySagaAction,
  importGeometrySagaAction,
  refreshImportGeometryTaskSagaAction,
} from 'redux/restResources/detail/geometry/actions';
import { selectGeometryImportProgress, selectGeometryThreejsData } from 'redux/restResources/detail/geometry/selectors';
import { selectIsSeatMissing, selectIsWritePermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { fk } from 'utils/strings/formKeys';
import { ps } from 'utils/strings/progressStatus';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { Viewer3D as Viewer3DComponent } from './Viewer3D.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  const routeGeometry = selectRouteResource(rrc.geometry)(rootState);
  const geometryImportProgress = selectGeometryImportProgress(rootState);
  const isLoading = geometryImportProgress !== ps.finished;
  const isImportingOrFetching = _includes([ps.fetchingData, ps.importingGeometry], geometryImportProgress);
  const isFloorspace = routeGeometry.format === 'floorspace';

  return {
    routeGeometry,
    routeProject: selectRouteResource(rrc.project)(rootState),
    threejsData: selectGeometryThreejsData(rootState),
    downloadError: selectFormApiErrorsTranslationPath(dra.downloadThreejs)(rootState),
    isLoading,
    isImportingOrFetching,
    isFloorspace,
    geometryImportProgress,
    isWritePermissionMissing: selectIsWritePermissionMissing(rootState),
    isSeatMissing: selectIsSeatMissing(rootState),
    formDrawerWidth: selectFormDrawerWidth(rootState),
  };
};

const mapDispatchToProps = {
  clearGeometryThreejs: clearGeometryThreejsAction,
  downloadGeometryThreejs: downloadGeometryThreejsSagaAction,
  importGeometry: importGeometrySagaAction,
  openFormDrawer: openFormDrawerSagaAction,
  exportGeometry: exportGeometrySagaAction,
  refreshImportGeometryTask: refreshImportGeometryTaskSagaAction,
  openAlertModal: openAlertModalAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: {}) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openImportGeometryForm: () =>
    dispatchProps.openFormDrawer(fk.import, {
      resourceId: stateProps.routeGeometry.id,
      resourceClass: rrc.geometry,
    }),
});

export const Viewer3D = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Viewer3DComponent);
