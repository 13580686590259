import { Grid } from '@material-ui/core';
import { HyperLink } from 'components/Links/HyperLink';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { _find } from 'libs/lodash';
import { _get } from 'libs/lodash';
import { _upperFirst } from 'libs/lodash';
import { toPlural } from 'libs/pluralize';
import React, { PureComponent, ReactNode } from 'react';
import { AlertTriangle } from 'react-feather';
import { navigateRoute } from 'routing/navigateRoute';
import {
  geometryMenuPages,
  getGeometryMenuPageRoute,
  getObatMenuPageRoute,
  getWeatherMenuPageRoute,
  obatMenuPages,
  weatherMenuPages,
} from 'routing/routes';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';

import { IProps } from './SimulationResourceConfig.container';

export class SimulationResourceConfig extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeProject, configOptions, resourceClass, resourceId, isObsolete } = this.props;

    const resourceOption = _find(_get(configOptions, toPlural(resourceClass)), ['value', resourceId]);

    if (!resourceOption) {
      return '-';
    }

    const resource = { id: resourceOption.value, name: resourceOption.label };

    const redirectToResource = (e: React.MouseEvent): void => {
      let route = '';
      switch (resourceClass) {
        case rrc.geometry: {
          route = getGeometryMenuPageRoute(geometryMenuPages.default, routeProject.id, resource.id);
          break;
        }
        case rrc.obat: {
          route = getObatMenuPageRoute(obatMenuPages.default, routeProject.id, resource.id);
          break;
        }
        case rrc.weather: {
          route = getWeatherMenuPageRoute(weatherMenuPages.default, routeProject.id, resource.id);
          break;
        }
      }
      navigateRoute(route, e);
    };

    return (
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <HyperLink onMouseDown={redirectToResource}>{resource.name}</HyperLink>
        </Grid>
        <Grid item>
          <AlertIconContainer>
            {isObsolete && (
              <SimpleTooltip content={$t(`obsolete${_upperFirst(resourceClass)}`)} placement={'bottom'}>
                <AlertTriangle color={theme.colors.warning} size={14} />
              </SimpleTooltip>
            )}
          </AlertIconContainer>
        </Grid>
      </Grid>
    );
  }
}

const AlertIconContainer = styled.div`
  margin-bottom: -4px;
`;
