import { EditorConfiguration } from 'codemirror';
import 'codemirror/addon/display/placeholder';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/python/python';
import 'codemirror/theme/darcula.css';
import React, { PureComponent, ReactNode } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

interface IProps {
  code: string;
  onChange?: (e: string) => void;
  readonly?: boolean;
}
export class CodeEditor extends PureComponent<IProps> {
  public handleChange = (_editor: object, _data: object, value: string): void => {
    this.setState({ code: value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  public render(): ReactNode {
    const options: EditorConfiguration = {
      lineNumbers: true,
      readOnly: this.props.readonly ? 'nocursor' : false,
      mode: 'python',
      theme: 'darcula',
      placeholder: 'Enter your code',
    };

    return <CodeMirror value={this.props.code} onBeforeChange={this.handleChange} options={options} />;
  }
}
