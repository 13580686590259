// tslint:disable:no-any

import { Action } from 'redux';
import {
  IHourlyResultsPlot,
  IHourlyResultsYearlyData,
  IMonthlyResultsTemplate,
} from 'redux/restResources/detail/simulationGroup/reducer';
import { ISimulationListed, ISimulationMetadata, ISimulationRetrieved } from 'types/SimulationGroup/Simulation';
import { ISimulationGroupConfigOptions } from 'types/SimulationGroup/SimulationGroup';
import { IRestResourceClass } from 'utils/strings/resourceClasses';
import { IMonthlyResultsSection, IResultsView } from 'utils/strings/results';

// _____ FETCH_SIMULATION_GROUP_CONFIG_OPTIONS_SAGA_ACTION _____

export const FETCH_SIMULATION_GROUP_CONFIG_OPTIONS_SAGA_ACTION = 'FETCH_SIMULATION_GROUP_CONFIG_OPTIONS_SAGA_ACTION';
export interface IFetchSimulationGroupConfigOptionsSagaAction
  extends Action<'FETCH_SIMULATION_GROUP_CONFIG_OPTIONS_SAGA_ACTION'> {}
export const fetchSimulationGroupConfigOptionsSagaAction = (): IFetchSimulationGroupConfigOptionsSagaAction => ({
  type: FETCH_SIMULATION_GROUP_CONFIG_OPTIONS_SAGA_ACTION,
});

// _____ UPDATE_SIMULATION_GROUP_CONFIG_OPTIONS_ACTION _____

export const UPDATE_SIMULATION_GROUP_CONFIG_OPTIONS_ACTION = 'UPDATE_SIMULATION_GROUP_CONFIG_OPTIONS_ACTION';
export interface IUpdateSimulationGroupConfigOptionsAction
  extends Action<'UPDATE_SIMULATION_GROUP_CONFIG_OPTIONS_ACTION'> {
  payload: {
    configOptions: ISimulationGroupConfigOptions;
  };
}
export const updateSimulationGroupConfigOptionsAction = (
  configOptions: ISimulationGroupConfigOptions
): IUpdateSimulationGroupConfigOptionsAction => ({
  type: UPDATE_SIMULATION_GROUP_CONFIG_OPTIONS_ACTION,
  payload: {
    configOptions,
  },
});

// _____ FETCH_OBAT_VARIANTS_SAGA_ACTION _____

export const FETCH_OBAT_VARIANTS_SAGA_ACTION = 'FETCH_OBAT_VARIANTS_SAGA_ACTION';
export interface IFetchObatVariantsSagaAction extends Action<'FETCH_OBAT_VARIANTS_SAGA_ACTION'> {
  payload: {
    obatId: string;
  };
}
export const fetchObatVariantsSagaAction = (obatId: string): IFetchObatVariantsSagaAction => ({
  type: FETCH_OBAT_VARIANTS_SAGA_ACTION,
  payload: {
    obatId,
  },
});

// _____ UPDATE_VARIANT_OPTIONS_BY_OBAT_ACTION _____

export const UPDATE_VARIANT_OPTIONS_BY_OBAT_ACTION = 'UPDATE_VARIANT_OPTIONS_BY_OBAT_ACTION';
export interface IUpdatevariantOptionsByObatAction extends Action<'UPDATE_VARIANT_OPTIONS_BY_OBAT_ACTION'> {
  payload: {
    obatId: string;
    obatVariantOptions: string[];
  };
}
export const updateVariantOptionsByObatAction = (
  obatId: string,
  obatVariantOptions: string[]
): IUpdatevariantOptionsByObatAction => ({
  type: UPDATE_VARIANT_OPTIONS_BY_OBAT_ACTION,
  payload: {
    obatId,
    obatVariantOptions,
  },
});

// _____ RUN_SIMULATION_SAGA_ACTION _____

export const RUN_SIMULATION_SAGA_ACTION = 'RUN_SIMULATION_SAGA_ACTION';
export interface IRunSimulationSagaAction extends Action<'RUN_SIMULATION_SAGA_ACTION'> {}
export const runSimulationSagaAction = (): IRunSimulationSagaAction => ({
  type: RUN_SIMULATION_SAGA_ACTION,
});

// _____ TOGGLE_IS_SIMULATION_IN_PROGRESS_ACTION _____

export const TOGGLE_IS_SIMULATION_IN_PROGRESS_ACTION = 'TOGGLE_IS_SIMULATION_IN_PROGRESS_ACTION';
export interface IToggleIsSimulationInProgressAction extends Action<'TOGGLE_IS_SIMULATION_IN_PROGRESS_ACTION'> {
  payload: {
    isSimulationInProgress: boolean;
  };
}
export const toggleIsSimulationInProgressAction = (
  isSimulationInProgress: boolean
): IToggleIsSimulationInProgressAction => ({
  type: TOGGLE_IS_SIMULATION_IN_PROGRESS_ACTION,
  payload: {
    isSimulationInProgress,
  },
});

// _____ REFRESH_SIMULATION_GROUP_SAGA_ACTION _____

export const REFRESH_SIMULATION_GROUP_SAGA_ACTION = 'REFRESH_SIMULATION_GROUP_SAGA_ACTION';
export interface IRefreshSimulationGroupSagaAction extends Action<'REFRESH_SIMULATION_GROUP_SAGA_ACTION'> {
  payload: {
    resourceClass: IRestResourceClass;
  };
}
export const refreshSimulationGroupSagaAction = (
  resourceClass: IRestResourceClass
): IRefreshSimulationGroupSagaAction => ({
  type: REFRESH_SIMULATION_GROUP_SAGA_ACTION,
  payload: {
    resourceClass,
  },
});

// _____ TOGGLE_SCROLL_ACTION _____

export const TOGGLE_LOGS_SCROLL_ACTION = 'TOGGLE_LOGS_SCROLL_ACTION';
export interface IToggleLogsScrollAction extends Action<'TOGGLE_LOGS_SCROLL_ACTION'> {}
export const toggleLogsScrollAction = (): IToggleLogsScrollAction => ({
  type: TOGGLE_LOGS_SCROLL_ACTION,
});

// =======================
// MULTI SIMULATION GROUPS
// =======================

// _____ UPDATE_SIMULATIONS_LIST_ACTION _____

export const UPDATE_SIMULATIONS_LIST_ACTION = 'UPDATE_SIMULATIONS_LIST_ACTION';
export interface IUpdateSimulationsListAction extends Action<'UPDATE_SIMULATIONS_LIST_ACTION'> {
  payload: {
    simulationsList: ISimulationListed[];
  };
}
export const updateSimulationsListAction = (simulationsList: ISimulationListed[]): IUpdateSimulationsListAction => ({
  type: UPDATE_SIMULATIONS_LIST_ACTION,
  payload: {
    simulationsList,
  },
});

// ==========
// SIMULATION
// ==========

// _____ UPDATE_SIMULATION_ACTION _____

export const UPDATE_SIMULATION_ACTION = 'UPDATE_SIMULATION_ACTION';
export interface IUpdateSimulationAction extends Action<'UPDATE_SIMULATION_ACTION'> {
  payload: {
    simulation: ISimulationListed | ISimulationRetrieved;
  };
}
export const updateSimulationAction = (
  simulation: ISimulationListed | ISimulationRetrieved
): IUpdateSimulationAction => ({
  type: UPDATE_SIMULATION_ACTION,
  payload: {
    simulation,
  },
});

// _____ REFRESH_SIMULATION_SAGA_ACTION _____

export const REFRESH_SIMULATION_SAGA_ACTION = 'REFRESH_SIMULATION_SAGA_ACTION';
export interface IRefreshSimulationSagaAction extends Action<'REFRESH_SIMULATION_SAGA_ACTION'> {
  payload: {
    simulationId: string;
  };
}
export const refreshSimulationSagaAction = (simulationId: string): IRefreshSimulationSagaAction => ({
  type: REFRESH_SIMULATION_SAGA_ACTION,
  payload: {
    simulationId,
  },
});

// _____ CLEAR_SIMULATION_ACTION _____

export const CLEAR_SIMULATION_ACTION = 'CLEAR_SIMULATION_ACTION';
export interface IClearSimulationAction extends Action<'CLEAR_SIMULATION_ACTION'> {}
export const clearSimulationAction = (): IClearSimulationAction => ({
  type: CLEAR_SIMULATION_ACTION,
});

// _____ EXPORT_EPLUS_OUTPUTS_SAGA_ACTION _____

export const EXPORT_EPLUS_OUTPUTS_SAGA_ACTION = 'EXPORT_EPLUS_OUTPUTS_SAGA_ACTION';
export interface IExportEplusOutputsSagaAction extends Action<'EXPORT_EPLUS_OUTPUTS_SAGA_ACTION'> {
  payload: {
    simulation: ISimulationListed;
  };
}
export const exportEplusOutputsSagaAction = (simulation: ISimulationListed): IExportEplusOutputsSagaAction => ({
  type: EXPORT_EPLUS_OUTPUTS_SAGA_ACTION,
  payload: {
    simulation,
  },
});

// _____ EXPORT_HOURLY_CSV_SAGA_ACTION _____

export const EXPORT_HOURLY_CSV_SAGA_ACTION = 'EXPORT_HOURLY_CSV_SAGA_ACTION';
export interface IExportHourlyCsvSagaAction extends Action<'EXPORT_HOURLY_CSV_SAGA_ACTION'> {
  payload: {
    simulation: ISimulationListed;
  };
}
export const exportHourlyCsvSagaAction = (simulation: ISimulationListed): IExportHourlyCsvSagaAction => ({
  type: EXPORT_HOURLY_CSV_SAGA_ACTION,
  payload: {
    simulation,
  },
});

// ===============
// MONTHLY RESULTS
// ===============

// _____ UPDATE_MONTHLY_RESULTS_MODE_ACTION _____

export const UPDATE_MONTHLY_RESULTS_MODE_ACTION = 'UPDATE_MONTHLY_RESULTS_MODE_ACTION';
export interface IUpdateMonthlyResultsModeAction extends Action<'UPDATE_MONTHLY_RESULTS_MODE_ACTION'> {
  payload: {
    resultsMode: IResultsView;
  };
}
export const updateMonthlyResultsModeAction = (resultsMode: IResultsView): IUpdateMonthlyResultsModeAction => ({
  type: UPDATE_MONTHLY_RESULTS_MODE_ACTION,
  payload: {
    resultsMode,
  },
});

// _____ DOWNLOAD_MONTHLY_RESULTS_SECTION_DATA_SAGA_ACTION _____

export const DOWNLOAD_MONTHLY_RESULTS_SECTION_DATA_SAGA_ACTION = 'DOWNLOAD_MONTHLY_RESULTS_SECTION_DATA_SAGA_ACTION';
export interface IDownloadMonthlyResultsSectionDataSagaAction
  extends Action<'DOWNLOAD_MONTHLY_RESULTS_SECTION_DATA_SAGA_ACTION'> {}
export const downloadMonthlyResultsSectionDataSagaAction = (): IDownloadMonthlyResultsSectionDataSagaAction => ({
  type: DOWNLOAD_MONTHLY_RESULTS_SECTION_DATA_SAGA_ACTION,
});

// _____ UPDATE_MONTHLY_RESULTS_DATA_ACTION _____

export const UPDATE_MONTHLY_RESULTS_DATA_ACTION = 'UPDATE_MONTHLY_RESULTS_DATA_ACTION';
export interface IUpdateMonthlyResultsDataAction extends Action<'UPDATE_MONTHLY_RESULTS_DATA_ACTION'> {
  payload: {
    simulationId: string;
    section: string;
    sectionData: string;
  };
}
export const updateMonthlyResultsDataAction = (
  simulationId: string,
  section: string,
  sectionData: string
): IUpdateMonthlyResultsDataAction => ({
  type: UPDATE_MONTHLY_RESULTS_DATA_ACTION,
  payload: {
    simulationId,
    section,
    sectionData,
  },
});

// _____ TOGGLE_MONTHLY_RESULTS_IS_FORCE_PIVOT_UPDATE_ACTION _____

export const TOGGLE_MONTHLY_RESULTS_IS_FORCE_PIVOT_UPDATE_ACTION =
  'TOGGLE_MONTHLY_RESULTS_IS_FORCE_PIVOT_UPDATE_ACTION';
export interface IToggleMonthlyResultsForcePivotUpdateAction
  extends Action<'TOGGLE_MONTHLY_RESULTS_IS_FORCE_PIVOT_UPDATE_ACTION'> {
  payload: {
    forcePivotUpdate: boolean;
  };
}
export const toggleMonthlyResultsForcePivotUpdateAction = (
  forcePivotUpdate: boolean
): IToggleMonthlyResultsForcePivotUpdateAction => ({
  type: TOGGLE_MONTHLY_RESULTS_IS_FORCE_PIVOT_UPDATE_ACTION,
  payload: {
    forcePivotUpdate,
  },
});

// _____ UPDATE_MONTHLY_RESULTS_TEMPLATE_ACTION _____

export const UPDATE_MONTHLY_RESULTS_TEMPLATE_ACTION = 'UPDATE_MONTHLY_RESULTS_TEMPLATE_ACTION';
export interface IUpdateMonthlyResultsTemplateAction extends Action<'UPDATE_MONTHLY_RESULTS_TEMPLATE_ACTION'> {
  payload: {
    template: IMonthlyResultsTemplate;
    isInitialTemplate?: boolean;
  };
}
export const updateMonthlyResultsTemplateAction = (
  template: IMonthlyResultsTemplate,
  isInitialTemplate: boolean = false
): IUpdateMonthlyResultsTemplateAction => ({
  type: UPDATE_MONTHLY_RESULTS_TEMPLATE_ACTION,
  payload: {
    template,
    isInitialTemplate,
  },
});

// _____ UPDATE_MONTHLY_RESULTS_SECTION_ACTION _____

export const UPDATE_MONTHLY_RESULTS_SECTION_ACTION = 'UPDATE_MONTHLY_RESULTS_SECTION_ACTION';
export interface IUpdateMonthlyResultsSectionAction extends Action<'UPDATE_MONTHLY_RESULTS_SECTION_ACTION'> {
  payload: {
    section: IMonthlyResultsSection;
  };
}
export const updateMonthlyResultsSectionAction = (
  section: IMonthlyResultsSection
): IUpdateMonthlyResultsSectionAction => ({
  type: UPDATE_MONTHLY_RESULTS_SECTION_ACTION,
  payload: {
    section,
  },
});

// _____ TOGGLE_IS_DOWNLOADING_MONTHLY_RESULTS_DATA_ACTION _____

export const TOGGLE_IS_DOWNLOADING_MONTHLY_RESULTS_DATA_ACTION = 'TOGGLE_IS_DOWNLOADING_MONTHLY_RESULTS_DATA_ACTION';
export interface IToggleIsDownloadingMonthlyResultsDataAction
  extends Action<'TOGGLE_IS_DOWNLOADING_MONTHLY_RESULTS_DATA_ACTION'> {
  payload: {
    isDownloading: boolean;
  };
}
export const toggleIsDownloadingMonthlyResultsDataAction = (
  isDownloading: boolean
): IToggleIsDownloadingMonthlyResultsDataAction => ({
  type: TOGGLE_IS_DOWNLOADING_MONTHLY_RESULTS_DATA_ACTION,
  payload: {
    isDownloading,
  },
});

export const CLEAR_MONTHLY_RESULTS = 'CLEAR_MONTHLY_RESULTS';
export interface IClearMonthlyResultsAction extends Action<'CLEAR_MONTHLY_RESULTS'> {}
export const clearMonthlyResultsAction = (): IClearMonthlyResultsAction => ({
  type: CLEAR_MONTHLY_RESULTS,
});

// ==============
// HOURLY RESULTS
// ==============

// _____ UPDATE_HOURLY_RESULTS_MODE_ACTION _____

export const UPDATE_HOURLY_RESULTS_MODE_ACTION = 'UPDATE_HOURLY_RESULTS_MODE_ACTION';
export interface IUpdateHourlyResultsModeAction extends Action<'UPDATE_HOURLY_RESULTS_MODE_ACTION'> {
  payload: {
    resultsMode: IResultsView;
  };
}
export const updateHourlyResultsModeAction = (resultsMode: IResultsView): IUpdateHourlyResultsModeAction => ({
  type: UPDATE_HOURLY_RESULTS_MODE_ACTION,
  payload: {
    resultsMode,
  },
});

// _____ DOWNLOAD_SIMULATION_METADATA_SAGA_ACTION _____

export const DOWNLOAD_SIMULATION_METADATA_SAGA_ACTION = 'DOWNLOAD_SIMULATION_METADATA_SAGA_ACTION';
export interface IDownloadSimulationMetadataSagaAction extends Action<'DOWNLOAD_SIMULATION_METADATA_SAGA_ACTION'> {
  payload: {
    simulationId: string;
  };
}
export const downloadSimulationMetadataSagaAction = (simulationId: string): IDownloadSimulationMetadataSagaAction => ({
  type: DOWNLOAD_SIMULATION_METADATA_SAGA_ACTION,
  payload: {
    simulationId,
  },
});

// _____ UPDATE_HOURLY_RESULTS_METADATA_ACTION _____

export const UPDATE_HOURLY_RESULTS_METADATA_ACTION = 'UPDATE_HOURLY_RESULTS_METADATA_ACTION';
export interface IUpdateHourlyResultsMetadataAction extends Action<'UPDATE_HOURLY_RESULTS_METADATA_ACTION'> {
  payload: {
    simulationId: string;
    simulationMetadata: ISimulationMetadata;
  };
}
export const updateHourlyResultsMetadataAction = (
  simulationId: string,
  simulationMetadata: ISimulationMetadata
): IUpdateHourlyResultsMetadataAction => ({
  type: UPDATE_HOURLY_RESULTS_METADATA_ACTION,
  payload: {
    simulationId,
    simulationMetadata,
  },
});

// _____ DOWNLOAD_HOURLY_RESULTS_DATA_SAGA_ACTION _____

export const DOWNLOAD_HOURLY_RESULTS_DATA_SAGA_ACTION = 'DOWNLOAD_HOURLY_RESULTS_DATA_SAGA_ACTION';
export interface IDownloadHourlyResultsDataSagaAction extends Action<'DOWNLOAD_HOURLY_RESULTS_DATA_SAGA_ACTION'> {
  payload: {
    plotYear?: string;
  };
}
export const downloadHourlyResultsDataSagaAction = (plotYear?: string): IDownloadHourlyResultsDataSagaAction => ({
  type: DOWNLOAD_HOURLY_RESULTS_DATA_SAGA_ACTION,
  payload: {
    plotYear,
  },
});

// _____ UPDATE_HOURLY_RESULTS_DATA_ACTION _____

export const UPDATE_HOURLY_RESULTS_DATA_ACTION = 'UPDATE_HOURLY_RESULTS_DATA_ACTION';
export interface IUpdateHourlyResultsDataAction extends Action<'UPDATE_HOURLY_RESULTS_DATA_ACTION'> {
  payload: {
    yearsData: IHourlyResultsYearlyData;
    plot: IHourlyResultsPlot;
  };
}
export const updateHourlyResultsDataAction = (
  yearsData: IHourlyResultsYearlyData,
  plot: IHourlyResultsPlot
): IUpdateHourlyResultsDataAction => ({
  type: UPDATE_HOURLY_RESULTS_DATA_ACTION,
  payload: {
    yearsData,
    plot,
  },
});

// _____ TOGGLE_IS_DOWNLOADING_HOURLY_RESULTS_DATA_ACTION _____

export const TOGGLE_IS_DOWNLOADING_HOURLY_RESULTS_DATA_ACTION = 'TOGGLE_IS_DOWNLOADING_HOURLY_RESULTS_DATA_ACTION';
export interface IToggleIsDownloadingHourlyResultsDataAction
  extends Action<'TOGGLE_IS_DOWNLOADING_HOURLY_RESULTS_DATA_ACTION'> {
  payload: {
    isDownloading: boolean;
  };
}
export const toggleIsDownloadingHourlyResultsDataAction = (
  isDownloading: boolean
): IToggleIsDownloadingHourlyResultsDataAction => ({
  type: TOGGLE_IS_DOWNLOADING_HOURLY_RESULTS_DATA_ACTION,
  payload: {
    isDownloading,
  },
});

// _____ UPDATE_HOURLY_RESULTS_PLOT_OPERATION_ACTION _____

export const UPDATE_HOURLY_RESULTS_PLOT_OPERATION_ACTION = 'UPDATE_HOURLY_RESULTS_PLOT_OPERATION_ACTION';
export interface IUpdateHourlyResultsPlotOperationAction extends Action<'UPDATE_HOURLY_RESULTS_PLOT_OPERATION_ACTION'> {
  payload: {
    operation: IHourlyResultsPlot['operation'];
  };
}
export const updateHourlyResultsPlotOperationAction = (
  operation: IHourlyResultsPlot['operation']
): IUpdateHourlyResultsPlotOperationAction => ({
  type: UPDATE_HOURLY_RESULTS_PLOT_OPERATION_ACTION,
  payload: {
    operation,
  },
});

export const CLEAR_HOURLY_RESULTS = 'CLEAR_HOURLY_RESULTS';
export interface IClearHourlyResultsAction extends Action<'CLEAR_HOURLY_RESULTS'> {}
export const clearHourlyResultsAction = (): IClearHourlyResultsAction => ({
  type: CLEAR_HOURLY_RESULTS,
});

export type ISimulationGroupActions =
  | IFetchSimulationGroupConfigOptionsSagaAction
  | IUpdateSimulationGroupConfigOptionsAction
  | IUpdatevariantOptionsByObatAction
  | IToggleIsSimulationInProgressAction
  | IToggleLogsScrollAction
  /* Multi simulation group */
  | IUpdateSimulationsListAction
  /* Simulation */
  | IUpdateSimulationAction
  | IClearSimulationAction
  /* Monthly Results */
  | IUpdateMonthlyResultsModeAction
  | IUpdateMonthlyResultsDataAction
  | IToggleMonthlyResultsForcePivotUpdateAction
  | IUpdateMonthlyResultsTemplateAction
  | IUpdateMonthlyResultsSectionAction
  | IClearMonthlyResultsAction
  /* Hourly Results */
  | IUpdateHourlyResultsModeAction
  | IDownloadSimulationMetadataSagaAction
  | IUpdateHourlyResultsMetadataAction
  | IUpdateHourlyResultsDataAction
  | IToggleIsDownloadingHourlyResultsDataAction
  | IUpdateHourlyResultsPlotOperationAction
  | IClearHourlyResultsAction;
