import { WithMenu } from 'components/Menus/WithMenu';
import React, { PureComponent, ReactNode } from 'react';
import { Briefcase, Folder } from 'react-feather';
import { getHomeMenuPageRoute, homeMenuPages } from 'routing/routes';

import { IProps } from './WithHomeMenu.container';

export class WithHomeDrawer extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isCollapsed, children, selectedPage } = this.props;

    const items = [
      { page: homeMenuPages.myProjects, Icon: <Folder /> },
      { page: homeMenuPages.myOrganizations, Icon: <Briefcase /> },
    ];

    return (
      <WithMenu
        isCollapsed={!!isCollapsed}
        selectedPage={selectedPage}
        items={items}
        getMenuPageRoute={getHomeMenuPageRoute}
      >
        {children}
      </WithMenu>
    );
  }
}
