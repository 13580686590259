import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { FormikProps } from 'formik';
import { _omit } from 'libs/lodash';
import { getYupPasswordValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { convertFormValuesEmptyStringToNull } from 'utils/functions/convertFormValuesEmptyStringToNull';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import { IRestResourceClass } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './PasswordForm.container';

export interface IPasswordFormValues {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
}

export class PasswordForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();
  private FormDrawer = getFormDrawer<IRestResourceClass>();
  private Form = getForm<IPasswordFormValues>();

  public render(): ReactNode {
    const { Form, FormDrawer, formFocusRef } = this;
    const { isSubmitting, errors } = this.props;

    return (
      <FormDrawer formKey={fk.userPassword} width={fdw.medium} title={$t('changePassword')} formFocusRef={formFocusRef}>
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          onSubmit={this.onSubmit}
          isSubmitting={isSubmitting}
          formApiErrors={errors}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IPasswordFormValues>): ReactNode => {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'old_password'}
                formikProps={formikProps}
                required
                type="password"
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput field={'new_password'} formikProps={formikProps} required type="password" />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'new_password_confirmation'}
                formikProps={formikProps}
                required
                type="password"
              />
            </Grid>
          </Grid>
        </>
      );
    };
  };

  private getInitialFormValues = (): IPasswordFormValues => {
    return {
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IPasswordFormValues> => {
    return yup.object().shape({
      old_password: getYupStringValidation(true),
      new_password: getYupPasswordValidation(),
      new_password_confirmation: getYupStringValidation(true).oneOf(
        [yup.ref('new_password'), null],
        'yupPasswordsDontMatch'
      ),
    }) as yup.ObjectSchema<IPasswordFormValues>;
  };

  private onSubmit = (formValues: IPasswordFormValues): void => {
    const { myUser, editPassword } = this.props;

    const requestParams = convertFormValuesEmptyStringToNull(_omit(formValues, ['new_password_confirmation']));

    // @ts-ignore: myUser always defined
    editPassword(myUser.id, requestParams);
  };
}
