// tslint:disable:no-any

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
// import Pagination from '@material-ui/lab/Pagination';
import { BREADCRUMB_BAR_HEIGHT } from 'components/BreadcrumbTopBar';
import { DatatableHeader } from 'components/Datatable/DatatableHeader';
import { DatatableRow } from 'components/Datatable/DatatableRow';
import { SCROLLBAR_HEIGHT } from 'components/Page/Page.component';
import { _find, _get, _includes, _intersection, _map, _orderBy, _size } from 'libs/lodash';
import React, { Component, createRef, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';

import { IProps } from './Datatable.container';

export interface IState {
  sortColumnId: string;
  sortOrder: 'asc' | 'desc';
  offsetTop?: number | undefined;
}
export class Datatable extends Component<IProps> {
  public state: IState = {
    sortColumnId: _get(this.props, 'defaultSort.columnId') || '',
    sortOrder: _get(this.props, 'defaultSort.order') || 'desc',
    offsetTop: undefined,
  };

  private tableRef = createRef<HTMLDivElement>();

  public componentDidMount(): void {
    this.setOffsetTop();
  }

  public componentDidUpdate(): void {
    this.setOffsetTop();
  }

  public render(): ReactNode {
    const { isSelectable, tableColumnsParams, tableResources, selectedTableResourcesIdList } = this.props;
    const { sortColumnId, sortOrder, offsetTop } = this.state;

    if (!tableResources) {
      return null;
    }

    const selectedCount = _size(_intersection(tableResources.selected, tableResources.full));
    const filteredTableResources = tableResources.filtered;

    return (
      <div ref={this.tableRef}>
        <TableContainer offsetTop={offsetTop}>
          <Table size="small">
            <DatatableHeader
              isSelectable={isSelectable}
              selectedCount={selectedCount}
              rowCount={_size(filteredTableResources)}
              sortOrder={sortOrder}
              sortColumnId={sortColumnId}
              onSort={this.onSort}
              tableColumnsParams={tableColumnsParams}
            />
            <TableBody>
              {_map(this.sortResources(filteredTableResources), (tableResource: any, rowIndex: number) => {
                const isSelected = !!isSelectable && _includes(selectedTableResourcesIdList, tableResource.id);

                return (
                  <DatatableRow
                    key={(!!tableResource && tableResource.id) || rowIndex.toString()}
                    tableResource={tableResource}
                    tableColumnsParams={tableColumnsParams}
                    isSelectable={!!isSelectable}
                    isSelected={isSelected}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <BottomInfo>
          <DisplayedRows>{`${_size(tableResources.filtered)} ${$t('rowsDisplayedOutOf')} ${_size(
            tableResources.full
          )}`}</DisplayedRows>
          {/* <Pagination count={10} color="primary" /> */}
        </BottomInfo>
      </div>
    );
  }

  private setOffsetTop = () => {
    const offsetTop = _get(this.tableRef, 'current.offsetTop');
    if (offsetTop !== this.state.offsetTop) {
      this.setState({ offsetTop });
    }
  };

  private onSort = (columnId: string) => {
    const { sortColumnId, sortOrder } = this.state;

    return () => {
      const newSortingOrder = sortColumnId === columnId ? (sortOrder === 'desc' ? 'asc' : 'desc') : sortOrder;

      this.setState({ sortColumnId: columnId, sortOrder: newSortingOrder });
    };
  };

  private sortResources = (tableResources: any[]): any[] => {
    const { sortColumnId, sortOrder } = this.state;
    const { tableColumnsParams } = this.props;

    const sortColumnResourceField = _get(_find(tableColumnsParams, ['id', sortColumnId]), 'resourceField');

    return _orderBy(tableResources, sortColumnResourceField, sortOrder);
  };
}

const BOTTOM_INFO_HEIGHT = 52;

const BottomInfo = styled.div`
  background-color: ${theme.colors.pageBody};
  color: ${theme.colors.font.dark};
  border-top: solid 1px ${theme.colors.divider};
  box-sizing: border-box;
  height: ${BOTTOM_INFO_HEIGHT}px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const DisplayedRows = styled.div`
  padding-top: 6px;
`;

interface ITableContainerProps {
  offsetTop: number | null | undefined;
}

const TableContainer = styled.div`
  width: 100%;
  overflow: scroll;
  height: ${(props: ITableContainerProps): string =>
    `calc(100vh - ${BOTTOM_INFO_HEIGHT}px - ${SCROLLBAR_HEIGHT}px - ${props.offsetTop || BREADCRUMB_BAR_HEIGHT}px)`};
`;
