import React, { PureComponent, ReactNode } from 'react';
import { $t } from 'utils/functions/translate';

import { ModalInfoTooltip } from '../ModalInfoTooltip';

import { IProps } from './ObatResourceCommitmentsTooltip.container';

export class ObatResourceCommitmentsTooltip extends PureComponent<IProps> {
  public render(): ReactNode {
    const { editionField, placement, children } = this.props;

    const message = $t(editionField ? 'editCommitmentTooltipAlert' : 'deleteCommitmentTooltipAlert');

    return (
      <ModalInfoTooltip openModal={this.openModal} message={message} placement={placement}>
        {children}
      </ModalInfoTooltip>
    );
  }

  private openModal = (): void => {
    const { obatResource, editionField, openCommitmentsModal } = this.props;

    openCommitmentsModal(obatResource, editionField);
  };
}
