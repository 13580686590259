import {
  CLOSE_USER_CREATION_MODAL_ACTION,
  IUserCreationModalActions,
  OPEN_USER_CREATION_MODAL_ACTION,
} from './actions';

export interface IUserCreationModalState {
  isOpen: boolean;
  userEmail: string;
}

export const initialState = { isOpen: false, userEmail: '' };

export const userCreationModalReducer = (
  state: IUserCreationModalState = initialState,
  action: IUserCreationModalActions
): IUserCreationModalState => {
  switch (action.type) {
    case OPEN_USER_CREATION_MODAL_ACTION:
      return {
        ...state,
        isOpen: true,
        userEmail: action.payload.email,
      };

    case CLOSE_USER_CREATION_MODAL_ACTION:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};
