import { IObatResource } from 'types/Obat/ObatResource';

import {
  CLOSE_OBATCONTENT_COMMITMENTS_MODAL_ACTION,
  IObatResourceCommitmentsModalActions,
  OPEN_OBATCONTENT_COMMITMENTS_MODAL_ACTION,
} from './actions';

export interface IObatResourceCommitmentsModalState {
  obatResource?: IObatResource;
  editionField?: string;
  isOpen: boolean;
}

export const initialState = {
  obatResource: undefined,
  editionField: undefined,
  isOpen: false,
};

export const obatResourceCommitmentsModalReducer = (
  state: IObatResourceCommitmentsModalState = initialState,
  action: IObatResourceCommitmentsModalActions
): IObatResourceCommitmentsModalState => {
  switch (action.type) {
    case OPEN_OBATCONTENT_COMMITMENTS_MODAL_ACTION:
      const { obatResource, editionField } = action.payload;

      return { ...state, obatResource, editionField, isOpen: true };

    case CLOSE_OBATCONTENT_COMMITMENTS_MODAL_ACTION:
      return { ...state, obatResource: undefined, editionField: undefined, isOpen: false };

    default:
      return state;
  }
};
