import CircularProgress from '@material-ui/core/CircularProgress';
import React, { PureComponent, ReactNode } from 'react';
import { IProgressStatus } from 'utils/strings/progressStatus';

import { Loader } from './Loader.component';

interface IProps {
  dark?: boolean;
  size?: number;
  progress?: IProgressStatus;
}

export class CircularLoader extends PureComponent<IProps> {
  public render(): ReactNode {
    const { dark, size, progress } = this.props;

    return (
      <Loader progress={progress}>
        <CircularProgress size={size || 100} color={dark ? 'secondary' : 'primary'} />
      </Loader>
    );
  }
}
