import Grid from '@material-ui/core/Grid';
import { Alert } from 'components/Alerts';
import { Button } from 'components/Buttons/Button.component';
import { BottomCenterButtons, BottomRightButtons } from 'components/Buttons/ButtonsContainers';
import { ITool, ToolsButton } from 'components/Buttons/ToolsButton/ToolsButton.component';
import { Datatable } from 'components/Datatable';
import { DropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput';
import { HyperLink } from 'components/Links/HyperLink';
import { OplusLoader } from 'components/Loader';
import { Page } from 'components/Page';
import { HourlyResultsPlot } from 'components/SimulationGroup/HourlyResults/HourlyResultsPlot';
import { ResultsModeForm } from 'components/SimulationGroup/ResultsModeForm';
import { _size } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { Activity, CheckSquare } from 'react-feather';
import { navigateRoute } from 'routing/navigateRoute';
import {
  getMonoSimulationGroupMenuPageRoute,
  getMultiSimulationGroupMenuPageRoute,
  monoSimulationGroupMenuPages,
  multiSimulationGroupMenuPages,
} from 'routing/routes';
import styled from 'styled-components';
import { ISimulationListed, ISimulationSeries } from 'types/SimulationGroup/Simulation';
import { downloadCustomTextFile } from 'utils/functions/downloadFile/downloadCustomTextFile';
import { memoizeMulti } from 'utils/functions/memoizeMulti';
import { pickColumnFilterParams, pickDatatableColumnParams } from 'utils/functions/pickColumnParams';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { convertHourlyResultsPlotDataToCsv } from 'utils/functions/simulationGroup/convertHourlyPlotDataToCsv';
import { $t, $tEnums } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { ps } from 'utils/strings/progressStatus';
import { rrc } from 'utils/strings/resourceClasses';
import { IResultsView, rv } from 'utils/strings/results';
import { rm } from 'utils/strings/routingMenus';

import { IHourlyResultsPlot } from 'redux/restResources/detail/simulationGroup/reducer';

import { IProps } from './HourlyResultsPage.container';

interface IState {
  isPlotDisplayed: boolean;
}

export class HourlyResultsPage extends PureComponent<IProps, IState> {
  public state: IState = {
    isPlotDisplayed: false,
  };

  public getColumnsParams = memoizeMulti((resultsMode: IResultsView): ITableColumnParams[] => {
    const { isMulti } = this.props;

    let columnsParams: ITableColumnParams[] = [
      { id: 'name', resourceField: 'name', minWidth: 250 },
      {
        id: 'topic',
        resourceField: 'topic',
        withFilterOptions: true,
        renderCustomText: (series: ISimulationSeries) => $tEnums(series.topic),
      },
      { id: 'use', resourceField: 'use', withFilterOptions: true },
      {
        id: 'unit',
        resourceField: 'unit',
        withFilterOptions: true,
        renderCustomText: (series: ISimulationSeries) => $tEnums(series.unit),
      },
      { id: 'activityZoneGroup', resourceField: 'azg', withFilterOptions: true },
      { id: 'outputZoneGroup', resourceField: 'ozg', withFilterOptions: true },
      { id: 'zone', resourceField: 'zone' },
      {
        id: 'energyType',
        resourceField: 'energy_type',
        withFilterOptions: true,
        renderCustomText: (series: ISimulationSeries) => $tEnums(series.energy_type),
      },
      {
        id: 'energyCategory',
        resourceField: 'energy_category',
        withFilterOptions: true,
        renderCustomText: (series: ISimulationSeries) => $tEnums(series.energy_category),
      },
    ];

    columnsParams =
      resultsMode === rv.aggregate && isMulti
        ? [{ resourceField: 'simulation.name', id: 'simulation' }, ...columnsParams]
        : columnsParams;

    return columnsParams;
  });

  public getResultsModeLabels = memoizeMulti((isMulti: boolean, tableResourcesSelectedSize?: number) => {
    const bySImulationLabel = isMulti ? $t('resultsBySimulation') : $t('results');
    const aggregateLabel = `${$t('selection')} (${tableResourcesSelectedSize || 0})`;

    return {
      bySimulation: bySImulationLabel,
      aggregate: aggregateLabel,
    };
  });

  public componentDidMount(): void {
    const {
      refreshSimulationGroup,
      routeSimulationGroupResourceClass,
      simulationsListByStatus,
      fetchTableInitialData,
      routeSimulationGroup,
      updateSimulation,
      resultsMode,
    } = this.props;

    refreshSimulationGroup(routeSimulationGroupResourceClass);
    const initialSimulation = simulationsListByStatus.success[0];
    updateSimulation(initialSimulation);
    const tableFilterParams = pickColumnFilterParams(this.getColumnsParams(resultsMode));
    fetchTableInitialData(tableFilterParams, rrc.series, routeSimulationGroup.id);
  }

  public componentWillUnmount(): void {
    const { clearHourlyResults, clearSelectedData } = this.props;
    clearHourlyResults();
    clearSelectedData();
  }

  public render(): ReactNode {
    const { isDownloadingHourlyResults, hourlyResultsPlot, tableResources, isMulti, isMono, resultsMode } = this.props;

    const { isPlotDisplayed } = this.state;

    const tableColumnsParams = pickDatatableColumnParams(this.getColumnsParams(resultsMode));

    const resultsModeLabels = this.getResultsModeLabels(isMulti, tableResources && _size(tableResources.selected));

    const isSelectionEmpty = !tableResources || _size(tableResources.selected) === 0;

    return (
      <Page
        pageTitle={$t('hourlyResults')}
        routingMenu={isMono ? rm.monoSimulationGroup : rm.multiSimulationGroup}
        selectedPage={
          isMono ? monoSimulationGroupMenuPages.hourlyResultsMono : multiSimulationGroupMenuPages.hourlyResultsMulti
        }
        renderAlert={this.renderAlert}
      >
        {!isPlotDisplayed && (
          <>
            {isDownloadingHourlyResults && !tableResources && <OplusLoader progress={ps.fetchingData} />}
            {!!tableResources && (
              <>
                <ResultsModeForm
                  resultsMode={resultsMode}
                  resultsModeLabels={resultsModeLabels}
                  onSimulationSelect={this.onSimulationSelect}
                  onResultsModeToggle={this.onResultsModeToggle}
                />
                {isDownloadingHourlyResults && <OplusLoader progress={ps.fetchingData} />}
                {!isDownloadingHourlyResults && (
                  <DatatableContainer>
                    <Datatable
                      isSelectable={true}
                      tableColumnsParams={tableColumnsParams}
                      tableResources={tableResources}
                      rowIdKey={'ref'}
                    />
                  </DatatableContainer>
                )}
              </>
            )}
          </>
        )}
        {isPlotDisplayed && (
          <PlotContainer>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                {_size(hourlyResultsPlot.yearOptions) > 1 && (
                  <DropdownInput
                    field={'plotYear'}
                    label={$t('year')}
                    value={hourlyResultsPlot.year}
                    options={hourlyResultsPlot.yearOptions}
                    onSelect={this.onPlotYearSelect}
                  />
                )}
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={2}>
                {_size(hourlyResultsPlot.lines) > 1 && (
                  <DropdownInput
                    field={'plotOperation'}
                    label={$t('operation')}
                    value={hourlyResultsPlot.operation}
                    options={[
                      { value: 'none', label: $t('noOperation') },
                      { value: 'sum', label: $t('sum') },
                      { value: 'mean', label: $t('mean') },
                      { value: 'normalization', label: $t('normalization') },
                    ]}
                    onSelect={this.onPlotOperationSelect}
                  />
                )}
              </Grid>
            </Grid>
            {isDownloadingHourlyResults ? (
              <LoaderContainer>
                <OplusLoader progress={ps.fetchingData} />
              </LoaderContainer>
            ) : (
              <HourlyResultsPlot />
            )}
          </PlotContainer>
        )}
        <BottomCenterButtons>
          <Button
            Icon={isPlotDisplayed ? <CheckSquare size={16} /> : <Activity size={16} />}
            text={$t(isPlotDisplayed ? 'selectResults' : 'plotSelection')}
            tooltipProps={{ content: isSelectionEmpty ? $t('selectResults') : '', placement: 'left' }}
            onClick={this.togglePlotDisplay}
            isDisabled={isSelectionEmpty}
          />
        </BottomCenterButtons>
        <BottomRightButtons>
          <ToolsButton tools={this.getTools()} />
        </BottomRightButtons>
      </Page>
    );
  }

  private onSimulationSelect = (selectedSimulation: ISimulationListed): void => {
    const { fetchTableUpdatedData, updateSimulation } = this.props;
    updateSimulation(selectedSimulation);
    fetchTableUpdatedData(rrc.series);
  };

  private onResultsModeToggle = (selectedMode: IResultsView): void => {
    const {
      initializeTableWithSelection,
      fetchTableInitialData,
      routeSimulationGroup,
      updateHourlyResultsMode,
    } = this.props;

    const tableFilterParams = pickColumnFilterParams(this.getColumnsParams(selectedMode));

    if (selectedMode === rv.aggregate) {
      initializeTableWithSelection(tableFilterParams);
    } else if (selectedMode === rv.bySimulation) {
      fetchTableInitialData(tableFilterParams, rrc.series, routeSimulationGroup.id);
    }

    updateHourlyResultsMode(selectedMode);
  };

  private getTools = (): ITool[] => {
    const { isPlotDisplayed } = this.state;
    const { simulation } = this.props;

    return isPlotDisplayed
      ? [{ text: $t('exportSelectedData'), onClick: this.exportPlotData }]
      : [
          {
            text: $t('exportSimulationHourlyResults', { simulationName: simulation ? simulation.name : '' }),
            onClick: this.exportSimulationResults,
          },
        ];
  };

  private exportPlotData = (): void => {
    const { hourlyResultsPlot, routeSimulationGroup, userMeLanguage } = this.props;
    const csv = convertHourlyResultsPlotDataToCsv(hourlyResultsPlot, userMeLanguage);
    const fileName = `${routeSimulationGroup.name} ${$t('selection')}.csv`;
    downloadCustomTextFile(csv, fileName);
  };

  private exportSimulationResults = (): void => {
    const { exportHourlyCsv, simulation } = this.props;
    if (!!simulation) {
      exportHourlyCsv(simulation);
    }
  };

  private onPlotYearSelect = (_field: string, selectedYear: string) => {
    const { downloadHourlyResultsData, simulation } = this.props;

    if (!!simulation) {
      downloadHourlyResultsData(selectedYear);
    }
  };

  private onPlotOperationSelect = (_field: string, selectedOperation: IHourlyResultsPlot['operation']) => {
    const { updateHourlyResultsPlotOperation } = this.props;
    updateHourlyResultsPlotOperation(selectedOperation);
  };

  private togglePlotDisplay = (): void => {
    const { downloadHourlyResultsData } = this.props;

    this.setState({ isPlotDisplayed: !this.state.isPlotDisplayed }, () => {
      if (this.state.isPlotDisplayed) {
        downloadHourlyResultsData();
      }
    });
  };

  private renderAlert = (): ReactNode => {
    const { routeSimulationGroup, routeProject, isMono, isMulti, isAllSimulationsEmpty } = this.props;

    const onMouseDown = (e: React.MouseEvent): void => {
      if (isMono) {
        const monoUrl = getMonoSimulationGroupMenuPageRoute(
          monoSimulationGroupMenuPages.monitorMono,
          routeProject.id,
          routeSimulationGroup.id
        );
        navigateRoute(monoUrl, e);
      } else if (isMulti) {
        const multiUrl = getMultiSimulationGroupMenuPageRoute(
          multiSimulationGroupMenuPages.monitorMulti,
          routeProject.id,
          routeSimulationGroup.id
        );
        navigateRoute(multiUrl, e);
      }
    };

    if (
      routeSimulationGroup.status === as.running ||
      routeSimulationGroup.obsolete ||
      (routeSimulationGroup.status === as.empty && !isAllSimulationsEmpty)
    ) {
      let hyperlink;
      let alertText;
      let alertStatus;

      if (routeSimulationGroup.status === as.running) {
        hyperlink = <HyperLink onMouseDown={onMouseDown}>{$t('seeProgress')}</HyperLink>;
        alertText = `${$t('simulationGroupRunningResultsAlert')}  `;
        alertStatus = as.info;
      }

      if (routeSimulationGroup.status === as.empty && !isAllSimulationsEmpty) {
        hyperlink = <HyperLink onMouseDown={onMouseDown}>{$t('simulate')}</HyperLink>;
        alertText = `${$t('simulationGroupConfigModificationAlert')}  `;
        alertStatus = as.warning;
      }

      if (routeSimulationGroup.obsolete) {
        hyperlink = <HyperLink onMouseDown={onMouseDown}>{$t('simulate')}</HyperLink>;
        alertText = `${$t('simulationGroupObsoleteAlert')}  `;
        alertStatus = as.warning;
      }

      const content = (
        <span>
          {alertText}
          {hyperlink}
        </span>
      );

      return (
        // @ts-ignore
        <Alert status={alertStatus} opacity={0.8}>
          {content}
        </Alert>
      );
    }

    return null;
  };
}

const DatatableContainer = styled.div`
  /* The props noPadding of the Page component causes a problem with the Datatable height (can't explain) */
  /* Anyway we need padding on this page for the plot, so don't use noPadding */
  margin-left: -16px;
  margin-right: -16px;
`;

const PlotContainer = styled.div`
  margin-top: 12px;
`;

const LoaderContainer = styled.div`
  padding-top: 30vh;
`;
