import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button } from 'components/Buttons';
import { HyperLink } from 'components/Links/HyperLink';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { _get, _includes } from 'libs/lodash';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { XSquare } from 'react-feather';
import { navigateRoute } from 'routing/navigateRoute';
import theme from 'style/theme';
import styled from 'styled-components';
import { IDatatableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';

import { IProps } from './DatatableRow.container';

// tslint:disable:no-any
export class DatatableRow extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isSelectable, tableResource, tableColumnsParams, isSelected, isSelectionDisplay } = this.props;

    return (
      <StyledTableRow hover selected={isSelected}>
        {!!isSelectable && (
          <TableCell padding="checkbox">
            {isSelectionDisplay ? (
              <Button
                onClick={this.selectRow}
                Icon={<XSquare size={20} />}
                bgColor={theme.colors.transparent}
                color={'black'}
                noShadow
              />
            ) : (
              <Checkbox checked={isSelected} onChange={this.selectRow} />
            )}
          </TableCell>
        )}
        {_map(tableColumnsParams, (columnParams: IDatatableColumnParams) =>
          columnParams.id === 'actions' ? (
            <ActionCell align={columnParams.align} key={columnParams.id}>
              {this.renderCellContent(tableResource, columnParams)}
            </ActionCell>
          ) : (
            <TableCell
              key={columnParams.id}
              align={columnParams.align}
              onClick={!!isSelectable ? this.selectRow : undefined}
              style={{ cursor: !!isSelectable ? 'pointer' : 'default' }}
            >
              {this.renderCellContent(tableResource, columnParams)}
            </TableCell>
          )
        )}
      </StyledTableRow>
    );
  }

  private renderCellContent = (tableResource: any, columnParams: IDatatableColumnParams): ReactNode => {
    const { id, renderCustomDOM, renderCustomText, resourceField, getNavigationUrl, getTooltipContent } = columnParams;

    if (renderCustomDOM) {
      return renderCustomDOM(tableResource);
    }

    if (renderCustomText) {
      return renderCustomText(tableResource);
    }

    let textContent = renderTextValueElseDash(resourceField && _get(tableResource, resourceField)) as any;

    if (getNavigationUrl) {
      const navigationUrl = getNavigationUrl(tableResource);

      if (navigationUrl) {
        const onMouseDown = (e: React.MouseEvent): void => {
          navigateRoute(navigationUrl, e);
        };

        textContent = <HyperLink onMouseDown={onMouseDown}>{textContent}</HyperLink>;
      }
    }

    let tooltipContent;

    if (id === 'name' && tableResource.comment) {
      tooltipContent = tableResource.comment;
    }

    if (getTooltipContent) {
      tooltipContent = getTooltipContent(tableResource);
    }

    if (tooltipContent) {
      textContent = (
        <SimpleTooltip content={tooltipContent} placement={'right'}>
          <Underlined>{textContent}</Underlined>
        </SimpleTooltip>
      );
    }

    if (id === 'name') {
      textContent = <NameContainer>{textContent}</NameContainer>;
    }

    return textContent;
  };

  private selectRow = () => {
    const { toggleRowSelection, tableResource } = this.props;
    toggleRowSelection(tableResource);
  };
}

const StyledTableRow = styled(TableRow)`
  &:hover {
    #datatableActions {
      display: flex;
    }
  }
` as typeof TableRow;

const ActionCell = styled(TableCell)`
  width: 96px;
  padding: 6px 0 6px 8px;
` as typeof TableCell;

const Underlined = styled.span`
  text-decoration: underline dotted;
`;

const NameContainer = styled.div`
  min-width: 50px;
  word-break: break-all;
`;
