import { Breadcrumbs, Grid, Link } from '@material-ui/core';
import EventSeat from '@material-ui/icons/EventSeat';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { BreadcrumbPopover } from 'components/BreadcrumbTopBar/BreadcrumbPopover';
import { Button } from 'components/Buttons/Button.component';
import { HyperLink } from 'components/Links/HyperLink';
import { NoFreeSeatsModal } from 'components/Modals/NoFreeSeatsModal';
import { _get, _has, _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { Activity, Box, Briefcase, ChevronsLeft, Folder, Layers, RefreshCw, Sun } from 'react-feather';
import { navigateRoute } from 'routing/navigateRoute';
import {
  getHomeMenuPageRoute,
  getOrganizationMenuPageRoute,
  getProjectMenuPageRoute,
  homeMenuPages,
  organizationMenuPages,
  projectMenuPages,
} from 'routing/routes';
import theme from 'style/theme';
import styled from 'styled-components';
import { IBreadcrumbLevel } from 'utils/functions/getBreadcrumbList/getBreadcrumbList';
import { getSeatColor } from 'utils/functions/seats/getSeatColor';
import { getSeatTooltipContent } from 'utils/functions/seats/getSeatTooltipContent';
import { $t } from 'utils/functions/translate';

import { IProps } from './BreadcrumbTopBar.container';

interface IState {
  activeLevel?: IBreadcrumbLevel;
  isPopoverOpen: boolean;
  popoverAnchorEl: (EventTarget & Element) | null;
}

export class BreadcrumbTopBar extends PureComponent<IProps, IState> {
  public state: IState = {
    popoverAnchorEl: null,
    isPopoverOpen: false,
    activeLevel: undefined,
  };

  public render(): ReactNode {
    const { pageTitle, isFormDrawerCollapsed, expandForm, routeProject, routeOrganization } = this.props;
    const { activeLevel, isPopoverOpen, popoverAnchorEl } = this.state;

    return (
      <>
        <Bar>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <BreadcrumbDiv>
                <Breadcrumbs aria-label="Breadcrumb">
                  {this.renderBreadcrumbItems()}
                  <span>{pageTitle}</span>
                </Breadcrumbs>

                <RefreshDiv>
                  <Button
                    onClick={this.refreshPage}
                    tooltipProps={{ content: $t('refresh'), placement: 'bottom' }}
                    Icon={<RefreshCw size={16} />}
                    bgColor={theme.colors.transparent}
                    color={'black'}
                    noShadow
                  />
                </RefreshDiv>
              </BreadcrumbDiv>
            </Grid>
          </Grid>
          {activeLevel && (
            <BreadcrumbPopover
              isOpen={isPopoverOpen}
              closePopover={this.closeBreadcrumbPopover}
              popoverAnchorEl={popoverAnchorEl}
              breadcrumbLevel={activeLevel}
            />
          )}
          {isFormDrawerCollapsed && (
            <ExpandFormButtonContainer>
              <ChevronsLeft onClick={expandForm} size={14} />
            </ExpandFormButtonContainer>
          )}
        </Bar>

        {(!!routeProject || !!routeOrganization) && <NoFreeSeatsModal />}
      </>
    );
  }

  private renderBreadcrumbItems = (): ReactNode => {
    const { breadcrumbList, routeProject } = this.props;

    const breadcrumbItemsParams = {
      project: {
        Icon: Folder,
        route: getHomeMenuPageRoute(homeMenuPages.myProjects),
        title: $t('myProjects'),
      },
      organization: {
        Icon: Briefcase,
        route: getHomeMenuPageRoute(homeMenuPages.myOrganizations),
        title: $t('myOrganizations'),
      },
      /* Project */
      geometry: {
        Icon: Box,
        route: routeProject ? getProjectMenuPageRoute(projectMenuPages.geometries, routeProject.id) : '',
        title: $t('geometries'),
      },
      obat: {
        Icon: Layers,
        route: routeProject ? getProjectMenuPageRoute(projectMenuPages.obats, routeProject.id) : '',
        title: $t('obats'),
      },
      weather: {
        Icon: Sun,
        route: routeProject ? getProjectMenuPageRoute(projectMenuPages.weathers, routeProject.id) : '',
        title: $t('weathers'),
      },
      mono_simulation_group: {
        Icon: Activity,
        route: routeProject ? getProjectMenuPageRoute(projectMenuPages.simulationGroups, routeProject.id) : '',
        title: $t('simulations'),
      },
      multi_simulation_group: {
        Icon: Activity,
        route: routeProject ? getProjectMenuPageRoute(projectMenuPages.simulationGroups, routeProject.id) : '',
        title: $t('simulations'),
      },
    };

    let breadcrumbItems = _map(
      breadcrumbList,
      (breadcrumbLevel: IBreadcrumbLevel): ReactNode => {
        if (!breadcrumbLevel.routeResource) {
          return null;
        }

        const Icon = _get(breadcrumbItemsParams, breadcrumbLevel.resourceClass).Icon;
        const route = _get(breadcrumbItemsParams, breadcrumbLevel.resourceClass).route;
        const title = _get(breadcrumbItemsParams, breadcrumbLevel.resourceClass).title;

        return (
          <BreadcrumbItem key={breadcrumbLevel.routeResource.id}>
            <BreadcrumbIcon>
              <Button
                Icon={<Icon size={16} />}
                onClick={this.onIconClick(route)}
                tooltipProps={{ content: $t(title), placement: 'bottom' }}
                bgColor={theme.colors.transparent}
                color={'black'}
                noShadow
              />
            </BreadcrumbIcon>
            <LinkContainer color="inherit">
              <span onClick={this.onLevelClick(breadcrumbLevel)}>
                {breadcrumbLevel.routeResource.name} <ExpandMore />
              </span>
            </LinkContainer>
          </BreadcrumbItem>
        );
      }
    );

    if (!!routeProject) {
      const seatColor = getSeatColor(routeProject);
      const seatTooltipContent = getSeatTooltipContent(routeProject, 'button');
      const isGratis = routeProject.is_activated && !routeProject.is_taking_up_seat;

      const organizationSeatsRoute = getOrganizationMenuPageRoute(
        organizationMenuPages.seats,
        routeProject.organization.id
      );
      const onMouseDown = (e: React.MouseEvent): void => {
        navigateRoute(organizationSeatsRoute, e);
      };

      const SeatItem = (
        <Grid key={routeProject.organization.id} container spacing={1} alignItems={'center'}>
          <Grid item>
            <Button
              noShadow
              Icon={<EventSeat fontSize={'small'} />}
              isDisabled={isGratis}
              onClick={this.onSeatClick}
              tooltipProps={{ content: seatTooltipContent, placement: 'bottom' }}
              bgColor={seatColor}
            />
          </Grid>
          <Grid item>
            {routeProject.is_member ? (
              <HyperLink onMouseDown={onMouseDown}>{routeProject.organization.name}</HyperLink>
            ) : (
              <span>{routeProject.organization.name}</span>
            )}
          </Grid>
        </Grid>
      );

      breadcrumbItems = [SeatItem, ...breadcrumbItems];
    }

    return breadcrumbItems;
  };

  private onSeatClick = (): void => {
    const { routeProject, manageSeat } = this.props;
    if (!routeProject.is_activated || routeProject.is_leaving_seat) {
      manageSeat('take');
    } else {
      manageSeat('leave');
    }
  };

  private refreshPage = (): void => {
    const { route, toggleIsRefreshingRoutePage, customPageRefresh } = this.props;

    if (customPageRefresh) {
      customPageRefresh();
    } else {
      toggleIsRefreshingRoutePage(true);
      navigateRoute(route);
    }
  };

  private closeBreadcrumbPopover = (): void => {
    this.setState({ isPopoverOpen: false, popoverAnchorEl: null });
  };

  private onLevelClick = (breadcrumbLevel: IBreadcrumbLevel) => {
    return (e: React.MouseEvent): void => {
      this.setState({
        isPopoverOpen: true,
        popoverAnchorEl: e.currentTarget,
        activeLevel: breadcrumbLevel,
      });
    };
  };

  private onIconClick = (route: string) => {
    return (_e: React.MouseEvent): void => {
      navigateRoute(route);
    };
  };
}

export const BREADCRUMB_BAR_HEIGHT = 57.5;

const Bar = styled.div`
  right: 0;
  top: 0;
  padding: 8px;
  border-bottom: solid 1px ${theme.colors.divider};
  background-color: ${theme.colors.pageBody};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: ${BREADCRUMB_BAR_HEIGHT}px;
`;

const BreadcrumbDiv = styled.div`
  margin-left: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RefreshDiv = styled.div`
  margin-left: 10px;
`;

const LinkContainer = styled(Link)`
  background-color: ${theme.colors.input.filled};
  display: inline-block;
  padding: 3px 3px 2px 10px;
  margin: 2px 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background-color: ${theme.colors.input.hover};
    text-decoration: none;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    font-size: 1.3rem;
  }
` as typeof Link;

const ExpandFormButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 12px;
  background-color: ${theme.colors.transparent};
  border-radius: 10% 0 0 10%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.input.hover};
  }
`;
const BreadcrumbItem = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BreadcrumbIcon = styled.span`
  cursor: pointer;
`;
