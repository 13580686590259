// tslint:disable:no-any
import { _get } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { roundFloat } from 'utils/functions/roundFloat';

export const setFormInitialValue = (resource: any, field: string, defaultValue: any = ''): any => {
  if (!resource) {
    return defaultValue;
  }
  if (!_has(resource, field)) {
    return defaultValue;
  }

  const fieldValue = _get(resource, field);

  if (fieldValue === 0) {
    return '0';
  }
  if (!fieldValue) {
    return defaultValue;
  }

  return typeof fieldValue === 'number' ? roundFloat(fieldValue).toString() : fieldValue;
};
