import MuiDrawer from '@material-ui/core/Drawer';
import { MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Button } from 'components/Buttons';
import { BoldText } from 'components/Texts/BoldText';
import React, { PureComponent, ReactNode } from 'react';
import { ChevronsLeft, ChevronsRight } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';

interface IProps {
  collapsedWidth?: number;
  collapsible?: boolean;
  expandedWidth: number;
  drawerTheme?: (theme: Theme | null) => Theme;
  menuTitle?: string;
  isCollapsed?: boolean;
  Menu: ReactNode;
  isVariantContext?: boolean;
}

interface IState {
  drawerWidth: number;
  isCollapsed: boolean;
  isHovered: boolean;
  mainLeftPosition: number;
}

const getDrawerWidth = (
  isCollapsed: boolean | undefined,
  expandedWidth: number,
  collapsedWidth: number | undefined
) => {
  return !!isCollapsed && !!collapsedWidth ? collapsedWidth : expandedWidth;
};

export class WithMenuDrawer extends PureComponent<IProps, IState> {
  public state = {
    drawerWidth: getDrawerWidth(this.props.isCollapsed, this.props.expandedWidth, this.props.collapsedWidth),
    mainLeftPosition: getDrawerWidth(this.props.isCollapsed, this.props.expandedWidth, this.props.collapsedWidth),
    isCollapsed: !!this.props.isCollapsed,
    isHovered: false,
  };

  public render(): ReactNode {
    const { collapsible, menuTitle, Menu: menuComponent, drawerTheme, children, isVariantContext } = this.props;
    const { isHovered, mainLeftPosition } = this.state;
    const getPaperStyle = (state: IState): { [key: string]: React.CSSProperties } => ({
      paper: {
        width: state.drawerWidth,
        position: 'absolute',
        background: isVariantContext ? theme.colors.variant : undefined,
        overflowX: 'hidden',
      },
    });

    let drawer = (
      <MuiDrawer
        open
        variant="permanent"
        PaperProps={{ classes: { root: 'no-scrollbar' }, style: getPaperStyle(this.state).paper }}
      >
        <>
          {collapsible && !isHovered && this.renderCollapseIcon()}
          {menuTitle && (
            <TitleContainer>
              <BoldText>{menuTitle}</BoldText>
            </TitleContainer>
          )}
          <MenuContainer onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
            {menuComponent}
          </MenuContainer>
        </>
      </MuiDrawer>
    );

    if (drawerTheme) {
      drawer = <MuiThemeProvider theme={drawerTheme}>{drawer}</MuiThemeProvider>;
    }

    return (
      <MainContainer>
        <nav>{drawer}</nav>
        <PageContainer leftPosition={mainLeftPosition}>{children}</PageContainer>
      </MainContainer>
    );
  }

  private onMouseEnter = (): void => {
    if (!!this.props.collapsible && !!this.state.isCollapsed) {
      this.setState({ drawerWidth: this.props.expandedWidth, isHovered: true });
    }
  };

  private onMouseLeave = (): void => {
    if (!!this.props.collapsible && !!this.state.isCollapsed && !!this.props.collapsedWidth) {
      this.setState({ drawerWidth: this.props.collapsedWidth, isHovered: false });
    }
  };

  private renderCollapseIcon = (): ReactNode => {
    const toggleCollapse = (): void => {
      this.setState({
        isCollapsed: !this.state.isCollapsed,
        drawerWidth: getDrawerWidth(!this.state.isCollapsed, this.props.expandedWidth, this.props.collapsedWidth),
        mainLeftPosition: getDrawerWidth(!this.state.isCollapsed, this.props.expandedWidth, this.props.collapsedWidth),
      });
    };

    return (
      <CollapseContainer>
        <Button
          Icon={this.state.isCollapsed ? <ChevronsRight size={12} /> : <ChevronsLeft size={12} />}
          onClick={toggleCollapse}
          color={theme.colors.menu.item}
          hoverColor={theme.colors.menu.secondary}
          bgColor={theme.colors.transparent}
          noShadow
          height={16}
        />
      </CollapseContainer>
    );
  };
}

const MenuContainer = styled.div`
  min-height: calc(100vh - 40px - 16px);
  margin-top: 16px;
`;

const CollapseContainer = styled.div`
  position: absolute;
  right: 0;
  top: 3px;
`;

const MainContainer = styled.div`
  display: flex;
  flex: 1;
`;

const TitleContainer = styled.div`
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: -16px;
  min-height: 20px;
  color: ${theme.colors.menu.item};
`;

interface IMainProps {
  leftPosition: number;
}

const PageContainer = styled.main`
  flex: 1;
  display: flex;
  position: absolute;
  left: ${(props: IMainProps) => props.leftPosition}px;
  right: 0;
  height: 100%;
`;
