import Drawer from '@material-ui/core/Drawer/Drawer';
import { BREADCRUMB_BAR_HEIGHT } from 'components/BreadcrumbTopBar';
import { Button } from 'components/Buttons';
import React, { Component, ReactNode } from 'react';
import { X } from 'react-feather';
import theme from 'style/theme';
import styled from 'styled-components';
import { getDrawerWidth } from 'utils/functions/getDrawerWidth';
import { $t } from 'utils/functions/translate';

import { IProps } from './FormDrawer.container';

export class FormDrawer<IResourceClass> extends Component<IProps<IResourceClass>> {
  public componentDidUpdate(prevProps: IProps<IResourceClass>): void {
    const { props } = this;
    const { formFocusRef, isFormDrawerOpen, updateDrawerWidth, width, clearAllApiRequests } = props;

    if (isFormDrawerOpen && !prevProps.isFormDrawerOpen) {
      /* clear previous requests errors so that they are not displayed anymore */
      clearAllApiRequests();

      /* update drawer width when it gets opened */
      updateDrawerWidth(getDrawerWidth(width));

      /* apply focus on desired input after a little tiemout to be sure it is the last focus applied */
      /* (Some other automatic focuses can happen, for instance when a popover is left, an automatic focus on <body> happens) */
      setTimeout(() => {
        if (formFocusRef && formFocusRef.current) {
          formFocusRef.current.focus();
        }
      }, 200);
    }
  }

  public render(): ReactNode {
    const { title, isFormDrawerOpen } = this.props;
    const width = getDrawerWidth(this.props.width);

    const styles = {
      paper: {
        width,
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
      },
    };

    return (
      <Drawer
        anchor="right"
        variant="persistent"
        open={isFormDrawerOpen}
        // @ts-ignore: TS bug with boxSizing...
        PaperProps={{ style: styles.paper }}
      >
        <TopBar width={width}>
          <IconRow>
            <HeaderTitle>{title ? title : $t('new')}</HeaderTitle>
            <Button
              Icon={<X size={16} />}
              color={'black'}
              bgColor={theme.colors.transparent}
              onClick={this.closeAndClear}
              noShadow
            />
          </IconRow>
          <SeparationLine />
        </TopBar>
        <ScrollableContent>{this.props.children}</ScrollableContent>
      </Drawer>
    );
  }

  private closeAndClear = (): void => {
    const { closeFormDrawer } = this.props;
    closeFormDrawer();
  };
}

const ScrollableContent = styled.div`
  padding: 12px;
  margin-top: ${BREADCRUMB_BAR_HEIGHT}px;
`;

interface ITopbarProps {
  width: number;
}

const TopBar = styled.div`
  padding: 0;
  top: 0;
  right: 0;
  width: ${(props: ITopbarProps): string => props.width + 'px'};
  position: fixed;
  z-index: 10;
  background-color: white;
  height: ${BREADCRUMB_BAR_HEIGHT}px;
  box-sizing: border-box;
  border-left: solid 1px rgba(0, 0, 0, 0.12);
`;

const ICON_ROW_VERTICAL_PADDING = 4;

const IconRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${ICON_ROW_VERTICAL_PADDING}px 8px;
  background: white;
  height: ${BREADCRUMB_BAR_HEIGHT - ICON_ROW_VERTICAL_PADDING * 2 - 1}px; // - 1px probably for the border
`;

const HeaderTitle = styled.span`
  font-size: 16px;
  text-align: center;
  margin: 12px 0 12px 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
`;

const SeparationLine = styled.div`
  border-bottom: 1px solid ${theme.colors.divider};
`;
