// tslint:disable:no-any
import { _includes } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { _split } from 'libs/lodash';
import { _toLower } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import Select from 'react-select';
import theme from 'style/theme';
import styled from 'styled-components';
import { ISelectOption, ISelectOptions } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { $t } from 'utils/functions/translate';

export interface IReactSelectProps {
  handleChange?: (selectedOption: any) => void;
  isClearable?: boolean;
  isDisabled?: boolean;
  optionsPlacement?: 'bottom' | 'top' | 'auto' | undefined;
  optionsPosition?: 'fixed' | 'absolute' | undefined;
  name: string;
  options?: ISelectOptions;
  selectedOption?: ISelectOption;
  minColumnWidth?: number;
  inputRef?: any;
  onBlur?(e: React.FocusEvent<any>): void;
}

export class ReactSelect extends PureComponent<IReactSelectProps> {
  public getCustomStyles = (): object => {
    const { minColumnWidth } = this.props;

    return {
      clearIndicator: (styles: any) => ({
        ...styles,
        padding: '0',
      }),

      container: (styles: any) => ({
        ...styles,
        outline: 'none !important',
      }),

      control: (styles: any) => ({
        ...styles,
        minHeight: '20px',
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
        borderRadius: `0 0 ${theme.borderRadius.small} ${theme.borderRadius.small}`,
        boxShadow: 'none !important',
        borderColor: this.props.isDisabled ? theme.colors.input.disabled : theme.colors.input.filled,
        borderWidth: '1px',
        backgroundColor: 'transparent',
        ':hover': {
          ...styles[':hover'],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
      }),

      dropdownIndicator: (styles: any) => ({
        ...styles,
        padding: '4px',
        paddingLeft: 0,
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),

      menu: (styles: any) => ({
        ...styles,
        minWidth: minColumnWidth,
        zIndex: 11,
        right: 0,
      }),

      option: (styles: any, data: any) => ({
        ...styles,
        backgroundColor: data.isSelected ? 'rgba(0, 0, 0, 0.08)' : data.isFocused ? 'rgba(0, 0, 0, 0.03)' : '',
        color: 'rgba(0, 0, 0, 0.87)',
        minHeight: '36px',
        ':hover': {
          ...styles[':hover'],
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        wordBreak: 'bear-word',
      }),
      singleValue: (styles: any) => ({
        ...styles,
        paddingLeft: '0',
      }),
      input: (styles: any) => ({
        ...styles,
        paddingLeft: '0',
      }),
    };
  };

  public render(): ReactNode {
    const {
      options,
      name,
      isDisabled,
      isClearable,
      optionsPlacement,
      optionsPosition,
      selectedOption,
      handleChange,
      inputRef,
      onBlur,
    } = this.props;

    return (
      <Select
        name={name}
        options={options}
        onChange={handleChange}
        value={selectedOption}
        styles={this.getCustomStyles()}
        onBlur={onBlur}
        isDisabled={isDisabled}
        isClearable={isClearable}
        optionsPlacement={optionsPlacement || 'auto'}
        optionsPosition={optionsPosition || 'absolute'}
        placeHolderText={'Select'}
        noOptionsMessage={this.noOptionsMessage}
        filterOption={this.customFilterOption}
        ref={inputRef}
      />
    );
  }

  private customFilterOption = (option: ISelectOption, rawInput: string): boolean => {
    /* https://github.com/JedWatson/react-select/issues/3067 */
    const words = _map(_split(rawInput, ' '), _toLower);

    return _reduce(
      words,
      (isFilterMatch: boolean, currentWord: string) => isFilterMatch && _includes(_toLower(option.label), currentWord),
      true
    );
  };

  private noOptionsMessage = (): string => {
    return $t('noOptions');
  };
}

export const SelectWrapper = styled.div`
  border-radius: ${theme.borderRadius.small};
  background: ${theme.colors.input.filled};
  &.disabled {
    background: ${theme.colors.input.disabled};
  }
  &:hover {
    background: ${theme.colors.input.hover};
  }
  &:not(.disabled) {
    &:focus,
    &:active,
    &:visited,
    &:focus-within {
      > div:first-child > p {
        color: ${theme.colors.main};
        transform: translate(-1px, 3px) scale(1);
        margin-top: 6px;
        font-size: 10.5px;
        margin-bottom: 2.5px;
      }
    }
  }
`;
