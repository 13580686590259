import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import { _isEmpty } from 'libs/lodash';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { ICalendar } from 'types/Obat/Calendar';
import { IDayGroup } from 'types/Obat/DayGroup';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { orc } from 'utils/strings/resourceClasses';

import { CalendarForm } from '../CalendarForm';
import { CalendarPlot } from '../CalendarPlot';
import { DayGroupCard } from '../DayGroupCard';

export class CalendarsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'calendars'}
        resourceClass={orc.calendar}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={CalendarForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { resourceField: 'ref', id: 'name', gridItemSize: 3 },
    { id: 'calendar', gridItemSize: 9, centerTitle: true },
  ];

  private renderCardColumnData = (calendar: ICalendar, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return calendar.ref;
      case 'calendar':
        const onCalendarPlotClick = (event: React.MouseEvent): void => {
          event.stopPropagation();
        };

        return (
          <div onClick={onCalendarPlotClick}>
            <CalendarPlot calendar={calendar} />
          </div>
        );
    }
  };

  private renderCardDetail = (calendar: ICalendar): ReactNode => {
    if (!_isEmpty(calendar.day_groups_contents)) {
      return _map(calendar.day_groups_contents, (dayGroup: IDayGroup) => (
        <DayGroupCard key={dayGroup.weak_ref} dayGroup={dayGroup} />
      ));
    }
  };
}
