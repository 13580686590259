import { RestResourcesRequests } from 'api/requests/RestResourcesRequests';
import { _includes } from 'libs/lodash';
import { _replace } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequestCallbackSuccessAction } from 'redux/apiRequests/actions';
import { apiRequestSaga } from 'redux/apiRequests/sagas';
import { selectApiRequestErrors } from 'redux/apiRequests/selectors';
import { closeFormDrawerAction, openFormDrawerAction } from 'redux/form/actions';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { closeDeletionModalAction } from 'redux/modals/deletionModal/actions';
import { fetchMyUserSaga } from 'redux/restResources/detail/me/sagas';
import { selectMyUser } from 'redux/restResources/detail/me/selectors';
import {
  clearSimulationAction,
  updateSimulationAction,
  updateSimulationsListAction,
} from 'redux/restResources/detail/simulationGroup/actions';
import { fetchSingleRouteResourceSaga } from 'redux/routing/sagas';
import {
  selectRouteResource,
  selectRouteResourceParentTag,
  selectRouteResourcesClasses,
} from 'redux/routing/selectors';
import { fetchTableUpdatedDataSaga } from 'redux/table/sagas';
import { selectResourceInTableResources } from 'redux/table/selectors';
import { navigateRoute } from 'routing/navigateRoute';
import { getHomeMenuPageRoute, homeMenuPages } from 'routing/routes';
import { getISOPeriodBounds } from 'utils/functions/datetimes/getISOPeriodBounds';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { IResourceTag } from 'utils/functions/getApiUrl/getRestApiUrl/getRestApiUrl';
import { $t } from 'utils/functions/translate';
import { dr } from 'utils/strings/detailRoutes';
import { fk } from 'utils/strings/formKeys';
import { gra } from 'utils/strings/requestActions';
import { IRestResourceClass } from 'utils/strings/resourceClasses';
import { rrc } from 'utils/strings/resourceClasses';

import {
  COPY_REST_RESOURCE_SAGA_ACTION,
  CREATE_REST_RESOURCE_SAGA_ACTION,
  DELETE_REST_RESOURCE_SAGA_ACTION,
  EDIT_REST_RESOURCE_SAGA_ACTION,
  ICopyRestResourceSagaAction,
  ICreateRestResourceSagaAction,
  IDeleteRestResourceSagaAction,
  IEditRestResourceSagaAction,
  IQueryParams,
} from './actions';

/* _____ RETRIEVE_REST_RESOURCE _____ */

export function* retrieveRestResourceSaga(
  resourceClass: IRestResourceClass,
  resourceId: string,
  parent?: IResourceTag
): SagaIterator {
  const apiRequestKey = getGenericApiRequestKey(gra.retrieve, resourceClass, resourceId);
  const parentResourceTag = parent || (yield select(selectRouteResourceParentTag(resourceClass)));
  const apiUrlParams = { resourceClass, resourceId, parentResourceTag };
  const resource = yield call(apiRequestSaga, apiRequestKey, false, RestResourcesRequests.retrieve, apiUrlParams);

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    /* In case of a mono simulation group, also retrieve its simulation */
    if (resourceClass === rrc.mono_simulation_group) {
      const monoSimulationGroup = resource;
      if (monoSimulationGroup.simulation_id) {
        const simulationId = monoSimulationGroup.simulation_id;
        const routeSimulationGroupTag: IResourceTag = {
          resourceClass: rrc.mono_simulation_group,
          resourceId: monoSimulationGroup.id,
        };
        const simulation = yield call(retrieveRestResourceSaga, rrc.simulation, simulationId, routeSimulationGroupTag);
        yield put(updateSimulationsListAction([simulation]));
      } else {
        yield put(clearSimulationAction());
        /* If a simulation already exists, the progress status will be updated during the refresh */
      }
    }
    /* In case of a multi simulation group, also retrieve its simulations */
    if (resourceClass === rrc.multi_simulation_group) {
      const multiSimulationGroup = resource;
      const simulationGroupTag: IResourceTag = { resourceClass, resourceId: multiSimulationGroup.id };
      const simulationsList = yield call(listRestResourcesSaga, rrc.simulation, simulationGroupTag, {}, false);
      yield put(updateSimulationsListAction(simulationsList));
    }
    /* In case of a simulation, update the simulation store */
    if (resourceClass === rrc.simulation) {
      const simulation = resource;
      yield put(updateSimulationAction(simulation));
    }

    return resource;
  }
}

/* _____ LIST_REST_RESOURCE_____ */

export function* listRestResourcesSaga(
  resourceClass: IRestResourceClass,
  parent?: IResourceTag,
  queryParams: IQueryParams = {},
  withLoading: boolean = true,
  withPagination: boolean = false
): SagaIterator {
  const apiRequestKey = getGenericApiRequestKey(gra.list, resourceClass, undefined, withLoading);
  const parentResourceTag = parent || (yield select(selectRouteResourceParentTag(resourceClass)));
  const apiUrlParams = { resourceClass, parentResourceTag };

  const response = yield call(
    apiRequestSaga,
    apiRequestKey,
    false,
    RestResourcesRequests.list,
    apiUrlParams,
    queryParams
  );

  let apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    if (withPagination) {
      return response;
    }

    const { recordsTotal, data } = response;

    const recordsLimitPerRequest = 1000; // backend-side
    let fullData = data;
    const neededRequestsNb = Math.floor(recordsTotal / recordsLimitPerRequest) + 1;
    let doneRequestsNb = 1;
    while (doneRequestsNb < neededRequestsNb) {
      const nextQueryParams = {
        ...queryParams,
        start: recordsLimitPerRequest * doneRequestsNb,
      };
      const nextResponse = yield call(
        apiRequestSaga,
        apiRequestKey,
        false,
        RestResourcesRequests.list,
        apiUrlParams,
        nextQueryParams
      );
      apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
      if (!apiRequestErrors) {
        fullData = [...fullData, ...nextResponse.data];
        doneRequestsNb += 1;
      }
    }

    return fullData;
  }
}

/* _____ CREATE_REST_RESOURCE_____ */

export function* createRestResourceSaga(payload: ICreateRestResourceSagaAction['payload']): SagaIterator {
  const { requestParams, resourceClass, getSuccessLandingPageUrl, isLeavingDrawerOpen } = payload;

  const apiRequestKey = getGenericApiRequestKey(gra.create, resourceClass);
  const parentResourceTag = yield select(selectRouteResourceParentTag(resourceClass));
  const isCreateFromTemplate = !!requestParams.template_ref;
  const apiUrlParams = {
    resourceClass,
    detailRoute: isCreateFromTemplate ? dr.create_from_template : undefined,
    parentResourceTag,
  };

  let createdRestResource;

  if (isCreateFromTemplate && resourceClass === rrc.project) {
    createdRestResource = yield call(createProjectFromTemplateSaga, requestParams);
  } else {
    createdRestResource = yield call(
      apiRequestSaga,
      apiRequestKey,
      true,
      RestResourcesRequests.create,
      apiUrlParams,
      requestParams
    );
  }

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors && createdRestResource) {
    /* When a restResource gets created, we enter in its page if getSuccessLandingPageUrl is defined */
    if (!!getSuccessLandingPageUrl) {
      const routeProject = yield select(selectRouteResource(rrc.project));
      const route = getSuccessLandingPageUrl(routeProject && routeProject.id, createdRestResource);
      yield call(navigateRoute, route);
    } else {
      if (resourceClass === rrc.project) {
        /* we don't want to finish callback if we are creating a project from template */
        return createdRestResource;
      }

      /* update table */
      yield call(fetchTableUpdatedDataSaga, { resourceClass });

      /* also update parent if needed (only for simulations for now)*/
      if (!!parentResourceTag) {
        yield call(fetchSingleRouteResourceSaga, parentResourceTag.resourceClass);
      }

      if (!isLeavingDrawerOpen) {
        yield put(closeFormDrawerAction());
      }
    }

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));

    return createdRestResource;
  }
}

export function* createRestResourceActionSaga(action: ICreateRestResourceSagaAction): SagaIterator {
  yield call(createRestResourceSaga, action.payload);
}

/* _____ EDIT_REST_RESOURCE_____ */

export function* editRestResourceSaga(payload: IEditRestResourceSagaAction['payload']): SagaIterator {
  const { requestParams, resourceId, resourceClass } = payload;

  const apiRequestKey = getGenericApiRequestKey(gra.edit, resourceClass, resourceId);
  const parentResourceTag = yield select(selectRouteResourceParentTag(resourceClass));
  const apiUrlParams = { resourceClass, resourceId, parentResourceTag };

  const editedResource = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    RestResourcesRequests.edit,
    apiUrlParams,
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    /* update table */
    yield call(fetchTableUpdatedDataSaga, { resourceClass });

    /* also update parent if needed (only for simulations for now) */
    if (!!parentResourceTag) {
      yield call(fetchSingleRouteResourceSaga, parentResourceTag.resourceClass);
    }

    /* in case user edits its own permission */
    const myUser = yield select(selectMyUser);
    if (resourceClass === rrc.user_project_permission && editedResource.user === myUser.id) {
      yield call(fetchSingleRouteResourceSaga, rrc.project);
    }
    if (resourceClass === rrc.user_organization_permission && editedResource.user === myUser.id) {
      yield call(fetchSingleRouteResourceSaga, rrc.organization);
    }

    /* in case of editing an routeResource directly (happens in weather, simulation group) */
    const routeResourcesClasses = yield select(selectRouteResourcesClasses);
    if (_includes(routeResourcesClasses, resourceClass)) {
      yield call(fetchSingleRouteResourceSaga, resourceClass);
    }

    /* update the user info after  editing it */
    if (resourceClass === rrc.user) {
      yield call(fetchMyUserSaga);
    }

    yield put(closeFormDrawerAction());
    yield put(apiRequestCallbackSuccessAction(apiRequestKey));

    return editedResource;
  }
}

export function* editRestResourceActionSaga(action: IEditRestResourceSagaAction): SagaIterator {
  yield call(editRestResourceSaga, action.payload);
}

/* _____ DELETE_REST_RESOURCE_____ */

export function* deleteRestResourceSaga(payload: IDeleteRestResourceSagaAction['payload']): SagaIterator {
  const { resourceClass, resourceId } = payload;

  const myUser = yield select(selectMyUser);
  const resourceBeingDeleted = (yield select(selectResourceInTableResources))(resourceId);

  /* close form drawer before deletion while resource still exists to avoid bugs */
  const resourceBeingEdited = yield select(selectFormInitialResourceFromTable(resourceClass));
  if (resourceBeingEdited && resourceBeingEdited.id === resourceId) {
    yield put(closeFormDrawerAction());
  }

  const apiRequestKey = getGenericApiRequestKey(gra.delete, resourceClass, resourceId);
  const parentResourceTag = yield select(selectRouteResourceParentTag(resourceClass));
  const apiUrlParams = { resourceClass, resourceId, parentResourceTag };

  yield call(apiRequestSaga, apiRequestKey, true, RestResourcesRequests.delete, apiUrlParams);

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    if (resourceClass === rrc.user_project_permission && resourceBeingDeleted.user.id === myUser.id) {
      /* in case user deletes its own project permission */
      const myProjectsUrl = getHomeMenuPageRoute(homeMenuPages.myProjects);
      yield call(navigateRoute, myProjectsUrl);
    } else if (resourceClass === rrc.user_organization_permission && resourceBeingDeleted.user.id === myUser.id) {
      /* in case user deletes its own organization permission */
      const myOrganizationsUrl = getHomeMenuPageRoute(homeMenuPages.myOrganizations);
      yield call(navigateRoute, myOrganizationsUrl);
    } else {
      /* general case: update table */
      yield call(fetchTableUpdatedDataSaga, { resourceClass });

      /* also update parent if needed (only for simulations for now) */
      if (!!parentResourceTag) {
        yield call(fetchSingleRouteResourceSaga, parentResourceTag.resourceClass);
      }
    }

    yield put(closeDeletionModalAction());

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }
}

export function* deleteRestResourceActionSaga(action: IDeleteRestResourceSagaAction): SagaIterator {
  yield call(deleteRestResourceSaga, action.payload);
}

/* _____ COPY_REST_RESOURCE_____ */

export function* copyRestResourceSaga(payload: ICopyRestResourceSagaAction['payload']): SagaIterator {
  const { resourceClass, resourceId, requestParams, getSuccessLandingPageUrl } = payload;

  const apiRequestKey = getGenericApiRequestKey(gra.copy, resourceClass, resourceId);
  const parentResourceTag = yield select(selectRouteResourceParentTag(resourceClass));
  const apiUrlParams = { resourceClass, resourceId, parentResourceTag, detailRoute: dr.copy };

  const copiedRestResource = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    RestResourcesRequests.copy,
    apiUrlParams,
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    /* When a restResource gets created, we enter in its page if getSuccessLandingPageUrl is defined */
    if (!!getSuccessLandingPageUrl) {
      const routeProject = yield select(selectRouteResource(rrc.project));
      const route = getSuccessLandingPageUrl(routeProject.id, copiedRestResource);
      yield call(navigateRoute, route);
    } else {
      /* update table */
      yield call(fetchTableUpdatedDataSaga, { resourceClass });

      /* also update parent if needed (only for simulations for now)*/
      if (!!parentResourceTag) {
        yield call(fetchSingleRouteResourceSaga, parentResourceTag.resourceClass);
      }

      yield put(openFormDrawerAction(fk.restResource, { resourceClass, resourceId: copiedRestResource.id }));
    }

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));

    return copiedRestResource;
  }
}

export function* copyRestResourceActionSaga(action: ICopyRestResourceSagaAction): SagaIterator {
  yield call(copyRestResourceSaga, action.payload);
}

/* _____ CREATE_PROJECT_FROM_TEMPLATE_SAGA ____ */

// tslint:disable-next-line:no-any
export function* createProjectFromTemplateSaga(requestParams: any): SagaIterator {
  const { template_ref, ...projectRequestParams } = requestParams;
  const { template_location, template_use, template_age } = template_ref;

  /* 1. create Project */
  const createdProject = yield call(createRestResourceSaga, {
    resourceClass: rrc.project,
    requestParams: projectRequestParams,
    isLeavingDrawerOpen: true,
  });

  if (createdProject) {
    /* 2. create Weather */
    const createdWeather = yield call(createRestResourceSaga, {
      resourceClass: rrc.weather,
      requestParams: {
        project: createdProject.id,
        name: _replace(template_location, '_', '/'),
        template_ref: template_location,
        template_category: 'template_weather_meteonorm',
      },
      isLeavingDrawerOpen: true,
    });

    /* 3. create Geometry */
    const createdGeometry = yield call(createRestResourceSaga, {
      resourceClass: rrc.geometry,
      requestParams: {
        project: createdProject.id,
        name: $t(template_use),
        template_ref: template_use,
        template_category: 'template_geometry_use',
      },
      isLeavingDrawerOpen: true,
    });

    /* 4. create Obat */
    const createdObat = yield call(createRestResourceSaga, {
      resourceClass: rrc.obat,
      requestParams: {
        project: createdProject.id,
        name: `${$t(template_use)} ${$t(template_age)}`,
        template_ref: `${template_use}_${template_age}`,
        template_category: 'template_obat_use_age',
      },
      isLeavingDrawerOpen: true,
    });

    /* 5. create Mono Simulation Group */
    const createdSimulationGroup = yield call(createRestResourceSaga, {
      resourceClass: rrc.mono_simulation_group,
      requestParams: {
        project: createdProject.id,
        name: `${$t(template_use)} ${$t(template_age)} - ${$t(template_location)}`,
      },
      isLeavingDrawerOpen: true,
    });

    /* 6. edit Mono Simulation Group */
    yield call(editRestResourceSaga, {
      resourceClass: rrc.mono_simulation_group,
      resourceId: createdSimulationGroup.id,
      requestParams: {
        config_geometry: createdGeometry.id,
        config_weather: createdWeather.id,
        config_obat: createdObat.id,
        config_start: getISOPeriodBounds(2018).start,
        config_end: getISOPeriodBounds(2018).end,
      },
    });

    return createdProject;
  }
}

export function* restResourcesActionsSagas(): Generator {
  yield takeLatest(CREATE_REST_RESOURCE_SAGA_ACTION, createRestResourceActionSaga);
  yield takeLatest(EDIT_REST_RESOURCE_SAGA_ACTION, editRestResourceActionSaga);
  yield takeLatest(DELETE_REST_RESOURCE_SAGA_ACTION, deleteRestResourceActionSaga);
  yield takeLatest(COPY_REST_RESOURCE_SAGA_ACTION, copyRestResourceActionSaga);
}
