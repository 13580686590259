import { Grid } from '@material-ui/core';
import { TopSnackbarAlert } from 'components/Alerts';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { SimpleCard } from 'components/Cards';
import { ConfigCardHeader } from 'components/Cards/ConfigCard/ConfigCardHeader';
import { HyperLink } from 'components/Links/HyperLink';
import { OplusLoader } from 'components/Loader';
import { Page } from 'components/Page';
import { LightText } from 'components/Texts';
import { MyAccountForm } from 'pages/HomeMenu/MyAccount/MyAccountForm/MyAccountForm.container';
import { PasswordForm } from 'pages/HomeMenu/MyAccount/PasswordForm';
import React, { PureComponent, ReactNode } from 'react';
import { homeMenuPages } from 'routing/routes';
import { downloadFileFromUrl } from 'utils/functions/downloadFile/downloadFileFromUrl';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './MyAccount.container';

interface IState {
  isPasswordSaving: boolean;
}

export class MyAccount extends PureComponent<IProps, IState> {
  public state: IState = {
    isPasswordSaving: false,
  };

  public componentDidUpdate(prevProps: IProps): void {
    if (prevProps.isPasswordSaving && !this.props.isPasswordSaving && !this.props.isPasswordError) {
      this.setState({ isPasswordSaving: true });
      setTimeout(() => this.setState({ isPasswordSaving: false }), 3000);
    }
  }

  public render(): ReactNode {
    const { isLoading } = this.props;

    return (
      <>
        <Page pageTitle={$t('myAccount')} routingMenu={rm.home} selectedPage={homeMenuPages.myAccount}>
          {isLoading && <OplusLoader />}
          {!isLoading && this.renderCard()}
        </Page>
        <PasswordForm />
        <MyAccountForm />
        <TopSnackbarAlert isOpen={this.state.isPasswordSaving} status={as.success}>
          {$t('saved')}
        </TopSnackbarAlert>
      </>
    );
  }

  private renderCard = (): ReactNode => {
    const { user, openPasswordFormDrawer, openAccountFormDrawer } = this.props;

    if (!user) {
      return null;
    }

    const onPrivacyPolicyClick = () => {
      downloadFileFromUrl('privacy_policy.pdf', `${$t('oplusPrivacyPolicy')}.pdf`);
    };

    return (
      <Grid container>
        <Grid item xs={6}>
          <SimpleCard>
            <ConfigCardHeader title={$t('config')} onClick={openAccountFormDrawer} />
            <Grid container spacing={1} alignItems={'flex-end'}>
              <Grid item xs={3}>
                {$t('firstName')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{user.first_name}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={'flex-end'}>
              <Grid item xs={3}>
                {$t('lastName')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{user.last_name}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={'flex-end'}>
              <Grid item xs={3}>
                {$t('email')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{user.email}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={'flex-end'}>
              <Grid item xs={3}>
                {$t('language')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{$t(user.language)}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={'flex-end'}>
              <Grid item xs={3}>
                {$t('password')}
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={1} alignItems={'center'}>
                  <Grid item>
                    <LightText>*****</LightText>
                  </Grid>
                  <Grid item>
                    <IconButton
                      action={'edit'}
                      onClick={openPasswordFormDrawer}
                      tooltipContent={$t('changePassword')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={'flex-end'}>
              <Grid item xs={12}>
                <HyperLink onMouseDown={onPrivacyPolicyClick}>{$t('downloadPrivacyPolicy')}</HyperLink>
              </Grid>
            </Grid>
          </SimpleCard>
        </Grid>
      </Grid>
    );
  };
}
