import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectIsNoOrganizationMember } from 'redux/restResources/detail/me/selectors';

import { MyProjectsList as Component } from './MyProjectsList.component';

const mapStateToProps = (rootState: IRootState) => {
  return {
    isNoOrganizationMember: selectIsNoOrganizationMember(rootState),
  };
};

const mapDispatchToProps = {};

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = IStateProps & IDispatchProps;

export const MyProjectsList = connect(mapStateToProps, mapDispatchToProps)(Component);
