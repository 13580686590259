import { _includes } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { IObatChildResourceClass, IObatParentResourceClass, IObatResourceClass } from 'utils/strings/resourceClasses';

interface IObatResourcesHierarchy {
  childToParent: { [child in IObatChildResourceClass]: IObatParentResourceClass };
  parentToChildren: { [parent in IObatParentResourceClass]: IObatChildResourceClass[] };
}

export const obatResourcesHierarchy: IObatResourcesHierarchy = {
  parentToChildren: {
    calendar: ['day_group', 'period'],
    year_profile: ['day_profile'],
    opening: [],
    construction: ['layer'],
    schedule: [],
    surface_group: [],
    bridge: [],
    output_zone_group: [],
    script: [],
    system: [],
    activity_zone_group: [],
    variant: ['modification'],
    material: [],
    use: [],
    general: [],
    ef_ep: [],
    e_plus_parameters: [],
  },
  childToParent: {
    day_group: 'calendar',
    period: 'calendar',
    day_profile: 'year_profile',
    layer: 'construction',
    modification: 'variant',
  },
};

// _____ OBAT RESOURCES _____

export const getObatResourceLevel = (resourceClass: IObatResourceClass): 'child' | 'parent' | undefined => {
  if (_includes(_keys(obatResourcesHierarchy.parentToChildren), resourceClass)) {
    return 'parent';
  } else if (_includes(_keys(obatResourcesHierarchy.childToParent), resourceClass)) {
    return 'child';
  }
};

export const getObatParentResourceClassOfChildResourceClass = (
  resourceClass: IObatChildResourceClass
): IObatParentResourceClass => {
  return obatResourcesHierarchy.childToParent[resourceClass];
};

export const getObatResourceChildrensOfParent = (
  resourceClass: IObatParentResourceClass
): IObatChildResourceClass[] => {
  return obatResourcesHierarchy.parentToChildren[resourceClass];
};
