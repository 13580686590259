import { connect } from 'react-redux';
import { selectObatContentsFlatOptionsList } from 'redux/enums/selectors';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { ocfe } from 'utils/configs/enums';
import { orc } from 'utils/strings/resourceClasses';

import { ConstructionForm as ConstructionFormComponent } from './ConstructionForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const constructionToEdit = selectFormInitialResourceFromTable(orc.construction)(rootState);

  return {
    constructionToEdit,
    surfaceCategoryOptions: selectObatContentsFlatOptionsList(ocfe.surface_categories)(rootState),
  };
};

const mapDispatchToProps = {};

export const ConstructionForm = connect(mapStateToProps, mapDispatchToProps)(ConstructionFormComponent);
