import { Datatable } from 'components/Datatable/Datatable.container';
import { DatatableActions } from 'components/Datatable/DatatableActions';
import { RestResourceCopyFormDrawer } from 'components/Forms/RestResourceCopyFormDrawer';
import { OplusLoader } from 'components/Loader';
import { DeletionModal } from 'components/Modals/DeletionModal';
import { RestResourceCommitmentsModal } from 'components/Modals/RestResourceCommitmentsModal';
import React, { PureComponent, ReactNode } from 'react';
import { IRestResource } from 'types/RestResource';
import { pickColumnFilterParams, pickDatatableColumnParams } from 'utils/functions/pickColumnParams';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { ps } from 'utils/strings/progressStatus';
import { rrc } from 'utils/strings/resourceClasses';

import { IProps } from './RestResourcesList.container';

export class RestResourcesList extends PureComponent<IProps> {
  public componentDidMount(): void {
    this.fetchData();
  }

  public render = (): ReactNode => {
    const { isDataEmpty, isLoading, FormComponent, resourceClass, getCreationSuccessLandingPageUrl } = this.props;

    return (
      <>
        {isLoading && <OplusLoader progress={ps.fetchingData} />}
        {!isLoading && !isDataEmpty && this.renderTable()}
        {FormComponent && (
          <>
            <FormComponent getCreationSuccessLandingPageUrl={getCreationSuccessLandingPageUrl} />
            <RestResourceCopyFormDrawer
              resourceClass={resourceClass}
              getCreationSuccessLandingPageUrl={getCreationSuccessLandingPageUrl}
            />
          </>
        )}
        <DeletionModal />
        <RestResourceCommitmentsModal />
      </>
    );
  };

  private fetchData = (): void => {
    const { fetchTableInitialData, parentId, resourceClass, fetchMyOrganizations } = this.props;

    fetchTableInitialData(pickColumnFilterParams(this.getColumnsParams()), resourceClass, parentId);

    if (resourceClass === rrc.project) {
      fetchMyOrganizations();
    }
  };

  private getColumnsParams = (): ITableColumnParams[] => {
    const { columnsParams, availableActions } = this.props;

    const actionsColumnParams = {
      id: 'actions',
      title: ' ',
      renderCustomDOM: this.renderDatatableActions,
      noFilter: true,
      maxWidth: 70,
    };

    return availableActions ? [actionsColumnParams, ...columnsParams] : columnsParams;
  };

  private renderTable = (): ReactNode => {
    const { tableResources, defaultSort } = this.props;

    return (
      tableResources && (
        <Datatable
          tableColumnsParams={pickDatatableColumnParams(this.getColumnsParams())}
          tableResources={tableResources}
          defaultSort={defaultSort || { columnId: 'lastModification', order: 'desc' }}
        />
      )
    );
  };

  private renderDatatableActions = (restResource: IRestResource): ReactNode => {
    const { resourceClass, availableActions } = this.props;

    return (
      <DatatableActions availableActions={availableActions} restResource={restResource} resourceClass={resourceClass} />
    );
  };
}
