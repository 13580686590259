// tslint:disable:no-any
import { FormControl, FormLabel } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ISelectInputProps } from 'components/Forms/Inputs/SingleSelectInputs/DropdownInput/DropdownInput.component';
import { _includes } from 'libs/lodash';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { getSelectOptions } from 'utils/functions/forms/getSelectOptions';
import { ISelectOption } from 'utils/functions/forms/getSelectOptions/getSelectOptions';

export interface IToggleInputProps extends ISelectInputProps {
  value: any;
  noLabel?: boolean;
  disabledOptions?: any[];
}

export class ToggleInput extends PureComponent<IToggleInputProps> {
  public render(): ReactNode {
    const { options, isDisabled, value, inputRef, renderOptionLabel, noLabel, label, disabledOptions } = this.props;

    const selectOptions = getSelectOptions(options, renderOptionLabel);

    return (
      <FormControl>
        {!noLabel && <FormLabel>{label}</FormLabel>}
        <StyledToggleButtonGroup value={value} exclusive onChange={this.onChange} size="small" ref={inputRef}>
          {_map(selectOptions, (option: ISelectOption) => {
            const isOptionDisabled = isDisabled || _includes(disabledOptions, option.value);

            return (
              <StyledToggleButton
                key={option.value}
                value={option.value}
                disabled={isOptionDisabled}
                className={value === option.value && !isOptionDisabled ? 'selected' : ''}
              >
                {option.label}
              </StyledToggleButton>
            );
          })}
        </StyledToggleButtonGroup>
      </FormControl>
    );
  }

  private onChange = (_e: React.ChangeEvent<{}>, selectedValue: any): void => {
    const { onSelect, field } = this.props;
    if (selectedValue !== null) {
      /* selectedValue as set to null when clicking on the already selected value */
      onSelect(field, selectedValue);
    }
  };
}

const StyledToggleButton = styled(ToggleButton)`
  text-transform: none; /* capitalize by default in MUI */
  font-size: 12px;
  color: black;
  border-color: ${theme.colors.input.disabled};
  background: ${theme.colors.input.disabled} !important;
  &.selected {
    color: white;
    background: ${theme.colors.main} !important;
    border-color: ${theme.colors.main};
  }
` as typeof ToggleButton;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-top: 10px;
  background: ${theme.colors.transparent};
` as typeof ToggleButtonGroup;
