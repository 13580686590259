import { DatePickerProps, KeyboardDatePicker } from '@material-ui/pickers';
import { FormLabelAsterisk } from 'components/Forms/FormLabelAsterisk';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { $tcc } from 'utils/functions/translate';

interface IProps {
  field: string;
  value: string;
  maxDate?: string;
  minDate?: string;
  required?: boolean;
  noLabel?: boolean;
}

export type IDateInputProps = IProps & DatePickerProps;

export class DateInput extends PureComponent<IDateInputProps> {
  public render(): ReactNode {
    const { field, value, minDate, maxDate, required, noLabel, ...datePickerProps } = this.props;

    const label = noLabel ? (
      ''
    ) : (
      <span>
        {this.props.label || $tcc(field)} <FormLabelAsterisk required={required} />
      </span>
    );

    return (
      <KeyboardDatePicker
        {...datePickerProps}
        autoOk
        allowKeyboardControl
        value={moment.utc(value)}
        maxDate={maxDate && moment.utc(maxDate)}
        minDate={minDate && moment.utc(minDate)}
        orientation={'landscape'}
        label={label}
        variant="inline"
      />
    );
  }
}
