import { Button } from 'components/Buttons';
import React, { PureComponent, ReactNode } from 'react';
import { Copy, Download, Edit, Eye, Trash2 } from 'react-feather';
import theme from 'style/theme';
import { $t } from 'utils/functions/translate';

export interface IProps {
  action: IIconAction;
  onClick: (event: React.MouseEvent) => void;
  tooltipContent?: string | ReactNode;
  isWritePermissionMissing?: boolean;
  isSeatMissing?: boolean;
  isAdminPermissionMissing?: boolean;
  isDisabled?: boolean;
  size?: number;
}

type IIconAction = 'edit' | 'copy' | 'delete' | 'seeDetails' | 'export';

export class IconButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const {
      size,
      action,
      isDisabled,
      tooltipContent,
      isWritePermissionMissing,
      isAdminPermissionMissing,
      isSeatMissing,
    } = this.props;

    // @ts-ignore
    const { color, Icon } = actionIcons[action];

    return (
      <Button
        Icon={<Icon size={size || 14} />}
        onClick={this.onClick}
        isDisabled={isDisabled}
        tooltipProps={{
          content: tooltipContent || $t(action),
          placement: 'bottom',
        }}
        isAdminPermissionMissing={isAdminPermissionMissing}
        isWritePermissionMissing={isWritePermissionMissing}
        isSeatMissing={isSeatMissing}
        color={color}
        bgColor={theme.colors.transparent}
        hoverColor={'white'}
        hoverBgColor={color}
        height={22}
        noShadow
      />
    );
  }

  private onClick = (event: React.MouseEvent): void => {
    const { onClick, isAdminPermissionMissing, isWritePermissionMissing, isSeatMissing, isDisabled } = this.props;
    event.stopPropagation();
    if (!(isAdminPermissionMissing || isWritePermissionMissing || isSeatMissing || !!isDisabled)) {
      onClick(event);
    }
  };
}

const actionIcons = {
  edit: { Icon: Edit, color: theme.colors.main },
  copy: { Icon: Copy, color: theme.colors.main },
  delete: { Icon: Trash2, color: theme.colors.error },
  export: { Icon: Download, color: theme.colors.main },
  seeDetails: { Icon: Eye, color: theme.colors.info },
};
