import { _includes } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { openAlertModalAction } from 'redux/modals/alertModal/actions';
import { retrieveRestResourceSaga } from 'redux/restResources/generic/sagas';
import { fetchSingleRouteResourceSaga } from 'redux/routing/sagas';
import { selectRoutePage } from 'redux/routing/selectors';
import { selectIsTaskInProgress, selectTask } from 'redux/tasks/selectors';
import { as } from 'utils/strings/alertStatus';
import { rrc } from 'utils/strings/resourceClasses';

import { clearTaskAction, IRefreshTaskSagaAction, REFRESH_TASK_SAGA_ACTION, updateTaskAction } from './actions';

export function* refreshTaskSaga(payload: IRefreshTaskSagaAction['payload']): SagaIterator {
  const { taskKey, pagesWhereToRefresh, routeResourceToUpdateClass, noWarnings } = payload;

  /* get last task stored at this key */
  const lastStoredTask = yield select(selectTask(taskKey));

  if (lastStoredTask) {
    /* get its id and retrieve it */
    const taskId = lastStoredTask.id;
    let task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
    yield put(updateTaskAction(task, taskKey));
    let inProgress = yield select(selectIsTaskInProgress(taskKey));
    let urlPage = yield select(selectRoutePage);

    if (inProgress) {
      /* start repeated retrieve */
      while (inProgress && (!pagesWhereToRefresh || _includes(pagesWhereToRefresh, urlPage))) {
        yield delay(1000);

        task = yield call(retrieveRestResourceSaga, rrc.user_task, taskId);
        yield put(updateTaskAction(task, taskKey));
        inProgress = yield select(selectIsTaskInProgress(taskKey));
        urlPage = yield select(selectRoutePage);
      }

      /* (For import tasks mainly) */
      /* the task has not finished but the pages where to refresh have been left: stop refreshing and clear task */
      /* it avoids useless api calls to update the task status */
      if (inProgress) {
        yield put(clearTaskAction(taskKey));

        return;
      }
    }

    /* the task has finished */
    const finishedTask = yield select(selectTask(taskKey));

    if (routeResourceToUpdateClass) {
      /* update resource so that the status has changed (not empty anymore in case of a simulation group for instance) */
      yield call(fetchSingleRouteResourceSaga, routeResourceToUpdateClass);
    }

    if (finishedTask.status_code === 200) {
      if (!noWarnings && finishedTask.message) {
        yield put(openAlertModalAction(finishedTask.message, as.warning));
      }
    } else {
      yield put(openAlertModalAction(finishedTask.message, as.error));
    }

    yield put(clearTaskAction(taskKey));

    return finishedTask;
  }
}

export function* refreshTaskActionSaga(action: IRefreshTaskSagaAction): SagaIterator {
  yield call(refreshTaskSaga, action.payload);
}

export function* taskActionsSagas(): Generator {
  yield takeLatest(REFRESH_TASK_SAGA_ACTION, refreshTaskActionSaga);
}
