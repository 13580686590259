import { FormControl, FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FormikCheckboxInput } from 'components/Forms/Inputs/BooleanInputs/FormikCheckboxInput';
import { FormikSwitchInput } from 'components/Forms/Inputs/BooleanInputs/FormikSwitchInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getRestResourceFormDrawer } from 'components/Forms/RestResourceFormDrawer';
import { FormikProps } from 'formik';
import { _omit } from 'libs/lodash';
import { getYupBooleanValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { fdw } from 'utils/configs/drawerWidths';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { formatUserName } from 'utils/functions/formatUserName';
import { cumulatePermissions } from 'utils/functions/forms/cumulatePermissions';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './OrganizationPermissionsForm.container';

interface IOrganizationPermissionsFormValues {
  first_name: string;
  last_name: string;
  email: string;
  is_member: boolean;
  can_read: boolean;
  can_write: boolean;
  can_admin: boolean;
  can_spend_daily_seats: boolean;
}

interface IOrganizationPermissionsRequestParams extends IOrganizationPermissionsFormValues {
  is_member: boolean;
}

const RestResourceFormDrawer = getRestResourceFormDrawer<
  IOrganizationPermissionsFormValues,
  IOrganizationPermissionsRequestParams
>();

export class OrganizationPermissionsForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { permissionToEdit } = this.props;

    return (
      <RestResourceFormDrawer
        title={permissionToEdit ? formatUserName(permissionToEdit.user) : undefined}
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        getRequestParams={this.getRequestParams}
        formDrawerWidth={fdw.medium}
        resourceClass={rrc.user_organization_permission}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IOrganizationPermissionsFormValues>): ReactNode => {
      const { permissionToEdit } = this.props;
      const formValues = formikProps.values;

      return (
        <>
          {!permissionToEdit && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikWriteInput field={'first_name'} formikProps={formikProps} inputRef={this.formFocusRef} />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput field={'last_name'} formikProps={formikProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput field={'email'} formikProps={formikProps} required />
              </Grid>
            </Grid>
          )}
          {permissionToEdit && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikSwitchInput
                  field={'is_member'}
                  formikProps={formikProps}
                  label={$t('member')}
                  onSelect={this.onAffiliationSelect(formikProps)}
                />
              </Grid>
              {formValues.is_member && (
                <>
                  <Grid item xs={12}>
                    <FormControl>
                      <StyledFormLabel>{$t('organizationPermission')}</StyledFormLabel>
                      <Grid item xs={12}>
                        <FormikCheckboxInput
                          field={'can_read'}
                          label={$t('canRead')}
                          formikProps={formikProps}
                          onSelect={this.onPermissionSelect(formikProps)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikCheckboxInput
                          field={'can_write'}
                          label={$t('canWrite')}
                          formikProps={formikProps}
                          onSelect={this.onPermissionSelect(formikProps)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikCheckboxInput
                          field={'can_admin'}
                          label={$t('canAdmin')}
                          formikProps={formikProps}
                          onSelect={this.onPermissionSelect(formikProps)}
                        />
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSwitchInput
                      field={'can_spend_daily_seats'}
                      formikProps={formikProps}
                      label={$t('dailySeats')}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </>
      );
    };
  };

  private getInitialFormValues = (): IOrganizationPermissionsFormValues => {
    const { permissionToEdit } = this.props;

    return {
      first_name: setFormInitialValue(permissionToEdit, 'first_name'),
      last_name: setFormInitialValue(permissionToEdit, 'last_name'),
      email: setFormInitialValue(permissionToEdit, 'email'),
      is_member: setFormInitialValue(permissionToEdit, 'is_member'),
      can_read: setFormInitialValue(permissionToEdit, 'can_read'),
      can_write: setFormInitialValue(permissionToEdit, 'can_write'),
      can_admin: setFormInitialValue(permissionToEdit, 'can_admin'),
      can_spend_daily_seats: setFormInitialValue(permissionToEdit, 'can_spend_daily_seats'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IOrganizationPermissionsFormValues> => {
    return yup.object().shape({
      first_name: getYupStringValidation(),
      last_name: getYupStringValidation(),
      email: getYupStringValidation(),
      is_member: getYupBooleanValidation(true),
      can_read: getYupBooleanValidation(),
      can_write: getYupBooleanValidation(),
      can_admin: getYupBooleanValidation(),
      can_spend_daily_seats: getYupBooleanValidation(),
    }) as yup.ObjectSchema<IOrganizationPermissionsFormValues>;
  };

  private getRequestParams = (
    formValues: IOrganizationPermissionsFormValues
  ): IOrganizationPermissionsRequestParams => {
    const { permissionToEdit } = this.props;
    const { is_member, can_read, can_write, can_admin } = formValues;

    const finalFormValues = {
      ...formValues,
      /* status member check will be performed by backend too (06/01/2020) */
      can_read: is_member && can_read,
      can_write: is_member && can_write,
      can_admin: is_member && can_admin,
    };

    const initialFormValues = this.getInitialFormValues();
    const filteredFinalFormValues = permissionToEdit
      ? filterEditedValues(initialFormValues, finalFormValues)
      : finalFormValues;
    const requestParamsFields = _omit(filteredFinalFormValues, ['status']);
    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams as IOrganizationPermissionsRequestParams;
  };

  private onAffiliationSelect = (formikProps: FormikProps<IOrganizationPermissionsFormValues>) => {
    return (_field: keyof IOrganizationPermissionsFormValues, selectedValue: boolean): void => {
      if (selectedValue === false) {
        formikProps.setValues({
          ...formikProps.values,
          is_member: false,
          can_read: false,
          can_write: false,
          can_admin: false,
        });
      } else {
        formikProps.setValues({
          ...formikProps.values,
          is_member: true,
        });
      }
    };
  };

  private onPermissionSelect = (formikProps: FormikProps<IOrganizationPermissionsFormValues>) => {
    return (field: string, isSelected: boolean): void => {
      const { can_read, can_write, can_admin } = cumulatePermissions(field, isSelected, formikProps.values);

      formikProps.setValues({
        ...formikProps.values,
        can_read,
        can_write,
        can_admin,
      });
    };
  };
}

const StyledFormLabel = styled(FormLabel)`
  padding-left: 8px;
  font-size: 80%;
` as typeof FormLabel;
