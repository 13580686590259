import Grid from '@material-ui/core/Grid';
import { FormikSwitchInput } from 'components/Forms/Inputs/BooleanInputs/FormikSwitchInput';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikRadioInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikRadioInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getRestResourceFormDrawer } from 'components/Forms/RestResourceFormDrawer';
import { FormikProps } from 'formik';
import { _omit } from 'libs/lodash';
import { getYupBooleanValidation, getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IGeometryRequestParams } from 'types/Geometry/Geometry';
import { fdw } from 'utils/configs/drawerWidths';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './GeometryForm.container';

export interface IGeometryFormValues {
  project: string;
  name: string;
  comment: string;
  rules_reference_surface: number;
  format: string;
  fromTemplate: boolean;
  template_category: string;
  template_ref: string;
}

const RestResourceFormDrawer = getRestResourceFormDrawer<IGeometryFormValues, IGeometryRequestParams>();

export class GeometryForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public componentDidMount = () => {
    const { fetchGeometriesTemplates } = this.props;
    fetchGeometriesTemplates();
  };

  public render(): ReactNode {
    return (
      <RestResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.small}
        resourceClass={rrc.geometry}
        getRequestParams={this.getRequestParams}
        getCreationSuccessLandingPageUrl={this.props.getCreationSuccessLandingPageUrl}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IGeometryFormValues>): ReactNode => {
      const { geometryToEdit, templateOptions } = this.props;
      const { fromTemplate } = formikProps.values;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikWriteInput field={'name'} formikProps={formikProps} required inputRef={this.formFocusRef} />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput field={'comment'} formikProps={formikProps} multiline />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput
              field={'rules_reference_surface'}
              label={$t('referenceSurfaceWithUnit')}
              formikProps={formikProps}
            />
          </Grid>
          {!geometryToEdit && (
            <>
              <Grid item xs={12}>
                <FormikSwitchInput field={'fromTemplate'} formikProps={formikProps} />
              </Grid>
              {fromTemplate && (
                <Grid item xs={12}>
                  <FormikDropdownInput
                    field={'template_ref'}
                    formikProps={formikProps}
                    options={templateOptions}
                    label={$t('buildingMainUse')}
                    required
                  />
                </Grid>
              )}
              {!fromTemplate && (
                <Grid item xs={12}>
                  <FormikRadioInput
                    field={'format'}
                    formikProps={formikProps}
                    options={[
                      { value: 'floorspace', label: $t('drawing2D') },
                      { value: 'import', label: $t('fromFile') },
                    ]}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): IGeometryFormValues => {
    const { geometryToEdit, routeProject } = this.props;

    return {
      project: routeProject.id,
      name: setFormInitialValue(geometryToEdit, 'name'),
      comment: setFormInitialValue(geometryToEdit, 'comment'),
      rules_reference_surface: setFormInitialValue(geometryToEdit, 'rules_reference_surface'),
      format: geometryToEdit ? (geometryToEdit.format === 'floorspace' ? 'floorspace' : 'import') : 'floorspace',
      fromTemplate: false,
      template_category: 'template_geometry_use',
      template_ref: '',
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IGeometryFormValues> => {
    const { geometryToEdit } = this.props;

    return yup.object().shape({
      name: getYupStringValidation(true),
      format: getYupStringValidation(!geometryToEdit),
      comment: getYupStringValidation(),
      rules_reference_surface: getYupNumberValidation(),
      fromTemplate: getYupBooleanValidation(),
      template_category: getYupStringValidation(),
      template_ref: yup.string().when(['fromTemplate'], {
        is: this.isTemplateRefRequired,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
    }) as yup.ObjectSchema<IGeometryFormValues>;
  };

  private getRequestParams = (formValues: IGeometryFormValues): IGeometryRequestParams => {
    const { geometryToEdit } = this.props;
    const { fromTemplate } = formValues;

    const initialFormValues = this.getInitialFormValues();
    const filteredFinalFormValues = geometryToEdit ? filterEditedValues(initialFormValues, formValues) : formValues;

    let paramsToOmit = ['fromTemplate'];
    if (geometryToEdit) {
      paramsToOmit = [...paramsToOmit, 'template_ref', 'template_category', 'fomrat'];
    } else if (!fromTemplate) {
      paramsToOmit = [...paramsToOmit, 'template_ref', 'template_category'];
    } else {
      paramsToOmit = [...paramsToOmit, 'format'];
    }

    const requestParamsFields = _omit(filteredFinalFormValues, paramsToOmit);
    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams as IGeometryRequestParams;
  };

  private isTemplateRefRequired = (fromTemplate: boolean): boolean => !this.props.geometryToEdit && fromTemplate;
}
