import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikSwitchInput } from 'components/Forms/Inputs/BooleanInputs/FormikSwitchInput';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikRadioInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikRadioInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { Section } from 'components/Section';
import { FormikProps } from 'formik';
import { _isEqual, _size } from 'libs/lodash';
import { _omit } from 'libs/lodash';
import { _pick } from 'libs/lodash';
import { getYupBooleanValidation, getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { departmentOptions } from 'utils/configs/optionsLists';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { convertFormValuesEmptyStringToNull } from 'utils/functions/convertFormValuesEmptyStringToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import * as yup from 'yup';

import { RestResourcesRequests } from 'api/requests/RestResourcesRequests';
import { selectAccessToken } from 'redux/tokens/selectors';
import { dr } from 'utils/strings/detailRoutes';
import { rrc } from 'utils/strings/resourceClasses';
import { IProps } from './WeatherConfigForm.container';

// sizing options
const Rt2012Method = 'rt2012';
const AshraeMethod = 'ashrae';

// historical weather source options
const LegacyOption = 'legacy';
const OpenergyOption = 'openergy';

export interface IWeatherConfigFormValues {
  historical_weather_series_id: string;
  configFillMethod: string;
  dataSource: string;
  location_latitude: number;
  location_longitude: number;
  location_altitude: number;
  location_time_zone_ref: string;
  /* sizing */
  method: string;
  department: string;
  is_coast: boolean;
  ashrae_wmo: string;
}

export interface IWeatherConfigRequestParams {
  historical_weather_series_id?: string;
  location_latitude?: number;
  location_longitude?: number;
  location_altitude?: number;
  location_time_zone_ref?: string;
  /* sizing */
  sizing?: {
    method: string;
    data: {
      department: string;
      is_coast: boolean;
      ashrae_wmo: string;
      heating_design_day: {};
      cooling_design_day: {};
    };
  };
}

const FormDrawer = getFormDrawer();
const Form = getForm<IWeatherConfigFormValues>();

export class WeatherConfigForm extends PureComponent<IProps> {
  public state = {
    timezoneOption: this.props.weatherToEdit.location_time_zone_ref,
    latitude: this.props.weatherToEdit.location_latitude,
    longitude: this.props.weatherToEdit.location_longitude,
    methodOption: this.props.weatherToEdit.sizing ? this.props.weatherToEdit.sizing.method : null,
    wmoOption: this.props.weatherToEdit.sizing ? this.props.weatherToEdit.sizing.data.ashrae_wmo : null,
    stationOption: this.props.weatherToEdit.openergy_historical_weather_series
      ? this.props.weatherToEdit.openergy_historical_weather_series.data_source || OpenergyOption
      : null,
  };
  private formFocusRef = createRef<HTMLInputElement>();

  public fetchWeatherLocationData = async (detailRoute: string, latitude: string, longitude: string) => {
    const { routeWeather, rootState } = this.props;
    const accessToken = selectAccessToken(rootState);
    const apiUrlParams = {
      resourceClass: rrc.weather,
      resourceId: routeWeather.id,
      detailRoute,
    };

    try {
      const response = await RestResourcesRequests.detailRoute(accessToken, apiUrlParams, 'get', {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
      });

      return detailRoute === dr.timezone_ref ? response.timezone : response.closest_wmo;
    } catch (error) {
      throw error;
    }
  };

  public handleCoordinateError = () => {
    this.setState({
      wmoOption: '',
      timezoneOption: '',
      methodOption: '',
    });
  };

  public handleCoordinateChange = (latitude: string | null, longitude: string | null) => {
    this.setState({ latitude, longitude });
    if (latitude !== null && longitude !== null) {
      // fetch timezone ref
      this.fetchWeatherLocationData(dr.timezone_ref, latitude, longitude)
        .then((timezone: string) => this.setSizingMethodFromTzRef(timezone))
        .catch(() => this.handleCoordinateError());
      // fetch closest wmo id
      this.fetchWeatherLocationData(dr.closest_referenced_wmo, latitude, longitude)
        .then((wmo: string) => this.setState({ wmoOption: wmo }))
        .catch(() => this.handleCoordinateError());
    } else {
      this.setState({
        timezoneOption: '',
        methodOption: '',
        wmoOption: '',
      });
    }
  };

  public setSizingMethodFromTzRef = (timezone: string) => {
    // fixme: values are hardcoded, could be improved
    const updatedMethodOption = timezone === 'Europe/Paris' ? Rt2012Method : AshraeMethod;
    this.setState({
      timezoneOption: timezone,
      methodOption: updatedMethodOption,
    });
  };

  public componentDidMount(): void {
    const { fetchOpenergyHistoricalWeatherConfigOptions } = this.props;
    fetchOpenergyHistoricalWeatherConfigOptions({ dataSource: this.state.stationOption });
  }

  public render(): ReactNode {
    const { isSubmitting, editErrors } = this.props;

    return (
      <FormDrawer formKey={fk.weatherConfig} width={fdw.medium} title={$t('config')} formFocusRef={this.formFocusRef}>
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          onSubmit={this.onSubmit}
          isSubmitting={isSubmitting}
          formApiErrors={editErrors}
          forceSubmitEnabled={this.forceSubmitEnabled}
          forceSubmitDisabled={this.forceSubmitDisabled}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    const { stationOptions, isOpenergyWeather } = this.props;

    return (formikProps: FormikProps<IWeatherConfigFormValues>): ReactNode => {
      const { configFillMethod } = formikProps.values;
      const { method } = formikProps.values;

      return (
        <>
          {isOpenergyWeather && (
            <Section title={$t('station')}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormikRadioInput
                    field={'dataSource'}
                    noLabel
                    formikProps={formikProps}
                    options={[
                      { value: OpenergyOption, label: $t(OpenergyOption) },
                      { value: LegacyOption, label: $t(LegacyOption) },
                    ]}
                    renderOptionLabel={$t}
                    onSelect={this.onFormatSelect(formikProps)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikDropdownInput
                    field={'historical_weather_series_id'}
                    formikProps={formikProps}
                    options={stationOptions}
                    label={$t('station')}
                    required
                  />
                </Grid>
              </Grid>
            </Section>
          )}

          <Section title={$t('location')}>
            <Grid container spacing={1}>
              {isOpenergyWeather && (
                <Grid item xs={12}>
                  <FormikRadioInput
                    field={'configFillMethod'}
                    noLabel
                    formikProps={formikProps}
                    options={['auto', 'manual']}
                    renderOptionLabel={$t}
                  />
                </Grid>
              )}
              {configFillMethod === 'manual' && (
                <>
                  <Grid item xs={12}>
                    <FormikWriteInput
                      field={'location_latitude'}
                      formikProps={formikProps}
                      required
                      label={$t('latitude')}
                      inputRef={this.formFocusRef}
                      onBlur={this.handleOnBlur(formikProps, 'location_latitude')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikWriteInput
                      field={'location_longitude'}
                      formikProps={formikProps}
                      required
                      label={$t('longitude')}
                      inputRef={this.formFocusRef}
                      onBlur={this.handleOnBlur(formikProps, 'location_longitude')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikWriteInput
                      field={'location_altitude'}
                      formikProps={formikProps}
                      required
                      label={$t('altitude')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDropdownInput
                      field={'location_time_zone_ref'}
                      formikProps={formikProps}
                      options={[this.state.timezoneOption]}
                      label={$t('timeZone')}
                      required
                    />
                  </Grid>
                  {method === Rt2012Method && (
                    <>
                      <Grid item xs={12}>
                        <FormikDropdownInput
                          field={'department'}
                          formikProps={formikProps}
                          options={departmentOptions}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikSwitchInput field={'is_coast'} formikProps={formikProps} label={$t('coastal')} />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Section>
          {configFillMethod === 'manual' && (
            <Section title={$t('sizing')}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormikDropdownInput
                    field={'method'}
                    formikProps={formikProps}
                    options={[this.state.methodOption]}
                    label={$t('method')}
                    required
                  />
                </Grid>
                {method === AshraeMethod && configFillMethod === 'manual' && (
                  <>
                    <Grid item xs={12}>
                      <FormikDropdownInput
                        field={'ashrae_wmo'}
                        formikProps={formikProps}
                        label={$t('ashrae_wmo')}
                        options={[this.state.wmoOption]}
                        required
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Section>
          )}
        </>
      );
    };
  };

  private onFormatSelect = (formikProps: FormikProps<IWeatherConfigFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      const { fetchOpenergyHistoricalWeatherConfigOptions } = this.props;
      fetchOpenergyHistoricalWeatherConfigOptions({ dataSource: selectedValue });

      formikProps.setValues({
        ...formikProps.values,
        dataSource: selectedValue,
      });
    };
  };

  private handleOnBlur = (formikProps: FormikProps<IWeatherConfigFormValues>, field: string) => {
    return (e: React.FocusEvent<HTMLInputElement>): void => {
      formikProps.setFieldValue(field, e.target.value);
      if (field === 'location_latitude') {
        this.handleCoordinateChange(e.target.value, this.state.longitude);
      } else {
        this.handleCoordinateChange(this.state.latitude, e.target.value);
      }
    };
  };

  private forceSubmitEnabled = (formikProps: FormikProps<IWeatherConfigFormValues>) => {
    return formikProps.values.configFillMethod === 'auto';
  };

  private forceSubmitDisabled = (formikProps: FormikProps<IWeatherConfigFormValues>) => {
    const formValues = formikProps.values;

    return (
      formikProps.values.configFillMethod === 'manual' &&
      _isEqual(_omit(formValues, ['configFillMethod']), _omit(this.getInitialFormValues(), ['configFillMethod']))
    );
  };

  private getInitialFormValues = (): IWeatherConfigFormValues => {
    const { weatherToEdit, isOpenergyWeather } = this.props;

    return {
      historical_weather_series_id: setFormInitialValue(
        weatherToEdit,
        'openergy_historical_weather_series.historical_weather_series'
      ),
      configFillMethod: !isOpenergyWeather ? 'manual' : 'auto',
      dataSource: setFormInitialValue(weatherToEdit, 'openergy_historical_weather_series.data_source', OpenergyOption),
      location_latitude: setFormInitialValue(weatherToEdit, 'location_latitude'),
      location_longitude: setFormInitialValue(weatherToEdit, 'location_longitude'),
      location_altitude: setFormInitialValue(weatherToEdit, 'location_altitude'),
      location_time_zone_ref: setFormInitialValue(weatherToEdit, 'location_time_zone_ref'),
      /* sizing */
      method: setFormInitialValue(weatherToEdit, 'sizing.method', AshraeMethod),
      department: setFormInitialValue(weatherToEdit, 'sizing.data.department'),
      is_coast: setFormInitialValue(weatherToEdit, 'sizing.data.is_coast', false),
      ashrae_wmo: setFormInitialValue(weatherToEdit, 'sizing.data.ashrae_wmo'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IWeatherConfigFormValues> => {
    const { isOpenergyWeather } = this.props;

    return yup.object().shape({
      historical_weather_series_id: getYupStringValidation(),
      configFillMethod: getYupStringValidation(),
      location_latitude: getYupNumberValidation(!isOpenergyWeather, -90, 90, true, true),
      location_longitude: getYupNumberValidation(!isOpenergyWeather, -180, 180, true, true),
      location_altitude: getYupNumberValidation(!isOpenergyWeather),
      location_time_zone_ref: getYupStringValidation(!isOpenergyWeather),
      /* sizing */
      method: getYupStringValidation(!isOpenergyWeather),
      department: getYupStringValidation(!isOpenergyWeather && this.state.methodOption === Rt2012Method),
      is_coast: getYupBooleanValidation(!isOpenergyWeather && this.state.methodOption === Rt2012Method),
      ashrae_wmo: getYupStringValidation(!isOpenergyWeather && this.state.methodOption === AshraeMethod),
    }) as yup.ObjectSchema<IWeatherConfigFormValues>;
  };

  private getRequestParams = (formValues: IWeatherConfigFormValues): IWeatherConfigRequestParams => {
    const { isOpenergyWeather } = this.props;
    const { configFillMethod, method, department, is_coast, ashrae_wmo } = formValues;

    const isManual = configFillMethod === 'manual';

    const editedFormValues =
      isManual && isOpenergyWeather
        ? formValues
        : !isManual && isOpenergyWeather
        ? _pick(formValues, ['historical_weather_series_id'])
        : filterEditedValues(
            convertFormValuesEmptyStringToNull(this.getInitialFormValues()),
            convertFormValuesEmptyStringToNull(formValues)
          );

    let formValuesToOmit = ['configFillMethod', 'dataSource', 'method', 'department', 'is_coast', 'ashrae_wmo'];
    formValuesToOmit = !isOpenergyWeather ? [...formValuesToOmit, 'historical_weather_series_id'] : formValuesToOmit;

    let requestParamsFields = {
      ..._omit(editedFormValues, formValuesToOmit),
    } as IWeatherConfigRequestParams;

    if (isManual) {
      requestParamsFields = {
        ...requestParamsFields,
        sizing: {
          method,
          data: {
            department,
            is_coast,
            ashrae_wmo,
            heating_design_day: {},
            cooling_design_day: {},
          },
        },
      };
    }

    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams;
  };

  private onSubmit = (formValues: IWeatherConfigFormValues): void => {
    const { editWeatherConfig, weatherToEdit } = this.props;

    if (weatherToEdit) {
      const requestParams = this.getRequestParams(formValues);
      editWeatherConfig(requestParams);
    }
  };
}
