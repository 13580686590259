import { _includes } from 'libs/lodash';
import { $t } from 'utils/functions/translate';

export const isOrientationFieldsDisplayed = (surfaceCategory: string): boolean =>
  _includes(['wall_outdoor', 'wall_ground', 'wall_interior'], surfaceCategory);

export const isFilterOuterZonesFieldDisplayed = (surfaceCategory: string): boolean =>
  _includes(['floor_interior', 'wall_interior'], surfaceCategory);

export const getFilterOuterZonesFieldLabel = (surfaceCategory: string): string | undefined => {
  if (surfaceCategory === 'wall_interior') {
    return $t('zone2Filter');
  }
  if (surfaceCategory === 'floor_interior') {
    return $t('lowerZoneFilter');
  }
};

export const getFilterInnerZonesFieldLabel = (surfaceCategory: string): string => {
  if (surfaceCategory === 'wall_interior') {
    return $t('zone1Filter');
  }
  if (surfaceCategory === 'floor_interior') {
    return $t('upperZoneFilter');
  }

  return $t('zonesFilter');
};
