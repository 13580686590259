import { Grid } from '@material-ui/core';
import { Button } from 'components/Buttons';
import { ModalFrame } from 'components/Modals/ModalFrame';
import { CenteredText } from 'components/Texts/CenteredText';
import { _has } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { Check } from 'react-feather';
import { formatUserName } from 'utils/functions/formatUserName';
import { $t } from 'utils/functions/translate';
import { orc, rrc } from 'utils/strings/resourceClasses';

import { IProps } from './DeletionModal.container';

export class DeletionModal extends PureComponent<IProps> {
  public render(): ReactNode {
    const { resourceToDelete, isDeleting, resourceClass, closeDeletionModal, isOpen, myUser } = this.props;

    if (resourceToDelete && resourceClass) {
      const toDelete = _has(resourceToDelete, 'name')
        ? resourceToDelete.name
        : _has(resourceToDelete, 'ref')
        ? resourceToDelete.ref
        : _has(resourceToDelete, 'weak_ref')
        ? resourceToDelete.weak_ref
        : _has(resourceToDelete, 'sort_index')
        ? resourceToDelete.sort_index + 1
        : _has(resourceToDelete, 'user') && myUser && resourceToDelete.user.id === myUser.id
        ? $t('yourself')
        : _has(resourceToDelete, 'user')
        ? formatUserName(resourceToDelete.user)
        : '';

      const Title = <CenteredText>{$t('deletionAlert', { toDelete })}</CenteredText>;

      const Actions = (
        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item>
            <Button Icon={<Check size={20} />} onClick={this.onDeleteClick} isLoading={isDeleting} noShadow />
          </Grid>
        </Grid>
      );

      return <ModalFrame isOpen={isOpen} onClose={closeDeletionModal} Title={Title} Actions={Actions} />;
    }

    return null;
  }

  private onDeleteClick = (): void => {
    const { deleteObatResource, deleteRestResource, resourceClass, resourceToDelete, routeObat } = this.props;

    if (resourceClass) {
      if (_has(orc, resourceClass)) {
        // @ts-ignore
        deleteObatResource(resourceClass, resourceToDelete.id, routeObat.id);
      }
      if (_has(rrc, resourceClass)) {
        // @ts-ignore
        deleteRestResource(resourceClass, resourceToDelete.id);
      }
    }
  };
}
