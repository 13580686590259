// tslint:disable:no-any

import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';
import { WithProjectSubMenu as Component } from './WithProjectSubMenu.component';

export interface IOwnProps {
  selectedSubMenu: string;
  subMenuTitle: string;
  selectedPage: string;
  isSubMenuCollapsed?: boolean;
  subMenuRouteResource: any;
  subMenuPagesDivision: string[][];
  getSubMenuPageRoute: (page: any, projectId: string, menuRouteResourceId: string) => string;
  getSubMenuItemTitleFromPage?: (page: string) => string;
}

export type IStateProps = ReturnType<typeof mapStateToProps>;
export type IDispatchProps = typeof mapDispatchToProps;
export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    routeProject: selectRouteResource(rrc.project)(rootState),
  };
};

const mapDispatchToProps = {};

export const WithProjectSubMenu = connect(mapStateToProps, mapDispatchToProps)(Component);
