import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { CardHeader, SimpleCard } from 'components/Cards';
import { LightText } from 'components/Texts';
import { _get, _isEmpty } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { renderTextValueElseDash } from 'utils/functions/renderValue/renderValue';
import { $t, $tcc } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { IProps } from './ActivityZoneGroupDetail.container';

export class ActivityDetail extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <>
        <SimpleCard>
          <CardHeader>{$t('filters')}</CardHeader>
          <Grid container spacing={2} alignItems="center">
            {this.renderField('filter_geometries', false, $t('geometriesFilter'))}
            {this.renderField('filter_zones', false, $t('zonesFilter'))}
          </Grid>
        </SimpleCard>
        <SimpleCard>
          <CardHeader>{$t('activityZoneGroups')}</CardHeader>
          <Grid container spacing={2} alignItems="center">
            {this.renderField('light', true)}
            {this.renderField('people', true)}
            {this.renderField('electric_equipment', true)}
            {this.renderField('electric_equipment_bis', true)}
            {this.renderField('natural_ventilation', true)}
            {this.renderField('natural_ventilation_bis', true)}
            {this.renderField('infiltration', true)}
            {this.renderField('internal_mass_coefficient', false)}
          </Grid>
        </SimpleCard>
        <SimpleCard>
          <CardHeader>{$t('control')}</CardHeader>
          <Grid container spacing={2} alignItems="center">
            {this.renderField('heating_setpoint', true)}
            {this.renderField('cooling_setpoint', true)}
            {this.renderField('outdoor_air', true)}
            {this.renderField('dhw', true)}
          </Grid>
        </SimpleCard>
        <SimpleCard>
          <CardHeader>{$t('systems')}</CardHeader>
          <Grid container spacing={2} alignItems="center">
            {this.renderField('heating_system', false, $t('heating'))}
            {this.renderField('cooling_system', false, $t('cooling'))}
            {this.renderField('mechanical_ventilation_system', false, $t('mechanicalVentilation'))}
            {this.renderField('dhw_system', false, $t('dhw'))}
          </Grid>
        </SimpleCard>
      </>
    );
  }

  private renderField = (field: string, isScheduleField: boolean, fieldName?: string): ReactNode => {
    const { activityZoneGroup } = this.props;

    const fieldValue = _get(activityZoneGroup, field);

    return (
      <>
        <Grid item xs={4}>
          {fieldName || $tcc(field)}
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item>
              <LightText>{renderTextValueElseDash(fieldValue)}</LightText>
            </Grid>
            {isScheduleField && !!fieldValue && (
              <Grid item>
                <IconButton size={16} action={'seeDetails'} onClick={this.openScheduleModal(fieldValue)} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  private openScheduleModal = (scheduleRef: string | null) => {
    const { openPlotsModal } = this.props;

    return (event: React.MouseEvent): void => {
      event.stopPropagation();
      if (scheduleRef) {
        openPlotsModal(scheduleRef, orc.schedule);
      }
    };
  };
}
