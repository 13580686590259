import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { GeometriesList as Component } from './GeometriesList.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => ({
  routeProject: selectRouteResource(rrc.project)(rootState),
});

const mapDispatchToProps = {
  //
};

export const GeometriesList = connect(mapStateToProps, mapDispatchToProps)(Component);
