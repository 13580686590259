import Grid from '@material-ui/core/Grid/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import * as yup from 'yup';

import { IProps } from './VariantContextForm.container';

export interface ISwitchVariantFormValues {
  variantId?: string;
}

const FormDrawer = getFormDrawer();
const Form = getForm<ISwitchVariantFormValues>();

export class VariantContextForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { isSubmitting, editErrors } = this.props;

    return (
      <FormDrawer
        formKey={fk.variantContext}
        width={fdw.small}
        title={$t('applyVariant')}
        formFocusRef={this.formFocusRef}
      >
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          isSubmitting={isSubmitting}
          onSubmit={this.onSubmit}
          formApiErrors={editErrors}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<ISwitchVariantFormValues>): ReactNode => {
      const { variantOptions } = this.props;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'variantId'}
              formikProps={formikProps}
              label={$t('variant')}
              options={variantOptions}
              required
              inputRef={this.formFocusRef}
            />
          </Grid>
        </Grid>
      );
    };
  };

  private getInitialFormValues = (): ISwitchVariantFormValues => {
    const { variantId } = this.props;

    return { variantId: variantId || '' };
  };

  private getValidationSchema = (): yup.ObjectSchema<ISwitchVariantFormValues> => {
    return yup.object().shape({
      variantId: getYupStringValidation(true),
    }) as yup.ObjectSchema<ISwitchVariantFormValues>;
  };

  private onSubmit = (formValues: ISwitchVariantFormValues): void => {
    const { updateVariantContext, closeFormDrawer } = this.props;
    updateVariantContext(formValues.variantId);
    closeFormDrawer();
  };
}
