import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import {
  selectSimulationGroupConfigOptions,
  selectVariantOptionsByObat,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { SimulationVariantConfig as Component } from './SimulationVariantConfig.component';

interface IOwnProps {
  variantRef?: string | null;
  obatId: string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => ({
  routeProject: selectRouteResource(rrc.project)(rootState),
  configOptions: selectSimulationGroupConfigOptions(rootState),
  variantOptionsByObat: selectVariantOptionsByObat(rootState),
});

const mapDispatchToProps = {};

export const SimulationVariantConfig = connect(mapStateToProps, mapDispatchToProps)(Component);
