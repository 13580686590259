import { getForm } from 'components/Forms/Form/Form.container';
import React, { Component, ReactNode } from 'react';
import { convertFormValuesEmptyStringToNull } from 'utils/functions/convertFormValuesEmptyStringToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';

import { IProps } from './ResourceForm.container';

export class ResourceForm<IResourceFormValues, IResourceRequestParams, IResourceClass> extends Component<
  IProps<IResourceFormValues, IResourceRequestParams, IResourceClass>
> {
  public Form = getForm<IResourceFormValues>();

  public render(): ReactNode {
    const { Form, props } = this;
    const {
      initialResource,
      isSubmittingEdit,
      isSubmittingCreate,
      isSubmittingCopy,
      editErrors,
      createErrors,
      copyErrors,
      obatResourceDeleteProps,
      isCopy,
    } = props;

    return (
      <Form
        {...this.props}
        onSubmit={this.onSubmit}
        isSubmitting={initialResource ? (isCopy ? isSubmittingCopy : isSubmittingEdit) : isSubmittingCreate}
        obatResourceDeleteProps={obatResourceDeleteProps}
        formApiErrors={initialResource ? (isCopy ? copyErrors : editErrors) : createErrors}
      />
    );
  }

  private onSubmit = (formValues: IResourceFormValues): void => {
    const { initialResource, resourceClass, saveResource, getRequestParams, getInitialFormValues } = this.props;

    let requestParams;
    if (!!getRequestParams) {
      requestParams = getRequestParams(formValues);
    } else {
      const initialFormValues = getInitialFormValues();
      const filteredFormValues = initialResource ? filterEditedValues(initialFormValues, formValues) : formValues;
      requestParams = convertFormValuesEmptyStringToNull(filteredFormValues);
    }

    if (resourceClass) {
      saveResource(resourceClass, initialResource, requestParams);
    }
  };
}
