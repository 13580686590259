import { _filter, _get, _has, _map, _reduce } from 'libs/lodash';
import { IObatData } from 'types/Obat/ObatData';
import { IObatResource } from 'types/Obat/ObatResource';

export const listObatResourcesIds = (obatData: IObatData, tableRef: string): string[] => {
  return _map(_get(obatData, tableRef), (obatResource: IObatResource): string => obatResource.id);
};

interface IGroupedObatResources {
  [groupedBy: string]: string[];
}

export const getGroupedObatResourcesIds = (
  obatData: IObatData,
  tableRef: string,
  groupedBy: string,
  groupByOptions: string[]
): IGroupedObatResources => {
  const emptyIds = _reduce(
    groupByOptions,
    (_empty: IGroupedObatResources, option: string): IGroupedObatResources => {
      return { ..._empty, [option]: [] };
    },
    {}
  );

  return _reduce(
    _get(obatData, tableRef),
    (groupedObatResources: IGroupedObatResources, obatResource: IObatResource): IGroupedObatResources => {
      const groupByKey = _get(obatResource, groupedBy);
      const existingIds: string[] = _has(groupedObatResources, groupByKey)
        ? _get(groupedObatResources, groupByKey)
        : [];

      // @ts-ignore
      return { ...groupedObatResources, [groupByKey]: [...existingIds, obatResource.id] };
    },
    emptyIds
  );
};

export const listObatResourcesIdsGroupedBy = (
  obatData: IObatData,
  tableRef: string,
  groupedBy: string,
  groupByKey: string
): string[] => {
  const obatResourcesGroupedBy = _filter(_get(obatData, tableRef), (obatResource: IObatResource): boolean => {
    return _get(obatResource, groupedBy) === groupByKey;
  });

  // @ts-ignore
  return _map(obatResourcesGroupedBy, (obatResource: IObatResource): string => obatResource.id);
};
