import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { FormikProps } from 'formik';
import { _upperCase } from 'libs/lodash';
import { getYupStringValidation } from 'libs/yup';
import React, { ChangeEvent, PureComponent, ReactNode } from 'react';
import { IGenericWeatherImportFields } from 'types/Weather/GenericWeather';
import { fdw } from 'utils/configs/drawerWidths';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import * as yup from 'yup';

import { IProps } from './WeatherImportForm.container';

export interface IGenericWeatherFormValues {
  convention: 'us' | 'eu';
  file_name: string;
  import_format: 'csv' | 'epw';
}

const FormDrawer = getFormDrawer();
const Form = getForm<IGenericWeatherFormValues>();

export class WeatherImportForm extends PureComponent<IProps> {
  // private formFocusRef = createRef<HTMLInputElement>();

  private chosenFile: File | null = null;

  public render(): ReactNode {
    const { isUploadingToAzure, importFormErrors } = this.props;

    const title = $t('importWeather');

    return (
      <FormDrawer formKey={fk.import} width={fdw.large} title={title}>
        <Form
          renderForm={this.renderForm}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          onSubmit={this.onSubmit}
          isSubmitting={isUploadingToAzure}
          forceSubmitDisabled={this.forceSubmitDisabled}
          formApiErrors={importFormErrors}
        />
      </FormDrawer>
    );
  }

  public renderForm = (formikProps: FormikProps<IGenericWeatherFormValues>): ReactNode => {
    const { import_format } = formikProps.values;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikDropdownInput
            field={'import_format'}
            formikProps={formikProps}
            options={['csv', 'epw']}
            onSelect={this.onFormatSelect(formikProps)}
            label={$t('format')}
            required
            renderOptionLabel={_upperCase}
          />
        </Grid>
        {this.isCsvFormatSelected(import_format) && (
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'convention'}
              formikProps={formikProps}
              options={[
                {
                  value: 'us',
                  label: 'US (Sep: "," | Dec: ".")',
                },
                {
                  value: 'eu',
                  label: 'EU (Sep: ";" | Dec: ",")',
                },
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormikWriteInput
            field={'file_name'}
            formikProps={formikProps}
            type={'file'}
            required
            noLabel
            onChange={this.onFileChange(formikProps)}
          />
        </Grid>
      </Grid>
    );
  };

  private getInitialFormValues = (): IGenericWeatherFormValues => {
    return {
      file_name: '',
      import_format: 'epw',
      convention: 'us',
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IGenericWeatherFormValues> => {
    return yup.object().shape({
      file_name: getYupStringValidation(),
      import_format: getYupStringValidation(true),
      convention: getYupStringValidation(),
    }) as yup.ObjectSchema<IGenericWeatherFormValues>;
  };

  private onSubmit = (formValues: IGenericWeatherFormValues): void => {
    if (this.chosenFile) {
      const { import_format, convention } = formValues;
      let importFields: IGenericWeatherImportFields = { import_format };
      if (import_format === 'csv' && convention === 'us') {
        importFields = { ...importFields, csv_separator: ',', csv_decimal: '.' };
      } else if (import_format === 'csv' && convention === 'eu') {
        importFields = { ...importFields, csv_separator: ';', csv_decimal: ',' };
      }

      this.importWeatherData(importFields);
    }
  };

  private importWeatherData = (importFields: IGenericWeatherImportFields): void => {
    const { importWeatherData } = this.props;

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      // @ts-ignore
      const fileContentBlob = new Blob([fileReader.result], { type: 'application/octet-binary' });
      if (fileContentBlob) {
        importWeatherData(importFields, fileContentBlob);
      }
    };
    if (this.chosenFile) {
      fileReader.readAsArrayBuffer(this.chosenFile);
    }
  };

  private onFileChange = (formikProps: FormikProps<IGenericWeatherFormValues>) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      formikProps.setFieldValue('file_name', e.target.value);
      this.chosenFile = e.target.files ? e.target.files[0] : null;
    };
  };

  private onFormatSelect = (formikProps: FormikProps<IGenericWeatherFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      formikProps.setFieldValue('import_format', selectedValue);
    };
  };

  private isCsvFormatSelected = (importFormat: IGenericWeatherFormValues['import_format']): boolean =>
    importFormat === 'csv';

  private forceSubmitDisabled = (): boolean => !this.chosenFile;
}
