import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { CardHeader, SimpleCard } from 'components/Cards';
import { LightText } from 'components/Texts';
import { _get, _includes } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { renderTextValueElseDash } from 'utils/functions/renderValue/renderValue';
import { $t, $tcc } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { IProps } from './SystemDetail.container';

export class SystemDetail extends PureComponent<IProps> {
  public render(): ReactNode {
    const { system } = this.props;
    const { type, method, heat_exchanger_bypass } = system;

    return (
      <>
        {!_includes(['dhw_loop'], type) && (
          <SimpleCard>
            <CardHeader>{$t('ventilationEquipment')}</CardHeader>
            <Grid container spacing={1}>
              {this.renderField('fan_sfp', false, false, $t('fanSfpWithUnit'))}
            </Grid>
          </SimpleCard>
        )}

        {!_includes(['extract_only', 'zone_temperature_control_ideal_ahu'], method) && _includes(['air_loop'], type) && (
          <SimpleCard>
            <CardHeader>{$t('heatExchanger')}</CardHeader>
            <Grid container spacing={1}>
              {!_includes(['cooling_loop', 'heating_loop'], type) && this.renderField('loop_recovery')}
              {heat_exchanger_bypass && this.renderField('heat_exchanger_bypass_outdoor_low_temperature')}
              {heat_exchanger_bypass && this.renderField('heat_exchanger_bypass_outdoor_high_temperature')}
              {heat_exchanger_bypass && this.renderField('heat_exchanger_bypass_setpoint_at_outdoor_low_temperature')}
              {heat_exchanger_bypass && this.renderField('heat_exchanger_bypass_setpoint_at_outdoor_high_temperature')}
            </Grid>
          </SimpleCard>
        )}

        {!_includes(['extract_only'], method) && (_includes(['air_loop'], type) || !_includes(['cooling_loop'], type)) && (
          <SimpleCard>
            <CardHeader>{$t('heating')}</CardHeader>
            <Grid container spacing={1}>
              {this.renderField('heating_energy', false, true, $t('energy'))}
              {this.renderField('heating_efficiency', false, false, $t('efficiency'))}
              {this.renderField('heating_aux_ratio', false, false, $t('auxiliaryRatio'))}
              {this.renderField('heating_aux_baseload', false, false, $t('auxiliaryBaseloadWithUnit'))}
              {this.renderField('heating_availability', true, false, $t('availabilitySchedule'))}
              {this.renderField('heating_capacity', false, false, $t('capacityWithUnit'))}
            </Grid>
          </SimpleCard>
        )}

        {!_includes(['extract_only'], method) &&
          (_includes(['air_loop'], type) || !_includes(['heating_loop', 'dhw_loop'], type)) && (
            <SimpleCard>
              <CardHeader>{$t('cooling')}</CardHeader>
              <Grid container spacing={1}>
                {this.renderField('cooling_energy', false, true, $t('energy'))}
                {this.renderField('cooling_efficiency', false, false, $t('efficiency'))}
                {this.renderField('cooling_aux_ratio', false, false, $t('auxiliaryRatio'))}
                {this.renderField('cooling_aux_baseload', false, false, $t('auxiliaryBaseloadWithUnit'))}
                {this.renderField('cooling_availability', true, false, $t('availabilitySchedule'))}
                {this.renderField('cooling_capacity', false, false, $t('capacityWithUnit'))}
              </Grid>
            </SimpleCard>
          )}

        {_includes(['fixed_supply_temperature_ahu', 'zone_temperature_control_ahu'], method) && (
          <SimpleCard>
            <CardHeader>{$t('advancedSettings')}</CardHeader>
            <Grid container spacing={1}>
              {this.renderField('supply_heating_trigger')}
              {this.renderField('supply_cooling_trigger')}
            </Grid>
          </SimpleCard>
        )}
      </>
    );
  }

  private renderField = (field: string, isSchedule?: boolean, isEnums?: boolean, fieldName?: string): ReactNode => {
    const { system } = this.props;

    const fieldValue = _get(system, field);
    const displayedValue = isEnums ? $tEnums(renderTextValueElseDash(fieldValue)) : renderTextValueElseDash(fieldValue);

    return (
      <>
        <Grid item xs={4}>
          {fieldName || $tcc(field)}
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item>
              <LightText>{displayedValue}</LightText>
            </Grid>
            {isSchedule && !!fieldValue && (
              <Grid item>
                <IconButton size={16} action={'seeDetails'} onClick={this.openScheduleModal(fieldValue)} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  private openScheduleModal = (scheduleRef: string | null) => {
    const { openPlotsModal } = this.props;

    return (event: React.MouseEvent): void => {
      event.stopPropagation();
      if (scheduleRef) {
        openPlotsModal(scheduleRef, orc.schedule);
      }
    };
  };
}
