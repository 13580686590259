import { _map } from 'libs/lodash';
import { _toString } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import {
  clearAzureErrorAction,
  toggleAzureDownloadingStatusAction,
  toggleUploadingStatusAction,
  updateAzureErrorAction,
} from 'redux/azure/actions';
import { openAlertModalAction } from 'redux/modals/alertModal/actions';
import { downloadFromAzureBlob } from 'utils/functions/azure/dowloadFromAzureBlob';
import { downloadMetadataFromAzureContainer } from 'utils/functions/azure/dowloadMetadataFromAzureContainer';
import { downloadSeriesYearDataFromAzureContainer } from 'utils/functions/azure/dowloadSeriesYearDataFromAzureContainer';
import { uploadFileToAzureBlob } from 'utils/functions/azure/uploadFileToAzureBlob';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';

export function* uploadFileToAzureSaga(blobUrl: string, fileContentBlob: Blob): SagaIterator {
  try {
    yield put(toggleUploadingStatusAction(true));
    yield call(uploadFileToAzureBlob, blobUrl, fileContentBlob);
    yield put(clearAzureErrorAction());
  } catch (e) {
    console.warn('Azure file upload error :', e);
    yield put(updateAzureErrorAction(e));
    yield put(openAlertModalAction(e, as.error, $t('fileUploadError')));
  } finally {
    yield put(toggleUploadingStatusAction(false));
  }
}

export function* downloadFromAzureBlobSaga(blobUrl: string, outputFormat: 'json' | 'csv' = 'json'): SagaIterator {
  try {
    yield put(toggleAzureDownloadingStatusAction(true));
    const data = yield call(downloadFromAzureBlob, blobUrl, outputFormat);
    yield put(clearAzureErrorAction());

    return data;
  } catch (e) {
    console.warn('Azure file download error :', e);
    yield put(updateAzureErrorAction(e));
    yield put(openAlertModalAction(e, as.error, $t('fileDownloadError')));
  } finally {
    yield put(toggleAzureDownloadingStatusAction(false));
  }
}

export function* downloadMetadataFromAzureSaga(containerUrl: string, sasToken: string): SagaIterator {
  try {
    yield put(toggleAzureDownloadingStatusAction(true));
    const metadata = yield call(downloadMetadataFromAzureContainer, containerUrl, sasToken);
    yield put(clearAzureErrorAction());

    return metadata.years ? { ...metadata, years: _map(metadata.years, _toString) } : metadata;
  } catch (e) {
    console.warn('Azure metadata download error :', e);
    yield put(updateAzureErrorAction(e));
    yield put(openAlertModalAction(e, as.error, $t('metadataDownloadError')));
  } finally {
    yield put(toggleAzureDownloadingStatusAction(false));
  }
}

export function* downloadSeriesYearDataFromAzureSaga(
  containerUrl: string,
  sasToken: string,
  seriesId: string,
  year: string
): SagaIterator {
  try {
    yield put(toggleAzureDownloadingStatusAction(true));
    const series = yield call(downloadSeriesYearDataFromAzureContainer, containerUrl, sasToken, seriesId, year);
    yield put(clearAzureErrorAction());

    return series;
  } catch (e) {
    console.warn('Azure series year data download error :', e);
    yield put(updateAzureErrorAction(e));
    yield put(openAlertModalAction(e, as.error, $t('dataDownloadError')));
  } finally {
    yield put(toggleAzureDownloadingStatusAction(false));
  }
}

export function* azureActionsSagas(): Generator {
  //
}
