import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectMyUser } from 'redux/restResources/detail/me/selectors';
import { editRestResourceSagaAction } from './../../../../redux/restResources/generic/actions';

import { selectFormApiErrorsTranslationPath, selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { gra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';
import { MyAccountForm as Component } from './MyAccountForm.component';

// tslint:disable-next-line
interface IOwnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState, _ownProps: IOwnProps) => {
  const myUser = selectMyUser(rootState);
  const isSubmitting =
    !!myUser && selectIsApiRequestPending(getGenericApiRequestKey(gra.edit, rrc.user, myUser.id))(rootState);
  const editErrors =
    myUser && selectFormApiErrorsTranslationPath(getGenericApiRequestKey(gra.edit, rrc.user, myUser.id))(rootState);

  return {
    myUser,
    isSubmitting,
    editErrors,
  };
};

const mapDispatchToProps = {
  editRestResource: editRestResourceSagaAction,
};

export const MyAccountForm = connect(mapStateToProps, mapDispatchToProps)(Component);
