import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { LightText } from 'components/Texts';
import { _get } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { IObatResource } from 'types/Obat/ObatResource';
import { IRestResource } from 'types/RestResource';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $tEnums } from 'utils/functions/translate';

interface IProps {
  resource: IRestResource | IObatResource;
  field: string;
  isEnums?: boolean;
  openPlotsModal?: (resourceRef: string) => () => void;
}

export class ConfigCardFieldValue extends PureComponent<IProps> {
  public render(): ReactNode {
    const { resource, field, openPlotsModal, isEnums } = this.props;

    const fieldValue = _get(resource, field);
    const displayedValue = isEnums ? $tEnums(renderTextValueElseDash(fieldValue)) : renderTextValueElseDash(fieldValue);

    return (
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <LightText>{displayedValue}</LightText>
        </Grid>
        {!!openPlotsModal && !!fieldValue && (
          <Grid item>
            <IconButton size={16} action={'seeDetails'} onClick={openPlotsModal(fieldValue)} />
          </Grid>
        )}
      </Grid>
    );
  }
}
