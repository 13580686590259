import { EmailLink } from 'components/Links/EmailLink';
import { RestResourcesListPage } from 'components/RestResources/RestResourcesListPage';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { ProjectPermissionsForm } from 'pages/ProjectMenu/Permissions/ProjectPermissionsForm';
import React, { PureComponent, ReactNode } from 'react';
import { projectMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { IExpandedUserProjectPermission } from 'types/Permissions';
import { getProjectOrganizationPermission } from 'utils/functions/permissions/getProjectOrganizationPermission';
import { renderHighestPremissionValue } from 'utils/functions/permissions/renderHighestPermissionValue';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t } from 'utils/functions/translate';
import { rrc } from 'utils/strings/resourceClasses';

import { rm } from 'utils/strings/routingMenus';
import { IProps } from './ProjectPermissionsList.container';

export class ProjectPermissionsList extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeProject } = this.props;

    return (
      <>
        <RestResourcesListPage
          routingMenu={rm.project}
          routeParentId={routeProject.id}
          selectedPage={projectMenuPages.projectPermissions}
          resourceClass={rrc.user_project_permission}
          parentResourceClass={rrc.project}
          columnsParams={this.getColumnsParams()}
          availableActions={['edit', 'delete']}
          FormComponent={ProjectPermissionsForm}
          createButtonText={$t('invite')}
          defaultSort={{ columnId: 'email', order: 'asc' }}
        />
      </>
    );
  }

  private renderPermission = (expandedUserProjectPermission: IExpandedUserProjectPermission): ReactNode => {
    const { routeProject, isAdminPermissionMissing } = this.props;

    const {
      bits_can_admin,
      bits_can_write,
      bits_can_read,
      can_admin,
      can_write,
      can_read,
    } = expandedUserProjectPermission;

    const highestPermissionValue = renderHighestPremissionValue(bits_can_admin, bits_can_write, bits_can_read);
    const projectPermissionValue = renderHighestPremissionValue(can_admin, can_write, can_read);
    const organizationPermission = getProjectOrganizationPermission(bits_can_admin, bits_can_write, bits_can_read);
    const organizationPermissionValue = renderHighestPremissionValue(
      organizationPermission.can_admin,
      organizationPermission.can_write,
      organizationPermission.can_read
    );

    const isOrganizationPermissionHighest = organizationPermissionValue === highestPermissionValue;
    const isProjectPermissionHighest =
      projectPermissionValue === highestPermissionValue && !isOrganizationPermissionHighest;

    const tooltipContent = (
      <table>
        <thead>
          <tr>
            <td>{`${$t('organization')} ${routeProject.organization.name}`}</td>
            <td>
              <ProjectCell>{`${$t('project')} ${routeProject.name}`}</ProjectCell>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <PermissionCell isHighest={isOrganizationPermissionHighest}>{organizationPermissionValue}</PermissionCell>
            <PermissionCell isHighest={isProjectPermissionHighest}>
              <ProjectCell>{projectPermissionValue}</ProjectCell>
            </PermissionCell>
          </tr>
        </tbody>
      </table>
    );

    return !isAdminPermissionMissing ? (
      <SimpleTooltip placement={'left'} content={tooltipContent}>
        <Underlined>{highestPermissionValue}</Underlined>
      </SimpleTooltip>
    ) : (
      highestPermissionValue
    );
  };

  private getColumnsParams = (): ITableColumnParams[] => {
    return [
      { id: 'firstName', resourceField: 'user.first_name' },
      { id: 'lastName', resourceField: 'user.last_name' },
      {
        id: 'email',
        resourceField: 'user.email',
        renderCustomDOM: (expandedUserProjectPermission: IExpandedUserProjectPermission) => (
          <EmailLink email={expandedUserProjectPermission.user.email} />
        ),
      },
      {
        id: 'permission',
        withFilterOptions: true,
        renderCustomDOM: this.renderPermission,
        getCustomDOMFilterComparisonValue: (expandedUserProjectPermission: IExpandedUserProjectPermission): string => {
          const { bits_can_admin, bits_can_write, bits_can_read } = expandedUserProjectPermission;

          return renderHighestPremissionValue(bits_can_admin, bits_can_write, bits_can_read);
        },
      },
    ];
  };
}

const Underlined = styled.span`
  text-decoration: underline dotted;
`;

interface IPermissionCellProps {
  isHighest: boolean;
}

const ProjectCell = styled.div`
  padding-left: 16px;
`;

const PermissionCell = styled.td`
  font-style: ${(props: IPermissionCellProps): string => (props.isHighest ? 'normal' : 'italic')};
  font-weight: ${(props: IPermissionCellProps): string => (props.isHighest ? 'bold' : 'normal')};
`;
