import { WithProjectSubMenu } from 'components/Menus/WithProjectSubMenu';
import { _map, _split } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import {
  getMultiSimulationGroupMenuPageRoute,
  IMultiSimulationGroupMenuPage,
  multiSimulationGroupMenuPages,
  projectMenuPages,
} from 'routing/routes';
import { $t } from 'utils/functions/translate';

import { IProps } from './WithMultiSimulationGroupMenu.container';

export class WithMultiSimulationGroupMenu extends PureComponent<IProps> {
  public render(): ReactNode {
    const { selectedPage, routeMultiSimulationGroup, children } = this.props;

    const getItemTitleFromPage = (page: string) => $t(_split(page, 'Multi')[0]);

    let pagesDivision: IMultiSimulationGroupMenuPage[][] = [[multiSimulationGroupMenuPages.configMulti]];

    if (routeMultiSimulationGroup.configured) {
      pagesDivision = [[multiSimulationGroupMenuPages.configMulti, multiSimulationGroupMenuPages.monitorMulti]];
    }

    if (routeMultiSimulationGroup.success_nb > 0 && !routeMultiSimulationGroup.obat_changed) {
      pagesDivision = [
        ...pagesDivision,
        [multiSimulationGroupMenuPages.monthlyResultsMulti, multiSimulationGroupMenuPages.hourlyResultsMulti],
      ];
    }

    return (
      <WithProjectSubMenu
        selectedSubMenu={projectMenuPages.simulationGroups}
        subMenuTitle={$t('simulation')}
        selectedPage={selectedPage}
        subMenuRouteResource={routeMultiSimulationGroup}
        subMenuPagesDivision={pagesDivision}
        getSubMenuPageRoute={getMultiSimulationGroupMenuPageRoute}
        getSubMenuItemTitleFromPage={getItemTitleFromPage}
      >
        {children}
      </WithProjectSubMenu>
    );
  }
}
