// tslint:disable:no-any

import { Action } from 'redux';
import { IRestResource } from 'types/RestResource';
import { IRestResourceClass } from 'utils/strings/resourceClasses';

export interface IQueryParams {
  [param: string]: string | number | boolean | undefined;
}

// _____ CREATE_REST_RESOURCE_SAGA_ACTION _____

export const CREATE_REST_RESOURCE_SAGA_ACTION = 'CREATE_REST_RESOURCE_SAGA_ACTION';
export interface ICreateRestResourceSagaAction extends Action<'CREATE_REST_RESOURCE_SAGA_ACTION'> {
  payload: {
    requestParams: any;
    resourceClass: IRestResourceClass;
    getSuccessLandingPageUrl?: (routeProjectId: string, createdRestResource: IRestResource) => string;
    isLeavingDrawerOpen?: boolean;
  };
}
export const createRestResourceSagaAction = (
  resourceClass: IRestResourceClass,
  requestParams: any,
  getSuccessLandingPageUrl?: (routeProjectId: string, createdRestResource: IRestResource) => string,
  isLeavingDrawerOpen?: boolean
): ICreateRestResourceSagaAction => ({
  type: CREATE_REST_RESOURCE_SAGA_ACTION,
  payload: {
    resourceClass,
    requestParams,
    getSuccessLandingPageUrl,
    isLeavingDrawerOpen,
  },
});

// _____ COPY_REST_RESOURCE_SAGA_ACTION _____

export const COPY_REST_RESOURCE_SAGA_ACTION = 'COPY_REST_RESOURCE_SAGA_ACTION';
export interface ICopyRestResourceSagaAction extends Action<'COPY_REST_RESOURCE_SAGA_ACTION'> {
  payload: {
    requestParams: any;
    resourceId: string;
    resourceClass: IRestResourceClass;
    getSuccessLandingPageUrl?: (routeProjectId: string, createdRestResource: IRestResource) => string;
  };
}
export const copyRestResourceSagaAction = (
  resourceClass: IRestResourceClass,
  resourceId: string,
  requestParams: any,
  getSuccessLandingPageUrl?: (routeProjectId: string, createdRestResource: IRestResource) => string
): ICopyRestResourceSagaAction => ({
  type: COPY_REST_RESOURCE_SAGA_ACTION,
  payload: {
    resourceClass,
    resourceId,
    requestParams,
    getSuccessLandingPageUrl,
  },
});

// _____ EDIT_REST_RESOURCE_SAGA_ACTION _____

export const EDIT_REST_RESOURCE_SAGA_ACTION = 'EDIT_REST_RESOURCE_SAGA_ACTION';
export interface IEditRestResourceSagaAction extends Action<'EDIT_REST_RESOURCE_SAGA_ACTION'> {
  payload: {
    resourceClass: IRestResourceClass;
    resourceId: string;
    requestParams: any;
  };
}
export const editRestResourceSagaAction = (
  resourceClass: IRestResourceClass,
  requestParams: any,
  resourceId: string
): IEditRestResourceSagaAction => ({
  type: EDIT_REST_RESOURCE_SAGA_ACTION,
  payload: {
    resourceClass,
    requestParams,
    resourceId,
  },
});

// _____ DELETE_REST_RESOURCE_SAGA_ACTION _____

export const DELETE_REST_RESOURCE_SAGA_ACTION = 'DELETE_REST_RESOURCE_SAGA_ACTION';
export interface IDeleteRestResourceSagaAction extends Action<'DELETE_REST_RESOURCE_SAGA_ACTION'> {
  payload: {
    resourceId: string;
    resourceClass: IRestResourceClass;
  };
}
export const deleteRestResourceSagaAction = (
  resourceClass: IRestResourceClass,
  resourceId: string
): IDeleteRestResourceSagaAction => ({
  type: DELETE_REST_RESOURCE_SAGA_ACTION,
  payload: {
    resourceClass,
    resourceId,
  },
});
