import { Grid } from '@material-ui/core';
import { SimpleCard } from 'components/Cards';
import { ConfigCardHeader } from 'components/Cards/ConfigCard/ConfigCardHeader';
import { SimulationResourceConfig } from 'components/SimulationGroup/SimulationResourceConfig';
import { SimulationVariantConfig } from 'components/SimulationGroup/SimulationVariantConfig';
import { _get } from 'libs/lodash';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { IRestResourceClass, orc, rrc } from 'utils/strings/resourceClasses';

import { IProps } from './MonoSimulationGroupConfigCard.container';

export class MonoSimulationGroupConfigCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeMonoSimulationGroup, openConfigFormDrawer } = this.props;
    const { config_weather, config_obat, config_geometry, config_variant, config_start } = routeMonoSimulationGroup;

    return (
      <SimpleCard>
        <ConfigCardHeader
          title={$t('simulation')}
          onClick={openConfigFormDrawer}
          isConfigDisabled={routeMonoSimulationGroup.status === as.running}
        />
        <Grid container spacing={1} alignItems={'flex-end'}>
          <Grid item xs={4}>
            {$t(rrc.weather)}
          </Grid>
          <Grid item xs={8}>
            <SimulationResourceConfig
              resourceClass={rrc.weather}
              resourceId={config_weather}
              isObsolete={this.isResourceObsolete(rrc.weather)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems={'flex-end'}>
          <Grid item xs={4}>
            {$t(rrc.geometry)}
          </Grid>
          <Grid item xs={8}>
            <SimulationResourceConfig
              resourceClass={rrc.geometry}
              resourceId={config_geometry}
              isObsolete={this.isResourceObsolete(rrc.geometry)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems={'flex-end'}>
          <Grid item xs={4}>
            {$t(rrc.obat)}
          </Grid>
          <Grid item xs={8}>
            <SimulationResourceConfig
              resourceClass={rrc.obat}
              resourceId={config_obat}
              isObsolete={this.isResourceObsolete(rrc.obat)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems={'flex-end'}>
          <Grid item xs={4}>
            {$t(orc.variant)}
          </Grid>
          <Grid item xs={8}>
            <SimulationVariantConfig variantRef={config_variant} obatId={config_obat} />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems={'flex-end'}>
          <Grid item xs={4}>
            {$t('year')}
          </Grid>
          <Grid item xs={8}>
            {renderTextValueElseDash(moment(config_start).year())}
          </Grid>
        </Grid>
        {/*<Grid container spacing={1} alignItems={'center'}>*/}
        {/*<Grid item xs={4}>*/}
        {/*{$t('status')}*/}
        {/*</Grid>*/}
        {/*<Grid item xs={8}>*/}
        {/*<AlertIcon status={status} />*/}
        {/*</Grid>*/}
        {/*</Grid>*/}
      </SimpleCard>
    );
  }

  private isResourceObsolete = (resourceClass: IRestResourceClass): boolean => {
    const { routeMonoSimulationGroup, simulation } = this.props;

    return routeMonoSimulationGroup.obsolete_front && simulation && _get(simulation.obsolete, resourceClass).obsolete;
  };
}
