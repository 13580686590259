import { Grid } from '@material-ui/core';
import { TopSnackbarAlert } from 'components/Alerts';
import { Button } from 'components/Buttons';
import { BottomRightButtons, CenterButtons } from 'components/Buttons/ButtonsContainers';
import { ToolsButton } from 'components/Buttons/ToolsButton';
import { ITool } from 'components/Buttons/ToolsButton/ToolsButton.component';
import { ToggleInput } from 'components/Forms/Inputs/SingleSelectInputs/ToggleInput';
import { OplusLoader } from 'components/Loader';
import { Page } from 'components/Page';
import { _includes } from 'libs/lodash';
import { DegreeDays } from 'pages/WeatherMenu/Data/DegreeDays/DegreeDays';
import { WeatherMeasures } from 'pages/WeatherMenu/Data/Measures/WeatherMeasures';
import { WeatherImportForm } from 'pages/WeatherMenu/Data/WeatherImportForm';
import React, { PureComponent, ReactNode } from 'react';
import { Upload } from 'react-feather';
import { weatherMenuPages } from 'routing/routes';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './WeatherData.container';

interface IState {
  activeTab: string;
}

export class WeatherData extends PureComponent<IProps> {
  public state: IState = {
    activeTab: 'measures',
  };

  public componentDidMount(): void {
    const { refreshImportWeatherTask } = this.props;
    refreshImportWeatherTask();
  }

  public render(): ReactNode {
    const {
      routeWeather,
      openImportForm,
      isImportingWeather,
      weatherImportProgress,
      isExporting,
      isWritePermissionMissing,
      isSeatMissing,
      formDrawerWidth,
    } = this.props;
    const { activeTab } = this.state;

    return (
      <Page pageTitle={$t('data')} routingMenu={rm.weather} selectedPage={weatherMenuPages.weatherData}>
        {routeWeather.empty && !isImportingWeather && formDrawerWidth === 0 && (
          <CenterButtons isPulsing>
            <Button
              Icon={<Upload size={12} />}
              onClick={openImportForm}
              text={$t('import')}
              isWritePermissionMissing={isWritePermissionMissing}
              isSeatMissing={isSeatMissing}
            />
          </CenterButtons>
        )}
        {isImportingWeather && <OplusLoader progress={weatherImportProgress} />}
        {!routeWeather.empty && !isImportingWeather && (
          <>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <ToggleInput
                  field={'tab'}
                  value={activeTab}
                  onSelect={this.onSelectTab}
                  options={['measures', 'degreeDays']}
                  renderOptionLabel={$t}
                />
              </Grid>
            </Grid>
            {activeTab === 'measures' && <WeatherMeasures />}
            {activeTab === 'degreeDays' && <DegreeDays />}
          </>
        )}
        {!routeWeather.empty && !isImportingWeather && (
          <BottomRightButtons formDrawerWidth={formDrawerWidth}>
            <ToolsButton tools={this.getTools()} />
          </BottomRightButtons>
        )}
        <WeatherImportForm />

        <TopSnackbarAlert isOpen={isExporting} status={as.info}>
          {$t('exportInProgress')}
        </TopSnackbarAlert>
      </Page>
    );
  }

  private getTools = (): ITool[] => {
    const {
      openImportForm,
      isExporting,
      isWritePermissionMissing,
      isSeatMissing,
      routeWeather,
      exportDegreeDaysFile,
      exportCsvWeatherFile,
      exportEpwWeatherFile,
    } = this.props;
    const { activeTab } = this.state;

    let tools: ITool[] = [];

    if (routeWeather.generic_weather_series) {
      tools = [
        ...tools,
        {
          text: $t('importNewFile'),
          onClick: openImportForm,
          isWritePermissionMissing,
          isSeatMissing,
        },
      ];
    }

    if (activeTab === 'measures' && !isExporting) {
      tools = [
        ...tools,
        {
          text: $t('exportCsv'),
          onClick: exportCsvWeatherFile,
          isWritePermissionMissing,
          isSeatMissing,
        },
        {
          text: $t('exportEpw'),
          onClick: exportEpwWeatherFile,
          isWritePermissionMissing,
          isSeatMissing,
        },
      ];
    }

    if (activeTab === 'degreeDays') {
      tools = [
        ...tools,
        {
          text: $t('exportDegreeDays'),
          onClick: exportDegreeDaysFile,
          isWritePermissionMissing,
          isSeatMissing,
        },
      ];
    }

    return tools;
  };

  // tslint:disable-next-line:no-any
  private onSelectTab = (_e: any, newValue: string) => {
    const { measuresSeriesIdOptions, openAlertModal } = this.props;
    this.setState({
      activeTab: newValue,
    });
    if (newValue === 'degreeDays' && !_includes(measuresSeriesIdOptions, 'dry_bulb_temperature')) {
      openAlertModal($t('noTemperatureDataAlert'), as.warning, $t('noTemperatureData'));
    }
  };
}
