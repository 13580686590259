import { $t } from 'utils/functions/translate';
import * as yup from 'yup';

export const yupErrors = {
  required: 'yupRequired',
  numberExpected: 'yupNumberExpected',
  invalidRef: 'yupInvalidRef',
  invalidEmail: 'yupInvalidEmail',
  invalidMinLength: 'yupInvalidMinLength',
  minIncluded: 'yupMinIncluded',
  minExcluded: 'yupMinExcluded',
  maxIncluded: 'yupMaxIncluded',
  maxExcluded: 'yupMaxExcluded',
  passwordMisses1Lowercase: 'yupPasswordMisses1Lowercase',
  passwordMisses1Uppercase: 'yupPasswordMisses1Uppercase',
  passwordMisses1Digit: 'yupPasswordMisses1Digit',
  passwordMisses1SpecialCharacter: 'yupPasswordMisses1SpecialCharacter',
  passwordIsTooShort: 'yupPasswordIsTooShort',
};

export const getYupStringValidation = (required: boolean = false, isRef: boolean = false) => {
  let stringValidation = yup.string().nullable();

  if (required) {
    stringValidation = stringValidation.required(yupErrors.required);
  }

  if (isRef) {
    stringValidation = stringValidation.matches(/^[a-z\d_]*$/, yupErrors.invalidRef);
  }

  return stringValidation;
};

export const getYupStringArrayValidation = (required: boolean = false) => {
  let arrayValidation = yup.array().of(getYupStringValidation());

  if (required) {
    arrayValidation = arrayValidation.required(yupErrors.required);
  }

  return arrayValidation;
};

export const getYupNumberValidation = (
  required: boolean = false,
  min: number | undefined = undefined,
  max: number | undefined = undefined,
  minIncluded: boolean = true,
  maxIncluded: boolean = true
) => {
  let numberValidation = yup
    .number()
    .nullable()
    .typeError($t(yupErrors.numberExpected));

  if (required) {
    numberValidation = numberValidation.required(yupErrors.required);
  }
  if (min || min === 0) {
    if (minIncluded) {
      numberValidation = numberValidation.min(min, `${yupErrors.minIncluded}=${min}`);
    } else {
      numberValidation = numberValidation.moreThan(min, `${yupErrors.minExcluded}=${min}`);
    }
  }
  if (max || max === 0) {
    if (maxIncluded) {
      numberValidation = numberValidation.max(max, `${yupErrors.maxIncluded}=${max}`);
    } else {
      numberValidation = numberValidation.lessThan(max, `${yupErrors.maxExcluded}=${max}`);
    }
  }

  return numberValidation;
};

export const getYupBooleanValidation = (required: boolean = false) => {
  let booleanValidation = yup.boolean();

  if (required) {
    booleanValidation = booleanValidation.required(yupErrors.required);
  }

  return booleanValidation;
};

export const getYupPasswordValidation = () => {
  return yup
    .string()
    .required(yupErrors.required)
    .matches(/[a-z]/, yupErrors.passwordMisses1Lowercase)
    .matches(/[A-Z]/, yupErrors.passwordMisses1Uppercase)
    .matches(/\d/, yupErrors.passwordMisses1Digit)
    .matches(/[~!@#$%^&*()_+{}":;'[\]]/, yupErrors.passwordMisses1SpecialCharacter)
    .min(8, yupErrors.passwordIsTooShort);
};
