import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectMyLanguage } from 'redux/restResources/detail/me/selectors';
import { IPeriod } from 'types/Obat/Period';

import { PeriodsDetails as PeriodsDetailsComponent } from './PeriodsDetails.component';

interface IOwnProps {
  periods: IPeriod[];
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IInjectedProps = IStateProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    myLanguage: selectMyLanguage(rootState),
  };
};

export const PeriodsDetails = connect(mapStateToProps)(PeriodsDetailsComponent);
