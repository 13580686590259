import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Buttons/Button.component';
import React, { PureComponent, ReactNode } from 'react';
import { GitBranch, X } from 'react-feather';
import theme from 'style/theme';
import { $t } from 'utils/functions/translate';

import { IProps } from './VariantContextButtons.container';

export class VariantContextButtons extends PureComponent<IProps> {
  public render(): ReactNode {
    const { openVariantContextForm, variantContextId, clearVariantContext } = this.props;

    if (!variantContextId) {
      return null;
    }

    return (
      <Grid container spacing={1} justify={'flex-start'}>
        <Grid item>
          <Button
            Icon={<GitBranch />}
            onClick={openVariantContextForm}
            tooltipProps={{ content: $t('applyVariant'), placement: 'right' }}
            text={variantContextId}
            bgColor={theme.colors.variant}
          />
        </Grid>
        <Grid item>
          <Button
            Icon={<X />}
            onClick={clearVariantContext}
            tooltipProps={{ content: $t('leaveVariantContext'), placement: 'right' }}
            bgColor={theme.colors.variant}
            color={theme.colors.error}
          />
        </Grid>
      </Grid>
    );
  }
}
