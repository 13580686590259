import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { SpaceBetweenCardHeader } from 'components/Cards';
import React, { PureComponent, ReactNode } from 'react';

import { IProps } from './ConfigCardHeader.container';

export class ConfigCardHeader extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isWritePermissionMissing, isSeatMissing, title, onClick, isConfigDisabled } = this.props;

    return (
      <SpaceBetweenCardHeader>
        {title}
        {!!onClick && (
          <IconButton
            action={'edit'}
            onClick={onClick}
            isWritePermissionMissing={isWritePermissionMissing}
            isSeatMissing={isSeatMissing}
            isDisabled={isConfigDisabled}
          />
        )}
      </SpaceBetweenCardHeader>
    );
  }
}
