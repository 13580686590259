import { CLOSE_DAILY_SEAT_MODAL, IDailySeatModalActions, OPEN_DAILY_SEAT_MODAL } from './actions';

export interface IDailySeatModalState {
  isOpen: boolean;
}

export const initialState = {
  isOpen: false,
};

export const dailySeatModalReducer = (
  state: IDailySeatModalState = initialState,
  action: IDailySeatModalActions
): IDailySeatModalState => {
  switch (action.type) {
    case OPEN_DAILY_SEAT_MODAL:
      return { ...state, isOpen: true };

    case CLOSE_DAILY_SEAT_MODAL:
      return { ...state, isOpen: false };

    default:
      return state;
  }
};
