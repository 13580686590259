import { IUserTaskRetrieved } from 'types/UserTask';

import { IRootState } from '../reducer';

export const selectTask = (taskKey: string) => {
  return (rootState: IRootState): IUserTaskRetrieved | undefined => {
    return rootState.tasks[taskKey];
  };
};

export const selectIsTaskInProgress = (taskKey: string) => {
  return (rootState: IRootState): boolean => {
    return rootState.tasks[taskKey] ? !rootState.tasks[taskKey].finished : false;
  };
};
