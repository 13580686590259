import { FormGroup, Grid } from '@material-ui/core';
import { FormikCheckboxInput } from 'components/Forms/Inputs/BooleanInputs/FormikCheckboxInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { FormikProps } from 'formik';
import { _concat } from 'libs/lodash';
import { _filter } from 'libs/lodash';
import { _find } from 'libs/lodash';
import { _get } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _omit } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IObatResource } from 'types/Obat/ObatResource';
import { IUse, IUseRequestParams } from 'types/Obat/Use';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './UseForm.container';

export const subusesKeys: string[] = [
  'consumption_heating_emitter_auxiliary_fan',
  'consumption_heating_emitter_auxiliary_other',
  'consumption_heating_emitter_thermal',
  'consumption_cooling_emitter_auxiliary_other',
  'consumption_cooling_emitter_thermal',
  'consumption_dhw_emitter_auxiliary',
  'consumption_dhw_emitter_thermal',
  // 'production_dhw_renewable',
  'consumption_cooling_emitter_auxiliary_fan',
  'consumption_electric_equipment',
  'consumption_electric_light',
  // 'production_photovoltaic',
  'consumption_ahu_cooling_auxiliary_other',
  'consumption_ahu_cooling_coil_thermal',
  'consumption_ahu_auxiliary_fan',
  'consumption_ahu_heating_auxiliary_other',
  'consumption_ahu_heating_coil_thermal',
];

export interface IUseFormValues {
  consumption_heating_emitter_auxiliary_fan: boolean;
  consumption_heating_emitter_auxiliary_other: boolean;
  consumption_heating_emitter_thermal: boolean;
  consumption_cooling_emitter_auxiliary_other: boolean;
  consumption_cooling_emitter_thermal: boolean;
  consumption_dhw_emitter_auxiliary: boolean;
  consumption_dhw_emitter_thermal: boolean;
  // production_dhw_renewable: boolean;
  consumption_cooling_emitter_auxiliary_fan: boolean;
  consumption_electric_equipment: boolean;
  consumption_electric_light: boolean;
  // production_photovoltaic: boolean;
  consumption_ahu_cooling_auxiliary_other: boolean;
  consumption_ahu_cooling_coil_thermal: boolean;
  consumption_ahu_auxiliary_fan: boolean;
  consumption_ahu_heating_auxiliary_other: boolean;
  consumption_ahu_heating_coil_thermal: boolean;
  ref: string;
  search: string;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IUseFormValues, IUseRequestParams>();

export class UseForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        getRequestParams={this.getRequestParams}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.use}
        forceSubmitDisabled={this.isNoNewSubuseChecked}
      />
    );
  }

  public renderForm = () => {
    /* Need a function here so that the form as rerendered when the props are ready (uses) */
    return (formikProps: FormikProps<IUseFormValues>): ReactNode => {
      const { useToEdit } = this.props;

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput field={'search'} formikProps={formikProps} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <FormGroup row>
              {_map(
                _filter(subusesKeys, (subuse: string): boolean =>
                  _includes($tEnums(subuse).toLowerCase(), formikProps.values.search.toLowerCase())
                ),
                (subuse: string): ReactNode => {
                  return (
                    <Grid item xs={12} key={subuse}>
                      <FormikCheckboxInput
                        field={subuse}
                        label={$tEnums(subuse)}
                        formikProps={formikProps}
                        isDisabled={this.isSubUseDisabled(subuse, useToEdit)}
                      />
                    </Grid>
                  );
                }
              )}
            </FormGroup>
          </Grid>
        </>
      );
    };
  };

  private getInitialFormValues = (): IUseFormValues => {
    const { useToEdit } = this.props;

    return {
      ref: setFormInitialValue(useToEdit, 'ref'),
      ..._reduce(
        subusesKeys,
        // @ts-ignore
        (values: IUseFormValues, subuse: string): IUseFormValues => {
          return { ...values, [subuse]: this.isSubUseAssigned(subuse) };
        },
        {}
      ),
      search: '',
    };
  };

  private getRequestParams = (formValues: IUseFormValues): IUseRequestParams => {
    const { useToEdit } = this.props;

    /* exception here: we don't want to filter the values that have not changed
    because we need to send the full list of subuses for the Patch */
    const filteredFinalFormValues = formValues;

    const subuses = _reduce(
      _omit(filteredFinalFormValues, ['ref', 'search']),
      (subusesToSend: string[], checked: boolean, subuse: string): string[] => {
        return checked && !this.isSubUseDisabled(subuse, useToEdit) ? [...subusesToSend, subuse] : [...subusesToSend];
      },
      []
    );

    return _omit({ ...formValues, subuses }, _concat(subusesKeys, ['search'])) as IUseRequestParams;
  };

  private getValidationSchema = (): yup.ObjectSchema<IUseFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      search: getYupStringValidation(),
    }) as yup.ObjectSchema<IUseFormValues>;
  };

  private isNoNewSubuseChecked = (formikProps: FormikProps<IUseFormValues>): boolean => {
    const { useToEdit } = this.props;

    for (const subuse of subusesKeys) {
      if (_get(formikProps.values, subuse) && !this.isSubUseDisabled(subuse, useToEdit)) {
        return false;
      }
    }

    return true;
  };

  private isSubUseAssigned = (subuse: string): boolean => {
    const { uses } = this.props;

    const notAssignedUse = _find(uses, ['ref', 'not_assigned']);

    return notAssignedUse ? !_includes(notAssignedUse.subuses, subuse) : true;
  };

  private isSubUseDisabled = (subuse: string, useToEdit: IUse | undefined = undefined): boolean => {
    const { uses } = this.props;

    const excludedUses = useToEdit ? ['not_assigned', useToEdit.ref] : ['not_assigned'];

    const usesToCheck = uses
      ? _filter(uses, (use: IObatResource): boolean => {
          return !_includes(excludedUses, use.ref);
        })
      : [];

    for (const useToCheck of usesToCheck) {
      if (_includes(useToCheck.subuses, subuse)) {
        return true;
      }
    }

    return false;
  };
}
