import wretch from 'wretch';

export const downloadMetadataFromAzureContainer = async (containerUrl: string, sasToken: string) => {
  const url = `${containerUrl}metadata.json?${sasToken}`;

  try {
    const wretcher = await wretch(url).get();

    return wretcher.json();
  } catch (e) {
    throw new Error(`Error while downloading metadata from azure: ${e}`);
  }
};
