import { Grid } from '@material-ui/core';
import { IconButton } from 'components/Buttons/IconButton';
import { CardHeader, SimpleCard } from 'components/Cards';
import { LightText } from 'components/Texts';
import { _includes } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { renderTextFloatElseDash } from 'utils/functions/renderValue/renderValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { IProps } from './OpeningDetail.container';

export class OpeningDetail extends PureComponent<IProps> {
  public render(): ReactNode {
    const { opening } = this.props;

    return (
      <>
        <SimpleCard>
          <CardHeader>{$t('opening')}</CardHeader>
          {/*<Grid container spacing={1}>*/}
          {/*<Grid item xs={3}>*/}
          {/*{$t('type')}*/}
          {/*</Grid>*/}
          {/*<Grid item xs={9}>*/}
          {/*<LightText>{$tEnums(opening.type)}</LightText>*/}
          {/*</Grid>*/}
          {/*</Grid>*/}
          <Grid container spacing={1}>
            <Grid item xs={3}>
              {$t('nominalConductanceWithUnit')}
            </Grid>
            <Grid item xs={9}>
              <LightText>{renderTextFloatElseDash(opening.nominal_conductance)}</LightText>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              {$t('conductanceRatio')}
            </Grid>
            <Grid item xs={9}>
              <LightText>{renderTextFloatElseDash(opening.conductance_ratio)}</LightText>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              {$t('solarHeatGain')}
            </Grid>
            <Grid item xs={9}>
              <LightText>{renderTextValueElseDash(opening.solar_heat_gain)}</LightText>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              {$t('visibleTransmittance')}
            </Grid>
            <Grid item xs={9}>
              <LightText>{renderTextValueElseDash(opening.visible_transmittance)}</LightText>
            </Grid>
          </Grid>
        </SimpleCard>
        {this.isExteriorSurface(opening.surface_category) && (
          <SimpleCard>
            <CardHeader>{$t('blinds')}</CardHeader>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('type')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{$tEnums(renderTextValueElseDash(opening.blind_type))}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('position')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{$tEnums(renderTextValueElseDash(opening.blind_position))}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('availabilitySchedule')}
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={1} alignItems={'center'}>
                  <Grid item>
                    <LightText>{renderTextValueElseDash(opening.blind_control_availability)}</LightText>
                  </Grid>
                  {!!opening.blind_control_availability && (
                    <Grid item>
                      <IconButton
                        size={16}
                        action={'seeDetails'}
                        onClick={this.onPlotsModalIconClick(opening.blind_control_availability)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('reflectance')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{renderTextValueElseDash(opening.blind_reflectance)}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('transmittance')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{renderTextValueElseDash(opening.blind_transmittance)}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('angleMethod')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{$tEnums(renderTextValueElseDash(opening.blind_angle_method))}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('angleValue')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{renderTextValueElseDash(opening.blind_angle_value)}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('controlMethod')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{$tEnums(renderTextValueElseDash(opening.blind_control_method))}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('controlValue')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{renderTextValueElseDash(opening.blind_control_value)}</LightText>
              </Grid>
            </Grid>
          </SimpleCard>
        )}
        {this.isExteriorSurface(opening.surface_category) && (
          <SimpleCard>
            <CardHeader>{$t('shadings')}</CardHeader>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('overhangRatio')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{renderTextValueElseDash(opening.shading_overhang_ratio)}</LightText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {$t('finRatio')}
              </Grid>
              <Grid item xs={9}>
                <LightText>{renderTextValueElseDash(opening.shading_fin_ratio)}</LightText>
              </Grid>
            </Grid>
          </SimpleCard>
        )}
      </>
    );
  }

  private isExteriorSurface = (surfaceCategory: string): boolean => {
    return !_includes(['wall_interior', 'floor_interior', 'roof_interior'], surfaceCategory);
  };

  private onPlotsModalIconClick = (scheduleRef: string | null) => {
    return (event: React.MouseEvent): void => {
      event.stopPropagation();
      if (scheduleRef) {
        this.props.openPlotsModal(scheduleRef, orc.schedule);
      }
    };
  };
}
