import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { Loader } from './Loader.component';

interface IProps {
  white?: boolean;
  progress?: string;
}

export class OplusLoader extends PureComponent<IProps> {
  public render(): ReactNode {
    const { progress } = this.props;

    return (
      <Loader progress={progress}>
        <RotatingContainer>
          <img src={process.env.PUBLIC_URL + '/images/symbolOplus.png'} />
        </RotatingContainer>
      </Loader>
    );
  }
}

const RotatingContainer = styled.div`
  width: 100px;
  -webkit-animation: rotatingOplus 1.4s ease infinite;
  animation: rotatingOplus 1.4s ease infinite;
  transform-origin: 50% 48%;
  img {
    width: 100%;
  }
`;
