import { _padStart } from 'libs/lodash';
import moment from 'moment';

export const getISOPeriodBounds = (
  year: number | string,
  month?: number | string,
  day?: number | string
): { start: string; end: string } => {
  const format = 'YYYY-MM-DD';
  const formattedStartDate = `${year}-${month ? _padStart(`${month}`, 2, '0') : '01'}-${
    day ? _padStart(`${day}`, 2, '0') : '01'
  }`;
  const addUnit = day ? 'days' : month ? 'months' : 'years';

  return {
    start: moment.utc(formattedStartDate, format).toISOString(),
    end: moment
      .utc(formattedStartDate, format)
      .add(1, addUnit)
      .subtract(1, 'hours')
      .toISOString(),
  };
};
