// tslint:disable:no-any
import { Action } from 'redux';
import { IOrganizationListed } from 'types/Organization';
import { IUserBase } from 'types/User';

// _____ FETCH_MY_USER_SAGA_ACTION _____

export const FETCH_MY_USER_SAGA_ACTION = 'FETCH_MY_USER_SAGA_ACTION';
export interface IFecthMyUserAction extends Action<'FETCH_MY_USER_SAGA_ACTION'> {}

export const fetchMyUserSagaAction = (): IFecthMyUserAction => ({
  type: FETCH_MY_USER_SAGA_ACTION,
});

export const UPDATE_MY_USER_ACTION = 'UPDATE_MY_USER_ACTION';
export interface IUpdateMyUserAction extends Action<'UPDATE_MY_USER_ACTION'> {
  payload: {
    user: IUserBase;
  };
}
export const updateMyUserAction = (user: IUserBase): IUpdateMyUserAction => ({
  type: UPDATE_MY_USER_ACTION,
  payload: {
    user,
  },
});

// _____ EDIT_MY_PASSWORD_SAGA_ACTION _____

export const EDIT_MY_PASSWORD_SAGA_ACTION = 'EDIT_MY_PASSWORD_SAGA_ACTION';
export interface IEditMyPasswordSagaAction extends Action<'EDIT_MY_PASSWORD_SAGA_ACTION'> {
  payload: {
    myUserId: string;
    requestParams: {
      old_password: string;
      new_password: string;
    };
  };
}
export const editMyPasswordSagaAction = (myUserId: string, requestParams: any): IEditMyPasswordSagaAction => ({
  type: EDIT_MY_PASSWORD_SAGA_ACTION,
  payload: {
    myUserId,
    requestParams,
  },
});

// _____ FETCH_MY_ORGANIZATIONS_SAGA_ACTION _____

export const FETCH_MY_ORGANIZATIONS_SAGA_ACTION = 'FETCH_MY_ORGANIZATIONS_SAGA_ACTION';
export interface IFetchMyOrganizationsAction extends Action<'FETCH_MY_ORGANIZATIONS_SAGA_ACTION'> {}

export const fetchMyOrganizationsSagaAction = (): IFetchMyOrganizationsAction => ({
  type: FETCH_MY_ORGANIZATIONS_SAGA_ACTION,
});

export const UPDATE_MY_ORGANIZATIONS_ACTION = 'UPDATE_MY_ORGANIZATIONS_ACTION';
export interface IUpdateMyOrganizationsAction extends Action<'UPDATE_MY_ORGANIZATIONS_ACTION'> {
  payload: {
    organizations: IOrganizationListed[];
  };
}
export const updateMyOrganizationsAction = (organizations: IOrganizationListed[]): IUpdateMyOrganizationsAction => ({
  type: UPDATE_MY_ORGANIZATIONS_ACTION,
  payload: {
    organizations,
  },
});

export type IMeActions =
  | IFetchMyOrganizationsAction
  | IUpdateMyOrganizationsAction
  | IFecthMyUserAction
  | IEditMyPasswordSagaAction
  | IUpdateMyUserAction;
