import Grid from '@material-ui/core/Grid/Grid';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { FormikProps } from 'formik';
import { _omit } from 'libs/lodash';
import { getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IConstructionRequestParams } from 'types/Obat/Construction';
import { fdw } from 'utils/configs/drawerWidths';
import { convertEmptyFieldsToNull } from 'utils/functions/convertEmptyFieldsToNull';
import { filterEditedValues } from 'utils/functions/filterEditedValues';
import { ISelectOptions } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { getWireframePositionOptions } from 'utils/functions/obatResources/construction/getWireframePositionOptions';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { LayersFormSection } from '../LayersFormSection';

import { IProps } from './ConstructionForm.container';

export interface IConstructionFormValues {
  ref: string;
  surface_category: string;
  wireframe_position: number;
  conductivities_ratio: number | '';
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IConstructionFormValues, IConstructionRequestParams>();

export class ConstructionForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        renderSubForm={this.renderSubForm}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.construction}
        subFormResources={[orc.layer]}
        getRequestParams={this.getRequestParams}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IConstructionFormValues>): ReactNode => {
      const { surfaceCategoryOptions, constructionToEdit } = this.props;

      const { surface_category } = formikProps.values;

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                label={$t('name')}
                formikProps={formikProps}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                formikProps={formikProps}
                field={'surface_category'}
                options={surfaceCategoryOptions}
                required
                renderOptionLabel={$tEnums}
                obatResourceToBeCheckedForCommitment={constructionToEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput formikProps={formikProps} field={'conductivities_ratio'} required />
            </Grid>
            {this.isWireframePositionDisplayed(surface_category) && (
              <Grid item xs={12}>
                <FormikDropdownInput
                  formikProps={formikProps}
                  field={'wireframe_position'}
                  options={this.getWireframePositionOptions(formikProps)}
                  required
                />
              </Grid>
            )}
          </Grid>
        </>
      );
    };
  };

  private getWireframePositionOptions = (formikProps: FormikProps<IConstructionFormValues>): ISelectOptions => {
    const { constructionToEdit } = this.props;
    const { surface_category } = formikProps.values;

    const layers = constructionToEdit ? constructionToEdit.layers_contents : [];

    return surface_category ? getWireframePositionOptions(layers, surface_category) : [];
  };

  private isWireframePositionDisplayed = (surfaceCategory: IConstructionFormValues['surface_category']): boolean =>
    !!surfaceCategory && surfaceCategory !== 'wall_interior';

  private getInitialFormValues = (): IConstructionFormValues => {
    const { constructionToEdit } = this.props;

    return {
      ref: setFormInitialValue(constructionToEdit, 'ref'),
      surface_category: setFormInitialValue(constructionToEdit, 'surface_category'),
      conductivities_ratio: setFormInitialValue(constructionToEdit, 'conductivities_ratio', 1),
      wireframe_position: setFormInitialValue(constructionToEdit, 'wireframe_position'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IConstructionFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      surface_category: getYupStringValidation(true),
      conductivities_ratio: getYupNumberValidation(true),
      wireframe_position: getYupNumberValidation(),
    }) as yup.ObjectSchema<IConstructionFormValues>;
  };

  private getRequestParams = (formValues: IConstructionFormValues): IConstructionRequestParams => {
    const { constructionToEdit } = this.props;

    const initialFormValues = this.getInitialFormValues();
    const filteredFinalFormValues = constructionToEdit ? filterEditedValues(initialFormValues, formValues) : formValues;

    const requestParamsFields =
      formValues.surface_category === 'wall_interior'
        ? _omit(filteredFinalFormValues, ['wireframe_position'])
        : filteredFinalFormValues;
    const requestParams = convertEmptyFieldsToNull(requestParamsFields);

    return requestParams as IConstructionRequestParams;
  };

  private renderSubForm = (): ReactNode => {
    return <LayersFormSection />;
  };
}
