import { _get } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { IMonthlyResultsTemplate } from 'redux/restResources/detail/simulationGroup/reducer';
import aggregateTemplates from 'utils/configs/monthlyResultsPivotTemplates/aggregate.json';
import bySimulationTemplates from 'utils/configs/monthlyResultsPivotTemplates/bySimulation.json';
import { IMonthlyResultsSection, IResultsView, rv } from 'utils/strings/results';

export const getMonthlyResultsInitialTemplate = (
  resultsMode: IResultsView,
  section: IMonthlyResultsSection
): IMonthlyResultsTemplate => {
  return {
    name:
      resultsMode === rv.bySimulation
        ? _keys(_get(bySimulationTemplates, section))[0]
        : _keys(_get(aggregateTemplates, section))[0],
    mode: 'chart',
    unit: 'kwh',
    energy: 'ef',
  };
};
