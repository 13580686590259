// tslint:disable:no-any

import { _map } from 'libs/lodash';
import { _reduce } from 'libs/lodash';
import { _uniq } from 'libs/lodash';
import { getFilterComparisonValue } from 'utils/functions/filter/getFilterComparisonValue';
import { ITableColumnFilterParams, ITableFilterParams } from 'utils/functions/pickColumnParams/pickColumnParams';

export const initializeTableFilterParams = (
  tableFullResourcesList: any[],
  tableFilterParams: ITableFilterParams
): ITableFilterParams => {
  /* add filter options for the filter dropdown input to the columnParams that have withFilterOptions=true */

  return _reduce(
    tableFilterParams,
    (_tableFilterParams: ITableFilterParams, columnFilterParams: ITableColumnFilterParams): ITableFilterParams => {
      const { withFilterOptions } = columnFilterParams;

      if (withFilterOptions) {
        const filterOptions = _uniq(
          _map(tableFullResourcesList, (resource: any): string =>
            getFilterComparisonValue(columnFilterParams, resource)
          )
        );
        const columnFilterParamsWithSelectOptions = { ...columnFilterParams, filterOptions };

        return [..._tableFilterParams, columnFilterParamsWithSelectOptions];
      }

      return [..._tableFilterParams, columnFilterParams];
    },
    []
  );
};
