import { IObatResourceClass } from 'utils/strings/resourceClasses';

import { CLOSE_PLOTS_MODAL_ACTION, IPlotsModalActions, OPEN_PLOTS_MODAL_ACTION } from './actions';

export interface IPlotsModalState {
  resourceId: string | undefined;
  isOpen: boolean;
  resourceClass?: IObatResourceClass | undefined;
}

export const initialState = {
  isOpen: false,
  resourceId: undefined,
  resourceClass: undefined,
};

export const plotsModalReducer = (
  state: IPlotsModalState = initialState,
  action: IPlotsModalActions
): IPlotsModalState => {
  switch (action.type) {
    case OPEN_PLOTS_MODAL_ACTION:
      const { resourceId, resourceClass } = action.payload;

      return { ...state, resourceId, resourceClass, isOpen: true };

    case CLOSE_PLOTS_MODAL_ACTION:
      return { ...state, resourceId: undefined, resourceClass: undefined, isOpen: false };

    default:
      return state;
  }
};
