import { _omit } from 'libs/lodash';
import { IUserTaskRetrieved } from 'types/UserTask';

import { CLEAR_TASK_ACTION, ITasksActions, UPDATE_TASK_ACTION } from './actions';

export interface ITasksState {
  [taskKey: string]: IUserTaskRetrieved;
}

export const initialState = {};

export const tasksReducer = (state: ITasksState = initialState, action: ITasksActions): ITasksState => {
  switch (action.type) {
    case UPDATE_TASK_ACTION:
      const { task, taskKey } = action.payload;

      return {
        ...state,
        [taskKey]: task,
      };

    case CLEAR_TASK_ACTION:
      return _omit(state, [action.payload.taskKey]);

    default:
      return state;
  }
};
