import {
  CLEAR_FLOORPLAN,
  IFloorspaceActions,
  TOGGLE_FLOORPLAN_JUST_SAVED_ACTION,
  UPDATE_FLOORPLAN_ACTION,
} from './actions';

export interface IFloorspaceState {
  floorplan?: object;
  isJustSaved: boolean;
}

export const initialState = {
  floorplan: undefined,
  isJustSaved: false,
};

export const floorspaceReducer = (
  state: IFloorspaceState = initialState,
  action: IFloorspaceActions
): IFloorspaceState => {
  switch (action.type) {
    case UPDATE_FLOORPLAN_ACTION:
      return {
        ...state,
        floorplan: action.payload.floorplan,
      };

    case TOGGLE_FLOORPLAN_JUST_SAVED_ACTION:
      return {
        ...state,
        isJustSaved: action.payload.isJustSaved,
      };

    case CLEAR_FLOORPLAN:
      return initialState;

    default:
      return state;
  }
};
