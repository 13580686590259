import { _forEach, _map, _sortBy } from 'libs/lodash';
import { IDayProfile } from 'types/Obat/DayProfile';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';
import { IYearProfile } from 'types/Obat/YearProfile';

import { getObatResourceIndex } from './getObatResource';
import { expandSeries, seriesToText } from './series';

export const enrichYearProfiles = (obatContents: IObatContents): IObatData => {
  /* calendars must have been enriched (we will use day group sort_group and sort_index) */
  const { day_profile: dayProfiles, year_profile: yearProfiles } = obatContents.data;

  // add the day_profiles_contents key to each year profiles
  let enrichedYearProfiles = _map(
    yearProfiles,
    (yearProfile: IYearProfile): IYearProfile => {
      return { ...yearProfile, day_profiles_contents: [] };
    }
  );

  // enrich day profiles and attach to year profiles
  _forEach(dayProfiles, (dayProfile: IDayProfile): void => {
    // enrich day profile
    dayProfile.expanded_series = expandSeries(dayProfile.series, dayProfile.day_group);
    dayProfile.text = seriesToText(dayProfile.series);

    /* _find day group */
    const dayGroup = obatContents.data.day_group[getObatResourceIndex(obatContents, 'day_group', dayProfile.day_group)];
    dayProfile.sort_group = dayGroup.sort_group;
    dayProfile.sort_index = dayGroup.sort_index;

    // attach to year profile
    const yearProfileIndex = getObatResourceIndex(obatContents, 'year_profile', dayProfile.year_profile);
    enrichedYearProfiles = _map(
      enrichedYearProfiles,
      (yearProfile: IYearProfile, index: number): IYearProfile => {
        if (yearProfileIndex === index) {
          return {
            ...yearProfile,
            day_profiles_contents: [...yearProfile.day_profiles_contents, dayProfile],
          };
        }

        return yearProfile;
      }
    );

    /* sort day profiles */
    enrichedYearProfiles = _map(
      enrichedYearProfiles,
      (yearProfile: IYearProfile): IYearProfile => {
        return {
          ...yearProfile,
          day_profiles_contents: _sortBy(yearProfile.day_profiles_contents, (dp: IDayProfile): number => {
            return dp.sort_index;
          }),
        };
      }
    );
  });

  return { ...obatContents.data, year_profile: enrichedYearProfiles };
};
