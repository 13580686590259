import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

const permissionAlerts = stringsTuple(
  'writePermissionRequired',
  'adminPermissionRequired',
  'spendDailySeatsPermissionRequired',
  'seatRequired'
);
export type IPermissionAlert = typeof permissionAlerts[number];
export const pa = mapStrings<IPermissionAlert>(permissionAlerts);
