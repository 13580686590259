import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { openDailySeatModalAction } from 'redux/modals/dailySeatModal/actions';
import { IRootState } from 'redux/reducer';
import {
  fetchUserOrganizationPermissionsSagaAction,
  manageSeatSagaAction,
} from 'redux/restResources/detail/organization/actions';
import {
  selectCanSpendDailySeats,
  selectTakingSeatUserPermissions,
} from 'redux/restResources/detail/organization/selectors';
import { selectIsAdminPermissionMissing, selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { Seats as Component } from './Seats.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeOrganization = selectRouteResource(rrc.organization)(rootState);

  return {
    routeOrganization,
    takingSeatUserPermissions: selectTakingSeatUserPermissions(rootState),
    isAdminPermissionMissing: selectIsAdminPermissionMissing(rootState),
    canSpendDailySeats: selectCanSpendDailySeats(rootState),
  };
};

const mapDispatchToProps = {
  fetchUserOrganizationPermissions: fetchUserOrganizationPermissionsSagaAction,
  manageSeat: manageSeatSagaAction,
  openDailySeatModal: openDailySeatModalAction,
  openFormDrawer: openFormDrawerSagaAction,
};

export const Seats = connect(mapStateToProps, mapDispatchToProps)(Component);
