export const ordinalSuffix = (n: number | string): string => {
  let nString = n.toString();
  if (/^0/.test(nString)) {
    nString = nString.slice(1, nString.length);
  }
  if (nString === '1') {
    return 'st';
  } else if (/1$/.test(nString)) {
    return `stBis`; /* To distinguish english and french => 21st but 21ème */
  } else if (/2$/.test(nString)) {
    return `nd`;
  } else if (/3$/.test(nString)) {
    return `rd`;
  } else {
    return `th`;
  }
};
