// tslint:disable:no-any

import { ICreateAndInviteRequestParams } from 'pages/OrganizationMenu/Permissions/CreateAndInviteForm/CreateAndInviteForm.component';
import { Action } from 'redux';
import { IUserOrganizationPermission } from 'types/Permissions';

/*** INVITE_GUEST_SAGA_ACTION ***/

export const INVITE_GUEST_SAGA_ACTION = 'INVITE_GUEST_SAGA_ACTION';
export interface IInviteGuestSagaAction extends Action<'INVITE_GUEST_SAGA_ACTION'> {
  payload: {
    requestParams: {
      user_email: string;
    };
  };
}
export const inviteGuestSagaAction = (
  requestParams: IInviteGuestSagaAction['payload']['requestParams']
): IInviteGuestSagaAction => ({
  type: INVITE_GUEST_SAGA_ACTION,
  payload: {
    requestParams,
  },
});

/*** CREATE_USER_SAGA_ACTION ***/

export const CREATE_USER_SAGA_ACTION = 'CREATE_USER_SAGA_ACTION';
export interface ICreateUserSagaAction extends Action<'CREATE_USER_SAGA_ACTION'> {
  payload: {
    requestParams: ICreateAndInviteRequestParams;
  };
}
export const createUserSagaAction = (
  requestParams: ICreateUserSagaAction['payload']['requestParams']
): ICreateUserSagaAction => ({
  type: CREATE_USER_SAGA_ACTION,
  payload: {
    requestParams,
  },
});

/*** FETCH_USER_ORGANIZATION_PERMISSIONS_SAGA_ACTION ***/

export const FETCH_USER_ORGANIZATION_PERMISSIONS_SAGA_ACTION = 'FETCH_USER_ORGANIZATION_PERMISSIONS_SAGA_ACTION';
export interface IFecthUserOrganizationPermissionsSagaAction
  extends Action<'FETCH_USER_ORGANIZATION_PERMISSIONS_SAGA_ACTION'> {
  payload: {
    organizationId: string;
  };
}

export const fetchUserOrganizationPermissionsSagaAction = (
  organizationId: string
): IFecthUserOrganizationPermissionsSagaAction => ({
  type: FETCH_USER_ORGANIZATION_PERMISSIONS_SAGA_ACTION,
  payload: {
    organizationId,
  },
});

/*** UPDATE_USER_ORGANIZATION_PERMISSIONS_SAGA_ACTION ***/

export const UPDATE_USER_ORGANIZATION_PERMISSIONS_ACTION = 'UPDATE_USER_ORGANIZATION_PERMISSIONS_ACTION';
export interface IUpdateUserOrganizationPermissionsAction
  extends Action<'UPDATE_USER_ORGANIZATION_PERMISSIONS_ACTION'> {
  payload: {
    userPermissions: IUserOrganizationPermission[];
  };
}
export const updateUserOrganizationPermissionsAction = (
  userPermissions: IUserOrganizationPermission[]
): IUpdateUserOrganizationPermissionsAction => ({
  type: UPDATE_USER_ORGANIZATION_PERMISSIONS_ACTION,
  payload: {
    userPermissions,
  },
});

/*** MANAGE_SEAT_SAGA_ACTION ***/

export const MANAGE_SEAT_SAGA_ACTION = 'MANAGE_SEAT_SAGA_ACTION';
export interface IManageSeatSagaAction extends Action<'MANAGE_SEAT_SAGA_ACTION'> {
  payload: {
    takeOrLeave: 'take' | 'leave';
    userOrganizationPermissionId?: string;
  };
}

export const manageSeatSagaAction = (
  takeOrLeave: 'take' | 'leave',
  userOrganizationPermissionId?: string
): IManageSeatSagaAction => ({
  type: MANAGE_SEAT_SAGA_ACTION,
  payload: {
    takeOrLeave,
    userOrganizationPermissionId,
  },
});

/*** SPEND_DAILY_SEATS_SAGA_ACTION ***/

export const SPEND_DAILY_SEATS_SAGA_ACTION = 'SPEND_DAILY_SEATS_SAGA_ACTION';
export interface ISpendDailySeatsSagaAction extends Action<'SPEND_DAILY_SEATS_SAGA_ACTION'> {
  payload: {
    amount: number;
  };
}
export const spendDailySeatsSagaAction = (amount: number): ISpendDailySeatsSagaAction => ({
  type: SPEND_DAILY_SEATS_SAGA_ACTION,
  payload: {
    amount,
  },
});

export type IOrganizationActions = IUpdateUserOrganizationPermissionsAction;
