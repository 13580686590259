import { Button } from 'components/Buttons/Button.component';
import { ITooltipProps } from 'components/Tooltip/SimpleTooltip/SimpleTooltip.component';
import React, { PureComponent, ReactNode } from 'react';
import { Plus } from 'react-feather';
import { organizationMenuPages } from 'routing/routes';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';

import { IProps } from './AddButton.container';

export class AddButton extends PureComponent<IProps> {
  public render = (): ReactNode => {
    const { isWritePermissionMissing, isSeatMissing, withText, isDisabled, tooltipContent } = this.props;

    const tooltipProps: ITooltipProps = {
      content: tooltipContent || $t('add'),
      placement: !!withText ? 'bottom' : 'left',
    };

    return (
      <Button
        Icon={<Plus />}
        tooltipProps={tooltipProps}
        onClick={this.openCreationForm}
        isWritePermissionMissing={isWritePermissionMissing}
        isSeatMissing={isSeatMissing}
        isDisabled={isDisabled}
        text={typeof withText === 'string' ? withText : !!withText ? $t('add') : undefined}
      />
    );
  };

  private openCreationForm = () => {
    const { openFormDrawer, routeObat, routePage } = this.props;

    const formKey = !!routeObat
      ? fk.obatResource
      : routePage === organizationMenuPages.organizationPermissions
      ? fk.inviteUser
      : fk.restResource;

    return openFormDrawer(formKey);
  };
}
