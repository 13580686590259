// tslint:disable:no-any
import { _get } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { _toLower } from 'libs/lodash';
import { ITableFilterValues } from 'redux/table/reducer';
import { getFilterComparisonValue } from 'utils/functions/filter/getFilterComparisonValue';
import { ITableFilterParams } from 'utils/functions/pickColumnParams/pickColumnParams';

export const isAllColumnsFilterMatch = (
  resource: any,
  tableFilterParams: ITableFilterParams,
  tableFilterValues: ITableFilterValues
): boolean => {
  for (const columnFilterParams of tableFilterParams) {
    const { id, withFilterOptions } = columnFilterParams;

    const filterValue = _get(tableFilterValues, id);

    if (filterValue !== '') {
      const filterComparisonValue = getFilterComparisonValue(columnFilterParams, resource);

      if (withFilterOptions && filterComparisonValue !== filterValue) {
        /* perfect match for options */
        return false;
      }

      if (!withFilterOptions && !_includes(_toLower(filterComparisonValue), _toLower(filterValue))) {
        /* _includes match and not case sensitive for text */
        return false;
      }
    }
  }

  return true;
};
