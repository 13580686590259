import { Action } from 'redux';
import { IAlertStatus } from 'utils/strings/alertStatus';

export const OPEN_ALERT_MODAL = 'OPEN_ALERT_MODAL';
export interface IOpenAlertModal extends Action<'OPEN_ALERT_MODAL'> {
  payload: {
    alertText: string;
    alertStatus: IAlertStatus;
    modalTitle?: string;
  };
}
export const openAlertModalAction = (
  alertText: string,
  alertStatus: IAlertStatus,
  modalTitle?: string
): IOpenAlertModal => ({
  type: OPEN_ALERT_MODAL,
  payload: {
    alertText,
    alertStatus,
    modalTitle,
  },
});

export const CLOSE_ALERT_MODAL = 'CLOSE_ALERT_MODAL';
export interface ICloseAlertModal extends Action<'CLOSE_ALERT_MODAL'> {}
export const closeAlertModalAction = (): ICloseAlertModal => ({
  type: CLOSE_ALERT_MODAL,
});

export type IAlertModalActions = IOpenAlertModal | ICloseAlertModal;
