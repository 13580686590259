import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath, selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import { selectMyUser } from 'redux/restResources/detail/me/selectors';
import { getDetailApiRequestKey, getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { fk } from 'utils/strings/formKeys';
import { dra, gra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { MyAccount as Component } from './MyAccount.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => {
  const fetchUserApiRequestKey = getGenericApiRequestKey(gra.retrieve, rrc.user, 'me');
  const user = selectMyUser(rootState);

  return {
    isLoading: selectIsApiRequestPending(fetchUserApiRequestKey)(rootState),
    user,
    isPasswordSaving:
      !!user && selectIsApiRequestPending(getDetailApiRequestKey(dra.changePassword, user.id))(rootState),
    isPasswordError: user
      ? !!selectFormApiErrorsTranslationPath(getDetailApiRequestKey(dra.changePassword, user.id))(rootState)
      : false,
  };
};

const mapDispatchToProps = {
  openFormDrawer: openFormDrawerSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: {}) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openAccountFormDrawer: () =>
    stateProps.user &&
    dispatchProps.openFormDrawer(fk.restResource, {
      resourceId: 'me',
      resourceClass: rrc.user,
    }),
  openPasswordFormDrawer: () =>
    stateProps.user &&
    dispatchProps.openFormDrawer(fk.userPassword, {
      resourceId: stateProps.user.id,
      resourceClass: rrc.user,
    }),
});

export const MyAccount = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
