import { _includes } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _pick } from 'libs/lodash';
import { IGeometryListed, IGeometryRetrieved } from 'types/Geometry/Geometry';
import { IObatListed, IObatRetrieved } from 'types/Obat/Obat';
import { IOrganizationRetrieved } from 'types/Organization';
import { IProjectListed, IProjectRetrieved } from 'types/Project';
import { IMonoSimulationGroupRetrieved } from 'types/SimulationGroup/MonoSimulationGroup';
import { IMultiSimulationGroupRetrieved } from 'types/SimulationGroup/MultiSimulationGroup';
import { ISimulationGroupListed } from 'types/SimulationGroup/SimulationGroup';
import { IWeatherListed, IWeatherRetrieved } from 'types/Weather/Weather';
import {
  getResourcesToUpdateFromRouteParams,
  IRouteParams,
} from 'utils/functions/routing/getResourcesToUpdateFromRouteParams';
import { IRestResourceClass, rrc } from 'utils/strings/resourceClasses';

import {
  CLEAN_ROUTE_RESOURCES_AFTER_RENDER_ACTION,
  IRoutingActions,
  TOGGLE_IS_REFRESHING_ROUTE_PAGE_ACTION,
  TOGGLE_IS_ROUTE_RESOURCES_LOADING_ACTION,
  TOGGLE_IS_ROUTE_RESOURCES_UP_TO_DATE_ACTION,
  UPDATE_ALL_ROUTE_RESOURCES_ACTION,
  UPDATE_ROUTE_ACTION,
  UPDATE_ROUTE_HASH_ACTION,
  UPDATE_SINGLE_ROUTE_RESOURCE_ACTION,
} from './actions';

export interface IRouteResources {
  organization?: IOrganizationRetrieved;
  project?: IProjectRetrieved;
  geometry?: IGeometryRetrieved;
  obat?: IObatRetrieved;
  weather?: IWeatherRetrieved;
  mono_simulation_group?: IMonoSimulationGroupRetrieved;
  multi_simulation_group?: IMultiSimulationGroupRetrieved;
}
export interface IRouteResourcesLists {
  project?: IProjectListed[];
  geometry?: IGeometryListed[];
  obat?: IObatListed[];
  weather?: IWeatherListed[];
  simulation_group?: ISimulationGroupListed[];
}

export interface IRoutingState {
  previousRoute: string;
  route: string;
  routeHash: string | undefined;
  routeParams: IRouteParams;
  routeResources: IRouteResources;
  routeResourcesLists: IRouteResourcesLists;
  isRouteResourcesLoading: boolean;
  isRouteResourcesUpToDate: boolean | undefined;
  isRefreshingRoutePage: boolean;
}

export const initialState = {
  previousRoute: '/',
  route: '/',
  routeHash: undefined,
  routeParams: {},
  routeResources: {},
  routeResourcesLists: {},
  isRouteResourcesLoading: false,
  isRouteResourcesUpToDate: undefined,
  isRefreshingRoutePage: false,
};

export const routingReducer = (state: IRoutingState = initialState, action: IRoutingActions): IRoutingState => {
  switch (action.type) {
    case UPDATE_ROUTE_ACTION:
      const { route } = action.payload;

      return {
        ...state,
        previousRoute: state.route,
        route,
      };

    case UPDATE_ROUTE_HASH_ACTION:
      const { routeHash } = action.payload;

      return {
        ...state,
        routeHash,
      };

    case TOGGLE_IS_ROUTE_RESOURCES_LOADING_ACTION:
      const { isRouteResourcesLoading } = action.payload;

      return {
        ...state,
        isRouteResourcesLoading,
      };

    case TOGGLE_IS_ROUTE_RESOURCES_UP_TO_DATE_ACTION:
      const { isRouteResourcesUpToDate } = action.payload;

      return {
        ...state,
        isRouteResourcesUpToDate,
      };

    case CLEAN_ROUTE_RESOURCES_AFTER_RENDER_ACTION:
      const routeResourcesToUpdate = getResourcesToUpdateFromRouteParams(state.routeParams);
      const routeResourcesClassToUpdate = _map(routeResourcesToUpdate, 'resourceClass');
      const routeResourcesListClassToUpdate = _map(routeResourcesClassToUpdate, (resourceClass: IRestResourceClass) => {
        return _includes([rrc.mono_simulation_group, rrc.multi_simulation_group], resourceClass)
          ? rrc.simulation_group
          : resourceClass;
      });

      return {
        ...state,
        isRouteResourcesUpToDate: undefined,
        routeResources: _pick(state.routeResources, routeResourcesClassToUpdate),
        routeResourcesLists: _pick(state.routeResourcesLists, routeResourcesListClassToUpdate),
      };

    case TOGGLE_IS_REFRESHING_ROUTE_PAGE_ACTION:
      const { isRefreshingRoutePage } = action.payload;

      return {
        ...state,
        isRefreshingRoutePage,
      };

    case UPDATE_SINGLE_ROUTE_RESOURCE_ACTION:
      return {
        ...state,
        routeResources: { ...state.routeResources, [action.payload.resourceClass]: action.payload.restResource },
      };

    case UPDATE_ALL_ROUTE_RESOURCES_ACTION:
      return {
        ...state,
        isRouteResourcesUpToDate: true,
        isRouteResourcesLoading: false,
        routeParams: action.payload.routeParams,
        routeResources: { ...state.routeResources, ...action.payload.routeResources },
        routeResourcesLists: { ...state.routeResourcesLists, ...action.payload.routeResourcesLists },

        /* Don't do routeResources: action.payload.routeResources otherwise it causes an errorText while leaving a component
        because the routeResources change before the new page get rendered.
        We either remove the obsolete ones in CLEAN_ROUTE_RESOURCES_AFTER_RENDER_ACTION (We need to remove them for the breadcrumb) */
      };

    default:
      return state;
  }
};
