import {
  IOrganizationActions,
  UPDATE_USER_ORGANIZATION_PERMISSIONS_ACTION,
} from 'redux/restResources/detail/organization/actions';
import { IUserOrganizationPermission } from 'types/Permissions';

export interface IOrganizationState {
  userPermissions: IUserOrganizationPermission[];
}

export const initialState = {
  userPermissions: [],
};

export const organizationReducer = (
  state: IOrganizationState = initialState,
  action: IOrganizationActions
): IOrganizationState => {
  switch (action.type) {
    case UPDATE_USER_ORGANIZATION_PERMISSIONS_ACTION:
      const { userPermissions } = action.payload;

      return {
        ...state,
        userPermissions,
      };

    default:
      return state;
  }
};
