import { IApiErrors, IFormApiErrorsTranslationPath } from 'types/Errors';
import { getFormApiErrorsTranslationPath } from 'utils/functions/forms/getFormApiErrorsTranslationPath';

import { IRootState } from '../reducer';

export const selectIsApiRequestPending = (apiRequestKey: string) => (rootState: IRootState): boolean => {
  const apiRequest = rootState.apiRequests[apiRequestKey];

  return apiRequest ? apiRequest.isPending : false;
};

export const selectApiRequestErrors = (apiRequestKey: string) => (rootState: IRootState): IApiErrors | undefined => {
  const apiRequest = rootState.apiRequests[apiRequestKey];

  return apiRequest && apiRequest.errors;
};

export const selectApiRequestErrorMessage = (apiRequestKey: string) => (rootState: IRootState): string | undefined => {
  const apiRequest = rootState.apiRequests[apiRequestKey];

  return apiRequest && apiRequest.errorMessage;
};

export const selectApiRequestErrorStatus = (apiRequestKey: string) => (rootState: IRootState): number | undefined => {
  const apiRequest = rootState.apiRequests[apiRequestKey];

  return apiRequest && apiRequest.status;
};

export const selectFormApiErrorsTranslationPath = (apiRequestKey: string) => (
  rootState: IRootState
): IFormApiErrorsTranslationPath | undefined => {
  const apiRequest = rootState.apiRequests[apiRequestKey];

  if (apiRequest && apiRequest.errors) {
    return getFormApiErrorsTranslationPath(apiRequest.errors);
  }
};
