import { NewSubFormButton, StartReorderingButton, StopReorderingButton } from 'components/Forms/Buttons/SubFormButtons';
import { getReorderForm } from 'components/Forms/ReorderForm';
import { Section } from 'components/Section/Section.component';
import { _isEqual } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { DayGroupForm } from 'pages/ObatMenu/Calendars/DayGroupForm';
import React, { PureComponent } from 'react';
import { IDayGroup } from 'types/Obat/DayGroup';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { IProps } from './DayGroupsFormSection.container';

interface IState {
  newDayGroupFormOpen: boolean;
  newDayGroupNumber: number;
}

const ReorderForm = getReorderForm<IDayGroup>();

export class DayGroupsFormSection extends PureComponent<IProps, IState> {
  public state: IState = {
    newDayGroupFormOpen: false,
    newDayGroupNumber: 0,
  };

  public componentDidUpdate(prevProps: IProps): void {
    if (!_isEqual(prevProps.calendar, this.props.calendar)) {
      this.setState({ newDayGroupFormOpen: false });
      this.props.toggleReordering(false);
    }
  }

  public render = () => {
    const { calendar, isReordering } = this.props;
    const { newDayGroupFormOpen, newDayGroupNumber } = this.state;

    if (!calendar) {
      return null;
    }

    const onStartReordering = (): void => {
      this.props.toggleReordering(true);
    };

    const onStopReordering = (): void => {
      this.props.toggleReordering(false);
    };

    return (
      <Section title={$t('dayGroups')} marginTop={20}>
        {!!isReordering && (
          <>
            <StopReorderingButton onClick={onStopReordering} text={$t('cancel')} />
            <ReorderForm resourcesToReorder={calendar.day_groups_contents} resourceClass={orc.day_group} />
          </>
        )}

        {!isReordering && (
          <>
            {_size(calendar.day_groups_contents) > 1 && <StartReorderingButton onClick={onStartReordering} />}
            {_map(calendar.day_groups_contents, (dayGroup: IDayGroup, index: number) => (
              <DayGroupForm key={dayGroup.weak_ref} calendar={calendar} dayGroup={dayGroup} titleIndex={++index} />
            ))}
            {newDayGroupFormOpen && (
              <DayGroupForm calendar={calendar} close={this.closeNewDayGroupForm} titleIndex={newDayGroupNumber} />
            )}
            {!newDayGroupFormOpen && (
              <NewSubFormButton onClick={this.openNewFormButtonClick} text={$t('addDayGroup')} />
            )}
          </>
        )}
      </Section>
    );
  };

  private closeNewDayGroupForm = (): void => {
    this.setState({ newDayGroupFormOpen: false });
  };

  private openNewFormButtonClick = (): void => {
    const { calendar } = this.props;

    this.setState({ newDayGroupFormOpen: true, newDayGroupNumber: _size(calendar.day_groups_contents) + 1 });
  };
}
