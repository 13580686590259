import { _includes } from 'libs/lodash';
import { as } from 'utils/strings/alertStatus';

export const isRunEnabled = (simulationGroup: {
  status: string;
  obsolete_front: boolean;
  configured: boolean;
}): boolean => {
  const { configured, obsolete_front, status } = simulationGroup;

  if (!configured || status === as.running) {
    return false;
  }

  return obsolete_front || _includes(['empty', 'server _error'], status);
};
