import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

const detailRoutesTuple = stringsTuple(
  'read_blob_url',
  'upload_url',
  'import_data',
  'threejs_blob_url',
  'source_blob_url',
  'blob_url',
  'export_data',
  'export_dju',
  'projection',
  'config_options',
  'run',
  'eplus_output',
  'hourly_csv',
  'generic_viz',
  'dju',
  'expanded_permissions',
  'me',
  'copy',
  'password',
  'update_obat',
  'add_simulation',
  'copy_simulation',
  'update_simulation',
  'delete_simulation',
  'create_from_template',
  'from_text',
  'auto_config_weather',
  'take_up_seat',
  'leave_seat',
  'spend_daily_seats',
  'generate_gbxml',
  'templates',
  'timezone_ref',
  'closest_referenced_wmo'
);
export type IDetailRoute = typeof detailRoutesTuple[number];
export const dr = mapStrings<IDetailRoute>(detailRoutesTuple);
