// @ts-ignore
import pulse from 'react-animations/lib/pulse';
import styled, { Keyframes, keyframes } from 'styled-components';

interface IRightButtonsProps {
  formDrawerWidth?: number;
}

const ButtonsContainer = styled.div`
  z-index: 1000;
  display: flex;
  // pointer events enable to let the click and hover events pass through except for buttons
  pointer-events: none;
  button {
    pointer-events: auto;
  }
`;

const RightButtons = styled(ButtonsContainer)`
  position: fixed;
  right: ${(props: IRightButtonsProps): string => (props.formDrawerWidth || 0) + 16 + 'px'};
  transition: right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  align-items: center;
  justify-content: flex-end;
`;

export const TopRightButtons = styled(RightButtons)`
  top: 8px;
`;

export const BottomRightButtons = styled(RightButtons)`
  bottom: 14px;
`;

export const BottomCenterButtons = styled(ButtonsContainer)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 14px;
  justify-content: center;
  align-items: center;
`;

export const BottomLeftButtons = styled(ButtonsContainer)`
  position: absolute;
  left: 8px;
  bottom: 14px;
  align-items: center;
  justify-content: flex-end;
`;

interface ICenterButtonsProps {
  isPulsing?: boolean;
  marginTop?: number;
}

export const CenterButtons = styled(ButtonsContainer)`
  margin-top: ${(props: ICenterButtonsProps): string => (props.isPulsing ? 24 : props.marginTop || 0) + 'px'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  button {
    animation-name: ${(props: ICenterButtonsProps): Keyframes | string =>
      props.isPulsing ? keyframes`${pulse}` : 'none'};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
`;
