import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikSwitchInput } from 'components/Forms/Inputs/BooleanInputs/FormikSwitchInput';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { FormikProps } from 'formik';
import { _join } from 'libs/lodash';
import { _last } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { _split } from 'libs/lodash';
import { getYupBooleanValidation, getYupStringValidation } from 'libs/yup';
import React, { ChangeEvent, createRef, PureComponent, ReactNode } from 'react';
import { IImportGeometryFields } from 'types/Geometry/Geometry';
import { fdw } from 'utils/configs/drawerWidths';
import { getExportFileName } from 'utils/functions/getExportFileName';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import * as yup from 'yup';

import { IProps } from './ImportGeometryForm.container';

export interface IImportGeometryFormValues {
  file_name: string;
  import_format: string;
  idf_import_envelope: boolean;
  idf_import_envelope_obat_name: string;
}

const FormDrawer = getFormDrawer();
const Form = getForm<IImportGeometryFormValues>();

export class ImportGeometryForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();
  private chosenFile: File | null = null;

  public render(): ReactNode {
    const { getInitialFormValues, getValidationSchema, props } = this;
    const { isUploadingToAzure, importFormErrors } = props;

    return (
      <FormDrawer formKey={fk.import} width={fdw.large} title={$t('importFile')} formFocusRef={this.formFocusRef}>
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={getInitialFormValues}
          getValidationSchema={getValidationSchema}
          forceSubmitDisabled={this.forceSubmitDisabled}
          isSubmitting={isUploadingToAzure}
          onSubmit={this.onSubmit}
          formApiErrors={importFormErrors}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IImportGeometryFormValues>): ReactNode => {
      const { import_format, idf_import_envelope } = formikProps.values;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikDropdownInput
              field={'import_format'}
              formikProps={formikProps}
              options={['idf', 'gbxml']}
              onSelect={this.onFormatSelect(formikProps)}
              label={$t('format')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikWriteInput
              field={'file_name'}
              formikProps={formikProps}
              type={'file'}
              required
              noLabel
              onChange={this.onFileChange(formikProps)}
              inputRef={this.formFocusRef}
            />
          </Grid>
          {import_format === 'idf' && (
            <Grid item xs={12}>
              <FormikSwitchInput field={'idf_import_envelope'} formikProps={formikProps} label={$t('extractObat')} />
            </Grid>
          )}
          {idf_import_envelope && (
            <Grid item xs={12}>
              <FormikWriteInput
                field={'idf_import_envelope_obat_name'}
                formikProps={formikProps}
                label={$t('obatName')}
                required
              />
            </Grid>
          )}
        </Grid>
      );
    };
  };

  private onFormatSelect = (formikProps: FormikProps<IImportGeometryFormValues>) => {
    return (_field: string, selectedFormat: string): void => {
      formikProps.setValues({
        ...formikProps.values,
        import_format: selectedFormat,
        idf_import_envelope: false,
      });
    };
  };

  private onFileChange = (formikProps: FormikProps<IImportGeometryFormValues>) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      formikProps.setFieldValue('file_name', e.target.value);
      this.chosenFile = e.target.files ? e.target.files[0] : null;
    };
  };

  private isIdfImportEnvelopeSelected = (idfImportEnvelope: string): boolean => !!idfImportEnvelope;

  private getValidationSchema = (): yup.ObjectSchema<IImportGeometryFormValues> => {
    return yup.object().shape({
      file_name: getYupStringValidation(),
      idf_import_envelope: getYupBooleanValidation(),
      idf_import_envelope_obat_name: yup.string().when(['idf_import_envelope'], {
        is: this.isIdfImportEnvelopeSelected,
        then: getYupStringValidation(true),
        otherwise: getYupStringValidation(false),
      }),
    }) as yup.ObjectSchema<IImportGeometryFormValues>;
  };

  // tslint:disable:no-any
  private onSubmit = (formValues: IImportGeometryFormValues): void => {
    const { importGeometry } = this.props;

    if (this.chosenFile) {
      const fileNameSplit = _split(this.chosenFile.name, '.');
      const hasFormat = _size(fileNameSplit) >= 2;
      const fileFormat = hasFormat ? _last(fileNameSplit) : '';
      const fileName = hasFormat ? _join(fileNameSplit.slice(0, -1), '.') : this.chosenFile.name;

      const importFields: IImportGeometryFields = {
        ...formValues,
        file_name: getExportFileName(fileName, fileFormat),
      };

      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        // @ts-ignore
        const fileContentBlob = new Blob([fileReader.result], { type: 'application/octet-binary' });

        if (fileContentBlob) {
          importGeometry(importFields, fileContentBlob);
        }
      };
      if (this.chosenFile) {
        fileReader.readAsArrayBuffer(this.chosenFile);
      }
    }
  };

  private getInitialFormValues = (): IImportGeometryFormValues => {
    const { routeGeometry } = this.props;

    return {
      file_name: '',
      import_format: 'idf',
      idf_import_envelope: false,
      idf_import_envelope_obat_name: routeGeometry.name,
    };
  };

  private forceSubmitDisabled = (): boolean => !this.chosenFile;
}
