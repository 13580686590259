import { $t } from 'utils/functions/translate';

export const renderHighestPremissionValue = (
  canAdmin: boolean | number,
  canWrite: boolean | number,
  canRead: boolean | number,
  isMember: boolean = true
): string => {
  /* isMember only for organizations */
  if (!isMember) {
    return '-';
  }

  if (canAdmin) {
    return $t('admin');
  } else if (canWrite) {
    return $t('write');
  } else if (canRead) {
    return $t('read');
  } else {
    return '-';
  }
};
