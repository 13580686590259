import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikRadioInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikRadioInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { fk } from 'utils/strings/formKeys';
import { rrc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './MyAccountForm.container';

export interface IAccountFormValues {
  first_name: string;
  last_name: string;
  language: string;
}
export type IAccountRequestParams = Partial<IAccountFormValues>;

const FormDrawer = getFormDrawer();
const Form = getForm<IAccountFormValues>();

export class MyAccountForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    const { isSubmitting, editErrors } = this.props;

    return (
      <FormDrawer formKey={fk.restResource} width={fdw.medium} title={$t('myAccount')} formFocusRef={this.formFocusRef}>
        <Form
          renderForm={this.renderForm()}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          onSubmit={this.onSubmit}
          isSubmitting={isSubmitting}
          formApiErrors={editErrors}
        />
      </FormDrawer>
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IAccountFormValues>): ReactNode => {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'first_name'}
                formikProps={formikProps}
                label={$t('firstName')}
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikWriteInput field={'last_name'} formikProps={formikProps} label={$t('lastName')} />
            </Grid>
            <Grid item xs={12}>
              <FormikRadioInput
                field={'language'}
                formikProps={formikProps}
                options={[
                  { value: 'en', label: 'English' },
                  { value: 'fr', label: 'Français' },
                ]}
              />
            </Grid>
          </Grid>
        </>
      );
    };
  };

  private getInitialFormValues = (): IAccountFormValues => {
    const { myUser } = this.props;

    return {
      first_name: setFormInitialValue(myUser, 'first_name'),
      last_name: setFormInitialValue(myUser, 'last_name'),
      language: setFormInitialValue(myUser, 'language'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IAccountFormValues> => {
    return yup.object().shape({
      first_name: getYupStringValidation(),
      last_name: getYupStringValidation(),
      language: getYupStringValidation(),
    }) as yup.ObjectSchema<IAccountFormValues>;
  };

  private onSubmit = (formValues: IAccountFormValues): void => {
    const { editRestResource, myUser } = this.props;

    if (myUser) {
      const requestParams = formValues;
      editRestResource(rrc.user, requestParams, myUser.id);
    }
  };
}
