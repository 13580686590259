import DragIndicator from '@material-ui/icons/DragIndicator';
import { Button, CenterButtons } from 'components/Buttons';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import { $t } from 'utils/functions/translate';

interface IProps {
  onClick: () => void;
}

export class StartReorderingButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { onClick } = this.props;

    return (
      <CenterButtons marginTop={0}>
        <Button
          onClick={onClick}
          color={theme.colors.main}
          bgColor={theme.colors.transparent}
          Icon={<DragIndicator />}
          text={$t('reorder')}
          noShadow
        />
      </CenterButtons>
    );
  }
}
