// tslint:disable:no-any
import { _includes } from 'libs/lodash';
import { _split } from 'libs/lodash';
import { $tErrorsDetailedCode, $tErrorsYup } from 'utils/functions/translate';
import { $tErrorsExtra } from 'utils/functions/translate/translate';

export const displayFormFieldError = (field: string, formikProps: any): string | undefined => {
  const { errors, values, touched } = formikProps;

  const fieldErrorTranslationPath = errors[field];
  const fieldValue = values[field];
  const isFieldTouched = touched[field];

  if (
    !!fieldErrorTranslationPath &&
    /* backend errors always displayed */
    (!_includes(fieldErrorTranslationPath, 'yup') ||
      /* yup errors displayed after user starts typing */
      (fieldErrorTranslationPath !== 'yupRequired' && fieldValue !== '') ||
      /* yup required error displayed after user has touched the field */
      (fieldErrorTranslationPath === 'yupRequired' && fieldValue === '' && isFieldTouched))
  ) {
    return translateError(fieldErrorTranslationPath);
  }
};

const translateError = (translationPath: string): string => {
  if (_includes(translationPath, 'yup')) {
    if (_includes(translationPath, 'Max') || _includes(translationPath, 'Min')) {
      const splitError = _split(translationPath, '=');

      // @ts-ignore
      return $tErrorsYup(splitError[0], { limit: splitError[1] });
    }

    return $tErrorsYup(translationPath);
  }

  if (_includes(translationPath, 'extra')) {
    const splitError = _split(translationPath, '|');

    // @ts-ignore
    return $tErrorsExtra(splitError[0], { minValue: splitError[1], maxValue: splitError[2] });
  }

  return $tErrorsDetailedCode(translationPath);
};
