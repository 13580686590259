import { WithProjectSubMenu } from 'components/Menus/WithProjectSubMenu';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { getObatMenuPageRoute, IObatMenuPage, obatMenuPages, projectMenuPages } from 'routing/routes';
import { $t } from 'utils/functions/translate';

import { IProps } from './WithObatMenu.container';
export class WithObatMenu extends PureComponent<IProps> {
  public render(): ReactNode {
    const { selectedPage, routeObat, children } = this.props;

    const pagesDivision: IObatMenuPage[][] = [
      [
        obatMenuPages.materials,
        obatMenuPages.constructions,
        obatMenuPages.openings,
        obatMenuPages.surfaceGroups,
        obatMenuPages.bridges,
      ],
      [
        obatMenuPages.calendars,
        obatMenuPages.yearProfiles,
        obatMenuPages.schedules,
        obatMenuPages.systems,
        obatMenuPages.activityZoneGroups,
      ],
      [obatMenuPages.outputZoneGroups, obatMenuPages.uses, obatMenuPages.variants],
      [obatMenuPages.efEp, obatMenuPages.ePlusParameters],
    ];

    return (
      <WithProjectSubMenu
        selectedSubMenu={projectMenuPages.obats}
        subMenuTitle={$t('obat')}
        selectedPage={selectedPage}
        subMenuRouteResource={routeObat}
        subMenuPagesDivision={pagesDivision}
        getSubMenuPageRoute={getObatMenuPageRoute}
      >
        {children}
      </WithProjectSubMenu>
    );
  }
}
