import { _find } from 'libs/lodash';
import { _get } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { _isEqual } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { listRestResourcesSaga, retrieveRestResourceSaga } from 'redux/restResources/generic/sagas';
import { selectRouteParams, selectRouteResources, selectRouteResourcesLists } from 'redux/routing/selectors';
import { updateVariantContextIdAction } from 'redux/variantContext/actions';
import { getResourcesToUpdateFromRouteParams } from 'utils/functions/routing/getResourcesToUpdateFromRouteParams';
import { IRestResourceClass, rrc } from 'utils/strings/resourceClasses';

import {
  FETCH_ALL_ROUTE_RESOURCES_SAGA_ACTION,
  IFetchAllRouteResourcesSagaAction,
  toggleIsRouteResourcesLoadingAction,
  toggleIsRouteResourcesUpToDateAction,
  updateAllRouteResourcesAction,
  updateSingleRouteResourceAction,
} from './actions';

export function* fetchSingleRouteResourceSaga(resourceClass: IRestResourceClass): SagaIterator {
  const routeParams = yield select(selectRouteParams);
  const routeResourcesToUpdate = getResourcesToUpdateFromRouteParams(routeParams);
  const routeResourceToUpdate = _find(routeResourcesToUpdate, ['resourceClass', resourceClass]);
  if (routeResourceToUpdate) {
    const resourceId = routeResourceToUpdate.resourceId;
    const resource = yield call(retrieveRestResourceSaga, resourceClass, resourceId);
    yield put(updateSingleRouteResourceAction(resourceClass, resource));
  }
}

export function* fetchAllRouteResourcesSaga(payload: IFetchAllRouteResourcesSagaAction['payload']): SagaIterator {
  const { forceUpdate } = payload;

  yield put(toggleIsRouteResourcesUpToDateAction(false));
  yield put(toggleIsRouteResourcesLoadingAction(true));

  const routeParams = yield select(selectRouteParams);
  const nextRouteParams = payload.nextRouteParams || routeParams;

  /* leave variant mode if we leave obat context or change of obat */
  if (
    !nextRouteParams.obatId ||
    (nextRouteParams.obatId && routeParams.obatId && nextRouteParams.obatId !== routeParams.obatId)
  ) {
    yield put(updateVariantContextIdAction());
  }

  const routeResourcesToUpdate = getResourcesToUpdateFromRouteParams(nextRouteParams);

  const routeProjectToUpdate = _find(routeResourcesToUpdate, ['resourceClass', rrc.project]);
  const routeOrganizationToUpdate = _find(routeResourcesToUpdate, ['resourceClass', rrc.organization]);

  if (routeProjectToUpdate || routeOrganizationToUpdate) {
    const routeProjectId = routeProjectToUpdate && routeProjectToUpdate.resourceId;
    const routeOrganizationId = routeOrganizationToUpdate && routeOrganizationToUpdate.resourceId;

    /* for each url level, retrieve the restResource and the list of restResources of the same resource */
    const previousRouteResources = yield select(selectRouteResources);
    const previousRouteResourcesLists = yield select(selectRouteResourcesLists);

    let nextRouteResources = {};
    let nextRouteResourcesLists = {};

    for (const routeResourceLevel of routeResourcesToUpdate) {
      const { resourceClass, resourceId } = routeResourceLevel;

      /* retrieve restResource if not already in previousRouteResources with the same id */
      if (
        !forceUpdate &&
        _has(previousRouteResources, resourceClass) &&
        _isEqual(_get(previousRouteResources, `${resourceClass}.id`), resourceId)
      ) {
        nextRouteResources = {
          ...nextRouteResources,
          [resourceClass]: _get(previousRouteResources, resourceClass),
        };
      } else {
        // @ts-ignore
        const restResource = yield call(retrieveRestResourceSaga, resourceClass, resourceId);
        nextRouteResources = { ...nextRouteResources, [resourceClass]: restResource };
      }

      /* list restResources if resourceClass was not in previousRouteResources */

      const listResourceClass = _includes([rrc.mono_simulation_group, rrc.multi_simulation_group], resourceClass)
        ? rrc.simulation_group
        : resourceClass;

      if (!forceUpdate && _has(previousRouteResources, listResourceClass)) {
        nextRouteResourcesLists = {
          ...nextRouteResourcesLists,
          [listResourceClass]: _get(previousRouteResourcesLists, listResourceClass),
        };
      } else {
        let queryParams = {};
        if (listResourceClass === rrc.organization) {
          queryParams = { is_member: true };
        } else if (listResourceClass !== rrc.project && routeProjectId) {
          queryParams = { project: routeProjectId };
        } else if (listResourceClass !== rrc.organization && routeOrganizationId) {
          queryParams = { organization: routeOrganizationId };
        }
        const restResourcesList = yield call(listRestResourcesSaga, listResourceClass, undefined, queryParams, false);
        nextRouteResourcesLists = { ...nextRouteResourcesLists, [listResourceClass]: restResourcesList };
      }
    }

    yield put(updateAllRouteResourcesAction(nextRouteParams, nextRouteResources, nextRouteResourcesLists));
  } else {
    yield put(updateAllRouteResourcesAction(nextRouteParams));
  }
}

export function* fetchAllRouteResourcesActionSaga(action: IFetchAllRouteResourcesSagaAction): SagaIterator {
  yield call(fetchAllRouteResourcesSaga, action.payload);
}

export function* routingActionsSagas(): Generator {
  yield takeLatest(FETCH_ALL_ROUTE_RESOURCES_SAGA_ACTION, fetchAllRouteResourcesActionSaga);
}
