import { connect } from 'react-redux';
import { selectFormApiErrorsTranslationPath } from 'redux/apiRequests/selectors';
import { selectIsUploadingToAzure } from 'redux/azure/selectors';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { importWeatherDataSagaAction } from 'redux/restResources/detail/weather/actions';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { WeatherImportForm as Component } from './WeatherImportForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const weatherToEdit = selectFormInitialResourceFromTable(rrc.weather)(rootState);
  const weatherImportApiRequestKey = weatherToEdit
    ? getDetailApiRequestKey(dra.importWeatherData, weatherToEdit.id)
    : '';

  return {
    isUploadingToAzure: selectIsUploadingToAzure(rootState),
    importFormErrors: selectFormApiErrorsTranslationPath(weatherImportApiRequestKey)(rootState),
  };
};

const mapDispatchToProps = {
  importWeatherData: importWeatherDataSagaAction,
};

export const WeatherImportForm = connect(mapStateToProps, mapDispatchToProps)(Component);
