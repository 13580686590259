// tslint:disable:no-any
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateInput, IDateInputProps } from 'components/Forms/Inputs/TextInputs/DateInput/DateInput.component';
import { _includes, _map, _omit } from 'libs/lodash';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { displayFormFieldError } from 'utils/functions/forms/displayFormFieldError/displayFormFieldError';
import { $t } from 'utils/functions/translate';

interface IProps extends Partial<IDateInputProps> {
  formikProps: any;
  field: string;
  errorText?: string;
  customMinDateMessage?: string;
  customMaxDateMessage?: string;
  startMode?: 'day' | 'month' | 'year';
  endMode?: 'day' | 'month' | 'year';
}

export class FormikDateInput extends PureComponent<IProps> {
  public render(): ReactNode {
    const {
      formikProps,
      field,
      customMinDateMessage,
      customMaxDateMessage,
      startMode,
      endMode,
      ...dateInputProps
    } = this.props;

    const datePickerErrors = {
      invalidDateMessage: $t('invalidDate'),
      minDateMessage: customMinDateMessage || $t('beforeMinDate'),
      maxDateMessage: customMaxDateMessage || $t('afterMaxDate'),
    };

    const errorText = displayFormFieldError(field, formikProps);

    return (
      <FormControl>
        {/*
         // @ts-ignore */}
        <DateInput
          {...dateInputProps}
          field={field}
          value={formikProps.values[field]}
          onChange={this.onChange}
          onError={this.onError}
          /* DatePicker errors should be managed via onError to use formik */
          {...datePickerErrors}
        />
        {/* Date picker errors display is already managed by the DatePicker component.
        errorText is for errors coming from backend only */}
        {!!errorText &&
          !_includes(
            _map(datePickerErrors, (msg: string) => msg),
            errorText
          ) && <FormHelperText error>{errorText}</FormHelperText>}
      </FormControl>
    );
  }

  private onError = (error: ReactNode) => {
    const { formikProps, field } = this.props;
    /* date picker error should be added to formikProps to benefit form validation
    but the error display is managed by the DatePicker component itself */
    formikProps.setFieldError(field, !!error ? error : undefined);
  };

  private onChange = (localeMomentDate: MaterialUiPickersDate) => {
    /* When a date get chosen in the picker input, we have access to this date as a local moment date */

    const { formikProps, field, startMode, endMode } = this.props;

    const localeMomentDateString = localeMomentDate && localeMomentDate.format('YYYY-MM-DD');

    if (localeMomentDateString) {
      /* Switch to utc date because all our dates are managed in utc */
      const utcMomentDate = moment.utc(localeMomentDateString, 'YYYY-MM-DD');

      if (startMode) {
        utcMomentDate.startOf(startMode);
      }

      if (endMode) {
        utcMomentDate.endOf(endMode);
      }

      const ISOStringToSet = utcMomentDate.toISOString();

      formikProps.setFieldValue(field, ISOStringToSet);
    }
  };
}
