import Info from '@material-ui/icons/Info';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { IProps } from './ModalInfoTooltip.container';

export class ModalInfoTooltip extends PureComponent<IProps> {
  public render(): ReactNode {
    const { children, placement } = this.props;

    return (
      <SimpleTooltip content={this.renderTooltipContent()} placement={placement} interactive>
        {children}
      </SimpleTooltip>
    );
  }

  private renderTooltipContent = (): ReactNode => {
    const { openModal, message } = this.props;

    const onClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      openModal();
    };

    return (
      <ContentContainer>
        <MessageContainer>{message}</MessageContainer>
        <IconContainer onClick={onClick}>
          <Info />
        </IconContainer>
      </ContentContainer>
    );
  };
}

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MessageContainer = styled.div`
  font-size: 100% !important;
`;

const IconContainer = styled.div`
  cursor: pointer;
  margin-top: 4px;
  margin-left: 4px;
`;
