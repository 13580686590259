import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { MonthlyResultsMulti as Component } from './MonthlyResultsMulti.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => ({
  routeMultiSimulationGroup: selectRouteResource(rrc.multi_simulation_group)(rootState),
});

const mapDispatchToProps = {};

export const MonthlyResultsMulti = connect(mapStateToProps, mapDispatchToProps)(Component);
