import { ObatResourcesRequests } from 'api/requests/ObatResourcesRequests';
import { _includes } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequestCallbackSuccessAction } from 'redux/apiRequests/actions';
import { apiRequestSaga } from 'redux/apiRequests/sagas';
import { selectApiRequestErrorMessage, selectApiRequestErrors } from 'redux/apiRequests/selectors';
import { closeFormDrawerAction, openFormDrawerAction, toggleReorderingAction } from 'redux/form/actions';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { closeDeletionModalAction } from 'redux/modals/deletionModal/actions';
import { selectResourceToDelete } from 'redux/modals/deletionModal/selectors';
import { selectObatContentsId } from 'redux/obatResources/selectors';
import { fetchTableUpdatedDataSaga } from 'redux/table/sagas';
import { IObatResource } from 'types/Obat/ObatResource';
import {
  getObatParentResourceClassOfChildResourceClass,
  getObatResourceLevel,
} from 'utils/configs/resourcesHierarchy/obatResourcesHierarchy';
import { getDetailApiRequestKey, getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { createObatContents } from 'utils/obatContents/createObatContents';
import { enrichObatData } from 'utils/obatContents/enrichObatData';
import { dr } from 'utils/strings/detailRoutes';
import { fk } from 'utils/strings/formKeys';
import { dra, gra } from 'utils/strings/requestActions';
import { IObatResourceClass, orc } from 'utils/strings/resourceClasses';

import { updateVariantContextErrorAction } from 'redux/variantContext/actions';
import {
  clearAllHeatmapsAction,
  clearSingleHeatmapAction,
  COPY_OBAT_RESOURCE_SAGA_ACTION,
  CREATE_OBAT_RESOURCE_SAGA_ACTION,
  DELETE_OBAT_RESOURCE_SAGA_ACTION,
  EDIT_MULTI_OBAT_RESOURCES_SAGA_ACTION,
  EDIT_OBAT_RESOURCE_SAGA_ACTION,
  FETCH_OBAT_CONTENTS_SAGA_ACTION,
  ICopyObatResourceSagaAction,
  ICreateObatResourceSagaAction,
  IDeleteObatResourceSagaAction,
  IEditMultiObatResourcesSagaAction,
  IEditObatResourceSagaAction,
  IFetchObatContentsSagaAction,
  updateObatContentsAction,
  updateRefToScrollToAction,
} from './actions';

/* _____ fetchObatContentsSaga _____ */

export function* fetchObatContentsSaga(obatId: string, withLoading: boolean = true, variantId?: string): SagaIterator {
  const previousObatContentsId = yield select(selectObatContentsId);
  const isStillSameObatId = previousObatContentsId === obatId;
  const apiRequestKey = getDetailApiRequestKey(dra.fetchObatContents, obatId, withLoading);
  const queryParams = variantId ? { variant: variantId } : {};
  const rawObatData = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    ObatResourcesRequests.retrieveContents,
    obatId,
    queryParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  const apiRequestErrorMessage = yield select(selectApiRequestErrorMessage(apiRequestKey));
  if (!apiRequestErrors && !apiRequestErrorMessage) {
    let obatContents = createObatContents(rawObatData, obatId);
    obatContents = { ...obatContents, data: enrichObatData(obatContents) };
    yield put(updateObatContentsAction(obatContents));

    if (!isStillSameObatId) {
      yield put(clearAllHeatmapsAction());
    }

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }

  if (apiRequestErrorMessage) {
    yield put(updateVariantContextErrorAction(apiRequestErrorMessage));
  }
}

export function* fetchObatContentsActionSaga(action: IFetchObatContentsSagaAction): SagaIterator {
  const { obatId, variantId } = action.payload;
  yield call(fetchObatContentsSaga, obatId, true, variantId);
}

/* _____ listObatResourcesSaga _____ */

export function* listObatResourcesSaga(resourceClass: IObatResourceClass, obatId: string): SagaIterator {
  const apiRequestKey = getGenericApiRequestKey(gra.list, resourceClass);
  const apiUrlParams = { obatId, resourceClass };

  const obatResourcesList = yield call(apiRequestSaga, apiRequestKey, false, ObatResourcesRequests.list, apiUrlParams);

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    return obatResourcesList;
  }
}

/* _____ obatChildRequestCallbackSaga _____ */

export function* obatChildRequestCallbackSaga(
  childResourceClass: IObatResourceClass,
  editedCalendarId?: string
): SagaIterator {
  // @ts-ignore
  const parentResourceClass = getObatParentResourceClassOfChildResourceClass(childResourceClass);
  yield call(fetchTableUpdatedDataSaga, { resourceClass: parentResourceClass });
  if (editedCalendarId) {
    yield put(clearSingleHeatmapAction(editedCalendarId));
  }
}

/* _____ obatParentRequestCallbackSaga _____ */

export function* obatParentRequestCallbackSaga(
  resourceClass: IObatResourceClass,
  resource: IObatResource
): SagaIterator {
  yield call(fetchTableUpdatedDataSaga, { resourceClass });
  yield put(openFormDrawerAction(fk.obatResource, { resourceId: resource.id, resourceClass }));
  if (resourceClass === orc.calendar) {
    yield put(clearSingleHeatmapAction(resource.id));
  }
  yield put(updateRefToScrollToAction(resource.id));
}

/* _____ createObatResourcesSaga _____ */

export function* createObatResourceSaga(payload: ICreateObatResourceSagaAction['payload']): SagaIterator {
  const { requestParams, resourceClass, obatId } = payload;
  const apiRequestKey = getGenericApiRequestKey(gra.create, resourceClass);
  const apiUrlParams = { obatId, resourceClass };

  const createdResource = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    ObatResourcesRequests.create,
    apiUrlParams,
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const resourceLevel = getObatResourceLevel(resourceClass);
    if (resourceLevel === 'parent') {
      yield call(obatParentRequestCallbackSaga, resourceClass, createdResource);
    } else {
      const editedCalendarId = _includes([orc.day_group, orc.period], resourceClass)
        ? createdResource.calendar
        : undefined;
      yield call(obatChildRequestCallbackSaga, resourceClass, editedCalendarId);
    }

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }
}

export function* createObatResourceActionSaga(action: ICreateObatResourceSagaAction): SagaIterator {
  yield call(createObatResourceSaga, action.payload);
}

/* _____ copyObatResourcesSaga _____ */

export function* copyObatResourceSaga(payload: ICopyObatResourceSagaAction['payload']): SagaIterator {
  const { resourceId, resourceClass, requestParams, obatId } = payload;
  const apiRequestKey = getGenericApiRequestKey(gra.copy, resourceClass, resourceId);
  const apiUrlParams = { obatId, resourceClass, resourceId, detailRoute: dr.copy };

  const copiedResource = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    ObatResourcesRequests.copy,
    apiUrlParams,
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    /* copy only available for parents */
    yield call(obatParentRequestCallbackSaga, resourceClass, copiedResource);

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }
}

export function* copyObatResourceActionSaga(action: ICopyObatResourceSagaAction): SagaIterator {
  yield call(copyObatResourceSaga, action.payload);
}

/* _____ editObatResourcesSaga _____ */

export function* editObatResourceSaga(payload: IEditObatResourceSagaAction['payload']): SagaIterator {
  const { requestParams, initialResourceId, resourceClass, obatId } = payload;
  const apiRequestKey = getGenericApiRequestKey(gra.edit, resourceClass, initialResourceId);
  const apiUrlParams = {
    obatId,
    resourceClass,
    resourceId: initialResourceId,
    detailRoute: resourceClass === orc.day_profile ? dr.from_text : undefined,
  };
  const editedResource = yield call(
    apiRequestSaga,
    apiRequestKey,
    true,
    ObatResourcesRequests.edit,
    apiUrlParams,
    requestParams
  );

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const resourceLevel = getObatResourceLevel(resourceClass);
    if (resourceLevel === 'parent') {
      yield call(obatParentRequestCallbackSaga, resourceClass, editedResource);
    } else {
      // @ts-ignore
      const parentResourceClass = getObatParentResourceClassOfChildResourceClass(resourceClass);
      const initialParentResource = yield select(selectFormInitialResourceFromTable(parentResourceClass));
      const editedCalendarId = _includes([orc.day_group, orc.period], resourceClass)
        ? initialParentResource.id
        : undefined;
      yield call(obatChildRequestCallbackSaga, resourceClass, editedCalendarId);
    }

    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }
}

export function* editObatResourceActionSaga(action: IEditObatResourceSagaAction): SagaIterator {
  yield call(editObatResourceSaga, action.payload);
}

/* _____ editMultiObatResourcesSaga _____ */

export function* editMultiObatResourcesSaga(payload: IEditMultiObatResourcesSagaAction['payload']): SagaIterator {
  const { requestParamsList, resourceClass, obatId } = payload;
  const apiRequestKey = getGenericApiRequestKey(gra.editMulti, resourceClass);
  const apiUrlParams = { obatId, resourceClass };

  yield call(apiRequestSaga, apiRequestKey, true, ObatResourcesRequests.editMulti, apiUrlParams, requestParamsList);

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    /* multi edit only available for reordering children for the moment */
    // @ts-ignore
    const parentResourceClass = getObatParentResourceClassOfChildResourceClass(resourceClass);
    const initialParentResource = yield select(selectFormInitialResourceFromTable(parentResourceClass));
    const editedCalendarId = _includes([orc.day_group, orc.period], resourceClass)
      ? initialParentResource.id
      : undefined;
    yield call(obatChildRequestCallbackSaga, resourceClass, editedCalendarId);

    yield put(toggleReorderingAction(false));
    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }
}

export function* editMultiObatResourcesActionSaga(action: IEditMultiObatResourcesSagaAction): SagaIterator {
  yield call(editMultiObatResourcesSaga, action.payload);
}

/* _____ deleteObatResourcesSaga _____ */

export function* deleteObatResourceSaga(payload: IDeleteObatResourceSagaAction['payload']): SagaIterator {
  const { resourceClass, resourceId, obatId } = payload;

  /* we select it now while it's still possible, to close form drawer */
  const resourceToDelete = yield select(selectFormInitialResourceFromTable(resourceClass));
  if (resourceToDelete && resourceToDelete.id === resourceId) {
    yield put(closeFormDrawerAction());
  }

  const apiRequestKey = getGenericApiRequestKey(gra.delete, resourceClass, resourceId);
  const apiUrlParams = { obatId, resourceClass, resourceId };
  yield call(apiRequestSaga, apiRequestKey, true, ObatResourcesRequests.delete, apiUrlParams, resourceId);

  const apiRequestErrors = yield select(selectApiRequestErrors(apiRequestKey));
  if (!apiRequestErrors) {
    const resourceLevel = getObatResourceLevel(resourceClass);
    if (resourceLevel === 'parent') {
      yield call(fetchTableUpdatedDataSaga, { resourceClass });
    } else {
      const editedCalendarId = _includes([orc.day_group, orc.period], resourceClass)
        ? (yield select(selectResourceToDelete)).calendar
        : undefined;
      yield call(obatChildRequestCallbackSaga, resourceClass, editedCalendarId);
    }

    yield put(closeDeletionModalAction());
    yield put(apiRequestCallbackSuccessAction(apiRequestKey));
  }
}

export function* deleteObatResourceActionSaga(action: IDeleteObatResourceSagaAction): SagaIterator {
  yield call(deleteObatResourceSaga, action.payload);
}

export function* obatResourcesActionsSagas(): Generator {
  yield takeLatest(FETCH_OBAT_CONTENTS_SAGA_ACTION, fetchObatContentsActionSaga);
  yield takeLatest(CREATE_OBAT_RESOURCE_SAGA_ACTION, createObatResourceActionSaga);
  yield takeLatest(COPY_OBAT_RESOURCE_SAGA_ACTION, copyObatResourceActionSaga);
  yield takeLatest(EDIT_OBAT_RESOURCE_SAGA_ACTION, editObatResourceActionSaga);
  yield takeLatest(EDIT_MULTI_OBAT_RESOURCES_SAGA_ACTION, editMultiObatResourcesActionSaga);
  yield takeLatest(DELETE_OBAT_RESOURCE_SAGA_ACTION, deleteObatResourceActionSaga);
}
