import { _find, _get, _isString, _lowerCase, _map, _replace, _sortBy, _toString } from 'libs/lodash';
import { IRootState } from 'redux/reducer';
import {
  IDegreeDaysData,
  IDegreeDaysParams,
  IOpenergyHistoricalWeatherConfigOption,
  IWeatherMeasuresMetadata,
  IWeatherMeasuresPlotted,
  IWeatherMeasuresYearsData,
} from 'redux/restResources/detail/weather/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { IPlotTimeSeries } from 'types/Series';
import { ISelectOption } from 'utils/functions/forms/getSelectOptions/getSelectOptions';
import { $tWeatherSeries } from 'utils/functions/translate/translate';
import { formatDegreeDaysTableData } from 'utils/functions/weathers/formatDegreeDaysTableData';
import { getHistoricalYearRange } from 'utils/functions/weathers/getHistoricalYearRange';
import { IProgressStatus } from 'utils/strings/progressStatus';
import { rrc } from 'utils/strings/resourceClasses';
import { _toLower } from './../../../../libs/lodash/_toLower';

export interface IDegreeDaysTableRowData {
  cdd: number;
  hdd: number;
  month: string;
}

export type IDegreeDaysTableData = IDegreeDaysTableRowData[];

export const selectOpenergyHistoricalWeatherStationOptions = (rootState: IRootState): ISelectOption[] => {
  const stationOptions = _map(
    rootState.weather.openergyHistoricalWeatherConfigOptions,
    (series: IOpenergyHistoricalWeatherConfigOption): ISelectOption => {
      return { value: series.id, label: _replace(series.name, '_', '/') };
    }
  );

  return _sortBy(stationOptions, (option: ISelectOption) => _lowerCase(option.label));
};

export const selectOpenergyHistoricalWeatherStationName = (rootState: IRootState): string | undefined => {
  const routeWeather = selectRouteResource(rrc.weather)(rootState);

  if (routeWeather.openergy_historical_weather_series) {
    const stationName = _get(
      _find(rootState.weather.openergyHistoricalWeatherConfigOptions, [
        'id',
        routeWeather.openergy_historical_weather_series.historical_weather_series,
      ]),
      'name'
    );
    if (_isString(stationName)) {
      return _replace(stationName, '_', '/');
    }
  }
};

export const selectIsWeatherDataDownloadError = (rootState: IRootState): boolean => {
  return rootState.weather.isDataDownloadError;
};

export const selectWeatherImportProgress = (rootState: IRootState): IProgressStatus => {
  return rootState.weather.importProgress;
};

export const selectWeatherMeasuresMetadata = (rootState: IRootState): IWeatherMeasuresMetadata => {
  return rootState.weather.measures.metadata;
};

export const selectWeatherMeasuresYearsData = (rootState: IRootState): IWeatherMeasuresYearsData => {
  return rootState.weather.measures.yearsData;
};

export const selectWeatherPlottedMeasure = (rootState: IRootState): IWeatherMeasuresPlotted | undefined => {
  return rootState.weather.measures.plotted;
};

export const selectWeatherMeasuresPlottedSeries = (rootState: IRootState): IPlotTimeSeries | undefined => {
  const { plotted, yearsData } = rootState.weather.measures;

  if (plotted) {
    return {
      datetimeIndex: yearsData[plotted.year].index,
      values: yearsData[plotted.year][plotted.seriesId],
      name: $tWeatherSeries(plotted.seriesId),
    };
  }
};

export const selectWeatherMeasuresSeriesIdOptions = (rootState: IRootState): string[] => {
  return rootState.weather.measures.metadata.series;
};

export const selectWeatherMeasuresYearOptions = (rootState: IRootState): string[] => {
  const { metadata } = rootState.weather.measures;

  return _map(metadata.years, _toString);
};

export const selectDegreeDaysData = (rootState: IRootState): IDegreeDaysData | undefined => {
  return rootState.weather.degreeDays.data;
};

export const selectDegreeDaysParams = (rootState: IRootState): IDegreeDaysParams => {
  return rootState.weather.degreeDays.params;
};

export const selectDegreeDaysTableData = (rootState: IRootState): IDegreeDaysTableData | undefined => {
  const degreeDaysData = selectDegreeDaysData(rootState);

  return degreeDaysData && formatDegreeDaysTableData(degreeDaysData);
};

export const selectRouteWeatherHistoricalYearRange = (rootState: IRootState): string[] | undefined => {
  const routeWeather = selectRouteResource(rrc.weather)(rootState);

  if (!routeWeather || routeWeather.generic_weather_series) {
    return undefined;
  }

  if (routeWeather.openergy_historical_weather_series) {
    return getHistoricalYearRange(
      routeWeather.openergy_historical_weather_series.start,
      routeWeather.openergy_historical_weather_series.end
    );
  }

  if (routeWeather.historical_weather_series) {
    return getHistoricalYearRange(
      routeWeather.historical_weather_series.start,
      routeWeather.historical_weather_series.end
    );
  }
};

export const selectWeathersTemplateOptionsFunction = (rootState: IRootState) => (templateCategory: string) => {
  const { templates } = rootState.weather;

  if (!templates) {
    return [];
  } else {
    const templateOptions = _map(
      _get(templates, templateCategory),
      (template: string): ISelectOption => {
        return { value: template, label: _replace(template, '_', '/') };
      }
    );

    return _sortBy(templateOptions, (option: ISelectOption) => _lowerCase(option.label));
  }
};
