import pluralize from 'pluralize';

export const toPlural = (singularWord: string): string => {
  return pluralize.plural(singularWord);
};

export const toSingular = (pluralWord: string): string => {
  pluralize.addSingularRule(/series$/i, 'series');

  return pluralize.singular(pluralWord);
};
