import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectAllFilteredRowsAction, unselectAllFilteredRowsAction } from 'redux/table/actions';
import { selectIsSelectionDisplay } from 'redux/table/selectors';
import { IDatatableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';

import { DatatableHeader as Component } from './DatatableHeader.component';

export interface IOwnProps {
  onSort: (columnKey: string) => (event: React.MouseEvent<unknown>) => void;
  rowCount: number;
  isSelectable?: boolean;
  selectedCount: number;
  sortColumnId: string;
  sortOrder: 'asc' | 'desc';
  tableColumnsParams: IDatatableColumnParams[];
  noSort?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => ({
  isSelectionDisplay: selectIsSelectionDisplay(rootState),
});

const mapDispatchToProps = {
  selectAllFilteredRows: selectAllFilteredRowsAction,
  unselectAllFilteredRows: unselectAllFilteredRowsAction,
};

export const DatatableHeader = connect(mapStateToProps, mapDispatchToProps)(Component);
