import { connect } from 'react-redux';
import { selectIsApiRequestPending } from 'redux/apiRequests/selectors';
import { IRootState } from 'redux/reducer';
import { fetchDegreeDaysYearDataSagaAction } from 'redux/restResources/detail/weather/actions';
import {
  selectDegreeDaysData,
  selectDegreeDaysParams,
  selectDegreeDaysTableData,
  selectRouteWeatherHistoricalYearRange,
} from 'redux/restResources/detail/weather/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { getDetailApiRequestKey } from 'utils/functions/getApiRequestKey';
import { dra } from 'utils/strings/requestActions';
import { rrc } from 'utils/strings/resourceClasses';

import { DegreeDays as DegreeDaysComponent } from './DegreeDays.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeWeather = selectRouteResource(rrc.weather)(rootState);
  const fetchDegreeDaysApiRequestKey = getDetailApiRequestKey(dra.fetchDegreeDaysData, routeWeather.id);

  return {
    routeWeather,
    yearOptions: selectRouteWeatherHistoricalYearRange(rootState),
    params: selectDegreeDaysParams(rootState),
    degreeDaysData: selectDegreeDaysData(rootState),
    degreeDaysTableData: selectDegreeDaysTableData(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
    isFetchingDegreeDaysData: selectIsApiRequestPending(fetchDegreeDaysApiRequestKey)(rootState),
  };
};

const mapDispatchToProps = {
  fetchDegreeDaysYearData: fetchDegreeDaysYearDataSagaAction,
};

export const DegreeDays = connect(mapStateToProps, mapDispatchToProps)(DegreeDaysComponent);
