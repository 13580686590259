import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { inviteGuestSagaAction } from 'redux/restResources/detail/organization/actions';

import { InviteForm as Component } from './InviteForm.component';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (_rootState: IRootState) => ({});

const mapDispatchToProps = {
  inviteGuest: inviteGuestSagaAction,
};

export const InviteForm = connect(mapStateToProps, mapDispatchToProps)(Component);
