import { Action } from 'redux';
import { IFormInitialResource } from 'redux/form/reducer';
import { IFormKey } from 'utils/strings/formKeys';

export const OPEN_FORM_DRAWER_ACTION = 'OPEN_FORM_DRAWER_ACTION';
export interface IOpenFormDrawerAction extends Action<'OPEN_FORM_DRAWER_ACTION'> {
  payload: {
    formKey: IFormKey;
    initialResource?: IFormInitialResource;
  };
}
export const openFormDrawerAction = (
  formKey: IOpenFormDrawerAction['payload']['formKey'],
  initialResource?: IOpenFormDrawerAction['payload']['initialResource']
): IOpenFormDrawerAction => ({
  type: OPEN_FORM_DRAWER_ACTION,
  payload: {
    formKey,
    initialResource,
  },
});

export const OPEN_FORM_DRAWER_SAGA_ACTION = 'OPEN_FORM_DRAWER_SAGA_ACTION';
export interface IOpenFormDrawerSagaAction extends Action<'OPEN_FORM_DRAWER_SAGA_ACTION'> {
  payload: IOpenFormDrawerAction['payload'];
}
export const openFormDrawerSagaAction = (
  formKey: IOpenFormDrawerSagaAction['payload']['formKey'],
  initialResource?: IOpenFormDrawerSagaAction['payload']['initialResource']
): IOpenFormDrawerSagaAction => ({
  type: OPEN_FORM_DRAWER_SAGA_ACTION,
  payload: {
    formKey,
    initialResource,
  },
});

export const CLOSE_FORM_DRAWER_ACTION = 'CLOSE_FORM_DRAWER_ACTION';
export interface ICloseFormDrawerAction extends Action<'CLOSE_FORM_DRAWER_ACTION'> {}
export const closeFormDrawerAction = (): ICloseFormDrawerAction => ({
  type: CLOSE_FORM_DRAWER_ACTION,
});

export const COLLAPSE_FORM_DRAWER_ACTION = 'COLLAPSE_FORM_DRAWER_ACTION';
export interface ICollapseFormDrawerAction extends Action<'COLLAPSE_FORM_DRAWER_ACTION'> {}
export const collapseFormDrawerAction = (): ICollapseFormDrawerAction => ({
  type: COLLAPSE_FORM_DRAWER_ACTION,
});

export const EXPAND_FORM_DRAWER_ACTION = 'EXPAND_FORM_DRAWER_ACTION';
export interface IExpandFormDrawerAction extends Action<'EXPAND_FORM_DRAWER_ACTION'> {}
export const expandFormDrawerAction = (): IExpandFormDrawerAction => ({
  type: EXPAND_FORM_DRAWER_ACTION,
});

export const TOGGLE_REORDERING_ACTION = 'TOGGLE_REORDERING_ACTION';
export interface IToggleReorderingAction extends Action<'TOGGLE_REORDERING_ACTION'> {
  payload: {
    isReordering: boolean;
  };
}
export const toggleReorderingAction = (isReordering: boolean): IToggleReorderingAction => ({
  type: TOGGLE_REORDERING_ACTION,
  payload: {
    isReordering,
  },
});

export const TOGGLE_FORM_RESET_ACTION = 'TOGGLE_FORM_RESET_ACTION';
export interface IToggleFormResetAction extends Action<'TOGGLE_FORM_RESET_ACTION'> {
  payload: {
    hasToReset: boolean;
  };
}
export const toggleFormResetAction = (hasToReset: boolean): IToggleFormResetAction => ({
  type: TOGGLE_FORM_RESET_ACTION,
  payload: {
    hasToReset,
  },
});

export const UPDATE_DRAWER_WIDTH_ACTION = 'UPDATE_DRAWER_WIDTH_ACTION';
export interface IUpdateDrawerWidthAction extends Action<'UPDATE_DRAWER_WIDTH_ACTION'> {
  payload: {
    width: number;
  };
}
export const updateDrawerWidthAction = (width: number): IUpdateDrawerWidthAction => ({
  type: UPDATE_DRAWER_WIDTH_ACTION,
  payload: {
    width,
  },
});

export type IFormActions =
  | IOpenFormDrawerSagaAction
  | IOpenFormDrawerAction
  | ICloseFormDrawerAction
  | ICollapseFormDrawerAction
  | IExpandFormDrawerAction
  | IToggleReorderingAction
  | IToggleFormResetAction
  | IUpdateDrawerWidthAction;
