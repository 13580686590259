import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { updateSimulationAction } from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectSimulation,
  selectSimulationsList,
  selectSimulationsListByStatus,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { selectRoutePage, selectRouteResource, selectRouteSimulationGroupTag } from 'redux/routing/selectors';
import { selectTableResources } from 'redux/table/selectors';
import { ISimulationListed } from 'types/SimulationGroup/Simulation';
import { rrc } from 'utils/strings/resourceClasses';
import { IResultsView } from 'utils/strings/results';

import { ResultsModeForm as Component } from './ResultsModeForm.component';

interface IOwnProps {
  resultsMode: IResultsView;
  resultsModeLabels: {
    bySimulation: string;
    aggregate: string;
  };
  onSimulationSelect: (simulation: ISimulationListed) => void;
  onResultsModeToggle: (value: IResultsView) => void;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = IStateProps & IDispatchProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => {
  const routeSimulationGroupTag = selectRouteSimulationGroupTag(rootState);
  const isMulti = !!routeSimulationGroupTag && routeSimulationGroupTag.resourceClass === rrc.multi_simulation_group;
  const tableResources =
    routeSimulationGroupTag && selectTableResources(rootState)(rrc.series, routeSimulationGroupTag.resourceId);

  return {
    urlPage: selectRoutePage(rootState),
    routeMultiSimulationGroup: selectRouteResource(rrc.multi_simulation_group)(rootState),
    isMulti,
    tableResources,
    simulation: selectSimulation(rootState),
    simulationsListByStatus: selectSimulationsListByStatus(rootState),
    simulationsList: selectSimulationsList(rootState),
  };
};

const mapDispatchToProps = {
  updateSimulation: updateSimulationAction,
};

export const ResultsModeForm = connect(mapStateToProps, mapDispatchToProps)(Component);
