import Grid from '@material-ui/core/Grid';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { _has } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { renderValueElseEmpty } from 'utils/functions/renderValue';
import { $tcc } from 'utils/functions/translate';

import { IProps } from './ObatResourceFormSpecificFields.container';

export class ObatResourceFormSpecificFields extends PureComponent<IProps> {
  public render = (): ReactNode => {
    const { formikProps, specificDefaultValues } = this.props;

    return _map(
      specificDefaultValues,
      (_defaultValue: number, specificField: string): ReactNode => {
        if (!_has(formikProps.initialValues, specificField)) {
          /* Don't display the field not connected yet (frontend delay compare to backend)  */
          return null;
        }

        return (
          <Grid item xs={12} key={specificField}>
            <FormikWriteInput
              field={specificField}
              formikProps={formikProps}
              value={renderValueElseEmpty(formikProps.values[specificField])}
              label={$tcc(specificField)}
              required={this.isRequired(specificField)}
            />
          </Grid>
        );
      }
    );
  };

  private isRequired = (field: string): boolean => {
    const { requiredFields } = this.props;

    return _includes(requiredFields, field);
  };
}
