import Grid from '@material-ui/core/Grid';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { FormikProps } from 'formik';
import { getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IVariantRequestParams } from 'types/Obat/Variant';
import { fdw } from 'utils/configs/drawerWidths';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { ModificationsFormSection } from '../ModificationsFormSection';

import { IProps } from './VariantForm.container';

export interface IVariantFormValues {
  ref: string;
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<IVariantFormValues, IVariantRequestParams>();

export class VariantForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        renderSubForm={this.renderSubForm}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.variant}
        subFormResources={[orc.modification]}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<IVariantFormValues>): ReactNode => {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
          </Grid>
        </>
      );
    };
  };

  private getInitialFormValues = (): IVariantFormValues => {
    const { variantToEdit } = this.props;

    return {
      ref: setFormInitialValue(variantToEdit, 'ref'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IVariantFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
    }) as yup.ObjectSchema<IVariantFormValues>;
  };

  private renderSubForm = (): ReactNode => {
    return <ModificationsFormSection />;
  };
}
