// tslint:disable:no-any
import Grid from '@material-ui/core/Grid';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import { TopSnackbarAlert } from 'components/Alerts';
import { BREADCRUMB_BAR_HEIGHT } from 'components/BreadcrumbTopBar';
import { Button } from 'components/Buttons/Button.component';
import { BottomRightButtons } from 'components/Buttons/ButtonsContainers';
import { OplusLoader } from 'components/Loader';
import { NavigationModal as LeaveFloorspaceModal } from 'components/Modals/LeaveFloorspaceModal';
import { Page } from 'components/Page';
import React, { PureComponent, ReactNode } from 'react';
import { Save } from 'react-feather';
import { geometryMenuPages } from 'routing/routes';
import styled from 'styled-components';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './Drawing2D.container';

interface IState {
  isFloorplanLoading: boolean;
  isIFrameLoading: boolean;
  isSavedAlertOpen: boolean;
  willRefresh: boolean;
}

export class Drawing2D extends PureComponent<IProps, IState> {
  public state = {
    isIFrameLoading: true,
    isFloorplanLoading: true,
    isSavedAlertOpen: false,
    willRefresh: false,
  };
  // tslint:disable-next-line:no-any
  private frameOpening: any = null;

  public componentDidMount(): void {
    const { downloadFloorplan, floorspace } = this.props;
    downloadFloorplan(floorspace.id);

    window.addEventListener('beforeunload', this.leaveHandler);
    window.addEventListener('keydown', this.handleKeyDown);
  }

  public componentDidUpdate(): void {
    const { floorplan } = this.props;
    const { isIFrameLoading, isFloorplanLoading } = this.state;

    if (floorplan && !isIFrameLoading && isFloorplanLoading) {
      this.frameOpening.api.openFloorplan(JSON.stringify(floorplan));
      this.setState({ isFloorplanLoading: false });

      const iframe = document.querySelector('iframe');

      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.addEventListener('keydown', this.handleKeyDown);
      }
    }
  }

  public componentWillUnmount(): void {
    this.props.clearFloorplan();
    window.removeEventListener('beforeunload', this.leaveHandler);
    window.removeEventListener('keydown', this.handleKeyDown);

    const iframe = document.querySelector('iframe');

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  public render(): ReactNode {
    const { isFloorplanLoading } = this.state;
    const { floorplanIsJustSaved, isLoadingUpload, isWritePermissionMissing, isSeatMissing } = this.props;

    const frameStyle = {
      height: `calc(100vh - ${BREADCRUMB_BAR_HEIGHT}px)`,
      width: `100%`,
      border: 'none',
    };
    const floorspaceFileUrl = `${window.location.origin}/frames/floorspace_1_11_2.html`;

    return (
      <>
        <Page
          pageTitle={$t('drawing2D')}
          customPageRefresh={this.customPageRefresh}
          routingMenu={rm.geometry}
          selectedPage={geometryMenuPages.drawing2D}
          isMenuDrawerCollapsed={true}
          noPadding
        >
          <FrameContainer>
            {isFloorplanLoading && (
              <div style={frameStyle}>
                <OplusLoader />
              </div>
            )}
            <iframe src={floorspaceFileUrl} title="floorspace" style={frameStyle} onLoad={this.displayFloorspace} />
          </FrameContainer>
          {!isFloorplanLoading && (
            <BottomRightButtons>
              <Grid container spacing={1} direction={'column'}>
                <Grid item>
                  <Button
                    tooltipProps={{ content: $t('save'), placement: 'left' }}
                    Icon={<Save />}
                    onClick={this.saveFloorplan(false)}
                    isLoading={this.props.isLoadingUpload}
                    isWritePermissionMissing={isWritePermissionMissing}
                    isSeatMissing={isSeatMissing}
                  />
                </Grid>
                <Grid item>
                  <Button
                    tooltipProps={{ content: $t('saveAndConvertTo3D'), placement: 'left' }}
                    Icon={<ThreeDRotationIcon />}
                    onClick={this.saveFloorplan(true)}
                    isWritePermissionMissing={isWritePermissionMissing}
                    isSeatMissing={isSeatMissing}
                  />
                </Grid>
              </Grid>
            </BottomRightButtons>
          )}
          <TopSnackbarAlert isOpen={floorplanIsJustSaved} status={as.success}>
            {$t('saved')}
          </TopSnackbarAlert>
        </Page>
        {/* When leaving page */}
        {!isWritePermissionMissing && (
          <LeaveFloorspaceModal
            when={this.LeaveModalModalWhen}
            save={this.saveFloorplan(false)}
            isLoading={isLoadingUpload}
          />
        )}
      </>
    );
  }

  private leaveHandler = (event: any) => {
    event.preventDefault();
    const confirmationMessage = $t('leavePageAlert');
    event.returnValue = confirmationMessage;

    return confirmationMessage;
  };

  private handleKeyDown = (event: any) => {
    if (event.ctrlKey && event.keyCode === 83) {
      event.preventDefault();
      this.saveFloorplan(false)();
    }
  };

  private LeaveModalModalWhen = () => {
    /* some keys of the floorplan evolves as soon as loading as done :
    - floorplan.application.currentSelections
    - floorplan.application.scale
    - floorplan.project.view
    Then it's not a good strategy to compare floorplan
    */
    if (!this.props.floorplan || !this.frameOpening) {
      return false;
    }

    return !this.props.floorplanIsJustSaved;
  };

  private customPageRefresh = () => {
    this.setState({ willRefresh: true });
  };

  private displayFloorspace = (): void => {
    const iframe = document.querySelector('iframe');

    if (iframe && iframe.contentWindow) {
      this.frameOpening = iframe.contentWindow;

      this.frameOpening.api.setConfig({
        language: this.props.userMeLanguage,
        units: 'si',
        showMapDialogOnStart: true,
        initialGridSize: 1,
        defaultLocation: {
          latitude: 48.8345028,
          longitude: 2.3687038,
        },
        snapMode: 'grid-strict',
        onChange: (): void => {
          // if change occurred in embedded app
        },
      });
      this.frameOpening.api.init();

      this.setState({ isIFrameLoading: false });
    }
  };

  private saveFloorplan = (convert3DNext: boolean): (() => void) => {
    const { uploadFloorplan, floorspace } = this.props;

    return (): void => {
      const floorplan = this.frameOpening.api.exportFloorplan();
      uploadFloorplan(floorspace.id, floorplan, convert3DNext);
    };
  };
}

const FrameContainer = styled.div`
  height: calc(100vh - ${BREADCRUMB_BAR_HEIGHT}px);
`;
