import { connect } from 'react-redux';
import { selectLogoutModalCause, selectLogoutModalTimer } from 'redux/modals/logoutModal/selectors';
import { IRootState } from 'redux/reducer';

import { LogoutModal as Component } from './LogoutModal.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  return {
    logoutTimer: selectLogoutModalTimer(rootState),
    logoutCause: selectLogoutModalCause(rootState),
  };
};

const mapDispatchToProps = {};

export const LogoutModal = connect(mapStateToProps, mapDispatchToProps)(Component);
