import { connect } from 'react-redux';
import { IDayGroup } from 'types/Obat/DayGroup';

import { PeriodsFormSection as PeriodsFormSectionComponent } from './PeriodsFormSection.component';

interface IOwnProps {
  dayGroup: IDayGroup;
}

export type IProps = IOwnProps;

export const PeriodsFormSection = connect(null, null)(PeriodsFormSectionComponent);
