import Grid from '@material-ui/core/Grid';
import { FormDeleteButton } from 'components/Forms/Buttons/FormDeleteButton';
import { FormSubmitButton } from 'components/Forms/Buttons/FormSubmitButton';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { IProps } from './FormButtons.container';

export class FormButtons extends PureComponent<IProps> {
  public render(): ReactNode {
    const {
      isSubmitting,
      formApiErrors,
      obatResourceDeleteProps,
      formikProps,
      forceSubmitDisabled,
      forceSubmitEnabled,
    } = this.props;

    return (
      <ButtonsContainer>
        <Grid container justify="space-between">
          <Grid item>
            {obatResourceDeleteProps && <FormDeleteButton obatResourceDeleteProps={obatResourceDeleteProps} />}
          </Grid>
          <Grid item>
            <FormSubmitButton
              isSubmitting={isSubmitting}
              formApiErrors={formApiErrors}
              formikProps={formikProps}
              forceSubmitDisabled={forceSubmitDisabled}
              forceSubmitEnabled={forceSubmitEnabled}
            />
          </Grid>
        </Grid>
      </ButtonsContainer>
    );
  }
}

const ButtonsContainer = styled.div`
  margin-top: 16px;
`;
