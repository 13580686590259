import { _filter, _get, _includes, _size } from 'libs/lodash';
import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { openDeletionModalAction } from 'redux/modals/deletionModal/actions';
import { openRestResourceCommitmentsModalAction } from 'redux/modals/restResourceCommitmentsModal/actions';
import { IRootState } from 'redux/reducer';
import { copyRestResourceSagaAction } from 'redux/restResources/generic/actions';
import {
  selectIsAdminPermissionMissing,
  selectIsSeatMissing,
  selectIsWritePermissionMissing,
  selectRouteResource,
} from 'redux/routing/selectors';
import { selectTableResources } from 'redux/table/selectors';
import { IRestResource } from 'types/RestResource';
import { getProjectOrganizationPermission } from 'utils/functions/permissions/getProjectOrganizationPermission';
import { as } from 'utils/strings/alertStatus';
import { fk } from 'utils/strings/formKeys';
import { IRestResourceClass, rrc } from 'utils/strings/resourceClasses';

import { DatatableActions as Component } from './DatatableActions.component';

export type IAvailableActions = Array<'edit' | 'copy' | 'delete'>;

interface IOwnProps {
  availableActions?: IAvailableActions;
  restResource: IRestResource;
  resourceClass: IRestResourceClass;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  const routeProject = selectRouteResource(rrc.project)(rootState);
  const routeOrganization = selectRouteResource(rrc.organization)(rootState);

  const organizationPermission =
    ownProps.resourceClass === rrc.user_project_permission &&
    getProjectOrganizationPermission(
      ownProps.restResource.bits_can_admin,
      ownProps.restResource.bits_can_write,
      ownProps.restResource.bits_can_read
    );

  const hasInheritedPermission =
    organizationPermission &&
    (organizationPermission.can_admin || organizationPermission.can_write || organizationPermission.can_read);

  const organizationPermissions =
    ownProps.resourceClass === rrc.user_organization_permission &&
    _get(selectTableResources(rootState)(rrc.user_organization_permission, routeOrganization.id), 'full');
  const orgnizationAdministrators = organizationPermissions && _filter(organizationPermissions, 'can_admin');
  const isLastOrganizationAdministrator =
    orgnizationAdministrators &&
    _size(orgnizationAdministrators) === 1 &&
    ownProps.restResource.id === orgnizationAdministrators[0].id;

  const isRestResourceCommitted =
    ownProps.restResource.mono_simulation_group_set && ownProps.restResource.mono_simulation_group_set.length > 0;

  const routeMultiSimulationGroup = selectRouteResource(rrc.multi_simulation_group)(rootState);
  const isSimulationRunning = !!routeMultiSimulationGroup && routeMultiSimulationGroup.status === as.running;
  const maxSimusNb = !!routeMultiSimulationGroup && routeMultiSimulationGroup.project.max_parallel_simulations;
  const isMaxSimusNbReached = !!routeMultiSimulationGroup && routeMultiSimulationGroup.simus_nb >= maxSimusNb;

  const isWritePermissionMissing = selectIsWritePermissionMissing(rootState);

  const isSeatMissing = selectIsSeatMissing(rootState);

  let isAdminPermissionMissing = selectIsAdminPermissionMissing(rootState);
  isAdminPermissionMissing = _includes([rrc.project, rrc.organization], ownProps.resourceClass)
    ? ownProps.restResource.rights.can_admin === 0
    : isAdminPermissionMissing;

  const isMultiSimulationGroup = ownProps.restResource.model === 'multisimulationgroup';

  return {
    routeProject,
    isMultiSimulationGroup,
    hasInheritedPermission,
    isLastOrganizationAdministrator,
    isRestResourceCommitted,
    isSimulationRunning,
    maxSimusNb,
    isMaxSimusNbReached,
    isAdminPermissionMissing,
    isSeatMissing,
    isWritePermissionMissing,
  };
};

const mapDispatchToProps = {
  copyRestResource: copyRestResourceSagaAction,
  openFormDrawer: openFormDrawerSagaAction,
  openDeletionModal: openDeletionModalAction,
  openCommitmentsModal: openRestResourceCommitmentsModalAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IOwnProps) => {
  const { restResource, resourceClass } = ownProps;
  const { openFormDrawer, openDeletionModal, openCommitmentsModal } = dispatchProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    openEditFormDrawer: () =>
      openFormDrawer(fk.restResource, {
        resourceId: restResource.id,
        resourceClass,
      }),
    openCopyFormDrawer: () =>
      openFormDrawer(fk.copy, {
        resourceId: restResource.id,
        resourceClass,
      }),
    openDeletionModal: () => openDeletionModal(restResource.id, resourceClass),
    openCommitmentsModal: () => openCommitmentsModal(restResource),
  };
};

export const DatatableActions = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
