import { Action, combineReducers } from 'redux';
import { azureReducer, IAzureState } from 'redux/azure/reducer';
import { enumsReducer, IEnumsState } from 'redux/enums/reducer';
import { alertModalReducer, IAlertModalState } from 'redux/modals/alertModal/reducer';
import { dailySeatModalReducer, IDailySeatModalState } from 'redux/modals/dailySeatModal/reducer';
import { INoFreeSeatsModalState, noFreeSeatsModalReducer } from 'redux/modals/noFreeSeatsModal/reducer';
import {
  IObatResourceCommitmentsModalState,
  obatResourceCommitmentsModalReducer,
} from 'redux/modals/obatResourceCommitmentsModal/reducer';
import {
  IRestResourceCommitmentsModalState,
  restResourceCommitmentsModalReducer,
} from 'redux/modals/restResourceCommitmentsModal/reducer';
import { IMeState, meReducer } from 'redux/restResources/detail/me/reducer';
import { IObatState, obatReducer } from 'redux/restResources/detail/obat/reducer';
import { IOrganizationState, organizationReducer } from 'redux/restResources/detail/organization/reducer';

import { apiRequestsReducer, IApiRequestsState } from './apiRequests/reducer';
import { formReducer, IFormState } from './form/reducer';
import { deletionModalReducer, IDeletionModalState } from './modals/deletionModal/reducer';
import { ILogoutModalState, logoutModalReducer } from './modals/logoutModal/reducer';
import { ILogsModalState, logsModalReducer } from './modals/logsModal/reducer';
import { INavigationModalState, navigationModalReducer } from './modals/navigationModal/reducer';
import { IPlotsModalState, plotsModalReducer } from './modals/plotsModal/reducer';
import { IUserCreationModalState, userCreationModalReducer } from './modals/userCreationModal/reducer';
import { IObatResourcesState, obatResourcesReducer } from './obatResources/reducer';
import { floorspaceReducer, IFloorspaceState } from './restResources/detail/floorspace/reducer';
import { geometryReducer, IGeometryState } from './restResources/detail/geometry/reducer';
import { ISimulationGroupState, simulationGroupReducer } from './restResources/detail/simulationGroup/reducer';
import { IWeatherState, weatherReducer } from './restResources/detail/weather/reducer';
import { IRoutingState, routingReducer } from './routing/reducer';
import { ITableState, tableReducer } from './table/reducer';
import { ITasksState, tasksReducer } from './tasks/reducer';
import { ITokensState, tokensReducer } from './tokens/reducer';
import { IVariantContextState, variantContextReducer } from './variantContext/reducer';

export interface IRootState {
  apiRequests: IApiRequestsState;
  azure: IAzureState;
  table: ITableState;
  floorspace: IFloorspaceState;
  form: IFormState;
  variantContext: IVariantContextState;
  geometry: IGeometryState;
  routing: IRoutingState;
  obatResources: IObatResourcesState;
  simulationGroup: ISimulationGroupState;
  tasks: ITasksState;
  tokens: ITokensState;
  weather: IWeatherState;
  me: IMeState;
  organization: IOrganizationState;
  obat: IObatState;
  enums: IEnumsState;
  modals: {
    alertModal: IAlertModalState;
    plotsModal: IPlotsModalState;
    deletionModal: IDeletionModalState;
    dailySeatModal: IDailySeatModalState;
    noFreeSeatsModal: INoFreeSeatsModalState;
    navigationModal: INavigationModalState;
    obatResourceCommitmentsModal: IObatResourceCommitmentsModalState;
    restResourceCommitmentsModal: IRestResourceCommitmentsModalState;
    logsModal: ILogsModalState;
    logoutModal: ILogoutModalState;
    userCreationModal: IUserCreationModalState;
  };
}

export const appReducer = combineReducers({
  apiRequests: apiRequestsReducer,
  azure: azureReducer,
  table: tableReducer,
  floorspace: floorspaceReducer,
  form: formReducer,
  variantContext: variantContextReducer,
  geometry: geometryReducer,
  routing: routingReducer,
  obatResources: obatResourcesReducer,
  tasks: tasksReducer,
  tokens: tokensReducer,
  weather: weatherReducer,
  simulationGroup: simulationGroupReducer,
  me: meReducer,
  organization: organizationReducer,
  obat: obatReducer,
  enums: enumsReducer,
  modals: combineReducers({
    alertModal: alertModalReducer,
    plotsModal: plotsModalReducer,
    deletionModal: deletionModalReducer,
    dailySeatModal: dailySeatModalReducer,
    noFreeSeatsModal: noFreeSeatsModalReducer,
    navigationModal: navigationModalReducer,
    obatResourceCommitmentsModal: obatResourceCommitmentsModalReducer,
    restResourceCommitmentsModal: restResourceCommitmentsModalReducer,
    logsModal: logsModalReducer,
    logoutModal: logoutModalReducer,
    userCreationModal: userCreationModalReducer,
  }),
});

export const rootReducer = (rootState: IRootState | undefined, action: Action) => {
  if (action.type === 'RESET_APP_ACTION') {
    return appReducer(undefined, action);
  }

  return appReducer(rootState, action);
};
