import { Add } from '@material-ui/icons';
import { Button, CenterButtons } from 'components/Buttons';
import React, { PureComponent, ReactNode } from 'react';

interface IProps {
  onClick: () => void;
  text: string;
}

export class NewSubFormButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { onClick, text } = this.props;

    return (
      <CenterButtons marginTop={12}>
        <Button onClick={onClick} text={text} Icon={<Add fontSize={'small'} />} noShadow />
      </CenterButtons>
    );
  }
}
