import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { fetchUserOrganizationPermissionsSagaAction } from 'redux/restResources/detail/organization/actions';
import { selectRouteProjectUserOptions } from 'redux/restResources/detail/organization/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { getProjectOrganizationPermission } from 'utils/functions/permissions/getProjectOrganizationPermission';
import { rrc } from 'utils/strings/resourceClasses';

import { ProjectPermissionsForm as Component } from './ProjectPermissionsForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const permissionToEdit = selectFormInitialResourceFromTable(rrc.user_project_permission)(rootState);

  const organizationPermission = permissionToEdit
    ? getProjectOrganizationPermission(
        permissionToEdit.bits_can_admin,
        permissionToEdit.bits_can_write,
        permissionToEdit.bits_can_read
      )
    : undefined;

  const hasNoInheritedPermission =
    organizationPermission &&
    !organizationPermission.can_admin &&
    !organizationPermission.can_write &&
    !organizationPermission.can_read;

  return {
    routeProject: selectRouteResource(rrc.project)(rootState),
    permissionToEdit,
    organizationPermission,
    hasNoInheritedPermission,
    userOptions: selectRouteProjectUserOptions(rootState),
  };
};

const mapDispatchToProps = {
  fetchUserOrganizationPermissions: fetchUserOrganizationPermissionsSagaAction,
};

export const ProjectPermissionsForm = connect(mapStateToProps, mapDispatchToProps)(Component);
