import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { obatMenuPages } from 'routing/routes';
import { IEfEp } from 'types/Obat/EfEp';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { orc } from 'utils/strings/resourceClasses';

import { $t } from 'utils/functions/translate';
import { EfEpForm } from '../EfEpForm';
import { EfEpTable } from '../EfEpTable';

export class EfEpPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={obatMenuPages.efEp}
        resourceClass={orc.ef_ep}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={EfEpForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', title: 'ref', gridItemSize: 12 },
  ];

  private renderCardColumnData = (efEp: IEfEp, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return $t(efEp.ref);
    }
  };

  private renderCardDetail = (efEp: IEfEp): ReactNode => {
    return <EfEpTable efEp={efEp} />;
  };
}
