import { _map } from 'libs/lodash';
import moment from 'moment';

export interface IPlotHoverTooltip {
  hovertemplate: string;
  text: string[];
}

export const getSeriesPlotHoverTooltip = (
  index: string[],
  seriesName: string | undefined = undefined,
  dateFormat: string = 'YYYY-MM-DD HH:mm',
  needExtraTag: boolean = false
): IPlotHoverTooltip => {
  let hovertemplate = `<b>%{y}</b><br><i>%{text}</i>`;
  hovertemplate = seriesName ? `${hovertemplate}<br>${seriesName}` : hovertemplate;
  /* Add an extra tag to avoid unwanted display (it seems to be random): https://github.com/plotly/plotly.js/issues/3621 */
  hovertemplate = needExtraTag ? `${hovertemplate}<extra></extra>` : hovertemplate;

  const text = _map(index, (datetime: string) => moment.utc(datetime).format(dateFormat));

  return { hovertemplate, text };
};
