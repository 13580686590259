import { connect } from 'react-redux';
import { openFormDrawerSagaAction } from 'redux/form/actions';
import { IRootState } from 'redux/reducer';
import {
  selectSimulation,
  selectSimulationGroupConfigOptions,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { fk } from 'utils/strings/formKeys';
import { rrc } from 'utils/strings/resourceClasses';

import { MonoSimulationGroupConfigCard as Component } from './MonoSimulationGroupConfigCard.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = ReturnType<typeof mergeProps>;

const mapStateToProps = (rootState: IRootState) => ({
  simulation: selectSimulation(rootState),
  routeMonoSimulationGroup: selectRouteResource(rrc.mono_simulation_group)(rootState),
  routeProject: selectRouteResource(rrc.project)(rootState),
  configOptions: selectSimulationGroupConfigOptions(rootState),
});

const mapDispatchToProps = {
  openFormDrawer: openFormDrawerSagaAction,
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: {}) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openConfigFormDrawer: () =>
    dispatchProps.openFormDrawer(fk.configMono, {
      resourceId: stateProps.routeMonoSimulationGroup.id,
      resourceClass: rrc.mono_simulation_group,
    }),
});

export const MonoSimulationGroupConfigCard = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
