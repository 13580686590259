import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { updateFilterColumnValueAction } from 'redux/table/actions';
import { selectTableFilterParams, selectTableFilterValues } from 'redux/table/selectors';

import { FilterForm as Component } from './FilterForm.component';

interface IOwnProps {
  columnId: string;
  minWidth?: number;
  maxWidth?: number;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IProps = IOwnProps & IStateProps & IDispatchProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    tableFilterValues: selectTableFilterValues(rootState),
    tableFilterParams: selectTableFilterParams(rootState),
  };
};

const mapDispatchToProps = {
  updateColumnValue: updateFilterColumnValueAction,
};

export const FilterForm = connect(mapStateToProps, mapDispatchToProps)(Component);
