import Grid from '@material-ui/core/Grid';
import { getForm } from 'components/Forms/Form';
import { getFormDrawer } from 'components/Forms/FormDrawer';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikToggleInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikToggleInput';
import { FormikProps } from 'formik';
import { _includes } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { getYupStringValidation } from 'libs/yup';
import React, { PureComponent, ReactNode } from 'react';
import { IMonthlyResultsTemplate } from 'redux/restResources/detail/simulationGroup/reducer';
import { fdw } from 'utils/configs/drawerWidths';
import aggregateTemplates from 'utils/configs/monthlyResultsPivotTemplates/aggregate.json';
import bySimulationTemplates from 'utils/configs/monthlyResultsPivotTemplates/bySimulation.json';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { $tPivotTemplates } from 'utils/functions/translate/translate';
import { fk } from 'utils/strings/formKeys';
import { mras, mrbss, rv } from 'utils/strings/results';
import * as yup from 'yup';

import { IProps } from './TemplateForm.container';

type IResultsTemplateFormValues = IMonthlyResultsTemplate;

const Form = getForm<IResultsTemplateFormValues>();
const FormDrawer = getFormDrawer();

export class TemplateForm extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <FormDrawer formKey={fk.template} width={fdw.medium} title={$t('applyTemplate')}>
        <Form
          renderForm={this.renderForm}
          getInitialFormValues={this.getInitialFormValues}
          getValidationSchema={this.getValidationSchema}
          isSubmitting={false}
          forceSubmitEnabled={this.forceSubmitEnabled}
          onSubmit={this.onSubmit}
        />
      </FormDrawer>
    );
  }

  public renderForm = (formikProps: FormikProps<IResultsTemplateFormValues>): ReactNode => {
    const { section, resultsMode } = this.props;

    const isToggleButtonDisabled = formikProps.values.name === 'empty';
    const templates = resultsMode === rv.bySimulation ? bySimulationTemplates : aggregateTemplates;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikDropdownInput
            field={'name'}
            formikProps={formikProps}
            // @ts-ignore
            options={_keys(templates[section])}
            onSelect={this.onTemplateNameSelect(formikProps)}
            label={$t('templateName')}
            renderOptionLabel={$tPivotTemplates}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <FormikToggleInput
                field={'mode'}
                formikProps={formikProps}
                options={['chart', 'table']}
                renderOptionLabel={$t}
                isDisabled={isToggleButtonDisabled}
              />
            </Grid>
          </Grid>
        </Grid>
        {_includes(
          [mrbss.out_monthly_consumption, mras.out_monthly_consumption_ef, mras.out_monthly_consumption_ep],
          section
        ) && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <FormikToggleInput
                  field={'unit'}
                  formikProps={formikProps}
                  options={['kwh', 'kwh_m2']}
                  renderOptionLabel={$t}
                  isDisabled={isToggleButtonDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {_includes([mrbss.out_monthly_consumption], section) && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <FormikToggleInput
                  field={'energy'}
                  formikProps={formikProps}
                  options={[
                    {
                      value: 'ef',
                      label: $t('kwh_ef'),
                    },
                    {
                      value: 'ep',
                      label: $t('kwh_ep'),
                    },
                  ]}
                  isDisabled={isToggleButtonDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  private getInitialFormValues = (): IResultsTemplateFormValues => {
    const { template, section, resultsMode } = this.props;

    const templates = resultsMode === rv.bySimulation ? bySimulationTemplates : aggregateTemplates;

    return {
      // @ts-ignore
      name: setFormInitialValue(template, 'name', _keys(templates[section])[0]),
      mode: setFormInitialValue(template, 'mode', 'chart'),
      energy: setFormInitialValue(template, 'energy', 'kwh'),
      unit: setFormInitialValue(template, 'unit', 'ef'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<IResultsTemplateFormValues> => {
    return yup.object().shape({
      name: getYupStringValidation(),
      mode: getYupStringValidation(),
      energy: getYupStringValidation(),
      unit: getYupStringValidation(),
    }) as yup.ObjectSchema<IResultsTemplateFormValues>;
  };

  private onSubmit = (formValues: IResultsTemplateFormValues): void => {
    const { updateTemplate, section, closeFormDrawer } = this.props;

    let template = {
      name: formValues.name,
      mode: formValues.mode,
      unit: '',
      energy: '',
    };
    if (
      _includes(
        [
          mrbss.out_monthly_consumption,
          mrbss.out_monthly_thermal_balance,
          mras.out_monthly_consumption_ep,
          mras.out_monthly_consumption_ef,
        ],
        section
      )
    ) {
      template = { ...template, unit: formValues.unit };
    }
    if (_includes([mrbss.out_monthly_consumption], section)) {
      template = { ...template, energy: formValues.energy };
    }
    updateTemplate(template);

    closeFormDrawer();
  };

  private onTemplateNameSelect = (formikProps: FormikProps<IResultsTemplateFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      formikProps.setFieldValue('name', selectedValue);

      if (selectedValue === '') {
        formikProps.setFieldValue('mode', '');
        formikProps.setFieldValue('unit', '');
        formikProps.setFieldValue('energy', '');
      } else {
        if (formikProps.values.mode === '') {
          formikProps.setFieldValue('mode', 'chart');
        }
        if (formikProps.values.unit === '') {
          formikProps.setFieldValue('unit', 'kwh');
        }
        if (formikProps.values.energy === '') {
          formikProps.setFieldValue('energy', 'ef');
        }
      }
    };
  };

  private forceSubmitEnabled = (formikProps: FormikProps<IResultsTemplateFormValues>) => {
    return formikProps.dirty;
  };
}
