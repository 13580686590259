import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { WithWeatherMenu as WithWeatherMenuComponent } from './WithWeatherMenu.component';

export interface IOwnProps {
  isCollapsed?: boolean;
  selectedPage: string;
}

export type IStateProps = ReturnType<typeof mapStateToProps>;
export type IDispatchProps = typeof mapDispatchToProps;
export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    routeWeather: selectRouteResource(rrc.weather)(rootState),
  };
};

const mapDispatchToProps = {};

export const WithWeatherMenu = connect(mapStateToProps, mapDispatchToProps)(WithWeatherMenuComponent);
