import Grid from '@material-ui/core/Grid';
import { SimpleCard } from 'components/Cards';
import { LegendItem } from 'components/PlotLegend';
import { LightText } from 'components/Texts';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import { ILayer } from 'types/Obat/Layer';
import { renderTextFloatElseDash } from 'utils/functions/renderValue/renderValue';
import { $t } from 'utils/functions/translate';

interface IProps {
  layers: ILayer[];
}

export class LayersCard extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <>
        <SimpleCard>
          <Grid container spacing={2}>
            <Grid item xs={4} />
            <Grid item xs={2}>
              {$t('conductivityWithUnit')}
            </Grid>
            <Grid item xs={2}>
              {$t('densityWithUnit')}
            </Grid>
            <Grid item xs={2}>
              {$t('specificHeatWithUnit')}
            </Grid>
            <Grid item xs={2}>
              {$t('thicknessWithUnit')}
            </Grid>
          </Grid>
          {_map(this.props.layers, (layer: ILayer, i: number) => (
            <div key={i}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <LegendItem
                    dotColor={theme.colors.plot.obatColorway[i]}
                    text={layer.material}
                    textFontWeight="normal"
                  />
                </Grid>
                <Grid item xs={2}>
                  <LightText>{renderTextFloatElseDash(layer.conductivity)}</LightText>
                </Grid>
                <Grid item xs={2}>
                  <LightText>{renderTextFloatElseDash(layer.material_content.density)}</LightText>
                </Grid>
                <Grid item xs={2}>
                  <LightText>{renderTextFloatElseDash(layer.material_content.specific_heat)}</LightText>
                </Grid>
                <Grid item xs={2}>
                  <LightText>{renderTextFloatElseDash(layer.thickness)}</LightText>
                </Grid>
              </Grid>
            </div>
          ))}
        </SimpleCard>
      </>
    );
  }
}
