import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectMyLanguage } from 'redux/restResources/detail/me/selectors';
import {
  clearHourlyResultsAction,
  downloadHourlyResultsDataSagaAction,
  exportHourlyCsvSagaAction,
  refreshSimulationGroupSagaAction,
  updateHourlyResultsModeAction,
  updateSimulationAction,
} from 'redux/restResources/detail/simulationGroup/actions';
import {
  selectHourlyResultsMode,
  selectHourlyResultsPlot,
  selectIsAllSimulationsEmpty,
  selectIsDownloadingHourlyResults,
  selectSimulation,
  selectSimulationsList,
  selectSimulationsListByStatus,
} from 'redux/restResources/detail/simulationGroup/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import {
  clearSelectedDataAction,
  fetchTableInitialDataSagaAction,
  fetchTableUpdatedDataSagaAction,
  initializeTableWithSelectionAction,
} from 'redux/table/actions';
import { selectTableResources } from 'redux/table/selectors';
import { IRestResource } from 'types/RestResource';
import { rrc } from 'utils/strings/resourceClasses';
import { updateHourlyResultsPlotOperationAction } from './../../../../redux/restResources/detail/simulationGroup/actions';

import { HourlyResultsPage as Component } from './HourlyResultsPage.component';

interface IOwnProps {
  routeSimulationGroup: IRestResource;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState, ownProps: IOwnProps) => {
  const isMulti = ownProps.routeSimulationGroup.model === 'multisimulationgroup';
  const isMono = ownProps.routeSimulationGroup.model === 'monosimulationgroup';
  const routeSimulationGroupResourceClass = isMono ? rrc.mono_simulation_group : rrc.multi_simulation_group;
  const routeProject = selectRouteResource(rrc.project)(rootState);
  const simulation = selectSimulation(rootState);

  return {
    isMulti,
    isMono,
    routeSimulationGroupResourceClass,
    simulation,
    routeProject,
    resultsMode: selectHourlyResultsMode(rootState),
    tableResources: selectTableResources(rootState)(rrc.series, ownProps.routeSimulationGroup.id),
    isDownloadingHourlyResults: selectIsDownloadingHourlyResults(rootState),
    simulationsList: selectSimulationsList(rootState),
    simulationsListByStatus: selectSimulationsListByStatus(rootState),
    hourlyResultsPlot: selectHourlyResultsPlot(rootState),
    userMeLanguage: selectMyLanguage(rootState),
    isAllSimulationsEmpty: selectIsAllSimulationsEmpty(rootState),
  };
};

const mapDispatchToProps = {
  updateHourlyResultsMode: updateHourlyResultsModeAction,
  downloadHourlyResultsData: downloadHourlyResultsDataSagaAction,
  fetchTableInitialData: fetchTableInitialDataSagaAction,
  fetchTableUpdatedData: fetchTableUpdatedDataSagaAction,
  clearHourlyResults: clearHourlyResultsAction,
  exportHourlyCsv: exportHourlyCsvSagaAction,
  updateSimulation: updateSimulationAction,
  refreshSimulationGroup: refreshSimulationGroupSagaAction,
  initializeTableWithSelection: initializeTableWithSelectionAction,
  clearSelectedData: clearSelectedDataAction,
  updateHourlyResultsPlotOperation: updateHourlyResultsPlotOperationAction,
};

export const HourlyResultsPage = connect(mapStateToProps, mapDispatchToProps)(Component);
