import { LightText } from 'components/Texts';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { IPeriod } from 'types/Obat/Period';
import { monthNameList } from 'utils/configs/optionsLists';
import { ordinalSuffix } from 'utils/functions/ordinalSuffix/ordinalSuffix';
import { $t } from 'utils/functions/translate';

import { IProps } from './PeriodsDetails.container';

export class PeriodsDetails extends PureComponent<IProps> {
  public render(): ReactNode {
    const { periods } = this.props;

    return (
      <DetailsContainer>
        {_map(periods, (period: IPeriod) => {
          return (
            <PeriodRow key={period.weak_ref}>
              <NameCol>{period.weak_ref}</NameCol>
              <LightCol>{this.renderDays(period.affected_day_types)}</LightCol>
              <LightCol>
                {`${this.getStringDates(
                  'start',
                  period.start_day_type,
                  period.start_number,
                  period.start_month,
                  null
                )}`}
              </LightCol>
              <LightCol>{`${this.getStringDates(
                'end',
                period.end_day_type,
                period.end_number,
                period.end_month,
                period.duration
              )}`}</LightCol>
            </PeriodRow>
          );
        })}
      </DetailsContainer>
    );
  }

  public renderDays = (affectedDayTypes: string[]): ReactNode => {
    if (affectedDayTypes.length === 7) {
      return <span>{$t('everyDay')}</span>;
    } else {
      return _map(
        affectedDayTypes,
        (day: string): ReactNode => {
          return <span key={day}>{$t(day).slice(0, 3) + '. '}</span>;
        }
      );
    }
  };

  private getStringDates = (
    mode: 'start' | 'end',
    dayType: string | null,
    dateNumber: number | null,
    monthNumber: number | null,
    duration: number | null
  ): string => {
    const { myLanguage } = this.props;

    if (!dateNumber) {
      dateNumber = mode === 'start' ? 1 : 31;
    }
    if (!monthNumber) {
      monthNumber = mode === 'start' ? 1 : 12;
    }

    const prefix = mode === 'start' ? $t('from') : $t('to');
    const monthString = $t(monthNameList[monthNumber - 1]);
    const of = $t('of');

    if (dayType) {
      const dateNumberString = dateNumber + $t(ordinalSuffix(dateNumber));
      const dayTypeString = $t(dayType);

      return `${prefix} ${dateNumberString} ${dayTypeString} ${of} ${monthString}`;
    } else if (duration) {
      return `${$t('for')} ${duration} ${$t('days')}`;
    } else {
      const dayOrdinal =
        dateNumber === 1 || myLanguage === 'en' ? dateNumber + $t(ordinalSuffix(dateNumber)) : dateNumber;

      return `${prefix} ${dayOrdinal} ${monthString}`;
    }
  };
}

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PeriodRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const NameCol = styled.div`
  flex: 1;
`;

const LightCol = styled(LightText)`
  flex: 1;
`;
