import Grid from '@material-ui/core/Grid';
import { SimpleCard } from 'components/Cards';
import { LightText } from 'components/Texts';
import { SimpleTooltip } from 'components/Tooltip/SimpleTooltip';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { IModification } from 'types/Obat/Modification';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';

interface IProps {
  modifications: IModification[];
}

export class ModificationsCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { modifications } = this.props;

    return (
      <SimpleCard>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            {$t('name')}
          </Grid>
          <Grid item xs={2}>
            {$t('objectType')}
          </Grid>
          <Grid item xs={3}>
            {$t('objectFilter')}
          </Grid>
          <Grid item xs={3}>
            {$t('method')}
          </Grid>
          <Grid item xs={2}>
            {$t('value')}
          </Grid>
        </Grid>
        {_map(modifications, (modification: IModification) => (
          <Grid container spacing={1} key={modification.weak_ref}>
            <Grid item xs={2}>
              <SimpleTooltip placement="bottom-start" content={modification.weak_ref}>
                <Ellipsis>{modification.weak_ref}</Ellipsis>
              </SimpleTooltip>
            </Grid>
            <Grid item xs={2}>
              <SimpleTooltip
                placement="bottom-start"
                content={modification.table_ref ? $tEnums(modification.table_ref) : ''}
              >
                <LightEllipsis>{$tEnums(renderTextValueElseDash(modification.table_ref))}</LightEllipsis>
              </SimpleTooltip>
            </Grid>
            <Grid item xs={3}>
              <SimpleTooltip placement="bottom-start" content={modification.filter_records}>
                <LightEllipsis>{renderTextValueElseDash(modification.filter_records)}</LightEllipsis>
              </SimpleTooltip>
            </Grid>
            <Grid item xs={3}>
              <SimpleTooltip placement="bottom-start" content={modification.method ? $tEnums(modification.method) : ''}>
                <LightEllipsis>{$tEnums(renderTextValueElseDash(modification.method))}</LightEllipsis>
              </SimpleTooltip>
            </Grid>
            <Grid item xs={2}>
              <SimpleTooltip placement="bottom-start" content={modification.value.value}>
                <LightEllipsis>{renderTextValueElseDash(modification.value.value)}</LightEllipsis>
              </SimpleTooltip>
            </Grid>
          </Grid>
        ))}
      </SimpleCard>
    );
  }
}

const Ellipsis = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block;
`;

const LightEllipsis = styled(LightText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block;
`;
