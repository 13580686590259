import { _reduce } from 'libs/lodash';
import { IObatGroupedPositionOptions } from 'redux/obatResources/selectors';
import { getCreationPositionOptions } from 'utils/functions/obatResources/position/getCreationPositionOptions';

export const getGroupedCreationPositionOptions = (groupedRefs: {
  [key: string]: string[];
}): IObatGroupedPositionOptions => {
  return _reduce(
    groupedRefs,
    (groupedPositions: IObatGroupedPositionOptions, refs: string[], groupedBy: string): IObatGroupedPositionOptions => {
      return { ...groupedPositions, [groupedBy]: getCreationPositionOptions(refs) };
    },
    {}
  );
};
