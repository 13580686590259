import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Buttons';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { SimpleCard, SpaceBetweenCardHeader } from 'components/Cards';
import { AlertIcon } from 'components/Icons';
import { EmailLink } from 'components/Links/EmailLink';
import { DailySeatsModal } from 'components/Modals/DailySeatsModal';
import { Page } from 'components/Page';
import { ITooltipProps } from 'components/Tooltip/SimpleTooltip/SimpleTooltip.component';
import { _map } from 'libs/lodash';
import { AffectSeatForm } from 'pages/OrganizationMenu/Seats/AffectSeatForm';
import React, { PureComponent, ReactNode } from 'react';
import { organizationMenuPages } from 'routing/routes';
import theme from 'style/theme';
import styled from 'styled-components';
import { IUserOrganizationPermission } from 'types/Permissions';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { fk } from 'utils/strings/formKeys';
import { pa } from 'utils/strings/permissionAlerts';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './Seats.container';

export class Seats extends PureComponent<IProps> {
  public componentDidMount(): void {
    const { fetchUserOrganizationPermissions, routeOrganization } = this.props;
    fetchUserOrganizationPermissions(routeOrganization.id);
  }

  public render(): ReactNode {
    return (
      <Page pageTitle={$t('seats')} routingMenu={rm.organization} selectedPage={organizationMenuPages.seats}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            {this.renderFreeSeatsCard()}
          </Grid>
          <Grid item xs={5}>
            {this.renderTakenSeatsCard()}
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            {this.renderDailySeatsCard()}
          </Grid>
        </Grid>

        <DailySeatsModal />
        <AffectSeatForm />
      </Page>
    );
  }

  private renderTakenSeatsCard = (): ReactNode => {
    const { routeOrganization, takingSeatUserPermissions, manageSeat, isAdminPermissionMissing } = this.props;
    const { taken_seats } = routeOrganization;

    const takenSeats = taken_seats;
    const isNoTakenSeat = takenSeats === 0;
    const onDeleteClick = (userPermissionId: string) => () => {
      manageSeat('leave', userPermissionId);
    };

    return (
      <SimpleCard>
        <SpaceBetweenCardHeader isDisabled={isNoTakenSeat}>
          <span>{$t('takenSeats')}</span>
          <SeatsNumber>{takenSeats}</SeatsNumber>
        </SpaceBetweenCardHeader>
        {_map(takingSeatUserPermissions, (userPermission: IUserOrganizationPermission) => {
          return (
            <TakenSeat key={userPermission.user.email}>
              <EmailLink email={userPermission.user.email} />
              {userPermission.is_leaving_seat ? (
                <AlertIcon status={as.running} tooltipContent={$t('isLeavingSeatAlert')} />
              ) : (
                <IconButton
                  action={'delete'}
                  onClick={onDeleteClick(userPermission.id)}
                  isAdminPermissionMissing={isAdminPermissionMissing}
                />
              )}
            </TakenSeat>
          );
        })}
      </SimpleCard>
    );
  };

  private renderFreeSeatsCard = (): ReactNode => {
    const { routeOrganization, manageSeat, isAdminPermissionMissing, openFormDrawer } = this.props;
    const { available_seats, taken_seats, is_activated, is_taking_up_seat } = routeOrganization;

    const freeSeats = available_seats - taken_seats;
    const isNoFreeSeat = freeSeats === 0;

    const takeOrLeaveButtonText = is_activated ? $t('leaveSeat') : $t('takeSeat');
    const isTakeOrLeaveButtonDisabled = (is_activated && !is_taking_up_seat) || (!is_activated && isNoFreeSeat);
    const onTakeOrLeaveButtonClick = (): void => {
      manageSeat(is_activated ? 'leave' : 'take');
    };

    const isAffectButtonDisabled = isNoFreeSeat || isAdminPermissionMissing;
    const onAffectButtonClick = (): void => {
      openFormDrawer(fk.allocateSeat);
    };
    const allocateButtonTooltipProps: ITooltipProps = {
      placement: 'bottom',
      content: isAdminPermissionMissing ? $t(pa.adminPermissionRequired) : undefined,
    };

    return (
      <SimpleCard>
        <SpaceBetweenCardHeader isDisabled={isNoFreeSeat}>
          <span>{$t('freeSeats')}</span>
          <SeatsNumber>{freeSeats}</SeatsNumber>
        </SpaceBetweenCardHeader>
        <Grid container spacing={1} direction={'column'} justify={'center'} alignItems={'center'}>
          <Grid item>
            <Button
              noShadow
              text={takeOrLeaveButtonText}
              isDisabled={isTakeOrLeaveButtonDisabled}
              onClick={onTakeOrLeaveButtonClick}
            />
          </Grid>
          <Grid item>
            <Button
              noShadow
              text={$t('allocateSeat')}
              isDisabled={isAffectButtonDisabled}
              onClick={onAffectButtonClick}
              tooltipProps={allocateButtonTooltipProps}
            />
          </Grid>
        </Grid>
      </SimpleCard>
    );
  };

  private renderDailySeatsCard = (): ReactNode => {
    const { routeOrganization, openDailySeatModal, canSpendDailySeats } = this.props;
    const { remaining_daily_seats } = routeOrganization;

    const dailySeats = remaining_daily_seats;
    const buttonText = $t('spendDailySeat');
    const isNoDailySeat = dailySeats === 0;
    const isDisabled = isNoDailySeat || !canSpendDailySeats;
    const tooltipProps: ITooltipProps = {
      content: !canSpendDailySeats
        ? $t(pa.spendDailySeatsPermissionRequired)
        : isNoDailySeat
        ? $t('noDailySeatAlert')
        : undefined,
      placement: 'bottom',
    };
    const onClick = (): void => {
      openDailySeatModal();
    };

    return (
      <SimpleCard>
        <SpaceBetweenCardHeader isDisabled={isNoDailySeat}>
          <span>{$t('dailySeats')}</span>
          <SeatsNumber>{remaining_daily_seats}</SeatsNumber>
        </SpaceBetweenCardHeader>
        <Grid container spacing={1} direction={'column'} justify={'center'} alignItems={'center'}>
          <Grid item>
            <Button noShadow text={buttonText} isDisabled={isDisabled} onClick={onClick} tooltipProps={tooltipProps} />
          </Grid>
        </Grid>
      </SimpleCard>
    );
  };
}

const TakenSeat = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  &:hover {
    background-color: ${theme.colors.listItemHover};
  }
`;

const SeatsNumber = styled.div`
  font-size: 125%;
`;
