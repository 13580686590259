// tslint:disable:no-any

import { _get } from 'libs/lodash';
import { _has } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { IObatResource } from 'types/Obat/ObatResource';

export const isCommittedForEdit = <IObatResourceType extends IObatResource>(
  obatResource: IObatResourceType,
  field: keyof IObatResourceType
): boolean => {
  if (_has(obatResource.commitments.update, field)) {
    return _size(_get(obatResource.commitments.update, field)) > 0;
  }

  return false;
};

export const isCommittedForDelete = <IObatResourceType extends IObatResource>(
  obatResource: IObatResourceType
): boolean => {
  return _size(_keys(obatResource.commitments.delete)) > 0;
};
