import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { closeDeletionModalAction } from 'redux/modals/deletionModal/actions';
import { IRootState } from 'redux/reducer';
import { orc } from 'utils/strings/resourceClasses';

import { VariantForm as VariantFormComponent } from './VariantForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const variantToEdit = selectFormInitialResourceFromTable(orc.variant)(rootState);

  return { variantToEdit };
};

const mapDispatchToProps = {
  closeDeletionModal: closeDeletionModalAction,
};

export const VariantForm = connect(mapStateToProps, mapDispatchToProps)(VariantFormComponent);
