import { mapStrings, stringsTuple } from 'utils/strings/mapStrings';

const resultsViews = stringsTuple('bySimulation', 'aggregate');
export type IResultsView = typeof resultsViews[number];
export const rv = mapStrings<IResultsView>(resultsViews);

const monthlyResultsBySimulationSections = stringsTuple(
  'out_monthly_consumption',
  'out_monthly_thermal_balance',
  'out_monthly_comfort',
  'out_monthly_comfort_indicators',
  'out_zones',
  'out_envelope',
  'out_monthly_miscellaneous',
  'out_monthly_weather'
);
export type IMonthlyResultsBySimulationSection = typeof monthlyResultsBySimulationSections[number];
export const mrbss = mapStrings<IMonthlyResultsBySimulationSection>(monthlyResultsBySimulationSections);

const monthlyResultsAggregateSections = stringsTuple(
  'out_monthly_consumption_ef',
  'out_monthly_consumption_ep',
  'out_monthly_comfort_occ',
  'out_monthly_comfort_indicators',
  'out_zones',
  'out_envelope',
  'out_monthly_weather'
);
export type IMonthlyResultsAggregateSection = typeof monthlyResultsAggregateSections[number];
export const mras = mapStrings<IMonthlyResultsAggregateSection>(monthlyResultsAggregateSections);

export type IMonthlyResultsSection = IMonthlyResultsBySimulationSection | IMonthlyResultsAggregateSection;
