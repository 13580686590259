import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectHourlyResultsPlot } from 'redux/restResources/detail/simulationGroup/selectors';

import { HourlyResultsPlot as Component } from './HourlyResultsPlot.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    hourlyResultsPlot: selectHourlyResultsPlot(rootState),
  };
};

const mapDispatchToProps = {};

export const HourlyResultsPlot = connect(mapStateToProps, mapDispatchToProps)(Component);
