export const oPalette = {
  dark: '#232b3b',
  lightdark: '#2f3849',
  turquoise: '#1abea4',
  lightturquoise: '#38e4c9',
  darkturquoise: '#149883',
  transparentturquoise: 'rgba(56, 228, 201, 0.1)',
  transparentgray: 'rgba(0, 0, 0, 0.12)',
  halfblack: 'rgba(0, 0, 0, 0.50)',
  transparent: 'rgba(255,255,255,0)',
  skyBlue: '#88b4e7',
};
