import { connect } from 'react-redux';
import { closePlotsModalAction } from 'redux/modals/plotsModal/actions';
import { selectIsPlotsModalOpen, selectPlotsModalData } from 'redux/modals/plotsModal/selectors';
import { IRootState } from 'redux/reducer';

import { PlotsModal as PlotsModalComponent } from './PlotsModal.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IStateProps, IDispatchProps {}

const mapStateToProps = (rootState: IRootState) => {
  return {
    isOpen: selectIsPlotsModalOpen(rootState),
    plotsModalData: selectPlotsModalData(rootState),
  };
};

const mapDispatchToProps = {
  closePlotsModal: closePlotsModalAction,
};

export const PlotsModal = connect(mapStateToProps, mapDispatchToProps)(PlotsModalComponent);
