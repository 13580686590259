import { _omit } from 'libs/lodash';
import { IHeatmap } from 'types/Obat/Calendar';
import { IObatContents } from 'types/Obat/ObatContents';

import {
  CLEAR_ALL_HEATMAPS_ACTION,
  CLEAR_OBAT_CONTENTS_ACTION,
  CLEAR_SINGLE_HEATMAP_ACTION,
  IObatResourcesActions,
  UPDATE_HEATMAP_ACTION,
  UPDATE_OBAT_CONTENTS_ACTION,
  UPDATE_REF_TO_SCROLL_TO_ACTION,
} from './actions';

interface IObatHeatmaps {
  [calendarRef: string]: IHeatmap;
}

export interface IObatResourcesState {
  obatContents: IObatContents | undefined;
  obatId: string | undefined;
  heatmaps: IObatHeatmaps;
  refToScrollTo: string | undefined;
}

export const initialState = {
  obatContents: undefined,
  obatId: undefined,
  heatmaps: {},
  refToScrollTo: undefined,
};

export const obatResourcesReducer = (
  state: IObatResourcesState = initialState,
  action: IObatResourcesActions
): IObatResourcesState => {
  switch (action.type) {
    case UPDATE_OBAT_CONTENTS_ACTION:
      return { ...state, obatContents: action.payload.obatContents };

    case CLEAR_OBAT_CONTENTS_ACTION:
      return { ...state, obatContents: initialState.obatContents };

    case UPDATE_HEATMAP_ACTION:
      const { calendarRef, heatmap } = action.payload;

      return { ...state, heatmaps: { ...state.heatmaps, [calendarRef]: heatmap } };

    case CLEAR_ALL_HEATMAPS_ACTION:
      return { ...state, heatmaps: initialState.heatmaps };

    case CLEAR_SINGLE_HEATMAP_ACTION:
      return { ...state, heatmaps: _omit(state.heatmaps, [action.payload.calendarRef]) };

    case UPDATE_REF_TO_SCROLL_TO_ACTION:
      return { ...state, refToScrollTo: action.payload.refToScrollTo };

    default:
      return state;
  }
};
