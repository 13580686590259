import history from './history';

export const navigateRoute = (route: string, mouseEvent?: React.MouseEvent, forceBlank?: boolean): void => {
  // ctrlKey = ctrl on Openings
  // metaKey = cmd on Mac and Openings on Openings
  // button 1 = wheel on mouse
  if (forceBlank || (mouseEvent && (mouseEvent.ctrlKey || mouseEvent.metaKey || mouseEvent.button === 1))) {
    window.open(route, '_blank');
  } else {
    history.push(route);
  }
};
