import DragIndicator from '@material-ui/icons/DragIndicator';
import { SubFormCard } from 'components/Cards';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';

interface IProps {
  text: string;
}

interface IState {
  isGrabbing: boolean;
}

export class DragAndDropCard extends PureComponent<IProps> {
  public state: IState = {
    isGrabbing: false,
  };

  public render(): ReactNode {
    return (
      // the element on drag and drop need hight z-index to show and overlap other elememt
      <SubFormCard onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp} style={{ zIndex: 9999 }}>
        <Container grabbing={this.state.isGrabbing}>
          <DragIndicator /> <span>{this.props.text}</span>
        </Container>
      </SubFormCard>
    );
  }

  private onMouseDown = (): void => {
    this.setState({ isGrabbing: true });
  };

  private onMouseUp = (): void => {
    this.setState({ isGrabbing: false });
  };
}

interface IContainerProps {
  grabbing: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props: IContainerProps): string => (props.grabbing ? 'grabbing' : 'grab')};
  z-index: 99999;
  color: ${theme.colors.main};
`;
