// tslint:disable:no-any

import { apiEndpoints } from 'api/endPoints';
import { _get } from 'libs/lodash';
import { _includes } from 'libs/lodash';
import { toPlural } from 'libs/pluralize';
import { ITokens } from 'types/Tokens';
import { gra, IGenericRequestAction } from 'utils/strings/requestActions';
import { IRestResourceClass } from 'utils/strings/resourceClasses';

export interface IResourceTag {
  resourceClass: IRestResourceClass;
  resourceId: string;
}

export interface IRestApiUrlParams {
  resourceClass: IRestResourceClass;
  resourceId?: string;
  parentResourceTag?: IResourceTag;
  detailRoute?: string;
}

export const getRestApiUrl = (
  accessToken: ITokens['access'],
  action: IGenericRequestAction,
  apiUrlParams: IRestApiUrlParams
): any => {
  const { resourceClass, resourceId, parentResourceTag, detailRoute } = apiUrlParams;

  let url = parentResourceTag
    ? _get(
        apiEndpoints.restResources,
        parentResourceTag.resourceClass
      )(accessToken)
        .url(parentResourceTag.resourceId + '/')
        .url(toPlural(resourceClass) + '/')
    : _get(apiEndpoints.restResources, resourceClass)(accessToken);

  url =
    resourceId && _includes([gra.retrieve, gra.copy, gra.edit, gra.delete, gra.detailRoute], action)
      ? url.url(resourceId + '/')
      : url;

  if (detailRoute) {
    return url.url(detailRoute + '/');
  }

  return url;
};
