import { NewSubFormButton } from 'components/Forms/Buttons/SubFormButtons';
import { Section } from 'components/Section/Section.component';
import { _isEqual } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { PeriodForm } from 'pages/ObatMenu/Calendars/PeriodForm';
import React, { PureComponent, ReactNode } from 'react';
import { IPeriod } from 'types/Obat/Period';
import { $t } from 'utils/functions/translate';

import { IProps } from './PeriodsFormSection.container';

interface IState {
  newPeriodFormOpen: boolean;
  newPeriodNumber: number;
}

export class PeriodsFormSection extends PureComponent<IProps, IState> {
  public state: IState = {
    newPeriodFormOpen: false,
    newPeriodNumber: 0,
  };

  public componentDidUpdate(prevProps: IProps): void {
    const { dayGroup } = this.props;

    if (dayGroup && !_isEqual(prevProps.dayGroup, dayGroup)) {
      this.setState({ newPeriodFormOpen: false });
    }
  }

  public render(): ReactNode {
    const { dayGroup } = this.props;
    const periods: IPeriod[] = dayGroup.periods_contents;

    return (
      <Section title={$t('periods')} marginTop={20}>
        {_map(periods, (period: IPeriod, index: number) => (
          <PeriodForm key={period.weak_ref} dayGroup={dayGroup} period={period} titleIndex={++index} />
        ))}
        {this.state.newPeriodFormOpen && (
          <PeriodForm dayGroup={dayGroup} close={this.closeNewPeriodForm} titleIndex={this.state.newPeriodNumber} />
        )}
        {!this.state.newPeriodFormOpen && (
          <NewSubFormButton onClick={this.onNewSubFormButtonClick} text={$t('addPeriod')} />
        )}
      </Section>
    );
  }

  private closeNewPeriodForm = (): void => {
    this.setState({ newPeriodFormOpen: false });
  };

  private onNewSubFormButtonClick = (): void => {
    const { dayGroup } = this.props;

    this.setState({
      newPeriodFormOpen: true,
      newPeriodNumber: _size(dayGroup.periods_contents) + 1,
    });
  };
}
