// tslint:disable:no-any
import { IObatResourceDeleteProps } from 'components/Forms/Buttons/FormDeleteButton/FormDeleteButton.container';
import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { IFormApiErrorsTranslationPath } from 'types/Errors';

import { FormButtons as Component } from './FormButtons.component';

interface IOwnProps {
  isSubmitting: boolean;
  formApiErrors?: IFormApiErrorsTranslationPath;
  obatResourceDeleteProps?: IObatResourceDeleteProps;
  formikProps: any;
  forceSubmitDisabled?: (formikProps: any) => boolean;
  forceSubmitEnabled?: (formikProps: any) => boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const mapStateToProps = (_rootState: IRootState) => {
  return {};
};

const mapDispatchToProps = {};

export const FormButtons = connect(mapStateToProps, mapDispatchToProps)(Component);
