import { IOrganizationListed } from 'types/Organization';
import { IUserBase } from 'types/User';

import { IMeActions, UPDATE_MY_ORGANIZATIONS_ACTION, UPDATE_MY_USER_ACTION } from './actions';

export interface IMeState {
  organizations: IOrganizationListed[];
  user?: IUserBase;
}

export const initialState = {
  organizations: [],
  user: undefined,
};

export const meReducer = (state: IMeState = initialState, action: IMeActions): IMeState => {
  switch (action.type) {
    case UPDATE_MY_ORGANIZATIONS_ACTION:
      return {
        ...state,
        organizations: action.payload.organizations,
      };

    case UPDATE_MY_USER_ACTION:
      return {
        ...state,
        user: action.payload.user,
      };

    default:
      return state;
  }
};
