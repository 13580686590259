import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { IScript } from 'types/Obat/Script';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { ScriptDetail } from '../ScriptDetail';
import { ScriptForm } from '../ScriptForm';

export class ScriptsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'scripts'}
        resourceClass={orc.script}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={ScriptForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 5 },
    {
      id: 'type',
      resourceField: 'type',
      withFilterOptions: true,
      gridItemSize: 3,
      renderCustomText: (script: IScript) => $tEnums(script.type),
    },
    { id: 'geometriesFilter', gridItemSize: 4 },
  ];

  private renderCardColumnData = (script: IScript, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return script.ref;
      case 'type':
        return renderTextValueElseDash(script.type);
      case 'geometriesFilter':
        return renderTextValueElseDash(script.filter_geometries);
    }
  };

  private renderCardDetail = (script: IScript): ReactNode => {
    return <ScriptDetail script={script} />;
  };
}
