import { ILogoutModalActions, UPDATE_LOGOUT_MODAL_CAUSE_ACTION, UPDATE_LOGOUT_MODAL_TIMER_ACTION } from './actions';

export interface ILogoutModalState {
  timer: number;
  cause: string; // 'expired' | 'kickedOut';
}

export const initialState = {
  timer: 0,
  cause: 'expired',
};

export const logoutModalReducer = (
  state: ILogoutModalState = initialState,
  action: ILogoutModalActions
): ILogoutModalState => {
  switch (action.type) {
    case UPDATE_LOGOUT_MODAL_TIMER_ACTION:
      const { timer } = action.payload;

      return timer > 0 ? { ...state, timer } : initialState;

    case UPDATE_LOGOUT_MODAL_CAUSE_ACTION:
      return { ...state, cause: action.payload.cause };

    default:
      return state;
  }
};
