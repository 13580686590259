import { createMuiTheme } from '@material-ui/core/styles';

import theme from './theme';

export default createMuiTheme({
  overrides: {
    MuiMenuItem: {
      root: {
        height: '10px',
      },
    },
    MuiInputLabel: {
      formControl: {
        zIndex: 1,
        fontSize: '14px',
        transform: 'translate(8px, 10px) scale(1)',
        color: theme.colors.formLabel,
        pointerEvents: 'none',
      },
      shrink: {
        transform: 'translate(8px, 0px) scale(0.75)',
      },
    },
    MuiFormLabel: {
      root: {
        color: theme.colors.formLabel,
        fontSize: '14px',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: '12px',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '10.5px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '14px',
      },
    },
    MuiInput: {
      root: {
        backgroundColor: theme.colors.input.filled,
        borderBottomColor: theme.colors.input.filled,
        borderRadius: theme.borderRadius.small,
        '&&&&:hover': {
          backgroundColor: theme.colors.input.hover,
        },
      },
      formControl: {
        marginTop: '0 !important',
      },
      input: {
        padding: '22px 10px 8px',
      },
      multiline: {
        padding: '0 !important',
      },
      underline: {
        '&:before': {
          borderBottomColor: theme.colors.transparent,
        },
        '&&&&:hover:before': {
          borderBottomColor: theme.colors.transparent,
        },
        '&&&&:after': {
          borderBottomColor: theme.colors.transparent,
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: 'white',
      },
      root: {
        textTransform: 'none',
      },
    },
    MuiDrawer: {
      docked: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        zIndex: 1100,
      },
      paperAnchorDockedLeft: {
        backgroundColor: theme.colors.menu.primary,
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex',
        flex: 1,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          borderLeft: `3px solid ${theme.colors.main}`,
          backgroundColor: theme.colors.menu.secondary,
          paddingLeft: '12px',

          '& svg, span': {
            color: theme.colors.main,
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: theme.colors.menu.item,
        margin: '4px 12px 4px 0',
        minWidth: 'unset',
      },
    },
    MuiListItemText: {
      primary: {
        color: theme.colors.menu.item,
      },
      root: {
        paddingRight: 0,
      },
    },
    MuiTableCell: {
      head: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '14px',
        minWidth: '40px',
        backgroundColor: 'white',
        /* position: 'sticky',
        top: 0,
        zIndex: 5, */
      },
      body: {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    MuiTableRow: {
      root: {
        tableLayout: 'auto',
        width: '100%',
      },
    },
    MuiTable: {
      root: {
        height: '36px',
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: theme.colors.main,
        },
      },
    },
    MuiDialogActions: {
      root: {
        margin: `16px 12px`,
        justifyContent: 'space-around',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '12px',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '14px',
      },
      h6: {
        fontSize: '18px',
      },
    },
    MuiDialogTitle: {
      root: {
        wordBreak: 'break-all',
        padding: '8px 32px',
        minHeight: '24px',
      },
    },
    // @ts-ignore Components of @material-ui/lab are not in the theme definition
    MuiToggleButton: {
      root: {
        lineHeight: 1.4,
        flex: 1,
        justifyContent: 'center',
        textTransform: 'capitalize',
        color: theme.colors.formLabel,
        borderRight: `1px solid ${theme.colors.input.disabled}`,
        '&$selected': {
          backgroundColor: theme.colors.main,
          color: 'white',
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        borderRadius: 2,
        display: 'flex',
        border: `1px solid ${theme.colors.transparent}`,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
    },
  },
  palette: {
    primary: {
      main: theme.colors.main,
    },
    secondary: {
      main: theme.colors.menu.primary,
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro',
    useNextVariants: true,
  },
});
