import { _join } from 'libs/lodash';
import { IDetailRequestAction, IGenericRequestAction } from 'utils/strings/requestActions';

export const getGenericApiRequestKey = (
  genericRequestAction: IGenericRequestAction,
  // tslint:disable-next-line:no-any
  resourceClass: any,
  id: string | undefined = undefined,
  withLoading: boolean | undefined = true
): string => {
  let wordsList: string[] = [genericRequestAction, resourceClass];
  wordsList = id ? [...wordsList, id] : wordsList;
  wordsList = withLoading ? wordsList : [...wordsList, 'noLoading'];

  return _join(wordsList, ':');
};

export const getDetailApiRequestKey = (
  detailRequestAction: IDetailRequestAction,
  id: string,
  withLoading: boolean | undefined = true
): string => {
  let wordsList: string[] = [detailRequestAction, id];
  wordsList = withLoading ? wordsList : [...wordsList, 'noLoading'];

  return _join(wordsList, ':');
};
