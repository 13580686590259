import { Grid } from '@material-ui/core';
import { Button } from 'components/Buttons';
import { ModalFrame } from 'components/Modals/ModalFrame';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import { $t } from 'utils/functions/translate';

import { IProps } from './UserCreationModal.container';

export class UserCreationModal extends PureComponent<IProps> {
  public render(): ReactNode {
    const { closeUserCreationModal, isOpen, email } = this.props;

    const Actions = (
      <Grid container spacing={3} justify={'center'} alignItems={'center'}>
        <Grid item>
          <Button text={$t('cancel')} onClick={closeUserCreationModal} bgColor={theme.colors.warning} noShadow />
        </Grid>
        <Grid item>
          <Button text={$t('createAndInvite')} onClick={this.onCreateAccountClick} noShadow />
        </Grid>
      </Grid>
    );

    return (
      <ModalFrame
        isOpen={isOpen}
        onClose={closeUserCreationModal}
        Title={$t('userDoesNotExistTitle')}
        Content={$t('userDoesNotExistContent', { email })}
        maxWidth={'md'}
        Actions={Actions}
      />
    );
  }

  private onCreateAccountClick = () => {
    const { closeUserCreationModal, openCreateAccountForm } = this.props;

    closeUserCreationModal();
    openCreateAccountForm();
  };
}
