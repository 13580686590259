import { _forEach } from 'libs/lodash';
import { IDegreeDaysData } from 'redux/restResources/detail/weather/reducer';
import { IDegreeDaysTableData } from 'redux/restResources/detail/weather/selectors';

export const formatDegreeDaysTableData = (degreeDaysData: IDegreeDaysData): IDegreeDaysTableData => {
  const degreeDaysTableData: IDegreeDaysTableData = [];
  _forEach(degreeDaysData.months, (_month: string, position: number): void => {
    degreeDaysTableData.push({
      month: degreeDaysData.months[position],
      hdd: degreeDaysData.hdd[position],
      cdd: degreeDaysData.cdd[position],
    });
  });

  degreeDaysTableData.push({
    month: 'Total',
    hdd: degreeDaysData.sumHdd,
    cdd: degreeDaysData.sumCdd,
  });

  return degreeDaysTableData;
};
