import { Button, CenterButtons } from 'components/Buttons';
import React, { PureComponent, ReactNode } from 'react';
import { X } from 'react-feather';
import theme from 'style/theme';
import { $t } from 'utils/functions/translate';

interface IProps {
  onClick: () => void;
  text: string;
}

export class StopReorderingButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { onClick } = this.props;

    return (
      <CenterButtons marginTop={0}>
        <Button
          onClick={onClick}
          color={theme.colors.warning}
          bgColor={theme.colors.transparent}
          Icon={<X />}
          text={$t('cancel')}
          noShadow
        />
      </CenterButtons>
    );
  }
}
