import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import { _isEmpty } from 'libs/lodash';
import { ConstructionForm } from 'pages/ObatMenu/Constructions/ConstructionForm';
import { ConstructionPlot } from 'pages/ObatMenu/Constructions/ConstructionPlot';
import { LayersCard } from 'pages/ObatMenu/Constructions/LayersCard/LayerCard.component';
import React, { PureComponent, ReactNode } from 'react';
import { IConstruction } from 'types/Obat/Construction';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { renderTextFloatElseDash } from 'utils/functions/renderValue/renderValue';
import { $t, $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

export class ConstructionsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'constructions'}
        resourceClass={orc.construction}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={ConstructionForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 2 },
    {
      id: 'surfaceCategory',
      resourceField: 'surface_category',
      gridItemSize: 2,
      withFilterOptions: true,
      renderCustomText: (construction: IConstruction) => $tEnums(construction.surface_category),
    },
    { id: 'u', resourceField: 'rt2012_u', title: $t('uWithUnit'), gridItemSize: 1 },
    { id: 'construction', gridItemSize: 7, centerTitle: true },
  ];

  private renderCardColumnData = (construction: IConstruction, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return construction.ref;
      case 'surfaceCategory':
        return $tEnums(renderTextValueElseDash(construction.surface_category));
      case 'u':
        return renderTextFloatElseDash(construction.rt2012_u);
      case 'construction':
        return <ConstructionPlot construction={construction} />;
    }
  };

  private renderCardDetail = (construction: IConstruction): ReactNode => {
    if (!_isEmpty(construction.layers_contents)) {
      return <LayersCard layers={construction.layers_contents} />;
    }
  };
}
