import { _get } from 'libs/lodash';
import { IDrawerWidth } from 'utils/configs/drawerWidths';
import { fdw } from 'utils/configs/drawerWidths';

export const getDrawerWidth = (drawerWidth: IDrawerWidth): number => {
  if (typeof drawerWidth === 'number') {
    return drawerWidth;
  } else {
    return _get(fdw, drawerWidth);
  }
};
