import { _concat } from 'libs/lodash';
import { IPlotTimeSeries } from 'types/Series';
import { applySpanOffset } from 'utils/functions/weathers/applySpanOffset';

export const applyStampOffset = (series: IPlotTimeSeries): IPlotTimeSeries => {
  const spanOffsetSeries = applySpanOffset(series);
  const { values } = spanOffsetSeries;

  const nextDayFirstValue = values[23];
  values.pop();

  return { ...spanOffsetSeries, values: _concat(nextDayFirstValue, values) };
};
