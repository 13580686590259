import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { selectObatResourceCreationPositionOptions } from 'redux/obatResources/selectors';
import { IRootState } from 'redux/reducer';
import { orc } from 'utils/strings/resourceClasses';

import { OutputZoneGroupForm as OutputZoneGroupFormComponent } from './OutputZoneGroupForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const outputZoneGroupToEdit = selectFormInitialResourceFromTable(orc.output_zone_group)(rootState);

  return {
    outputZoneGroupToEdit,
    createPositionOptions: selectObatResourceCreationPositionOptions(orc.output_zone_group)(rootState),
  };
};

const mapDispatchToProps = {};

export const OutputZoneGroupForm = connect(mapStateToProps, mapDispatchToProps)(OutputZoneGroupFormComponent);
