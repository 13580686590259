import theme from 'style/theme';
import styled from 'styled-components';

interface ICardHeaderProps {
  isDisabled?: boolean;
  marginBottom?: string;
}

export const CardHeader = styled.div`
  font-size: 115%;
  border-bottom: solid 1px ${theme.colors.divider};
  padding-bottom: 10px;
  margin-bottom: ${(props: ICardHeaderProps) => (props.marginBottom ? props.marginBottom : '16px')};
  color: ${(props: ICardHeaderProps): string => (props.isDisabled ? theme.colors.input.disabled : '')};
`;

export const SpaceBetweenCardHeader = styled(CardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
