import { _forEach, _includes, _keys, _map, _size } from 'libs/lodash';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';
import {
  IObatMetaIndexes,
  IObatMetaLink,
  IObatMetaLinksByTableRef,
  IObatMetaTableCommitmentsMap,
} from 'types/Obat/ObatMeta';
import { IObatResource } from 'types/Obat/ObatResource';

import { obatContentsCommitmentsMap, obatContentsLinksMap } from './obatMaps';

export const createObatContents = (rawObatData: IObatData, obatId: string): IObatContents => {
  const obatData = { ...rawObatData };

  // initialize meta keys
  const indexes: IObatMetaIndexes = {};
  const linksBySource: IObatMetaLinksByTableRef = {};
  const linksByTarget: IObatMetaLinksByTableRef = {};
  _map(_keys(rawObatData), (tableRef: keyof IObatData): void => {
    indexes[tableRef] = {};
    linksBySource[tableRef] = {};
    linksByTarget[tableRef] = {};
  });

  // iter tableRef, tableContents
  _forEach(obatData, (contents: IObatResource[], sourceTableRef: string): void => {
    // skip magic tables
    if (sourceTableRef.slice(0, 2) === '__') {
      return;
    }

    // initialize meta
    indexes[sourceTableRef] = {};
    linksBySource[sourceTableRef] = {};

    // retrieve table links _map
    const tableLinksMap = obatContentsLinksMap[sourceTableRef] ? obatContentsLinksMap[sourceTableRef] : {};

    // iter contents of sourceTableRef
    _forEach(contents, (content: IObatResource): void => {
      // apply empty commitments
      content.commitments = { update: {}, delete: {} };

      // store source id
      const sourceId = content.id;

      // complete indexes
      indexes[sourceTableRef][sourceId] = _size(indexes[sourceTableRef]);

      // store if link
      _forEach(tableLinksMap, (targetTableRef: keyof IObatData, sourceField: string): void => {
        // store target id
        const targetId = content[sourceField];

        // leave if no pointed restResource
        if (!targetId) {
          return;
        }

        // create and store link
        const link: IObatMetaLink = {
          // @ts-ignore
          sourceTableRef,
          sourceId,
          sourceField,
          targetTableRef,
          targetId,
        };
        // store in by source
        if (!linksBySource[sourceTableRef][sourceId]) {
          linksBySource[sourceTableRef][sourceId] = [];
        }
        linksBySource[sourceTableRef][sourceId].push(link);

        // store in by target
        if (!linksByTarget[targetTableRef]) {
          linksByTarget[targetTableRef] = {};
        }
        if (!linksByTarget[targetTableRef][targetId]) {
          linksByTarget[targetTableRef][targetId] = [];
        }
        linksByTarget[targetTableRef][targetId].push(link);
      });
    });
  });

  // set commitments

  // iter table commitments _map
  _forEach(
    obatContentsCommitmentsMap,
    (tableCommitmentsMap: IObatMetaTableCommitmentsMap, targetTableRef: string): void => {
      // iter target table links
      _forEach(
        linksByTarget[targetTableRef],

        // iter target record links
        (links: IObatMetaLink[]): void => {
          // iter links
          _forEach(links, (link: IObatMetaLink): void => {
            // retrieve target content
            const targetContentIndex = indexes[targetTableRef][link.targetId];
            // @ts-ignore
            const targetContent = obatData[targetTableRef][targetContentIndex];

            // add deletion if exists commitments
            if (_includes(tableCommitmentsMap.delete, link.sourceTableRef)) {
              if (!targetContent.commitments.delete[link.sourceTableRef]) {
                targetContent.commitments.delete[link.sourceTableRef] = [];
              }
              targetContent.commitments.delete[link.sourceTableRef].push(link.sourceId);
            }

            // add update commitments
            _forEach(tableCommitmentsMap.update, (committedToTables: string[], field: string): void => {
              if (_includes(committedToTables, link.sourceTableRef)) {
                if (!targetContent.commitments.update[field]) {
                  targetContent.commitments.update[field] = {};
                }
                if (!targetContent.commitments.update[field][link.sourceTableRef]) {
                  targetContent.commitments.update[field][link.sourceTableRef] = [];
                }
                targetContent.commitments.update[field][link.sourceTableRef].push(link.sourceId);
              }
            });
          });
        }
      );
    }
  );

  return {
    id: obatId,
    data: obatData,
    meta: {
      indexes,
      linksBySource,
      linksByTarget,
    },
  };
};
