import { _includes } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { _map } from 'libs/lodash';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { startApiRequestAction } from 'redux/apiRequests/actions';
import { fetchObatContentsSaga } from 'redux/obatResources/sagas';
import { selectObatResources } from 'redux/obatResources/selectors';
import {
  downloadSimulationMetaDataSaga,
  fetchObatVariantsSaga,
} from 'redux/restResources/detail/simulationGroup/sagas';
import { selectSimulation } from 'redux/restResources/detail/simulationGroup/selectors';
import { listRestResourcesSaga } from 'redux/restResources/generic/sagas';
import { selectRouteResource } from 'redux/routing/selectors';
import {
  FETCH_TABLE_INITIAL_DATA_SAGA_ACTION,
  FETCH_TABLE_UPDATED_DATA_SAGA_ACTION,
  IFetchTableInitialDataSagaAction,
  IFetchTableUpdatedDataSagaAction,
  initializeTableAction,
  updateTableFullDataAction,
} from 'redux/table/actions';
import { selectTableResourceClass } from 'redux/table/selectors';
import { selectVariantContextId } from 'redux/variantContext/selectors';
import { ISimulationSeries } from 'types/SimulationGroup/Simulation';
import { getGenericApiRequestKey } from 'utils/functions/getApiRequestKey';
import { as } from 'utils/strings/alertStatus';
import { gra } from 'utils/strings/requestActions';
import { IApiResourceClass } from 'utils/strings/resourceClasses';
import { orc, rrc } from 'utils/strings/resourceClasses';
import { apiRequestCallbackSuccessAction } from './../apiRequests/actions';

/* _____ fetchTableDataSaga _____  */

export function* fetchTableDataSaga(resourceClass: IApiResourceClass, withLoading: boolean): SagaIterator {
  let tableData = [];
  if (resourceClass === rrc.series) {
    /* simulation should have been updated before calling this saga */
    const simulation = yield select(selectSimulation);
    if (simulation && simulation.status !== as.empty) {
      const simulationMetadata = yield call(downloadSimulationMetaDataSaga, {
        simulationId: simulation.id,
      });
      tableData = _map(simulationMetadata.series, (series: ISimulationSeries) => ({
        ...series,
        simulation,
      }));
    }
  } else if (_includes(_keys(rrc), resourceClass)) {
    const routeOrganization = yield select(selectRouteResource(rrc.organization));
    const routeProject = yield select(selectRouteResource(rrc.project));

    let queryParams = {};

    if (!!routeOrganization) {
      queryParams = { organization: routeOrganization.id };
    } else if (!!routeProject) {
      if (resourceClass !== rrc.simulation) {
        queryParams = { project: routeProject.id };
      }
    }

    // @ts-ignore
    tableData = yield call(listRestResourcesSaga, resourceClass, undefined, queryParams, withLoading);

    /** FIXME: When backend pagination becomes necessary:
     *  const withPagination = true;
     *  { data, recordsFiltered, recordsTotal } = yield call(listRestResourcesSaga, resourceClass, undefined, queryParams, withLoading, withPagination);
     *  if ( recordsFiltered < recordsTotal ) {
     *     tablePagination = { recordsFiltered, recordsTotal }
     *     return { tableData: data, tablePagination } (the use tablePagination in fetchTableInitialDataSaga and fetchTableUpdatedDataSaga)
     *  }
     *  In all other cases,
     *  return { tableData }
     */

    if (resourceClass === rrc.simulation) {
      /** also fetch variants of each obats  */
      /** fake apiRequestKey to keep the loader on screen... it's ugly but I'm running out of time right now */
      const simulationsListApiRequestKey = getGenericApiRequestKey(gra.list, rrc.simulation, undefined, withLoading);
      yield put(startApiRequestAction(simulationsListApiRequestKey, true));
      const fetchedObatIds = new Set();
      for (const simulation of tableData) {
        const obatId = simulation.obat_id;
        if (!fetchedObatIds.has(obatId)) {
          yield call(fetchObatVariantsSaga, { obatId });
          fetchedObatIds.add(obatId);
        }
      }
      yield put(apiRequestCallbackSuccessAction(simulationsListApiRequestKey));
    }
  } else if (_includes(_keys(orc), resourceClass)) {
    const routeObat = yield select(selectRouteResource(rrc.obat));
    const variantContextId = yield select(selectVariantContextId);
    yield call(fetchObatContentsSaga, routeObat.id, withLoading, variantContextId);
    // @ts-ignore
    tableData = yield select(selectObatResources(resourceClass));
  }

  return tableData;
}

/* _____ fetchTableInitialDataSaga _____  */

export function* fetchTableInitialDataSaga(payload: IFetchTableInitialDataSagaAction['payload']): SagaIterator {
  const { tableFilterParams, resourceClass, parentId } = payload;
  const tableData = yield call(fetchTableDataSaga, resourceClass, true);
  yield put(initializeTableAction(tableData, tableFilterParams, resourceClass, parentId));
}

export function* fetchTableInitialDataActionSaga(action: IFetchTableInitialDataSagaAction): SagaIterator {
  yield call(fetchTableInitialDataSaga, action.payload);
}

/* _____ fetchTableUpdatedDataSaga _____  */

export function* fetchTableUpdatedDataSaga(payload: IFetchTableUpdatedDataSagaAction['payload']): SagaIterator {
  const { resourceClass } = payload;

  const currentResourceClass = yield select(selectTableResourceClass);
  if (resourceClass === currentResourceClass) {
    const tableData = yield call(fetchTableDataSaga, resourceClass, false);
    yield put(updateTableFullDataAction(tableData));
  }
}

export function* fetchTableUpdatedDataActionSaga(action: IFetchTableUpdatedDataSagaAction): SagaIterator {
  yield call(fetchTableUpdatedDataSaga, action.payload);
}

export function* tableActionsSagas(): Generator {
  yield takeLatest(FETCH_TABLE_INITIAL_DATA_SAGA_ACTION, fetchTableInitialDataActionSaga);
  yield takeLatest(FETCH_TABLE_UPDATED_DATA_SAGA_ACTION, fetchTableUpdatedDataActionSaga);
}
