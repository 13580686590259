import { ModalFrame } from 'components/Modals/ModalFrame';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { IProps } from './RestResourceCommitmentsModal.container';

export class RestResourceCommitmentsModal extends PureComponent<IProps> {
  public render = (): ReactNode => {
    const { isOpen, commitments, commitmentTitle, closeRestResourceCommitmentsModal } = this.props;

    const Content = (
      <CommitmentsDiv>
        {_map(
          commitments,
          (restResourceRef: string): ReactNode => {
            return <div key={restResourceRef}>{restResourceRef}</div>;
          }
        )}
      </CommitmentsDiv>
    );

    return (
      <ModalFrame
        isOpen={isOpen}
        onClose={closeRestResourceCommitmentsModal}
        maxWidth={'lg'}
        Title={commitmentTitle}
        Content={Content}
      />
    );
  };
}

const CommitmentsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
