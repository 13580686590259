import { Action } from 'redux';
import { IObatResourceClass } from 'utils/strings/resourceClasses';

export const OPEN_PLOTS_MODAL_ACTION = 'OPEN_PLOTS_MODAL_ACTION';
export interface IOpenPlotsModalAction extends Action<'OPEN_PLOTS_MODAL_ACTION'> {
  payload: {
    resourceId: string;
    resourceClass: IObatResourceClass;
  };
}

export const openPlotsModalAction = (resourceId: string, resourceClass: IObatResourceClass): IOpenPlotsModalAction => ({
  type: OPEN_PLOTS_MODAL_ACTION,
  payload: {
    resourceId,
    resourceClass,
  },
});

export const CLOSE_PLOTS_MODAL_ACTION = 'CLOSE_PLOTS_MODAL_ACTION';
export interface IClosePlotsModalAction extends Action<'CLOSE_PLOTS_MODAL_ACTION'> {}
export const closePlotsModalAction = (): IClosePlotsModalAction => ({
  type: CLOSE_PLOTS_MODAL_ACTION,
});

export type IPlotsModalActions = IOpenPlotsModalAction | IClosePlotsModalAction;
