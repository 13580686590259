import { IPermission } from 'types/Permissions';

export const cumulatePermissions = (field: string, isSelected: boolean, formPermission: IPermission): IPermission => {
  let { can_read, can_write, can_admin } = formPermission;

  switch (field) {
    case 'can_read':
      can_read = isSelected;
      if (!isSelected) {
        can_write = false;
        can_admin = false;
      }
      break;
    case 'can_write':
      can_write = isSelected;
      if (isSelected) {
        can_read = true;
      }
      if (!isSelected) {
        can_admin = false;
      }
      break;
    case 'can_admin':
      can_admin = isSelected;
      if (isSelected) {
        can_read = true;
        can_write = true;
      }
      break;
  }

  return {
    can_read,
    can_write,
    can_admin,
  };
};
