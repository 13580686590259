import theme from 'style/theme';
import styled from 'styled-components';

export const ConfigCardTable = styled.table`
  width: 100%;
  border-spacing: 0;
`;

interface ICardSectionRowProps {
  isLast?: boolean;
  isFirst?: boolean;
}

export const ConfigCardSectionRow = styled.tr`
  td {
    padding-top: ${(props: ICardSectionRowProps): string => (props.isFirst ? '0' : '16px')};
    padding-bottom: ${(props: ICardSectionRowProps): string => (props.isLast ? '0' : '16px')};
    border-bottom: ${(props: ICardSectionRowProps): string =>
      `solid 1px ${props.isLast ? 'transparent' : theme.colors.divider}`};
  }
`;

export const ConfigCardSectionTitleCell = styled.td`
  padding-right: 32px;
  font-weight: bold;
`;

export const ConfigCardFieldNamesCell = styled.td`
  padding-right: 24px;
`;

export const ConfigCardFieldValuesCell = styled.td`
  font-weight: ${theme.fonts.weight.light};
`;

export const ConfigCardField = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;
