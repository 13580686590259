import theme from 'style/theme';

export const getSeatColor = (projectOrOrganization: {
  is_activated: boolean;
  is_taking_up_seat: boolean;
  is_leaving_seat: boolean;
}): string => {
  const { is_activated, is_taking_up_seat, is_leaving_seat } = projectOrOrganization;

  return is_activated && !is_taking_up_seat
    ? theme.colors.seat.gratis
    : is_leaving_seat
    ? theme.colors.seat.leaving
    : is_taking_up_seat
    ? theme.colors.seat.taken
    : theme.colors.seat.free;
};
