import { CardHeader, SimpleCard } from 'components/Cards';
import { LegendItem } from 'components/PlotLegend';
import React, { PureComponent, ReactNode } from 'react';
import { getDayGroupColor } from 'utils/functions/obatResources/calendar/calendarHeatmap/getColorByLegendItem';

import { PeriodsDetails } from '../PeriodsDetails';

import { IProps } from './DayGroupCard.container';

export class DayGroupCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { dayGroup } = this.props;

    return (
      <SimpleCard>
        <CardHeader>
          <LegendItem
            key={dayGroup.weak_ref}
            dotColor={getDayGroupColor(dayGroup.sort_index)}
            text={dayGroup.weak_ref}
          />
        </CardHeader>
        <PeriodsDetails periods={dayGroup.periods_contents} />
      </SimpleCard>
    );
  }
}
