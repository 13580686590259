import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { _merge } from 'libs/lodash';
import theme from 'style/theme';
import styled from 'styled-components';

export const secondaryDrawerTheme = (muiTheme: Theme | null): Theme =>
  createMuiTheme(
    _merge({}, muiTheme, {
      overrides: {
        MuiListItem: {
          root: {
            paddingTop: '2px',
            paddingBottom: '2px',
            '&$selected': {
              borderLeft: 0,
              backgroundColor: theme.colors.menu.selectedItem,
              borderRadius: theme.borderRadius.small,
              paddingLeft: '4px',
            },
          },
          gutters: {
            paddingLeft: '4px',
            paddingRight: '4px',
          },
        },
        MuiListItemText: {
          primary: {
            fontSize: '14px',
          },
        },
        MuiDrawer: {
          paperAnchorDockedLeft: {
            backgroundColor: theme.colors.menu.secondary,
          },
        },
      },
    })
  );

export const ListItemContainer = styled.div`
  margin: 4px 16px;
`;

export const StyledDivider = styled(Divider)`
  background-color: ${theme.colors.divider};
  opacity: 0.4;
` as typeof Divider;

export const StyledList = styled(List)`
  margin-top: 40px;
` as typeof List;
