import { _get, _has, _map, _omit, _reduce, _sortBy } from 'libs/lodash';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';
import { IUse } from 'types/Obat/Use';

interface IUsesByRef {
  [ref: string]: IUse;
}

export const enrichUses = (obatContents: IObatContents): IObatData => {
  const { uses: rawUses } = obatContents.data;

  const _usesByRef = _reduce(
    [
      'consumption_cooling_emitter_thermal',
      'consumption_heating_emitter_thermal',
      'consumption_heating_emitter_auxiliary_fan',
      'consumption_cooling_emitter_auxiliary_fan',
      'consumption_heating_emitter_auxiliary_other',
      'consumption_cooling_emitter_auxiliary_other',
      'consumption_ahu_heating_coil_thermal',
      'consumption_ahu_cooling_coil_thermal',
      'consumption_ahu_auxiliary_fan',
      'consumption_ahu_heating_auxiliary_other',
      'consumption_ahu_cooling_auxiliary_other',
      'consumption_dhw_emitter_thermal',
      'consumption_dhw_emitter_auxiliary',
      'consumption_electric_equipment',
      'consumption_electric_light',
      // 'production_dhw_renewable',
      // 'production_photovoltaic',
    ],
    (usesByRef: IUsesByRef, subuseKey: string): IUsesByRef => {
      const useRef = _get(rawUses[0], subuseKey);
      // create use if it does not exist
      if (!_has(usesByRef, useRef)) {
        usesByRef = {
          ...usesByRef,
          [useRef]: {
            id: useRef,
            ref: useRef,
            subuses: [],
            commitments: {
              update: {},
              delete: {},
            },
          },
        };
      }
      // add subuse
      usesByRef[useRef].subuses = [...usesByRef[useRef].subuses, subuseKey];

      return usesByRef;
    },
    {}
  );

  // create list
  let uses = _map(
    _usesByRef,
    (use: IUse): IUse => {
      return use;
    }
  );

  // sort
  uses = _sortBy(uses, ['ref']);

  // @ts-ignore
  return _omit({ ...obatContents.data, use: uses }, ['uses']);
};
