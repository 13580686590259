// tslint:disable:no-any

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Datatable } from 'components/Datatable';
import { OplusLoader } from 'components/Loader';
import { _get } from 'libs/lodash';
import { _keys } from 'libs/lodash';
import { _map } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { IProjectionSection } from 'redux/restResources/detail/obat/reducer';
import theme from 'style/theme';
import styled from 'styled-components';
import { pickColumnFilterParams, pickDatatableColumnParams } from 'utils/functions/pickColumnParams';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';

import { IProps } from './ProjectionTable.container';

export class ProjectionTable extends PureComponent<IProps> {
  public render(): ReactNode {
    const { projectionTableData, tableResources, projectionSection } = this.props;

    const projectionSectionTitles = {
      zones: 'zones',
      surfaces: 'surfaces',
      subsurfaces: 'windows',
    };

    return (
      <>
        {!projectionTableData && <OplusLoader />}
        {projectionTableData && (
          <>
            <TabsContainer position="static">
              <Tabs
                textColor="primary"
                indicatorColor="primary"
                value={projectionSection}
                variant="scrollable"
                scrollButtons="off"
                onChange={this.onSectionChange}
              >
                {_map(_keys(projectionTableData), (section: string) => {
                  return projectionTableData[section] ? (
                    <StyledTab key={section} label={$t(_get(projectionSectionTitles, section))} value={section} />
                  ) : null;
                })}
              </Tabs>
            </TabsContainer>
            <TableContainer>
              <Datatable
                tableColumnsParams={pickDatatableColumnParams(getProjectionColumnsParams(projectionSection))}
                tableResources={tableResources}
                rowIdKey={'ref'}
              />
            </TableContainer>
          </>
        )}
      </>
    );
  }

  // tslint:disable-next-line:no-any
  private onSectionChange = (_e: any, newSection: IProjectionSection) => {
    const { updateProjectionSection } = this.props;
    updateProjectionSection(newSection);
    this.initializeSectionData(newSection);
  };

  private initializeSectionData = (section: IProjectionSection) => {
    const { initializeTable, projectionTableData } = this.props;

    if (projectionTableData) {
      const sectionData = projectionTableData[section];
      const sectionColumnsParams = getProjectionColumnsParams(section);
      const sectionFilterColumnsParams = pickColumnFilterParams(sectionColumnsParams);
      initializeTable(sectionData, sectionFilterColumnsParams);
    }
  };
}

/* This function gets also called in fetchProjectionDataSaga */
export const getProjectionColumnsParams = (section: IProjectionSection): ITableColumnParams[] => {
  switch (section) {
    case 'surfaces':
      return [
        {
          resourceField: 'ref',
          id: 'surface',
          renderCustomDOM: (surface: any) => {
            const { surface_group, construction, ref } = surface;

            return !surface_group || !construction ? <RedText>{ref}</RedText> : ref;
          },
        },
        { resourceField: 'inner_zone_ref', id: 'innerZone' },
        { resourceField: 'outer_zone_ref', id: 'outerZone' },
        {
          resourceField: 'category',
          id: 'category',
          withFilterOptions: true,
          renderCustomText: (surface: any) => $tEnums(surface.category),
        },
        { resourceField: 'orientation', id: 'orientation' },
        { resourceField: 'surface_group', id: 'surfaceGroup', withFilterOptions: true },
        { resourceField: 'construction', id: 'construction', withFilterOptions: true },
        { resourceField: 'inner_zone_tag_story', id: 'story' },
        { resourceField: 'inner_zone_tag_zg1', id: 'Tag 1' },
        { resourceField: 'inner_zone_tag_zg2', id: 'Tag 2' },
        { resourceField: 'inner_zone_tag_zg3', id: 'Tag 3' },
      ];

    case 'subsurfaces':
      return [
        {
          resourceField: 'ref',
          id: 'window',
          renderCustomDOM: (subsurface: any) => {
            const { sg_ref, sg_opening, ref } = subsurface;

            return !sg_ref || !sg_opening ? <RedText>{ref}</RedText> : ref;
          },
        },
        { resourceField: 'surface_ref', id: 'surface' },
        {
          resourceField: 'surface_category',
          id: 'surfaceCategory',
          withFilterOptions: true,
          renderCustomText: (subsurface: any) => $tEnums(subsurface.surface_category),
        },
        { resourceField: 'sg_ref', id: 'surfaceGroup', withFilterOptions: true },
        { resourceField: 'sg_opening', id: 'opening', withFilterOptions: true },
        { resourceField: 'surface_inner_zone_ref', id: 'innerZone' },
      ];

    case 'zones':
      return [
        { resourceField: 'ref', id: 'zone' },
        { resourceField: 'activity_zone_group', id: 'activityZoneGroup', withFilterOptions: true },
        { resourceField: 'output_zone_group', id: 'outputZoneGroup', withFilterOptions: true },
        { resourceField: 'tag_story', id: 'story' },
        { resourceField: 'tag_zg1', id: 'Tag 1' },
        { resourceField: 'tag_zg2', id: 'Tag 2' },
      ];

    // case 'bridges':
    //   return [
    //     { resourceField: 'surface_a', id: 'Surface A', withFilterOptions: true },
    //     { resourceField: 'surface_b', id: 'Surface B', withFilterOptions: true },
    //     { resourceField: 'ref', id: 'bridge', withFilterOptions: true },
    //     { resourceField: 'psi', id: 'Psi' },
    //     { resourceField: 'length', id: 'length' },
    //   ];
  }
};

const TabsContainer = styled(AppBar)`
  margin-top: 16px;
  background-color: white;
` as typeof AppBar;

const TableContainer = styled.div`
  overflow: auto;
  margin: 10px -16px 0 -16px;
`;

const StyledTab = styled(Tab)`
  text-transform: unset;
  text-shadow: 0 0 0;
  letter-spacing: 0.15px;
` as typeof Tab;

const RedText = styled.span`
  color: ${theme.colors.error};
`;
