import { FormikProps } from 'formik';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { copyRestResourceSagaAction } from 'redux/restResources/generic/actions';
import { createRestResourceSagaAction, editRestResourceSagaAction } from 'redux/restResources/generic/actions';
import { IRestResource } from 'types/RestResource';
import { IRestResourceClass } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { RestResourceForm as Component } from './RestResourceForm.component';

interface IOwnProps<IResourceFormValues, IResourceRequestParams> {
  initialRestResource: IRestResource | undefined;
  renderForm: (formikProps: FormikProps<IResourceFormValues>) => ReactNode;
  getInitialFormValues: () => IResourceFormValues;
  getRequestParams?: (finalFormValues: IResourceFormValues) => IResourceRequestParams;
  getValidationSchema: () => yup.ObjectSchema /*<IResourceFormValues>*/;
  renderSubForm?: () => ReactNode;
  resourceClass: IRestResourceClass;
  // subFormResources?: IRestResourceClass[];
  forceSubmitDisabled?: (formikProps: FormikProps<IResourceFormValues>) => boolean;
  forceSubmitEnabled?: (formikProps: FormikProps<IResourceFormValues>) => boolean;
  getCreationSuccessLandingPageUrl?: (routeProjectId: string, createdGeometry: IRestResource) => string;
  isCopy?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps<IResourceFormValues, IResourceRequestParams> = IOwnProps<
  IResourceFormValues,
  IResourceRequestParams
> &
  IInjectedProps;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createRestResource: createRestResourceSagaAction,
  editRestResource: editRestResourceSagaAction,
  copyRestResource: copyRestResourceSagaAction,
};

export const getRestResourceForm = <IResourceFormValues, IResourceRequestParams>() => {
  return connect<IStateProps, IDispatchProps, IOwnProps<IResourceFormValues, IResourceRequestParams>, IRootState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component as new () => Component<IResourceFormValues, IResourceRequestParams>);
};
