import { Alert } from 'components/Alerts';
import { BottomCenterButtons, BottomRightButtons, Button, CenterButtons } from 'components/Buttons';
import { ITool, ToolsButton } from 'components/Buttons/ToolsButton/ToolsButton.component';
import { LogsConsole } from 'components/Consoles/LogsConsole';
import { OplusLoader } from 'components/Loader';
import { Page } from 'components/Page';
import { _includes } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import { Play } from 'react-feather';
import { monoSimulationGroupMenuPages } from 'routing/routes';
import { getRunTooltip } from 'utils/functions/simulationGroup/getRunTooltip';
import { isRunEnabled } from 'utils/functions/simulationGroup/isRunEnabled';
import { $t } from 'utils/functions/translate';
import { as } from 'utils/strings/alertStatus';
import { ps } from 'utils/strings/progressStatus';
import { rrc } from 'utils/strings/resourceClasses';
import { rm } from 'utils/strings/routingMenus';

import { IProps } from './MonitorMono.container';

export class MonitorMono extends PureComponent<IProps> {
  public componentDidMount = (): void => {
    const { refreshSimulationGroup } = this.props;

    refreshSimulationGroup(rrc.mono_simulation_group);
  };

  public render(): ReactNode {
    const { isWritePermissionMissing, routeMonoSimulationGroup, runSimulation, isSeatMissing } = this.props;

    const { starting, status } = routeMonoSimulationGroup;

    const tools = this.getTools();

    const simulateButton = (
      <Button
        Icon={<Play size={12} />}
        text={$t('simulate')}
        onClick={runSimulation}
        isDisabled={!isRunEnabled(routeMonoSimulationGroup)}
        tooltipProps={{ content: getRunTooltip(routeMonoSimulationGroup), placement: 'bottom' }}
        isWritePermissionMissing={isWritePermissionMissing}
        isSeatMissing={isSeatMissing}
      />
    );

    return (
      <Page
        pageTitle={$t('monitor')}
        routingMenu={rm.monoSimulationGroup}
        selectedPage={monoSimulationGroupMenuPages.monitorMono}
        renderAlert={this.renderAlert}
        withBottomButtons
      >
        {status === 'empty' && <CenterButtons isPulsing>{simulateButton}</CenterButtons>}
        {starting && <OplusLoader progress={ps.starting} />}
        {!starting && status !== 'empty' && (
          <>
            <LogsConsole />
            <BottomCenterButtons>{simulateButton}</BottomCenterButtons>
          </>
        )}
        {tools.length > 0 && (
          <BottomRightButtons>
            <ToolsButton tools={tools} />
          </BottomRightButtons>
        )}
      </Page>
    );
  }

  private getTools = (): ITool[] => {
    const { routeMonoSimulationGroup, simulation, isLogsScrollEnabled, toggleLogsScrollEnabled } = this.props;

    const { status } = routeMonoSimulationGroup;

    let tools: ITool[] = [];

    const isExportDisabled =
      (status === 'failed' && simulation && !_includes(['eplus', 'outputs'], simulation.fail_step)) ||
      status === as.server_error;

    const exportTooltipContent = isExportDisabled ? $t('noEplusOutputsAvailable') : undefined;

    if (status !== 'empty') {
      tools = [
        ...tools,
        {
          text: $t('exportEPlusOutputs'),
          onClick: this.onExportClick,
          isDisabled: isExportDisabled,
          tooltipContent: exportTooltipContent,
        },
      ];

      if (status === 'running' && isLogsScrollEnabled) {
        tools = [
          ...tools,
          { text: isLogsScrollEnabled ? $t('disableScroll') : $t('enableScroll'), onClick: toggleLogsScrollEnabled },
        ];
      }
    }

    return tools;
  };

  private onExportClick = (): void => {
    const { exportEplusOutputs, simulation } = this.props;

    if (simulation) {
      exportEplusOutputs(simulation);
    }
  };

  private renderAlert = (): ReactNode => {
    const { routeMonoSimulationGroup } = this.props;

    if (routeMonoSimulationGroup.obsolete_front) {
      return (
        <Alert status={as.warning} opacity={0.8}>
          {$t('simulationGroupObsoleteAlert')}
        </Alert>
      );
    }

    return null;
  };
}
