import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectSimulationGroupConfigOptions } from 'redux/restResources/detail/simulationGroup/selectors';
import { selectRouteResource } from 'redux/routing/selectors';
import { IApiResourceClass, rrc } from 'utils/strings/resourceClasses';

import { SimulationResourceConfig as Component } from './SimulationResourceConfig.component';

interface IOwnProps {
  resourceClass: IApiResourceClass;
  resourceId: string;
  isObsolete?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps & IOwnProps;

const mapStateToProps = (rootState: IRootState) => ({
  routeProject: selectRouteResource(rrc.project)(rootState),
  configOptions: selectSimulationGroupConfigOptions(rootState),
});

const mapDispatchToProps = {};

export const SimulationResourceConfig = connect(mapStateToProps, mapDispatchToProps)(Component);
