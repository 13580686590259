import { connect } from 'react-redux';
import { IRootState } from 'redux/reducer';
import { selectRouteResource } from 'redux/routing/selectors';
import { rrc } from 'utils/strings/resourceClasses';

import { WithMonoSimulationGroupMenu as Component } from './WithMonoSimulationGroupMenu.component';

export interface IOwnProps {
  isCollapsed?: boolean;
  selectedPage: string;
}

export type IStateProps = ReturnType<typeof mapStateToProps>;
export type IDispatchProps = typeof mapDispatchToProps;
export type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    routeMonoSimulationGroup: selectRouteResource(rrc.mono_simulation_group)(rootState),
    routeProject: selectRouteResource(rrc.project)(rootState),
  };
};

const mapDispatchToProps = {};

export const WithMonoSimulationGroupMenu = connect(mapStateToProps, mapDispatchToProps)(Component);
