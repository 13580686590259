export const weatherMeasureTypes = {
  dry_bulb_temperature: 'stamp',
  dew_point_temperature: 'stamp',
  relative_humidity: 'stamp',
  direct_normal_radiation: 'span',
  diffuse_horizontal_radiation: 'span',
  wind_direction: 'stamp',
  wind_speed: 'stamp',
  atmospheric_pressure: 'stamp',
  horizontal_infrared_radiation_intensity_from_sky: 'span',
  present_weather_observation: 'span',
  present_weather_codes: 'span',
  snow_depth: 'span',
  liquid_precipitation_depth: 'span',
};

export const weatherMeasuresOrder = [
  'dry_bulb_temperature',
  'dew_point_temperature',
  'relative_humidity',
  'atmospheric_pressure',
  'wind_speed',
  'wind_direction',
  'direct_normal_radiation',
  'diffuse_horizontal_radiation',
  'horizontal_infrared_radiation_intensity_from_sky',
  'present_weather_codes',
  'snow_depth',
  'liquid_precipitation_depth',
  'opaque_sky_cover',
  'present_weather_observation',
];
