import Grid from '@material-ui/core/Grid';
import { FormikDropdownInput } from 'components/Forms/Inputs/SingleSelectInputs/FormikDropdownInput';
import { FormikWriteInput } from 'components/Forms/Inputs/TextInputs/FormikWriteInput';
import { getObatResourceFormDrawer } from 'components/Forms/ObatResourceFormDrawer';
import { Section } from 'components/Section/Section.component';
import { FormikProps } from 'formik';
import { _get } from 'libs/lodash';
import { _size } from 'libs/lodash';
import { getYupNumberValidation, getYupStringValidation } from 'libs/yup';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IObatPositionOptions } from 'redux/obatResources/selectors';
import { ISurfaceGroupRequestParams } from 'types/Obat/SurfaceGroup';
import { fdw } from 'utils/configs/drawerWidths';
import { withEmptyOption } from 'utils/functions/forms/withEmptyOption';
import { getEditionPositionOptions } from 'utils/functions/obatResources/position/getEditionPositionOptions';
import {
  getFilterInnerZonesFieldLabel,
  getFilterOuterZonesFieldLabel,
  isFilterOuterZonesFieldDisplayed,
} from 'utils/functions/obatResources/surfaceGroup/fieldsDisplay';
import { isOrientationFieldsDisplayed } from 'utils/functions/obatResources/surfaceGroup/fieldsDisplay';
import { setFormInitialValue } from 'utils/functions/setFormInitialValue';
import { $t } from 'utils/functions/translate';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';
import * as yup from 'yup';

import { IProps } from './SurfaceGroupForm.container';

export interface ISurfaceGroupFormValues {
  ref: string;
  surface_category: string;
  sort_index: number | '';
  construction: string;
  opening: string;
  filter_geometries: string;
  filter_subsurfaces: string;
  filter_surfaces: string;
  filter_inner_zones: string;
  filter_outer_zones: string;
  orientation_direction: number | '';
  orientation_tolerance: number | '';
}

const ObatResourceFormDrawer = getObatResourceFormDrawer<ISurfaceGroupFormValues, ISurfaceGroupRequestParams>();

export class SurfaceGroupForm extends PureComponent<IProps> {
  private formFocusRef = createRef<HTMLInputElement>();

  public render(): ReactNode {
    return (
      <ObatResourceFormDrawer
        renderForm={this.renderForm()}
        formFocusRef={this.formFocusRef}
        getInitialFormValues={this.getInitialFormValues}
        getValidationSchema={this.getValidationSchema}
        formDrawerWidth={fdw.medium}
        resourceClass={orc.surface_group}
      />
    );
  }

  public renderForm = () => {
    return (formikProps: FormikProps<ISurfaceGroupFormValues>): ReactNode => {
      const { surfaceCategoryOptions } = this.props;
      const { surface_category } = formikProps.values;

      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikWriteInput
                field={'ref'}
                formikProps={formikProps}
                label={$t('name')}
                required
                inputRef={this.formFocusRef}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'surface_category'}
                formikProps={formikProps}
                options={surfaceCategoryOptions}
                onSelect={this.onSurfaceCategorySelect(formikProps)}
                renderOptionLabel={$tEnums}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'sort_index'}
                formikProps={formikProps}
                label={$t('position')}
                options={this.getPositionOptions(surface_category)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'construction'}
                formikProps={formikProps}
                options={withEmptyOption(this.getConstructionOptions(surface_category))}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDropdownInput
                field={'opening'}
                formikProps={formikProps}
                options={withEmptyOption(this.getOpeningOptions(surface_category))}
              />
            </Grid>
          </Grid>
          <Section title={$t('surfaceFilters')} marginTop={20}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'filter_geometries'}
                  formikProps={formikProps}
                  label={$t('geometriesFilter')}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'filter_surfaces'}
                  formikProps={formikProps}
                  label={$t('surfacesFilter')}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'filter_inner_zones'}
                  formikProps={formikProps}
                  label={getFilterInnerZonesFieldLabel(surface_category)}
                  multiline
                />
              </Grid>
              {isFilterOuterZonesFieldDisplayed(surface_category) && (
                <Grid item xs={12}>
                  <FormikWriteInput
                    field={'filter_outer_zones'}
                    formikProps={formikProps}
                    label={getFilterOuterZonesFieldLabel(surface_category)}
                    multiline
                  />
                </Grid>
              )}
              {isOrientationFieldsDisplayed(surface_category) && (
                <>
                  <Grid item xs={6}>
                    <FormikWriteInput
                      field={'orientation_direction'}
                      formikProps={formikProps}
                      label={$t('orientationDirectionWithUnit')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikWriteInput
                      field={'orientation_tolerance'}
                      formikProps={formikProps}
                      label={$t('orientationToleranceWithUnit')}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Section>
          <Section title={$t('windowFilters')} marginTop={20}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikWriteInput
                  field={'filter_subsurfaces'}
                  formikProps={formikProps}
                  label={$t('windowsFilter')}
                  multiline
                />
              </Grid>
            </Grid>
          </Section>
        </>
      );
    };
  };

  private getConstructionOptions = (surfaceCategory: ISurfaceGroupFormValues['surface_category']): string[] => {
    if (!surfaceCategory || surfaceCategory === '') {
      return [];
    }

    return this.props.selectConstructionsBySurfaceCategoryOptions(surfaceCategory);
  };

  private getOpeningOptions = (surfaceCategory: ISurfaceGroupFormValues['surface_category']): string[] => {
    if (!surfaceCategory || surfaceCategory === '') {
      return [];
    }

    return this.props.selectOpeningsBySurfaceCategoryOptions(surfaceCategory);
  };

  private getPositionOptions = (surfaceCategory: ISurfaceGroupFormValues['surface_category']): IObatPositionOptions => {
    if (!surfaceCategory || surfaceCategory === '') {
      return [];
    }

    const { surfaceGroupToEdit, groupedCreatePositionOptions } = this.props;

    return surfaceGroupToEdit
      ? getEditionPositionOptions(groupedCreatePositionOptions[surfaceCategory], surfaceGroupToEdit.ref)
      : groupedCreatePositionOptions[surfaceCategory];
  };

  private getInitialFormValues = (): ISurfaceGroupFormValues => {
    const { surfaceGroupToEdit } = this.props;

    return {
      ref: setFormInitialValue(surfaceGroupToEdit, 'ref'),
      surface_category: setFormInitialValue(surfaceGroupToEdit, 'surface_category'),
      sort_index: setFormInitialValue(surfaceGroupToEdit, 'sort_index'),
      construction: setFormInitialValue(surfaceGroupToEdit, 'construction'),
      opening: setFormInitialValue(surfaceGroupToEdit, 'opening'),
      filter_geometries: setFormInitialValue(surfaceGroupToEdit, 'filter_geometries'),
      filter_surfaces: setFormInitialValue(surfaceGroupToEdit, 'filter_surfaces'),
      filter_inner_zones: setFormInitialValue(surfaceGroupToEdit, 'filter_inner_zones'),
      filter_outer_zones: setFormInitialValue(surfaceGroupToEdit, 'filter_outer_zones'),
      filter_subsurfaces: setFormInitialValue(surfaceGroupToEdit, 'filter_subsurfaces'),
      orientation_direction: setFormInitialValue(surfaceGroupToEdit, 'orientation_direction'),
      orientation_tolerance: setFormInitialValue(surfaceGroupToEdit, 'orientation_tolerance'),
    };
  };

  private getValidationSchema = (): yup.ObjectSchema<ISurfaceGroupFormValues> => {
    return yup.object().shape({
      ref: getYupStringValidation(true, true),
      surface_category: getYupStringValidation(true),
      sort_index: getYupNumberValidation(true),
      construction: getYupStringValidation(),
      opening: getYupStringValidation(),
      filter_geometries: getYupStringValidation(),
      filter_surfaces: getYupStringValidation(),
      filter_inner_zones: getYupStringValidation(),
      filter_outer_zones: getYupStringValidation(),
      filter_subsurfaces: getYupStringValidation(),
      orientation_direction: getYupNumberValidation(false, 0, 360, true, false),
      orientation_tolerance: getYupNumberValidation(false, 0, 180),
    }) as yup.ObjectSchema<ISurfaceGroupFormValues>;
  };

  private onSurfaceCategorySelect = (formikProps: FormikProps<ISurfaceGroupFormValues>) => {
    return (_field: string, selectedValue: string): void => {
      const { groupedCreatePositionOptions } = this.props;
      // @ts-ignore
      formikProps.setValues({
        ...formikProps.values,
        surface_category: selectedValue,
        construction: '',
        opening: '',
        filter_outer_zones: isFilterOuterZonesFieldDisplayed(selectedValue)
          ? formikProps.values.filter_outer_zones
          : '',
        sort_index: (_size(_get(groupedCreatePositionOptions, selectedValue)) - 1).toString(),
      });
    };
  };
}
