import { WithProjectSubMenu } from 'components/Menus/WithProjectSubMenu';
import { _lowerCase, _map, _split } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import {
  getWeatherMenuPageRoute,
  IWeatherMenulPage as IWeatherMenuPage,
  projectMenuPages,
  weatherMenuPages,
} from 'routing/routes';
import { $t } from 'utils/functions/translate';

import { IProps } from './WithWeatherMenu.container';

export class WithWeatherMenu extends PureComponent<IProps> {
  public render(): ReactNode {
    const { selectedPage, routeWeather, children } = this.props;

    const getItemTitleFromPage = (page: string) => $t(_lowerCase(_split(page, 'weather')[1]));

    const pagesDivision: IWeatherMenuPage[][] =
      routeWeather.generic_weather_series || !routeWeather.empty
        ? [[weatherMenuPages.weatherConfig, weatherMenuPages.weatherData]]
        : [[weatherMenuPages.weatherConfig]];

    return (
      <WithProjectSubMenu
        selectedSubMenu={projectMenuPages.weathers}
        subMenuTitle={$t('weather')}
        selectedPage={selectedPage}
        subMenuRouteResource={routeWeather}
        subMenuPagesDivision={pagesDivision}
        getSubMenuPageRoute={getWeatherMenuPageRoute}
        getSubMenuItemTitleFromPage={getItemTitleFromPage}
      >
        {children}
      </WithProjectSubMenu>
    );
  }
}
