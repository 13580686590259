import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { ISchedule } from 'types/Obat/Schedule';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { $tEnums } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { ScheduleDetail } from '../ScheduleDetail';
import { ScheduleForm } from '../ScheduleForm';
import { SchedulesPlot } from '../SchedulesPlot/SchedulesPlot.component';

export class SchedulesPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'schedules'}
        resourceClass={orc.schedule}
        renderCardDetail={this.renderCardDetail}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={ScheduleForm}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 3 },
    {
      id: 'type',
      resourceField: 'type',
      withFilterOptions: true,
      gridItemSize: 2,
      renderCustomText: (schedule: ISchedule) => $tEnums(schedule.type),
    },
    { id: 'schedule', gridItemSize: 7, centerTitle: true },
  ];

  private renderCardColumnData = (schedule: ISchedule, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return schedule.ref;
      case 'type':
        return $tEnums(renderTextValueElseDash(schedule.type));
      case 'schedule':
        return (
          schedule && <SchedulesPlot daySchedules={schedule.day_schedules_contents} daySchedulesUnit={schedule.unit} />
        );
    }
  };

  private renderCardDetail = (schedule: ISchedule): ReactNode => {
    return <ScheduleDetail schedule={schedule} />;
  };
}
