import { Drawing2D } from 'pages/GeometryMenu/Drawing2D/Drawing2D';
import { Viewer3D } from 'pages/GeometryMenu/Viewer3D/Viewer3D';
import { MyAccount } from 'pages/HomeMenu/MyAccount/MyAccountPage';
import { MyOrganizationsList } from 'pages/HomeMenu/MyOrganizations/MyOrganizationsList';
import { MyProjectsList } from 'pages/HomeMenu/MyProjects/MyProjectsList';
import { Login } from 'pages/Login';
import { ActivityZoneGroupsPage } from 'pages/ObatMenu/ActivityZoneGroups/ActivityZoneGroupsPage';
import { BridgesPage } from 'pages/ObatMenu/Bridges/BridgesPage';
import { CalendarsPage } from 'pages/ObatMenu/Calendars/CalendarsPage';
import { ConstructionsPage } from 'pages/ObatMenu/Constructions/ConstructionsPage';
import { EfEpPage } from 'pages/ObatMenu/EfEp/EfEpPage';
import { EPlusParametersPage } from 'pages/ObatMenu/EPlusParameters/EPlusParametersPage';
import { MaterialsPage } from 'pages/ObatMenu/Materials/MaterialsPage';
import { OpeningsPage } from 'pages/ObatMenu/Openings/OpeningsPage';
import { OutputZoneGroupsPage } from 'pages/ObatMenu/OutputZoneGroups/OutputZoneGroupsPage';
import { ProjectionPage } from 'pages/ObatMenu/Projection/ProjectionPage';
import { SchedulesPage } from 'pages/ObatMenu/Schedules/SchedulesPage';
import { ScriptsPage } from 'pages/ObatMenu/Scripts/ScriptsPage';
import { SurfaceGroupsPage } from 'pages/ObatMenu/SurfaceGroups/SurfaceGroupsPage';
import { SystemsPage } from 'pages/ObatMenu/Systems/SystemsPage';
import { UsesPage } from 'pages/ObatMenu/Uses/UsesPage';
import { VariantsPage } from 'pages/ObatMenu/Variants/VariantsPage';
import { YearProfilesPage } from 'pages/ObatMenu/YearProfiles/YearProfilesPage';
import { OrganizationPermissionsList } from 'pages/OrganizationMenu/Permissions/OrganizationPermissionsList';
import { OrganizationProjectsList } from 'pages/OrganizationMenu/Projects';
import { Seats } from 'pages/OrganizationMenu/Seats/Seats';
import { Page404 } from 'pages/Page404';
import { GeometriesList } from 'pages/ProjectMenu/Geometries/GeometriesList';
import { ObatsList } from 'pages/ProjectMenu/Obats/ObatsList';
import { ProjectPermissionsList } from 'pages/ProjectMenu/Permissions/ProjectPermissionsList';
import { SimulationGroupsList } from 'pages/ProjectMenu/SimulationGroups/SimulationGroupsList';
import { WeathersList } from 'pages/ProjectMenu/Weathers/WeathersList';
import { ConfigMono } from 'pages/SimulationGroupMenu/Mono/Config/ConfigMono';
import { HourlyResultsMono } from 'pages/SimulationGroupMenu/Mono/HourlyResults/HourlyResultsMono';
import { MonitorMono } from 'pages/SimulationGroupMenu/Mono/Monitor/MonitorMono';
import { MonthlyResultsMono } from 'pages/SimulationGroupMenu/Mono/MonthlyResults/MonthlyResultsMono';
import { ConfigMulti } from 'pages/SimulationGroupMenu/Multi/Config/ConfigMulti';
import { HourlyResultsMulti } from 'pages/SimulationGroupMenu/Multi/HourlyResults/HourlyResultsMulti';
import { MonitorMulti } from 'pages/SimulationGroupMenu/Multi/Monitor/MonitorMulti';
import { MonthlyResultsMulti } from 'pages/SimulationGroupMenu/Multi/MonthlyResults/MonthlyResultsMulti';
import { WeatherConfig } from 'pages/WeatherMenu/Config/WeatherConfig';
import { WeatherData } from 'pages/WeatherMenu/Data/WeatherData';
import React, { PureComponent, ReactNode } from 'react';
import { Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router-dom';
import { isTokenValid } from 'utils/functions/isTokenValid';

import history from '../history';
import { Interceptor } from '../Interceptor';
import {
  geometryMenuRoutePaths,
  homeMenuRoutePaths,
  homeRoute,
  loginRoute,
  monoSimulationGroupMenuRoutePaths,
  multiSimulationGroupMenuRoutePaths,
  obatMenuRoutePaths,
  organizationMenuRoutePaths,
  page404Route,
  projectMenuRoutePaths,
  weatherMenuRoutePaths,
} from '../routes';

import { IProps } from './Router.container';

export class RouterComponent extends PureComponent<IProps> {
  /** I tried to use React.lazy and Suspense to split code loading but it's not satisfying.
   * For the moment, the Suspense fallback is unavoidable, causing an ugly flicker while navigating for the first time through lazy loaded components.
   * https://stackoverflow.com/questions/63624950/react-suspense-lazy-loading-without-fallback
   * (It was even impossible to load any image in the fallback, the Loader logo in particular...)
   */

  public render(): ReactNode {
    return <Router history={history}>{this.renderRoutes()}</Router>;
  }

  // tslint:disable-next-line:no-any
  private interceptor = (InterceptedComponent: any) => {
    return () => {
      return <Interceptor InterceptedComponent={InterceptedComponent} />;
    };
  };

  private isNotLogged = (): boolean => {
    const { accessToken, refreshToken } = this.props;

    return !accessToken || !refreshToken || (!!refreshToken && !isTokenValid(refreshToken));
  };

  private redirectToLogin = (props: RouteComponentProps): ReactNode => {
    return <Redirect to={{ pathname: loginRoute, state: { targetRoute: props.location.pathname } }} />;
  };
  private redirectToProjectsList = (): ReactNode => <Redirect to={homeMenuRoutePaths.myProjects} />;
  private redirectToPage404 = (): ReactNode => <Redirect to={page404Route} />;

  private renderRoutes = (): ReactNode => {
    if (this.isNotLogged()) {
      return (
        <Switch>
          <Route exact path={loginRoute} component={Login} />
          <Route path="/*" render={this.redirectToLogin} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route exact path={loginRoute} component={Login} />

        <Route exact path={homeRoute} render={this.redirectToProjectsList} />

        <Route exact path={homeMenuRoutePaths.myProjects} render={this.interceptor(MyProjectsList)} />
        <Route exact path={homeMenuRoutePaths.myOrganizations} render={this.interceptor(MyOrganizationsList)} />
        <Route exact path={homeMenuRoutePaths.myAccount} render={this.interceptor(MyAccount)} />

        {/***  ORGANIZATION MENU  ***/}

        <Route
          exact
          path={organizationMenuRoutePaths.organizationProjects}
          render={this.interceptor(OrganizationProjectsList)}
        />
        <Route
          exact
          path={organizationMenuRoutePaths.organizationPermissions}
          render={this.interceptor(OrganizationPermissionsList)}
        />
        <Route exact path={organizationMenuRoutePaths.seats} render={this.interceptor(Seats)} />

        {/***  PROJECTS MENU  ***/}

        <Route exact path={projectMenuRoutePaths.geometries} render={this.interceptor(GeometriesList)} />
        <Route exact path={projectMenuRoutePaths.obats} render={this.interceptor(ObatsList)} />
        <Route exact path={projectMenuRoutePaths.simulationGroups} render={this.interceptor(SimulationGroupsList)} />
        <Route exact path={projectMenuRoutePaths.weathers} render={this.interceptor(WeathersList)} />
        <Route
          exact
          path={projectMenuRoutePaths.projectPermissions}
          render={this.interceptor(ProjectPermissionsList)}
        />

        {/***  OBATS MENU ***/}

        <Route exact path={obatMenuRoutePaths.yearProfiles} render={this.interceptor(YearProfilesPage)} />
        <Route exact path={obatMenuRoutePaths.calendars} render={this.interceptor(CalendarsPage)} />
        <Route exact path={obatMenuRoutePaths.openings} render={this.interceptor(OpeningsPage)} />
        <Route exact path={obatMenuRoutePaths.constructions} render={this.interceptor(ConstructionsPage)} />
        <Route exact path={obatMenuRoutePaths.schedules} render={this.interceptor(SchedulesPage)} />
        <Route exact path={obatMenuRoutePaths.surfaceGroups} render={this.interceptor(SurfaceGroupsPage)} />
        <Route exact path={obatMenuRoutePaths.bridges} render={this.interceptor(BridgesPage)} />
        <Route exact path={obatMenuRoutePaths.outputZoneGroups} render={this.interceptor(OutputZoneGroupsPage)} />
        <Route exact path={obatMenuRoutePaths.scripts} render={this.interceptor(ScriptsPage)} />
        <Route exact path={obatMenuRoutePaths.systems} render={this.interceptor(SystemsPage)} />
        <Route exact path={obatMenuRoutePaths.activityZoneGroups} render={this.interceptor(ActivityZoneGroupsPage)} />
        <Route exact path={obatMenuRoutePaths.variants} render={this.interceptor(VariantsPage)} />
        <Route exact path={obatMenuRoutePaths.materials} render={this.interceptor(MaterialsPage)} />
        <Route exact path={obatMenuRoutePaths.uses} render={this.interceptor(UsesPage)} />
        <Route exact path={obatMenuRoutePaths.projection} render={this.interceptor(ProjectionPage)} />
        <Route exact path={obatMenuRoutePaths.efEp} render={this.interceptor(EfEpPage)} />
        <Route exact path={obatMenuRoutePaths.ePlusParameters} render={this.interceptor(EPlusParametersPage)} />

        {/***  GEOMETRIES MENU ***/}

        <Route exact path={geometryMenuRoutePaths.drawing2D} render={this.interceptor(Drawing2D)} />
        <Route exact path={geometryMenuRoutePaths.viewer3D} render={this.interceptor(Viewer3D)} />

        {/***  WEATHERS MENU ***/}

        <Route exact path={weatherMenuRoutePaths.weatherConfig} render={this.interceptor(WeatherConfig)} />
        <Route exact path={weatherMenuRoutePaths.weatherData} render={this.interceptor(WeatherData)} />

        {/***  SIMULATION GROUP MENU  ***/}

        <Route exact path={monoSimulationGroupMenuRoutePaths.configMono} render={this.interceptor(ConfigMono)} />
        <Route exact path={monoSimulationGroupMenuRoutePaths.monitorMono} render={this.interceptor(MonitorMono)} />
        <Route
          exact
          path={monoSimulationGroupMenuRoutePaths.hourlyResultsMono}
          render={this.interceptor(HourlyResultsMono)}
        />
        <Route
          exact
          path={monoSimulationGroupMenuRoutePaths.monthlyResultsMono}
          render={this.interceptor(MonthlyResultsMono)}
        />

        <Route exact path={multiSimulationGroupMenuRoutePaths.configMulti} render={this.interceptor(ConfigMulti)} />
        <Route exact path={multiSimulationGroupMenuRoutePaths.monitorMulti} render={this.interceptor(MonitorMulti)} />
        <Route
          exact
          path={multiSimulationGroupMenuRoutePaths.hourlyResultsMulti}
          render={this.interceptor(HourlyResultsMulti)}
        />
        <Route
          exact
          path={multiSimulationGroupMenuRoutePaths.monthlyResultsMulti}
          render={this.interceptor(MonthlyResultsMulti)}
        />

        {/***  PROTOS  ***/}

        {/*<Route exact path="/map" component={Map} />*/}
        {/*<Route exact path="/largeData" component={LargeDatatable} />*/}

        {/***  NOT FOUND  ***/}
        {/* This default route should stay at the end !!*/}
        <Route exact path={page404Route} component={Page404} />
        <Route path="/*" render={this.redirectToPage404} />
      </Switch>
    );
  };
}
