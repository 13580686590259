// tslint:disable:no-any
import { _map, _pick } from 'libs/lodash';
import { ReactNode } from 'react';

/* TODO: memoize these functions with a resolver able to detect render functions changes (JSON.stringify does not work with functions) */

interface ITableColumnCoreParams {
  id: string;
  title?: string;
  resourceField?: string;
}

const coreParams = ['id', 'title', 'resourceField'];

export interface ICardsTableColumnParams extends ITableColumnCoreParams {
  gridItemSize: any;
  centerTitle?: boolean;
  noFilter?: boolean;
}
export type ICardsTableColumnsParams = ICardsTableColumnParams[];

export const pickCardColumnParams = (columnsParams: ITableColumnParams[]): ICardsTableColumnParams[] => {
  const cardParams = [...coreParams, 'gridItemSize', 'centerTitle', 'noFilter'];

  return _map(
    columnsParams,
    (columnParams: ITableColumnParams) => _pick(columnParams, cardParams) as ICardsTableColumnParams
  );
};

export interface IDatatableColumnParams extends ITableColumnCoreParams {
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  getNavigationUrl?: (tableResource: any) => string | undefined;
  getTooltipContent?: (tableResource: any) => string | undefined;
  renderCustomText?: (tableResource: any) => string;
  renderCustomDOM?: (tableResource: any) => any;
  noSort?: boolean;
  noFilter?: boolean;
  minWidth?: number;
  maxWidth?: number;
}
export type IDatatableColumnsParams = IDatatableColumnParams[];

export const pickDatatableColumnParams = (columnParams: ITableColumnParams[]): IDatatableColumnParams[] => {
  const tableParams = [
    ...coreParams,
    'align',
    'getNavigationUrl',
    'getTooltipContent',
    'renderCustomText',
    'renderCustomDOM',
    'noSort',
    'noFilter',
    'minWidth',
    'maxWidth',
  ];

  return _map(
    columnParams,
    (columnParam: ITableColumnParams) => _pick(columnParam, tableParams) as IDatatableColumnParams
  );
};

export interface ITableColumnFilterParams extends ITableColumnCoreParams {
  withFilterOptions?: boolean /* need to precise if the filter options have to be implemented later */;
  filterOptions?: string[];
  renderCustomText?: (tableResource: any) => string;
  renderCustomDOM?: (tableResource: any) => ReactNode;
  getCustomDOMFilterComparisonValue?: (tableResource: any) => string;
}
export type ITableFilterParams = ITableColumnFilterParams[];

export const pickColumnFilterParams = (columnParams: ITableColumnParams[]): ITableFilterParams => {
  const filterParams = [
    ...coreParams,
    'withFilterOptions',
    'filterOptions',
    'renderCustomText',
    'renderCustomDOM',
    'getCustomDOMFilterComparisonValue',
  ];

  return _map(
    columnParams,
    (columnParam: ITableColumnParams) => _pick(columnParam, filterParams) as ITableColumnFilterParams
  );
};

export type ITableColumnParams = Partial<ICardsTableColumnParams & ITableColumnFilterParams & IDatatableColumnParams>;

export type ITableColumnsParams = ITableColumnParams[];
