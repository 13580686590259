import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import { SimpleCard } from 'components/Cards';
import { _includes, _map, _slice } from 'libs/lodash';
import React, { PureComponent, ReactNode } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';
import { isCommittedForDelete } from 'utils/functions/obatResources/commitments/isCommitted';
import { ICardsTableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { $t } from 'utils/functions/translate';
import { orc } from 'utils/strings/resourceClasses';

import { IProps } from './ObatCard.container';

interface IState {
  cursorStyle: 'pointer' | 'auto';
  isExpanded: boolean;
  isHovered: boolean;
}

export class ObatCard extends PureComponent<IProps, IState> {
  public state: IState = {
    isHovered: false,
    isExpanded: this.props.isExpanded || false,
    cursorStyle:
      !!this.props.renderCardDetail && !!this.props.renderCardDetail(this.props.obatResource) ? 'pointer' : 'auto',
  };

  public componentDidUpdate = (prevProps: IProps): void => {
    if (prevProps.isExpanded !== this.props.isExpanded) {
      this.setState({ isExpanded: this.props.isExpanded || false });
    }
  };

  public render(): ReactNode {
    const {
      cardColumnsParams,
      renderColumnData,
      renderCardDetail,
      leftColor,
      obatResource,
      obatResourceToEdit,
      isWritePermissionMissing,
      isSeatMissing,
      isVariantContext,
      resourceClass,
      openEditFormDrawer,
      openCopyFormDrawer,
      openDeletionModal,
      openCommitmentsModal,
      ...props
    } = this.props;

    const { isExpanded, isHovered, cursorStyle } = this.state;

    if (obatResource.id === 'not_assigned') {
      return null;
    }

    const isCommitted = isCommittedForDelete(obatResource);

    return (
      <CardContainer id={obatResource.id}>
        <LeftColorBar color={leftColor} isExpanded={isExpanded} isHovered={isHovered} />
        <StyledSimpleCard
          {...props}
          onClick={this.onCardClick}
          onMouseUp={this.onMouseEnter}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          // @ts-ignore
          minHeight={50}
          isEdited={!!obatResourceToEdit && obatResource.id === obatResourceToEdit.id}
          isHovered={isHovered}
          isExpanded={isExpanded}
          cursorStyle={cursorStyle}
        >
          <CardMainBody>
            <Grid container spacing={2}>
              <Grid item xs={12} md={cardColumnsParams[0].gridItemSize} key={cardColumnsParams[0].id}>
                <FirstCellContainer>
                  <FirstCell onClick={this.stopCardExpansionEvent}>
                    {renderColumnData(obatResource, cardColumnsParams[0].id)}
                  </FirstCell>
                  <IconsContainer>
                    <IconButton
                      action={'edit'}
                      onClick={openEditFormDrawer}
                      isWritePermissionMissing={isWritePermissionMissing}
                      isSeatMissing={isSeatMissing}
                      isDisabled={isVariantContext}
                      tooltipContent={isVariantContext ? $t('cannotWriteInVariantContext') : $t('edit')}
                    />
                    {!_includes([orc.bridge, orc.use, orc.ef_ep, orc.e_plus_parameters], resourceClass) && (
                      <IconButton
                        action={'copy'}
                        onClick={openCopyFormDrawer}
                        isWritePermissionMissing={isWritePermissionMissing}
                        isSeatMissing={isSeatMissing}
                        isDisabled={isVariantContext}
                        tooltipContent={isVariantContext ? $t('cannotWriteInVariantContext') : $t('copy')}
                      />
                    )}
                    {!_includes([orc.ef_ep, orc.e_plus_parameters], resourceClass) && (
                      <IconButton
                        action={'delete'}
                        onClick={openDeletionModal}
                        isWritePermissionMissing={isWritePermissionMissing}
                        isSeatMissing={isSeatMissing}
                        isDisabled={isVariantContext || isCommitted}
                        tooltipContent={
                          isVariantContext
                            ? $t('cannotWriteInVariantContext')
                            : isCommitted
                            ? $t('deleteCommitmentAlert')
                            : $t('delete')
                        }
                      />
                    )}
                    {isCommitted && (
                      <IconButton
                        action={'seeDetails'}
                        onClick={openCommitmentsModal}
                        tooltipContent={$t('seeCommitments')}
                      />
                    )}
                  </IconsContainer>
                </FirstCellContainer>
              </Grid>
              {_map(
                _slice(cardColumnsParams, 1),
                (columnParams: ICardsTableColumnParams): ReactNode => {
                  return (
                    <Grid item xs={12} md={columnParams.gridItemSize} key={columnParams.id}>
                      <Cell onClick={this.stopCardExpansionEvent}>
                        {renderColumnData(obatResource, columnParams.id)}
                      </Cell>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </CardMainBody>
          {renderCardDetail && !!renderCardDetail(obatResource) && (
            <Collapse in={isExpanded}>
              <CardCollapsedBody
                onClick={this.onSubItemClick}
                onMouseEnter={this.onSubItemEnter}
                onMouseLeave={this.onSubItemLeave}
              >
                {renderCardDetail(obatResource)}
              </CardCollapsedBody>
            </Collapse>
          )}
        </StyledSimpleCard>
      </CardContainer>
    );
  }

  private stopCardExpansionEvent = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  private onCardClick = (): void => {
    const { renderCardDetail, obatResource } = this.props;
    if (!!renderCardDetail && !!renderCardDetail(obatResource)) {
      this.setState((state: IState) => ({
        isExpanded: !state.isExpanded,
      }));
    }
  };

  private onMouseEnter = (): void => {
    const { renderCardDetail, obatResource } = this.props;
    this.setState({ isHovered: true });
    if (!!renderCardDetail && !!renderCardDetail(obatResource)) {
      this.setState({ cursorStyle: 'pointer' });
    }
  };

  private onMouseLeave = (): void => {
    this.setState({ isHovered: false, cursorStyle: 'auto' });
  };
  private onSubItemClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
    this.setState({ cursorStyle: 'auto' });
  };

  private onSubItemEnter = (): void => {
    this.setState({ cursorStyle: 'auto' });
  };
  private onSubItemLeave = (): void => {
    this.setState({ cursorStyle: 'pointer' });
  };
}

interface IStyledSimpleCard {
  color?: string;
  cursorStyle?: 'pointer' | 'auto';
  isExpanded?: boolean;
  isHovered?: boolean;
  isEdited?: boolean;
}
interface IColorBar {
  color?: string;
  isExpanded?: boolean;
  isHovered?: boolean;
}

export const StyledSimpleCard = styled(SimpleCard)`
  cursor: ${(props: IStyledSimpleCard): string => props.cursorStyle || 'auto'};
  box-shadow: ${(props: IStyledSimpleCard): string =>
    props.isEdited
      ? theme.shadows.card.edited
      : props.isExpanded
      ? theme.shadows.card.clicked
      : props.isHovered
      ? theme.shadows.card.hovered
      : theme.shadows.card.standard};
  background-color: ${(props: IStyledSimpleCard): string =>
    props.isExpanded || props.isHovered ? theme.colors.pageBody : 'white'};
  margin-left: ${(props: IStyledSimpleCard): string => (props.isHovered ? '-2px' : '0')};
  margin-right: ${(props: IStyledSimpleCard): string => (props.isHovered ? '2px' : '0')};
  min-height: ${(props: IStyledSimpleCard & { minHeight?: string }) => (props.minHeight ? props.minHeight : '40px')};
  position: relative;
`;

export const CardMainBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  align-items: flex-start;
`;

const CardCollapsedBody = styled.div`
  background-color: ${theme.colors.pageBody};
  padding: 16px;
  margin: -16px;
  border-radius: 0 0 4px 4px;
`;

const LeftColorBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 8px;
  border-radius: 4px 0 0 4px;
  display: block;
  background-color: ${(props: IColorBar) => props.color};
  margin-left: ${(props: IColorBar): string => (props.isExpanded ? '-4px' : props.isHovered ? '-2px' : '0')};
  margin-right: ${(props: IColorBar): string => (props.isExpanded ? '4px' : props.isHovered ? '2px' : '0')};
  z-index: 3;
`;

const IconsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

const CardContainer = styled.div`
  position: relative;
  display: block;
`;

const FirstCell = styled.div`
  word-break: break-all;
  cursor: auto !important;
`;

const Cell = styled.div`
  word-break: break-word;
  cursor: auto !important;
`;

const FirstCellContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
