import { ObatCardsPage } from 'components/ObatResources/ObatCardsPage';
import React, { PureComponent, ReactNode } from 'react';
import { IOutputZoneGroup } from 'types/Obat/OutputZoneGroup';
import { ITableColumnParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { renderTextValueElseDash } from 'utils/functions/renderValue';
import { orc } from 'utils/strings/resourceClasses';

import { OutputZoneGroupForm } from '../OutputZoneGroupForm';

export class OutputZoneGroupsPage extends PureComponent {
  public render(): ReactNode {
    return (
      <ObatCardsPage
        columnsParams={this.getColumnsParams()}
        selectedPage={'outputZoneGroups'}
        resourceClass={orc.output_zone_group}
        renderCardColumnData={this.renderCardColumnData}
        ObatResourceFormComponent={OutputZoneGroupForm}
        projectionSection={'zones'}
      />
    );
  }

  private getColumnsParams = (): ITableColumnParams[] => [
    { id: 'name', resourceField: 'ref', gridItemSize: 4 },
    { id: 'geometriesFilter', resourceField: 'filter_geometries', gridItemSize: 4 },
    { id: 'zonesFilter', resourceField: 'filter_zones', gridItemSize: 4 },
  ];

  private renderCardColumnData = (outputZoneGroup: IOutputZoneGroup, columnId: string): ReactNode => {
    switch (columnId) {
      case 'name':
        return outputZoneGroup.ref;
      case 'geometriesFilter':
        return renderTextValueElseDash(outputZoneGroup.filter_geometries);
      case 'zonesFilter':
        return renderTextValueElseDash(outputZoneGroup.filter_zones);
    }
  };
}
