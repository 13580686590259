import { connect } from 'react-redux';
import { selectFormInitialResourceFromTable } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { orc } from 'utils/strings/resourceClasses';

import { CalendarForm as CalendarFormComponent } from './CalendarForm.component';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

export type IInjectedProps = IStateProps & IDispatchProps;

export type IProps = IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  const calendarToEdit = selectFormInitialResourceFromTable(orc.calendar)(rootState);

  return { calendarToEdit };
};

const mapDispatchToProps = {};

export const CalendarForm = connect(mapStateToProps, mapDispatchToProps)(CalendarFormComponent);
