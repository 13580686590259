// tslint:disable:no-any

import { _find, _intersection, _map, _size } from 'libs/lodash';
import { ITableResourcesList } from 'redux/table/reducer';
import { ITableFilterValues, ITableResources } from 'redux/table/reducer';
import { createSelector } from 'reselect';
import { memoizeMulti } from 'utils/functions/memoizeMulti';
import { ITableFilterParams } from 'utils/functions/pickColumnParams/pickColumnParams';
import { IApiResourceClass } from 'utils/strings/resourceClasses';

import { IRootState } from '../reducer';

export const selectTableResourceClass = (rootState: IRootState): IApiResourceClass | undefined =>
  rootState.table.resourceClass;

export const selectTableParentId = (rootState: IRootState): string | undefined => rootState.table.parentId;

export const selectTableFilterValues = (rootState: IRootState): ITableFilterValues => rootState.table.filter.values;

export const selectTableFilterParams = (rootState: IRootState): ITableFilterParams => rootState.table.filter.params;

export const selectIsSelectionDisplay = (rootState: IRootState): boolean => rootState.table.isSelectionDisplay;

export const selectFullTableResourcesList = (rootState: IRootState): ITableResourcesList =>
  rootState.table.resources.full;
export const selectFilteredTableResourcesList = (rootState: IRootState): ITableResourcesList =>
  rootState.table.resources.filtered;
export const selectSelectedTableResourcesList = (rootState: IRootState): ITableResourcesList =>
  rootState.table.resources.selected;

export const selectTableResources = createSelector(
  /* Need this function to be sure we get the expected data: */
  /* When a component mount, a first render happens before tableResources gets updated. It's still the ones of the previous component */
  /* So we check manually that the resourceClass and parentId of tableResources are the expected ones, if not nothing gets returned */

  [
    // @ts-ignore: TS would like ITableResources to be string....
    (rootState: IRootState): ITableResources => rootState.table.resources,
    selectTableResourceClass,
    selectTableParentId,
  ],
  (tableData: ITableResources, tableResourceClass: IApiResourceClass, tableParentId: string | undefined) =>
    memoizeMulti((resourceClass?: IApiResourceClass, parentId?: string) => {
      return tableResourceClass === resourceClass && tableParentId === parentId ? tableData : undefined;
    })
);

export const selectSelectedTableResourcesIdList = createSelector(
  selectSelectedTableResourcesList,
  (tableResourcesSelected: ITableResourcesList) => _map(tableResourcesSelected, 'id')
);

export const selectResourceInTableResources = createSelector(
  [selectFilteredTableResourcesList, selectFullTableResourcesList],
  (tableResourcesFiltered: ITableResourcesList, tableResourcesFull: ITableResourcesList) =>
    memoizeMulti((id: string, inFilteredDataOnly: boolean = false) => {
      const dataToFindIn = inFilteredDataOnly ? tableResourcesFiltered : tableResourcesFull;

      return _find(dataToFindIn, ['id', id]);
    })
);

export const selectHasTableFilteredResourcesSelectedResources = createSelector(
  [selectSelectedTableResourcesList, selectFilteredTableResourcesList],
  (selectedTableData: ITableResourcesList, filteredTableData: ITableResourcesList) =>
    _size(_intersection(selectedTableData, filteredTableData)) > 0
);
