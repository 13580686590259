import { HourlyResultsPage } from 'components/SimulationGroup/HourlyResults/HourlyResultsPage';
import React, { PureComponent, ReactNode } from 'react';

import { IProps } from './HourlyResultsMulti.container';

export class HourlyResultsMulti extends PureComponent<IProps> {
  public render(): ReactNode {
    const { routeMultiSimulationGroup } = this.props;

    return <HourlyResultsPage routeSimulationGroup={routeMultiSimulationGroup} />;
  }
}
