import { _get, _map } from 'libs/lodash';
import { IObatContents } from 'types/Obat/ObatContents';
import { IObatData } from 'types/Obat/ObatData';

export const enrichEfEp = (obatContents: IObatContents): IObatData => {
  const enrichedEfEp = [
    {
      id: 'ef_ep',
      ref: 'energyConversionFactors',
      commitments: { update: {}, delete: {} },
      energy_co2_content_electricity: obatContents.data.general[0].energy_co2_content_electricity,
      energy_co2_content_gas: obatContents.data.general[0].energy_co2_content_gas,
      energy_co2_content_heating_district: obatContents.data.general[0].energy_co2_content_heating_district,
      energy_co2_content_cooling_district: obatContents.data.general[0].energy_co2_content_cooling_district,
      energy_co2_content_biomass: obatContents.data.general[0].energy_co2_content_biomass,
      energy_co2_content_fuel_oil: obatContents.data.general[0].energy_co2_content_fuel_oil,
      energy_coefficients_electricity: obatContents.data.general[0].energy_coefficients_electricity,
      energy_coefficients_gas: obatContents.data.general[0].energy_coefficients_gas,
      energy_coefficients_heating_district: obatContents.data.general[0].energy_coefficients_heating_district,
      energy_coefficients_cooling_district: obatContents.data.general[0].energy_coefficients_cooling_district,
      energy_coefficients_biomass: obatContents.data.general[0].energy_coefficients_biomass,
      energy_coefficients_fuel_oil: obatContents.data.general[0].energy_coefficients_fuel_oil,
    },
  ];

  return { ...obatContents.data, ef_ep: enrichedEfEp };
};
