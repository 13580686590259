import { IObatHeaderOwnProps } from 'components/ObatResources/ObatHeadersBar/ObatHeadersBar.container';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { selectFormDrawerWidth } from 'redux/form/selectors';
import { IRootState } from 'redux/reducer';
import { selectIsRefreshingRoutePage } from 'redux/routing/selectors';
import { IRoutingMenu } from 'utils/strings/routingMenus';

import { Page as Component } from './Page.component';

interface IOwnProps {
  pageTitle: string;
  obatHeaderProps?: IObatHeaderOwnProps;
  customPageRefresh?: () => void;
  isMenuDrawerCollapsed?: boolean;
  routingMenu: IRoutingMenu;
  renderAlert?: () => ReactNode;
  selectedPage?: string;
  noPadding?: boolean;
  withBottomButtons?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

type IInjectedProps = IStateProps & IDispatchProps;
export type IProps = IOwnProps & IInjectedProps;

const mapStateToProps = (rootState: IRootState) => {
  return {
    isRefreshingRoutePage: selectIsRefreshingRoutePage(rootState),
    formDrawerWidth: selectFormDrawerWidth(rootState),
  };
};

const mapDispatchToProps = {};

export const Page = connect(mapStateToProps, mapDispatchToProps)(Component);
