import { Action } from 'redux';
import { IRouteResources, IRouteResourcesLists } from 'redux/routing/reducer';
import { IRestResource } from 'types/RestResource';
import { IRouteParams } from 'utils/functions/routing/getResourcesToUpdateFromRouteParams';
import { IRestResourceClass } from 'utils/strings/resourceClasses';

/*** UPDATE_ROUTE_ACTION ***/

export const UPDATE_ROUTE_ACTION = 'UPDATE_ROUTE_ACTION';
export interface IUpdateRouteAction extends Action<'UPDATE_ROUTE_ACTION'> {
  payload: {
    route: string;
  };
}
export const updateRouteAction = (route: string): IUpdateRouteAction => ({
  type: UPDATE_ROUTE_ACTION,
  payload: {
    route,
  },
});

/*** UPDATE_ROUTE_HASH_ACTION ***/

export const UPDATE_ROUTE_HASH_ACTION = 'UPDATE_ROUTE_HASH_ACTION';
export interface IUpdateRouteHashAction extends Action<'UPDATE_ROUTE_HASH_ACTION'> {
  payload: {
    routeHash?: string;
  };
}
export const updateRouteHashAction = (routeHash: string | undefined): IUpdateRouteHashAction => ({
  type: UPDATE_ROUTE_HASH_ACTION,
  payload: {
    routeHash,
  },
});

/*** TOGGLE_IS_ROUTE_RESOURCES_LOADING_ACTION ***/

export const TOGGLE_IS_ROUTE_RESOURCES_LOADING_ACTION = 'TOGGLE_IS_ROUTE_RESOURCES_LOADING_ACTION';
export interface IToggleIsRouteResourcesLoadingAction extends Action<'TOGGLE_IS_ROUTE_RESOURCES_LOADING_ACTION'> {
  payload: {
    isRouteResourcesLoading: boolean;
  };
}
export const toggleIsRouteResourcesLoadingAction = (
  isRouteResourcesLoading: boolean
): IToggleIsRouteResourcesLoadingAction => ({
  type: TOGGLE_IS_ROUTE_RESOURCES_LOADING_ACTION,
  payload: {
    isRouteResourcesLoading,
  },
});

/*** TOGGLE_IS_ROUTE_RESOURCES_UP_TO_DATE_ACTION ***/

export const TOGGLE_IS_ROUTE_RESOURCES_UP_TO_DATE_ACTION = 'TOGGLE_IS_ROUTE_RESOURCES_UP_TO_DATE_ACTION';
export interface IToggleIsRouteResourcesUpToDateAction extends Action<'TOGGLE_IS_ROUTE_RESOURCES_UP_TO_DATE_ACTION'> {
  payload: {
    isRouteResourcesUpToDate: boolean | undefined;
  };
}
export const toggleIsRouteResourcesUpToDateAction = (
  isRouteResourcesUpToDate: boolean | undefined
): IToggleIsRouteResourcesUpToDateAction => ({
  type: TOGGLE_IS_ROUTE_RESOURCES_UP_TO_DATE_ACTION,
  payload: {
    isRouteResourcesUpToDate,
  },
});

/*** CLEAN_ROUTE_RESOURCES_AFTER_RENDER_ACTION ***/

export const CLEAN_ROUTE_RESOURCES_AFTER_RENDER_ACTION = 'CLEAN_ROUTE_RESOURCES_AFTER_RENDER_ACTION';
export interface ICleanRouteResourcesAfterRenderAction extends Action<'CLEAN_ROUTE_RESOURCES_AFTER_RENDER_ACTION'> {}
export const cleanRouteResourcesAfterRenderAction = (): ICleanRouteResourcesAfterRenderAction => ({
  type: CLEAN_ROUTE_RESOURCES_AFTER_RENDER_ACTION,
});

/*** TOGGLE_IS_REFRESHING_ROUTE_PAGE_ACTION ***/

export const TOGGLE_IS_REFRESHING_ROUTE_PAGE_ACTION = 'TOGGLE_IS_REFRESHING_ROUTE_PAGE_ACTION';
export interface IToggleRefreshingRoutePageStatusAction extends Action<'TOGGLE_IS_REFRESHING_ROUTE_PAGE_ACTION'> {
  payload: {
    isRefreshingRoutePage: boolean;
  };
}
export const toggleIsRefreshingRoutePageAction = (
  isRefreshingRoutePage: boolean
): IToggleRefreshingRoutePageStatusAction => ({
  type: TOGGLE_IS_REFRESHING_ROUTE_PAGE_ACTION,
  payload: {
    isRefreshingRoutePage,
  },
});

/*** UPDATE_SINGLE_ROUTE_RESOURCE_ACTION ***/

export const UPDATE_SINGLE_ROUTE_RESOURCE_ACTION = 'UPDATE_SINGLE_ROUTE_RESOURCE_ACTION';
export interface IUpdateSingleRouteResourceAction extends Action<'UPDATE_SINGLE_ROUTE_RESOURCE_ACTION'> {
  payload: {
    restResource: IRestResource;
    resourceClass: IRestResourceClass;
  };
}
export const updateSingleRouteResourceAction = (
  resourceClass: IRestResourceClass,
  restResource: IRestResource
): IUpdateSingleRouteResourceAction => ({
  type: UPDATE_SINGLE_ROUTE_RESOURCE_ACTION,
  payload: {
    restResource,
    resourceClass,
  },
});

/*** FETCH_ALL_ROUTE_RESOURCES_SAGA_ACTION ***/

export const FETCH_ALL_ROUTE_RESOURCES_SAGA_ACTION = 'FETCH_ALL_ROUTE_RESOURCES_SAGA_ACTION';
export interface IFetchAllRouteResourcesSagaAction extends Action<'FETCH_ALL_ROUTE_RESOURCES_SAGA_ACTION'> {
  payload: {
    nextRouteParams?: IRouteParams;
    forceUpdate?: boolean;
  };
}
export const fetchAllRouteResourcesSagaAction = (
  nextRouteParams: IRouteParams | undefined = undefined,
  forceUpdate: boolean = false
): IFetchAllRouteResourcesSagaAction => ({
  type: FETCH_ALL_ROUTE_RESOURCES_SAGA_ACTION,
  payload: {
    nextRouteParams,
    forceUpdate,
  },
});

/*** UPDATE_ALL_ROUTE_RESOURCES_ACTION ***/

export const UPDATE_ALL_ROUTE_RESOURCES_ACTION = 'UPDATE_ALL_ROUTE_RESOURCES_ACTION';
export interface IUpdateAllRouteResourcesAction extends Action<'UPDATE_ALL_ROUTE_RESOURCES_ACTION'> {
  payload: {
    routeParams: IRouteParams;
    routeResources: IRouteResources;
    routeResourcesLists: IRouteResourcesLists;
  };
}
export const updateAllRouteResourcesAction = (
  routeParams: IRouteParams,
  routeResources: IRouteResources = {},
  routeResourcesLists: IRouteResourcesLists = {}
): IUpdateAllRouteResourcesAction => ({
  type: UPDATE_ALL_ROUTE_RESOURCES_ACTION,
  payload: {
    routeParams,
    routeResources,
    routeResourcesLists,
  },
});

export const RESET_APP_ACTION = 'RESET_APP_ACTION';
export type IResetAppAction = Action<'RESET_APP_ACTION'>;
export const resetAppAction = (): IResetAppAction => ({
  type: RESET_APP_ACTION,
});

export type IRoutingActions =
  | IUpdateRouteAction
  | IUpdateRouteHashAction
  | IToggleIsRouteResourcesLoadingAction
  | IToggleIsRouteResourcesUpToDateAction
  | ICleanRouteResourcesAfterRenderAction
  | IToggleRefreshingRoutePageStatusAction
  | IUpdateSingleRouteResourceAction
  | IUpdateAllRouteResourcesAction;
